<template>
  <div class="reissue-password">
    <!-- ↓↓ パスワード再発行 ↓↓ -->
    <div v-if="pageType === 'default'">
      <el-row>
        <h1>パスワード再発行</h1>
      </el-row>
      <div class="center">
        <div>
          <p>
            登録メールアドレスを入力してください。<br class="sp-only">パスワード再設定用URLを送信します。
          </p>
        </div>
          <el-input type="text" placeholder="abcdefg@gmail.com" v-model="email" style="max-width: 700px;">
            <template slot="prepend">登録メールアドレス</template>
          </el-input>
      </div>
      <el-row>
        <el-col class="button-cover">
          <el-button @click="sendToken" type="primary">送信</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- ↑↑ パスワード再発行 ↑↑ -->

    <!-- ↓↓ 暗証番号の確認 ↓↓ -->
    <div v-else-if="pageType === 'confirm'">
      <el-row type="flex" justify="center">
        <el-col>
          <h1>暗証番号の確認</h1>
          <p>登録済みメールアドレスに認証用のメールを送信しました。<br>メール内に記載されている暗証番号を入力してください。</p>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="6">
          <el-input type="text" placeholder="1234567" v-model="token">
            <template slot="prepend">暗証番号</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="button-cover">
          <el-button @click="confirmToken" type="primary">認証</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- ↑↑ 暗証番号の確認 ↑↑ -->

    <!-- ↓↓ パスワードの更新 ↓↓ -->
    <div v-else-if="pageType === 'reissue'">
      <el-row type="flex" justify="center">
        <h1>パスワードの更新</h1>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="12">
          <el-input type="password" placeholder="abcdefg12345678" v-model="newPassword">
            <template slot="prepend">新しいパスワード</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="12">
          <el-input
            type="password"
            placeholder="abcdefg12345678"
            v-model="newPasswordForConfirmation"
          >
            <template slot="prepend">新しいパスワード（確認用）</template>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col class="button-cover">
          <el-button @click="reissuePassword" type="primary">変更</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- ↑↑ パスワードの更新 ↑↑ -->

    <!-- ↓↓ パスワードの更新完了 ↓↓ -->
    <div v-else-if="pageType === 'complete'">
      <el-row type="flex" justify="center">
        <h1>パスワードの更新完了</h1>
      </el-row>
      <el-row>
        <el-col class="button-cover">
          <router-link to="/login">
            <el-button type="primary">ログイン画面へ</el-button>
          </router-link>
        </el-col>
      </el-row>
    </div>
    <!-- ↑↑ パスワードの更新完了 ↑↑ -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      pageType: "default",
      email: "",
      token: "",
      token2: "",
      newPassword: "",
      newPasswordForConfirmation: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  methods: {
    sendToken() {
      let params = new URLSearchParams();
      params.append("email", this.email);
      this.$axios
        .post(`${this.rt}/api/send-reissue-password-token.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.pageType = "confirm";
            return;
          } else if (res.data.status === 400 || res.data.status === 401) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 423) {
            alert(res.data.message);
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmToken() {
      let params = new URLSearchParams();
      params.append("email", this.email);
      params.append("token", this.token);
      this.$axios
        .post(`${this.rt}/api/confirm-reissue-password-token.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.pageType = "reissue";
            return;
          } else if (res.data.status === 400 || res.data.status === 401) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 423) {
            alert(res.data.message);
            this.$router.push("/login");
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    reissuePassword() {
      let params = new URLSearchParams();
      params.append("email", this.email);
      params.append("new_password", this.newPassword);
      params.append(
        "new_password_for_confirmation",
        this.newPasswordForConfirmation
      );
      params.append(
        "new_password_for_confirmation",
        this.newPasswordForConfirmation
      );
      this.$axios
        .post(`${this.rt}/api/reissue-password.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.pageType = "complete";
            return;
          } else if (res.data.status === 400 || res.data.status === 401) {
            alert(res.data.message);
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h1,
p {
  text-align: center;
}
.el-col.button-cover {
  text-align: center;
}
.desc {
  color: #999;
}
a.here {
  text-decoration: underline;
}
.center{
  display: flex;
  flex-direction: column;
  align-items: center;
  div{
    max-width: 1100px;
    width: 80%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

}
</style>
