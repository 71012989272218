<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（Link-Ag）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>対象ASP：Link-AG、BULK-AD、SIXPACK-C</p>
        <p class="red">
          以下、「Link-AG」を例に解説をしていますが、「BULK-AD」「SIXPACK-C」とも同じ手順になります。適宜、「Link-AG」→（該当するASP名）に読み替えて設定を行ってください。
        </p>
        <h4>
          【１】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/point-back`"
            >ASP自動連携設定</router-link
          >」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`"
          alt=""
        />
        <h4>【２】Link-AGの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no08/01.png`" alt="" />
        <h4>【３】以下の文言をLink-AGの担当者に連絡します。</h4>
        <p>
          -----------------------<br />
          アドタスカルのポイントバック連携を希望します。<br />
          テスト案件でのテストコード発行もお願いします。<br />
          <br />
          （【２】でコピーしたLink-AGの通知URL）<br />
          -----------------------
        </p>
        <h4>【４】連携が完了すると、テスト案件用のURLが発行してもらえます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no08/02.png`" alt="" />
        </div>
        <h4>【５】発行されたURLに「<span class="red">{{ tool.clickIdName }}_xxx</span>」を追記してください。</h4>
        <h5>▼発行されたテストURL</h5>
        <p>https://cl.link-ag.net/click/●●●/●●●</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p>
          https://cl.link-ag.net/click/●●●/●●●<span class="red"
            >/{{ tool.clickIdName }}_xxx</span
          >
        </p>
        <p class="red">※自分で付けたパラメータを覚えておきましょう</p>
        <h4>
          【６】パラメータを付けたテストURLにアクセスします。<span class="red"
            >ここで10秒以上待ちましょう</span
          >
        </h4>
        <p>
          <span class="red">10秒経過後、</span
          >「コンバージョンページへ遷移する」をクリックします。
        </p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no08/03.png`" alt="" />
        </div>
        <h4>【７】Thanksページが表示されます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no08/04.png`" alt="" />
        </div>
        <h4>
          【８】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/auto-import-log`"
            >自動登録履歴</router-link
          >」を確認します。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`"
          alt=""
        />
        <h4>
          【９】数分後に、テストCVが登録されていれば問題なく連携完了です。
        </h4>
        <AutoImportLog 
          :aspId="'8'"
          :conversionTime="'2021/02/22 12:44:37'"
          :aspPromotionName="'6561'"
          :conversionValue="'33000'"
        />
        <ul>
          <li>ASP：Link-AG</li>
          <li>
            CV時間：テストした時間になっているか？（多少の誤差はあり）
          </li>
          <li>プログラム名：-</li>
          <li>パラメータ：【５】で付けたパラメータと一致しているか？</li>
          <li>プログラムID： 6561</li>
          <li>報酬額：33,000</li>
          <li>インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です</li>
        </ul>
        <h4>【１０】連携完了</h4>
        <p>担当さんに一報入れましょう。これで連携は完了です。</p>
        <PointbackSettingDescription :aspId="'8'" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PointbackSettingDescription,
    AutoImportLog
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
