<template>
  <div class="manual-tools01">

    <!-- ↓↓ 目次 ↓↓ -->
    <ManualTools01Contents :tool="tool" />
    <!-- ↑↑ 目次 ↑↑ -->

    <!-- ↓↓ 共通コンテンツ１ ↓↓ -->
    <ManualTools01RegisterDomain :tool="tool" />
    <ManualTools01Conversion :tool="tool" />
    <!-- ↑↑ 共通コンテンツ１ ↑↑ -->

    <!-- ↓↓ ツール個別コンテンツ ↓↓ -->
    <ManualTools01Popup01 v-if="tool == 'popup01'" :tool="tool" />
    <ManualTools01Popup02 v-else-if="tool == 'popup02'" :tool="tool" />
    <ManualTools01Overlay01 v-else-if="tool == 'overlay01'" :tool="tool" />
    <ManualTools01Overlay02 v-else-if="tool == 'overlay02'" :tool="tool" />
    <!-- ↑↑ ツール個別コンテンツ ↑↑ -->

    <!-- ↓↓ 共通コンテンツ２ ↓↓ -->
    <ManualTools01Other :tool="tool" />
    <ManualTools01Editor :tool="tool" />
    <ManualTools01ABtest :tool="tool" />
    <ManualTools01Wordpress01 :tool="tool" />
    <ManualTools01Wordpress02 :tool="tool" />
    <!-- ↑↑ 共通コンテンツ２ ↑↑ -->

  </div>
</template>

<script>
import ManualTools01Contents from "@/components/Manual/Tools01/Contents.vue";
import ManualTools01RegisterDomain from "@/components/Manual/Tools01/RegisterDomain.vue";
import ManualTools01Conversion from "@/components/Manual/Tools01/Conversion.vue";
import ManualTools01Popup01 from "@/components/Manual/Tools01/Popup01.vue";
import ManualTools01Popup02 from "@/components/Manual/Tools01/Popup02.vue";
import ManualTools01Overlay01 from "@/components/Manual/Tools01/Overlay01.vue";
import ManualTools01Overlay02 from "@/components/Manual/Tools01/Overlay02.vue";
import ManualTools01Other from "@/components/Manual/Tools01/Other.vue";
import ManualTools01Editor from "@/components/Manual/Tools01/Editor.vue";
import ManualTools01ABtest from "@/components/Manual/Tools01/ABtest.vue";
import ManualTools01Wordpress01 from "@/components/Manual/Tools01/Wordpress01.vue";
import ManualTools01Wordpress02 from "@/components/Manual/Tools01/Wordpress02.vue";

export default {
  name: 'ManualTools01',
  components: {
    ManualTools01Contents,
    ManualTools01RegisterDomain,
    ManualTools01Conversion,
    ManualTools01Popup01,
    ManualTools01Popup02,
    ManualTools01Overlay01,
    ManualTools01Overlay02,
    ManualTools01Other,
    ManualTools01Editor,
    ManualTools01ABtest,
    ManualTools01Wordpress01,
    ManualTools01Wordpress02
  },
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
