<template>
  <div class="tools02-subscription-histories">
    <h2>利用ステータス履歴</h2>
    <el-row>
      <el-col>
        
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <SubscriptionHistoryList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubscriptionHistoryList from "@/components/Ui/Tools02/SubscriptionHistoryList.vue";

export default {
  components: {
    SubscriptionHistoryList,
  },
  props: {
    //
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
