<template>
  <div class="tools02-parameter-manual-internal-link">
    <h3 class="back-gray">ページ内リンク</h3>
    <p>ページ内リンクを設置する場合は、以下の注意点をご確認ください。</p>
    <h4>【１】リンク元（出発点）</h4>
    <p>リンク元のURL指定は、絶対パス・相対パスどちらでもパラメータを引き継いでリンク先に飛びます。</p>
    <p>ただ、絶対パスの場合、リンク先に飛ぶのに若干のタイムラグが発生してしまいます。相対パスでURL指定をすることを推奨いたします。</p>
    <h4>【２】リンク先（到達点）</h4>
    <p>id属性で指定してください。name属性の場合、リンク先に飛ばない場合がございます。</p>
    <div class="table-cover">
    <table>
      <tr>
        <td></td>
        <td>リンク元（出発点）</td>
        <td>リンク先（到達点）</td>
      </tr>
      <tr>
        <td>◎</td>
        <td>
          &lt;a href="#top">ページトップに戻る&lt;/a>
        </td>
        <td>
          &lt;div <span style="font-weight:bold;">id</span>="top">ページトップ&lt;/div>
        </td>
      </tr>
      <tr>
        <td>○</td>
        <td>
          &lt;a href="https://hogehoge.com/kiji/#top">ページトップに戻る&lt;/a>
        </td>
        <td>
          &lt;div <span style="font-weight:bold;">id</span>="top">ページトップ&lt;/div>
        </td>
      </tr>
      <tr>
        <td>☓</td>
        <td>
          &lt;a href="#top">ページトップに戻る&lt;/a>
        </td>
        <td>
          &lt;div <span style="font-weight:bold;">name</span>="top">ページトップ&lt;/div>
        </td>
      </tr>
    </table>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02ParameterManualInternalLink",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.table-cover{
  @media screen and (max-width: 923px){
    overflow: auto;
  }
}
table {
  border: 1px solid #999;
  border-collapse: collapse;
  @media screen and (max-width: 923px){
    width: 923px;
  }
  tr {
    td {
      padding: 10px;
      border: 1px solid #999;
    }
  }
}
</style>
