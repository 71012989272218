<template>
  <div class="point-payment-simulation">
    <el-row>
      <el-col>
        <h3>◎報酬の受け取り方によるシミュレーション</h3>
        <p>
          11,000円のアフィリエイト報酬が確定した場合の報酬のシミュレーションは以下のようになります。
        </p>
        <el-row type="flex" :gutter="20">
          <el-col :span="12">
            <h4>＜現金で報酬を受け取る場合＞</h4>
            <p>
              ①振込手数料：{{
                rewardFeeWithTax | localeNum
              }}円/回<br />↓<br />受取額：11,000円ー{{
                rewardFeeWithTax | localeNum
              }}円＝10,500円
            </p>
            <p>
              ②法人税：受取額の30%（※概算）<br />↓<br />法人税額：10,500円✕30%＝3,150円
            </p>
            <p>③実質的な報酬金額：10,500円ー3,150円＝7,350円</p>
          </el-col>
          <el-col :span="12">
            <h4>＜ポイントで報酬を受け取る場合＞</h4>
            <p>
              ①振込手数料：なし<br />↓<br />受取ポイント：11,000円÷1.1＝10,000ポイント<br />※ポイント換算：1ポイント＝税込1.1円
            </p>
            <p>②法人税：なし</p>
            <p>③実質的な報酬金額：11,000円</p>
          </el-col>
        </el-row>
        <p style="font-weight: bold; font-size: 20px">
          ポイントでの受け取りの方が約1.5倍オトクです。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PointPaymentSimulation",
  components: {},
  props: {
    type: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("affiliate", ["rewardFeeWithTax", "rewardBorderWithTax"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
