<template>
  <div class="affiliate">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(h3) {
  background-color: #dedede;
  padding: 3px 15px;
  border-radius: 5px;
}
:deep(table.af-pop) {
  border: solid 1px #ccc;
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  tr {
    th,
    td {
      border: solid 1px #ccc;
      padding: 5px 10px;
    }
    th {
      background-color: #f8f8f8;
    }
    td {
      text-align: right;
    }
  }
}

:deep(.register-button-cover) {
  margin-top: 10px;
  text-align: center;
}
</style>