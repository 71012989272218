<template>
  <div class="ctrl-affiliate-payment-information">
    <h2>支払情報管理</h2>
    <template>
      <el-row>
        <el-col>
          <PaymentList :dateRange="dateRange" />
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import PaymentList from "@/components/Ctrl/Affiliate/PaymentInformation/PaymentList.vue";

export default {
  components: {
    PaymentList,
  },
  data() {
    return {
      dateRange: [],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
