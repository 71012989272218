<template>
  <div class="manual-tools01-popup">
    <h3>{{tools[tool].name}}フォーマットを作成またはアップロードする</h3>
    <p>{{tools[tool].name}}の新規設定時は、表示する{{tools[tool].name}}フォーマットを作成またはアップロードする必要があります。新規で用意する「新規追加」と、設定済のページから転用する「他のページから転用」の2とおりの方法があります。</p>
    <h3>表示する{{tools[tool].name}}のフォーマットの新規作成</h3>
    <p>「新規設定」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-06.jpg`" alt="">
    <p>{{tools[tool].name}}のフォーマットの新規設定・編集画面が表示されます。以下について、入力と選択をします。</p>
    <table class="typeB">
      <tr>
        <th>フォーマット名</th>
        <td>設定する{{tools[tool].name}}フォーマットに名前を設定</td>
      </tr>
      <tr>
        <th>{{tools[tool].name}}からの遷移先タイプ</th>
        <td>{{tools[tool].name}}から直接LPへリンクする場合は「直LP」を選択。{{tools[tool].name}}から記事LPなどにリンクする場合は「記事LP」を選択</td>
      </tr>
      <tr>
        <th>紐付けるコンバージョン</th>
        <td>設定する{{tools[tool].name}}と紐づくコンバージョンを設定可能（設定は任意で、事前にコンバージョン登録が必要）</td>
      </tr>
    </table>
    <img :src="`${rt}/img/manual/manual2-1-07.jpg`" alt="">
    <p>アドタスカルでは以下の{{tools[tool].name}}フォーマットを用意しています。その中から、表示したい{{tools[tool].name}}フォーマットを選択してください。</p>
    <table class="typeB">
      <tr>
        <th>タイプA・タイプB</th>
        <td>エディター機能によって、テキスト設定とカラー選択、画像挿入などをすることで{{tools[tool].name}}フォーマットを作成</td>
      </tr>
      <tr>
        <th>画像のみ</th>
        <td>表示する{{tools[tool].name}}として1枚ものの画像を指定</td>
      </tr>
      <tr>
        <th>Yahoo!,Google風</th>
        <td>Yahoo!やGoogleの広告フォーマットに似た{{tools[tool].name}}フォーマットをエディター機能によって作成</td>
      </tr>
      <tr>
        <th>Facebook風</th>
        <td>Facebookの広告フォーマットに似た{{tools[tool].name}}フォーマットをエディター機能によって作成</td>
      </tr>
    </table>
    <img :src="`${rt}/img/manual/manual2-1-08.jpg`" alt="">
    <h3>「タイプA」「タイプB」の設定方法</h3>
    <p>フォーマットタイプから、「タイプA」または「タイプB」を選択すると、以下の編集エディター画面が表示されます（例としてAタイプを表示しています）。エディター機能を使って、フォーマットのカラー、表示するテキスト、挿入する画像、ボタン枠などを自由に編集し、「登録」をクリックすることでフォーマットが登録されます。</p>
    <p><router-link v-scroll-to="`#editor`" to="">エディター機能の使用方法詳細マニュアルはコチラ</router-link></p>
    <img :src="`${rt}/img/manual/manual2-1-09.jpg`" alt="">
    <h3>「画像のみ」の設定方法</h3>
    <p>フォーマットタイプから、「画像のみ」を選択すると、以下の画像選択画面が表示されるので、「画像選択」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-10.jpg`" alt="">
    <p>「画像を選択」をクリックすると、画像エディターが立ち上がるので、「＋クリックしてファイル選択」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-11.jpg`" alt="">
    <p>画像の選択画面が表示されるので、アップロードする画像を指定します。</p>
    <img :src="`${rt}/img/manual/manual2-1-12.jpg`" alt="">
    <p>指定した画像が表示されるので、「アップロード」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-13.jpg`" alt="">
    <p>以下の画面が表示されるので「OK」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-14.jpg`" alt="">
    <p>「画像一覧」のタブをクリックすると、アップロードした画像が表示されます。</p>
    <img :src="`${rt}/img/manual/manual2-1-15.jpg`" alt="">
    <p>アップロードした画像をクリックすると、以下のように選択状態となるので、「画像挿入」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-16.jpg`" alt="">
    <p>{{tools[tool].name}}の設定画面に戻るので、「登録」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-17.jpg`" alt="">
    <p>{{tools[tool].name}}の設定画面に戻ったら、「画像のみ」のフォーマットの登録は完了です。</p>
    <h3>表示する{{tools[tool].name}}のフォーマットを転用する</h3>
    <p>上では、表示する{{tools[tool].name}}のフォーマットの新規作成を説明しましたが、作成済のフォーマットをコピーして転用することもできます。「他のページから転用」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-18.jpg`" alt="">
    <p>作成済のフォーマットがあるページ一覧が表示されるので、転用したいページ名をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-19.jpg`" alt="">
    <p>選択したページで作成したフォーマットが表示されるので、転用したいもの下にある「コピー」をクリックします。</p>
    <img :src="`${rt}/img/manual/manual2-1-20.jpg`" alt="">
    <p>フォーマット一覧に追加されます。</p>
    <img :src="`${rt}/img/manual/manual2-1-21.jpg`" alt="">
    <h3>「Yahoo!, Google風」「Facebook風」の設定方法</h3>
    <p>フォーマットタイプから、「Yahoo!, Google風」または「Facebook風」を選択すると、以下の編集エディター画面が表示されます（例としてFacebook風タイプを表示しています）。エディター機能を使って、フォーマットのカラー、表示するテキスト、挿入する画像、ボタン枠などを自由に編集し、「登録」をクリックすることでフォーマットが登録されます。</p>
    <p><router-link v-scroll-to="`#editor`" to="">エディター機能の使用方法詳細マニュアルはコチラ</router-link></p>
    <img :src="`${rt}/img/manual/manual2-1-22.jpg`" alt="">
    <p>以上で、表示する{{tools[tool].name}}の作成は完了です。</p>
    <p>上記の操作によって、表示したい{{tools[tool].name}}を複数作成し、登録することができます。</p>

  </div>
</template>

<script>
export default {
  name: 'ManualTools01Popup',
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
