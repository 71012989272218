<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>MCCアカウントから新規のサブアカウントを開設する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】MCCアカウントの管理画面TOP→「アカウント」→「＋」ボタンをクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no04/01.png`" alt="" />
        <h4>【２】「新しいアカウントを作成」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no04/02.png`" alt="" />
        <h4>【３】サブアカウントの名前を入力して、「保存して次へ」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no04/03.png`" alt="" />
        <h4>【４】請求情報を入力して、「送信」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no04/04.png`" alt="" />
        <img :src="`${rt}/img/manual/google-cv/no09/no04/05.png`" alt="" />
        <h4>【５】新しいサブアカウントが表示されていればOKです。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no04/06.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
