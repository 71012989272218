<template>
  <div v-loading="loading" class="ctrl-affiliate-rewards-user-summaries">
    <el-row>
      <el-col>
        <div style="overflow: scroll;">
          <table class="two-d">
            <thead>
              <tr>
                <th colspan="2"></th>
                <th colspan="5">クリック数</th>
                <th colspan="5">発生数</th>
                <th colspan="5">成約率</th>
                <th colspan="5">確定件数</th>
                <th colspan="3">報酬</th>
              </tr>
              <tr>
                <th>ID</th>
                <th>登録名</th>
                <th>合計</th>
                <th>トップ</th>
                <th>G</th>
                <th>Y</th>
                <th>FB</th>
                <th>合計</th>
                <th>トップ</th>
                <th>G</th>
                <th>Y</th>
                <th>FB</th>
                <th>平均</th>
                <th>トップ</th>
                <th>G</th>
                <th>Y</th>
                <th>FB</th>
                <th>合計</th>
                <th>試</th>
                <th>Sta</th>
                <th>Big</th>
                <th>Pre</th>
                <th>確定金額</th>
                <th>報酬率</th>
                <th>報酬の受取方法</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(userSummaryData, index) in userSummaryList"
                :key="index"
              >
                <template v-if="index === 0">
                  <th colspan="2">{{ userSummaryData.userName }}</th>
                </template>
                <template v-else>
                  <td>{{ userSummaryData.userId }}</td>
                  <td>{{ userSummaryData.userName }}</td>
                </template>
                <!--  -->
                <th>{{ userSummaryData.clicksTotal | localeNum }}</th>
                <td>{{ userSummaryData.toolsClicks | localeNum }}</td>
                <td>{{ userSummaryData.googleCvClicks | localeNum }}</td>
                <td>{{ userSummaryData.yahooCvClicks | localeNum }}</td>
                <td>{{ userSummaryData.facebookCvClicks | localeNum }}</td>
                <!--  -->
                <th>{{ userSummaryData.conversionsTotal | localeNum }}</th>
                <td>{{ userSummaryData.toolsConversions | localeNum }}</td>
                <td>{{ userSummaryData.googleCvConversions | localeNum }}</td>
                <td>{{ userSummaryData.yahooCvConversions | localeNum }}</td>
                <td>{{ userSummaryData.facebookCvConversions | localeNum }}</td>
                <!--  -->
                <th>{{ userSummaryData.cvrTotal }}%</th>
                <td>{{ userSummaryData.toolsCvr }}%</td>
                <td>{{ userSummaryData.googleCvCvr }}%</td>
                <td>{{ userSummaryData.yahooCvCvr }}%</td>
                <td>{{ userSummaryData.facebookCvCvr }}%</td>
                <!--  -->
                <th>{{ userSummaryData.achievementsTotal | localeNum }}</th>
                <td>{{ userSummaryData.trialAchievements | localeNum }}</td>
                <td>{{ userSummaryData.p25Achievements | localeNum }}</td>
                <td>{{ userSummaryData.p27Achievements | localeNum }}</td>
                <td>{{ userSummaryData.p29Achievements | localeNum }}</td>
                <!--  -->
                <td>{{ userSummaryData.rewardTotalWithTax | localeNum }}円</td>
                <td>{{ userSummaryData.rewardRate }}%</td>
                <td>{{ userSummaryData.affiliatePaymentType }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CtrlAffiliateRewardsUserSummaryList",
  components: {},
  props: {
    dateRange: Array,
  },
  data() {
    return {
      loading: true,
      userSummaryList: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    dateRange: {
      handler(newDateRange) {
        this.getUserSummaryList(newDateRange);
      },
      immediate: true,
    },
  },
  methods: {
    getUserSummaryList(dateRange) {
      this.loading = true;
      this.$axios
        .get(
          `${
            this.rt
          }/api/ctrl/affiliate/get-user-summary-list.php?date_range=${encodeURIComponent(
            JSON.stringify(dateRange)
          )}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.userSummaryList = response.data.userSummaryList;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
table.two-d {
  width: 2000px;
  tbody {
    tr {
      td {
        text-align: center;
      }
    }
  }
}
</style>
