<template>
  <div class="tools02-accounts">
    <el-row>
      <el-col>
        <h2 v-if="tool.codeKebab === 'microsoft-cv'">
          マネージャーアカウント 一覧
        </h2>
        <h2 v-else>広告アカウント一覧</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <Tools02AccountsAccountList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02AccountsAccountList from "@/components/Tools02/Accounts/AccountList.vue";

export default {
  components: {
    Tools02AccountsAccountList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
