<template>
  <div class="ctrl-affiliate-rewards">
    <h2>ユーザー報酬額確認</h2>
    <el-row>
      <el-col>
        <DateRangePicker :dateRange.sync="dateRange" />
      </el-col>
    </el-row>
    <template v-if="dateRange.length !== 0">
      <el-row>
        <el-col>
          <UserSummaryList :dateRange="dateRange" />
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import DateRangePicker from "@/components/Affiliate/Ui/DateRangePicker.vue";
import UserSummaryList from "@/components/Ctrl/Affiliate/Rewards/UserSummaryList.vue";

export default {
  components: {
    DateRangePicker,
    UserSummaryList,
  },
  data() {
    return {
      dateRange: [],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
