<template>
  <div class="tools02-parameter-manual-recommended-settings">
    <h3 class="back-gray">推奨設定</h3>
    <h4>【１】記事LP、中間ページともにSSL化してください。</h4>
    <div class="table-cover">
      <table class="simple">
        <tr>
          <td></td>
          <td>記事LP・サイト</td>
          <td>中間ページ</td>
        </tr>
        <tr>
          <td>OK</td>
          <td>
            <span style="font-weight: bold">https</span>://hogehoge.com/kiji/
          </td>
          <td>
            <span style="font-weight: bold">https</span
            >://hogehoge.com/kiji/link.html
          </td>
        </tr>
        <tr>
          <td>NG</td>
          <td>
            <span style="font-weight: bold">https</span>://hogehoge.com/kiji/
          </td>
          <td>
            <span style="font-weight: bold">http</span
            >://hogehoge.com/kiji/link.html
          </td>
        </tr>
        <tr>
          <td>NG</td>
          <td>
            <span style="font-weight: bold">http</span>://hogehoge.com/kiji/
          </td>
          <td>
            <span style="font-weight: bold">https</span
            >://hogehoge.com/kiji/link.html
          </td>
        </tr>
        <tr>
          <td>NG</td>
          <td>
            <span style="font-weight: bold">http</span>://hogehoge.com/kiji/
          </td>
          <td>
            <span style="font-weight: bold">http</span
            >://hogehoge.com/kiji/link.html
          </td>
        </tr>
      </table>
    </div>
    <h4>【２】記事LP、中間ページを同一ドメインにしてください。</h4>
    <div class="table-cover">
      <table class="simple">
        <tr>
          <td></td>
          <td>記事LP・サイト</td>
          <td>中間ページ</td>
        </tr>
        <tr>
          <td>OK</td>
          <td>
            https://<span style="font-weight: bold">hogehoge.com</span>/kiji/
          </td>
          <td>
            https://<span style="font-weight: bold">hogehoge.com</span
            >/kiji/link.html
          </td>
        </tr>
        <tr>
          <td>NG</td>
          <td>
            https://<span style="font-weight: bold">hogehoge.com</span>/kiji/
          </td>
          <td>
            https://<span style="font-weight: bold">abc.com</span
            >/kiji/link.html
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02ParameterManualRecommendedSettings",
  components: {},
  props: {
    normalTag: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.table-cover {
  @media screen and (max-width: 768px) {
    overflow: auto;
  }
  .simple {
    width: 593px;
  }
}
</style>
