<template>
  <div class="footer">
    <p>Copyright ©adtasukaru.com All Rights Reserved.</p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  line-height: 70px;

  // background: var(--black);
  background: #eee;
  border-top: solid 1px #ddd;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-top: 100px;
  p {
    color: var(--black);
    margin: 0;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }
}
</style>
