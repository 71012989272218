<template>
  <div class="tools01-abtest-report">
    <el-row>
      <el-col>
        <h2>ABテストレポート</h2>
      </el-col>
    </el-row>
    <AbTestReportList :toolId="toolId" :toolPageId="toolPageId" :toolPageTestId="toolPageTestId" />
  </div>
</template>

<script>
import AbTestReportList from "@/components/Tools01/AbTestReport/AbTestReportList.vue";

export default {
  name: "tools01-abtest-report",
  components: {
    AbTestReportList,
  },
  props: {
    toolId: String,
    toolPageId: String,
    toolPageTestId: String,
  },
  data() {
    return {
      // 
    };
  },
  computed: {
    // 
  },
  methods: {
    // 
  },
};
</script>

<style lang="scss" scoped>
</style>
