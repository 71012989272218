<template>
  <div class="tools02-pointback">
    <el-row>
      <el-col>
        <h2 v-if="tool.codeKebab === 'cart-system'">カート/計測システム自動連携設定</h2>
        <h2 v-else>ASP自動連携設定</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <Tools02PointBackAutoImportTags/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02PointBackAutoImportTags from "@/components/Tools02/PointBack/AutoImportTags.vue";

export default {
  components: {
    Tools02PointBackAutoImportTags
  },
  props: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
