<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（アクセストレード）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a
            href="https://www.accesstrade.ne.jp/"
            target="_blank"
            rel="noopener noreferrer"
            >アクセストレードの管理画面</a
          >にログインしたら、管理画面TOPページから「レポート」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no08/01.png`" alt="" />
        <h4>【２】「計測パラメータ」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no08/02.png`" alt="" />
        <p>※「計測パラメータ」はポイントバック通知機能の審査通過後、追加されます。詳しくは「<router-link :to="`/manual/${tool.codeKebab}/08/20`">ASPとアドタスカルを連携する（アクセストレード）</router-link>」を参考にしてください。</p>
        <h4>【３】期間を選択して、「表示」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no08/03.png`" alt="" />
        <h4>
          【４】成果が表示されたら、「計測ID」にクリックパラメータ（{{tool.clickIdName}}_xxx）が表示されているか確認します。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no08/04.png`" alt="" />
        <p class="red">
          ※パラメータが表示されていない場合、パラメータ引継ぎ設定を間違えている可能性があります。
        </p>
        <p>
          「<router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。
        </p>
        <h4>
          【５】CSVをダウンロードします。「詳細版」を選択して、「ダウンロード」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no08/05.png`" alt="" />
        <p>
          これで成果CSVのダウンロードは完了です。「<router-link
            :to="`/manual/${tool.codeKebab}/07/14`"
            >ASPの成果データをアドタスカルにインポートする</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
