<template>
  <div class="manual-tools01-overlay01">
    <el-row>
      <el-col>
        <h2>{{tools[tool].name2}}設定マニュアル</h2>
        <h3 :id="`${tools[tool].code2}-01`">{{tools[tool].name2}}を設置するサイト・ページの新規登録</h3>
        <p>「新規設定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-01.jpg`" alt="">
        <p>新規設定画面が表示されます。以下について、入力と選択をしてください。</p>
        <table class="typeB">
          <tr>
            <th>ページ名</th>
            <td>{{tools[tool].name2}}を設置するページ名を入力</td>
          </tr>
          <tr>
            <th>設定ドメイン</th>
            <td>{{tools[tool].name2}}を設定するドメインを選択</td>
          </tr>
          <tr>
            <th>設定ページURL</th>
            <td>{{tools[tool].name2}}を設定するページのURLを入力</td>
          </tr>
        </table>
        <p>入力したら、「登録」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-02.jpg`" alt="">
        <p>以上で、{{tools[tool].name2}}の新規設定が完了しました。</p>
        <img :src="`${rt}/img/manual/manual2-3-03.jpg`" alt="">
        <h3 :id="`${tools[tool].code2}-02`">表示する{{tools[tool].name2}}の設定</h3>
        <p>「編集」をクリックして、表示する{{tools[tool].name2}}の詳細設定をしていきます。</p>
        <img :src="`${rt}/img/manual/manual2-3-04.jpg`" alt="">
        <h3>{{tools[tool].name2}}を設定するページ情報の入力</h3>
        <p>{{tools[tool].name2}}の新規設定時に入力した情報が反映されているので、必要に応じて編集をしてください。「一言メモ」には、{{tools[tool].name}}を設置したページの属性など、任意でメモを残すことができます。入力や編集した項目は「更新」をクリックして確定させてください。</p>
        <img :src="`${rt}/img/manual/manual2-3-05.jpg`" alt="">
        <h3>{{tools[tool].name2}}からの飛び先URLの設定</h3>
        <p>設定する{{tools[tool].name2}}からの飛び先URLを指定できます。「編集（クリックして編集画面へ）」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-06.jpg`" alt="">
        <p>飛び先URLの編集画面が立ち上がるので、飛び先として設定するURLをhttps://またはhttp://から入力し、「OK」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-07.jpg`" alt="">
        <p>飛び先URLの入力欄の横にある「更新」をクリックすると、飛び先URLの設定が完了します。</p>
        <p>続いて、{{tools[tool].name2}}のからの飛び先URLのページの開き方について設定します。同一タブで開く設定と、新規タブで開く設定ができるので、どちらかを選択してください。「{{tools[tool].name}}からの飛び先URLメモ」は、飛び先URLについてのメモ書きを任意で残すことができます。入力や編集した項目は「更新」をクリックして確定させてください。</p>
        <img :src="`${rt}/img/manual/manual2-3-08.jpg`" alt="">
        <p>{{tools[tool].name}}の表示タイミングについては、「常に表示」と「指定のスクロール位置に訪問者が達したら表示」のどちらかを選択してください。「指定のスクロール位置に訪問者が達したら表示」を選択した場合は、訪問者の行動による表示の制御として、「上に戻ったら消える」と「上に戻っても消えない」のどちらかを選択してください。選択後は「更新」をクリックして確定させてください。</p>
        <img :src="`${rt}/img/manual/manual2-3-09.jpg`" alt="">
        <h3>{{tools[tool].name}}を作成またはアップロードする</h3>
        <p>{{tools[tool].name2}}の新規設定時は、表示する{{tools[tool].name}}のフォーマットを作成またはアップロードする必要があります。新規で用意する「新規追加」と、設定済のページから転用する「他のページから転用」の2とおりの方法があります。</p>
        <h3>表示する{{tools[tool].name2}}のフォーマットの新規作成</h3>
        <p>「新規設定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-10.jpg`" alt="">
        <p>{{tools[tool].name2}}のフォーマットの新規設定・編集画面が表示されます。以下について、入力と選択をします。</p>
        <table class="typeB">
          <tr>
            <th>フォーマット名</th>
            <td>設定する{{tools[tool].name}}のフォーマットに名前を設定</td>
          </tr>
          <tr>
            <th>{{tools[tool].name}}からの遷移先タイプ</th>
            <td>{{tools[tool].name}}から直接LPへリンクする場合は「直LP」を選択。{{tools[tool].name}}から記事LPなどにリンクする場合は「記事LP」を選択</td>
          </tr>
          <tr>
            <th>紐付けるコンバージョン</th>
            <td>設定する{{tools[tool].name}}と紐づくコンバージョンを設定可能（設定は任意で、事前にコンバージョン登録が必要）</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/manual2-3-11.jpg`" alt="">
        <p>アドタスカルでは以下の{{tools[tool].name}}のフォーマットを用意しています。その中から、表示したい{{tools[tool].name}}のフォーマットを選択してください。</p>
        <table class="typeB">
          <tr>
            <th>タイプA</th>
            <td>エディター機能によって、テキスト設定とカラー選択、画像挿入などをすることで{{tools[tool].name}}のフォーマットを作成</td>
          </tr>
          <tr>
            <th>画像のみ</th>
            <td>表示する{{tools[tool].name}}として1枚ものの画像を指定</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/manual2-3-12.jpg`" alt="">
        <h3>「タイプA」の設定方法</h3>
        <p>フォーマットタイプから、「タイプA」を選択すると、以下の編集エディター画面が表示されます。エディター機能を使って、フォーマットのカラー、表示するテキスト、挿入する画像、ボタン枠などを自由に編集し、「登録」をクリックすることでフォーマットが登録されます。</p>
        <p><router-link v-scroll-to="`#editor`" to="">エディター機能の使用方法詳細マニュアルはコチラ</router-link></p>
        <img :src="`${rt}/img/manual/manual2-3-13.jpg`" alt="">
        <h3>「画像のみ」の設定方法</h3>
        <p>フォーマットタイプから、「画像のみ」を選択すると、以下の画像選択画面が表示されるので、「画像選択」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-14.jpg`" alt="">
        <p>「画像を選択」をクリックすると、画像エディターが立ち上がるので、「＋クリックしてファイル選択」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-15.jpg`" alt="">
        <p>画像の選択画面が表示されるので、アップロードする画像を指定します。</p>
        <img :src="`${rt}/img/manual/manual2-3-16.jpg`" alt="">
        <p>指定した画像が表示されるので、「アップロード」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-17.jpg`" alt="">
        <p>以下の画面が表示されるので「OK」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-18.jpg`" alt="">
        <p>「画像一覧」のタブをクリックすると、アップロードした画像が表示されます。</p>
        <img :src="`${rt}/img/manual/manual2-3-19.jpg`" alt="">
        <p>アップロードした画像をクリックすると、以下のように選択状態となるので、「画像挿入」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-20.jpg`" alt="">
        <p>{{tools[tool].name2}}の設定画面に戻るので、「登録」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-21.jpg`" alt="">
        <p>{{tools[tool].name2}}の設定画面に戻ったら、「画像のみ」のフォーマットの登録は完了です。</p>
        <h3>表示する{{tools[tool].name}}のフォーマットを転用する</h3>
        <p>上では、表示する{{tools[tool].name}}のフォーマットの新規作成を説明しましたが、作成済のフォーマットをコピーして転用することもできます。「他のページから転用」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-22.jpg`" alt="">
        <p>作成済のフォーマットがあるページ一覧が表示されるので、転用したいページ名をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-23.jpg`" alt="">
        <p>選択したページで作成したフォーマットが表示されるので、転用したいもの下にある「コピー」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-24.jpg`" alt="">
        <p>フォーマット一覧に追加されます。</p>
        <img :src="`${rt}/img/manual/manual2-3-25.jpg`" alt="">
        <p>以上で、表示する{{tools[tool].name}}の作成は完了です。</p>
        <p>上記の操作によって、表示したい{{tools[tool].name}}を複数作成し、登録することができます。</p>
        <h3>表示する{{tools[tool].name}}のフォーマットを決定する</h3>
        <p>フォーマット一覧にある{{tools[tool].name}}の中で、背景がピンク色になっているものは現在設定中のものになります。表示させる{{tools[tool].name}}を変更したい場合は、フォーマットの下にある「○印」にチェックを入れ、「登録」をクリックすると、表示フォーマットが変更されます。</p>
        <img :src="`${rt}/img/manual/manual2-3-26.jpg`" alt="">
        <p>以上で、{{tools[tool].name2}}の設定が完了しました。</p>
        <h3 :id="`${tools[tool].code2}-03`">{{tools[tool].name2}}の設置</h3>
        <p>以下のタグを設置することで、{{tools[tool].name2}}を稼働させることができます。</p>
        <table class="typeB">
          <tr>
            <th>ツール設置タグ</th>
            <td>ツール自体を稼働させるためのタグ</td>
          </tr>
          <tr>
            <th>コンバージョンタグ</th>
            <td>設定する{{tools[tool].name}}と紐づくコンバージョンを設定することができます（当機能を利用する場合のみ設定が必要）</td>
          </tr>
        </table>
        <p>{{tools[tool].name2}}のツール設置タグは、以下の画面から取得することができます。「タグ表示」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-3-27.jpg`" alt="">
        <p>ツール設置タグが表示されるので、{{tools[tool].name}}を登場させたい位置にタグを設置してください。 {{tools[tool].name}}を常にしたい場合は、任意の位置に設置してください（&lt;/body>の直前など）。</p>
        <img :src="`${rt}/img/manual/manual2-3-28.jpg`" alt="">
        <p>ツール設置タグは、{{tools[tool].name2}}の編集画面からも取得ができます。編集画面の最下部にツール設置タグがあるので、{{tools[tool].name}}を登場させたい位置にタグを設置してください。 {{tools[tool].name}}を常にしたい場合は、任意の位置に設置してください（&lt;/body>の直前など）。</p>
        <img :src="`${rt}/img/manual/manual2-3-29.jpg`" alt="">
        <p>コンバージョンタグは、共通マニュアルとして別途用意していますので、<router-link v-scroll-to="`#conversion`" to="">コチラ</router-link>を参照してください。</p>
        <p><router-link to="">{{tools[tool].name2}}に関するよくある質問はコチラ</router-link></p>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'ManualTools01Overlay01',
  components: {
  },
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
