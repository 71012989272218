<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>オフラインコンバージョンの作成</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            オフラインコンバージョンを利用するためには、一般的なコンバージョンタグは利用せず、<br />オフラインコンバージョン用のコンバージョンを作成します。
          </li>
        </ul>
        <div v-if="tool.codeKebab === 'google-cv'">
          ※MCV（アフィリリンクのクリック）を計測したい場合は、中間ページ（リダイレクトページ）にコンバージョンタグの設置は必要です。<br /><span
            style="margin-right: 1em"
          ></span
          >MCV用のコンバージョンタグは今まで通り作成してください。
        </div>
        <h3>【所要時間】</h3>
        <p>５分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h4>【１】「ツール」→「測定」→「コンバージョン」</h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/01.png`" alt="" />
        <h4>【２】「＋新しいコンバージョン アクション」をクリック</h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/02.png`" alt="" />
        <h4>
          【３】「インポート」→「他のデータソースまたはCRM」→「クリック経由のコンバージョンをトラッキング」→「続行」
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/03.png`" alt="" />
        <h4>【４】オフラインコンバージョンの入力をします。</h4>
        <p>
          <span style="font-weight: bold">①目標とアクションの最適化：</span><br />
          <span class="indent-1">カテゴリ：コンバージョンに至った見込み顧客（デフォルト）</span><br />
          <span class="indent-1">最適化オプション：</span><br />
          <span class="indent-2">オフラインコンバージョンを最適化の対象にしたい場合：「メインアクション」</span><br />
          <span class="indent-2">最適化には使わず、計測用にしたい場合：「サブアクション」</span>
        </p>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/04.png`" alt="" />
        <p>
          <span style="font-weight: bold">②コンバージョン名：案件ごとにご自身で分かりやすい名前を付けましょう。<span class="red">※カンマ（,）は使わないでください</span></span>
        </p>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/05.png`" alt="" />
        <p>
          <span style="font-weight: bold">③値：</span><br />
          <span>ASPによって、コンバージョン値の設定方法が異なります</span>
        </p>
        <p>
          <span style="font-weight: bold">報酬額が返却されるASP</span
          >：Felmat、SLVRbullet、セレス、レントラックス、SCAN、famAD、Link-AG、afb、アクセストレード、Mobee2、A8.net、メディパートナー、BULK-AD、SIXPACK-C
        </p>
        <p>
          <span style="font-weight: bold">報酬額が返却されないASP</span
          >：サルクルー、アフィリコードプラス、アフィリコードシステム（Tempura-Link、twowin、ゴリラテ、Arrows、i-counter、クラウン、ADRIP、アシスタ）、AFAD（Self Leap、インペリアル、LADDER）、AFAD旧（Valley Consulting）、CATS（akibito）、VENT、TOSHOアフィリエイト
        </p>
        <el-tabs type="border-card" @tab-click="handleClick">
          <el-tab-pane label="★報酬額が返却されるASP">
            <p>報酬額が返却されるASPでは、ASPの報酬額を自動で反映させることができます。</p>
            <h4>＜ASPの報酬額を自動で反映したい場合＞</h4>
            <p>
              「コンバージョンごとに異なる価値を割り当てる」をチェックしてください。<br />
              値は「1」のままで大丈夫です。ASPの報酬額が自動で反映されます。
            </p>
            <img :src="`${rt}/img/manual/google-cv/no03/no02/06.png`" alt="" />
            <p>
              <span class="red">※実際に反映されるコンバージョン値は、[ASPの報酬額（税別）]×[アドタスカルのプログラム登録で設定した承認率]になります。</span>
            </p>
            <h4>＜自分で固定の報酬額を設定したい場合＞</h4>
            <p>
             「すべてのコンバージョンに同一の価値を割り当てる」にチェックしてください。<br />
             値は、ご自身で反映させたい報酬額を入力してください<br />
             <span class="red">※案件によっては、ASP上で発生する報酬額と実際の報酬額が異なる場合があります。そのときは、こちらをお使いください。</span>
            </p>
            <img :src="`${rt}/img/manual/google-cv/no03/no02/07.png`" alt="" />
          </el-tab-pane>
          <el-tab-pane label="★報酬額が返却されないASP">
            <p>報酬額が返却されないASPでは、アドタスカルのプログラム登録で入力した報酬額を自動で反映させることができます。</p>
            <h4>＜ASPの報酬額を自動で反映したい場合＞</h4>
            <p>
              「コンバージョンごとに異なる価値を割り当てる」をチェックしてください。<br />
              値は「1」のままで大丈夫です。アドタスカルのプログラム登録で設定した報酬額が自動で反映されます。
            </p>
            <img :src="`${rt}/img/manual/google-cv/no03/no02/06.png`" alt="" />
            <p>
              <span class="red">※実際に反映されるコンバージョン値は、[アドタスカルのプログラム登録で設定した報酬額]×[アドタスカルのプログラム登録で設定した承認率]になります。</span>
            </p>
            <h4>＜自分で固定の報酬額を設定したい場合＞</h4>
            <p>
             「すべてのコンバージョンに同一の価値を割り当てる」にチェックしてください。<br />
             値は、ご自身で反映させたい報酬額を入力してください<br />
             <span class="red">※案件によっては、ASP上で発生する報酬額と実際の報酬額が異なる場合があります。そのときは、こちらをお使いください。</span>
            </p>
            <img :src="`${rt}/img/manual/google-cv/no03/no02/07.png`" alt="" />
          </el-tab-pane>
        </el-tabs>
        <p>
          <span style="font-weight: bold">④カウント方法</span
          >：「初回のみ」を選択
        </p>
        <p>
          <span style="font-weight: bold"
            >⑤クリックスルー コンバージョンの計測期間</span
          >：ご自身で期間を選択してください。（特にこだわりがない方は、デフォルトで問題ないです）
        </p>
        <p>
          <span style="font-weight: bold">⑥アトリビューションモデル</span
          >：ご自身で好きなアトリビューション モデルを選択してください。
        </p>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/08.png`" alt="" />
        <h4>
          【５】全ての入力が完了したら、「作成して続行」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/09.png`" alt="" />
        <p>
          <span class="red">※アカウントによっては以下の画面が表示されます。（Google広告はアカウントによって表示内容が異なることがあります。もしマニュアルに無い画面が表示されたらサポートまでお問い合わせください。）</span>
        </p>
        <h4>【パターンA】</h4>
        <p>オフラインコンバージョンを測定する方法：GoogleクリックID<br />
        オフラインコンバージョンのインポート：アップロード<br />
        「完了」をクリック</p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no03/no02/10.png`" alt="" />
        </div>
        <h4>【パターンB】</h4>
        <p>リードの拡張コンバージョン：チェックしない<br />
        オフラインコンバージョンのインポート：アップロード<br />
        「GoogleクリックIDで続行」をクリック</p>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/16.png`" alt="" />
        <h4>
          【６】以下の画面が表示されましたらオフラインコンバージョンの作成は完了です。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no02/11.png`" alt="" />
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <p>
          ※Yahoo!検索広告でオフラインコンバージョン機能を利用できるのは「検索広告」のみです。「ディスプレイ広告」はオフラインコンバージョン機能を利用できません。
        </p>

        <h4>
          【１】「ツール」の横にある「▼」→「コンバージョン測定」の順でクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no02/01.png`" alt="" />

        <h4>【２】「コンバージョン測定の新規設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no02/02.png`" alt="" />

        <h4>【３】オフラインコンバージョンの入力をします。</h4>
        <ul>
          <li>
            コンバージョン名：案件ごとにご自身で分かりやすい名前を付けます。
          </li>
          <li>コンバージョンの種類：「インポート」を選択します。</li>
          <li>コンバージョンの測定目的：該当するものを選択します。</li>
          <li>計測方法：該当するものを選択します。</li>
          <li>自動入札への利用：希望する方を選択します。入力は任意です。</li>
          <li>計測期間：ご自身で期間を選択してください。入力は任意です。</li>
          <li>1コンバージョンあたりの価値：以下の【４】で別途説明します。</li>
        </ul>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no02/03.png`" alt="" />

        <h4>
          【４】「1コンバージョンあたりの価値」を入力し、「設定を保存」をクリックします。
        </h4>
        <p>
          ◎サルクルーの場合、または、ポイントバック通知で一旦100円などの仮の報酬額でデータが飛んでくる場合
        </p>
        <p>報酬額を入力してください。</p>

        <p>◎上記以外のAPSの場合</p>
        <p>
          報酬データをアドタスカルに反映させる仕組みになっているため入力不要です。
        </p>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no02/04.png`" alt="" />

        <h4>【５】以下の画面が表示されたら設定完了です。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no02/05.png`" alt="" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>これで設定は完了です。</p>
        <p v-if="tool.codeKebab === 'google-cv'">
          次は、「<router-link :to="`/manual/${tool.codeKebab}/04/01`"
            >{{ tool.mediaName }}アカウント登録</router-link
          >」に進んでください。
        </p>
        <p v-else-if="tool.codeKebab === 'yahoo-cv'">
          次は、「<router-link :to="`/manual/${tool.codeKebab}/04/02`"
            >Yahoo!JAPANビジネスIDの登録</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.indent-1{
  display: inline-block;
  text-indent: 1rem;
}
.indent-2{
  display: inline-block;
  text-indent: 2rem;
}
</style>
