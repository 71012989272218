<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（アクセストレード）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【注意事項】</h4>
        <p>アクセストレードは、アドタスカルとの連携に必要な<br />
        ・計測パラメータ機能<br />
        ・リアルポイントバック通知機能<br />
        の利用に対して審査があります。<br />
        まずは、以下の手順に添って審査依頼をお願いします。</p>
        <h4>■審査依頼</h4>
        <h4>
          【１】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/point-back`"
            >ASP自動連携設定</router-link
          >」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`"
          alt=""
        />
        <h4>【２】アクセストレードの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no20/01.png`" alt="" />
        <h4>【３】アクセストレードへの審査依頼をします。</h4>
        <p>
          アクセストレードのご担当者様に以下の文章をご連絡ください。
        </p>
        <p>
          以下の文章中の②の「【アドタスカルの通知URL】」には【２】でコピーしたアクセストレードの通知URLを貼り付けてください。
        </p>
        <div style="margin-top: 50px">
          <p>
            ------------------------------------------------------------------------
          </p>
          <p>
            計測パラメータとリアルポイントバック通知の導入審査をお願いいたします。
          </p>
          <h5 style="margin-left: 20px">▼理由</h5>
          <p>
            アドタスカルという{{ tool.mediaName }}とASPと連携して{{
              tool.mediaName
            }}のオフラインコンバージョンを自動化するツールを使うためです。
          </p>
          <p>※すでに連携できているメディア様もいると聞いております。</p>

          <h5 style="margin-left: 20px">▼アドタスカルの仕様書</h5>
          <a
            style="margin-top: 0"
            href="https://www.dropbox.com/s/rt3vstx6n71l7bz/%E3%82%A2%E3%83%89%E3%82%BF%E3%82%B9%E3%82%AB%E3%83%AB%E8%AA%AC%E6%98%8E%E8%B3%87%E6%96%99%EF%BC%88ASP%E5%90%91%E3%81%91%EF%BC%89.pdf?dl=0"
            ><p>
              https://www.dropbox.com/s/rt3vstx6n71l7bz/%E3%82%A2%E3%83%89%E3%82%BF%E3%82%B9%E3%82%AB%E3%83%AB%E8%AA%AC%E6%98%8E%E8%B3%87%E6%96%99%EF%BC%88ASP%E5%90%91%E3%81%91%EF%BC%89.pdf?dl=0
            </p></a
          >
          <h5 style="margin-left: 20px">▼リアルポイントバック通知の仕様</h5>
          <p>
            ①アクトレ様から通知としてお戻しする必要がある情報：発生成果のみ通知
          </p>
          <p>
            ②アクトレ様からのアドタスカルの通知先のURL:【アドタスカルの通知URL】
          </p>
          <p>
            ③通知に必要なパラメーター:[[add]] 会員ID、[[mid]] マーチャントID、[[hdate]] 発生日、[[reward]] 報酬、[[rk]] rk
          </p>
          <p>④パートナー報酬が0円の成果は：通知する</p>

          <p>
            ------------------------------------------------------------------------
          </p>
        </div>

        <h4>【４】計測パラメータの利用規約に同意します。</h4>
        <p>
          計測パラメータとリアルタイムポイントバックの利用が承認されましたら、アクセストレードから計測パラメータの利用規約の同意を求められますので、内容を確認して同意をお願いいたします。
        </p>
        
        <h4>■連携確認方法＆連携依頼</h4>
        <p>審査に承認されましたら、アドタスカルとの連携確認をお願いします。</p>
        <h4>【５】「マイページ」→「サイト情報」の順にクリックしてください。登録しているサイトが表示されます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no20/02.png`" alt="" />

        <h4>【６】この画面の「ポイントバック」欄で連携確認ができます。</h4>
        <p>空欄：連携されています。【８】に進んでください。</p>
        <p>利用：連携されていません。【７】に進んでください。</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no20/03.png`" alt="" />

        <h4>【７】連携したいサイトのサイトIDとサイト名、通知URLを担当者さんにご連絡ください</h4>
        <p style="margin-bottom: 0">--------------------</p>
        <p class="inner">アドタスカルのポイントバック連携をお願いします。</p>
        <p class="inner">ID：○○</p>
        <p class="inner">サイト名：○○</p>
        <p class="inner">通知URL：【アドタスカルの通知URL】</p>
        <p class="inner">設定後、リワードテストの提携もお願いします。</p>
        <p style="margin-top: 0">--------------------</p> 

        <h4>【８】連携が完了したら、テスト用の案件を提携のお知らせが来ます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no20/04.png`" alt="" />

        <h4>【９】アクセストレードの管理画面からアフィリリンクを取得します。</h4>
        <p>①検索窓に「リワードテスト」と入力して「提携中」をクリック</p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no20/05.png`" alt="" />
        </div>
        <p>②連携テストしたいサイトを選択→「サイト切替」をクリック</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no20/06.png`" alt="" />
        <p>③リワードテストを見つけたら「リンクコード」をクリック</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no20/07.png`" alt="" />
        <p>④アフィリリンクをコピー</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no20/08.png`" alt="" />
        <h4>【１０】アフィリエイトリンクに「<span class="green">&add={{tool.clickIdName}}_xxx</span>」を追記してください</h4>
        <h5>▼アフィリエイトリンク</h5>
        <p class="blue">https://h.accesstrade.net/sp/cc?rk=●●●●●●●●●</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p class="blue">
          https://h.accesstrade.net/sp/cc?rk=●●●●●●●●●<span class="green">&add={{tool.clickIdName}}_xxx</span>
        </p>
        <p>※自分で付けたパラメータを覚えておきましょう</p>
        <h4>【１１】パラメータを付けたアフィリエイトリンクにアクセスし、「定額」の「こちらをクリックしてください」をクリックしてください。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no20/09.png`" alt="" />
        </div>
        <h4>【１２】以下のページが表示されたらテスト終了です。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no20/10.png`" alt="" />
        </div>

        <h4>
          【１３】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/auto-import-log`"
            >自動登録履歴</router-link
          >」を確認します。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`"
          alt=""
        />

        <h4>【１４】３〜５分後に、テストCVが登録されていれば問題なく連携完了です。</h4>
        <AutoImportLog 
          :aspId="'10'"
          :promotionName="'-'"
          :aspPromotionName="'387580'"
          :conversionValue="'1'"
        />
        <ul>
          <li>ASP：アクセストレード</li>
          <li>
            CV時間：テストした時間になっているか？（多少の誤差はあり）
          </li>
          <li>プログラム名：-</li>
          <li>パラメータ：【１０】で付けたパラメータと一致しているか？</li>
          <li>プログラムID：画像の様な英数字が表示されているか？</li>
          <li>報酬額：報酬額：「1」が反映されているか？</li>
          <li>
            インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です。
          </li>
        </ul>

        <h4>【１５】連携完了</h4>
        <p>担当さんに一報入れましょう。これで連携は完了です。</p>
        
        <PointbackSettingDescription :aspId="'10'" />

        <p>不明な点がありましたら、お気軽にお問い合わせくださいませ。</p>
        <p>以上、よろしくお願いいたします。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PointbackSettingDescription,
    AutoImportLog
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.inner {
  margin: 3px 35px;
}

</style>
