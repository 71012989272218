<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>自動タグの設定</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>自動タグ設定をオンにします。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <h3 v-if="tool.codeKebab === 'google-cv'">【動画】</h3>
        <!-- <iframe
          v-if="tool.codeKebab === 'google-cv'"
          src="https://player.vimeo.com/video/529599850?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="03-01自動タグの設定.mp4"
        ></iframe> -->
        <div style="padding: 62.5% 0 0 0; position: relative" v-if="tool.codeKebab === 'google-cv'">
          <iframe
            src="https://player.vimeo.com/video/529599850?h=baf356bbd8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="03-01自動タグの設定.mp4"
          ></iframe>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h4>【１】Google広告の管理画面にアクセスします。</h4>
        <h4>
          【２】「すべてのキャンペーン」→「設定」→「アカウント設定」→「自動タグ設定」→チェック→「保存」の順にクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no01/01.png`" alt="" />
        <h4>【３】「自動タグ設定」が「はい」になります。</h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no01/02.png`" alt="" />
        <h4>
          【４】アドタスカルと連携したい全てのアカウントで同様の設定を行ってください。
        </h4>
        <p>これで設定は完了です。</p>
        <p>
          次は、「<router-link to="/manual/google-cv/03/02"
            >オフラインコンバージョンの作成</router-link
          >」に進んでください。
        </p>
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <h4>【１】Yahoo!検索広告の管理画面にアクセスします。</h4>
        <h4>【２】「アカウント管理」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no01/01.png`" alt="" />
        <h4>
          【３】「検索広告」をクリックし、自動タグの設定をするアカウント名の「アカウント設定」の「設定」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no01/02.png`" alt="" />
        <h4>
          【４】「自動タグ設定」の欄が「設定する」になっているかを確認します。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no01/03.png`" alt="" />
        <p>「設定する」になっている場合は、自動タグの設定がされています。</p>
        <p style="padding-left: 10px">
          アドタスカルと連携したい全てのアカウントで同様の設定になっているかをご確認ください。
        </p>
        <p style="padding-left: 10px">
          次は、「<router-link to="/manual/yahoo-cv/03/02"
            >オフラインコンバージョンの作成</router-link
          >」に進みます。
        </p>
        <p style="margin-top: 50px">
          「設定しない」になってる場合は、以下の手順５へ進んでください。
        </p>
        <h4>【５】「設定しない」になってる場合は「編集」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no01/04.png`" alt="" />
        <h4>
          【６】「自動タグ設定」を「設定する」にチェックを入れ、「保存」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no01/05.png`" alt="" />
        <h4>【７】「自動タグ設定」が「設定する」になります。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no01/06.png`" alt="" />

        <p>以上で、自動タグの設定がされています。</p>
        <p>
          アドタスカルと連携したい全てのアカウントで同様の設定を行ってください。
        </p>
        <p>
          次は、「<router-link to="/manual/yahoo-cv/03/02"
            >オフラインコンバージョンの作成</router-link
          >」に進みます。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
</style>
