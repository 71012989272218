<template>
  <div class="gadget-description">
    <el-row>
      <el-col>
        <h3>ガジェットとは</h3>
        <p>ガジェットとは、「今、まさに注文が入っている」「今、まさに・・・」という臨場感を演出することで、閲覧者のアクションへの意欲を煽るためのツールです。</p>
        <img :src="rt+'/img/description/gadget/test01.jpg'" />
        <p>
          ガジェットは、閲覧者には動いて見え、「今まさに・・」「多くの人が・・」ということを露骨に煽ることができます。
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>テンプレートのバリエーション</h3>
        <p>以下の3種類から選択肢、表示する商品名・価格・テキスト・画像などを自由に設定することができます。</p>
        <img :src="rt+'/img/description/gadget/test02.jpg'" />
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col>
        <h3>ガジェットによる効果</h3>
        <p>広告サイトによって、オーバーレイボタンのクリック率は異なりますが、当事例では、全体の訪問者数に対して、オーバーレイボタンを押したユーザーは全体の8.3%程度で、成約数は対前月比で+16.8%となりました。</p>
        <img :src="rt+'/img/description/gadget/test03.jpg'" />
      </el-col>
    </el-row> -->
    <EnabledDevice/>
    <el-row>
      <el-col>
        <h3>機能</h3>
        <p>
          ワンタグ設置・・・ガジェットしたいページへタグを１つ設置するだけで使用することができます。<br>
          配信オン・オフ設定・・・ボタン１つで、配信オン・オフを切り替えられるため、タグをその都度、外したり、設置したりする手間はありません。<br>
          ガジェット内に表示する情報の編集・・・表示するテキストをすべて自由に設定できます。画像も設定できます。
        </p>
      </el-col>
    </el-row>
    <!-- <el-row>
      <el-col>
        <h3>利用料金</h3>
        <p>未定</p>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import EnabledDevice from "@/components/Description/Parts/EnabledDevice.vue";

export default {
  name: "GadgetDescription",
  props: {},
  components: {
    EnabledDevice
  },
  data() {
    return {
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
