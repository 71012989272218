<template>
  <div class="manual-home">
    <el-row>
      <el-col>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    tool: String,
    category01: String
  },
  data() {
    return {
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>