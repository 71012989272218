<template>
  <div class="to-top-button">
    <div
      class="to-top-button"
      v-scroll-to="{
        el: '#app',
        container: 'body',
        duration: 750,
        easing: 'ease',
        offset: 0,
      }"
    >
      ▲
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToTopButton',
  props: {
  },
  data() {
    return {}
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    }
  }
}
</script>

<style lang="scss" scoped>
.to-top-button {
  position: fixed;
  right: 5%;
  bottom: 5%;
  width: 50px;
  height: 50px;
  background: var(--black);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border: solid 3px white;
  cursor: pointer;
  border-radius: 5px;
}
</style>