<template>
  <div class="affiliate-result">
    <h2>獲得ユーザーの利用状況（サマリー・個別）</h2>
    <el-row>
      <el-col>
        <DateRangePicker :dateRange.sync="dateRange" />
      </el-col>
    </el-row>
    <template v-if="dateRange.length !== 0">
      <el-row>
        <el-col>
          <RegistrationsAndPlans :dateRange="dateRange" />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <ToolUtilizationSummary :dateRange="dateRange" />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <AffiliatedUserList :dateRange="dateRange" />
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import DateRangePicker from "@/components/Affiliate/Ui/DateRangePicker.vue";
import RegistrationsAndPlans from "@/components/Affiliate/Status/RegistrationsAndPlans.vue";
import ToolUtilizationSummary from "@/components/Affiliate/Status/ToolUtilizationSummary.vue";
import AffiliatedUserList from "@/components/Affiliate/Status/AffiliatedUserList.vue";

export default {
  components: {
    DateRangePicker,
    RegistrationsAndPlans,
    ToolUtilizationSummary,
    AffiliatedUserList
  },
  props: {},
  data() {
    return {
      dateRange: []
    };
  },
  computed: {
  },
  created() {
    this.dateRange = this.g_getDateRange('thisMonth');
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>


