<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（afb）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/point-back`"
            >ASP自動連携設定</router-link
          >」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`"
          alt=""
        />
        <h4>【２】afbの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no09/02.png`" alt="" />
        <h4>【３】afbの担当者さんに以下の依頼文とセットで連絡します</h4>
        <p>
          ▼依頼文<br />
          -----------------------<br />
          アドタスカルとのポイントバック連携と成果テストをお願いいたします。<br />
          <br />
          （【２】でコピーしたをafbの通知URL）<br />
          <br />
          サイトID（SID）：連携したいサイトのサイトID<br />
          サイト名称：連携したいサイトのサイト名称<br />
          通知のタイミング：成果発生時のみ通知<br />
          失敗時の再送信：再送信あり<br />
          -----------------------
        </p>
        <h5>▼サイトIDとサイト名称</h5>
        <p>afbの管理画面の「登録情報管理」→「サイト情報」から確認できます。</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no09/06.png`" alt="" />
        <p>
          afbではASP登録サイト単位でアドタスカルの連携するので、連携したいサイトのIDと名称をコピーしてください。
        </p>
        <img :src="`${rt}/img/manual/google-cv/no08/no09/07.png`" alt="" />
        <h4>【４】連携が完了すると、afbさんでテストしてくれます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no09/03.png`" alt="" />
        </div>
        <h4>
          【８】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/auto-import-log`"
            >自動登録履歴</router-link
          >」を確認します。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`" alt="" />
        <h4>
          【９】数分後に、テストCVが登録されていれば問題なく連携完了です。
        </h4>
        <AutoImportLog 
          :aspId="'9'"
          :aspPromotionName="'3474'"
          :parameter="'adtasukaru_test'"
          :conversionValue="'10'"
        />
        <ul>
          <li>ASP：afb</li>
          <li>CV時間：afbさんでテストCVした時間</li>
          <li>プログラム名：-</li>
          <li>パラメータ：「adtasukaru_test」</li>
          <li>プログラムID： 3475、3474</li>
          <li>報酬額：5，10</li>
          <li>インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です</li>
        </ul>
        <h4>【１０】連携完了報告をしましょう。</h4>
        <p>
          無事、連携できたことを報告しましょう。これでアドタスカルとafbとの連携は完了です。
        </p>
        <h4>【１１】注意事項</h4>
        <p>afbはサイトID（SID）単位でアドタスカルと連携されます。そのため、新しいサイトを登録した際は、再度、通知URLの設定をご依頼ください。</p>
        <p>都度、アドタスカルとの連携がお手間でしたら、１つのサイトID（SID）から複数の案件のリンクを発行することも可能ということでした。</p>
        <p>その際は、担当さんに「○○（すでにアドタスカルと連携しているサイトID）でリンクの発行をお願いします」とお伝えください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    AutoImportLog,
  },
  props: {},
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
