<template>
  <div class="enable-device">
    <div class="hr-border-line"></div>
    <el-row type="flex" align="middle">
      <el-col :span="12">
        <h3>対応端末・ブラウザ</h3>
      </el-col>
      <el-col :span="12">
        <el-row type="flex" align="start">
          <el-col :span="14">
            <p>
              iPhoneXXXX
              <br />AndroidXXXX
              <br />WIndowsXXX
              <br />MACXXXX
            </p>
          </el-col>
          <el-col :span="10">
            <p>
              Chrome
              <br />safari
              <br />edge
            </p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="hr-border-line"></div>
  </div>
</template>

<script>

export default {
  name: "EnabledDevice",
  props: {
    tool: String
  },
  components: {},
  data() {
    return {
      tool_name: ""
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
h3 {
  font-size: 26px;
  padding-left: 30px;
}
.el-row {
  margin: 0;
}
</style>
