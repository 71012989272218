<template>
  <div class="ctrl-user">
    <h2>ユーザー情報</h2>
    <el-row :gutter="50" type="flex" justify="space-between" align="middle">
      <el-col :span="4">
        <router-link to="/ctrl">
          <Button3 msg="戻る" type="success" />
        </router-link>
      </el-col>
      <!-- <el-col :span="4">&nbsp;</el-col> -->
      <el-col :span="6">
        <el-button type="warning">アドレスの一括ダウンロード</el-button>
      </el-col>
      <el-col :span="12" type="flex" justify="space-between" align="middle">
        <el-row
          style="margin-bottom: 0"
          type="flex"
          justify="space-between"
          align="middle"
        >
          <el-col :span="4">苗字：</el-col>
          <el-col :span="6">
            <el-input placeholder="ミョウジ" v-model="reading1"></el-input>
          </el-col>
          <el-col :span="4">名前：</el-col>
          <el-col :span="6">
            <el-input placeholder="ナマエ" v-model="reading2"></el-input>
          </el-col>
          <el-col :span="4">
            <span class="search">
              <el-button type="primary" @click="search">検索</el-button>
            </span>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row style="margin-top: 50px">
      <el-col>
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>ID</th>
              <th>お名前</th>
              <th>ヨミガナ</th>
              <th>メールアドレス</th>
              <th>会社名</th>
              <th>当月購入金額</th>
              <th>累計購入金額</th>
              <th>利用期間</th>
              <th>詳細</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in userLists" :key="index">
              <td>
                {{ item.user_id }}
              </td>
              <td>
                {{ item.name }}
              </td>
              <td>
                {{ item.reading }}
              </td>
              <td>
                {{ item.email }}
              </td>
              <td>
                {{ item.company_name }}
              </td>
              <td>
                {{ item.purchase_price_month }}
              </td>
              <td>
                {{ item.purchase_price_total }}
              </td>
              <td>
                {{ item.use_term }}
              </td>
              <td>
                <router-link :to="'/ctrl/user-edit/' + item.user_id">
                  <el-button type="info" plain>確認</el-button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  data() {
    return {
      reading1: "",
      reading2: "",
      userLists: [],
    };
  },
  components: {
    Button3,
  },
  created() {},
  mounted() {
    //ユーザーの一覧を取得
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getUserList.php")
      .then((res) => {
        console.log(res);
        console.log(res.data.list);
        this.userLists = res.data.list;
        // this.List1 = res.data.list.list1;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    search: function () {
      let params = new URLSearchParams();
      params.append("searchFLG", true);
      params.append("reading1", this.reading1);
      params.append("reading2", this.reading2);
      this.$axios
        .post(this.$store.getters.rt + "/api/ctrl/getUserList.php", params)
        .then((response) => {
          console.log(response.data.list);
          this.userLists = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.search_flex {
  display: flex;
  .search {
    margin-left: 5px;
  }
}
</style>
