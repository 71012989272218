<template>
  <div class="tools02-tags-tag-list">
    <el-row>
      <el-col :span="4">
        <a :href="toolData.manualData.tagRegistrationManualUrl" target="_blank" rel="noopener noreferrer">
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <router-link to="tag-detail/0">
          <el-button type="primary" class="new-add">新規追加</el-button>
        </router-link>
      </el-col>
    </el-row>
    <el-row v-if="userData.bonusPointData[toolData.codeCamel].pixel === false">
      <el-col>
        <BonusAlert :toolCode="toolData.codeCamel" :bonusCode="'pixel'" boxStyle="fat" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <!-- ↓↓ Facebook ↓↓ -->
        <table v-if="toolData.codeKebab === 'facebook-cv'" class="typeA stripe">
          <thead>
            <tr>
              <th>ピクセル名</th>
              <th>ピクセルID</th>
              <th style="width: 50px"></th>
              <th style="width: 75px"></th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <tbody v-show="pixels.length > 0">
            <tr v-for="(pixel, index) in pixels" :key="index">
              <td>
                {{ pixel.pixelName }}
              </td>
              <td>
                {{ pixel.pixelIdForApi }}
              </td>
              <td>
                <router-link :to="`tag-detail/${pixel.pixelId}`">
                  <el-button type="">編集</el-button>
                </router-link>
              </td>
              <td>
                <router-link :to="`tag-test/${pixel.pixelId}`">
                  <el-button type="success">テスト送信</el-button>
                </router-link>
              </td>
              <td>
                <el-button @click="delTag(pixel.pixelId, pixel.pixelName)" type="danger">削除</el-button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- ↑↑ Facebook ↑↑ -->

        <!-- ↓↓ その他 ↓↓ -->
        <table v-else class="typeA stripe">
          <thead>
            <tr>
              <th class="tag-name">
                <span v-if="toolData.codeKebab === 'tiktok-cv'">ピクセル</span>
                <span v-else-if="toolData.codeKebab === 'line-cv'">LINE Tag</span>
                <span v-else-if="toolData.codeKebab === 'yda-cv'">コンバージョン</span>
                <span>名</span>
              </th>
              <th class="media-tag-id">
                <span v-if="toolData.codeKebab === 'tiktok-cv'">ピクセルID</span>
                <span v-else-if="toolData.codeKebab === 'line-cv'">LINE Tag ID</span>
                <span v-else-if="toolData.codeKebab === 'yda-cv'">yahoo_ydn_conv_io</span>
              </th>
              <th class="media-access-token" v-if="toolData.codeKebab === 'yda-cv'">
                <span>yahoo_ydn_conv_label</span>
              </th>
              <th class="edit" style="width: 50px"></th>
              <th v-if="toolData.codeKebab === 'tiktok-cv' ||
        toolData.codeKebab === 'line-cv'
        " class="fire" style="width: 50px"></th>
              <th class="test-send" v-if="toolData.codeKebab === 'tiktok-cv' ||
        toolData.codeKebab === 'line-cv'
        " style="width: 75px"></th>
              <th class="delete" style="width: 50px"></th>
            </tr>
          </thead>
          <tbody v-show="tagList.length > 0">
            <tr v-for="(tagData, index) in tagList" :key="index">
              <td>
                {{ tagData.name }}
              </td>
              <td :class="{
        red:
          toolData.codeKebab === 'yda-cv' &&
          tagData.mediaTagId !== firstYahooYdnConvIo,
      }">
                {{ tagData.mediaTagId }}
              </td>
              <td v-if="toolData.codeKebab === 'yda-cv'">
                {{ tagData.accessToken }}
              </td>
              <td>
                <router-link :to="`tag-detail/${tagData.id}`">
                  <el-button type="">編集</el-button>
                </router-link>
              </td>
              <td v-if="toolData.codeKebab === 'tiktok-cv' ||
        toolData.codeKebab === 'line-cv'
        ">
                <router-link :to="`tag-fire/${tagData.id}`">
                  <el-button type="warning">テスト発火</el-button>
                </router-link>
              </td>
              <td v-if="toolData.codeKebab === 'tiktok-cv' ||
        toolData.codeKebab === 'line-cv'
        ">
                <router-link :to="`tag-test/${tagData.id}`"><el-button type="success">テスト送信</el-button></router-link>
              </td>
              <td>
                <el-button @click="deleteTag(tagData.id, tagData.name)" type="danger">削除</el-button>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- ↑↑ その他 ↑↑ -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
import BonusAlert from "@/components/Ui/BonusAlert.vue";

export default {
  name: "TagList",
  components: {
    Button2,
    BonusAlert,
  },
  props: {
    toolId: String,
    accountId: String,
  },
  data() {
    return {
      firstYahooYdnConvIo: null,
    };
  },
  computed: {
    ...mapGetters(["rt", "userData", "apiErrorMessage"]),
    ...mapGetters("tools02", ["pixels"]),
    tagList() {
      return this.$store.getters["tools02/tagListInTool"](
        this.toolId,
        this.accountId
      );
    },
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {
    tagList: {
      handler(newTagList) {
        if (this.toolData.codeKebab === "yda-cv") {
          if (newTagList.length > 0) {
            this.firstYahooYdnConvIo = newTagList[0].mediaTagId;
          } else {
            // no action.
          }
        } else {
          // no action.
        }
      },
      immediate: true,
    },
  },
  methods: {
    deleteTag(tagId, tagName) {
      //
      let generalTagName = "タグ";
      switch (this.toolData.codeKebab) {
        case "yda-cv":
          generalTagName = "コンバージョン";
          break;

        default:
          // no action.
          break;
      }

      //
      let confirmText = `${generalTagName}名「${tagName}」の${generalTagName}情報を削除します。よろしいですか？`;
      if (confirm(confirmText)) {
        // no action.
      } else {
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("tag_id", tagId);
      this.$axios
        .post(`${this.rt}/l/api/tools02/tag/delete`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("tools02/setTagList");
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/");
          } else if (response.data.status === 440) {
            alert(response.data.message);
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ============================== ↓↓ Facebook専用 ↓↓ ==============================
    delTag(tagId, tagName) {
      // 
      let parentUnitList = [];
      for (const unitData of this.$store.getters['tools02/units']) {
        for (const pixelId of unitData.selectedPixels) {
          if (pixelId !== tagId) {
            // ok!
          } else {
            parentUnitList.push(unitData);
          }
        }
      }

      // 
      if (parentUnitList.length === 0) {
        // ok!
      } else {
        let message = ``;
        message += `以下のユニットに選択されているため削除できません。\n`;
        message += `ユニットで別のピクセルを選択するか、ユニットを削除してください。\n`;
        message += `\n`;
        for (const parentUnitData of parentUnitList) {
          message += `・${parentUnitData.unitName}（${parentUnitData.unitToken}）\n`;
        }
        alert(message);
        return;
      }

      // 
      if (
        confirm(
          `ピクセル名「${tagName}」のピクセル情報を削除します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("pixel_id", tagId);
        this.$axios
          .post(`${this.rt}/api/tools02/delPixel.php`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("tools02/setPixels");
              return;
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    // ============================== ↑↑ Facebook専用 ↑↑ ==============================
  },
};
</script>

<style lang="scss" scoped></style>
