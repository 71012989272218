<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>プログラム情報登録<br class="sp-only">（SSアフィリエイト）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a
            href="https://affiliate.sonicsense.jp/login.php"
            target="_blank"
            rel="noopener noreferrer"
            >管理画面</a
          >にログインしたら、「広告掲載」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no21/01.png`" alt="" />
        <h4>
          【２】登録したいプログラム名を探して、「広告名」をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no21/02.png`" alt="" />
        <h4>
          【３】登録したいプログラム名をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no21/03.png`" alt="" />
        <h4>
          【４】URL欄からプログラムIDをコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no21/04.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">
          --------------------------------
        </p>
        <h4>【５】アドタスカルのASPプログラム名とプログラムIDに貼り付け、報酬額も記入します。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no05/no21/05.png`" alt="" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  components: {
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
  created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
