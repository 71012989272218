<template>
  <div class="format0301">
    <div
      class="format-cover2"
      :style="$route.name === 'tools01-format' ? 'height: 600px;' : 'height: 100px;'"
    >
      <div
        class="overlay-background"
        :style="
          'height:' + format.height02 + 'px;background:' + format.color04 + ';'
        "
      >
        <div :class="'txt txt' + format_id">
          <div v-html="format.text01"></div>
          <div
            class="number-zone"
            :style="
              'font-size:' +
              format.int08 +
              'pt;color:' +
              format.color05 +
              ';font-weight:' +
              format.str02 +
              ';'
            "
          ></div>
          <div v-html="format.text02"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Format0301",
  props: {
    format: Object,
  },

  data() {
    return {
      amount_seed: [],
      second_seed: [],
      stop: 0,
      ms: 0,
      txt: 0,
      start: 0,
      format_id: "",
      yet: 0,
    };
  },
  watch: {},

  created() {
    //ここではまだpropsが来ていない→

    var seed = "1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var password = "";
    var length = 10;
    for (var i = 0; i < length; i++) {
      password += seed.charAt(Math.floor(Math.random() * seed.length));
    }
    this.format_id = password;

    this.start = parseInt(this.format.int02);
    this.$nextTick(() => {
      this.animate(this.format.type);
    });

    // this.$nextTick(function() {
    //   this.start = parseInt(this.format.int02);
    //   this.animate(this.format.type);
    //   alert(this.format.int02);
    // });
  },

  methods: {
    animate(type) {
      this.amount_seed.length = 0;
      this.second_seed.length = 0;

      for (let i = this.format.int04; i <= this.format.int05; i++) {
        this.amount_seed.push(i);
      }
      for (let i = this.format.int06; i <= this.format.int07; i++) {
        this.second_seed.push(i);
      }

      if (type == 1) {
        this.txt =
          this.amount_seed[Math.floor(Math.random() * this.amount_seed.length)];
      } else if (type == 2 || type == 3) {
        var amount =
          this.amount_seed[Math.floor(Math.random() * this.amount_seed.length)];
        if (type == 2) {
          this.start += parseInt(amount);
        } else if (type == 3) {
          this.start -= amount;
        }
        this.txt = this.start;
        if (this.format.type == 3 && this.start <= this.format.int03) {
          this.txt = this.format.int03;
          this.stop = 1;
        }
      }

      var second =
        this.second_seed[Math.floor(Math.random() * this.second_seed.length)];
      this.ms = second * 1000;
      var target = "animated " + this.format.str01;
      var number_zone = ".txt" + this.format_id + " .number-zone";

      $(number_zone).text(this.txt);
      $(number_zone)
        .addClass(target)
        .one("animationend", function () {
          $(number_zone).removeClass(target);
        });

      if (this.stop == 0) {
        setTimeout(
          function () {
            this.animate(this.format.type);
          }.bind(this),
          this.ms
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.format-cover2 {
  position: relative;
  width: 360px;
  background: white;
  margin: auto;
  .overlay-background {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .txt {
      display: flex;
      justify-content: center;
      align-items: center;
      .number-zone {
        margin: 0 5px;
      }
    }
  }
}
</style>