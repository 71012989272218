<template>
  <div class="domains">
    <el-row>
      <el-col>
        <h2>ドメイン一覧</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <DomainList :toolId="toolId"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DomainList from "@/components/Domains/DomainList.vue";

export default {
  components: {
    DomainList,
  },
  props: {
    toolId: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  watch: {},
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
