import { render, staticRenderFns } from "./UnitList.vue?vue&type=template&id=95d21272&scoped=true&"
import script from "./UnitList.vue?vue&type=script&lang=js&"
export * from "./UnitList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95d21272",
  null
  
)

export default component.exports