import { render, staticRenderFns } from "./TagDetail.vue?vue&type=template&id=30e5f27e&scoped=true&"
import script from "./TagDetail.vue?vue&type=script&lang=js&"
export * from "./TagDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e5f27e",
  null
  
)

export default component.exports