<template>
  <div class="tools02-tag-fire-tag-fire-editor">
    <el-row>
      <el-col :span="4">
        <a
          :href="toolData.manualData.tagFireManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <table class="typeB">
          <tr>
            <th>
              <span>{{ g_getTools02TagCategoryName(toolId) }}名</span>
            </th>
            <td>{{ tagData.name }}</td>
          </tr>
          <tr>
            <th>
              <span>
                <span
                  v-if="
                    tool.codeKebab === 'facebook-cv' ||
                    tool.codeKebab === 'tiktok-cv'
                  "
                  >ピクセルID</span
                >
                <span v-else-if="tool.codeKebab === 'line-cv'"
                  >LINE Tag ID</span
                >
                <span v-else-if="tool.codeKebab === 'yda-cv'"
                  >yahoo_ydn_conv_io</span
                >
              </span>
            </th>
            <td>{{ tagData.mediaTagId }}</td>
          </tr>
          <tr v-if="toolData.codeKebab === 'tiktok-cv'">
            <th>
              <span>CVイベント</span>
            </th>
            <td>
              <el-select
                v-model="tiktokEvent"
                placeholder="選択してください。"
                style="width: 330px"
                clearable
              >
                <el-option
                  v-for="(tiktokEventData, index) in tiktokEventList"
                  :key="index"
                  :label="tiktokEventData.name + `（${tiktokEventData.event}）`"
                  :value="tiktokEventData.event"
                ></el-option>
              </el-select>
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col>
        <div class="button-cover">
          <a
            :href="`${rt}/tests/tools02/media/${toolData.codeKebab}/pixel-fire.php?id=${tagData.mediaTagId}&event=${tiktokEvent}`"
            target="_blank"
            rel="noopener noreferrer"
            ><el-button @click="fire" type="warning">発火</el-button></a
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "Tools02TagFireTagFireEditor",
  components: {
    Button2,
  },
  props: {
    toolId: String,
    accountId: String,
    tagId: String,
  },
  data() {
    return {
      tagData: {
        name: "",
        mediaTagId: "",
        accessToken: "",
        testCode: "",
      },

      //
      tiktokEvent: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    ...mapGetters("tools02", ["tiktokEventList"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {
    tagId: {
      handler(newTagId) {
        this.tagData = JSON.parse(
          JSON.stringify(
            this.$store.getters["tools02/tagData"](this.toolId, newTagId)
          )
        );
      },
      immediate: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.button-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
