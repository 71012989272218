<template>
  <el-row>
    <el-col>
      <el-button
        @click="showImageEditor = true"
        class="image-button"
        size="medium"
        ><i class="el-icon-picture-outline"></i>メディアを追加</el-button
      >
      <Editor
        v-model="localText"
        api-key="jg024mzr4orehm1c1b1y7jfmhqrz1j9oyqz6931bnf8rpirh"
        :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo | redo | forecolor backcolor fontsizeselect | bold underline',

          //入力時に改行が<p>にならないように
          force_br_newlines: true,
          force_p_newlines: false,
          forced_root_block: '',

          //テキストからコピペするときに改行が<p>にならないように
          paste_as_text: true,
        }"
      />

      <div v-show="showImageEditor === true">
        <div
          @click="showImageEditor = false"
          class="image-editor-background"
        ></div>
        <div class="image-editor">
          <div class="image-editor-header">
            <h3>メディアを追加</h3>
            <div @click="showImageEditor = false" class="close-button">×</div>
          </div>
          <el-tabs type="card" v-model="imageEditorTab">
            <el-tab-pane label="ファイルをアップロード" name="upload">
              <div class="file-select-button-cover">
                <label for="file-select">
                  <div class="file-select-button">ファイルを選択</div>
                </label>
                <p>最大アップロードサイズ: 1 MB。</p>
              </div>
              <input
                style="display: none;"
                id="file-select"
                name="file"
                type="file"
                @change="uploadImage($event)"
                accept="image/*"
              />
            </el-tab-pane>
            <el-tab-pane label="メディアライブラリ" name="images">
              <p style="text-align: center">画像一覧</p>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "TinyMceEditor",
  components: {
    Editor,
  },
  props: {
    text: String
  },
  data() {
    return {
      showImageEditor: false,
      imageEditorTab: 'upload',

      // props
      textString: this.text
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    localText: {
      get() {
        return this.textString;
      },
      set(newValue) {
        this.$emit('update:text', newValue);
      }
    }
  },
  watch: {
    propsText(newValue) {
      this.textString = newValue
    },
    textString(newValue) {
      this.$emit('text', newValue)
    }
  },
  methods: {
    uploadImage(event) {
      let formData = new FormData();
      formData.append("uploaded_file_data", event.target.files[0]);
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(`${this.rt}/api/upload-image.php`, formData, config)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.imageEditorTab = 'images';
            return;
          } else if (res.data.status === 204) {//ファイル未選択
            return;
          } else if (res.data.status === 400 || res.data.status === 500) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-button {
  margin-bottom: 5px;
}

//image-editor
.image-editor-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.image-editor {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 95%;
  height: 95%;
  background-color: #fff;
  .image-editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin: 10px 20px;
    }
    .close-button {
      font-size: 24px;
      font-weight: bold;
      margin-right: 15px;
      cursor: pointer;
    }
  }
  .file-select-button-cover {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .file-select-button {
      font-size: 18px;
      padding: 15px 30px;
      color: #0071a1;
      background: #f3f5f6;
      border: solid 1px #0071a1;
      border-radius: 5px;
      opacity: 0.9;
      &:hover {
        opacity: 1.0;
      }
    }
    p {
      font-size: 14px;
    }
  }
}
</style>
