<template>
  <div class="qa">
    <el-row>
      <el-col>
        <h2>よくある質問</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <QAQAList/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import QAQAList from "@/components/QA/QAList.vue";

export default {
  components: {
    QAQAList
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
