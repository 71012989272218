<template>
  <div class="affiliate-result">
    <h2>アフィリエイト報酬・成果確認</h2>
    <el-row>
      <el-col>
        <DateRangePicker ref="daterange" :dateRange.sync="dateRange" />
        <p>
          {{ displayDateRange[0] }}～{{
            displayDateRange[1]
          }}のアフィリエイト報酬と成果を表示しています。
        </p>
      </el-col>
    </el-row>
    <template v-if="dateRange.length !== 0">
      <el-row>
        <el-col>
          <Rewards :dateRange="dateRange" />
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <Achievements :dateRange="dateRange" />
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import DateRangePicker from "@/components/Affiliate/Ui/DateRangePicker.vue";
import Rewards from "@/components/Affiliate/Result/Rewards.vue";
import Achievements from "@/components/Affiliate/Result/Achievements.vue";

export default {
  components: {
    DateRangePicker,
    Rewards,
    Achievements,
  },
  props: {},
  data() {
    return {
      dateRange: [],
      displayDateRange: [],
    };
  },
  computed: {},
  watch: {
    dateRange(newDateRange) {
      this.displayDateRange = [];
      for (let index = 0; index < newDateRange.length; index++) {
        let date = new Date(newDateRange[index]);
        let newDate =
          date.getFullYear() +
          "年" +
          (date.getMonth() + 1) +
          "月" +
          date.getDate() +
          "日";
        this.displayDateRange.push(newDate);
      }
    },
  },
  created() {
    this.dateRange = this.g_getDateRange("thisMonth");
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(table.af-pop) {
  tr {
    th,
    td {
      width: 25%;
    }
  }
}
:deep(table.two-d) {
  tr {
    th,
    td {
      width: 25%;
    }
  }
}
</style>
