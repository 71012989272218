<template>
  <div class="popup-description01">
    <h3 class="title">離脱防止ポップアップとは</h3>
    <p>
      離脱防止ポップアップとは、広告サイトを訪問しているユーザーが広告サイトから去ろうとし、ブラウザの「戻るボタン」を押した際ポップアップを表示することで、ユーザーの広告サイトからの離脱を防止し、広告のパフォーマンスを向上させるためのツールです。
    </p>
    <div class="popup01-01">
      <img :src="rt + '/img/description/popup01/popup_step02.png'" />
    </div>

    <h3 class="title">離脱防止ポップアップのポイント</h3>
    <div class="popup-point">
      <img :src="rt + '/img/description/popup01/popup_step03.png'" />
      <div class="txt">
        <p class="number">Point<span>01</span></p>
        <p class="point-txt">
          見てほしいページやコンテンツへのリンクを自由に設定できる！
        </p>
        <p class="number">Point<span>02</span></p>
        <p class="point-txt">
          ポップアップに設定したリンク先をクリックせず、閉じるボタンから閉じた場合も、ページ内にアクセスが残る！
        </p>
      </div>
    </div>
    <p>
      さらには、ユーザーの行動に合わせて、表示するポップアップやリンク先を変更することもできます。ファーストビューで離脱したユーザーにはポップアップAを表示し、ページの途中で離脱したユーザーにはポップアップBを表示し、ページ下部まで到達したユーザーにはポップアップCを表示する、というように、1ページにき最大で3個のポップアップを設定することができます。
    </p>

    <div class="yajirushi-mark">
      <img :src="rt + '/img/description/popup01/yajirushi_mark.png'" />
    </div>

    <img
      class="secchi-img"
      :src="rt + '/img/description/popup01/secchi_img.png'"
    />
    <p>
      そして、ユーザーが到達した最下部の位置を判別してポップアップを表示する設定と、ユーザーが離脱時に滞在していた位置を判別してポップアップを表示する設定を選択することもできます。
    </p>

    <div class="ResultAndEffect">
      <p class="title">アドタスカルは成果計測とA/Bテスト機能で改善効果◎</p>

      <p class="pin-style">
        アドタスカルのポップアップは、コンバージョン測定機能、A/Bテスト機能、パラメータ保持機能があるため、成果計測と改善がしやすいのも大きな特徴です。
      </p>

      <Template02
        :TemplateTitle="'コンバージョン測定機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'コンバージョン測定機能で発行したコンバージョンタグをサンクスページに設置することで、コンバージョンの計測をすることができます。'"
      />
      <Template02
        :TemplateTitle="'A/Bテスト機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'アドタスカルはA/Bテスト機能を搭載しているため、テストによってパフォーマンス改善を追求することができます。A/Bテスト機能を利用することで、広告スキルやコンテンツ制作スキルもアップします。'"
      />
      <Template02
        :TemplateTitle="'パラーメター保持機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'パラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入る'"
      />
    </div>

    <h3 class="title">機能について</h3>
    <PopupFunction />
  </div>
</template>

<script>
import Template02 from "@/components/Description/Parts/Template02.vue";
import PopupFunction from "@/components/Description/Parts/PopupFunction.vue";

export default {
  name: "Popup01Description",
  components: {
    Template02,
    PopupFunction,
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 20px;
}
.popup01-01 {
  text-align: center;
  margin: 50px 0 120px;
  img {
    width: 550px;
  }
}
.popup-point {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  .txt {
    width: 750px;
    .number {
      font-size: 25px;
      padding-bottom: 5px;
      display: inline;
      background: linear-gradient(transparent 50%, #fdef6a 50%);
      span {
        font-size: 38px;
        margin-left: 10px;
        font-weight: bold;
      }
    }
    .point-txt {
      font-weight: bold;
      font-size: 28px;
      color: #1c6db5;
      margin-bottom: 30px;
    }
  }
  img {
    width: 310px;
  }
}

.yajirushi-mark {
  text-align: center;
  margin: 60px 0 30px;
  img {
    width: 130px;
  }
}
.secchi-img {
  margin-bottom: 50px;
}
.pin-style {
  margin-bottom: 50px;
}

.ResultAndEffect {
  margin: 120px 0;
}
</style>