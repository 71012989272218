<template>
  <div class="test-point-back">
    <h4>自動インポート</h4>
    <el-button @click="pointBack('local')" type="primary">Local</el-button>
    <el-button @click="pointBack('web')" type="primary">WEB</el-button>
    <h4>レコード削除</h4>
    <el-button @click="deleteImportedRecords('local')" type="danger"
      >Local</el-button
    >
    <el-button @click="deleteImportedRecords('web')" type="danger"
      >WEB</el-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TestPointBack",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  methods: {
    pointBack(env) {
      this.$axios
        .post(`${this.rt}/api/test/point-back.php?env=${env}`)
        .then((res) => {
          console.log(res);
          res.data.pointBackDataList.forEach((pointBackData) => {
            console.log(pointBackData.url);
            window.open(pointBackData.url, "_blank");
          });
          return;
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    deleteImportedRecords(env) {
      let rootUrl;
      if (env === "local") {
        rootUrl = "http://adtasukaru.localhost";
      } else if (env === "web") {
        rootUrl = "https://adtasukaru.com";
      } else {
        alert("???");
        return;
      }
      this.$axios
        .get(`${rootUrl}/api/test/tools02/import/del-cv-report.php`)
        .then((res) => {
          console.log(res);
          alert("削除完了");
          return;
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.new-add {
  margin-top: 20px;
}
</style>
