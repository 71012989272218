<template>
  <div class="tools02-auto-import-log">
    <el-row>
      <el-col>
        <h2>自動登録履歴</h2>
      </el-col>
    </el-row>
    <!-- <Tools02AutoImportLogLogTable :type="'ctrl'"/> -->
    <PointBackHistoryList :type="'ctrl'"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Tools02AutoImportLogLogTable from "@/components/Tools02/AutoImportLog/LogTable.vue";
import PointBackHistoryList from "@/components/Tools02/AutoImportLog/PointBackHistoryList.vue";

export default {
  components: {
    // Tools02AutoImportLogLogTable,
    PointBackHistoryList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.manual-button-cover {
  @media screen and (max-width: 767px) {
    display: none;
  }
  .manual-button {
    margin-bottom: 20px;
  }
}
</style>
