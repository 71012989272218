<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>リピストとアドタスカルを連携する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3 id="01">手順</h3>
        <span>以下の設定をすることで、リピストとアドタスカルを連携できます。リピストには手動インポート機能がないため、自動インポートのみの対応となります。</span>
        <ul>
          <li><a href="#01">自動連携設定</a></li>
          <li><a href="#02">プログラム情報登録</a></li>
          <li><a href="#03">パラメータ引き継ぎ設定</a></li>
        </ul>
        <h3>自動連携設定</h3>
        <h4>
          【１】アドタスカルのいずれかの広告成果連携ツールの「ASP自動連携設定」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/no12/no03/01.png`"
          alt=""
        />
        <h4>【２】リピストの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no12/no03/02.png`" alt="" />
        <h4>【３】クライアントのリピスト管理画面にて、ポイントバック通知の設定をしてもらうようにマニュアルと通知URLを渡す</h4>
        <p>上記【２】で取得したリピストの通知URLと、以下のソケット連携機能の設定マニュアルをクライアントに提供して、ポイントバック通知の設定をしてもらいます。</p>
        <p>
          <a href="https://www.evernote.com/l/AB-rCPqTnd1OboJdbiVCCkAC6V9-DASuNrg/" target="_blank">https://www.evernote.com/l/AB8BEeM2vZBF-qReXYdgmNne4S-rz-64iFI/</a><br />
          クライアントによってリピスト側の設定が異なる可能性があるので、不明点ありましたらご連絡ください。<br />
          特に「ADコード」の設定箇所が異なるかもしれません。
        </p>
        <p id="02">設定完了の報告を受けましたら、引き続きの設定を行います。</p>
        <h3>プログラム情報登録</h3>
        <h4>【４】連携を設定する成果連携ツールの「広告アカウント&ASPプログラム情報登録」をクリックします。</h4>
        <p>ここではGoogle広告成果連携ツールの画面で説明していきます。</p>
        <img :src="`${rt}/img/manual/google-cv/no12/no03/03.png`" alt="" />
        <h4>【5】「プログラム情報登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no12/no03/04.png`" alt="" />
        <p>※まだGoogle広告アカウントを登録していない場合は、Google広告アカウントの登録をお願いします。</p>
        <h4>【６】「プログラム新規登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no12/no03/05.png`" alt="" />
        <h4>【７】以下のようにプログラム情報を入力します。</h4>
        <img :src="`${rt}/img/manual/google-cv/no12/no03/06.png`" alt="" />
        <h3 id="03">パラメータ引き継ぎ設定</h3>
        <h4>【８】以下のようにプログラム情報を入力します。</h4>
        <p>プログラム登録で設定したプログラムIDをアフィリコードに「<span class="red" style="font-weight: bold;">&p1=○○</span>」という形で付与します。</p>
        <p>プログラムID：sulabody</p>
        <p>↓</p>
        <p>https://andonestore.jp/lp?u=sulabody_saru_t_1_a4_fb<span class="red" style="font-weight: bold;">&p1=sulabody</span></p>
        <p>このURLを記事に設置してください。</p>
        <p>また、アフィリコードごとにパラメータ引き継ぎの設定が必要なためアフィリコードのドメインを教えてください。</p>
        <h4>【注意事項】</h4>
        <p>リピストの場合、手動インポートができないため自動インポートのみの対応となります。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  components: {
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
