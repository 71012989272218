<template>
  <div class="tools02-units">
    <el-row>
      <el-col>
        <h2>ユニット一覧</h2>
      </el-col>
    </el-row>

    <Tools02UnitsUnitList />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02UnitsUnitList from "@/components/Tools02/Units/UnitList.vue";

export default {
  components: {
    Tools02UnitsUnitList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
