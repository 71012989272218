<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>パラメータログを確認する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            パラメータ引継ぎタグが正常に設置できているかチェックすること。
          </li>
          <li>パラメータログの見方を覚えること。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p v-if="tool.codeKebab === 'facebook-cv'">5分</p>
        <p v-else>3分</p>
      </el-col>
    </el-row>
    <el-row v-if="tool.codeKebab === 'facebook-cv'">
      <el-col>
        <h4>【１】ご自身の記事LPを開きます。</h4>
        <h4>【２】記事LPのURLの末尾に「?fbclid=test」を付けます。</h4>
        <div class="mini-img">
          <img
            :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/01.png`"
            alt
          />
        </div>
        <p>（例）</p>
        <p>https://bi-body-navi.net/archives/271</p>
        <p>↓</p>
        <p>
          https://bi-body-navi.net/archives/271
          <span class="red" style="font-weight: bold">?fbclid=test</span>
        </p>
        <p>
          ※WordPressのURL設定ですでに「?●●=△△」が付いている場合は、「
          <span class="red">&amp;fbclid=test</span>」にしましょう。
        </p>
        <h4>
          【３】パラメータを付けた状態で、URLバーをクリックして選択し、キーボードの[ENTER]を押します。
        </h4>
        <div class="mini-img">
          <img
            :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/02.png`"
            alt
          />
        </div>
        <h4>
          【４】アドタスカルのタグが読み込まれると、自動で「atid」「utAT」「bIdAT」「ckAT」のパラメータが記事LPのURLに付与されます。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/03.png`" alt />
        <p>
          もし、以下の現象が発生した場合は、パラメータ設定が正常に行えていないので、「
          <router-link :to="`/manual/${tool.codeKebab}/06/02`"
            >パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。
        </p>
        <ul>
          <li>「atid」が付与されていない ⇒ LPタグが正常に設置されていない</li>
          <li>
            「utAT」「bIdAT」「ckAT」が付与されていない ⇒
            セーフティータグが正常に設置されていない
          </li>
        </ul>
        <h4>
          【５】記事LPに設置しているアフィリリンク、または、中間ページのリンクをクリックします。
        </h4>
        <div class="mini-img">
          <img
            :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/04.png`"
            alt
          />
        </div>
        <h4>【６】正常にクライアントLPに遷移することを確認します。</h4>
        <p class="kokokara">
          --------------------------------<br
            class="sp-only"
          />ここからアドタスカル<br
            class="sp-only"
          />--------------------------------
        </p>
        <h4>
          【７】次に、「
          <router-link :to="`/tools02/${tool.codeKebab}`"
            >アドタスカルの管理画面</router-link
          >」から「
          <router-link :to="`/tools02/${tool.codeKebab}/parameter-log`"
            >パラメータログ</router-link
          >」をクリックします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/parameter-log.png`"
          alt
        />
        <h4>
          【８】パラメータログの画面で、以下のようにアドタスカルのパラメータが付与されていることを確認します。
        </h4>
        <p>リファラ：「atid」「utAT」「bIdAT」「ckAT」</p>
        <p>飛び先URL：「atid」</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/05.png`" alt />
        <p>
          以下の現象が発生した場合は、パラメータ引継ぎタグの設定が正常に行えていないので、「
          <router-link :to="`/manual/${tool.codeKebab}/06/02`"
            >パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。
        </p>
        <ul>
          <li>パラメータログに載ってこない ⇒ LPタグが正常に設置されていない</li>
          <li>「atid」が付与されていない ⇒ LPタグが正常に設置されていない</li>
          <li>
            「utAT」「bIdAT」「ckAT」が付与されない ⇒
            セーフティータグが正常に設置されていない
          </li>
        </ul>
        <h4>【９】成果が発生するとASPの管理画面でatidが表示されます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/06.png`" alt />
        <p>以上で、パラメータ引継ぎ設定は完了です。</p>
        <p>
          「<router-link :to="`/manual/${tool.codeKebab}/11/09`"
            >コンバージョンAPIで最適化する方法</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
    <el-row v-else>
      <el-col>
        <h4>【１】ご自身の記事LPを開いてください。</h4>
        <p>（例）https://bi-body-navi.net/archives/271</p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no06/no04/01.png`" alt />
        </div>
        <h4>
          【２】URLの末尾に「
          <span class="red">?{{ tool.clickIdName }}=test</span
          >」を付けてください。
        </h4>
        <p>（例）</p>
        <p>https://bi-body-navi.net/archives/271</p>
        <p>↓</p>
        <p>
          https://bi-body-navi.net/archives/271
          <span class="red" style="font-weight: bold"
            >?{{ tool.clickIdName }}=test</span
          >
        </p>
        <div style="margin-top: 70px">
          <p>
            ※WordPressのURL設定ですでに「?●●=△△」が付いている場合は、「
            <span class="red">&amp;{{ tool.clickIdName }}=test</span
            >」にしましょう。
          </p>
        </div>
        <div class="mini-img">
          <img
            :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/02.png`"
            alt
          />
        </div>
        <h4>【３】パラメータを付けたら[ENTER]を押してください。</h4>
        <h4>【４】設置したアフィリリンクをクリックします。</h4>
        <div class="mini-img">
          <img
            :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/03.png`"
            alt
          />
        </div>
        <h4>【５】正常にクライアントLPに遷移することを確認します。</h4>
        <p class="kokokara">
          --------------------------------<br
            class="sp-only"
          />ここからアドタスカル<br
            class="sp-only"
          />--------------------------------
        </p>
        <h4>
          【６】次に、「
          <router-link :to="`/${tool.codeKebab}`"
            >アドタスカルの管理画面</router-link
          >」から「
          <router-link :to="`/tools02/${tool.codeKebab}/parameter-log`"
            >パラメータログ</router-link
          >」をクリックします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/parameter-log.png`"
          alt
        />
        <h4>【７】先程、クリックした記事LPとアフィリリンクを確認します。</h4>
        <p>
          アフィリリンクに【２】で付けたパラメータが付いていれば、パラメータの引継ぎ設定は完了です。
        </p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/04.png`" alt />
        <p>
          もし、以下の現象が発生した場合は、パラメータ設定が正常に行えていないので、「
          <router-link :to="`/manual/${tool.codeKebab}/06/02`"
            >パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。
        </p>
        <ul>
          <li>パラメータログの飛び先URLにパラメータが引き継げない</li>
          <li>パラメータログに載ってこない</li>
        </ul>
        <h4>
          【８】実際に、{{ tool.mediaName }}経由のアクセスの場合、以下のように{{
            tool.clickIdName
          }}が付与されます。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/05.png`" alt />
        <p>
          成果が発生するとASPの管理画面で{{ tool.clickIdName }}が表示されます。
        </p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no04/06.png`" alt />
        <p>これでパラメータ引継ぎ設定は完了です。</p>
        <p>実際に、運用開始しましょう。</p>
        <p>
          成果が発生したら、「
          <router-link :to="`/manual/${tool.codeKebab}/07/00`"
            >ASPの成果データをダウンロードする</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
