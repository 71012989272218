<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（アフィリコードシステム）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CommonDescription :systemType="'affilicode'" />
        <h4>
          【１】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/point-back`"
            >ASP自動連携設定</router-link
          >」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`"
          alt=""
        />
        <h4>【２】該当するASPの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no19/01.png`" alt="" />
        <h4>【３】アフィリコードシステムは、メディアごとに通知URLの連携が必要です。アドタスカルと連携したいメディア名と通知URLを担当者に連絡します。</h4>
        <p>
          -----------------------<br />
          アドタスカルのキックバック連携、希望です。<br />
          <br />
          連携したいメディア名：●●<br />
          キックバック通知URL：（【２】でコピーした通知URL）<br />
          -----------------------
        </p>
        <h4>＜メディア名の確認方法＞</h4>
        <p>
          @tentionの管理画面＞「掲載メディア」＞「メディア一覧」からご確認ください。
        </p>
        <img :src="`${rt}/img/manual/google-cv/no08/no19/02.png`" alt="" />
        <h4>【４】連携が完了したら、アフィリエイトリンクに「<span class="green">&pbid={{tool.clickIdName}}_xxx</span>」を追記してください。</h4>
        <h5>▼アフィリエイトリンク</h5>
        <p class="blue">https://atmarktension.com/link.php?i=●●●&m=●●●</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p class="blue">
          https://atmarktension.com/link.php?i=●●●&m=●●●<span class="green">&pbid={{tool.clickIdName}}_xxx</span>
        </p>

        <p>※自分で付けたパラメータを覚えておきましょう</p>
        <h4>
          【５】パラメータを付けたテストURLにアクセスし、テスト規定に従って購入を完了させてください。
        </h4>

        <h4>
          【６】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/auto-import-log`"
            >自動登録履歴</router-link
          >」を確認します。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`"
          alt=""
        />

        <h4>
          【７】数分後に、テストCVが登録されていれば問題なく連携完了です。
        </h4>
        <AutoImportLog 
          :aspId="'24'"
          :aspPromotionName="'5e6f2e31c7931'"
        />
        <ul>
          <li>ASP：@tension</li>
          <li>
            CV時間：テストした時間になっているか？（多少の誤差はあり）
          </li>
          <li>プログラム名：-</li>
          <li>パラメータ：【４】で付けたパラメータと一致しているか？</li>
          <li>プログラムID：画像の様な英数字が表示されているか？</li>
          <li>報酬額：0</li>
          <li>
            インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です。
          </li>
        </ul>

        <h4>【８】連携完了</h4>
        <p>担当さんに一報入れましょう。これで連携は完了です。</p>

        <PointbackSettingDescription :aspId="'24'" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonDescription from "@/components/Manual/Tools02/Parts/CommonDescription.vue";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    CommonDescription,
    PointbackSettingDescription,
    AutoImportLog,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.blue {
  color: var(--blue);
}
.green {
  color: var(--green);
}
</style>
