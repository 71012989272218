<template>
  <span class="question">
    <el-tooltip effect="dark" :placement="placement">
      <div slot="content" v-html="content"></div>
      <i class="el-icon-question"></i>
    </el-tooltip>
  </span>
</template>

<script>
export default {
  name: 'Question',
  props: {
    placement: String,
    content: String
  }
}
</script>

<style lang="scss" scoped>
.el-tooltip {
  max-width: 300px;
  cursor: help;
  margin: 0 5px;
}
</style>