<template>
  <div class="manual-tools02-07-00">
    <el-row>
      <el-col>
        <h2>ASPの成果データをダウンロードする</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>成果が発生したら、アドタスカルに登録するためにASPの成果データ（CSV）が必要になります。</li>
          <li>各ASPの成果データ（CSV）のダウンロード方法を理解しましょう。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <p>ASPごとに手順が異なるので、ご自身が使っているASPをクリックしてください。</p>
        <ul>
          <li
            v-for="(manualData, index) in manualList"
            :key="index"
            v-show="manualData.name !== ''"
          >
            <router-link
              :to="`/manual/${tool.codeKebab}/${manualData.cate01}/${manualData.cate02}`"
            >{{ manualData.name }}</router-link>
          </li>
        </ul>
        <p>
          ASPから成果データがダウンロードできたら、「
          <router-link :to="`/manual/${tool.codeKebab}/07/14`">ASPの成果データをアドタスカルにインポートする</router-link>」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "tools",
      "asps",
      "apiErrorMessage",
      "breadCrumbList",
    ]),
    manualList() {
      let manualSectionData = this.tools[this.tool.codeCamel].manualSectionList.find(
        (sectionData) => sectionData.section === "i"
      )

      let manualList = manualSectionData.manualList;

      let additionalManualList = [
        {
          cate01: "07",
          cate02: "06",
          kubun: "ASP→アドタスカル",
          name: "BULK-AD"
        },
        {
          cate01: "07",
          cate02: "06",
          kubun: "ASP→アドタスカル",
          name: "SIXPACK-C"
        },
        {
          cate01: "07",
          cate02: "20",
          kubun: "ASP→アドタスカル",
          name: "@tension"
        },
        {
          cate01: "07",
          cate02: "20",
          kubun: "ASP→アドタスカル",
          name: "Tempura-Link"
        },
        {
          cate01: "07",
          cate02: "20",
          kubun: "ASP→アドタスカル",
          name: "twowin"
        }
      ];

      additionalManualList.forEach(additionalManualData => {
        manualList.push(additionalManualData);
      });
      return manualList;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
h4 {
  margin-top: 40px;
  margin-bottom: 15px;
}

img {
  width: 95%;
  margin-left: 40px;
  border: 3px solid gray;
}
</style>
