<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>アップロードした成果を<br class="sp-only">確認する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            {{
              tool.mediaName
            }}アカウントへアップロードが完了したら、実際に反映されているか確認しましょう。
          </li>
          <li>
            概ね、数時間以内～半日以内には反映されます。<br />※ただ、新しく作成したばかりのオフラインコンバージョンに関しては少し時間がかかることが多いです。
          </li>
        </ul>
        <h3>【所要時間】</h3>
        <p>5分</p>
      </el-col>
      <el-col>
        <div v-if="tool.codeKebab === 'google-cv'">
          <h4>【１】「ツール」→「測定」→「コンバージョン」</h4>
          <img :src="`${rt}/img/manual/google-cv/no07/no17/01.png`" alt="" />
          <h4>【２】表示した期間を選択します。</h4>
          <p>「すべてのコンバージョン」欄に反映されたCV数が表示されます。</p>
          <img :src="`${rt}/img/manual/google-cv/no07/no17/02.png`" alt="" />
        </div>
        <div v-else-if="tool.codeKebab === 'yahoo-cv'">
          <h4>【１】「ツール」横の「▼」→「コンバージョン測定」の順にクリックします。</h4>
          <img :src="`${rt}/img/manual/yahoo-cv/no07/no17/01.png`" alt="" />
          <h4>【２】表示したい期間を選択します。CV数と報酬額が反映されます。</h4>
          <p>コンバージョン作成時に「自動入札への利用」を「する」にした場合、「コンバージョン数」「コンバージョンの価値」にも反映されます。</p>
          <p>「すべてのコンバージョン数」「すべてのコンバージョンの価値」は設定関係なく反映されます。</p>
          <img :src="`${rt}/img/manual/yahoo-cv/no07/no17/02.png`" alt="" />
          <h4>【３】キャンペーン、広告グループ、広告、キーワード、検索クエリー、ターゲティングでも確認できます。</h4>
          <img :src="`${rt}/img/manual/yahoo-cv/no07/no17/03.png`" alt="" />
          <h4>【補足】コンバージョン数関連の項目が表示されていない場合</h4>
          <p>①「表示」→「表示項目の編集」の順にクリックする。</p>
          <img :src="`${rt}/img/manual/yahoo-cv/no07/no17/04.png`" alt="" />
          <p>②確認したい項目にチェックをいれて、「適用」をクリックしてください。（「この設定に名前をつけて保存」をしておくと便利です。）</p>
          <img :src="`${rt}/img/manual/yahoo-cv/no07/no17/05.png`" alt="" />
          <p>これでCV数を確認することができます。</p>
        </div>
        <h4>アップロードしたCV数と一致しない場合</h4>
        <p>
          反映される日時は広告がクリックされた日付で表示されます。なので、成果日時と若干ずれる可能性がございます。
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>
          これで手動コースは終了です。最低でも１日１回は、{{tool.mediaName}}へアップロードしてください。
        </p>
        <p>
          手動コースでも、ASPの成果を{{tool.mediaName}}のテンプレートに移し替える手間が省けるので、非常に効率化できますし、ミスも無くせます。
        </p>
        <p>
          といっても、毎日行うとなるとめんどくさいですし、ASP数が多いともっとめんどくさいですよね。
        </p>
        <p>そこで、自動コースの設定を行ってください。</p>
        <p>
          まずは、「<router-link :to="`/manual/${tool.codeKebab}/08/00`"
            >ASPとアドタスカルを連携する</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <router-link :to="`/manual/google-cv/07/21`">【よくある質問】キャンペーンにオフラインCVが反映されません。</router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
