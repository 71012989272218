<template>
  <div class="tiktok-conversion-api-history-list">
    <el-table :data="conversionApiHistoryList" border>
      <el-table-column
        fixed
        label="API送信時間"
        min-width="160px"
        prop="sendDatetime"
      >
      </el-table-column>
      <el-table-column label="プログラム名">
        <template slot-scope="scope">
          <span v-if="scope.row.reportData.tools02PromotionData !== null">{{
            scope.row.reportData.tools02PromotionData.promotionName
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="ピクセル名">
        <template slot-scope="scope">
          <span>{{ scope.row.tools02TagData.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ピクセルID">
        <template slot-scope="scope">
          <span>{{ scope.row.tools02TagMediaTagId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="CVイベント" min-width="160px">
        <template slot-scope="scope">
          <span
            >{{
              $store.getters["tools02/tiktokEventDataByEvent"](scope.row.event)
                .name
            }}（{{ scope.row.event }}）</span
          >
        </template>
      </el-table-column>
      <el-table-column label="アクセストークン">
        <template slot-scope="scope">
          <span>{{ scope.row.tools02TagAccessToken }}</span>
        </template>
      </el-table-column>
      <el-table-column label="API送信結果">
        <template slot-scope="scope">
          <span v-if="scope.row.eventReceived === true">成功</span>
          <span v-else>失敗</span>
        </template>
      </el-table-column>
      <el-table-column min-width="160px" prop="errorCode" label="エラーコード">
      </el-table-column>
      <el-table-column width="500px" label="エラーメッセージ">
        <template slot-scope="scope">
          <el-input readonly v-model="scope.row.errorMessage">
            <el-button
              @click="copyText(scope.row.errorMessage)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column
        min-width="200px"
        prop="conversionDatetime"
        label="CV発生日時"
      >
      </el-table-column>
      <el-table-column min-width="160px" prop="value" label="報酬額">
      </el-table-column>
      <!-- <el-table-column min-width="160px" prop="currency" label="currency">
      </el-table-column> -->
      <el-table-column min-width="200px" label="atid生成日時">
        <template slot-scope="scope">
          <span
            v-if="scope.row.reportData.formattedAdtasukaruIdCreatedAt === null"
            >-</span
          >
          <span v-else>{{
            scope.row.reportData.formattedAdtasukaruIdCreatedAt
          }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="300px" label="atid">
        <template slot-scope="scope">
          <el-input readonly :value="scope.row.reportData.adtasukaruId">
            <el-button
              @click="copyText(scope.row.reportData.adtasukaruId)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column min-width="300px" label="ttclid">
        <template slot-scope="scope">
          <el-input readonly :value="scope.row.callback">
            <el-button
              @click="copyText(scope.row.callback)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column min-width="300px" label="User Agent">
        <template slot-scope="scope">
          <el-input readonly v-model="scope.row.userAgent">
            <el-button
              @click="copyText(scope.row.userAgent)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column min-width="160px" prop="ip" label="IPアドレス">
      </el-table-column>
      <el-table-column min-width="160px" prop="eventId" label="event_id">
      </el-table-column>
      <el-table-column
        min-width="160px"
        prop="url"
        label="CVページURL"
      ></el-table-column>
      <el-table-column min-width="160px" prop="requestId" label="request_id">
      </el-table-column>
      <el-table-column fixed="right" min-width="80px" label="再送">
        <template slot-scope="scope">
          <el-button
            @click="resendConversionEvent(scope.row)"
            type="primary"
            size="mini"
            >再送</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "tiktok-conversion-api-history-list",
  components: {},
  props: {
    conversionApiHistoryList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
  },
  watch: {},
  methods: {
    copyText(text) {
      this.$emit("copyText", { text: text });
    },
    resendConversionEvent(apiHistoryData) {
      console.log(apiHistoryData);

      //
      const promotionName =
        apiHistoryData.reportData.tools02PromotionData !== null
          ? apiHistoryData.reportData.tools02PromotionData.promotionName
          : "-";

      //
      let confirmText = "";
      confirmText += `API送信日時: ${apiHistoryData.sendDatetime}\n`;
      confirmText += `プログラム名: ${promotionName}\n`;
      confirmText += `ピクセル名: ${apiHistoryData.tools02TagData.name}\n`;
      confirmText += `ピクセルID: ${apiHistoryData.tools02TagData.mediaTagId}\n`;
      confirmText += `アクセストークン: ${apiHistoryData.tools02TagData.accessToken}\n`;
      confirmText += `CV発生日時: ${apiHistoryData.conversionDatetime}\n`;
      confirmText += `報酬額: ${apiHistoryData.value}\n`;
      confirmText += `atid: ${apiHistoryData.reportData.adtasukaruId}\n`;
      confirmText += `\n`;
      confirmText += `のAPIを再送信します。よろしいですか？`;

      //
      let payload = {
        confirmText: confirmText,
        apiHistoryData: apiHistoryData,
      };
      this.$emit("resendConversionEvent", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
