<template>
  <div class="tools01-edit">
    <el-row>
      <el-col :span="18">
        <h3>{{ pageData.name }}</h3>
      </el-col>
      <el-col :span="6" style="text-align: right">
        <router-link
          :to="`/tools01/${toolData.code2}/report/${pageData.id}`"
          style="margin-right: 15px"
        >
          <el-button type="success">レポート</el-button>
        </router-link>
        <router-link
          :to="`/tools01/${toolData.code2}/abtest/${pageData.siteId}`"
        >
          <el-button type="warning">ABテスト</el-button>
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="step">
          <span>STEP1: </span>{{ toolData.name2 }}情報の入力
          <router-link to="/" class="manual">【マニュアル】</router-link>
        </p>
        <p>入力後は「更新」をクリックして、更新を確定させてください。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-descriptions class="margin-top" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              {{ toolData.name }}名<Question
                :placement="'left'"
                :content="
                  toolData.name + 'を設置するページ名を入力してください。'
                "
              />
            </template>
            <el-input v-model="broadcastConfigData.name"></el-input>
          </el-descriptions-item>
          <template
            v-if="
              toolData.code2 === 'popup02' || toolData.code2 === 'overlay01'
            "
          >
            <el-descriptions-item>
              <template slot="label"> 飛び先URL </template>
              <el-input v-model="broadcastConfigData.url01"></el-input>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 飛び先URLの開き方 </template>
              <el-select
                v-model="broadcastConfigData.blank"
                placeholder=""
                style="width: 400px"
              >
                <el-option
                  v-for="item in blank"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-descriptions-item>
          </template>
          <el-descriptions-item
            v-if="
              toolData.code2 === 'overlay01' || toolData.code2 === 'overlay02'
            "
          >
            <template slot="label">
              表示タイミング<Question
                :placement="'left'"
                :content="`${toolData.name}を表示するタイミングを指定できます。`"
              />
            </template>
            <el-select
              v-model="broadcastConfigData.timing"
              placeholder=""
              style="width: 360px"
            >
              <el-option
                v-for="item in overlayDisplay"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="toolData.code2 === 'popup02'"
              ></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item
            v-if="
              (toolData.code2 === 'overlay01' ||
                toolData.code2 === 'overlay02') &&
              broadcastConfigData.timing == '1'
            "
          >
            <template slot="label">
              訪問者の行動による表示の制御<Question
                :placement="'left'"
                :content="`訪問者が指定のスクロール位置よりも上に戻った場合に、${toolData.name}をどうするか、その制御を選択してください。`"
              />
            </template>
            <el-select
              v-model="broadcastConfigData.back_and_forth"
              placeholder="選択してください。"
            >
              <el-option
                v-for="item in backAndForth"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              一言メモ<Question
                :placement="'left'"
                :content="
                  toolData.name +
                  'を設置したページの属性など、任意でメモを残すことができます。'
                "
              />
            </template>
            <el-input v-model="broadcastConfigData.memo"></el-input>
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="text-align: center">
        <el-button v-on:click="register('general')" type="primary"
          >更新</el-button
        >
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <div class="hr-border-line"></div>
      </el-col>
    </el-row>

    <p class="step">
      <span>STEP2: フォーマットの作成</span>
      <span v-if="toolData.code2 === 'popup01'"></span>
      <span v-else>と選択</span>
      <router-link to="/" class="manual">【マニュアル】</router-link>
    </p>
    <div id="format-list"></div>
    <FormatList
      :type="1"
      :tool="toolData.code2"
      :toolId="toolId"
      :pageId="pageId"
      :buttonName="'編集'"
      :formatDecideProp="formatDecideProp"
      @formatDecide="formatDecide"
      @numberOfFormats="setNumberOfFormats"
      @changeDisplay="changeDisplay"
      @register="register('general')"
    />

    <div v-if="toolData.code2 === 'popup01'">
      <el-row>
        <el-col>
          <div class="hr-border-line"></div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <p class="step">
            <span v-show="toolData.code2 === 'popup01'"
              >STEP3: 表示分割数の選択</span
            ><router-link to="/" class="manual">【マニュアル】</router-link>
          </p>
          <div class="el-select-prepend-cover" style="position: relative">
            <div class="el-select-prepend">表示分割数選択</div>
            <el-select v-model="broadcastConfigData.sections" placeholder="">
              <el-option
                v-for="section in sectionList"
                :key="section"
                :label="section"
                :value="section"
              ></el-option>
            </el-select>
            <Question
              style="
                position: absolute;
                right: 101%;
                top: 50%;
                transform: translateY(-50%);
              "
              :placement="'left'"
              :content="`同一ページ内での、ユーザーの到達位置によって、<br>表示する${toolData.name}を変更することができます。<br>最大でページを3分割することができ、<br>その分割位置によって表示する${toolData.name}を変更することができます。`"
            />
          </div>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <div class="hr-border-line"></div>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <p class="step">
            <span v-show="toolData.code2 === 'popup01'"
              >STEP4: 表示フォーマット設定</span
            ><router-link to="/" class="manual">【マニュアル】</router-link>
          </p>
          <p>
            ポップアップで表示するフォーマットと飛び先URLの設定をしてください。
          </p>
        </el-col>
      </el-row>

      <div class="sections-area">
        <!-- ↓↓↓{{toolData.name}}領域１↓↓↓ -->
        <el-row>
          <el-col class="popup01-register">
            <h3 class="back-gray">
              <Question
                :placement="'left'"
                :content="`⊕をクリックして、登録済の${toolData.name}から、表示したい${toolData.name}を選択してください。`"
              />分割領域１の表示フォーマット設定
            </h3>
            <FormatRegister
              :type="0"
              :area="1"
              :toolId="toolId"
              :pageId="pageId"
              :format="format01"
              @formatDecide="formatDecide2"
              @registerParentConfigData="register('general')"
              :parentComponentIsEditing="broadcastConfigDataIsEditing"
            />
            <el-descriptions class="margin-top" :column="1" border>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URL<Question
                    :placement="'right'"
                    :content="'飛び先ページを指定します。'"
                  />
                </template>
                <el-input v-model="broadcastConfigData.url01"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URLの開き方<Question
                    :placement="'right'"
                    :content="`${toolData.name}からの飛び先URLを新しいウィンドウで開くようにしたい場合は、<br>「新規タブで表示」を選択してください。<br>現在のウィンドウのまま表示したい場合は、<br>「同一タブで表示」を選択してください。`"
                  />
                </template>
                <el-select v-model="broadcastConfigData.blank01" placeholder="">
                  <el-option
                    v-for="item in blank"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URLメモ<Question
                    :placement="'right'"
                    :content="'飛び先ページのページ名など、任意でメモを残すことができます。'"
                  />
                </template>
                <el-input v-model="broadcastConfigData.url_memo01"></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
        </el-row>
        <!-- ↑↑↑{{toolData.name}}領域１↑↑↑ -->

        <!-- ↓↓↓{{toolData.name}}領域２↓↓↓ -->
        <el-row
          v-show="broadcastConfigData.sections >= 2"
          style="margin-top: 90px"
        >
          <el-col class="popup01-register">
            <h3 class="back-gray">
              <Question
                :placement="'left'"
                :content="
                  '⊕をクリックして、登録済の' +
                  toolData.name +
                  'から、表示したい' +
                  toolData.name +
                  'を選択してください。'
                "
              />分割領域２の表示フォーマット設定
            </h3>
            <FormatRegister
              :type="0"
              :area="2"
              :toolId="toolId"
              :pageId="pageId"
              :format="format02"
              @formatDecide="formatDecide2"
              @registerParentConfigData="register('general')"
              :parentComponentIsEditing="broadcastConfigDataIsEditing"
            />
            <el-descriptions class="margin-top" :column="1" border>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URL<Question
                    :placement="'right'"
                    :content="'飛び先ページを指定します。'"
                  />
                </template>
                <el-input v-model="broadcastConfigData.url02"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URLの開き方<Question
                    :placement="'right'"
                    :content="`${toolData.name}からの飛び先URLを新しいウィンドウで開くようにしたい場合は、<br>「新規タブで表示」を選択してください。<br>現在のウィンドウのまま表示したい場合は、<br>「同一タブで表示」を選択してください。`"
                  />
                </template>
                <el-select v-model="broadcastConfigData.blank02" placeholder="">
                  <el-option
                    v-for="item in blank"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URLメモ<Question
                    :placement="'right'"
                    :content="'飛び先ページのページ名など、任意でメモを残すことができます。'"
                  />
                </template>
                <el-input v-model="broadcastConfigData.url_memo02"></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
        </el-row>
        <!-- ↑↑↑{{toolData.name}}領域２↑↑↑ -->

        <!-- ↓↓↓{{toolData.name}}領域３↓↓↓ -->
        <el-row
          v-show="broadcastConfigData.sections >= 3"
          style="margin-top: 90px"
        >
          <el-col class="popup01-register">
            <h3 class="back-gray">
              <Question
                :placement="'left'"
                :content="`⊕をクリックして、登録済の${toolData.name}から、表示したい${toolData.name}を選択してください。`"
              />分割領域３の表示フォーマット設定
            </h3>
            <FormatRegister
              :type="0"
              :area="3"
              :toolId="toolId"
              :pageId="pageId"
              :format="format03"
              @formatDecide="formatDecide2"
              @registerParentConfigData="register('general')"
              :parentComponentIsEditing="broadcastConfigDataIsEditing"
            />
            <el-descriptions class="margin-top" :column="1" border>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URL<Question
                    :placement="'right'"
                    :content="'飛び先ページを指定します。'"
                  />
                </template>
                <el-input v-model="broadcastConfigData.url03"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URLの開き方<Question
                    :placement="'right'"
                    :content="`${toolData.name}からの飛び先URLを新しいウィンドウで開くようにしたい場合は、<br>「新規タブで表示」を選択してください。<br>現在のウィンドウのまま表示したい場合は、<br>「同一タブで表示」を選択してください。`"
                  />
                </template>
                <el-select v-model="broadcastConfigData.blank03" placeholder="">
                  <el-option
                    v-for="item in blank"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  飛び先URLメモ<Question
                    :placement="'right'"
                    :content="'飛び先ページのページ名など、任意でメモを残すことができます。'"
                  />
                </template>
                <el-input v-model="broadcastConfigData.url_memo03"></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
        </el-row>

        <el-row
          v-show="broadcastConfigData.sections >= 2"
          type="flex"
          align="middle"
          style="margin-top: 20px; padding-left: 35px"
        >
          <el-col :span="23" style="position: relative">
            <Question
              style="position: absolute; right: 101%; top: 20%"
              :placement="'left'"
              :content="
                '「ページを訪問した訪問者が到達した最下部の位置を判別して' +
                toolData.name +
                'を表示する」<br>を選択した場合、ユーザーが分割領域3の位置まで到達し、その後、<br>分割領域1の位置まで戻って離脱したとしても、分割領域3に設定した' +
                toolData.name +
                'を表示します。<br>「離脱時に訪問者が滞在している位置を判別して' +
                toolData.name +
                'を表示する」<br>を選択した場合、ユーザーが分割領域3の位置まで到達し、その後、<br>分割領域1の位置まで戻って離脱すると、分割領域1に設定した' +
                toolData.name +
                'を表示します。'
              "
            />
            <el-radio-group v-model="broadcastConfigData.position">
              <el-radio :label="'0'"
                >ページを訪問した訪問者が到達した最下部の位置を判別して{{
                  toolData.name
                }}を表示する</el-radio
              >
              <el-radio :label="'1'"
                >離脱時に訪問者が滞在している位置を判別して{{
                  toolData.name
                }}を表示する</el-radio
              >
            </el-radio-group>
          </el-col>
          <el-col :span="1"> </el-col>
        </el-row>
        <el-row>
          <el-col
            class="right-button-cover"
            style="text-align: center; margin-top: 30px"
          >
            <el-button @click="register('general')" type="primary"
              >更新</el-button
            >
          </el-col>
        </el-row>
        <!-- ↑↑↑{{toolData.name}}領域３↑↑↑ -->
      </div>
    </div>
    <el-row>
      <el-col>
        <div class="hr-border-line"></div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="24">
        <p class="step">
          <span>STEP</span>
          <span v-if="toolData.code2 === 'popup01'">5</span>
          <span v-else>3</span>
          <span>: タグの設置</span>
          <router-link to="/" class="manual">【マニュアル】</router-link>
        </p>
        <h3 class="back-gray">設置タグ</h3>
        <p>
          {{
            toolData.name
          }}を表示させたいページのbodyタグ内に以下のタグを設置してください。
          <span
            v-if="
              toolData.code2 === 'overlay01' || toolData.code2 === 'overlay02'
            "
            >{{
              toolData.name
            }}を常にしたい場合は、任意の位置に設置してください（&lt;/body>の直前など）。</span
          >
        </p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3 }"
          v-model="broadcastConfigData.tag[0]"
          onclick="this.select();"
        ></el-input>
      </el-col>
    </el-row>
    <div v-if="toolData.code2 === 'popup01'">
      <el-row v-if="broadcastConfigData.sections >= 2">
        <el-col>
          <div>
            「分割領域１」と「分割領域２」の切り替える箇所に以下のタグを設置してください。
          </div>
          <div>
            <el-input v-model="partition01" onclick="this.select();">
              <template slot="prepend">表示分割位置の仕切りタグ１</template>
            </el-input>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="broadcastConfigData.sections >= 3">
        <el-col>
          <div>
            「分割領域２」と「分割領域３」の切り替える箇所に以下のタグを設置してください。
          </div>
          <div>
            <el-input v-model="partition02" onclick="this.select();">
              <template slot="prepend">表示分割位置の仕切りタグ２</template>
            </el-input>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col>
        <div class="hr-border-line"></div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="step">
          <span>STEP</span>
          <span v-if="toolData.code2 === 'popup01'">6</span>
          <span v-else>4</span>
          <span>: {{ toolData.name }}利用設定</span>
          <router-link to="/" class="manual">【マニュアル】</router-link>
        </p>
        <p>利用設定をONにするとポップアップの表示が開始されます。</p>
        <p>
          利用設定: OFF<ActiveSwitch
            :pageId="pageData.id"
            style="margin: 0 10px"
          />ON
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormatList from "@/components/Ui/Tools01/FormatList.vue";
import $ from "jquery";
import FormatRegister from "@/components/Ui/Tools01/FormatRegister.vue";
import Question from "@/components/Ui/Question.vue";
import ActiveSwitch from "@/components/Tools01/Home/PageList/ActiveSwitch.vue";

export default {
  components: {
    ActiveSwitch,
    Question,
    FormatList,
    FormatRegister,
  },
  props: {
    toolId: String,
    pageId: String,
  },
  data() {
    return {
      list: [],
      jsCode: "",
      openClose: "+",
      options: {
        group: "myGroup",
        str01: 200,
      },
      broadcastConfigData: null,
      format01: {},
      format02: {},
      format03: {},
      partition01: '<div id="adtasukaruPopup01Partition01"></div>',
      partition02: '<div id="adtasukaruPopup01Partition02"></div>',
      items: [],
      sectionList: [1, 2, 3],

      // overlay
      backAndForth: [
        { value: "0", label: "上に戻っても消えない" },
        { value: "1", label: "戻ったら消える" },
      ],

      format: [],
      dialogVisible: false,
      numberOfFormats: 0,

      formatDecideProp: 0,

      //
      broadcastConfigDataIsEditing: false,
      broadcastConfigDataIsSet: false,
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },
    pageData() {
      return this.$store.getters["tools01/pageDataBySomeIds"](
        this.toolId,
        this.pageId
      );
    },
    formatList() {
      return this.$store.getters["tools01/formatListByPageId"](this.pageData.id);
    },
    blank() {
      //
      let blank = [
        {
          value: "0",
          label: this.toolData.name + "からの飛び先URLを同一タブで開く",
        },
        {
          value: "1",
          label: this.toolData.name + "からの飛び先URLを新規タブで開く",
        },
      ];

      //
      return blank;
    },
    overlayDisplay() {
      let overlayDisplay = [];
      if (this.toolData.code2 === "popup02") {
        // no action.
      } else {
        overlayDisplay.push({ value: "0", label: "常に表示" });
      }
      overlayDisplay.push({
        value: "1",
        label: "指定のスクロール位置に訪問者が達したら表示",
      });

      //
      return overlayDisplay;
    },
  },
  watch: {
    broadcastConfigData: {
      handler() {
        if (this.broadcastConfigDataIsSet === true) {
          this.broadcastConfigDataIsEditing = true;
        } else {
          //no action.
        }
      },
      deep: true,
    },
  },
  created() {
    //
    this.$axios
      .get(
        `${this.rt}/api/tools01/getSiteDetail.php?tool=${this.toolData.code2}&site_id=${this.pageId}`
      )
      .then((response) => {
        console.log(response);
        this.broadcastConfigData = response.data.list;
        this.getFormatDetail(1, this.broadcastConfigData.display01);
        this.getFormatDetail(2, this.broadcastConfigData.display02);
        this.getFormatDetail(3, this.broadcastConfigData.display03);
      })
      .catch((error) => {
        console.log(error);
      });

    this.$axios
      .get(
        `${this.rt}/api/tools01/getCode.php?tool=${this.toolData.code2}&site_id=${this.pageId}`
      )
      .then((response) => {
        console.log(response);
        this.jsCode = response.data.code;
        this.broadcastConfigDataIsSet = true;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    getFormatDetail(area, format_id) {
      //format_id=0の時は初期値を決定。それ以外の時は引っ張ってくる
      let params = new URLSearchParams();
      params.append("tool", this.toolData.code2);
      params.append("site_id", this.pageId);
      params.append("format_id", format_id);
      this.$axios
        .post(`${this.rt}/api/tools01/getFormatDetail.php`, params)
        .then((response) => {
          console.log(response);
          let format = format_id == 0 ? { format_id: 0 } : response.data.list;
          if (area == 1) {
            this.format01 = format;
          } else if (area == 2) {
            this.format02 = format;
          } else if (area == 3) {
            this.format03 = format;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    register(val) {
      //
      const duplicatePageData = this.$store.getters[
        "tools01/pageDataByToolIdAndName"
      ](this.toolId, this.broadcastConfigData.name);
      console.log(duplicatePageData);
      if (duplicatePageData === undefined) {
        console.log("undefined!");
      } else {
        if (duplicatePageData.id === this.pageData.id) {
          console.log("same!");
        } else {
          alert("同じページ名がすでに登録されています。");
          return;
        }
      }

      //
      let params = new URLSearchParams();
      params.append("tool", this.toolData.code2);
      params.append("site_id", this.pageId);
      params.append("val", val);
      params.append("item", JSON.stringify(this.broadcastConfigData));
      this.$axios
        .post(`${this.rt}/api/tools01/regSiteDetail.php`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store
              .dispatch("tools01/setPageList")
              .then((response) => {
                console.log(response);
                location.reload();
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert(response.data.message);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDecide(format_id) {
      this.$store.dispatch("setFormatEditFrom", this.pageData.name);
      this.$router.push(
        `/tools01/${this.toolData.code2}/format/${this.pageId}/${format_id}`
      );
    },
    formatDecide2(area, format_id) {
      console.log(format_id);
      //formatRegisterからの$emit
      switch (area) {
        case 1:
          this.broadcastConfigData.display01 = format_id;
          break;
        case 2:
          this.broadcastConfigData.display02 = format_id;
          break;
        case 3:
          this.broadcastConfigData.display03 = format_id;
          break;
      }
      this.getFormatDetail(area, format_id);
    },
    open() {
      $(".how-to-config").slideToggle();
      this.openClose = this.openClose === "+" ? "-" : "+";
    },
    setNumberOfFormats(num) {
      this.numberOfFormats = num;
    },
    changeDisplay(format_id) {
      this.broadcastConfigData.display = format_id;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-descriptions) {
  .el-descriptions__body {
    table {
      tbody {
        tr {
          th {
            width: 20%;
          }
        }
      }
    }
  }
}
:deep(p.step) {
  font-weight: bold;
  .manual {
    margin-left: 1em;
  }
}
.open-bar {
  border-radius: 5px;
  height: 26px;
  .bar-left {
    border-radius: 5px 0 0 5px;
  }
  .bar-right {
    padding: 2px 20px;
    font-size: 15px;
  }
}
.how-to-config {
  font-size: 15px;
  border-radius: 5px;
  border: solid 1px #ccc;
  padding: 10px 20px;
  display: none;
  margin-top: 5px;
  ul {
    margin: 0;
    padding-left: 20px;
    list-style: decimal;
    li {
    }
  }
}
.format-list-box {
  justify-content: center;
  width: 100%;
  height: 650px;
  border: dashed 1px #000;
}
.code {
  margin-top: 30px;
  input {
    width: 50%;
  }
}
.el-radio {
  width: 100%;
}
.format-wrap {
  display: flex;
  justify-content: space-between;
  .format {
    width: 30%;
    img {
      width: 50%;
    }
    .img_box {
      width: 50%;
      margin: 0 auto;
      border: dotted 2px #aaa;
      background: #eee;
      height: 212px;
      box-sizing: border-box;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.format-img {
  width: 100%;
}
.item-box {
  width: 840px;
  height: 500px;
  margin: 10px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border: solid 1px #000;
  .item {
    margin: 5px;
    width: 30%;
    height: 100px;
    border: solid 1px #000;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.url-config-box {
  margin-top: 10px;
}
.sections-area {
  .el-row {
    margin: 0;
  }
  .el-input,
  .el-select {
    margin-top: 10px;
  }
  .popup01-register {
    h3 {
      font-size: 16px;
      font-weight: normal;
      padding: 2px 10px;
      margin-bottom: 10px;
    }
    .el-select-prepend-cover {
      :deep(.el-select) {
        // width: 100%;
        width: 370px;
      }
    }
    :deep(.el-descriptions) {
      margin-top: 1em;
      .el-select {
        width: 370px;
      }
    }
  }
}
</style>
