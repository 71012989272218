<template>
  <div class="manual-tools01-contents">
    <el-row class="mokuji" :gutter="30">

      <el-col :span="24">
        <h2>初期設定マニュアル</h2>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="'#register-domain'" :src="`${rt}/img/manual/btn00.jpg`" alt="">
          <div class="desc">ツールを設置するドメインの登録</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="'#conversion'" :src="`${rt}/img/manual/btn01.jpg`" alt="">
          <div class="desc">コンバージョン測定の設定（任意）</div>
        </div>
      </el-col>

      <el-col :span="24">
        <h2>{{tools[tool].name2}} 設定マニュアル</h2>
      </el-col>

      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="`#${tools[tool].code2}-01`" :src="`${rt}/img/manual/${tools[tool].code2}/btn02.jpg`" alt="">
          <div class="desc">{{tools[tool].name2}}のサイト・ページの新規登録</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="`#${tools[tool].code2}-02`" :src="`${rt}/img/manual/${tools[tool].code2}/btn03.jpg`" alt="">
          <div class="desc">{{tools[tool].name2}}の設定</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="`#${tools[tool].code2}-03`" :src="`${rt}/img/manual/${tools[tool].code2}/btn04.jpg`" alt="">
          <div class="desc">{{tools[tool].name2}}の設置</div>
        </div>
      </el-col>

      <el-col :span="24">
        <h2>その他のマニュアル</h2>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="'#other'" :src="`${rt}/img/manual/btn05.jpg`" alt="">
          <div class="desc">運用（編集・削除・レポート確認）</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="'#editor'" :src="`${rt}/img/manual/btn06.jpg`" alt="">
          <div class="desc">フォーマット作成エディターの使い方</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="'#abtest'" :src="`${rt}/img/manual/btn07.jpg`" alt="">
          <div class="desc">ABテスト機能</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="'#wordpress01'" :src="`${rt}/img/manual/btn08.jpg`" alt="">
          <div class="desc">WordPressでページごとにタグを設置する方法</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="toc-box">
          <img class="header" v-scroll-to="'#wordpress02'" :src="`${rt}/img/manual/btn09.jpg`" alt="">
          <div class="desc">WordPressで特定のページのみタグ設置を除外する方法</div>
        </div>
      </el-col>

    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01Contents',
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
.mokuji {
  .toc-box {
    img {
      width: 100%;
      cursor: pointer;
    }
    .desc {
      text-align: center;
      font-size: 14px;
    }
  }
  .el-col {
    margin-bottom: 20px;
  }
}
</style>
