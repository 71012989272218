<template>
  <div class="affiliate-bank-account-bank-account-information">
    <div v-if="pageType === 'input'" class="bank-cover">
      <div class="bank">
        <p>
          アフィリエイトの利用に際して、まずは報酬の振込先となる銀行口座情報の登録をお願いいたします。
        </p>
        <table class="af-pop">
          <tr>
            <th>
              <div class="title">
                <span>銀行コード</span>
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-input
                @blur="setBankName"
                v-model="userData.bankCode"
                style="width: 100px"
              />
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>銀行名</span>
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-input
                v-model="userData.bankName"
                style="width: 200px"
                disabled
              />
              <!-- <span class="addition">銀行</span> -->
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>支店コード</span>
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-input
                @blur="setBranchName"
                v-model="userData.branchCode"
                style="width: 100px"
                :disabled="userData.bankName === ''"
              />
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>支店名</span>
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-input
                v-model="userData.branchName"
                style="width: 200px"
                disabled
              />
              <!-- <span class="addition">支店</span> -->
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>口座区分</span>
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-select
                v-model="userData.accountType"
                placeholder="選択してください。"
              >
                <el-option
                  v-for="bankAccountTypeData in bankAccountTypeList"
                  :key="bankAccountTypeData.typeId"
                  :label="bankAccountTypeData.typeName"
                  :value="bankAccountTypeData.typeId"
                ></el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>口座番号</span>
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-input v-model="userData.accountNumber" style="width: 150px" />
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span class="sp-small"
                  >口座名義<br class="sp-only" />（カタカナ）</span
                >
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-input v-model="userData.accountName" style="width: 200px" />
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span class="sp-small">インボイス登録番号</span>
                <span class="recquired">必須</span>
              </div>
            </th>
            <td>
              <el-input
                v-model="userData.invoiceRegistrationNumber"
                style="width: 200px"
              />
            </td>
          </tr>
        </table>
        <div class="register-button-cover">
          <el-button @click="confirmBankAccountData" type="primary"
            >確認</el-button
          >
        </div>
        <template id="affiliate-point-reward">
          <PointPaymentDescription v-if="$route.query.firstTime === 'true'" />
        </template>
      </div>
    </div>
    <div v-else-if="pageType === 'confirm'" class="bank-cover">
      <div class="bank">
        <table class="af-pop">
          <tr>
            <th>
              <div class="title">
                <span>銀行コード</span>
              </div>
            </th>
            <td>
              <span>{{ userData.bankCode }}</span>
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>銀行名</span>
              </div>
            </th>
            <td>
              <span>{{ userData.bankName }}</span>
              <!-- <span class="addition">銀行</span> -->
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>支店コード</span>
              </div>
            </th>
            <td>
              <span>{{ userData.branchCode }}</span>
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>支店名</span>
              </div>
            </th>
            <td>
              <span>{{ userData.branchName }}</span>
              <!-- <span class="addition">支店</span> -->
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>口座区分</span>
              </div>
            </th>
            <td>
              <span>{{ bankAccountTypeName(userData.accountType) }}</span>
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span>口座番号</span>
              </div>
            </th>
            <td>
              <span>{{ userData.accountNumber }}</span>
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span class="sp-small"
                  >口座名義<br class="sp-only" />（カタカナ）</span
                >
              </div>
            </th>
            <td>
              <span>{{ userData.accountName }}</span>
            </td>
          </tr>
          <tr>
            <th>
              <div class="title">
                <span class="sp-small">インボイス登録番号</span>
              </div>
            </th>
            <td>
              <span>{{ userData.invoiceRegistrationNumber }}</span>
            </td>
          </tr>
        </table>
        <div class="register-button-cover">
          <el-button @click="pageType = 'input'" type="info">修正</el-button>
          <el-button @click="registerBankAccountData" type="primary"
            >登録</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointPaymentDescription from "@/components/Affiliate/Parts/PointPaymentDescription.vue";

export default {
  name: "AffiliateBankAccountBankAccountInformation",
  components: {
    PointPaymentDescription,
  },
  props: {},
  data() {
    return {
      pageType: "input",
      userData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "bankAccountTypeList"]),
    ...mapGetters("affiliate", ["rewardBorderWithTax"]),
  },
  watch: {},
  created() {
    this.userData = JSON.parse(JSON.stringify(this.$store.getters["userData"]));
  },
  methods: {
    setBankName() {
      this.$axios
        .get(
          `https://apis.bankcode-jp.com/v1/banks/${this.userData.bankCode}?apiKey=bk_c540b863737e419fa6f3b8b81afee959`
        )
        .then((response) => {
          console.log(response);
          this.userData.bankName = response.data.name;
          return;
        })
        .catch((error) => {
          console.log(error);
          this.userData.bankName = "";
          return;
        });
    },
    setBranchName() {
      this.$axios
        .get(
          `https://apis.bankcode-jp.com/v1/banks/${this.userData.bankCode}/branches/${this.userData.branchCode}?apiKey=bk_c540b863737e419fa6f3b8b81afee959`
        )
        .then((response) => {
          console.log(response);
          if (response.data.length > 0) {
            this.userData.branchName = response.data[0].name;
          } else {
            this.userData.branchName = "";
          }
          return;
        })
        .catch((error) => {
          console.log(error);
          this.userData.branchName = "";
          return;
        });
    },
    confirmBankAccountData() {
      if (
        this.userData.bankCode !== "" &&
        this.userData.bankName !== "" &&
        this.userData.branchCode !== "" &&
        this.userData.branchName !== "" &&
        this.userData.accountType !== "" &&
        this.userData.accountNumber !== "" &&
        this.userData.accountName !== "" &&
        this.userData.invoiceRegistrationNumber !== ""
      ) {
        //ok.
      } else {
        alert("未入力の項目があります。");
        return;
      }

      //
      this.pageType = "confirm";
    },
    bankAccountTypeName(typeId) {
      let bankAccountTypeData =
        this.$store.getters["bankAccountTypeData"](typeId);
      return bankAccountTypeData.typeName;
    },
    registerBankAccountData() {
      let params = new URLSearchParams();
      params.append("user_data", JSON.stringify(this.userData));
      this.$axios
        .post(
          `${this.rt}/api/affiliate/bank-account/reg-bank-account-data.php`,
          params
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$store
              .dispatch("setUserData")
              .then((response) => {
                console.log(response);
                if (this.$route.query.firstTime !== "true") {
                  this.$router.go(-1);
                } else {
                  this.$router.push("/affiliate");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.af-pop {
  tr {
    th {
      height: 40px;
      width: 30%;
      @media screen and (max-width: 768px) {
        width: 35%;
        font-size: 14px;
      }
      .title {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          align-items: center;
        }
        .recquired {
          width: 2.5em;
          height: 25px;
          line-height: 25px;
          font-size: 0.75em;
          color: white;
          background-color: red;
          padding: 0px 2px;
        }
      }
    }
    td {
      text-align: left;
      span.addition {
        margin-left: 5px;
      }
    }
  }
}
.bank-cover {
  display: flex;
  justify-content: center;
  .bank {
    width: 70%;
  }
}
.sp-small {
  @media screen and (max-width: 768px) {
    font-size: 13px;
    margin-left: -10px;
  }
}
</style>
