<template>
  <div v-loading="loading" class="affiliate-reward-report">
    <el-row>
      <el-col>
        <table class="two-d">
          <thead>
            <tr>
              <th>対象年月</th>
              <th>振込年月</th>
              <th>獲得報酬</th>
              <th>繰越報酬額</th>
              <th>報酬合計</th>
              <th>手数料</th>
              <th>振込金額</th>
              <th>繰越報酬額</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(monthlyReportData, index) in monthlyReportList"
              :key="index"
            >
              <th>{{ monthlyReportData.objectMonth }}</th>
              <th>{{ monthlyReportData.transferMonth }}</th>
              <td>{{ monthlyReportData.monthlyTotal | localeNum }}</td>
              <td>{{ monthlyReportData.amountCarriedOver | localeNum }}</td>
              <td>{{ monthlyReportData.rewardTotal | localeNum }}</td>
              <td>{{ monthlyReportData.fee | localeNum }}</td>
              <td>
                <div>
                  <span v-if="monthlyReportData.paid === false">（準備中）</span>
                  <span>{{ monthlyReportData.transferAmount | localeNum }}</span>
                </div>
              </td>
              <td>{{ monthlyReportData.amountCarriedForward | localeNum }}</td>
            </tr>
          </tbody>
        </table>
        <div class="notice">
          <span>※当画面をキャプチャして明細としてご利用くださいませ。</span>
          <span style="text-align: right">
            レポート最終更新日：{{ updatedDate }}
            <br />金額の単位は円・すべて税込表示
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateRewardReport",
  components: {},
  props: {
    monthRange: Array,
  },
  data() {
    return {
      loading: true,
      updatedDate: "",
      monthlyReportList: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    monthRange: {
      handler(newMonthRange) {
        this.getMonthlyReportList(newMonthRange);
      },
      immediate: true,
    },
  },
  methods: {
    getMonthlyReportList(monthRange) {
      this.loading = true;
      this.$axios
        .get(`${this.rt}/api/affiliate/get-monthly-report-list.php?month_range=${encodeURIComponent(JSON.stringify(monthRange))}`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.updatedDate = res.data.updatedDate;
            this.monthlyReportList = res.data.monthlyReportList;
            this.loading = false;
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.two-d {
  margin-top: 30px;
}
.notice {
  display: flex;
  justify-content: space-between;
}
</style>
