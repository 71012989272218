var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"format0201"},[_c('div',{staticClass:"format-cover2",class:{ small: _vm.type == 1 },style:(_vm.$route.name === 'tools01-format' ? 'height: 600px;' : 'height: 100px;')},[_c('div',{staticClass:"overlay-background",style:('background:' + _vm.format.color04 + ';height:' + _vm.format.height02 + 'px;')},[_c('div',{staticClass:"overlay-outline"},[_c('span',{staticClass:"overlay-btn",style:('background:' +
            _vm.format.color01 +
            ';padding:0 ' +
            _vm.format.width02 +
            'px;height:' +
            _vm.format.height03 +
            'px;border-radius:' +
            _vm.format.int01 +
            'px;')},[_c('span',{staticClass:"button_text",domProps:{"innerHTML":_vm._s(_vm.format.text01)}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }