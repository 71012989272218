<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（レントラックス）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/point-back`">ASP自動連携設定</router-link>」にアクセスします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`" alt="" />
        <h4>【２】レントラックスの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no05/02.png`" alt="" />
        <h4>【３】以下の文言をレントラックスの担当者に連絡します。</h4>
        <p>-----------------------<br>
          アドタスカルのポイントバック連携を希望します。<br>
          テスト案件でのテストコード発行もお願いします。<br>
          <br>
          （【２】でコピーしたレントラックスの通知URL）<br>
          -----------------------</p>
        <h4>【４】連携が完了すると、テスト案件用のURLが発行してもらえます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no05/03.png`" alt="" />
        </div>
        <h4>【５】発行されたURLに「<span class="red">&amp;uix={{tool.clickIdName}}_xxx</span>」を追記してください。</h4>
        <h5>▼発行されたテストURL</h5>
        <p>https://www.rentracks.jp/adx/r.html?idx=●●●&amp;dna=●●●</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p>https://www.rentracks.jp/adx/r.html?idx=●●●&amp;dna=●●●<span class="red">&amp;uix={{tool.clickIdName}}_xxx</span></p>
        <p class="red">※自分で付けたパラメータを覚えておきましょう</p>
        <h4>【６】パラメータを付けたテストURLにアクセスすると、成果発生します。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no05/04.png`" alt="" />
        </div>
        <h4>【７】アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/auto-import-log`">自動登録履歴</router-link>」を確認します。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`" alt="" />
        <h4>【８】数分後に、テストCVが登録されていれば問題なく連携完了です。</h4>
        <AutoImportLog 
          :aspId="'4'"
          :aspPromotionName="'成果キックバック連携テスト用'"
          :conversionValue="'110'"
        />
        <ul>
          <li>ASP：レントラックス</li>
          <li>CV時間：テストした時間になっているか？（多少の誤差はあり）</li>
          <li>プログラム名：-</li>
          <li>パラメータ：【５】で付けたパラメータと一致しているか？</li>
          <li>プログラムID： 成果キックバック連携テスト用</li>
          <li>報酬額：110</li>
          <li>インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です</li>
        </ul>
        <h4>【９】連携完了</h4>
        <p>担当さんに一報入れましょう。これで連携は完了です。</p>
        <PointbackSettingDescription :aspId="'4'" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PointbackSettingDescription,
    AutoImportLog,
  },
  props: {
  },
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>

