<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>すでにMCVで最適化しているキャンペーンのオフラインCVの活用方法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>すでにMCVで最適化しているキャンペーンのオフラインCVの活用方法を知ること</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【説明】</h3>
        <p>現状MCV運用している場合、オフラインコンバージョンの活用パターンは２つあります。</p>
        <h4>【１】最適化をMCVから最終CVに変更する（コンバージョンアクションをMCVからオフラインコンバージョンに変える）</h4>
        <h5>＜メリット＞</h5>
        <ul>
          <li>
            最終CVで最適化できるようになります
          </li>
        </ul>
        <h5>＜デメリット＞</h5>
        <ul>
          <li>MCVより最終CVの方が量が少ないので、最適化に時間がかかります</li>
          <li>すでにMCV運用で上手くできている場合、最適化が崩れる可能性があります</li>
        </ul>
        <h5>＜設定方法＞</h5>
        <ul>
          <li>キャンペーン単位のコンバージョン設定をMCVからオフラインコンバージョンに変えます</li>
          <li>目標コンバージョン単価で設定している場合は、目標値を変更してください</li>
        </ul>
        <h5>＜管理画面の見方＞</h5>
        <ul>
          <li>コンバージョン：オフラインCVのデータ</li>
          <li>すべてのコンバージョン：オフラインCVとMCVの合算値</li>
        </ul>
        <h4>【２】最適化はMCVのままで、管理画面上で報酬額、利益、ROASを確認するためにオフラインコンバージョンを使います。（キャンペーン単位のコンバージョン設定をMCVのままです。）</h4>
        <h5>＜メリット＞</h5>
        <ul>
          <li>
            すでにMCV運用で上手く行っている場合は、最適化はMCVのまま、計測用にオフラインCVを使うことができます。<br>→計測用にオフラインCVを使うことで、「キーワード、広告、デモグラ」など正確な最終CV数、報酬額が管理画面上で見れます。<br>→正確な精査ができるようになります。
            <ul>
              <li>MCVRは良いけど、実は赤字→損切り</li>
              <li>MCVRは悪いけど、実は黒字→残せる</li>
            </ul>
          </li>
          <li>CVしたクエリが見れます<br>※Googleの仕様上すべてのクエリは見れません。</li>
        </ul>
        <h5>＜デメリット＞</h5>
        <ul>
          <li>最終CVで最適化ができません</li>
        </ul>
        <h5>＜設定方法＞</h5>
        <ul>
          <li>すでにコンバージョンアクションにMCVを設定している場合は設定不要です。</li>
          <li>設定していない場合は、キャンペーン単位のコンバージョン設定をMCVにします。</li>
        </ul>
        <h5>＜管理画面の見方＞</h5>
        <ul>
          <li>コンバージョン：MCVのデータ</li>
          <li>すべてのコンバージョン：オフラインCVとMCVの合算値</li>
        </ul>
        <h5>【３】リスクを減らす切り替え方</h5>
        <p>まずは、【２】の方法で運用していきます。そうすると、オフラインCVにデータが溜まっていきます。</p>
        <p>ある程度、オフラインCVにデータが溜まった状態で最適化対象をオフラインCVに変更することで、最適化されるまでの期間が短くなります。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【まとめ】</h3>
        <p>「どう運用していくか？」は運用者自身の考え方、資金、目標、リスク許容度によって異なるため明確な答えは出せません。</p>
        <p>また、最適化対象をMCVからオフラインCVに変えて、最適化が崩れても責任を取ることはできません。</p>
        <p>なので、最初は【２】の方法で、正確な成果計測をするためにオフラインCVを活用してみてはどうでしょうか？</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
