<template>
  <div class="tools02-report-asp">
    <el-row>
      <el-col>
        <h2>ASPごとの成果データのダウンロード方法</h2>
      </el-col>
    </el-row>
    <Tools02CsvManualLinksManualLinks/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02CsvManualLinksManualLinks from "@/components/Tools02/CsvManualLinks/ManualLinks.vue";

export default {
  components: {
    Tools02CsvManualLinksManualLinks
  },
  props: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
