<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（A8.net）</h2>
      </el-col>
    </el-row>

    <!-- <el-row>
  <el-col>
    <h4>【１】<a href="https://www.a8.net/" target=”_blank” rel="noopener noreferrer">A8.net管理画面</a>にログインしたら、「登録情報」→「ポイントレポートパスワード変更」をクリックします。</h4>
    <img :src="`${rt}/img/manual/google-cv/no07/no12/01.png`" alt="" />

    <h4>【２】A8.net管理画面へのログインIDとパスワードを入力して、「次へ進む」をクリックします。</h4>
    <img :src="`${rt}/img/manual/google-cv/no07/no12/02.png`" alt="" />

    <h4>【３】ポイントレポートパスワード変更の画面が表示されるので、任意でパスワードを設定し、「パスワードを変更する」をクリックします。</h4>
    <p>なお、ここで設定するパスワードは、A8.netの管理画面へログインするパスワードではありません。以後、説明する成果データダウンロード専用の管理画面のパスワードとなります。パスワードをメモしておいてください。</p>
    <img :src="`${rt}/img/manual/google-cv/no07/no12/03.png`" alt="" />

    <h4>【４】A8.net側でのパスワード変更処理の反映に24時間要するため、24時間待ちます。</h4>
    <p>その間、A8.netから以下のメールが届きますが、このタイミングでパスワード変更が完了し、成果データダウンロード専用の管理画面にログインできる状態にはなってはいません。24時間待っていただく必要があります。</p>
    <img class="mini-img" :src="`${rt}/img/manual/google-cv/no07/no12/04.png`" alt="" />

    <h4>【５】24時間以上経過したら、成果データダウンロード専用の管理画面にアクセスします。</h4>
    <p>■成果データダウンロード専用の管理画面へのアクセス方法</p>
    
    <p class="top">以下のURLの「ここにあなたのメディアIDを入力」部分を、あたなのA8.netのメディアIDに書き換えます。</p>
    <div class="bold">https://api.a8.net/as/ここにあなたのメディアIDを入力/</div>

    <p class="top">メディアIDは、<a href="https://www.a8.net/" target=”_blank” rel="noopener noreferrer">A8.net管理画面</a>にログインすると、以下の箇所に表示されています。</p>
    <img :src="`${rt}/img/manual/google-cv/no07/no12/05.png`" alt="" />
    <p class="top">メディアIDが「a12071318803」の場合、成果データダウンロード専用の管理画面のログインURLは以下となります。</p>

    <div class="bold">https://api.a8.net/as/a12071318803/</div>

    <h4>【６】成果データダウンロード専用の管理画面にログインします。</h4>
    <p>上で記したログインURLで成果データダウンロード専用の管理画面へアクセスします。以下の画面が表示されるので、「ユーザー名」にはA8.net管理画面へのログインIDを、「パスワード」には上の【３】で設定したポイントレポートパスワードを入力し、「ログイン」をクリックします。</p>
    <img class="mini-img" :src="`${rt}/img/manual/google-cv/no07/no12/06.png`" alt="" />

    <h4>【７】以下の画面が表示されるので、「pointreport/」をクリックします。</h4>
    <img :src="`${rt}/img/manual/google-cv/no07/no12/07.png`" alt="" />

    <h4>【８】以下の画面が表示されるので、「unsealed/」をクリックします。</h4>
    <img :src="`${rt}/img/manual/google-cv/no07/no12/08.png`" alt="" />

    <h4>【９】以下の画面が表示されるので、取得したい日付のBタイプのレポートを右クリックして、「名前を付けてリンク先を保存」を選択します。</h4>
    <img :src="`${rt}/img/manual/google-cv/no07/no12/09.png`" alt="" />

  <h4>【１０】txt形式のレポートが保存されます(A8.netはtxt形式のレポートとなります)。</h4>
    <img class="mini-img" :src="`${rt}/img/manual/google-cv/no07/no12/10.png`" alt="" />

    <p>これで成果データのダウンロードは完了です。<router-link to="/manual/google-cv/07/14">「ASPの成果データをアドタスカルにインポートする」</router-link>に進んでください。</p>

  </el-col>
</el-row> -->

    <el-row>
      <el-col>
        <p>A8.netでは２つのレポートから手動インポート可能です。</p>
        <p>レポートの表示期間が異なります。</p>
        <table class="simple">
          <thead>
            <tr>
              <td style="font-weight: bold;">レポート名</td>
              <td style="font-weight: bold;">表示期間</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>①未確定速報レポート</td>
              <td>当日のみ</td>
            </tr>
            <tr>
              <td>②新レポートβ版のコンバージョンリファラレポート</td>
              <td>前日～過去365日</td>
            </tr>
          </tbody>
        </table>
        <p>使い分けとしては、</p>
        <p>・成果発生した当日にインポートする場合 → ①未確定速報レポート<br>・成果発生した翌日以降にインポートする場合 → ②新レポートβ版のコンバージョンリファラレポート</p>
        <p>を利用してください。</p>
        <p>それでは、それぞれのレポートのダウンロードの方法とアドタスカルへのインポートの方法を説明します。</p>
        <h4 class="back-gray">①未確定速報レポート</h4>
        <p>このレポートは、当日の0:00から24:00に発生した成果が反映されます。</p>
        <p>それでは、ダウンロードの手順を説明いたします。</p>
        <h5>【１】<a href="https://www.a8.net/" target=”_blank” rel="noopener noreferrer">A8.netの管理画面</a>にログインしたら、管理画面TOPページから「レポート」→「未確定速報」の順にクリックします。</h5>
        <img :src="`${rt}/img/manual/google-cv/no07/no12/11.png`" alt="" />
        <h5>【２】未確定速報レポートが表示されたら「ポイントID」か「リファラ」にクリックパラメータ（{{tool.clickIdName}}_xxx）が付与されているか確認します。</h5>
        <p class="red">※「パラメータID」に関しては、A8.netに<span style="font-size: 18px;font-weight: bold;">ポイントバック通知機能</span>の申請をしないと表示されません。その場合でもリファラから手動インポート可能なのでご安心ください。</p>
        <p class="red">※パラメータが表示されていない場合、パラメータ引継ぎ設定を間違えている可能性があります。</p>
        <p><router-link :to="`/manual/${tool.codeKebab}/06/03`">「パラメータ引継ぎタグのチェックをする」</router-link>をご確認ください。</p>
        <p>次に、「CSV」をクリックしてください。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no12/12.png`" alt="" />
        <h5>【３】CSVがダウンロードできたら、アドタスカルに「ASP成果インポート・エクスポート」インポートします。</h5>
        <p>「A8.net」→「新レポートβ版コンバージョンリファラレポート」→先程ダウンロードしたCSVをアップロード→「登録」</p>
        <p class="red">※他のASPと異なり、レポートを選択する項目があります。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no12/13.png`" alt="" />
        <h4 class="back-gray">②新レポートβ版のコンバージョンリファラレポート</h4>
        <p>このレポートでは、成果発生日の翌日に反映されます。<br>※22:00以降(多少前後します)の成果発生分は、翌々日以降、レポートに反映される場合もあります。</p>
        <p>それでは、ダウンロードの手順を説明いたします。</p>
        <h5>【１】<a href="https://www.a8.net/" target=”_blank” rel="noopener noreferrer">A8.netの管理画面</a>にログインしたら、管理画面TOPページから→「新レポートβ版」の順にクリックします。</h5>
        <img :src="`${rt}/img/manual/google-cv/no07/no12/14.png`" alt="" />
        <h5>【２】新レポートβ版が表示されたら「コンバージョンリファラレポート」をクリックします。</h5>
        <p>次に、表示したい期間を選択したら「検索」をクリックします。</p>
        <p>成果が表示されたら、「リファラ」に「リファラ」にクリックパラメータ（{{tool.clickIdName}}_xxx）が付与されているか確認します。</p>
        <p class="red">※パラメータが表示されていない場合、パラメータ引継ぎ設定を間違えている可能性があります。</p>
        <p><router-link :to="`/manual/${tool.codeKebab}/06/03`">「パラメータ引継ぎタグのチェックをする」</router-link>をご確認ください。</p>
        <p>画面下の「CSV」をクリックしてください。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no12/15.png`" alt="" />
        <h5>【３】CSVがダウンロードできたら、アドタスカルにインポートします。</h5>
        <p>「A8.net」→「新レポートβ版コンバージョンリファラレポート」→先程ダウンロードしたCSVをアップロード→「登録」</p>
        <p class="red">※他のASPと異なり、レポートを選択する項目があります。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no12/16.png`" alt="" />

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.mini-img {
  width: auto;
}

.bold {
  font-weight: bold;
  border: solid 3px gray;
  padding: 15px;
  width: 50%;
  margin-left: 40px;
  text-align: center;
}

.top {
  margin-top: 50px;
}

.back-gray {
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #ddd;
  padding: 5px 15px;
}
</style>
