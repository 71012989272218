<template>
  <div class="tools01-select">
    <el-row v-if="tool == 'popup'" type="flex" justify="space-around" class="overlay-box">
      <el-col :span="11">
        <router-link to="/tools01/popup01"><Button :msg="tools['popup01'].name2"/></router-link>
        <img :src="rt+'/img/btn_type.jpg'">
      </el-col>
      <el-col :span="11">
        <router-link to="/tools01/popup02"><Button :msg="tools['popup02'].name2"/></router-link>
        <img :src="rt+'/img/text_type.jpg'">
      </el-col>
    </el-row>
    <el-row v-else-if="tool == 'overlay'" type="flex" justify="space-around" class="overlay-box">
      <el-col :span="11">
        <router-link to="/tools01/overlay01"><Button :msg="tools['overlay01'].name2"/></router-link>
        <img :src="rt+'/img/btn_type.jpg'">
      </el-col>
      <el-col :span="11">
        <router-link to="/tools01/overlay02"><Button :msg="tools['overlay02'].name2"/></router-link>
        <img :src="rt+'/img/text_type.jpg'">
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p style="text-align:center">【※注意※】同一URLのページに、ボタンタイプとテキストタイプを問わず、{{tools[tool].name}}のタグを2つ以上貼り付けた場合、ソースコード上の最下部にあるタグ採用されます。2つ以上の{{tools[tool].name}}を同一URLですることはできません。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button from '@/components/Ui/Button.vue'

export default {
  components: {
    Button
  },
  props: {
    tool: String
  },
  data() {
    return {
      items: []
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.overlay-box {
  .el-col {
    text-align: center;
    a {
      button {
        width: 60%;
      }
    }
    img {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>