<template>
  <div class="affiliate-home">
    <h2>アフィリエイト</h2>
    <el-row>
      <el-col>
        <Summary />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <LinkButtons />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <template id="affiliate-point-reward">
          <PointPaymentDescription />
          <p>
            ポイントでの受け取りを希望の場合は、「<router-link
              to="/affiliate/bank-account"
              >報酬の受取方法の設定</router-link
            >」から変更をお願いします。
          </p>
          <PointPaymentSimulation />
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Summary from "@/components/Affiliate/Home/Summary.vue";
import LinkButtons from "@/components/Affiliate/Home/LinkButtons.vue";
import PointPaymentDescription from "@/components/Affiliate/Parts/PointPaymentDescription.vue";
import PointPaymentSimulation from "@/components/Affiliate/Parts/PointPaymentSimulation.vue";

export default {
  components: {
    Summary,
    LinkButtons,
    PointPaymentDescription,
    PointPaymentSimulation,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
