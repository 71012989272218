<template>
  <div class="tools02-accounts-account-list">
    <el-row>
      <el-col style="width: 140px">
        <a
          :href="toolData.manualData.accountRegistrationManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <router-link to="account-detail/0">
          <el-button type="primary" class="new-add">新規追加</el-button>
        </router-link>
      </el-col>
    </el-row>

    <el-row v-if="userData.bonusPointData[tool.codeCamel].account === false">
      <el-col>
        <UiBonusAlert
          :toolCode="tool.codeCamel"
          :bonusCode="'account'"
          boxStyle="fat"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col class="table-cover">
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>アカウントID</th>
              <th>アカウント名</th>
              <th v-if="tool.codeKebab === 'google-cv'">種別</th>
              <th v-else-if="tool.codeKebab === 'yahoo-cv'">
                Yahoo!JAPAN ビジネスID
              </th>
              <th style="width: 50px"></th>
              <th style="width: 50px"></th>
              <th style="width: 50px"></th>
              <th
                v-if="
                  tool.codeKebab === 'google-cv' ||
                  tool.codeKebab === 'yahoo-cv'
                "
              >
                API自動アップロード
              </th>
              <template v-if="tool.codeKebab === 'google-cv'">
                <th></th>
                <th></th>
              </template>
            </tr>
          </thead>
          <tbody v-if="accountList.length > 0">
            <tr v-for="(accountData, index) in accountList" :key="index">
              <td>
                {{ accountData.accountCode }}
              </td>
              <td style="max-width: 160px; overflow-wrap: break-word">
                {{ accountData.accountName }}
              </td>
              <td v-if="tool.codeKebab === 'google-cv'">
                {{ accountTypeNames2[accountData.accountType] }}
              </td>
              <td v-else-if="tool.codeKebab === 'yahoo-cv'">
                {{ $store.getters['tools02/bussinessAccountData'](accountData.bussinessAccountId).bussinessId }}
              </td>
              <td>
                <router-link
                  v-if="tool.codeKebab === 'yda-cv'"
                  :to="`account-detail/${accountData.accountId}/tags`"
                >
                  <el-button type="">コンバージョン登録</el-button>
                </router-link>
                <router-link
                  v-else
                  :to="`account-detail/${accountData.accountId}#promotions`"
                >
                  <el-button type="">プログラム情報登録</el-button>
                </router-link>
              </td>
              <td class="edit" style="width: 100px">
                <router-link :to="`account-detail/${accountData.accountId}`">
                  <el-button type="">編集</el-button>
                </router-link>
              </td>
              <td class="edit">
                <el-button
                  @click="
                    delAccount(accountData.accountId, accountData.accountCode)
                  "
                  type="danger"
                  >削除</el-button
                >
              </td>
              <td
                v-if="
                  tool.codeKebab === 'google-cv' ||
                  tool.codeKebab === 'yahoo-cv'
                "
                style="text-align: center"
              >
                <span v-if="accountData.autoUpload === true">ON</span>
                <span v-else-if="accountData.autoUpload === false">OFF</span>
              </td>
              <template v-if="tool.codeKebab === 'google-cv'">
                <template v-if="accountData.autoUpload === true">
                  <td>
                    <p v-if="accountData.apiAuthorized === true">認証済み</p>
                    <p v-else style="color: red">未認証</p>
                  </td>
                  <td style="text-align: center">
                    <img
                      @click="authorize(accountData.accountId)"
                      :src="`${rt}/img/tools02/google-cv/sign-in-button.png`"
                      alt=""
                      style="cursor: pointer; width: 150px"
                    />
                  </td>
                </template>
                <template v-else>
                  <td></td>
                  <td></td>
                </template>
              </template>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <el-row
      v-if="
        userData.bonusPointData[tool.codeCamel].account === true &&
        userData.bonusPointData[tool.codeCamel].promotion === false
      "
    >
      <el-col class="center">
        <UiBonusAlert
          :toolCode="tool.codeCamel"
          :bonusCode="'promotion'"
          boxStyle="fat"
        />
      </el-col>
    </el-row>
    <a href="http://" target="_blank" rel="noopener noreferrer"></a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
import UiBonusAlert from "@/components/Ui/BonusAlert.vue";

export default {
  name: "Tools02AccountsAccountList",
  components: {
    Button2,
    UiBonusAlert,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "userData"]),
    ...mapGetters("tools02", ["accountTypeNames2"]),
    accountList() {
      return this.$store.getters["tools02/accounts"](this.tool.id);
    },
    toolData() {
      return this.$store.getters["toolData"](this.tool.id);
    },
  },
  watch: {},
  created() {
    if (
      this.$route.query.state !== undefined &&
      this.$route.query.code !== undefined
    ) {
      this.getAdsApiTokens(this.$route.query.state, this.$route.query.code);
    } else {
      // no action.
    }
  },
  methods: {
    authorize(accountId) {
      this.$axios
        .get(
          `${this.rt}/l/api/tools02/media/google/get-adwords-auth-url?account_id=${accountId}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            location.href = response.data.adwordsAuthUrl;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAdsApiTokens(state, code) {
      let params = new URLSearchParams();
      params.append("state", state);
      params.append("code", code);
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/media/google/get-and-update-ads-api-tokens`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200 || response.data.status === 400) {
            //
            this.$alert(response.data.message, "", {
              dangerouslyUseHTMLString: true,
            })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });

            // パラメータ削除（どこかから戻ってくると処理が実行されてしまうので）
            history.replaceState("", "", "accounts");

            //
            this.$store.dispatch("tools02/setAccounts");
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    delAccount(accountId, accountCode) {
      if (
        confirm(
          `アカウントID「${accountCode}」のアカウント情報を削除します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("account_id", accountId);
        this.$axios
          .post(`${this.rt}/api/tools02/delAccount.php`, params)
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              this.$store.dispatch("tools02/setAccounts");
              this.$store.dispatch("tools02/setWithTrashedPromotionList");
              return;
            } else if (res.data.status === 401) {
              alert(res.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("キャンセルしました。");
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-add {
  margin-top: 20px;
}
.table-cover {
  overflow: auto;
  table {
    width: 1000px;
  }
}
table.typeA {
  th,
  td {
    padding-left: 5px;
    padding-right: 5px;
  }
}
:deep(.el-message-box) {
  width: 540px !important;
}
.el-message-box {
  width: 540px !important;
}
</style>
