<template>
  <div class="ctrl-user-edit">
    <h2>ユーザー情報詳細</h2>
    <el-row :gutter="70" type="flex" justify="space-between" align="middle">
      <el-col :span="4">
        <router-link to="/ctrl/user">
          <Button3 msg="戻る" type="success" />
        </router-link>
      </el-col>
      <!-- <el-col :span="4">&nbsp;</el-col> -->
      <el-col :span="4">
        <el-button type="warning" class="userBTN">購入履歴</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="info" class="userBTN" @click="contact(user.user_id)">お問い合わせ履歴</el-button>
      </el-col>
      <el-col :span="4">
        <el-button type="danger" class="userBTN">獲得ユーザー情報</el-button>
      </el-col>
    </el-row>

    <el-row type="flex" justify="center">
      <el-col>
        <table class="typeB">
          <tr>
            <th>お名前</th>
            <td >
              <span>
                <el-input type="text" v-model="user.name01" style="width:250px"></el-input>
              </span>
              <span>
                <el-input type="text" v-model="user.name02" style="width:250px;margin-left:10px"></el-input>
              </span>
            </td>
          </tr>
          <tr>
            <th>ヨミガナ</th>
            <td>
              <span>
                <el-input type="text" v-model="user.reading01" style="width:250px"></el-input>
              </span>
              <span>
                <el-input type="text" v-model="user.reading02" style="width:250px;margin-left:10px"></el-input>
              </span>
            </td>
          </tr>
          <tr>
            <th>会社名・屋号名</th>
            <td>
              <el-input type="text" v-model="user.company_name"></el-input>
            </td>
          </tr>
          <tr>
            <th>会社名・屋号名（ヨミガナ）</th>
            <td>
              <el-input type="text" v-model="user.company_reading"></el-input>
            </td>
          </tr>
          <tr>
            <th>メールアドレス（ログインID）</th>
            <td>
              <div>
                <el-input type="email" v-model="user.email"></el-input>
              </div>
            </td>
          </tr>
          <!-- <tr>
            <th>郵便番号</th>
            <td>
              <el-input type="text" v-model="user.post01"></el-input>
            </td>
          </tr>
          <tr>
            <th>住所</th>
            <td>
              <el-input type="text" v-model="user.address"></el-input>
            </td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>
              <el-input type="text" v-model="user.tel"></el-input>
            </td>
          </tr> -->
          <tr>
            <th>登録日</th>
            <td>
              <el-input type="text" v-model="user.create_datetime"></el-input>
            </td>
          </tr>
          <tr>
            <th>利用期間</th>
            <td>
              <el-input type="text" v-model="user.use_term"></el-input>
            </td>
          </tr>
          <tr>
            <th>累計購入金額</th>
            <td>
              <el-input type="text" v-model="user.purchase_price_total"></el-input>
            </td>
          </tr>
          <tr>
            <th>当月利用のツール</th>
            <td>
              <el-input type="text" v-model="user.tool"></el-input>
            </td>
          </tr>
          <tr>
            <th>当月購入金額</th>
            <td>
              <el-input type="text" v-model="user.purchase_price_month"></el-input>
            </td>
          </tr>
          <tr>
            <th>備考</th>
            <td>
              <el-input type="text" v-model="user.remark"></el-input>
            </td>
          </tr>
        </table>

      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="text-align: center;">
          <el-button type="primary" @click="confirm">更新</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  data() {
    return {
      user_id: "",
      user: [],
    }
  },
  components: {
    Button3,
  },
  created() {
  },
  mounted() {
    this.user_id = this.$route.params.user_id;
    //ユーザーの詳細を取得
    this.$axios
      .get(`${this.$store.getters.rt}/api/getUserDetail.php?user_id=${this.user_id}&mode=ctrl`)
      .then((res) => {
        console.log(res);
        console.log(res.data.list);
        this.user = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    confirm: function () {
      let params = new URLSearchParams();
      params.append("reading1", this.reading1);
      params.append("reading2", this.reading2);
      this.$axios
        .post(this.$store.getters.rt + "/api/ctrl/search_reading.php", params)
        .then((response) => {
          console.log(response.data.list);
          this.userLists = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    contact:function(user_id){
      this.$router.push("/ctrl/contact-edit/"+user_id);
    }
  },
}
</script>

<style lang="scss" scoped>
.typeB {
  width: 1100px;
}
span.input {
  font-size: 18px;
  font-weight: bold;
}

.userBTN {
  width: 200px;
}
</style>
