<template>
  <div class="ui-bonus-alert">
    <div
      class="bonusbox"
      :class="{
        'bonusbox-wide': boxStyle === 'wide',
        'bonusbox-fat': boxStyle === 'fat',
      }"
    >
      <p
        class="
          title
          animate__animated animate__flash animate__slower animate__infinite
        "
        style="white-space: nowrap"
      >
        ＼&nbsp;&nbsp;&nbsp;獲得できるボーナスポイントあり&nbsp;&nbsp;&nbsp;／
      </p>
      <p
        class="mess"
      >
        {{ bonusPointData.name }}：<span style="font-size: 1.5em">{{ bonusPointData.point }}</span
        >ポイント
        <el-tooltip
          class="item"
          effect="dark"
          :content="bonusPointData.description"
          :placement="'right'"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BonusAlert",
  components: {},
  props: {
    toolCode: String,
    bonusCode: String,
    boxStyle: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "tools",
    ]),
    bonusPointData() {
      return this.$store.getters.bonusPointData(this.toolCode, this.bonusCode);
    }
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.bonusbox {
  border: 1px solid #4d4d40;
  text-align: center;
  font-size: 16px;
  @media screen and (max-width: 1130px){
    font-size: 15px;
  }
  .mess {
    font-weight: bold;
  }
  .title {
    color: #f15e62;
    font-weight: bold;
  }
}
.bonusbox-wide {
  // width: 77%;
  // width: 100%;
  display: flex;
  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
   @media screen and (max-width: 767px){
     flex-direction: column;
   }
  p {
    margin: 0;
  }
  .mess {
    padding: 0px 2em;
    @media screen and (max-width: 1130px){
padding: 0;
    }
  }
  .title {
    padding: 0px 2em;
  }
}
.bonusbox-fat {
  max-width: 422px;
}
</style>
