<template>
  <div class="history-detail-order-data">
    <table class="typeB">
      <tr>
        <th>注文ID</th>
        <td>{{ orderData.orderId }}</td>
      </tr>
      <tr>
        <th>ご購入日</th>
        <td>{{ orderData.orderDatetime }}</td>
      </tr>
      <tr>
        <th>プラン名１</th>
        <td>{{ orderData.plan01 }}</td>
      </tr>
      <tr>
        <th>プラン名２</th>
        <td>{{ orderData.plan02 }}</td>
      </tr>
      <tr>
        <th>プラン名３</th>
        <td>{{ orderData.plan03 }}</td>
      </tr>
      <tr>
        <th>決済金額</th>
        <td>{{ orderData.total | localeNum }} 円</td>
      </tr>
      <tr>
        <th>{{ orderData.taxRate }}%対象</th>
        <td>{{ orderData.price | localeNum }} 円</td>
      </tr>
      <tr>
        <th>消費税</th>
        <td>{{ orderData.tax | localeNum }} 円</td>
      </tr>
    </table>
    <table class="typeB invoice">
      <tr>
        <th>請求者</th>
        <td>株式会社グレイトヘルプ</td>
      </tr>
      <tr>
        <th>インボイス登録番号</th>
        <td>T7200001030936</td>
      </tr>
      <tr>
        <th>支払者</th>
        <td>
          <span v-if="userData.companyName === ''"></span>
          <span v-else>{{ userData.companyName }} </span>
          <span>{{ userData.name01 }} {{ userData.name02 }}</span>
        </td>
      </tr>
    </table>
    <p>※当画面をキャプチャして、税務資料としてください。</p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "history-detail-order-data",
  components: {},
  props: {
    orderId: String,
  },
  data() {
    return {
      orderData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "userData"]),
  },
  watch: {},
  created() {
    let params = new URLSearchParams();
    params.append("order_id", this.orderId);
    this.$axios
      .post(`${this.rt}/api/getOrderDetail.php`, params)
      .then((res) => {
        console.log(res);
        if (res.data.status === 200) {
          this.orderData = res.data.orderData;
        } else if (res.data.status === 401) {
          alert(res.data.message);
          this.$router.push("/login");
        } else {
          alert(this.apiErrorMessage);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.el-tooltip {
  margin-left: 5px;
}

.select-hours-box {
  .el-checkbox {
    margin-right: 5px;
  }
}

.invoice {
  margin-top: 40px;
}
</style>
