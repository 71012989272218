import { render, staticRenderFns } from "./AffiliatedUserData.vue?vue&type=template&id=d051f8c0&scoped=true&"
import script from "./AffiliatedUserData.vue?vue&type=script&lang=js&"
export * from "./AffiliatedUserData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d051f8c0",
  null
  
)

export default component.exports