<template>
  <div class="manual-google-cv-contents">
    <h2>ピクセルIDとアクセストークンの取得方法</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>アドタスカル管理画面に設定するピクセルの「ピクセルID」と「アクセストークン」を取得します。</li>
          <li>「ファーストパーティCookie」がオンになっているか確認します。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>10分</p>
        <p>※新規作成したピクセルもしくは既存のピクセルのどちらも同じ手順で取得することができます。</p>
        <h4>【１】<router-link :to="`/manual/${tool.codeKebab}/01/02`">データソース</router-link>からアドタスカルと連携したいピクセルを選択し、「設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no07/01.png`" alt="" />
        <h4>【２】画面上に「ピクセルID」が表示されるので、後ほどアドタスカルの管理画面で設定するために、コピーして保存しておきます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no07/02.png`" alt="" />
        <h4>【３】画面を下へスクロールし、「ファーストパーティCookie」がオンになっているのを確認します。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no07/03.png`" alt="" />
        <p>オフになっている場合は、「編集」→「オン」→「変更を保存」の順にクリックしてオンに変更してください。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no07/04.gif`" alt="" />
        <h4>【４】さらに画面を下へスクロールし、「手動で設定」の欄にある「アクセストークンを生成」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no07/05.png`" alt="" />
        <h4>【５】画面上に「アクセストークン」が表示されるので、後ほどアドタスカルの管理画面で設定するために、コピーして保存しておきます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no07/06.png`" alt="" />
        <p>以上で、ピクセルIDとアクセストークンの取得が完了です。</p>
        <p>次に、「<router-link :to="`/manual/${tool.codeKebab}/03/08`">ピクセルIDとアクセストークンをアドタスカルに登録する</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
