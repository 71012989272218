<template>
  <div class="ui-drawer">
    <div class="back-gray"
    :class="{ 'back-gray-show': openDrawer === true}"
    @click="switchDrawer">
    </div>
    <img
      @click="switchDrawer"
      :src="`${rt}/img/menu.png`"
      class="drawer-button"
      alt="ドロワーメニュー"
    />
    <div
      class="drawer-menu"
      :class="{
        'open-drawer': openDrawer === true,
        'close-drawer': openDrawer === false,
      }"
    >
      <div class="drawer-header">
        <img
          @click="switchDrawer"
          :src="`${rt}/img/drawer-close.jpg`"
          class="close-button"
          alt="閉じるドロワーメニュー"
        />
      </div>
      <div class="drawer-body">
        <LayoutSideBar :type="`hamburger`" @switchDrawer="switchDrawer" class="sidebar"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutSideBar from "@/components/Layout/SideBar.vue";
export default {
  name: "UiDrawer",
   components:{
     LayoutSideBar,
   },
  data() {
    return {
      openDrawer: false,
    };
  },
  computed: {
        ...mapGetters(["rt", "login", "openSidebar", "stockPoint"]),
  },
  methods: {
    switchDrawer() {
      this.openDrawer = this.openDrawer === false ? true : false;
    },
        move(path) {
      this.$router.push(path).catch((err) => {
        console.log(err);
      });
    },
    logout() {
      this.$axios
        .get(`${this.rt}/api/logout.php`)
        .then((res) => {
          console.log(res);
          this.$store.dispatch("setLogin", 0);
          this.$router.push("/logout");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$width: 320px;
.drawer-button {
  width: 38px;
  cursor: pointer;
  @media screen and (max-width: 767px){
    width: 38px;
  }
}
.drawer-menu {
  transition: all 1s 0s ease;
  position: fixed;
  top: 0;
  background-color: white;
  padding: 5px 10px 50px 10px;
  box-sizing: border-box;
  z-index: 999;
  width: $width;
  // height: 100vh;
  .drawer-header {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    img.close-button {
      width: 45px;
      margin: 8px;
      cursor: pointer;
    }
  }
  }

.open-drawer {
  transform: translateX(-$width);
  // right: translateX(-$width);
  right: -$width;
  height: 95%;
  overflow-y: auto !important;
  overflow-x: hidden;
 -webkit-overflow-scrolling: touch;

}
.close-drawer {
  transform: translateX($width);
  right: -$width;
  // right: 0-$width;
}

.back-gray {
  display: none;
  position: fixed;
  z-index: 995;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color:gray;
  opacity: 0.4;
}
.back-gray-show {
  display: initial;
}
</style>
