<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（TOSHOアフィリエイト）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a href="https://af.tosho-trading.co.jp/affiliator/login.php" target="_blank" rel="noopener noreferrer">管理画面</a
          >にログインし、「一括CSVダウンロード」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no27/01.png`" alt="" />
        <h4>【２】検索期間を選択し「検索」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no27/02.png`" alt="" />
        <h4>【３】CSVをダウンロードします。「CSVダウンロード」をクリックします。</h4>
        
        <img :src="`${rt}/img/manual/google-cv/no07/no27/03.png`" alt="" />

        <p>これで成果CSVのダウンロードは完了です。</p>
        「
        <router-link :to="`/manual/${tool.codeKebab}/07/14`"
          >ASPの成果データをアドタスカルにインポートする</router-link
        >」に進んでください。
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  components: {
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
