<template>
  <div class="history-order-and-point-list">
    <el-tabs type="border-card">
      <el-tab-pane label="購入履歴">
        <div class="flex">
          <el-pagination
            :page-size="pageSize"
            :pager-count="pagerCount"
            :total="numberOfOrderData"
            :current-page.sync="orderPageNumber"
            layout="prev, pager, next"
          />
          <div class="right">
            全 {{ numberOfOrderData }} 件（過去180日分）<el-tooltip
              class="item"
              effect="dark"
              content="180日を超えたものは順次削除されますのでご注意ください"
              placement="top"
              ><i class="el-icon-question"></i
            ></el-tooltip>
          </div>
        </div>
<div class="table-cover">
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>ご購入日</th>
              <th style="text-align: right">お支払料金</th>
              <th></th>
              <th>詳細</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orderData in orderList" :key="orderData.orderId">
              <td>
                {{ orderData.orderDate }}
              </td>
              <td style="text-align: right">
                {{ orderData.total | localeNum }} 円
              </td>
              <td></td>
              <td class="btn">
                <router-link :to="`/history-detail/${orderData.orderId}`">
                  <el-button>詳細</el-button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="numberOfOrderData"
          :current-page.sync="orderPageNumber"
          layout="prev, pager, next"
        />
      </el-tab-pane>

      <el-tab-pane label="ポイント履歴">
        <div class="flex">
          <el-pagination
            :page-size="pageSize"
            :pager-count="pagerCount"
            :total="numberOfPointData"
            :current-page.sync="pointPageNumber"
            layout="prev, pager, next"
          />
          <div class="right">
            全 {{ numberOfPointData }} 件（過去2年分）
            <el-tooltip
              class="item"
              effect="dark"
              content="2年を超えたものは順次削除されますのでご注意ください"
              placement="top"
              ><i class="el-icon-question"></i
            ></el-tooltip>
          </div>
        </div>
<div class="table-cover">
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>獲得日時</th>
              <th style="text-align: right">獲得ポイント</th>
              <th></th>
              <th style="width: 15%">獲得区分</th>
              <th style="width: 15%">有効期限</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pointData, pointIndex) in pointList" :key="pointIndex">
              <td>
                {{ pointData.getDatetime }}
              </td>
              <td style="text-align: right">
                {{ pointData.getPoint | localeNum }}
              </td>
              <td></td>
              <td>
                {{ pointData.typeName }}
              </td>
              <td>
                {{ pointData.limitDate }}
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="numberOfPointData"
          :current-page.sync="pointPageNumber"
          layout="prev, pager, next"
        />
      </el-tab-pane>

      <el-tab-pane label="ポイント消費履歴">
        <div class="flex">
          <el-pagination
            :page-size="pageSize"
            :pager-count="pagerCount"
            :total="numberOfUsedPointData"
            :current-page.sync="usedPointPageNumber"
            layout="prev, pager, next"
          />
          <div class="right">
            全 {{ numberOfUsedPointData }} 件（過去1年分）
            <el-tooltip
              class="item"
              effect="dark"
              content="1年を超えたものは順次削除されますのでご注意ください"
              placement="top"
              ><i class="el-icon-question"></i
            ></el-tooltip>
          </div>
        </div>
<div class="table-cover">
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>消費日時</th>
              <th style="text-align: right">消費ポイント</th>
              <th></th>
              <th style="width: 30%">消費区分</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(usedPointData, pointIndex) in usedPointList" :key="pointIndex">
              <td>
                {{ usedPointData.usedDatetime }}
              </td>
              <td style="text-align: right">
                {{ usedPointData.usedPoint | localeNum }}
              </td>
              <td></td>
              <td>
                {{ usedPointData.toolName }}
              </td>
            </tr>
          </tbody>
        </table>
        </div>
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="numberOfUsedPointData"
          :current-page.sync="usedPointPageNumber"
          layout="prev, pager, next"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      orderList: [],
      pointList: [],
      usedPointList: [],
      pageSize: 100,
      pagerCount: 7,
      orderPageNumber: 1,
      pointPageNumber: 1,
      usedPointPageNumber: 1,
      numberOfOrderData: 0,
      numberOfPointData: 0,
      numberOfUsedPointData: 0,
      elText: "365日を超えたものは順次削除されますのでご注意ください",
    };
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  watch: {
    orderPageNumber() {
      this.getOrderList(this.orderPageNumber, this.pointPageNumber);
    },
    pointPageNumber() {
      this.getOrderList(this.orderPageNumber, this.pointPageNumber);
    },
    usedPointPageNumber() {
      this.getOrderList(this.orderPageNumber, this.pointPageNumber, this.usedPointPageNumber)
    }
  },
  created() {
    this.getOrderList(this.orderPageNumber, this.pointPageNumber);
  },
  methods: {
    getOrderList(orderPageNumber, pointPageNumber, usedPointPageNumber = 1) {
      let params = new URLSearchParams();
      params.append("page_size", this.pageSize);
      params.append("order_page_number", orderPageNumber);
      params.append("point_page_number", pointPageNumber);
      params.append("used_point_page_number", usedPointPageNumber);
      this.$axios
        .post(`${this.rt}/api/getOrderList.php`, params)
        .then((res) => {
          console.log(res);
          this.orderList = res.data.orderList;
          this.numberOfOrderData = res.data.numberOfOrderData;
          this.pointList = res.data.pointList;
          this.numberOfPointData = res.data.numberOfPointData;
          this.usedPointList = res.data.usedPointList;
          this.numberOfUsedPointData = res.data.numberOfUsedPointData;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
   @media screen and (max-width: 767px){
     flex-direction: column;
     justify-content: center;
   }
  .el-pagination {
    width: 50%;
  }
  .right {
    width: 50%;
    text-align: right;
     @media screen and (max-width: 767px){
       width: 100%;
     }
  }
}
.table-cover{
   @media screen and (max-width: 767px){
     overflow: auto;
     table{
       width: 767px;
     }
   }
}
</style>
