<template>
  <el-button
    v-if="showButton === true"
    type="primary"
    @click="continueUsingCartSystem"
    >継続</el-button
  >
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ContinueButton",
  components: {},
  props: {},
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["rt", "userData"]),
    ...mapGetters("tools02", ["activeCartSystemSubscriptionData"]),
    showButton() {
      //
      let showButton = false;

      //
      if (this.activeCartSystemSubscriptionData !== undefined) {
        // ok.
      } else {
        return showButton;
      }
      console.log(this.activeCartSystemSubscriptionData["endDate"]);

      // 現在時刻を取得
      const today = new Date();
      const aMonthLaterDate = new Date();
      aMonthLaterDate.setMonth(today.getMonth() + 1);
      console.log(today);
      console.log(aMonthLaterDate);

      // 指定した日付をDateオブジェクトに変換
      const endDatetime = this.activeCartSystemSubscriptionData["endDate"] + " 00:00:00";
      const endDate = new Date(endDatetime);
      console.log(endDate);

      // 日付を比較し、dateAが現在時刻より前であるかをチェック
      if (endDate <= aMonthLaterDate) {
        showButton = true;
      } else {
        //
      }

      //
      return showButton;
    },
  },
  watch: {},
  methods: {
    continueUsingCartSystem() {
      //
      let message = "";
      message += "継続の申し込みをします。よろしいですか？";
      if (confirm(message)) {
        // through.
      } else {
        return;
      }

      //
      this.$axios
        .post(`${this.rt}/l/api/tools02/cart-system/continue`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            let alertMessage =
              "お申し込みありがとうございます！\n" +
              "ご登録のメールアドレス宛にお振込み先を記載したメールを送付いたしました。\n" +
              "ご確認お願いいたします。\n" +
              "メールが届かない場合は、迷惑メールボックスの中をご確認ください。";
            alert(alertMessage);
            this.$store.dispatch("tools02/setCartSystemSubscriptionList");
          } else {
            alert("申込処理に失敗しました。");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
