<template>
  <div class="point-payment-description">
    <p>アフィリエイト報酬は、月末締めで集計し、振込手数料として税込500円を差し引いた金額を翌月末日にお振り込みします。月間の報酬金額が税込5,500円未満の場合は翌月の報酬と合算してお振り込みとなります。</p>
    <p>
      アフィリエイト報酬は、ポイントでのお受け取りも可能です。<span
        v-show="displayConfigurableDescription"
        >後ほど管理画面上で選択できます。</span
      >ポイントでのお受け取りの場合、以下のメリットがあります。
    </p>
    <ul>
      <li>振込手数料が引かれない</li>
      <li>課税対象にならない</li>
      <li>
        ポイント受取の繰越なし（現金の場合、月間の報酬額{{
          rewardBorderWithTax | localeNum
        }}円未満は繰越）
      </li>
      <li>
        月末締め翌月5営業日までにポイント付与（現金の場合、翌月25日支払い）
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PointPaymentDescription",
  components: {},
  props: {
    displayConfigurableDescription: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("affiliate", ["rewardFeeWithTax", "rewardBorderWithTax"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
