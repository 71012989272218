<template>
  <div class="home-tool-list">
    <el-row class="tool-list-header pc-only">
      <el-col :offset="17" :span="3" class="use-amounts-and-points"
        >当月利用日数</el-col
      >
      <el-col :span="4" class="use-amounts-and-points"
        >当月利用ポイント数</el-col
      >
    </el-row>
    <template v-for="(toolData, index) in topPageToolList">
      <el-row
        v-if="
          toolData.toolId === '7' ||
          toolData.toolId === '8' ||
          toolData.toolId === '10' ||
          toolData.toolId === '11' ||
          toolData.toolId === '12' ||
          toolData.toolId === '13' ||
          toolData.toolId === '14' ||
          toolData.toolId === '15' ||
          toolData.toolId === '16' ||
          (toolData.toolId === '1' && isCtrlUser === true) ||
          (toolData.toolId === '2' && isCtrlUser === true) ||
          (toolData.toolId === '3' && isCtrlUser === true) ||
          (toolData.toolId === '6' && isCtrlUser === true)
        "
        :key="index"
        type="flex"
        align="middle"
        :gutter="20"
        class="tool-list"
      >
        <el-col :xs="24" :sm="0" class="tool-name">{{ toolData.name2 }}</el-col>
        <el-col :xs="9" :sm="2" :md="2" :lg="2" class="media-logo"
          ><img :src="`${rt}/img/tool${toolData.id}.jpg`" :alt="toolData.name2"
        /></el-col>
        <el-col :xs="0" :sm="7" :md="7" :lg="9" class="tool-name"
          ><div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <span
              v-if="
                toolData.id === '11' && g_todayIsBefore('2024-08-08') === true
              "
              >TikTok広告<br />成果連携ツール</span
            >
            <span v-else>{{ toolData.name2 }}</span>
            <span
              v-if="
                toolData.id === '11' && g_todayIsBefore('2024-08-08') === true
              "
              style="color: red; font-weight: bold"
              >β版公開中!8/7まで<br />1日100ポイントで利用可能</span
            >
          </div></el-col
        >
        <el-col :xs="15" :sm="4" :md="4" :lg="3" class="to-tool-top-button"
          ><router-link
            :to="`/${
              toolData.consumptionType === '2' ? 'tools02' : 'tools01'
            }/${toolData.codeKebab}`"
            ><div class="button">管理画面</div></router-link
          ></el-col
        >
        <el-col
          :xs="0"
          :sm="4"
          :md="4"
          :lg="3"
          class="to-tool-description-button"
          ><router-link :to="`/manual/${toolData.codeKebab}/02/01`"
            ><div class="button">ツール説明</div></router-link
          ></el-col
        >
        <el-col :xs="0" :sm="3" :md="3" :lg="3">
          <div class="use-amounts-cover">
            <div class="title sp-only">当日利用日数</div>
            <div class="use-amounts">
              {{
                spentAmountsThisMonthInEachTool.amount[toolData.code2]
                  | localeNum
              }}
            </div>
          </div>
        </el-col>
        <el-col :xs="0" :sm="4" :md="4" :lg="4">
          <div class="use-points-cover">
            <div class="title sp-only">当日利用ポイント数</div>
            <div class="use-points">
              {{
                spentAmountsThisMonthInEachTool.point[toolData.code2]
                  | localeNum
              }}
            </div>
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tool-list",
  components: {},
  props: {},
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "topPageToolList",
      "userData",
      "spentAmountsThisMonthInEachTool",
    ]),
    isCtrlUser() {
      let isCtrlUser = false;
      if (
        this.userData.userId === "1" ||
        this.userData.userId === "2" ||
        this.userData.userId === "3" ||
        this.userData.userId === "4" ||
        this.userData.userId === "5"
      ) {
        isCtrlUser = true;
      } else {
        // no action.
      }

      //
      return isCtrlUser;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
//
.tool-list-header,
.tool-list {
  margin: 0;
  padding: 10px 0;
  font-weight: bold;
  .use-amounts,
  .use-points {
    text-align: center;
  }
}

//
.tool-list {
  $boderColor: #ddd;
  flex-wrap: wrap;
  border-bottom: solid 1px $boderColor;
  min-height: 90px;
  &:last-child {
    border: none;
  }
  @media screen and (max-width: 768px) {
    border: none;
  }

  //
  .media-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    // @media screen and (max-width: 768px) {
    //   text-align: center;
    // }
    img {
      width: 100%;
      @media screen and (max-width: 768px) {
        width: 50%;
      }
    }
  }

  //
  .tool-name {
    @media screen and (max-width: 768px) {
      text-align: center;
      border: solid 1px $boderColor;
      border-right: none;
      border-left: none;
      font-size: 16px;
      // font-size: 20px;
      padding: 5px;
      margin: 15px auto;
    }
  }

  //
  .to-tool-top-button,
  .to-tool-description-button {
    a {
      text-decoration: none;
      .button {
        border: solid 1px;
        border-radius: 15px;
        padding: 3px;
        text-align: center;
        @media screen and (max-width: 768px) {
          // display: inline-block;
          // width: 60%;
          height: 90px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 28px;
        }
      }
    }
  }
  $buttonSpacing: 20px;
  .to-tool-top-button {
    // @media screen and (max-width: 768px) {
    //   text-align: right;
    // }
    .button {
      $blue: rgb(89, 118, 186);
      color: $blue;
      border-color: $blue;
      // @media screen and (max-width: 768px) {
      //   margin-right: $buttonSpacing;
      // }
    }
  }
  .to-tool-description-button {
    .button {
      $red: rgb(231, 84, 80);
      color: $red;
      border-color: $red;
      // @media screen and (max-width: 768px) {
      //   margin-left: $buttonSpacing;
      // }
    }
  }

  //
  .use-amounts-cover,
  .use-points-cover {
    // @media screen and (max-width: 768px) {
    //   width: 80%;
    //   margin: auto;
    //   margin-top: 25px;
    //   text-align: right;
    // }
    .title {
      // @media screen and (max-width: 768px) {
      //   font-size: 18px;
      // }
    }
    .use-amounts,
    .use-points {
      // @media screen and (max-width: 768px) {
      //   text-align: right;
      //   font-size: 20px;
      // }
    }
  }
}
</style>
