<template>
  <div v-loading="loading" class="affiliate-status-affiliated-user-list">
    <el-row v-if="affiliatedUserIdList.length > 0">
      <el-col>
        <h3>ユーザー個別利用状況</h3>
        <div v-for="(affiliatedUserId, index) in affiliatedUserIdList" :key="index">
          <AffiliatedUserData :affiliatedUserId="affiliatedUserId" />
          <hr style="margin: 100px 0;" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AffiliatedUserData from "@/components/Affiliate/Status/AffiliatedUserList/AffiliatedUserData.vue";

export default {
  name: "AffiliateStatusAffiliatedUserList",
  components: {
    AffiliatedUserData
  },
  props: {
    dateRange: Array
  },
  data() {
    return {
      loading: true,
      affiliatedUserIdList: []
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    dateRange: {
      handler(newDateRange) {
        this.getAffiliatedUserIdList(newDateRange);
      },
      immediate: true,
    },
  },
  methods: {
    getAffiliatedUserIdList(dateRange) {
      this.loading = true;
      this.$axios
        .get(`${this.rt}/api/affiliate/get-affiliated-user-id-list.php?date_range=${encodeURIComponent(JSON.stringify(dateRange))}`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.affiliatedUserIdList = res.data.affiliatedUserIdList;
            this.loading = false;
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push('/login');
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
