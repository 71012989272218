<template>
  <div class="account">
    <el-row>
      <el-col>
        <h2>アカウント</h2>
      </el-col>
    </el-row>
    <MenuButtonList />
  </div>
</template>

<script>
import MenuButtonList from "@/components/Account/MenuButtonList.vue";

export default {
  components: {
    MenuButtonList,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
