<template>
  <div class="logout">
    <el-row>
      <el-col>
        <p style="text-align:center;margin-top:30px;">ログアウトしました。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="text-align:center;">
        <router-link to='/login'>
          <el-button type="primary">ログインページへ</el-button>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      email: '',
      pass: '',
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
