<template>
  <div class="tools02-tag-detail-tag-editor">
    <el-row>
      <el-col :span="4">
        <a
          :href="toolData.manualData.tagRegistrationManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <table class="typeB">
          <tr>
            <th>
              <span>
                <span
                  v-if="
                    toolData.codeKebab === 'facebook-cv' ||
                    toolData.codeKebab === 'tiktok-cv'
                  "
                  >ピクセル</span
                >
                <span v-else-if="toolData.codeKebab === 'yda-cv'"
                  >コンバージョン</span
                >
                <span v-else-if="toolData.codeKebab === 'line-cv'">LINE Tag</span>
                <span>名</span>
              </span>
            </th>
            <td>
              <el-input v-model="tagData.name"></el-input>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.name"
              />
            </td>
          </tr>
          <tr>
            <th>
              <span>
                <span
                  v-if="
                    toolData.codeKebab === 'facebook-cv' ||
                    toolData.codeKebab === 'tiktok-cv'
                  "
                  >ピクセルID</span
                >
                <span v-else-if="toolData.codeKebab === 'line-cv'"
                  >LINE Tag ID</span
                >
                <span v-else-if="toolData.codeKebab === 'yda-cv'"
                  >yahoo_ydn_conv_io</span
                >
              </span>
            </th>
            <td>
              <el-input v-model="tagData.mediaTagId"></el-input>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.mediaTagId"
              />
            </td>
          </tr>
          <tr>
            <th>
              <span
                v-if="
                  toolData.codeKebab === 'facebook-cv' ||
                  toolData.codeKebab === 'tiktok-cv' ||
                  toolData.codeKebab === 'line-cv'
                "
                >アクセストークン</span
              >
              <span v-else-if="toolData.codeKebab === 'yda-cv'"
                >yahoo_ydn_conv_label</span
              >
            </th>
            <td>
              <el-input v-model="tagData.accessToken"></el-input>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.accessToken"
              />
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col>
        <div class="button-cover">
          <el-button
            v-if="toolData.codeKebab !== 'facebook-cv'"
            @click="register"
            type="primary"
            >登録</el-button
          >
          <el-button v-else @click="registerPixelData" type="primary"
            >登録</el-button
          >
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  name: "Tools02TagDetailTagEditor",
  components: {
    Button2,
    ValidationErrorMessages,
  },
  props: {
    toolId: String,
    accountId: String,
    tagId: String,
  },
  data() {
    return {
      tagData: {
        name: "",
        mediaTagId: "",
        accessToken: "",
        testCode: "",
      },
      validationErrorData: {
        name: [],
        mediaTagId: [],
        accessToken: [],
        testCode: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "userData"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {
    tagId: {
      handler(newTagId) {
        if (newTagId !== "0") {
          this.tagData = JSON.parse(
            JSON.stringify(
              this.$store.getters["tools02/tagData"](this.toolId, newTagId)
            )
          );
        } else {
          console.log("新規登録なので初期値のまま。");
        }
      },
      immediate: true,
    },
  },
  methods: {
    register() {
      let params = new URLSearchParams();
      params.append("tag_id", this.tagId);
      params.append("tool_id", this.toolId);
      params.append("account_id", this.accountId);
      params.append("name", this.tagData.name);
      params.append("media_tag_id", this.tagData.mediaTagId);
      params.append("access_token", this.tagData.accessToken);
      params.append("test_code", this.tagData.testCode);
      this.$axios
        .post(`${this.rt}/l/api/tools02/tag/register`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("setUserData");
            this.$store.dispatch("tools02/setTagList");
            this.$router.go(-1);
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    registerPixelData() {
      let params = new URLSearchParams();
      params.append("pixel_id", this.tagId);
      params.append("pixel_name", this.tagData.name);
      params.append("pixel_id_for_api", this.tagData.mediaTagId);
      params.append("access_token", this.tagData.accessToken);
      params.append("test_code", this.tagData.testCode);
      this.$axios
        .post(`${this.rt}/api/tools02/facebook-cv/reg-pixel-detail.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$store.dispatch("tools02/setPixels");
            this.$store.dispatch("setUserData");
            this.$router.go(-1);
          } else if (res.data.status === 400) {
            this.validationErrorData = res.data.validationErrorData;
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.validation-error-message {
  font-size: 12px;
  color: red;
}
.button-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
