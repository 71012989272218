<template>
  <div class="affiliate-notice-notification-config">
    <el-row>
      <el-col>
        <p>成果の発生、または、報酬の確定があった際に指定のメールアドレスに通知を入れることができます。</p>
        <table class="af-pop">
          <tr>
            <th>成果発生（新規ユーザー獲得）の通知設定</th>
            <td>
              <el-radio-group v-model="userData.affiliateAchievementsNotification">
                <el-radio :label="'1'">通知する</el-radio>
                <el-radio :label="'0'">通知しない</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <th>通知先のアドレス設定</th>
            <td>
              <el-input v-model="userData.affiliateAchievementsNotificationEmail" type="email"/>
            </td>
          </tr>
          <tr>
            <th>報酬確定の通知設定</th>
            <td>
              <el-radio-group v-model="userData.affiliateSettlementsNotification">
                <el-radio :label="'1'">通知する</el-radio>
                <el-radio :label="'0'">通知しない</el-radio>
              </el-radio-group>
            </td>
          </tr>
          <tr>
            <th>通知先のアドレス設定</th>
            <td>
              <el-input v-model="userData.affiliateSettlementsNotificationEmail" type="email"/>
            </td>
          </tr>
        </table>
        <div class="register-button-cover">
          <el-button @click="registerConfigData" type="primary">更新</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateNoticeNotificationConfig",
  components: {},
  props: {},
  data() {
    return {
      userData: {}
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  created() {
    this.userData = JSON.parse(JSON.stringify(this.$store.getters["userData"]));
  },
  methods: {
    registerConfigData() {
      let params = new URLSearchParams();
      params.append("user_data", JSON.stringify(this.userData));
      this.$axios
        .post(`${this.rt}/api/affiliate/notification/reg-notification-data.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$store.dispatch("setUserData");
            this.$router.go(-1);
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push('/login');
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
table.af-pop {
  tr {
    th {
      width: 45%;
      height: 40px;
       @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    td {
      text-align: left;
    }
  }
}
</style>
