<template>
  <div class="format-list">
    <el-row v-if="type === 1 || type === 2" style="margin-top: 30px">
      <el-col>
        <el-button type="primary" @click="regFormat(0)">新規作成</el-button>
        <el-button type="success" @click="regFormat(1)"
          >他の{{ toolData.name }}から転用</el-button
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3 class="back-gray" style="margin-top: 10px">
          フォーマット
          <Question
            v-if="type === 0"
            :placement="'right'"
            :content="`コピーしたいフォーマットの下部にある「コピー」をクリックすると、フォーマットのコピーができます。`"
          />
          <Question
            v-else
            :placement="'right'"
            :content="`以下に作成したフォーマットのみ、表示する${toolData.name}として選択できるようになります。`"
          />
        </h3>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <draggable
          v-model="formatList"
          group="myGroup"
          class="format-list-box"
          :options="options"
        >
          <div
            class="draggable"
            style="width: 33%; margin-top: 30px; margin-bottom: 40px"
            v-for="(formatData, index) in formatList"
            :key="index"
            :class="{
              selected:
                formatData.formatId === display_now && toolData.code2 !== 'popup01',
            }"
          >
            <div
              class="format-list-name"
              :class="{
                selected_name:
                  formatData.formatId === display_now &&
                  toolData.code2 !== 'popup01',
              }"
            >
              フォーマット名: <span>{{ formatData.name }}</span>
            </div>
            <div
              class="format-display-cover"
              :style="
                toolData.code2 === 'overlay01' || toolData.code2 === 'overlay02'
                  ? 'height: 100px;'
                  : ''
              "
            >
              <FormatDisplay :size="`mini`" :format="formatData" />
            </div>
            <div class="format-list-select-btn">
              <el-button
                @click="formatDecide(formatData.formatId)"
                type="primary"
                style="margin-right: 20px"
                >{{ buttonName }}</el-button
              >
              <el-button @click="deleteFormat(formatData)" type="danger"
                >削除</el-button
              >
            </div>
            <div
              v-show="type === 1 && toolData.code2 !== 'popup01'"
              style="text-align: center"
            >
              <input type="radio" v-model="display" :value="formatData.formatId" />
            </div>
          </div>
        </draggable>
      </el-col>
    </el-row>
    <el-row
      v-show="formats !== 0 && type === 1 && toolData.code2 !== 'popup01'"
      type="flex"
      justify="center"
    >
      <el-col :span="8" style="text-align: center">
        <el-button @click="regDisplayFormat('dis')" type="primary"
          >更新</el-button
        >
      </el-col>
    </el-row>
    <!-- <el-row v-show="formats !== 0 && type === 1" type="flex" justify="center">
      <el-col style="text-align: center">
        <Question
          :placement="'left'"
          :content="'登録済のフォーマットをドラッグ・アンド・ドロップで並び替えることができます。<br>並び替えた後、「フォーマット並び替え」をクリックして、並び替えを確定させてください。'"
        />
        <el-button @click="regFormatSort">フォーマット並び替え</el-button>
      </el-col>
    </el-row> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import draggable from "vuedraggable";
import FormatDisplay from "@/components/Ui/Tools01/FormatDisplay.vue";
import Question from "@/components/Ui/Question.vue";

export default {
  name: "FormatList",
  props: {
    type: Number, //0: フォーマットを決定するだけ, 1:ページ編集画面（表示フォーマットの決定ができる）, 2: ABテスト
    toolId: String,
    pageId: String,
    buttonName: String,
  },
  components: {
    FormatDisplay,
    // draggable,
    Question,
  },
  data() {
    return {
      display_now: 0,
      display: 0,
      formats: 0,
      count: 0,
      tag: "",
      options: {
        group: "myGroup",
        str01: 200,
      },
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },
    pageData() {
      return this.$store.getters["tools01/pageDataBySomeIds"](
        this.toolId,
        this.pageId
      );
    },
    formatList() {
      return this.$store.getters["tools01/formatListByPageId"](this.pageData.id);
    },
  },
  watch: {
    display(newValue) {
      this.$emit("changeDisplay", newValue);
    },
  },
  created() {
    this.getInfo(this.toolData.code2, this.pageId);
    this.formats = this.formatList.length;
  },
  methods: {
    formatDecide(format_id) {
      this.$emit("formatDecide", format_id);
    },
    getInfo(toolCode, pageId) {
      this.$axios
        .get(
          `${this.rt}/api/tools01/getSiteDetail.php?tool=${toolCode}&site_id=${pageId}`
        )
        .then((response) => {
          console.log(response);
          this.display_now = response.data.list.display;
          this.display = response.data.list.display;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    regDisplayFormat() {
      this.$emit("register");
    },
    regFormatSort() {
      var list = JSON.stringify(this.formatList);
      let params = new URLSearchParams();
      params.append("tool", this.toolData.code2);
      params.append("site_id", this.pageId);
      params.append("list", list);
      this.$axios
        .post(`${this.rt}/api/tools01/regFormatSort.php`, params)
        .then((response) => {
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    regFormat(type) {
      console.log(type);
      this.$store.dispatch("setFormatEditFrom", this.pageData.name);

      //
      if (type === 0) {
        //新規登録
        this.$router.push(
          `/tools01/${this.toolData.code2}/format/${this.pageId}/0`
        );
      } else if (type === 1) {
        //コピー
        this.$router.push(
          `/tools01/${this.toolData.code2}/copy/${this.pageId}`
        );
      }
    },
    deleteFormat(formatData) {
      //
      let deletingDisplaySettingFormat = false;
      if (this.toolData.code2 === "popup01") {
        if (
          this.pageData.firstFormatId !== formatData.id &&
          this.pageData.secondFormatId !== formatData.id &&
          this.pageData.thirdFormatId !== formatData.id
        ) {
          // ok!
        } else {
          deletingDisplaySettingFormat = true;
        }
      } else {
        if (this.pageData.displayFormatId !== formatData.id) {
          // ok!
        } else {
          deletingDisplaySettingFormat = true;
        }
      }

      //
      if (deletingDisplaySettingFormat === false) {
        // ok!
      } else {
        alert(
          "このフォーマットは表示フォーマットに設定されているので削除できません。"
        );
        return;
      }

      //
      let confirmMessage = "";
      confirmMessage += `フォーマット名: ${formatData.name}\n`;
      confirmMessage += `を削除します。よろしいですか？`;
      if (confirm(confirmMessage)) {
        //
      } else {
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("format_id", formatData.id);
      this.$axios
        .post(`${this.rt}/l/api/tools01/format/delete`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch('tools01/setFormatList');
          } else {
            //
          }
        })
        .catch((error) => {
          console.log(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.format-list-box {
  .draggable {
    cursor: initial;
  }
}
.format-display-cover {
  overflow: hidden;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eee;
  box-sizing: border-box;
  border: 3px solid #fff;
}
.selected {
  background-color: rgb(255, 222, 255);
  .format-display-cover {
    background: transparent;
    border: none;
  }
}
.format-list-select-btn {
  margin: 20px auto 10px;
  display: flex;
  justify-content: center;
}
.el-radio__label {
  padding-left: 0;
}
.go-to-frame {
  margin-top: 10px;
  .el-row {
    .el-col {
      text-align: center;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
