<template>
  <div class="format0104">
    <div class="format01">
      <div class="popup-title-box" :style="display01+'background-color: '+format.color02">
        <div class="txt-box" v-html="format.text02"></div>
      </div>
      <div class="popup-img-box" :style="border">
        <div v-if="format.img == ''">画像</div>
        <img v-else :src="rt+uploadsFolder+format.img" alt />
      </div>
      <div class="popup-title-box" :style="display02+'background-color: '+format.color02+';border-top:solid 1px #000;'">
        <div class="txt-box" v-html="format.text02"></div>
      </div>
      <div class="popup-normal-box">
        <div class="txt-box" v-html="format.text03"></div>
      </div>
      <div class="popup-button-box">
        <div class="btn" :style="'background-color: '+format.color04" v-html="format.text04"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Format0104",
  props: {
    format: [Array, Object]
  },
  data() {
    return {
      display01: "",
      display02: "",
      border: ""
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    uploadsFolder() {
      return this.$store.getters.uploadsFolder;
    }
  },
  watch: {
    "format.type"(newValue) {
      this.changeDisplay(newValue);
    },
  },
  created() {
    this.changeDisplay(this.format.type);
  },
  methods: {
    changeDisplay(val) {
      if (val == '4') {
        this.display01 = "display:none;";
        this.display02 = "";
        this.border = "";
      } else if (val == '5') {
        this.display01 = "";
        this.display02 = "display:none;";
        this.border = "border-bottom: solid 1px #000";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.format01 {
  background: white;
  border: solid 1px #000;
  .popup-title-box,
  .popup-normal-box,
  .popup-img-box,
  .popup-button-box {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    padding: 10px;
  }

  .popup-title-box {
    border-bottom: solid 1px #000;
    box-sizing: border-box;
  }

  .popup-title-box,
  .popup-normal-box {
    .txt-box {
      text-align: center;
    }
  }

  .popup-img-box {
    position: relative;
    height: 180px;
    padding: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-bottom: solid 1px #000;
    box-sizing: border-box;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }

  .popup-button-box {
    text-align: right;
  }
  .popup-button-box {
    .btn {
      padding: 5px 10px;
      background-color: white;
      display: inline-block;
      border: solid 1px #000;
      box-sizing: border-box;
    }
  }
}
</style>
