<template>
    <div :class="'tinymce-editor'+num">
        <Editor
        v-model="myValue"
        api-key="jg024mzr4orehm1c1b1y7jfmhqrz1j9oyqz6931bnf8rpirh"
        :init="{
          height: height,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar: 'undo | redo | forecolor backcolor fontsizeselect | bold underline',
          
          //入力時に改行が<p>にならないように
          force_br_newlines : true,
          force_p_newlines : false,
          forced_root_block : '',

          //テキストからコピペするときに改行が<p>にならないように
          paste_as_text: true
        }"
        @onInit="changeColor()"
        />
    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
import $ from 'jquery'

export default {
  name: 'TinymceEditor',
  components: {
    Editor
  },
  props:{
    value: String,
    height: Number,
    backcolor: String,
    num: Number,
  },
  data() {
    return {
      myValue: this.value,
      backcolor2: ''
    }
  },
  watch: {
    value: function(newValue) {
      this.myValue = newValue
    },
    myValue: function(newValue) {
      this.$emit('input', newValue)
    },
    backcolor: function() {
      this.changeColor(this.backcolor);
    }
  },
  methods: {
    changeColor: function() {
      this.$nextTick(function(){
        $('.tinymce-editor'+this.num+' .tox-edit-area__iframe').css({'background-color': this.backcolor});
      });
    }
  }
}
</script>

<style lang="scss" scoped>
</style>