var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-row',[_c('el-col',[_c('el-button',{staticClass:"image-button",attrs:{"size":"medium"},on:{"click":function($event){_vm.showImageEditor = true}}},[_c('i',{staticClass:"el-icon-picture-outline"}),_vm._v("メディアを追加")]),_c('Editor',{attrs:{"api-key":"jg024mzr4orehm1c1b1y7jfmhqrz1j9oyqz6931bnf8rpirh","init":{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo | redo | forecolor backcolor fontsizeselect | bold underline',

        //入力時に改行が<p>にならないように
        force_br_newlines: true,
        force_p_newlines: false,
        forced_root_block: '',

        //テキストからコピペするときに改行が<p>にならないように
        paste_as_text: true,
      }},model:{value:(_vm.localText),callback:function ($$v) {_vm.localText=$$v},expression:"localText"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImageEditor === true),expression:"showImageEditor === true"}]},[_c('div',{staticClass:"image-editor-background",on:{"click":function($event){_vm.showImageEditor = false}}}),_c('div',{staticClass:"image-editor"},[_c('div',{staticClass:"image-editor-header"},[_c('h3',[_vm._v("メディアを追加")]),_c('div',{staticClass:"close-button",on:{"click":function($event){_vm.showImageEditor = false}}},[_vm._v("×")])]),_c('el-tabs',{attrs:{"type":"card"},model:{value:(_vm.imageEditorTab),callback:function ($$v) {_vm.imageEditorTab=$$v},expression:"imageEditorTab"}},[_c('el-tab-pane',{attrs:{"label":"ファイルをアップロード","name":"upload"}},[_c('div',{staticClass:"file-select-button-cover"},[_c('label',{attrs:{"for":"file-select"}},[_c('div',{staticClass:"file-select-button"},[_vm._v("ファイルを選択")])]),_c('p',[_vm._v("最大アップロードサイズ: 1 MB。")])]),_c('input',{staticStyle:{"display":"none"},attrs:{"id":"file-select","name":"file","type":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadImage($event)}}})]),_c('el-tab-pane',{attrs:{"label":"メディアライブラリ","name":"images"}},[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("画像一覧")])])],1)],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }