<template>
  <div class="privileges-privilege-list">
    <p style="margin-left: 5px">
      自動インポート成功後に特典が表示されていない場合は、当管理画面から一度ログアウトし、再ログインしてご確認をお願いします。
    </p>
    <div class="table-cover">
      <table class="typeA stripe" style="width: 100%">
        <thead>
          <tr>
            <th>特典名</th>
            <th style="width: 15%">提供者名</th>
            <th style="width: 320px; text-align: center">閲覧条件</th>
            <th style="width: 90px; text-align: center">ステータス</th>
          </tr>
        </thead>
        <tbody v-if="privilegeList.length > 0">
          <tr v-for="(privilegeData, index) in privilegeList" :key="index">
            <td>{{ privilegeData.privilegeName }}</td>
            <td>{{ privilegeData.providerName }}</td>
            <td style="text-align: center">
              <div v-if="privilegeData.conditionId === '2'">
                <span v-if="privilegeData.toolId === '0'"></span>
                <span v-else
                  >{{
                    $store.getters["toolData"](privilegeData.toolId).mediaName
                  }}の成果が</span
                >自動登録履歴で<br />「
                <el-button
                  type="success"
                  icon="el-icon-check"
                  circle
                  class="success-icon"
                />正常にインポート完了」したら
              </div>
              <div v-else>{{ privilegeData.conditionName }}</div>
            </td>
            <td style="text-align: center">
              <el-button
                v-if="privilegeData.acquired === '1'"
                @click="g_toPrivilegePage(privilegeData.privilegeId)"
                type="primary"
                >閲覧</el-button
              >
              <el-button v-else type="info">条件未達</el-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <FAQ :faqList="faqList"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FAQ from "@/components/Ui/FAQ.vue";

export default {
  name: "privileges-privilege-list",
  components: {
    FAQ,
  },
  props: {},
  data() {
    return {
      faqList: [
        {
          title: "特典の条件を達成しているのに特典が閲覧できません。",
          url: "https://adtasukaru.wraptas.site/fc70b251b30b4111938cbcf827bec755",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rt", "privilegeList"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.table-cover {
  overflow: auto;
  table {
    width: 1000px;
  }
}
</style>
