<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>プログラム情報登録<br class="sp-only">（サルクルー）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <PromotionIdDescription :aspId="'5'"/>
        <h4>
          【１】<a
            href="https://pub.monkey-ads.com/#/login"
            target="_blank"
            rel="noopener noreferrer"
            >サルクルーの管理画面</a
          >にログインしたら、「広告コード管理」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no09/01.png`" alt="" />
        <h4>
          【２】すでに提携しているプログラムが表示されるので、登録したいプログラム名探して、「広告名」をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no09/02.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>
        <h4>【３】アドタスカルのASPプログラム名に貼り付けます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no05/no09/03.png`" alt="" />
        </div>
        <h4>【４】プログラムIDはご自身で決めます。</h4>
        <p>◎決め方</p>
        <ul>
          <li>半角英数字</li>
          <li>同じASP内で実施している他の案件と被らないようにする</li>
        </ul>
        <h4 id="from-manual1">
          【５】アフィリエイトリンクの後ろに「&amp;p1=[プログラムID]」を追記する
        </h4>
        <h5>▼サルクルーから発行されたアフィリリンク</h5>
        <p>
          https://rcv.monkey-ads.com/api/click?id=○○○○○○○○○<span
            class="green"
            style="font-weight: bold"
            >&amp;mktag=●●●</span
          >
        </p>
        <h5>
          ▼「<span class="green">&amp;mktag=●●●</span>」が付いていたら削除する
        </h5>
        <p>https://rcv.monkey-ads.com/api/click?id=○○○○○○○○○</p>
        <h5>
          ▼【４】で決めたプログラムIDを「<span
            class="red"
            style="font-weight: bold"
            >&amp;p1=プログラムID</span
          >」という形で付ける
        </h5>
        <p>
          https://rcv.monkey-ads.com/api/click?id=○○○○○○○○○<span
            class="red"
            style="font-weight: bold"
            >&amp;p1=slimleg</span
          >
        </p>
        <p>これを記事LPや中間ページに設置してください。</p>
        <!-- <p class="red2">※サルクルーは他のASPと違い、プログラムIDを自分で命名して、それをアフィリリンクに付与する必要があるので注意してください。</p> -->
        <p class="red2">
          ※自動連携テストの際はさらにパラメータが必要となりますので、詳しくは<router-link
            :to="`/manual/${tool.codeKebab}/08/07`"
            >こちら</router-link
          >を参考にしてください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromotionIdDescription from "@/components/Manual/Tools02/No05/Parts/PromotionIdDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PromotionIdDescription
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
  created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>

</style>
