<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（A8.net）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>
          A8.netとアドタスカルを自動連携するために必要な「ポイントバック通知機能」が審査制となります。
        </p>
        <p>
          A8.net側でポイントバック通知機能の審査や設定に手数がかかるため、設定後にちゃんと利用していただけるメディア様のみにポイントバック通知機能を利用してもらいたいとのことです。
        </p>
        <p>そのため、以下の条件を満たす方が審査の対象となります。</p>
        <p class="dot"
        >
         
          ・A8.netの担当がついている<br />
          ・A8.net×アドタスカルを継続的に利用する見込みがある
          
        </p>
        <p>
          条件を満たしている場合は、<br />
          以下の情報をアドタスカルのサポートまでご連絡ください。
        </p>
        <p class="dot">
         
          ・メディアID（例：a12071318803）<br />
          ・登録名（例：株式会社グレイトヘルプ）<br />
          ・A8.netの担当者名<br />
          
        </p>
        <h4 style="margin-left:20px;">担当者が現在いない場合</h4>
<p>担当者が現在いない場合は、ポイントバック通知機能の利用ができません。<br>そのため、まずは一定の報酬をA8.net様で獲得し、その上で、A8.net様に「担当者を付けてほしい」と依頼をしてください。<br>担当者が付いた後、アドタスカルへ連携の依頼を上記の手順でお願いいたします。</p>

        <PointbackSettingDescription :aspId="'16'" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PointbackSettingDescription,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dot{
  border:1px black dashed;
  // border-bottom:2px black dashed;
  width: 45%;
  padding: 30px 10px;
  margin: 40px 20px;
  min-width: 423px;
  // font-weight: bold;
    @media screen and (max-width: 768px){
  
margin: 0;
width: 95%;
min-width: initial;
  
  }
}
</style>
