<template>
  <div class="tools02-account-detail-account-editor">
    <el-row>
      <el-col style="width: 170px">
        <a
          :href="toolData.manualData.accountRegistrationManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <div class="account-table">
      <div class="account-table-inner">
        <table class="typeB">
          <tr>
            <th>
              <span
                ><span v-if="tool.codeKebab === 'microsoft-cv'"
                  >マネージャー</span
                >アカウントID</span
              >
              <OriginalToolTip
                v-show="tool.codeKebab === 'google-cv'"
                :htmlText="'Google広告アカウント画面右上に表示されています。<br />画像内の赤枠で示した箇所の値を入力してください。'"
                :imgPath="'account-example01.png'"
              />
            </th>
            <td>
              <el-input v-model="accountData.accountCode"></el-input>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.accountCode"
              />
            </td>
          </tr>
          <tr>
            <th>
              <span
                ><span v-if="tool.codeKebab === 'microsoft-cv'"
                  >マネージャー</span
                >アカウント名</span
              >
              <OriginalToolTip
                v-show="tool.codeKebab === 'google-cv'"
                :htmlText="'Google広告アカウント画面右上に表示されています。<br />画像内の赤枠で示した箇所の値を入力してください。'"
                :imgPath="'account-example02.png'"
              />
            </th>
            <td>
              <el-input v-model="accountData.accountName"></el-input>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.accountName"
              />
            </td>
          </tr>
          <tr v-if="tool.codeKebab === 'google-cv'">
            <th>
              <span>種別</span>
              <OriginalToolTip
                v-show="tool.codeKebab === 'google-cv'"
                :htmlText="'【用語解説】<br />サブアカウント：広告運用するためのアカウント<br />MCCアカウント：サブアカウントを一元管理するためのアカウント<br /><br />【見分け方】<br />管理画面右上のアイコンをクリックすると紐づいているアカウントが表示されます。<br />アカウントIDの横に「MCC」が表示されていれば「MCCアカウント」になります。<br />何も表示されていなければ「サブアカウント」になります。<br /><img src=\'https://adtasukaru.com/img/google-cv/account-example03.png\' style=\'width: 70%;margin-top: 5px;\'/><br /><br />【注意点】<br />MCCアカウントを使っていてもコンバージョンをサブアカウントで作成している場合は、サブアカウントを登録してください。'"
              />
            </th>
            <td>
              <el-select
                v-model="accountData.accountType"
                placeholder="選択してください。"
              >
                <el-option
                  v-for="item in accountTypeNames"
                  :key="item.accountType"
                  :label="item.accountTypeName"
                  :value="item.accountType"
                ></el-option>
              </el-select>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.accountType"
              />
            </td>
          </tr>
          <tr v-if="tool.codeKebab === 'yahoo-cv'">
            <th>Yahoo!JAPAN<br />ビジネスID</th>
            <td>
              <el-select
                v-model="accountData.bussinessAccountId"
                placeholder="選択してください。"
              >
                <el-option
                  v-for="bussinessAccountData in bussinessAccountList"
                  :key="bussinessAccountData.bussinessAccountId"
                  :label="bussinessAccountData.bussinessId"
                  :value="bussinessAccountData.bussinessAccountId"
                ></el-option>
              </el-select>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.bussinessAccountId"
              />
            </td>
          </tr>
          <tr
            v-if="
              tool.codeKebab === 'google-cv' || tool.codeKebab === 'yahoo-cv'
            "
          >
            <th>API自動アップロード</th>
            <td>
              <span>
                <span style="margin-right: 5px">OFF</span>
                <el-switch v-model="accountData.autoUpload"></el-switch>
                <span style="margin-left: 5px">ON</span>
              </span>
            </td>
          </tr>
          <tr
            v-if="
              tool.codeKebab === 'google-cv' && accountData.autoUpload === true
            "
          >
            <th>API自動アップロード<br />（対象）</th>
            <td>
              <!-- <el-select
                v-model="accountData.apiUploadType"
                placeholder="選択してください。"
              >
                <el-option
                  v-for="(apiUploadTypeData, index) in apiUploadTypeList"
                  :key="index"
                  :label="apiUploadTypeData.name"
                  :value="apiUploadTypeData.type"
                ></el-option>
              </el-select>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.apiUploadType"
              /> -->
              <el-checkbox-group
                v-model="accountData.uploadGoogleClickIdTypeList"
              >
                <el-checkbox label="0">gclid</el-checkbox><br />
                <el-checkbox label="1">wbraid</el-checkbox><br />
                <el-checkbox label="2">gbraid（<a href="https://adtasukaru.wraptas.site/458af6554e174f3f82d06beb7561bd70?pvs=4#386b093c79b7484eaab3d8970b94a44b" target="_blank" rel="noopener noreferrer">非推奨</a>）</el-checkbox>
              </el-checkbox-group>
              <ValidationErrorMessages
                :errorMessageList="validationErrorData.googleClickIdTypeList"
              />
            </td>
          </tr>
          <tr
            v-if="
              tool.codeKebab === 'google-cv' || tool.codeKebab === 'yahoo-cv'
            "
            v-show="accountData.autoUpload === true"
          >
            <th>API自動アップロード<br />（時間帯）</th>
            <td>
              <div class="select-hours-box">
                <div class="button-cover">
                  <div class="button-outer">
                    <el-button
                      @click="changeAllHours(true)"
                      type="primary"
                      size="mini"
                      >全選択</el-button
                    >
                  </div>
                  <div class="button-outer">
                    <el-button
                      @click="changeAllHours(false)"
                      type="danger"
                      size="mini"
                      >全解除</el-button
                    >
                  </div>
                </div>
                <el-row
                  v-for="(hours, index) in [
                    ['00', '01', '02', '03', '04', '05'],
                    ['06', '07', '08', '09', '10', '11'],
                    ['12', '13', '14', '15', '16', '17'],
                    ['18', '19', '20', '21', '22', '23'],
                  ]"
                  :key="index"
                >
                  <el-col
                    :span="4"
                    v-for="(hour, index2) in hours"
                    :key="index2"
                    ><el-checkbox v-model="accountData['h' + hour]" />{{
                      hour | localeNum
                    }}</el-col
                  >
                </el-row>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="register" type="primary">登録</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
import OriginalToolTip from "@/components/Tools02/AccountDetail/OriginalToolTip.vue";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  name: "Tools02AccountDetailAccountEditor",
  components: {
    Button2,
    OriginalToolTip,
    ValidationErrorMessages,
  },
  props: {
    accountId: String,
  },
  data() {
    return {
      accountData: {
        accountCode: "",
        accountName: "",
        autoUpload: false,
        accountType: "",
        bussinessAccountId: "",
        apiUploadType: "",
        uploadGoogleClickIdTypeList: [
          '0'
        ],
        h00: false,
        h01: false,
        h02: false,
        h03: false,
        h04: false,
        h05: false,
        h06: false,
        h07: false,
        h08: false,
        h09: false,
        h10: false,
        h11: false,
        h12: false,
        h13: false,
        h14: false,
        h15: false,
        h16: false,
        h17: false,
        h18: false,
        h19: false,
        h20: false,
        h21: false,
        h22: false,
        h23: false,
      },
      apiUploadTypeList: [
        {
          type: "0",
          name: "全成果",
        },
        {
          type: "1",
          name: "wbraid、gbraidのみ",
        },
      ],
      validationErrorData: {
        accountCode: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "userData", "apiErrorMessage"]),
    ...mapGetters("tools02", ["accountTypeNames", "bussinessAccountList"]),
    toolData() {
      return this.$store.getters["toolData"](this.tool.id);
    },
  },
  watch: {
    accountId: {
      handler(newAccountId) {
        if (newAccountId === "0") {
          // no action.
        } else {
          this.accountData = JSON.parse(
            JSON.stringify(
              this.$store.getters["tools02/accountData"](newAccountId)
            )
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    register() {
      let params = new URLSearchParams();
      params.append("id", this.accountId);
      params.append("tool_id", this.tool.id);
      params.append("account_code", this.accountData.accountCode);
      params.append("account_name", this.accountData.accountName);
      params.append(
        "auto_upload",
        this.accountData.autoUpload === true ? "1" : "0"
      );
      params.append("account_type", this.accountData.accountType);
      params.append(
        "bussiness_account_id",
        this.accountData.bussinessAccountId
      );
      params.append("h00", this.accountData.h00 === true ? "1" : "0");
      params.append("h00", this.accountData.h00 === true ? "1" : "0");
      params.append("h01", this.accountData.h01 === true ? "1" : "0");
      params.append("h02", this.accountData.h02 === true ? "1" : "0");
      params.append("h03", this.accountData.h03 === true ? "1" : "0");
      params.append("h04", this.accountData.h04 === true ? "1" : "0");
      params.append("h05", this.accountData.h05 === true ? "1" : "0");
      params.append("h06", this.accountData.h06 === true ? "1" : "0");
      params.append("h07", this.accountData.h07 === true ? "1" : "0");
      params.append("h08", this.accountData.h08 === true ? "1" : "0");
      params.append("h09", this.accountData.h09 === true ? "1" : "0");
      params.append("h10", this.accountData.h10 === true ? "1" : "0");
      params.append("h11", this.accountData.h11 === true ? "1" : "0");
      params.append("h12", this.accountData.h12 === true ? "1" : "0");
      params.append("h13", this.accountData.h13 === true ? "1" : "0");
      params.append("h14", this.accountData.h14 === true ? "1" : "0");
      params.append("h15", this.accountData.h15 === true ? "1" : "0");
      params.append("h16", this.accountData.h16 === true ? "1" : "0");
      params.append("h17", this.accountData.h17 === true ? "1" : "0");
      params.append("h18", this.accountData.h18 === true ? "1" : "0");
      params.append("h19", this.accountData.h19 === true ? "1" : "0");
      params.append("h20", this.accountData.h20 === true ? "1" : "0");
      params.append("h21", this.accountData.h21 === true ? "1" : "0");
      params.append("h22", this.accountData.h22 === true ? "1" : "0");
      params.append("h23", this.accountData.h23 === true ? "1" : "0");
      params.append("google_click_id_type_list", JSON.stringify(this.accountData.uploadGoogleClickIdTypeList));
      this.$axios
        .post(`${this.rt}/l/api/tools02/account/register`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("tools02/setAccounts");
            this.$store.dispatch("setUserData");
            this.$router.go(-1);
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeAllHours(status) {
      for (let hour = 0; hour < 24; hour++) {
        let hourKeyName = "h" + ("0" + hour).slice(-2);
        this.accountData[hourKeyName] = status;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tooltip {
  margin-left: 5px;
}
.select-hours-box {
  .el-checkbox {
    margin-right: 5px;
  }
}
.account-table {
  display: flex;
  justify-content: center;
  .account-table-inner {
    width: 75%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  table {
    tr {
      th {
        // min-width: 183px;
        min-width: 162px;
        padding: 20px;
        @media screen and (max-width: 767px) {
          min-width: initial !important;
          width: 110px !important;
          font-size: 12px;
          padding-left: 0px;
          padding-right: 0px;
        }
      }
      td {
        @media screen and (max-width: 767px) {
          padding-right: 0;
          padding-left: 3px;
        }
        .el-input- {
          @media screen and (max-width: 767px) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
}
.button-cover {
  margin-bottom: 5px;
  display: flex;
  .button-outer {
    margin-right: 5px;
  }
}
</style>
