var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools02-tag-detail-tag-editor"},[_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('a',{attrs:{"href":_vm.toolData.manualData.tagRegistrationManualUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('Button2',{staticClass:"manual-button",attrs:{"type2":"1","msg":"マニュアル"}})],1)])],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":18}},[_c('table',{staticClass:"typeB"},[_c('tr',[_c('th',[_c('span',[(
                  _vm.toolData.codeKebab === 'facebook-cv' ||
                  _vm.toolData.codeKebab === 'tiktok-cv'
                )?_c('span',[_vm._v("ピクセル")]):(_vm.toolData.codeKebab === 'yda-cv')?_c('span',[_vm._v("コンバージョン")]):(_vm.toolData.codeKebab === 'line-cv')?_c('span',[_vm._v("LINE Tag")]):_vm._e(),_c('span',[_vm._v("名")])])]),_c('td',[_c('el-input',{model:{value:(_vm.tagData.name),callback:function ($$v) {_vm.$set(_vm.tagData, "name", $$v)},expression:"tagData.name"}}),_c('ValidationErrorMessages',{attrs:{"errorMessageList":_vm.validationErrorData.name}})],1)]),_c('tr',[_c('th',[_c('span',[(
                  _vm.toolData.codeKebab === 'facebook-cv' ||
                  _vm.toolData.codeKebab === 'tiktok-cv'
                )?_c('span',[_vm._v("ピクセルID")]):(_vm.toolData.codeKebab === 'line-cv')?_c('span',[_vm._v("LINE Tag ID")]):(_vm.toolData.codeKebab === 'yda-cv')?_c('span',[_vm._v("yahoo_ydn_conv_io")]):_vm._e()])]),_c('td',[_c('el-input',{model:{value:(_vm.tagData.mediaTagId),callback:function ($$v) {_vm.$set(_vm.tagData, "mediaTagId", $$v)},expression:"tagData.mediaTagId"}}),_c('ValidationErrorMessages',{attrs:{"errorMessageList":_vm.validationErrorData.mediaTagId}})],1)]),_c('tr',[_c('th',[(
                _vm.toolData.codeKebab === 'facebook-cv' ||
                _vm.toolData.codeKebab === 'tiktok-cv' ||
                _vm.toolData.codeKebab === 'line-cv'
              )?_c('span',[_vm._v("アクセストークン")]):(_vm.toolData.codeKebab === 'yda-cv')?_c('span',[_vm._v("yahoo_ydn_conv_label")]):_vm._e()]),_c('td',[_c('el-input',{model:{value:(_vm.tagData.accessToken),callback:function ($$v) {_vm.$set(_vm.tagData, "accessToken", $$v)},expression:"tagData.accessToken"}}),_c('ValidationErrorMessages',{attrs:{"errorMessageList":_vm.validationErrorData.accessToken}})],1)])])])],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('div',{staticClass:"button-cover"},[(_vm.toolData.codeKebab !== 'facebook-cv')?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.register}},[_vm._v("登録")]):_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.registerPixelData}},[_vm._v("登録")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }