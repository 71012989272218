<template>
  <div class="button2">
    <el-button :type="type" :class="{ 'manual' : type2 == '1' }" round>{{msg}}</el-button>
  </div>
</template>

<script>
export default {
  name: 'Button2',
  props: {
    msg: String,
    type: String,
    type2: String,
  }
}
</script>

<style lang="scss" scoped>
  // .el-button {
  //   padding: 1.5em 0;
  //   font-size: 24px;
  //   width: 100%;
  // }
  // .is-round {
  //     border-radius: 50px;
  // }
  .el-button {
    padding: 1em 0!important;
    font-size: 20px;
    width: 100%!important;
  }
  .is-round {
      border-radius: 50px!important;
  }
  .manual {
    background: #f2f2f2;
  }
</style>
