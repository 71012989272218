<template>
  <div class="tools02-parameter">
    <el-row>
      <el-col>
        <h2>パラメータ引継ぎ設定</h2>
      </el-col>
    </el-row>
    <Tools02ParameterManualManualSystem
      :category01="category01"
      :category02="category02"
      :unitId="unitId"
    />
  </div>
</template>

<script>
import Tools02ParameterManualManualSystem from "@/components/Tools02/ParameterManual/ManualSystem.vue";

export default {
  components: {
    Tools02ParameterManualManualSystem,
  },
  props: {
    category01: Number,
    category02: Number,
    unitId: Number,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
