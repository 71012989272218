<template>
  <div class="ctrl-qa-edit">
    <h2>質問の編集・回答</h2>
    <el-row :gutter="30" type="flex" justify="space-between">
      <el-col :span="4">
        <router-link to="/ctrl/qa">
          <Button3 msg="戻る" type="success" />
        </router-link>
      </el-col>
    </el-row>
    <el-row type="flex" justify="start" align="middle">
      <el-col :span="5">
        <h3>カテゴリ1</h3>
        <el-col :span="24">
          <el-select
            v-model="cateSelect1"
            placeholder="選択してください"
            @change="change1"
            value-key="index"
          >
            <!-- <el-select
            v-model="cateSelect1"
            placeholder="選択してください"
            @change="change1(cateSelect1)"
            value-key="index"
            >-->
            <el-option
              v-for="(list1,index) in cateLists"
              :key="index"
              :label="list1.cate01_name"
              :value="list1.cate01"
            ></el-option>
            <!-- <el-option
              v-for="(list1,index) in cateLists"
              :key="index"
              :label="list1.cate01_name"
              :value="list1.cate01"
            ></el-option>-->
          </el-select>
        </el-col>
      </el-col>
      <el-col :span="5">
        <h3>カテゴリ2</h3>
        <el-col :span="24">
          <el-select v-model="cateSelect2" placeholder="選択してください">
            <el-option
              v-for="(list2,index) in subLists"
              :key="index"
              :label="list2.cate02_name"
              :value="list2.cate02"
            ></el-option>
          </el-select>
        </el-col>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【質問】</h3>
        <el-input type="textarea" :rows="10" placeholder="質問を入力してください。" v-model="question"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【回答】</h3>
        <!-- 20200908 小関さんより回答にはスタイルをつけたい（赤字・太字等）のでソースコードで回答できるようにしたいそうです -->

        <el-input type="textarea" :rows="10" placeholder="回答を入力してください。" v-model="answer"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="text-align: center;">
          <!-- <router-link to="/register" style="margin-right: 50px;">
            <el-button type="info">修正</el-button>
          </router-link>-->
          <el-button v-if="$route.params.id == 0" type="primary">投稿</el-button>
          <el-button v-else type="primary">更新</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  data() {
    return {
      FAQ_id: "",
      cateLists: [],
      // cateSelectLists: [],
      subLists: [],
      cateSelect1: "", //セレクトタグ用
      cateSelect2: "", //セレクトタグ用
      cateSelect1_select: "", //持ち運び用
      question: "",
      answer: "",
      cate01: "",
      cate02: "",
    }
  },
  components: {
    Button3,
  },
  created() {
  },
  mounted() {
    //cateを取得
    this.cateSelect1 = this.$route.params.cate01;
    this.cateSelect2 = this.$route.params.cate02;
    //セレクト用のカテゴリを取得
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getCategory.php")
      .then((res) => {
        console.log(res);
        console.log(res.data.list);
        this.cateLists = res.data.list;
        // this.List1 = res.data.list.list1;
        // this.cateSelect1 = this.cate01;
        // this.cateSelect2 = this.cate02;
        this.change1(this.cateSelect2);
      })
      .catch((err) => {
        console.log(err);
      });

    //FAQの現在のカテゴリ取得
    // axios
    //   .get(
    //     this.$store.getters.rt +
    //       "/api/ctrl/getSelectCategory.php?FAQ=" +
    //       this.$route.params.qa_id
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     console.log(res.data.list);
    //     this.cateSelectLists = res.data.list;
    //     // this.List1 = res.data.list.list1;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    change1: function (val) {
      console.log(val);
      // console.log(this.cateLists[val].cate01_name);
      // console.log(this.cateLists[val].cate01);
      // this.cateSelect1_select = this.cateLists[val].cate01;
      // this.subLists = this.cateLists[val].list2.map((e) => {
      //   console.log(e);
      //   return e;
      // });
      this.cateLists.map((e) => {
        if (e.cate01 == val) {
          console.log(e.cate01_name);
          this.subLists = e.list2.map((e) => {
            console.log(e);
            return e;
          });
        }
        console.log(e);
        return e;
      });
      console.log(this.subLists);
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
