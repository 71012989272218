<template>
  <div class="ctrl-api-histories">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  components: {
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
</style>
