<template>
  <div class="format0201">
    <div
      class="format-cover2"
      :class="{ small: type == 1 }"
      :style="$route.name === 'tools01-format' ? 'height: 600px;' : 'height: 100px;'"
    >
      <div
        class="overlay-background"
        :style="
          'background:' + format.color04 + ';height:' + format.height02 + 'px;'
        "
      >
        <div class="overlay-outline">
          <span
            class="overlay-btn"
            :style="
              'background:' +
              format.color01 +
              ';padding:0 ' +
              format.width02 +
              'px;height:' +
              format.height03 +
              'px;border-radius:' +
              format.int01 +
              'px;'
            "
          >
            <span class="button_text" v-html="format.text01"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Format0201",
  props: {
    format: [Array, Object],
  },
  data() {
    return {
      type: 0,
    };
  },
  watch: {
    "format.text01"() {
      this.emitTextWidth();
    },
  },
  mounted() {
    this.emitTextWidth();
  },
  methods: {
    emitTextWidth() {
      this.$emit("text_width", $(".button_text").width());
    },
  },
};
</script>

<style lang="scss" scoped>
.small {
  width: 360px !important;
  transform: scale(0.95);
}
.format-cover2 {
  width: 375px;
  margin: auto;
  background: white;
  margin: auto;
  .overlay-background {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    bottom: 0;
    .overlay-outline {
      height: 100%;
      position: relative;
      .overlay-btn {
        width: max-content;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>