<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（PRESCO）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/point-back`"
            >ASP自動連携設定</router-link
          >」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`"
          alt=""
        />
        <h4>【２】該当するASPの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no27/01.png`" alt="" />
        <h4>【３】以下の文言を担当者に連絡します。</h4>
        <p>
          -----------------------<br />
          アドタスカルのポイントバック連携、希望です。<br />
          <br />
          （【２】でコピーした通知URL）<br />
          -----------------------
        </p>

        <h4>【４】連携が完了すると、テスト案件用のURLが発行してもらえます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no27/02.png`" alt="" />
        </div>  
        <h5>▼アフィリエイトリンク</h5>
        <p class="blue">https://ad.presco.asia/cl/?b_id=○○○&t_id=●●●</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p class="blue">
          https://ad.presco.asia/cl/?b_id=○○○&t_id=●●●<span class="green">&amp;afad_param_1={{tool.clickIdName}}_xxx&amp;afad_param_2=test</span>
        </p>
        <p>※自分で付けたパラメータを覚えておきましょう</p>
        <h4>
          【５】テストURLを先方に伝えてテストを行ってもらいます。
        </h4>
        <h4>
          【６】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/auto-import-log`"
            >自動登録履歴</router-link
          >」を確認します。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`"
          alt=""
        />

        <h4>
          【７】数分後に、テストCVが登録されていれば問題なく連携完了です。
        </h4>
        <AutoImportLog 
          :aspId="'36'"
          :aspPromotionName="'test'"
        />
        <ul>
          <li>ASP：PRESCO</li>
          <li>
            CV時間：テストした時間になっているか？（多少の誤差はあり）
          </li>
          <li>プログラム名：-</li>
          <li>パラメータ：【５】で付けたパラメータと一致しているか？</li>
          <li>プログラムID： test</li>
          <li>
            インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です。
          </li>
        </ul>

        <h4>【８】連携完了</h4>
        <p>担当さんに一報入れましょう。これで連携は完了です。</p>

      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    AutoImportLog,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.blue {
  color: var(--blue);
}
</style>
