<template>
  <div id="app">
    <el-container>
      <el-header style="height: 70px; padding: 0" v-if="header != false">
        <LayoutHeader />
      </el-header>
      <el-container>
        <el-aside v-if="login == 1">
          <LayoutSideBar :path="path" :type="`sidebar`"/>
        </el-aside>
        <el-container>
          <el-main style="padding-bottom: 70px">
            <UiBreadCrumb v-if="login == 1" />
            <UiBackButton v-if="login == 1" />
            <router-view />
            <UiBackButton v-if="login == 1" />
            <UiContactButton v-if="login == 1" />
            <UiToTopButton />
          </el-main>
          <el-footer style="padding: 0; margin-top: -71px">
            <LayoutFooter />
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LayoutHeader from "@/components/Layout/Header.vue";
import LayoutSideBar from "@/components/Layout/SideBar.vue";
import LayoutFooter from "@/components/Layout/Footer.vue";
import UiBreadCrumb from "@/components/Ui/BreadCrumb.vue";
import UiBackButton from "@/components/Ui/BackButton.vue";
import UiContactButton from "@/components/Ui/ContactButton.vue";
import UiToTopButton from "@/components/Ui/ToTopButton.vue";

export default {
  name: "App",
  data() {
    return {};
  },
  components: {
    LayoutHeader,
    LayoutSideBar,
    LayoutFooter,
    UiBreadCrumb,
    UiBackButton,
    UiContactButton,
    UiToTopButton,
  },
  created() {},
  computed: {
    ...mapGetters(["rt", "login", "openSidebar", "backButton"]),
    header() {
      return this.$route.meta.header;
    },
    path() {
      return this.$route.path;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
:root {
  --black: #4d4d4d;
  --gray: #ccc;
  --red: #f15e62;
  --orange: #e2963a;
  --green: #61b939;
  --blue: #3797fd;
  --blue2: #409eff;
  // --blue3: #c4d9ec;
  --blue3: #e9f4ff;
  --link-blue: #0000ee;
  --o-blue: #1c6db5;
}
#app[v-cloak] {
  display: none;
}
.format01 {
  p {
    margin: 0;
  }
}
h2 {
  font-size: 26px;
  text-align: center;
  color: var(--black);
  border-bottom: solid 1px var(--gray);
  padding-top: 40px;
  padding-bottom: 15px;
  margin-top: 0;
}
h3.description {
  text-align: center;
  font-size: 28px;
  color: var(--black);
  margin-bottom: 50px;
}
img.description {
  margin: 40px auto;
}
html {
  color: var(--black);
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
}
html, body {
  & * {
    box-sizing: border-box!important;
  }
}
body {
  margin: 0;
  main {
    width: 1100px;
    margin: 0 auto;
    padding-top: 100px;
    min-height: calc(100vh - 70px);
    // @media screen and (max-width: 1400px) {
    //   //main + sidebar
    //   width: 900px;
    // }
    // @media screen and (max-width: 767px) {
    //   width: 100%;
    // }
    @media screen and (max-width: 1395px) {
      width: 100%;
    }
    &.el-main {
      overflow: visible;
      @media screen and (max-width: 767px) {
        padding: 10px;
      }
    }
  }
  .el-aside {
    width: 295px !important;
    @media screen and (max-width: 1240px) {
      // width: 0 !important;
      display: none;
    }
  }
}

#app {
  display: flex;
  .main {
    // padding-left: 270px;
    width: 100%;
  }
  .noSidebar {
    padding-left: 0 !important;
  }
}
.width-max-cover {
  width: 80vw;
  margin: 0 calc(50% - 40vw);
  @media screen and (max-width: 1500px) {
    width: 100%;
    margin: auto;
  }
}
.center {
  text-align: center !important;
}
.right {
  text-align: right !important;
}
.left {
  text-align: left !important;
}
input[type="radio"] {
  cursor: pointer;
}
label {
  cursor: pointer;
}
.el-row {
  margin: 15px 0;
}
.red {
  color: rgb(235, 0, 115);
}
.red2 {
  color: rgb(235, 0, 115);
  text-decoration: underline;
}
.function-description-box {
  background: #f2f2f2;
  padding: 30px;
  h3 {
    font-size: 26px;
    margin-top: 0;
  }
  .function-list {
    p {
      span {
        font-weight: bold;
      }
    }
  }
}
.hide {
  display: none !important;
}
.pc-only {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.sp-only {
  display: none !important;
  @media screen and (max-width: 767px) {
    display: initial !important;
  }
}
.bold {
  font-weight: bold;
}

//=============== ↓↓ table関係 ↓↓ ===============
table.two-d {
  border: solid 1px #ccc;
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  tr {
    th,
    td {
      border: solid 1px #ccc;
      box-sizing: border-box;
      padding: 5px;
    }
    td {
      text-align: right;
    }
  }
  thead {
    tr {
      th {
        background-color: #f8f8f8;
      }
    }
  }
  tbody {
    tr {
      th {
        background-color: #f8f8f8;
      }
      td {
        text-align: right;
        font-weight: normal;
      }
    }
  }
}

//
table.typeA {
  //一覧などに使うテーブル。ヘッダー有色。
  width: 100%;
  border-collapse: collapse;
  color: #4d4d4d;
  tr {
    border-top: solid 1px #f0f0f0;
    border-bottom: solid 1px #f0f0f0;
    th,
    td {
      padding: 10px 20px;
    }
  }
  thead {
    tr {
      background: #f0f0f0;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      th {
        font-weight: normal;
        text-align: left;
      }
    }
  }
  tbody {
    tr {
      td {
        &.btn {
          width: 80px;
          text-align: center;
          padding: 10px 0;
        }
      }
    }
  }
}
table.stripe {
  tbody {
    tr {
      &:nth-child(2n) {
        background-color: #f8f8f8;
      }
    }
  }
}
//詳細情報などに使うテーブル
table.typeB {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  color: #4d4d4d;
  tr {
    th,
    td {
      border: solid 1px #ebeef5;
      border-right: none;
      border-left: none;
    }
    th {
      width: 30%;
      padding: 20px 30px;
      text-align: center;
      background-color: #f0f0f0;
      border-top: solid 1px #ddd;
      border-bottom: solid 1px #ddd;
    }
    td {
      padding: 10px;
      .el-input {
        width: 90%;
        .el-input__inner {
          height: 34px;
        }
      }
    }
  }
  .el-row {
    margin: 0;
  }
}
//角丸のテーブル（例: ポイント表）
table.typeC {
  margin: 0 auto;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
  border: solid 1px #888;
  border-radius: 7px;
  tr {
    td {
      text-align: center;
      border-bottom: solid 1px #888;
      border-right: solid 1px #888;
      padding: 10px;
    }
    td:first-child {
      width: 60%;
    }
    td:last-child {
      border-right: none;
    }
  }
  tr:last-child {
    td {
      border-bottom: none;
    }
  }
}
//定義リストのようなテーブル
table.typeD {
  width: 100%;
  text-align: left;
  border: 1px solid #ccc;
  border-collapse: collapse;
  .el-row {
    margin: 0;
  }
  th {
    background-color: #f2f2f2;
    font-size: 18px;
    font-weight: normal;
    padding: 10px 25px;
    box-sizing: border-box;
    letter-spacing: 0.05em;
    span {
      font-size: 14px;
      color: #1c6db5;
      font-weight: bold;
    }
  }
  td {
    padding: 25px;
    border-bottom: 1px solid #ccc;
    input {
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 10px 15px;
    }
    textarea {
      width: 100%;
      border: 1px solid #ccc;
      padding: 15px;
      box-sizing: border-box;
    }
    select {
      width: 40%;
      padding: 10px 15px;
      border: 1px solid #ccc;
      color: #4d4d4d;
    }
  }
  .name_box {
    display: flex;
    input {
      width: 28%;
      margin-left: 35px;
    }
    input:first-child {
      margin-left: 0;
    }
  }
}
table.typeE {
  border: solid 1px #ccc;
  border-collapse: collapse;
  width: 100%;
  box-sizing: border-box;
  tr {
    th,
    td {
      border: solid 1px #ccc;
      box-sizing: border-box;
    }
    td {
      text-align: right;
    }
  }
  thead {
    tr {
      th {
        background-color: #f8f8f8;
      }
    }
  }
  tbody {
    font-weight: bold;
    tr {
      th {
        width: 230px;
      }
      td {
        padding: 3px 0;
        text-align: center;
      }
    }
  }
}
table.simple {
  border: 1px solid #999;
  border-collapse: collapse;
  tr {
    td {
      padding: 10px;
      border: 1px solid #999;
    }
  }
}
.total_tbl {
  border: solid 1px #ccc;
  border-collapse: collapse;
  width: 100%;
  tr {
    th,
    td {
      border: solid 1px #ccc;
      padding: 5px 2px;
    }
    th {
      background-color: #f2f2f2;
    }
    td {
      text-align: center;
    }
  }
}
//=============== ↑↑ table関係 ↑↑ ===============
.el-tab-pane {
  table.typeA {
    tr {
      background-color: white !important;
    }
    thead {
      tr {
        border-top: none;
        border-bottom: solid 2px #ccc;
      }
    }
  }
}
.info {
  .align {
    .el-textarea__inner {
      height: 75px;
    }
  }
}
.right-button-cover {
  text-align: right;
}
.format-cover2 {
  border: solid 1px #000;
  box-sizing: border-box;
  width: 375px;
  height: 600px;
  display: flex;
  align-items: flex-end;
  .glay-zone {
    width: 100%;
    height: 70px;
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .format-img-box2 {
    position: relative;
    width: 100%;
    height: 70px;
    background: #ddd;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .format-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }
}
.hr-border-line {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px var(--gray);
  margin: 20px 0;
}
.format-list-box {
  display: flex;
  flex-wrap: wrap;
  .format-list-name {
    font-size: 12px;
    color: #b3b3b3;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 2px 10px;
    overflow: hidden;
    border: solid 1px #ccc;
    border-radius: 5px;
    margin: 5px;
    span {
      font-weight: bold;
      font-size: 18px;
      margin-left: 10px;
      color: #4d4d4d;
    }
  }
}
.draggable {
  &:hover {
    cursor: grab;
  }
  &:active {
    cursor: grabbing;
  }
}

//ポップアップフォーマット用
.format01 {
  width: 350px;
  margin: 0 auto;
  p {
    margin: 0 !important;
  }
}
h3.back-gray {
  background-color: #e6e6e6;
  padding: 3px 0 2px 20px;
  color: #666;
  border-radius: 5px;
}
.open-bar {
  width: 100%;
  cursor: pointer;
  border: solid 1px #ccc;
  text-align: center;
  margin: 30px 0 10px;
  border-radius: 10px;
  display: flex;
  font-weight: bold;
  .bar-left {
    // background-color: #e6e6e6;//~2021/02/18
    background-color: #9fc0dd;
    color: white;
    border-radius: 10px 0 0 10px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }
  .bar-right {
    padding: 10px 20px;
  }
}
.question-flex {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .el-select,
  .el-input {
    margin-top: 0 !important;
  }
}
.el-notification {
  background-color: #bc3f3f !important;
  .el-notification__group {
    .el-notification__title {
      border: none;
      padding: 0;
      text-align: left;
      font-size: 17px;
      color: #fff;
    }
    .el-notification__content {
      color: #fff;
      span.number {
        font-weight: bold;
        font-size: 17px;
      }
    }
  }
}
.el-select-prepend-cover {
  display: flex;
  .el-select-prepend {
    color: #909399;
    background: #f5f7fa;
    border: 1px solid #dcdfe6;
    border-right: none;
    padding: 0 20px;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  :deep(.el-select) {
    .el-input__inner {
      border-radius: 0;
      border-radius: 0 4px 4px 0;
    }
  }
}
.el-button.success-icon {
  padding: 0 !important;
  width: 18px;
  height: 18px;
  margin: 0 3px;
}
.el-button.danger-icon {
  font-size: 14px;
  padding: 0px !important;
  width: 18px;
  height: 18px;
  margin: 0 3px;
}
.log-search-box {
  .clear-button {
    border: none;
    border-radius: 5px;
    font-size: 0.5em;
    height: 40px;
    background-color: #999;
    color: white;
    cursor: pointer;
  }
}
</style>
