<template>
  <div class="manual-google-cv-contents">
    <h2>システム全体説明</h2>

    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <!-- <iframe
          src="https://player.vimeo.com/video/529599454?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="02-01Google広告成果連携ツールのシステム全体説明.mp4"
        ></iframe> -->
        <div style="padding: 62.5% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/529599454?h=a9503becc9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="02-01Google広告成果連携ツールのシステム全体説明.mp4"
          ></iframe>
        </div>
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <!-- <iframe
          src="https://player.vimeo.com/video/645459334?h=a0e4af5d38"
          width="100%"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="02-01Yahoo広告成果連携ツールのシステム全体説明.mp4"
        ></iframe> -->
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/645459334?h=a0e4af5d38&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="Yahoo広告成果連携ツールシステム全体説明"
          ></iframe>
        </div>
      </el-col>
      <el-col :span="24" style="text-align: center;" v-if="tool.codeKebab === 'facebook-cv'">
        <span>※準備中</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
