<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（Link-Ag）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>対象ASP：Link-AG、BULK-AD、SIXPACK-C</p>
        <p class="red">
          以下、「Link-AG」を例に解説をしていますが、「BULK-AD」「SIXPACK-C」とも同じ手順になります。適宜、「Link-AG」→（該当するASP名）に読み替えて設定を行ってください。
        </p>
        <h4>
          【１】<a
            href="https://link-ag.net/partner/sign_in"
            target="_blank"
            rel="noopener noreferrer"
            >Link-AGの管理画面</a
          >にログインし、「成果一覧」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no06/01.png`" alt="" />
        <h4>【２】期間を選択して、「検索」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no06/02.png`" alt="" />
        <h4>【３】成果が表示されたら、「詳細」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no06/03.png`" alt="" />
        <h4>
          【４】「パラメータ１」にクリックパラメータ（{{
            tool.clickIdName
          }}_xxx）が表示されているか確認します。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/no07/no06/04.png`"
          alt=""
        />
        <p class="red">
          ※パラメータが表示されていない場合、パラメータ引継ぎ設定を間違えている可能性があります。
        </p>
        <p>
          「<router-link :to="`/manual/${tool.codeKebab}/06/02`"
            >パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。
        </p>
        <h4>
          【５】成果一覧画面に戻って頂き、CSVをダウンロードします。「CSVダウンロード」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no06/05.png`" alt="" />
        <p>
          これで成果CSVのダウンロードは完了です。「<router-link
            :to="`/manual/${tool.codeKebab}/07/14`"
            >ASPの成果データをアドタスカルにインポートする</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
