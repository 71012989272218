<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>アドタスカルとYahoo!JAPANビジネスIDを連携する</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>登録したYahoo!JAPANビジネスIDとアドタスカルの連携を行います。</li>
          <li>
            連携をすることで、Yahoo!検索広告管理画面にCVデータを自動アップロードすることができます。
          </li>
          <li>自動アップロードを希望しない場合は不要です。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h4>
          【１】「「<router-link to="/tools02/yahoo-cv/bussiness-accounts"
            >Yahoo!JAPANビジネスID</router-link
          >」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no04/01.png`" alt="" />

        <h4>
          【２】アドタスカルと連携したいYahoo!JAPANビジネスIDの横にある「認証」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no04/02.png`" alt="" />

        <h4>【３】Yahoo!検索広告の管理画面にアクセスされるので、登録したYahoo!JAPANビジネスIDとパスワードを入力して「ログイン」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no04/03.png`" alt="" />
        <p>
すでにYahoo!JAPANビジネスIDが入力されている場合は、登録したYahoo!JAPANビジネスIDと一致しているか確認してください。</p>
<p style="margin-top:50px;">異なる場合は、「別のYahoo!JAPANビジネスIDでログイン」をクリックしてください。</p>

        <img :src="`${rt}/img/manual/yahoo-cv/no04/no04/04.png`" alt="" />
        <h4>
          【４】アドタスカルからのアクセス許可を求められるので「承認」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no04/05.png`" alt="" />

        <h4>【５】アドタスカルの管理画面に自動で遷移し、「連携済み」に表示が変わったことを確認します。
</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no04/06.png`" alt="" />

        <p>Yahoo!検索広告とアドタスカルの連携の解除方法は<router-link to="/manual/yahoo-cv/04/03"
            >コチラ</router-link>をご確認ください。</p>
            <p>以上で、Yahoo!JAPANビジネスIDとアドタスカルの連携は完了です。</p>
            <p>次は、<router-link to="/manual/yahoo-cv/04/01"
            >「Yahoo!検索広告アカウント登録する」</router-link>に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualTools02Contents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
