<template>
  <div class="info">
    <el-row>
      <el-col>
        <h2>特典一覧</h2>
      </el-col>
    </el-row>
    <PrivilegeList />
  </div>
</template>

<script>
import PrivilegeList from "@/components/Privileges/PrivilegeList.vue";

export default {
  components: {
    PrivilegeList
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>
