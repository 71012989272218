<template>
  <div class="account-contents">
    <div class="account-cover">
      <div class="account-item">
        <router-link to="/info"
          ><MenuButton
            :iconName="'el-icon-user'"
            :title="'会員情報の編集'"
            :colorCode="'#61b939'"
        /></router-link>
      </div>
      <div class="account-item">
        <router-link to="/history"
          ><MenuButton
            :iconName="'el-icon-shopping-cart-1'"
            :title="'購入・消費履歴'"
            :colorCode="'#f15e62'"
        /></router-link>
      </div>
      <div class="account-item">
        <router-link to="/subscription-histories"
          ><MenuButton
            :iconName="'el-icon-document'"
            :title="'利用ステータス履歴'"
            :colorCode="'#e2963a'"
        /></router-link>
      </div>
    </div>
    <div class="account-cover">
      <div class="account-item">
        <router-link to="/change-password"
          ><MenuButton
            :iconName="'el-icon-lock'"
            :title="'パスワード変更'"
            :colorCode="'#ac98d3'"
        /></router-link>
      </div>
      <div class="account-item">
        <router-link to="/asp-display-settings"
          ><MenuButton
            :iconName="'el-icon-present'"
            :title="'ASP表示設定'"
            :colorCode="'#3399FF'"
        /></router-link>
      </div>
      <div class="account-item">
        <router-link to="/privileges"
          ><MenuButton
            :iconName="'el-icon-present'"
            :title="'特典'"
            :colorCode="'#f19ec2'"
        /></router-link>
      </div>
    </div>
    <div class="account-cover">
      <div class="account-item">
        <router-link to="/affiliate/"
          ><MenuButton
            :iconName="'el-icon-connection'"
            :title="'アフィリエイト'"
            :colorCode="'#7ecef4'"
        /></router-link>
      </div>
      <div class="account-item">
        <a
          href="https://adtasukaru.wraptas.site/a247b7ff12854619a98d5a777e26a8a2"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuButton :iconName="'el-icon-more'" :title="'休止・解約'" />
        </a>
      </div>
      <div class="account-item"></div>
    </div>
    <div style="display: none">
      以下の「ドメイン登録」は、離脱防止ポップアップ、特定位置登場ポップアップ、オーバーレイを使用する場合に設定が必要となります。「コンバージョン設定」も任意で行うことができます。
    </div>
    <el-row class="items" :gutter="20" style="display: none">
      <el-col :span="8">
        <router-link to="/domain"
          ><MenuButton
            :iconName="'el-icon-user'"
            :title="'会員情報の編集'"
            :colorCode="'#61b939'"
        /></router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/cv"
          ><MenuButton
            :iconName="'el-icon-user'"
            :title="'会員情報の編集'"
            :colorCode="'#61b939'"
        /></router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuButton from "@/components/Account/MenuButtonList/MenuButton.vue";

export default {
  name: "AccountContents",
  components: {
    MenuButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "userData"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
  &:hover {
    opacity: 0.7;
  }
}
.account-cover {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .account-item {
    width: 32%;
    @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 20px;
    }
    a {
      text-decoration: none;
    }
  }
}
</style>
