<template>
  <div class="tools02-auto-import-log">
    <el-row>
      <el-col>
        <h2>自動登録履歴</h2>
      </el-col>
      <el-col style="width: 170px">
        <a
          href="https://adtasukaru.wraptas.site/fb4e5fd716574b178225f8b679f6363a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <!-- <Tools02AutoImportLogLogTable :type="'default'"/> -->
    <PointBackHistoryList :type="'default'"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
// import Tools02AutoImportLogLogTable from "@/components/Tools02/AutoImportLog/LogTable.vue";
import PointBackHistoryList from "@/components/Tools02/AutoImportLog/PointBackHistoryList.vue";

export default {
  components: {
    Button2,
    // Tools02AutoImportLogLogTable,
    PointBackHistoryList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.manual-button-cover {
  @media screen and (max-width: 767px) {
    display: none;
  }
  .manual-button {
    margin-bottom: 20px;
  }
}
</style>
