<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する(CATS)</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p style="text-align: center">準備中</p>
        <!-- <img
          :src="`${rt}/img/manual/${tool.codeKebab}/no08/no17/05.png`"
          alt=""
        /> -->
        <!-- <PointbackSettingDescription :aspId="'22'" /> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    // PointbackSettingDescription,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
