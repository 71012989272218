import store from '@/store'

/**
 * ページタイトル設定
 * @param pathTitle
 * @returns {string}
 */
export const setTitle = pathTitle => {
  const siteTitle = 'アドタスカル';
  const pageTitle = !pathTitle ? siteTitle : pathTitle + ' | ' + siteTitle;
  return (window.document.title = pageTitle);
}

/**
 * Description設定
 * @param pathMeta
 */
export const setDescription = pathMeta => {
  const defaultDescription = 'This is initial description.';
  const description = pathMeta ? pathMeta : defaultDescription;
  document
    .querySelector("meta[name='description']")
    .setAttribute('content', description);
}

export const globalMixins = {
  methods: {

    g_hello() {
      alert('hello! from mixins.')
    },

    //setTitle
    setTitle,

    //setDescription
    setDescription,

    //
    g_buySelectedProduct(productId, tempPass) {
      let params = new URLSearchParams();
      params.append("product_id", productId);
      params.append("temp_pass", tempPass);
      this.$axios
        .post(`${store.getters.rt}/api/payment/settle.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            location.href = res.data.URL;
          } else if (res.data.status === 409) {
            alert(res.data.message);
            return;
          } else {
            alert(store.getters.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //
    g_getDatetime(datetime) {
      let year = datetime.getFullYear();
      let month = datetime.getMonth() + 1;
      let date = datetime.getDate();
      let hours = datetime.getHours().toString().padStart(2, "0");
      let minutes = datetime.getMinutes().toString().padStart(2, "0");
      let seconds = datetime.getSeconds().toString().padStart(2, "0");
      return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
    },

    //
    g_getDatetimeRange(type) {
      let startDatetime = new Date();
      let endDatetime = new Date();

      if (Number.isInteger(type)) {
        startDatetime.setDate(startDatetime.getDate() - type);
        startDatetime.setHours(0);
        startDatetime.setMinutes(0);
        startDatetime.setSeconds(0);
      } else {
        switch (type) {
          case "30":
          case "60":
          case "180":
            startDatetime.setDate(startDatetime.getDate() - parseInt(type));
            startDatetime.setHours(0);
            startDatetime.setMinutes(0);
            startDatetime.setSeconds(0);
            break;

          case "today":
            startDatetime.setHours(0);
            startDatetime.setMinutes(0);
            startDatetime.setSeconds(0);
            endDatetime.setHours(23);
            endDatetime.setMinutes(59);
            endDatetime.setSeconds(59);
            break;

          case "yesterday":
            startDatetime.setDate(startDatetime.getDate() - 1);
            startDatetime.setHours(0);
            startDatetime.setMinutes(0);
            startDatetime.setSeconds(0);
            endDatetime.setDate(endDatetime.getDate() - 1);
            endDatetime.setHours(23);
            endDatetime.setMinutes(59);
            endDatetime.setSeconds(59);
            break;

          case "thisMonth":
            startDatetime.setDate(1);
            startDatetime.setHours(0);
            startDatetime.setMinutes(0);
            startDatetime.setSeconds(0);
            endDatetime.setMonth(endDatetime.getMonth() + 1);
            endDatetime.setDate(0);
            endDatetime.setHours(23);
            endDatetime.setMinutes(59);
            endDatetime.setSeconds(59);
            break;

          case "all":
            startDatetime.setFullYear(2000);
            startDatetime.setMonth(0);
            startDatetime.setDate(1);
            startDatetime.setHours(0);
            startDatetime.setMinutes(0);
            startDatetime.setSeconds(0);
            break;
          default:
            break;
        }
      }
      let dateTimeRange = [
        this.g_getDatetime(startDatetime),
        this.g_getDatetime(endDatetime),
      ];
      return dateTimeRange;
    },

    //
    g_getDate(dt) {
      let year = dt.getFullYear();
      let month = dt.getMonth() + 1;
      month = ('0' + month).slice(-2);
      let date = dt.getDate();
      date = ('0' + date).slice(-2);
      return `${year}-${month}-${date}`;
    },


    //
    g_getDateRange(type) {
      let startDate = new Date();
      let endDate = new Date();

      // 
      if (Number.isInteger(type)) {
        startDate.setDate(startDate.getDate() - type);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
      } else {
        switch (type) {
          case "30":
          case "60":
          case "180":
            startDate.setDate(startDate.getDate() - parseInt(type));
            startDate.setHours(0);
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            break;

          case "today":
            //no action.
            break;

          case "yesterday":
            startDate.setDate(startDate.getDate() - 1);
            endDate.setDate(endDate.getDate() - 1);
            break;

          case "thisMonth":
            startDate.setDate(1);
            break;

          case "lastMonth":
            startDate.setDate(1);
            startDate.setMonth(startDate.getMonth() - 1);
            endDate.setDate(0);
            break;

          case "all":
            startDate.setFullYear(2020);
            startDate.setMonth(0);
            startDate.setDate(1);
            break;

          default:
            break;
        }
      }

      let dateRange = [
        this.g_getDate(startDate),
        this.g_getDate(endDate),
      ];
      return dateRange;
    },

    g_toPrivilegePage(privilegeId) {
      this.$axios
        .get(
          `${store.getters.rt}/api/mypage/check-privilege-acquisition.php?privilege_id=${privilegeId}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            window.open(response.data.privilegeUrl, "_blank");
          } else {
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * 2022年末までのfb期間限定ポイントキャンペーンの表示切り替え
     */
    checkCampaign() {
      let nowDate = new Date();
      let limitDate = new Date('2023-01-01');

      if (nowDate < limitDate) {
        this.isCampaign = true;
      } else {
        this.isCampaign = false;
      }

      console.log(this.isCampaign);
    },



    /**
     * @param {string} dateToCheck 
     * @returns 
     */
    g_todayIsBefore(dateToCheck) {
      // 現在時刻を取得
      const now = new Date();

      // 指定した日付をDateオブジェクトに変換
      const dateA = new Date(dateToCheck);

      // 日付を比較し、dateAが現在時刻より前であるかをチェック
      return now < dateA;
    },



    /**
     * @param {string} text
     */
    g_copyText(text) {
      navigator.clipboard.writeText(text);
      alert("コピーしました。");
    },



    /**
     * @param {string} text
     * @returns {string}
     */
    g_getTools02TagCategoryName(toolId) {
      // 
      let tagCategoryName = '-';
      switch (toolId) {
        case '8':
        case '11':
          tagCategoryName = "ピクセル";
          break;
        case '14':
          tagCategoryName = "LINE Tag";
          break;
        case '15':
          tagCategoryName = "コンバージョン";
          break;
        default:
          tagCategoryName = "-";
          break;
      }

      // 
      return tagCategoryName;
    },



    /**
     * 
     * @param {int} length 
     * @returns 
     */
    g_generateRandomPassword(length) {
      const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      let password = '';

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }

      return password;
    },
  }
}
