<template>
  <div class="ai-reception">
    <el-row>
      <el-col>
        <h2>AI</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <AiForm />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AiForm from "@/components/Ui/AiForm.vue";

export default {
  components: {
    AiForm,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
