<template>
  <div class="ctrl-home-buttons">
    <h2>一般</h2>
    <el-row class="items" :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/api-histories/google">
          <Button2 msg="Google" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/api-histories/facebook">
          <Button2 msg="Facebook" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/api-histories/others">
          <Button2 msg="その他" />
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "buttons",
  data() {
    return {};
  },
  components: {
    Button2,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
h2 {
  text-align: left;
  border: none;
  margin-top: 35px;
}
</style>
