<template>
  <div class="asp-display-settings">
    <el-row>
      <el-col>
        <h2>ASP表示設定</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <AspDisplaySettingsRegister />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import AspDisplaySettingsRegister from "@/components/AspDisplaySettings/AspDisplaySettingsRegister.vue";

export default {
  components: {
    AspDisplaySettingsRegister,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
// h2 {
//   margin-top: 50px;
// }
</style>