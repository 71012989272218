<template>
  <div class="manual-tools01-conversion">
    <el-row>
      <el-col>
        <h2 id="conversion">コンバージョン設定（各ツール共通）</h2>
        <h3 id="header01">「コンバージョン設定」とは</h3>
        <p>設定する各ツールに対して、コンバージョン設定をし、コンバージョンタグを該当ページへ設置することで、レポートから、コンバージョン数や収益を確認できるようになります。なお、コンバージョンの設定は任意となっており、設定しなくても各ツールを通常どおり使用できます。</p>
        <p>※マイクロコンバージョンとは・・中間コンバージョンのことを意味します。最終的な成果に至るまでの中間ゴールのことを指し、アドタスカルでは、そのコンバージョン数などを測定できます。</p>
        <h3 id="header01">設置するツールに対する「コンバージョン設定」をする</h3>
        <p>管理画面の左サイドメニューまたはヘッド部分にある「アカウント」から、「コンバージョン設定」の画面に進みます。「新規追加」から設定したいコンバージョンの情報を入力します。</p>
        <img :src="`${rt}/img/manual/manual1-2-01.jpg`" alt="">
        <p>以下の例のように、「コンバージョン名」または「マイクロコンバージョン名」、「1コンバージョンあたりの金額」、「コンバージョンメモ」を入力し、「登録」をクリックしてください。</p>
        <img :src="`${rt}/img/manual/manual1-2-02.jpg`" alt="">
        <p>コンバージョンの登録が完了しました。当コンバージョンを各ツールのコンバージョンまたはマイクロコンバージョンとして紐付けすることができるようになります。紐付けの方法は、各ツールの設置マニュアルを参照してください。</p>
         <img :src="`${rt}/img/manual/manual1-2-03.jpg`" alt="">
        <h3>コンバージョンの編集と削除</h3>
        <p>設定済のコンバージョンを編集または削除したい場合は、以下の「編集」または「削除」から行うことができます。</p>
        <img :src="`${rt}/img/manual/manual1-2-04.jpg`" alt="">
        <h3>コンバージョンタグを設置する</h3>
        <p>コンバージョンを計測したい場合は「CV計測用タグ」を使用します。「CV計測用タグ」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual1-2-05.jpg`" alt="">
        <p>CV用タグが表示されるので、CV計測したいページにタグを貼り付けます。</p>
        <img :src="`${rt}/img/manual/manual1-2-06.jpg`" alt="">
        <p>マイクロコンバージョンを計測したい場合は「MCV計測用タグ」を使用します。「MCV計測用タグ」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual1-2-07.jpg`" alt="">
        <p>以下の画面が表示されます。MCVを計測したいボタンやテキストリンクなどのクリック要素があるページに計測用タグを貼り付けます。また、MCVを計測したいリンク（ボタン・テキストなど）に計測用IDを付与してください。</p>
        <img :src="`${rt}/img/manual/manual1-2-08.jpg`" alt="">
        <p>以上でコンバージョン設定は完了です。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01Conversion',
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
