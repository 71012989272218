<template>
  <div class="secret">
    <UiLogin :loginType="'secret'" />
  </div>
</template>

<script>
import UiLogin from '@/components/Ui/Login.vue'

export default {
  components: {
    UiLogin
  },
  props: {},
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
// h2 {
//   margin-top: 50px;
// }
</style>