<template>
  <div class="manual-tools01-overlay02">
    <el-row>
      <el-col>
        <h2>{{tools[tool].name2}}設定マニュアル</h2>
        <h3 :id="`${tools[tool].code2}-01`">{{tools[tool].name2}}を設置するサイト・ページの新規登録</h3>
        <p>「新規設定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-4-01.jpg`" alt="">
        <p>新規設定画面が表示されます。以下について、入力と選択をしてください。</p>
        <table class="typeB">
          <tr>
            <th>ページ名</th>
            <td>{{tools[tool].name2}}を設置するページ名を入力</td>
          </tr>
          <tr>
            <th>設定ドメイン</th>
            <td>{{tools[tool].name2}}を設定するドメインを選択</td>
          </tr>
          <tr>
            <th>設定ページURL</th>
            <td>{{tools[tool].name2}}を設定するページのURLを入力</td>
          </tr>
        </table>
        <p>入力したら、「登録」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-4-02.jpg`" alt="">
        <p>以上で、{{tools[tool].name2}}の新規設定が完了しました。</p>
        <img :src="`${rt}/img/manual/manual2-4-03.jpg`" alt="">
        <h3 :id="`${tools[tool].code2}-02`">表示する{{tools[tool].name2}}の設定</h3>
        <p>「編集」をクリックして、表示する{{tools[tool].name2}}の詳細設定をしていきます。</p>
        <img :src="`${rt}/img/manual/manual2-4-04.jpg`" alt="">
        <h3>{{tools[tool].name2}}を設定するページ情報の入力</h3>
        <p>{{tools[tool].name2}}の新規設定時に入力した情報が反映されているので、必要に応じて編集をしてください。「一言メモ」には、{{tools[tool].name}}を設置したページの属性など、任意でメモを残すことができます。入力や編集した項目は「更新」をクリックして確定させてください。</p>
        <img :src="`${rt}/img/manual/manual2-4-05.jpg`" alt="">
        <p>{{tools[tool].name}}の表示タイミングについては、「常に表示」と「指定のスクロール位置に訪問者が達したら表示」のどちらかを選択してください。「指定のスクロール位置に訪問者が達したら表示」を選択した場合は、訪問者の行動による表示の制御として、「上に戻ったら消える」と「上に戻っても消えない」のどちらかを選択してください。選択後は「更新」をクリックして確定させてください。</p>
        <img :src="`${rt}/img/manual/manual2-4-06.jpg`" alt="">
        <h3>{{tools[tool].name}}の作成</h3>
        <p>ボタンタイプ{{tools[tool].name}}の新規設定時は、表示する{{tools[tool].name}}のフォーマットを作成する必要があります。新規で作成する「新規追加」と、設定済のページから転用する「他のページから転用」の2とおりの方法があります。</p>
        <h3>表示する{{tools[tool].name2}}のフォーマットの新規作成</h3>
        <p>「新規設定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-4-07.jpg`" alt="">
        <p>{{tools[tool].name2}}のフォーマットの新規設定・編集画面が表示されます。フォーマット名を入力します。</p>
        <img :src="`${rt}/img/manual/manual2-4-08.jpg`" alt="">
        <p>アドタスカルでは以下の{{tools[tool].name2}}のフォーマットを用意しています。その中から、表示したい{{tools[tool].name}}のフォーマットを選択してください。</p>
        <table class="typeB">
          <tr>
            <th>テキスト＋ランダム数値</th>
            <td>テキストとランダムで変動する数値を設定</td>
          </tr>
          <tr>
            <th>テキスト＋数値増加</th>
            <td>テキストと増加する数値を設定</td>
          </tr>
          <tr>
            <th>テキスト＋数値減少</th>
            <td>テキストと減少する数値を設定</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/manual2-4-09.jpg`" alt="">
        <h3>「テキスト＋ランダム数値」の設定方法</h3>
        <p>フォーマットタイプから、「テキスト＋ランダム数値」を選択すると、以下の編集エディター画面が表示されます。エディター機能を使って、表示する数値の仕様、表示するテキスト、フォーマットのカラーを自由に編集し、「登録」をクリックすることでフォーマットが登録されます。数値の仕様については、以下の調整ができます。</p>
        <table class="typeB">
          <tr>
            <th>アニメーション</th>
            <td>数値部分の動作を設定</td>
          </tr>
          <tr>
            <th>数値の変動範囲</th>
            <td>ランダムで表示される数値が取りうる値の範囲を設定</td>
          </tr>
          <tr>
            <th>数値が変動する時間の間隔</th>
            <td>ランダムで表示が変わる時間の間隔を設定</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/manual2-4-10.jpg`" alt="">
        <h3>「テキスト＋数値増加」の設定方法</h3>
        <p>フォーマットタイプから、「テキスト＋数値増加」を選択すると、以下の編集エディター画面が表示されます。エディター機能を使って、表示する数値の仕様、表示するテキスト、フォーマットのカラーを自由に編集し、「登録」をクリックすることでフォーマットが登録されます。数値の仕様については、以下の調整ができます。</p>
        <table class="typeB">
          <tr>
            <th>アニメーション</th>
            <td>数値部分の動作を設定</td>
          </tr>
          <tr>
            <th>数値の増加量の範囲</th>
            <td>数値が増加する際の値の範囲を設定（1～5を設定すれば、1・2・3・4・5のいずれかの値で数値が増加）</td>
          </tr>
          <tr>
            <th>数値が変動する時間の間隔</th>
            <td>数値が増加する時間の間隔を設定</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/manual2-4-11.jpg`" alt="">
        <h3>「テキスト＋数値減少」の設定方法</h3>
        <p>フォーマットタイプから、「テキスト＋数値減少」を選択すると、以下の編集エディター画面が表示されます。エディター機能を使って、表示する数値の仕様、表示するテキスト、フォーマットのカラーを自由に編集し、「登録」をクリックすることでフォーマットが登録されます。数値の仕様については、以下の調整ができます。</p>
        <table class="typeB">
          <tr>
            <th>アニメーション</th>
            <td>数値部分の動作を設定</td>
          </tr>
          <tr>
            <th>初期値</th>
            <td>減少する数値の表示開始時の値を設定</td>
          </tr>
          <tr>
            <th>終了時の値</th>
            <td>減少する数値の最小値を設定（最小値として設定した値よりも少ない値にはならず、数値の減少が終了）</td>
          </tr>
          <tr>
            <th>数値の減少量の範囲</th>
            <td>数値が減少する際の値の範囲を設定（1～5を設定すれば、1・2・3・4・5のいずれかの値で数値が減少）</td>
          </tr>
          <tr>
            <th>数値が変動する時間の間隔</th>
            <td>数値が減少する時間の間隔を設定</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/manual2-4-12.jpg`" alt="">
        <h3>表示する{{tools[tool].name2}}のフォーマットの転用する</h3>
        <p>上では、表示するボタンタイプ{{tools[tool].name}}のフォーマットの新規作成を説明しましたが、作成済のフォーマットをコピーして転用することもできます。「他のページから転用」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-4-13.jpg`" alt="">
        <p>作成済のフォーマットがあるページ一覧が表示されるので、転用したいページ名をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-4-14.jpg`" alt="">
        <p>選択したページで作成したフォーマットが表示されるので、転用したいもの下にある「コピー」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-4-15.jpg`" alt="">
        <p>フォーマット一覧に追加されます。</p>
        <img :src="`${rt}/img/manual/manual2-4-16.jpg`" alt="">
        <p>以上で、表示する{{tools[tool].name}}の作成は完了です。</p>
        <p>上記の操作によって、表示したい{{tools[tool].name}}を複数作成し、登録することができます。</p>
        <h3>表示する{{tools[tool].name}}のフォーマットを決定する</h3>
        <p>フォーマット一覧にある{{tools[tool].name}}の中で、背景がピンク色になっているものは現在設定中のものになります。表示させる{{tools[tool].name}}を変更したい場合は、フォーマットの下にある「○印」にチェックを入れ、「登録」をクリックすると、表示フォーマットが変更されます。</p>
        <img :src="`${rt}/img/manual/manual2-4-17.jpg`" alt="">
        <p>以上で、{{tools[tool].name2}}の設定が完了しました。</p>
        <h3 :id="`${tools[tool].code2}-03`">{{tools[tool].name2}}の設置</h3>
        <p>{{tools[tool].name2}}は、ツール設置タグを設置することで稼働させることができます。{{tools[tool].name2}}のツール設置タグは、以下の画面から取得することができます。「タグ表示」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-4-18.jpg`" alt="">
        <p>ツール設置タグが表示されるので、{{tools[tool].name}}を登場させたい位置にタグを設置してください。 {{tools[tool].name}}を常にしたい場合は、任意の位置に設置してください（&lt;/body>の直前など）。</p>
        <img :src="`${rt}/img/manual/manual2-4-19.jpg`" alt="">
        <p>ツール設置タグは、{{tools[tool].name2}}の編集画面からも取得ができます。編集画面の最下部にツール設置タグがあるので、{{tools[tool].name}}を登場させたい位置にタグを設置してください。 {{tools[tool].name}}を常にしたい場合は、任意の位置に設置してください（&lt;/body>の直前など）。</p>
        <img :src="`${rt}/img/manual/manual2-4-20.jpg`" alt="">
        <p><router-link to="">{{tools[tool].name2}}に関するよくある質問はコチラ</router-link></p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualOverlay02",
  components: {},
  props: {
    tool: String,
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
