<template>
  <div class="format-register">
    <div
      class="register-cover"
      :class="{ 'gray-filter': type == 1 && active == false }"
    >
      <div
        v-if="
          (type == 0 || (type == 1 && status == 0)) && format.format_id == 0
        "
      >
        <div class="register-btn" @click="displayFormatBox">+</div>
        <p style="text-align: center">
          表示する{{ toolData.name }}を設定しない場合は、<br />{{
            toolData.name
          }}は表示されません。
        </p>
      </div>
      <div v-else-if="type == 1 && format.format_id == 0 && status != 0">
        フォーマット登録なし
      </div>
      <div v-else class="format-display">
        <div class="format-display-cover">
          <FormatDisplay :format="format" />
        </div>
        <div v-show="type == 0 || (type == 1 && status == 0 && active == true)">
          <el-button type="primary" @click="edit">編集</el-button>
          <el-button @click="displayFormatBox">変更</el-button>
          <!-- <el-button type="danger" @click="formatDecide(0)">削除</el-button> -->
        </div>
      </div>

      <div class="dialog-box" style="display: none">
        <el-dialog
          title="フォーマット選択"
          :visible.sync="dialogVisible"
          width="80%"
          top="30px"
        >
          <FormatList
            :type="0"
            :toolId="toolId"
            :pageId="pageId"
            :buttonName="'決定'"
            @formatDecide="formatDecide"
          />
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import FormatList from "@/components/Ui/Tools01/FormatList.vue";
import FormatDisplay from "@/components/Ui/Tools01/FormatDisplay.vue";
import $ from "jquery";

export default {
  name: "FormatRegister",
  props: {
    type: Number, //0: 通常, 1: ABテスト
    area: Number, //0~6。（indexが入る）
    toolId: String,
    pageId: String,
    format: Object,
    status: Number, //0: テスト前, 1: テスト中, 2: テスト終了（ABテスト用）
    active: Boolean, //true: 配信する, false: 配信しない（ABテスト用）
    parentComponentIsEditing: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormatList,
    FormatDisplay,
  },
  computed: {
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },
    pageData() {
      return this.$store.getters["tools01/pageDataBySomeIds"](
        this.toolId,
        this.pageId
      );
    },
  },
  data() {
    return {
      dialogVisible: true,
      list: [],
      formats: 0,
      count: 0,
    };
  },
  created() {},
  mounted() {
    $(".v-modal").css("background", "transparent");
    this.dialogVisible = false;
  },
  methods: {
    edit() {
      let path = `/tools01/${this.toolData.code2}/format/${this.pageId}/${this.format.format_id}`;
      if (this.parentComponentIsEditing === true) {
        if (confirm("まだ未設定の項目がありますが保存しますか？")) {
          //
          this.$emit("registerParentConfigData");

          //
          this.$router.push(path);
        } else {
          //no action.
        }
      } else {
        this.$router.push(path);
      }
    },
    //現時点（2020/12/08）で、
    //通常→format_id
    //ABテスト→format(Object)
    //が飛ぶようになっている
    formatDecide(format_id) {
      this.$emit("formatDecide", this.area, format_id);
      this.dialogVisible = false;
    },
    displayFormatBox() {
      if (this.type == 1 && this.active == false) {
        return;
      }
      $(".dialog-box").css("display", "block");
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.register-cover {
  width: 100%;
  height: 600px;
  border: dashed 1px #000;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  .format-display {
    text-align: center;
    .format-display-cover {
      max-height: 500px;
      overflow: hidden;
    }
    .el-button {
      margin-top: 10px;
    }
  }
  .register-btn {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    border: dotted 1px #000;
    border-radius: 100%;
    cursor: pointer;
  }
  .el-dialog {
    .dialog-footer {
      text-align: center;
    }
  }
}
.gray-filter {
  background: gray;
  filter: brightness(50%);
}
</style>
