<template>
  <div class="tools02-api-histories-google-log-table">
    <!-- 検索部分 -->
    <div class="log-search-box">
      <el-row type="flex" align="middle">
        <el-col>
          <el-button @click="changeDatetimeRange('180')" type="primary"
            >全件（過去６０日）</el-button
          >
          <el-button @click="changeDatetimeRange('today')" type="primary"
            >本日</el-button
          >
          <el-button @click="changeDatetimeRange('yesterday')" type="primary"
            >昨日</el-button
          >
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">
          <span>検索日時：</span>
        </el-col>
        <el-col :span="21">
          <el-date-picker
            v-model="dateTimeRange"
            type="datetimerange"
            align="right"
            start-placeholder="開始日時"
            end-placeholder="終了日"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-col>
      </el-row>
      <el-row v-if="pageType === 'ctrl'" type="flex" align="middle">
        <el-col :span="3"> ユーザーID： </el-col>
        <el-col :span="20">
          <el-input-number v-model="userId"></el-input-number>
          <button
            class="clear-button"
            type="button"
            @click="clearValue('userId')"
          >
            クリア</button
          >（０で検索対象外）
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">アカウント名：</el-col>
        <el-col :span="20">
          <el-select placeholder="選択してください" v-model="accountId">
            <el-option
              v-for="(accountData, accountIndex) in accountList"
              :key="accountIndex"
              :label="accountData.accountName"
              :value="accountData.accountId"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="1">
          <button
            class="clear-button"
            type="button"
            @click="clearValue('accountId')"
          >
            クリア
          </button>
        </el-col>
      </el-row>

      <el-row>
        <el-col style="text-align: center">
          <el-button @click="getParameterLogs(1)" type="primary"
            >検索</el-button
          >
        </el-col>
      </el-row>
      <!--  -->
      <el-divider></el-divider>
      <el-row type="flex" v-if="searchedDatetimeRange.length > 0">
        <el-col :span="2">検索期間：</el-col>
        <el-col>
          {{ searchedDatetimeRange[0] }} ～
          {{ searchedDatetimeRange[1] }}
        </el-col>
      </el-row>
    </div>
    <!-- 検索部分ここまで -->

    <div
      v-if="loading === true"
      v-loading="loading"
      style="width: 100%; height: 150px"
    ></div>
    <div v-else class="width-max-cover">
      <el-row v-if="logsTotal > 0">
        <el-col>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-pagination
              :page-size="pageSize"
              :pager-count="pagerCount"
              :total="logsTotal"
              :current-page.sync="currentPage"
              layout="prev, pager, next"
            />
            <div style="padding: 5px">
              <span style="margin-right: 15px">
                件数：
                <span>{{ logsTotal }}</span
                >件
              </span>
            </div>
          </div>
          <el-table :data="googleApiHistoryList" border>
            <el-table-column prop="sendDatetime" label="API送信日時">
            </el-table-column>
            <el-table-column label="アカウント名">
              <template slot-scope="scope">
                <span>{{ scope.row.accountData.accountName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="successCount" label="成功">
            </el-table-column>
            <el-table-column prop="errorCount" label="エラー">
            </el-table-column>
            <el-table-column label="重複">
              <template slot-scope="scope">
                <span>{{ scope.row.duplicateCount }}</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="80px" label="ログ">
              <template slot-scope="scope">
                <template v-if="scope.row.errorData === null">
                  <router-link
                    :to="`api-history-detail/${scope.row.id}`"
                    target="_blank"
                  >
                    <el-button type="primary" size="mini">ログ</el-button>
                  </router-link>
                </template>
                <template v-else>
                  <a
                    style="color: red !important"
                    :href="scope.row.errorData.manualPath"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p>{{ scope.row.errorData.message }}</p>
                  </a>
                </template>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :pager-count="pagerCount"
            :total="logsTotal"
            :current-page.sync="currentPage"
            layout="prev, pager, next"
          />
        </el-col>
      </el-row>
      <el-row v-else>
        <el-col>
          <p style="text-align: center">ログがありません。</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Tools02ApiHistoriesGoogleLogTable",
  components: {},
  props: {
    pageType: {
      validator(value) {
        return ["default", "ctrl"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      logsTotal: 0,

      // ===== ↓↓ 検索用 ↓↓ =====
      dateTimeRange: [],
      userId: 0,
      accountId: "",
      // ===== ↑↑ 検索用 ↑↑ =====

      googleApiHistoryList: [],
      searchedDatetimeRange: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
    accountList() {
      return this.$store.getters["tools02/accounts"]("7");
    },
  },
  watch: {
    currentPage: {
      handler(newValue) {
        this.getParameterLogs(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    changeDatetimeRange(term) {
      this.dateTimeRange = this.g_getDatetimeRange(term);
    },
    getParameterLogs(currentPage) {
      this.currentPage = currentPage;
      this.loading = true;
      let url = `${this.rt}/l/api/tools02/media/google/get-api-history-list`;
      url += `?user_id=${this.userId}`;
      url += `&datetime_range=${JSON.stringify(this.dateTimeRange)}`;
      url += `&account_id=${this.accountId === "" ? "0" : this.accountId}`;
      url += `&current_page=${currentPage}`;
      url += `&page_size=${this.pageSize}`;
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.googleApiHistoryList = response.data.googleApiHistoryList;
            this.logsTotal = response.data.totalCount;
            this.searchedDatetimeRange = response.data.searchedDatetimeRange;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearValue: function (object) {
      if (object === "userId") {
        this.userId = 0;
      } else if (object === "accountId") {
        this.accountId = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
