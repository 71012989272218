<template>
  <div class="format-display" :class="{ mini: this.size == 'mini' }">
    <div
      v-if="
        format.toolId === '1' ||
        format.tool_id === '1' ||
        format.toolId === '6' ||
        format.tool_id === '6'
      "
    >
      <Format0101
        v-if="
          format.typeId === '1' || format.type_id === '1' || format.type === '1'
        "
        :format="format"
      />
      <Format0102
        v-else-if="
          format.typeId === '2' || format.type_id === '2' || format.type === '2'
        "
        :format="format"
      />
      <Format0103
        v-else-if="
          format.typeId === '3' || format.type_id === '3' || format.type === '3'
        "
        :format="format"
      />
      <Format0104
        v-else-if="
          format.typeId === '4' ||
          format.type_id === '4' ||
          format.type === '4' ||
          format.typeId === '5' ||
          format.type_id === '5' ||
          format.type === '5'
        "
        :format="format"
      />
    </div>
    <div v-else-if="format.toolId === '2' || format.tool_id === '2'">
      <Format0201
        v-if="
          format.typeId === '1' || format.type_id === '1' || format.type === '1'
        "
        :format="format"
      />
      <Format0202
        v-else-if="
          format.typeId === '2' || format.type_id === '2' || format.type === '2'
        "
        :format="format"
      />
    </div>
    <div v-else-if="format.toolId === '3' || format.tool_id === '3'">
      <Format0301 :format="format" />
    </div>
    <div v-else-if="format.toolId === '4' || format.tool_id === '4'">
      <Format0401
        v-if="
          format.typeId === '1' || format.type_id === '1' || format.type === '1'
        "
        :format="format"
      />
      <Format0401
        v-else-if="
          format.typeId === '2' || format.type_id === '2' || format.type === '2'
        "
        :format="format"
      />
      <Format0401
        v-else-if="
          format.typeId === '3' || format.type_id === '3' || format.type === '3'
        "
        :format="format"
      />
    </div>
  </div>
</template>

<script>
import Format0101 from "@/components/Ui/Tools01/Format0101.vue";
import Format0102 from "@/components/Ui/Tools01/Format0102.vue";
import Format0103 from "@/components/Ui/Tools01/Format0103.vue";
import Format0104 from "@/components/Ui/Tools01/Format0104.vue";
import Format0201 from "@/components/Ui/Tools01/Format0201.vue";
import Format0202 from "@/components/Ui/Tools01/Format0202.vue";
import Format0301 from "@/components/Ui/Tools01/Format0301.vue";
import Format0401 from "@/components/Ui/Tools01/Format0401.vue";

export default {
  name: "FormatDisplay",
  props: {
    size: String,
    format: Object,
  },
  components: {
    Format0101,
    Format0102,
    Format0103,
    Format0104,
    Format0201,
    Format0202,
    Format0301,
    Format0401,
  },
  data() {
    return {};
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.mini {
  transform: scale(0.93);
}
.el-button {
  padding: 1em 0;
  font-size: 20px;
  width: 100%;
}
</style>
