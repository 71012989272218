<template>
  <div class="tools02-authorization-yahoo">
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="authorize" type="success">認証画面へ</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Tools02AuthorizationYahoo",
  components: {},
  props: {},
  data() {
    return {
      userData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {
    if(this.$route.query.status === undefined) {
      //no action.
    }else {
      if(this.$route.query.status === '200') {
        alert("Tokenの取得に成功しました。");
      }else {
        alert("Tokenの取得に失敗しました。");
      }
    }
    this.userData = JSON.parse(JSON.stringify(this.$store.getters.userData));
  },
  methods: {
    authorize() {
      this.$axios
        .get(`${this.rt}/api/tools02/yahoo-cv/create-token.php`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            //URL
            let url = `https://biz-oauth.yahoo.co.jp/oauth/v1/authorize`;

            //query
            let query = "";
            query += `?response_type=code`;
            query += `&client_id=${res.data.clientId}`;
            query += `&redirect_uri=${this.rt}/api/tools02/yahoo-cv/get-tokens.php`;
            query += `&scope=yahooads`;
            query += `&state=${res.data.state}`;

            //jump
            location.href = url + query;
            return;

          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 95%;
  margin-left: auto;
  td {
    padding: 25px;
  }
}
a {
  color: #409eff;
  font-weight: bold;
}
</style>
