<template>
  <div class="tools01">
    <router-view
      :toolId="toolId"
      :tool="tool"
    />
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    toolId: String,
    tool: String,
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
