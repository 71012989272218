import { render, staticRenderFns } from "./TagTestSystem.vue?vue&type=template&id=4237e040&scoped=true&"
import script from "./TagTestSystem.vue?vue&type=script&lang=js&"
export * from "./TagTestSystem.vue?vue&type=script&lang=js&"
import style0 from "./TagTestSystem.vue?vue&type=style&index=0&id=4237e040&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4237e040",
  null
  
)

export default component.exports