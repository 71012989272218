<template>
  <div class="manual-tools02-contents">
    <h2>全体の進め方について</h2>

    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <!-- <iframe
          src="https://player.vimeo.com/video/529599153?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="01-01全体の進め方について"
        ></iframe> -->
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/529599153?h=ff05bbc6c4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="01-01全体の進め方について"
          ></iframe>
        </div>
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <p class="center">※準備中</p>
        <p class="center">
          （→<router-link :to="'/manual/yahoo-cv'">マニュアル一覧</router-link
          >）
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualTools02Contents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
