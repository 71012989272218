<template>
  <div
    class="tools02-report-import-manual-send-conversion-event-system-tiktok-inputs"
  >
    <el-row v-if="type === 'default'" type="flex" align="middle">
      <el-col :span="3">プログラム名（※）</el-col>
      <el-col :span="10">
        <el-select v-model="promotionId" clearable>
          <el-option
            v-for="(promotionData, index) in promotionList"
            :key="index"
            :label="promotionData.promotionName"
            :value="promotionData.promotionId"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">報酬額（※）</el-col>
      <el-col :span="4"
        ><el-input
          v-model="conversionData.value"
          type="number"
          min="0"
          clearable
      /></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">CV時間（※）</el-col>
      <el-col :span="10"
        ><el-date-picker
          v-model="conversionData.timestamp"
          type="datetime"
          clearable
        >
        </el-date-picker
      ></el-col>
    </el-row>
    <el-row v-if="type === 'ctrl'" type="flex" align="middle">
      <el-col :span="3">CVイベント</el-col>
      <el-col :span="20">
        <el-select v-model="conversionData.event">
          <el-option
            v-for="tiktokEventData in tiktokEventList"
            :key="tiktokEventData.id"
            :label="tiktokEventData.name"
            :value="tiktokEventData.event"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">ttclid（※）</el-col>
      <el-col :span="21"
        ><el-input v-model="conversionData.callback" clearable
      /></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">IPアドレス</el-col>
      <el-col :span="4"
        ><el-input v-model="conversionData.ip" clearable
      /></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">User Agent</el-col>
      <el-col :span="21"
        ><el-input v-model="conversionData.userAgent" clearable
      /></el-col>
    </el-row>
    <el-row v-if="type === 'ctrl'" type="flex" align="middle">
      <el-col :span="3">event_id</el-col>
      <el-col :span="4"
        ><el-input v-model="conversionData.eventId" clearable
      /></el-col>
    </el-row>
    <el-row v-if="type === 'ctrl'" type="flex" align="middle">
      <el-col :span="3">CVページURL</el-col>
      <el-col :span="15"
        ><el-input v-model="conversionData.url" clearable
      /></el-col>
    </el-row>
    <!-- <el-row type="flex" align="middle">
      <el-col :span="3">リファラ</el-col>
      <el-col :span="21"><el-input v-model="conversionData.referrer" clearable/></el-col>
    </el-row> -->
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="sendConversionEvent" type="primary">送信</el-button>
      </el-col>
    </el-row>
    <el-row v-if="resultData !== undefined">
      <el-col>
        <h3>送信結果</h3>
        <div v-if="resultData.eventReceived === true">送信成功！</div>
        <div v-else>{{ resultData.errorMessage }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tools02-report-import-manual-send-conversion-event-system-tiktok-inputs",
  components: {},
  props: {
    resultData: Object,
    type: String,
  },
  data() {
    return {
      tools02TagId: "",
      promotionId: "",
      conversionData: {
        timestamp: "",
        event: "",
        value: 0,
        callback: "",
        ip: "",
        userAgent: "",
        eventId: "",
        url: "",
        // referrer: "",
        currency: "JPY",
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "userData", "apiErrorMessage"]),
    ...mapGetters("tools02", ["tiktokEventList"]),
    promotionList() {
      return this.$store.getters["tools02/promotionListByToolId"]("11");
    },
  },
  methods: {
    sendConversionEvent() {
      //
      if (this.type === 'default') {
        if (
          this.promotionId !== '' &&
          this.conversionData.timestamp !== '' &&
          this.conversionData.timestamp !== null &&
          this.conversionData.callback !== ''
        ) {
          // ok.
        } else {
          alert("必須項目（※）を入力してください。");
          return;
        }
      } else {
        // no action.
      }

      //
      let conversionData = JSON.parse(JSON.stringify(this.conversionData));

      //
      if (this.promotionId !== "") {
        const promotionData = this.$store.getters["tools02/promotionData"](
          this.promotionId
        );

        //
        const tiktokEventData = this.$store.getters["tools02/tiktokEventData"](
          promotionData.tiktokEventId
        );
        conversionData.event = tiktokEventData.event;

        //
        conversionData.url = promotionData.cvPageUrl;
      } else {
        // no action.
      }

      //
      const payload = {
        tools02TagId: this.tools02TagId,
        conversionData: conversionData,
      };
      this.$emit("sendConversionEvent", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
