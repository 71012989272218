<template>
  <div class="info-user-data">
    <div v-if="pageType === 'default'">
      <el-row type="flex" justify="center">
        <el-col>
          <table class="typeB">
            <tr>
              <th>ユーザーID</th>
              <td>
                <span>{{ userData.userId }} </span>
              </td>
            </tr>
            <tr>
              <th>法人名・屋号名（※任意）</th>
              <td>
                <div>
                  <el-input v-model="userData.companyName" />
                  <el-button type="primary" @click="register">変更</el-button>
                </div>
              </td>
            </tr>
            <tr>
              <th>お名前</th>
              <td>
                <span>{{ userData.name01 }} </span>
                <span>{{ userData.name02 }}</span>
              </td>
            </tr>
            <tr>
              <th>メールアドレス<br class="sp-only" />（ログインID）</th>
              <td>
                <div>{{ userData.email }}</div>
              </td>
            </tr>
            <tr>
              <th>新しいメールアドレス</th>
              <td>
                <div>
                  <el-input v-model="newEmail" class="newmail" />
                  <el-button type="primary" @click="changeEmail"
                    >変更</el-button
                  >
                </div>
              </td>
            </tr>
          </table>
          <div class="description">
            <p>
              ご登録時の「お名前」の変更を希望する場合は、お問い合わせからご連絡をお願いします。<br />
              法人名・屋号名をご登録いただくと、ポイント購入時に配信されるメール内、管理画面内の「購入・消費履歴」に法人名・屋号名が記載されるようになります。
            </p>
            <!-- <p>
              ※変更できるのは「メールアドレス（ログインID）」のみです。ご登録時の「お名前」の変更を希望する場合は、お問い合わせからご連絡をお願いします。
            </p> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="pageType === 'confirm'">
      <el-row>
        <el-col>
          <p style="text-align: center">
            新しいメールアドレスに認証用のメールを送信しました。<br />メール内に記載されている暗証番号を入力してください。
          </p>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center" align="middle">
        <el-col :span="2">
          <p>暗証番号:</p>
        </el-col>
        <el-col :span="3">
          <el-input v-model="emailChangePassword" />
        </el-col>
      </el-row>
      <el-row>
        <el-col style="text-align: center">
          <el-button @click="confirmPassword" type="primary">認証</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-else-if="pageType === 'complete'">
      <el-col>
        <p style="text-align: center">メールアドレスの変更が完了しました。</p>
        <div style="text-align: center; margin-top: 30px">
          <el-button @click="toDefault">戻る</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "InfoUserData",
  components: {},
  props: {},
  data() {
    return {
      pageType: "default",
      // pageType: "confirm",
      // pageType: "complete",
      userData: {
        companyName: "",
      },
      emailChangePassword: "",
      newEmail: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  created() {
    this.userData = JSON.parse(JSON.stringify(this.$store.getters.userData));
  },
  methods: {
    register() {
      //
      let params = new URLSearchParams();
      params.append("company_name", this.userData.companyName);
      this.$axios
        .post(`${this.rt}/l/api/user/register`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert("更新しました。");
            this.$store.dispatch("setUserData");
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push(`/login`);
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeEmail() {
      //
      if (
        this.newEmail.indexOf("outlook.jp") === -1 &&
        this.newEmail.indexOf("hotmail.co.jp") === -1 &&
        this.newEmail.indexOf("hotmail.com") === -1
      ) {
        // no action.
      } else {
        alert("outlook.jpやhotmailのメールはご利用いただけません");
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("new_email", this.newEmail);
      this.$axios
        .post(`${this.rt}/api/send-email-change-password.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.pageType = "confirm";
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push(`/login`);
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirmPassword() {
      let params = new URLSearchParams();
      params.append("password", this.emailChangePassword);
      this.$axios
        .post(`${this.rt}/api/change-email.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.newEmail = "";
            this.emailChangePassword = "";
            this.$store.dispatch("setUserData");
            this.pageType = "complete";
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push(`/login`);
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toDefault() {
      this.pageType = "default";
    },
  },
};
</script>

<style lang="scss" scoped>
span.input {
  font-size: 18px;
  font-weight: bold;
}
table.typeB {
  tr {
    th {
      @media screen and (max-width: 767px) {
        font-size: 12px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    td {
      padding-left: 30px;
      @media screen and (max-width: 767px) {
        padding-left: 8px;
      }
      .el-input {
        width: 60%;
        margin-right: 15px;
      }
    }
  }
}
.newmail {
  width: 70%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
</style>
