<template>
  <div class="tools02-copy-promotion-list">
    <el-row>
      <el-col :span="4">
        <a :href="``" target="_blank" rel="noopener noreferrer">
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row class="search-box">
      <el-col>
        <el-row type="flex" align="middle">
          <el-col :xs="9" :sm="3">
            <span>媒体：</span>
          </el-col>
          <el-col :xs="15" :sm="4">
            <el-select
              v-model="searchConditionData.toolId"
              placeholder="選択してください。"
              clearable
            >
              <el-option
                v-for="toolData in sbExcludedToolListInTopPageOrder"
                :key="toolData.toolId"
                :label="toolData.mediaName"
                :value="toolData.toolId"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :xs="9" :sm="3">
            <span>アカウント名：</span>
          </el-col>
          <el-col :xs="15" :sm="8">
            <el-input
              v-model="searchConditionData.accountName"
              clearable
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :xs="9" :sm="3">
            <span>プログラム名：</span>
          </el-col>
          <el-col :xs="15" :sm="12">
            <el-input
              v-model="searchConditionData.promotionName"
              clearable
            ></el-input>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :xs="9" :sm="3">
            <span>ASP：</span>
          </el-col>
          <el-col :xs="15" :sm="4">
            <el-select
              v-model="searchConditionData.aspId"
              placeholder="選択してください。"
              clearable
            >
              <el-option
                v-for="aspData in aspList"
                :key="aspData.aspId"
                :label="aspData.aspName"
                :value="aspData.aspId"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="button-cover">
              <el-button @click="getPromotionList()" type="primary"
                >検索</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="promotion-list-cover">
      <el-col>
        <el-table :data="promotionList" stripe style="width: 100%">
          <el-table-column label="媒体" width="70px">
            <template slot-scope="scope">{{
              $store.getters.toolData(scope.row.toolId).alphaCode
            }}</template>
          </el-table-column>
          <el-table-column label="アカウント名" width="250px">
            <template slot-scope="scope">{{
              scope.row.accountData.accountName
            }}</template>
          </el-table-column>
          <el-table-column label="プログラム名">
            <template slot-scope="scope">{{
              scope.row.promotionName
            }}</template>
          </el-table-column>
          <el-table-column label="ASP" width="150px">
            <template slot-scope="scope">{{
              $store.getters.aspData(scope.row.aspId).aspName
            }}</template>
          </el-table-column>
          <el-table-column label="" width="110px">
            <template slot-scope="scope"
              ><el-button @click="copy(scope.row)" type="success"
                >コピー</el-button
              ></template
            >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "Tools02AccountsAccountList",
  components: {
    Button2,
  },
  props: {
    toolId: String,
    accountId: String,
  },
  data() {
    return {
      promotionList: [],
      searchConditionData: {
        toolId: "",
        accountName: "",
        promotionName: "",
        aspId: "",
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "userData", "aspList"]),
    ...mapGetters("tools02", ["sbExcludedToolListInTopPageOrder"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {},
  created() {
    this.getPromotionList();
  },
  methods: {
    getPromotionList() {
      //
      let originalPromotionList = JSON.parse(
        JSON.stringify(this.$store.getters["tools02/promotionList"])
      );

      //
      let dataAddedPromotionList = [];
      for (const originalPromotionData of originalPromotionList) {
        //
        originalPromotionData.accountData = this.$store.getters[
          "tools02/accountData"
        ](originalPromotionData.accountId);

        //
        dataAddedPromotionList.push(originalPromotionData);
      }

      //
      let promotionList = [];
      for (const promotionData of originalPromotionList) {
        //
        if (this.searchConditionData.toolId !== "") {
          if (promotionData.toolId === this.searchConditionData.toolId) {
            // ok.
          } else {
            continue;
          }
        } else {
          // no action.
        }

        //
        if (this.searchConditionData.accountName !== "") {
          if (
            promotionData.accountData.accountName.includes(
              this.searchConditionData.accountName
            ) === true
          ) {
            // ok.
          } else {
            continue;
          }
        } else {
          // no action.
        }

        //
        if (this.searchConditionData.promotionName !== "") {
          if (
            promotionData.promotionName.includes(
              this.searchConditionData.promotionName
            ) === true
          ) {
            // ok.
          } else {
            continue;
          }
        } else {
          // no action.
        }

        //
        if (this.searchConditionData.aspId !== "") {
          if (promotionData.aspId === this.searchConditionData.aspId) {
            // ok.
          } else {
            continue;
          }
        } else {
          // no action.
        }

        //
        promotionList.push(promotionData);
      }

      // sort
      const topPageToolIdList = this.$store.getters[
        "tools02/toolListInTopPageOrder"
      ].map((toolData) => toolData.toolId);
      const sortedPromotionList = promotionList.sort((a, b) => {
        const orderA = topPageToolIdList.indexOf(a.toolId);
        const orderB = topPageToolIdList.indexOf(b.toolId);

        if (orderA !== orderB) {
          return orderA - orderB;
        } else if (a.accountData.accountName !== b.accountData.accountName) {
          return a.accountData.accountName.localeCompare(
            b.accountData.accountName
          );
        } else {
          return a.promotionName.localeCompare(b.promotionName);
        }
      });

      //
      this.promotionList = sortedPromotionList;
    },
    copy(promotionData) {
      //
      let confirmMessage = "";
      confirmMessage += "以下のプログラム情報をコピーしますか？\n";
      confirmMessage += "\n";
      confirmMessage += `媒体：${
        this.$store.getters.toolData(promotionData.toolId).mediaName
      }\n`;
      confirmMessage += `アカウント名：${promotionData.accountData.accountName}\n`;
      confirmMessage += `プログラム名：${promotionData.promotionName}\n`;
      confirmMessage += `ASP：${
        this.$store.getters.aspData(promotionData.aspId).aspName
      }`;

      //
      if (confirm(confirmMessage)) {
        //
        let url = `/tools02/${this.toolData.codeKebab}/account-detail/${this.accountId}/promotion/0?copyPromotionId=${promotionData.promotionId}`;

        //
        switch (this.toolId) {
          case "8":
          case "11":
          case "14":
          case "15":
            url = `/tools02/${this.toolData.codeKebab}/promotion-detail/0?copyPromotionId=${promotionData.promotionId}`;
            break;

          default:
            break;
        }

        // 
        this.$router.push(url);
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-box {
  .button-cover {
    margin-top: 15px;
    text-align: center;
  }
}
.promotion-list-cover {
  margin-top: 30px;
}
</style>
