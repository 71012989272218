Ω<template>
  <div class="description">
    <!-- このComponent使ってないかも -->
    <el-row>
      <el-col>
        <h2 v-if="tool === 'overlay01'">{{ tools[tool.codeCamel].name }} ツール説明</h2>
        <h2 v-else>{{ tools[tool.codeCamel].name2 }} ツール説明</h2>
      </el-col>
    </el-row>
    <Popup01Description v-if="tool == 'popup01'" />
    <Popup02Description v-else-if="tool == 'popup02'" />
    <OverlayDescription
      v-else-if="tool == 'overlay01' || tool == 'overlay02'"
    />
    <GadgetDescription v-else-if="tool == 'gadget'" />
    <GoogleCvDescription v-else-if="tool == 'google-cv'" />

    <div v-if="tool == 'popup01' || tool == 'popup02'">
      <router-link class="btn01" :to="`/description/${relatedToolCode}`">
        【関連ツール】{{ tools[relatedToolCode].name2 }}について詳細を見てみる
      </router-link>
    </div>
    <router-link class="btn02" to="/"> 管理画面トップへ戻る </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Popup01Description from "@/components/Description/Popup01Description.vue";
import Popup02Description from "@/components/Description/Popup02Description.vue";
import OverlayDescription from "@/components/Description/OverlayDescription.vue";
import GadgetDescription from "@/components/Description/GadgetDescription.vue";
import GoogleCvDescription from "@/components/Description/GoogleCvDescription.vue";

export default {
  components: {
    Popup01Description,
    Popup02Description,
    OverlayDescription,
    GadgetDescription,
    GoogleCvDescription,
  },
  props: {
    tool: String,
  },
  data() {
    return {
      relatedToolCode: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  watch: {
    tool: {
      handler(newValue) {
        this.changeRelatedToolCode(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    changeRelatedToolCode(tool) {
      switch (tool) {
        case "popup01":
          this.relatedToolCode = "popup02";
          break;
        case "popup02":
          this.relatedToolCode = "popup01";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(h3.title) {
  font-size: 36px;
  margin-bottom: 50px;
  color: #4d4d4d;
  font-weight: bold;
  text-align: center;
  // border-top: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
}
.typeA {
  border-top: solid 1px var(--gray);
  border-bottom: solid 1px var(--gray);
  text-align: center;
  font-size: 26px;
  padding: 10px 0;
}
.qa-box {
  margin-top: 30px;
  .qa-unit {
    border-bottom: dotted 1px var(--gray);
    padding: 20px 0;
    .q,
    .a {
      display: flex;
      align-items: center;
      span:first-child {
        font-weight: bold;
        font-size: 30px;
        margin-right: 25px;
      }
    }
    .q {
      color: #1c6db5;
      font-weight: bold;
    }
    .a {
      color: #ccc;
      span:last-child {
        color: var(--black);
      }
      font-size: 14px;
    }
  }
  .qa-unit:last-child {
    border-bottom: none;
  }
}
.btn01,
.btn02 {
  border: 2px solid #ccc;
  display: block;
  padding: 10px 50px;
  border-radius: 8px;
  color: #4d4d4d;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  width: 600px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 20px;
  // background: #f2f2f2;
}
</style>
