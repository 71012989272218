<template>
  <div class="item-detail">
    <!-- 2020/12/14現在、router/index.js にて、リダイレクトしています。 -->
    <el-row>
      <el-col class="right-button-cover" v-show="$route.params.item_id != 0">
        <el-button @click="del" type="danger">削除</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <table class="typeB">
          <tr v-show="$route.params.item_id != 0">
            <th>商品画像</th>
            <td>
              <div class="img-box">
                <img :src="img">
              </div>
              <div class="edit">
                <div v-if="item.img == 0">
                  <input type="file" @change="selectedFile"><br>
                  <el-button type="primary" @click="upImg">アップロード</el-button>
                </div>
                <el-button v-else type="danger" @click="delImg">画像を削除</el-button>
              </div>
            </td>
          </tr>
          <tr>
            <th>商品名</th>
            <td>
              <div>
                <el-input type="text" v-model="item.name"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>価格</th>
            <td>
              <div>
                <el-input type="number" v-model="item.price" style="width:120px;margin-right:10px;" min="0"/>円
              </div>
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row v-show="uploadFile == null">
      <el-col style="text-align:center;">
        <el-button type="primary" @click="register">登録</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      item: [],
      img: '',
      uploadFile: null,
    }
  },
  created() {
      this.$axios.get(this.$store.getters.rt+"/api/getItemDetail.php?item_id="+this.$route.params.item_id)
      .then(res => {
        console.log(res);
        this.item = res.data.list;
        if(this.item.img == 0) {
          this.img = this.$store.getters.rt+'/uploads/user/item/active/no_photo.jpg';
        }else {
          this.img = this.$store.getters.rt+'/uploads/user/item/active/'+this.item.img;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {

    register() {
      if(this.item.name == '') {
        alert('入力されていない項目があります。');
      }else {
        let params = new URLSearchParams();
        params.append("item", JSON.stringify(this.item));
        this.$axios.post(this.$store.getters.rt+"/api/mypage/regItemDetail.php", params)
        .then(res => {
          console.log(res);
          // alert('登録しました。');
          if(this.$route.params.item_id == 0) {
            this.$router.go(-1);
          }else {
            location.reload();
          }
        })
        .catch(err => {
          console.log(err);
        });
      }
    },
    
		upImg () {
			if(this.uploadFile === null) {
				alert('ファイルを選択してください。');
				return;
			}else {
				let formData = new FormData();
				formData.append('uploadFile', this.uploadFile);
				formData.append('item_id', this.$route.params.item_id);
				let config = { headers: {'content-type': 'multipart/form-data'} }
				this.$axios.post(this.$store.getters.rt+'/api/mypage/uploadItemImg.php', formData, config)
				.then(res => {
          console.log(res);
          if(res.data == "EXCESS") {
            alert(this.$store.getters.imgLimitMessage);
            return;
          }else {
            // alert('アップロードしました。');
            location.reload();
          }
				})
				.catch(function (err) {
          console.log(err);
				});
			}
		},
		selectedFile (event) {
			var size = event.target.files[0].size;
			if(size > this.imgLimit) {
				alert(this.imgLimitMessage);
				return;
			}else {
				event.preventDefault();
				let files = event.target.files;
				this.uploadFile = files[0];
				this.$forceUpdate();
			}
		},
    delImg() {
      if(confirm('この画像を削除します。よろしいですか？')) {
				let formData = new FormData();
				formData.append('file_name', this.item.img);
				this.$axios.post(this.$store.getters.rt+'/api/mypage/delItemImg.php', formData)
				.then(res => {
					console.log(res);
          // alert('削除しました。');
          location.reload();
				})
				.catch(function (err) {
          console.log(err);
				});
      }
    },
    del() {
      if(confirm('この商品を削除します。よろしいですか？')) {
				let formData = new FormData();
				formData.append('item_id', this.$route.params.item_id);
				this.$axios.post(this.$store.getters.rt+'/api/mypage/delItemDetail.php', formData)
				.then(res => {
					console.log(res);
          // alert('削除しました。');
          this.$router.go(-1);
				})
				.catch(function (err) {
          console.log(err);
				});
      }
    }

  }
}
</script>

<style lang="scss" scoped>
table.typeB {
  .img-box {
    width: 250px;
    height: 250px;
    position: relative;
    margin: 0 auto;
    img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .edit {
    margin: 15px 0 5px;
    text-align: center;
    input[type="file"] {
      margin-bottom: 10px;
    }
  }
}
</style>
