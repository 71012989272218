<template>
  <div class="tools02-account-detail-auto-upload-url">
    <div class="account-table">
      <div class="account-table-inner">
        <h3 class="back-gray">CSV自動アップロード用URL</h3>
        <el-input
          type="textarea"
          style="min-heght: 76px"
          :autosize="{ minRows: 1 }"
          :value="uploadFileUrl"
          onclick="this.select();"
        />
        <div class="flex">
          <el-col class="manu-but">
            <a v-if="tool.codeKebab === 'microsoft-cv'"
              href="https://adtasukaru.wraptas.site/02686f3bbc154f958a8faa2bce50cb9d"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button2 type2="1" class="manual-button" msg="マニュアル" />
            </a>
            <a v-else
              href="https://adtasukaru.wraptas.site/9b8200cea24941cda8f59298bd652e1c"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button2 type2="1" class="manual-button" msg="マニュアル" />
            </a>
          </el-col>
          <el-col>
            <div class="letter">
              ※注１ 過去2日間＋本日分を毎時00分に更新しております。
            </div>
            <div class="letter">
              ※注２
              過去2日以前のものはレポートから手動でダウンロード・アップロードをお願いいたします。
            </div>
          </el-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "Tools02AccountDetailAutoUploadUrl",
  components: {
    Button2,
  },
  props: {
    accountId: String,
  },
  data() {
    return {
      account: {},
      uploadFileUrl: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
  },
  watch: {
    accountId: {
      handler(newValue) {
        this.account = JSON.parse(
          JSON.stringify(this.$store.getters["tools02/accountData"](newValue))
        );
      },
      immediate: true,
    },
  },
  created() {
    switch (this.tool.codeKebab) {
      case "google-cv":
        this.uploadFileUrl = `https://adtasukaru.com/uploads/${this.tool.codeKebab}/schedule/${this.account.pass}.csv`;
        break;

      case "microsoft-cv":
        this.uploadFileUrl = `https://adtasukaru.com/l/storage/tools02/media/microsoft/upload-files/${this.account.pass}.xlsx`;
        break;

      default:
        // no action.
        break;
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.account-table {
  display: flex;
  justify-content: center;
  .account-table-inner {
    width: 75%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.flex {
  display: flex;
  margin-top: 20px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .manu-but {
    width: 140px;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
}
</style>
