<template>
  <div>
    <el-button type="success" @click="dialogVisible = true">認証用タグ</el-button>

    <!-- ↓↓ dialog ↓↓ -->
    <el-dialog :title="`【ドメイン認証用タグ】`" :visible.sync="dialogVisible" width="50%">
      <div>
        <p>&lt;/body>の直前に以下のタグを設置してください。</p>
        <el-input type="textarea" :autosize="{ minRows: 3 }" v-model="domainData.authTag" onclick="this.select();" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">閉じる</el-button>
      </span>
    </el-dialog>
    <!-- ↑↑ dialog ↑↑ -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'AuthTagButton',
  props: {
    domainId: String
  },
  data() {
    return {
      dialogVisible: false
    };
  },
  computed: {
    ...mapGetters([]),
    domainData() {
      return this.$store.getters.domainData(this.domainId);
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
:deep(.el-dialog) {
  text-align: left!important;
}
</style>
