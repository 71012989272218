<template>
  <div class="tools02-promotions">
    <el-row
      v-show="
        tool.codeKebab === 'facebook-cv' ||
        tool.codeKebab === 'tiktok-cv' ||
        tool.codeKebab === 'line-cv' ||
        tool.codeKebab === 'yda-cv'
      "
    >
      <el-col>
        <h2>プログラム一覧</h2>
      </el-col>
    </el-row>
    <Tools02PromotionsPromotionList :accountId="accountId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02PromotionsPromotionList from "@/components/Tools02/Promotions/PromotionList.vue";

export default {
  components: {
    Tools02PromotionsPromotionList,
  },
  props: {
    accountId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
