<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>プログラム情報登録<br class="sp-only">（AFAD）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CommonDescription :systemType="'afad'" />
        <PromotionIdDescription :aspId="'38'" />
        <h4>
          【１】<a
            href="https://affi-ads.com/"
            target="_blank"
            rel="noopener noreferrer"
            >管理画面</a
          >にログインしたら、「広告選択」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no19/01.png`" alt="" />
        <h4>
          【２】対象パートナーサイトを選択し、「検索する」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no19/02.png`" alt="" />
        <h4>
          【３】すでに提携しているプログラムが表示されるので、登録したいプログラム名を探して、「広告名」をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no19/03.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>
        <h4>【３】アドタスカルのASPプログラム名に貼り付けます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no05/no19/04.png`" alt="" />
        </div>
        <h4>【４】プログラムIDはご自身で決めます。</h4>
        <p>◎決め方</p>
        <ul>
          <li>半角英数字</li>
          <li>同じASP内で実施している他の案件と被らないようにする</li>
        </ul>
        <h4 id="from-manual1">
          【５】アフィリエイトリンクの後ろに「&amp;param2=[プログラムID]」を追記する
        </h4>
        <h5>▼SelfLeapから発行されたアフィリリンク</h5>
        <p>
          https://ac.sfad-by.com/○○○○○/cl/?bId=○○○○○<span
            class="green"
            style="font-weight: bold"
            >&amp;param2=●●●</span
          >
        </p>
        <h5>
          ▼「<span class="green">&amp;param2=●●●</span>」が付いていたら削除する
        </h5>
        <p>https://ac.sfad-by.com/○○○○○/cl/?bId=○○○○○</p>
        <h5>
          ▼【４】で決めたプログラムIDを「<span
            class="red"
            style="font-weight: bold"
            >&amp;param2=プログラムID</span
          >」という形で付ける
        </h5>
        <p>
          https://ac.sfad-by.com/○○○○○/cl/?bId=○○○○○<span
            class="red"
            style="font-weight: bold"
            >&amp;param2=eyesone</span
          >
        </p>
        <p>これを記事LPや中間ページに設置してください。</p>
        <p class="red2">
          ※自動連携テストの際はさらにパラメータが必要となりますので、詳しくは<router-link
            :to="`/manual/${tool.codeKebab}/08/21`"
            >こちら</router-link
          >を参考にしてください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromotionIdDescription from "@/components/Manual/Tools02/No05/Parts/PromotionIdDescription.vue";
import CommonDescription from "@/components/Manual/Tools02/Parts/CommonDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PromotionIdDescription,
    CommonDescription,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
  created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
