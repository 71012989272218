var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-test-system"},[_c('el-row',[_c('el-col',{attrs:{"span":4}},[_c('a',{attrs:{"href":_vm.toolData.manualData.tagTestManualUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('Button2',{staticClass:"manual-button",attrs:{"type2":"1","msg":"マニュアル"}})],1)])],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":15}},[_c('table',{staticClass:"typeB"},[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.g_getTools02TagCategoryName(_vm.toolId))+"名")]),_c('td',[_c('div',{staticStyle:{"padding":"10px"}},[_vm._v(_vm._s(_vm.tagData.name))])])]),_c('tr',[_c('th',[_c('span',[(
                  _vm.toolData.codeKebab === 'facebook-cv' ||
                  _vm.toolData.codeKebab === 'tiktok-cv'
                )?_c('span',[_vm._v("ピクセルID")]):(_vm.toolData.codeKebab === 'line-cv')?_c('span',[_vm._v("LINE Tag ID")]):(_vm.toolData.codeKebab === 'yda-cv')?_c('span',[_vm._v("yahoo_ydn_conv_io")]):_vm._e()])]),_c('td',[_c('div',{staticStyle:{"padding":"10px"}},[_vm._v(_vm._s(_vm.tagData.mediaTagId))])])]),_c('tr',[_c('th',[(
                _vm.toolData.codeKebab === 'facebook-cv' ||
                _vm.toolData.codeKebab === 'tiktok-cv' ||
                _vm.toolData.codeKebab === 'line-cv'
              )?_c('span',[_vm._v("アクセストークン")]):(_vm.toolData.codeKebab === 'yda-cv')?_c('span',[_vm._v("yahoo_ydn_conv_label")]):_vm._e()]),_c('td',[_c('div',{staticStyle:{"padding":"10px","word-break":"break-all"}},[_vm._v(" "+_vm._s(_vm.tagData.accessToken)+" ")])])]),(
            _vm.toolData.codeKebab === 'facebook-cv' ||
            _vm.toolData.codeKebab === 'tiktok-cv'
          )?_c('tr',[_c('th',[_c('span',[_vm._v("テストコード")])]),_c('td',[_c('el-input',{staticStyle:{"width":"30%"},model:{value:(_vm.testEventCode),callback:function ($$v) {_vm.testEventCode=$$v},expression:"testEventCode"}})],1)]):_vm._e()])])],1),_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',[_c('div',{staticClass:"button-cover"},[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.sendTestEvent}},[_vm._v("テスト送信")])],1)])],1),(_vm.resultData !== null)?_c('el-row',{attrs:{"type":"flex","justify":"center"}},[_c('el-col',{attrs:{"span":15}},[_c('h4',[_vm._v("送信結果")]),(_vm.resultData.eventReceived === true)?_c('div',[_vm._v("送信完了")]):_c('div',[_vm._v(_vm._s(_vm.resultData.errorMessage))])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }