<template>
  <div class="test-point-back">
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <el-button @click="setTestData" type="success"
          >テストデータをセット</el-button
        >
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>pixel_code</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="pixelCode" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>access_token</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="accessToken" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>event</h4>
      </el-col>
      <el-col :span="20">
        <el-select v-model="conversionData.event">
          <el-option
            v-for="item in [
              { label: '注文', value: 'PlaceAnOrder' },
              { label: '注文開始', value: 'InitiateCheckout' },
            ]"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>timestamp</h4>
      </el-col>
      <el-col :span="20">
        <el-date-picker
          v-model="conversionData.timestamp"
          type="datetime"
          value-format="yyyy-MM-ddTHH:mm:ssZ"
          placeholder="Select date and time"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>callback</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.callback" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>url</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.url" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>referrer</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.referrer" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>external_id</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.externalId" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>user_agent</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.userAgent" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>ip</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.ip" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>price</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.price" type="number" min="0" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>quantity</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.quantity" disabled />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>content_type</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.contentType" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>content_id</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.contentId" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>currency</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.currency" disabled />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>value</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionData.value" type="number" min="0" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="text-align: center">
          <el-button @click="send" type="primary">送信</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>レスポンス</h4>
        <p>{{ responseData }}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TestTiktokApi",
  components: {},
  props: {},
  data() {
    return {
      pixelCode: "",
      accessToken: "",
      conversionData: {
        event: "",
        eventId: "1616318632825_357",
        timestamp: "",
        callback: "",
        url: "",
        referrer: "",
        externalId: "",
        phoneNumber: "",
        email: "",
        userAgent: "",
        ip: "",
        price: 0,
        quantity: 1,
        contentType: "",
        contentId: "",
        currency: "JPY",
        value: 0,
      },

      //
      responseData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  methods: {
    setTestData() {
      this.pixelCode = "C96JGORC77U12DDT3V1G";
      this.accessToken = "433ebccd8ca2c5920b4dd3311e2d432786265451";
      this.conversionData = {
        event: "InitiateCheckout",
        eventId: "1616318632825_357",
        timestamp: "2020-09-17T19:49:27Z",
        callback: "123ATXSfe",
        url: "http://demo.mywebsite.com/purchase",
        referrer: "http://demo.mywebsite.com",
        externalId:
          "f0e388f53921a51f0bb0fc8a2944109ec188b59172935d8f23020b1614cc44bc",
        phoneNumber:
          "2f9d2b4df907e5c9a7b3434351b55700167b998a83dc479b825096486ffcf4ea",
        email:
          "dd6ff77f54e2106661089bae4d40cdb600979bf7edc9eb65c0942ba55c7c2d7f",
        userAgent:
          "Mozilla/5.0 (platform; rv:geckoversion) Gecko/geckotrail Firefox/firefoxversion",
        ip: "13.57.97.131",
        price: 8,
        quantity: 1,
        contentType: "product_group",
        contentId: "1077218",
        currency: "JPY",
        value: 10000,
      };
    },
    send() {
      let params = new URLSearchParams();
      params.append("pixel_code", this.pixelCode);
      params.append("access_token", this.accessToken);
      params.append("conversion_data", JSON.stringify(this.conversionData));
      this.$axios
        .post(`${this.rt}/api/test/tools02/tiktok-cv/api-send.php`, params)
        .then((response) => {
          console.log(response);
          this.responseData = response.data.responseData;
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0;
}
.el-row {
  margin: 10px 0;
}
</style>
