<template>
<div id="Template">
<div>
  <div class="cover" :style="'background-image: url('+rt+'/img/lp/tool_bg_img.png);'">
    <div class="txt_cover">
      <p class="point_title">{{TemplateTitle}}</p>
      <p class="point_txt">{{TemplateText}}</p>
    </div>
    <img :src="rt+'/img/description/'+DataName" :alt="altText" />
  </div>
</div>
</div>
</template>

<script>
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
import store from "@/store";
export default {
  name: "Template",
  props: {
    TemplateTitle: String,
    TemplateText: String,
    DataName: String,
    altText: String
  },
  data() {
    return {
      rt: store.state.rt
    }
  }
}
</script>

<style lang="scss" scoped>
.cover {
  margin-bottom: 70px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-repeat: no-repeat;
  
  .txt_cover {
    width: 440px;
    height: 260px;
    padding: 30px 40px;
    border-radius: 7px;
    box-sizing: border-box;
    
  }
 
  .point_title {
    font-size: 30px;
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 0.05em;
    color: #4d4d4d;
  }
  
}
img {
  box-shadow: 8px 10px 20px -9px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  margin-top: 50px;
  
}
</style>
