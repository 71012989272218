<template>
  <div class="manual-google-cv-contents">
    <h2>ドメイン認証を行う</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>ドメイン認証を行うことで、広告サイトを運用する自社ドメインのセキュリティ対策や誤った情報の拡散防止に役立ちます。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <h4>【１】ハンバガーメニューをクリックして開いて、「ビジネス設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/01.png`" alt="" />
        <h4>【２】「ブランドセーフティ」をクリックして開き、「ドメイン」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/02.png`" alt="" />
        </div>
        <h4>【３】「追加」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/03.png`" alt="" />
        <h4>【４】認証するドメインを入力し、「追加する」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/04.png`" alt="" />
        </div>
        <h4>【５】「オプションを1つ選択してください」の欄のプルダウンから、希望の認証方法を選択します。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/05.png`" alt="" />
        <h4>【６】手順【５】で選択した方法の指示どおりに対応したら、「ドメイン認証」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/06.png`" alt="" />
        <h4>【７】正常に認証されると、以下の画面が表示されるので、「完了」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/07.png`" alt="" />
        </div>
        <h4>【８】以下の画面のように「認証済み」と表示がされます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no11/08.png`" alt="" />
        <p>以上で、ドメイン認証は完了です。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/03/12`">合算イベント測定</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
