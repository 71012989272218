<template>
  <div class="tools01-abtest-detail">
    <el-row>
      <el-col>
        <h2>ABテスト設定・編集</h2>
      </el-col>
    </el-row>
    <AbTestRegister :toolId="toolId" :pageId="pageId" :testId="testId" />
  </div>
</template>

<script>
import AbTestRegister from "@/components/Tools01/AbTestDetail/AbTestRegister.vue";

export default {
  components: {
    AbTestRegister,
  },
  props: {
    toolId: String,
    pageId: String,
    testId: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
