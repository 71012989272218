var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'tinymce-editor'+_vm.num},[_c('Editor',{attrs:{"api-key":"jg024mzr4orehm1c1b1y7jfmhqrz1j9oyqz6931bnf8rpirh","init":{
      height: _vm.height,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar: 'undo | redo | forecolor backcolor fontsizeselect | bold underline',
      
      //入力時に改行が<p>にならないように
      force_br_newlines : true,
      force_p_newlines : false,
      forced_root_block : '',

      //テキストからコピペするときに改行が<p>にならないように
      paste_as_text: true
    }},on:{"onInit":function($event){return _vm.changeColor()}},model:{value:(_vm.myValue),callback:function ($$v) {_vm.myValue=$$v},expression:"myValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }