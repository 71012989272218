<template>
  <div class="login">
    <UiLogin :loginType="'normal'"/>
  </div>
</template>

<script>
import UiLogin from '@/components/Ui/Login.vue'

export default {
  components: {
    UiLogin
  },
  data() {
    return {
      email: '',
      pass: ''
    }
  },
  created() {
    sessionStorage.clear();
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
