<template>
  <div class="tools02-home-contents">
    <div class="sub-title3">お申し込み</div>
    <div class="items">
      <div class="button-cover">
        <router-link :to="`/tools02/${tool.codeKebab}/0`">
          <el-button class="initial">
            <div class="line">
              <span>お申し込み</span>
            </div>
          </el-button>
        </router-link>
      </div>
    </div>

    <div class="sub-title">マニュアル・利用方法</div>
    <div class="items">
      <div class="button-cover">
        <a href="https://adtasukaru.wraptas.site/ea4efc78aafd4ca3b33b995b89891cd6" target="_blank" rel="noopener noreferrer">
          <el-button class="initial">
            <div class="line">
              <img :src="`${rt}/img/tools02/initial.png`" alt height="20px" />
              <span>初期設定チュートリアル</span>
            </div>
          </el-button>
        </a>
      </div>
      <div class="button-cover">
        <a href="https://adtasukaru.wraptas.site/0cb303ae82d14b26998ce68e4183dfb7" target="_blank" rel="noopener noreferrer">
          <el-button class="doble">
            <div class="line">
              <span>
                <i class="el-icon-user-solid"></i>
              </span>
              <span style="text-align: center">
                利用設定・解約
                <br />・休止について
              </span>
            </div>
          </el-button>
        </a>
      </div>

      <div class="button-cover">
        <a href="https://adtasukaru.wraptas.site/71798b928d1347f99eb0c278ca3ce711" target="_blank" rel="noopener noreferrer">
          <el-button class="initial">
            <div class="line">
              <span>
                <i class="el-icon-s-order"></i>
              </span>
              <span>マニュアル・よくある質問</span>
            </div>
          </el-button>
        </a>
      </div>
    </div>
    
    <div class="sub-title2">連携設定</div>
    <div class="items">
      <div class="button-cover">
        <router-link :to="`/tools02/${tool.codeKebab}/point-back`">
          <el-button class="initial">
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span>カート・計測システム<br />
                連携設定</span>
            </div>
          </el-button>
        </router-link>
      </div>
      <div class="button-cover">
        <el-button
          class="initial"
          :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
          @click="move(`/tools02/${tool.codeKebab}/auto-import-log`)"
        >
          <div class="line">
            <span>
              <i class="el-icon-coin"></i>
            </span>
            <span>自動登録履歴</span>
          </div>
        </el-button>
      </div>
      <div class="button-cover">
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02HomeCartSystemContents",
  components: {
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "userData"]),
    ...mapGetters("subscription", ["validities"]),
  },
  methods: {
    move(link) {
      if (this.validities[this.tool.codeCamel] === 1) {
        this.$router.push(link);
      } else {
        console.log("not allowed.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
