<template>
  <div class="manual-tools02-contents">
    <h2>オフラインCV名のミスしない入力方法</h2>

    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h3>目的</h3>
        <ul>
          <li>
            アドタスカルのプログラム情報登録時のオフラインCV名の正しい登録方法を学びましょう。
          </li>
          <li>
            オフラインCV名を間違えて登録してしまうと、Googleにアップロードしたときにエラーが発生してしまいます。
          </li>
        </ul>
        <div class="mini-img">
          <img
            :src="`${rt}/img/manual/google-cv/no05/no02/01.png`"
            alt=""
            class="mini"
          />
        </div>
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <h3>目的</h3>
        <ul>
          <li>
            アドタスカルへのプログラム情報登録時に、オフラインCV名の正しい登録方法を学びます。
          </li>
          <li>
            オフラインCV名を間違えて登録してしまうと、Yahoo広告に成果データをアップロードしたときにエラーが発生してしまいます。
          </li>
        </ul>
      </el-col>
    </el-row>

    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h4>【１】「ツール」→「測定」→「コンバージョン」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no02/02.png`" alt="" />

        <h4>【２】「すべてのコンバージョン アクションを表示」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no02/05.png`" alt="" />

        <h4>【３】コンバージョン名をコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no02/03.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【４】オフラインCV名に貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no02/04.png`" alt="" />
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <h4>
          【１】Yahoo広告の管理画面から「検索広告」→「ツール」の横にある「▼」→「コンバージョン測定」の順にクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no02/01.png`" alt="" />

        <h4>【２】「コンバージョン測定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no02/02.png`" alt="" />

        <h4>【３】該当の「オフラインコンバージョン名」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no02/03.png`" alt="" />

        <h4>【４】「コンバージョン設定情報の変更」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no02/04.png`" alt="" />

        <h4>
          【５】「コンバージョン名」の欄の文字列を全選択し、コピーします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no05/no02/05.png`" alt="" />

        <p class="kokokara">
          --------------------------------ここからアドタスカル--------------------------------
        </p>

        <h4>【６】オフラインCV名にペーストします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/yahoo-cv/no05/no02/06.png`" alt="" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
