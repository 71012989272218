<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>オフラインコンバージョンの仕様説明</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】アップロードしてから管理画面に反映されるのに１～２時間かかります。</h4>
        <h4>【２】クリックから6時間以内の成果はアップロードできません。</h4>
        <h4>【３】{{tool.clickIdName}}、成果日時が同じなら重複でカウントされません。</h4>
        <p>同じコンバージョンデータをアップロードしても重複でカウントされないので安心してください。</p>
        <h4>【４】成果日時の秒は「00」に固定しています。</h4>
        <p v-if="tool.codeKebab === 'google-cv'">CSVの仕様で、上書き保存すると秒が「00」になります。</p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no15/01.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
