<template>
  <div class="tools02-copy-promotion">
    <el-row>
      <el-col>
        <h2>既存のプログラム情報一覧</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CopyPromotionList :toolId="toolId" :accountId="accountId" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CopyPromotionList from "@/components/Tools02/CopyPromotion/CopyPromotionList.vue";

export default {
  components: {
    CopyPromotionList,
  },
  props: {
    toolId: String,
    accountId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
