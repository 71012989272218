<template>
  <div class="Ctrl-parameter-log">
    <el-row>
      <el-col>
        <h2>{{ toolData.mediaName }} atidログ</h2>
      </el-col>
    </el-row>
    <BridgeDataList :type="'default'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BridgeDataList from "@/components/Tools02/Bridges/BridgeDataList.vue";

export default {
  components: {
    BridgeDataList,
  },
  props: {
    toolId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    }
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
