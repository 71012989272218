<template>
  <div>
    <el-row type="flex">
      <el-col :span="8">現在の登録者数</el-col>
      <el-col>{{ total_count }}人</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="8">自動更新有効者数</el-col>
      <el-col>{{ auto_update_count }}人</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="8">GoogleTools利用設定者数</el-col>
      <el-col>{{ google_tools_active_count }}人</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="8">アカウント登録ボーナス達成率：</el-col>
      <el-col>{{ Math.floor((bonus01 / total_count)*100*100)/100 }}％</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="8">プログラム登録ボーナス達成率：</el-col>
      <el-col>{{ Math.floor((bonus02 / total_count)*100*100)/100 }}％</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="8">手動アップロード成功ボーナス：</el-col>
      <el-col>{{ Math.floor((bonus03 / total_count)*100*100)/100 }}％</el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="8">自動インポート成功ボーナス：</el-col>
      <el-col>{{ Math.floor((bonus04 / total_count)*100*100)/100 }}％</el-col>
    </el-row>
    <el-divider></el-divider>

    <div
      v-for="(val, index) in user_data"
      :key="index"
    >
      <el-row>
        <el-col>==========&nbsp;{{ val.user_name }}&nbsp;&nbsp;(user_id:{{val.user_id}})&nbsp;==========</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="8">会社名：</el-col>
        <el-col>{{ val.company }}</el-col>
      </el-row>
      <el-row type="flex">
        <el-col
          class="alt"
          :span="8"
        >登録日時：</el-col>
        <el-col class="alt">{{ val.create_datetime }}</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="8">残ポイント：</el-col>
        <el-col>{{ val.point.toLocaleString() }}</el-col>
      </el-row>
      <el-row type="flex">
        <el-col
          class="alt"
          :span="8"
        >自動更新設定：</el-col>
        <el-col class="alt">{{ val.auto_update }}</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="8">手動ストップ（1回目）: </el-col>
        <el-col>
          <span v-show="val.manual_stop[0].time!='0000-00-00 00:00:00'">{{ val.manual_stop[0].time }}</span>
          <span v-show="val.manual_stop[0].time=='0000-00-00 00:00:00'">値なし</span>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col
          class="alt"
          :span="8"
        >Googleツール利用設定:</el-col>
        <el-col class="alt">{{ val.google_cv.google_cv_active }}</el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="8">アカウント登録ボーナス:</el-col>
        <el-col>{{ val.google_cv.google_cv_bonus01 }}<span v-show="val.google_cv.google_cv_bonus01=='○' && val.google_cv.google_cv_bonus01_time!='2000年1月1日 00時00分'">{{val.google_cv.google_cv_bonus01_time}}</span></el-col>
      </el-row>
      <el-row type="flex">
        <el-col
          class="alt"
          :span="8"
        >プログラム登録ボーナス:</el-col>
        <el-col class="alt">{{ val.google_cv.google_cv_bonus02 }}<span v-show="val.google_cv.google_cv_bonus02=='○' && val.google_cv.google_cv_bonus02_time!='2000年1月1日 00時00分'">{{val.google_cv.google_cv_bonus02_time}}</span></el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="8">手動アップロード成功ボーナス: </el-col>
        <el-col>{{ val.google_cv.google_cv_bonus03 }}<span v-show="val.google_cv.google_cv_bonus03=='○' && val.google_cv.google_cv_bonus03_time!='2000年1月1日 00時00分'">{{val.google_cv.google_cv_bonus03_time}}</span></el-col>
      </el-row>
      <el-row type="flex">
        <el-col
          class="alt"
          :span="8"
        >自動インポート成功ボーナス: </el-col>
        <el-col class="alt">{{ val.google_cv.google_cv_bonus04 }}<span v-show="val.google_cv.google_cv_bonus04=='○' && val.google_cv.google_cv_bonus04_time!='2000年1月1日 00時00分'">{{val.google_cv.google_cv_bonus04_time}}</span></el-col>
      </el-row>
      <el-row type="flex">
        <el-col
          :span="8"
        >自動更新のタイミング設定:</el-col>
        <el-col>{{ val.google_cv.ap_point }}ポイント</el-col>
      </el-row>
      <el-row type="flex">
        <el-col
          :span="8"
        >【決済状況】</el-col>
        <el-col>&nbsp;</el-col>
      </el-row>
      <div
        v-for="(val2,index) in val.payment"
        :key="index"
      >
        <el-row type="flex">
          <el-col
            :span="8"
          >{{ val2.order_date }}</el-col>
          <el-col>{{ val2.price }}円</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user_data: [],
      total_count: 0,
      auto_update_count: 0,
      google_tools_active_count: 0,
      bonus01: 0,
      bonus02: 0,
      bonus03: 0,
      bonus04: 0,
    };
  },
  created: function () {
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getUsageSummary.php")
      .then((res) => {
        console.log(res);
        this.total_count = res.data.total_count;
        this.user_data = res.data.user_data;

        const auto_update = this.user_data.filter((v) => v.auto_update == "ON");
        this.auto_update_count = auto_update.length;

        const google_tools_active = this.user_data.filter(
          (v) => v.google_cv.google_cv_active == "ON"
        );
        this.google_tools_active_count = google_tools_active.length;

        const bonus01 = this.user_data.filter(
          (v) => v.google_cv.google_cv_bonus01 == "○"
        );
        this.bonus01 = bonus01.length;

        const bonus02 = this.user_data.filter(
          (v) => v.google_cv.google_cv_bonus02 == "○"
        );
        this.bonus02 = bonus02.length;

        const bonus03 = this.user_data.filter(
          (v) => v.google_cv.google_cv_bonus03 == "○"
        );
        this.bonus03 = bonus03.length;

        const bonus04 = this.user_data.filter(
          (v) => v.google_cv.google_cv_bonus04 == "○"
        );
        this.bonus04 = bonus04.length;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style scoped lang="scss">
.alt {
  background-color: #efefef;
}
</style>