<template>
  <div class="tools02-bussiness-accounts-account-list">
    <el-row>
      <el-col>
        <router-link to="bussiness-account-detail/0">
          <el-button type="primary" class="new-add">新規追加</el-button>
        </router-link>
      </el-col>
    </el-row>
    <el-row v-if="userData.bonusPointData[tool.codeCamel].auth === false">
      <el-col>
        <UiBonusAlert
          :toolCode="tool.codeCamel"
          :bonusCode="'auth'"
          boxStyle="fat"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="cover">
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>Yahoo!JAPAN ビジネスID</th>
              <th style="width: 100px"></th>
              <th style="width: 50px"></th>
              <th style="width: 50px"></th>
            </tr>
          </thead>
          <tbody v-if="bussinessAccountList.length > 0">
            <tr
              v-for="bussinessAccountData in bussinessAccountList"
              :key="bussinessAccountData.bussinessAccountId"
            >
              <td>
                {{ bussinessAccountData.bussinessId }}
              </td>
              <td>
                <div style="text-align: center">
                  <el-button
                    v-if="bussinessAccountData.apiAuth === '0'"
                    @click="authorize(bussinessAccountData.bussinessAccountId)"
                    type="primary"
                  >
                    認証
                  </el-button>
                  <el-button
                    v-if="bussinessAccountData.apiAuth === '1'"
                    @click="authorize(bussinessAccountData.bussinessAccountId)"
                    type="success"
                  >
                    連携済み
                  </el-button>
                </div>
              </td>
              <td>
                <router-link
                  :to="`bussiness-account-detail/${bussinessAccountData.bussinessAccountId}`"
                >
                  <el-button type="">編集</el-button>
                </router-link>
              </td>
              <td>
                <el-button
                  @click="
                    delBussinessAccount(
                      bussinessAccountData.bussinessAccountId,
                      bussinessAccountData.bussinessId
                    )
                  "
                  type="danger"
                  >削除</el-button
                >
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBonusAlert from "@/components/Ui/BonusAlert.vue";

export default {
  name: "Tools02BussinessAccountsAccountList",
  components: {
    UiBonusAlert,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "userData"]),
    ...mapGetters("tools02", ["bussinessAccountList"]),
  },
  watch: {},
  created() {
    if (this.$route.query.status === "200") {
      this.$store.dispatch("setUserData");
    } else {
      //no action.
    }
  },
  methods: {
    authorize(bussinessAccountId) {
      this.$axios
        .get(
          `${this.rt}/api/tools02/yahoo-cv/create-token.php?bussiness_account_id=${bussinessAccountId}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            //URL
            let url = `https://biz-oauth.yahoo.co.jp/oauth/v1/authorize`;

            //query
            let query = "";
            query += `?response_type=code`;
            query += `&client_id=${res.data.clientId}`;
            query += `&redirect_uri=${this.rt}/api/tools02/yahoo-cv/get-tokens.php`;
            query += `&scope=yahooads`;
            query += `&state=${res.data.state}`;

            //jump
            location.href = url + query;
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    delBussinessAccount(bussinessAccountId, bussinessId) {
      if (
        confirm(
          `ビジネスID「${bussinessId}」のアカウント情報を削除します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("bussiness_account_id", bussinessAccountId);
        this.$axios
          .post(
            `${this.rt}/api/tools02/yahoo-cv/del-bussiness-account.php`,
            params
          )
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              this.$store.dispatch("tools02/setBussinessAccountList");
              return;
            } else if (res.data.status === 400) {
              alert(res.data.message);
              return;
            } else if (res.data.status === 401) {
              alert(res.data.message);
              this.$router.push("/login");
              return;
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log("キャンセルしました。");
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.new-add {
  margin-top: 20px;
}
.cover{
  overflow: auto;
  .typeA{
    width: 1100px;
  }
}
</style>
