<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（アフィリコードシステム）</h2>
    <el-row>
      <el-col>
        <CommonDescription :systemType="'affilicode'" />
        <h4>
          【１】<a
            href="https://atmarktension.com/"
            target="_blank"
            rel="noopener noreferrer"
            >@tensionの管理画面</a
          >
          にログインし、「レポート管理」内の「掲載レポート」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no18/01.png`" alt="" />

        <h4>【２】レポート対象「広告別」が選択されていることを確認します。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no18/02.png`" alt="" />

        <h4>【３】「掲載レポート」からプログラム名をコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no18/03.png`" alt="" />
        <p class="red"><u>※もし、該当のプログラム名が表示されていない場合は、アフィリリンクをご自身で数回クリックしてください。</u></p>

        <h4>
          【４】プログラム名をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no18/04.png`" alt="" />

        <h4>【５】URL欄からプログラムIDをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no18/05.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>
          【6】アドタスカルのASPプログラム名とプログラムIDに貼り付け、報酬額も記入します。
        </h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no05/no18/06.png`" alt="" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonDescription from "@/components/Manual/Tools02/Parts/CommonDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    CommonDescription,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
