<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>パラメータ引継ぎタグを<br class="sp-only">設置する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            パラメータ引継ぎ設定が{{tool.mediaName}}と成果連携をするキモになります。
          </li>
          <li>
            この設定をすることで、{{tool.mediaName}}の{{tool.clickIdName}}をASPの成果に紐付けることができます。
          </li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】「<router-link :to="`/tools02/${tool.codeKebab}/parameter-manual`">パラメータ引継ぎ設定</router-link
          >」にアクセスしてください。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/parameter.png`" alt="" />
        <h4>【２】サイト作成ツールとアフィリリンクの設置方法によって、設定手順が異なります。ご自身の状況に合った選択肢を選んで、「検索」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no06/no02/01.png`" alt="" />
        <h4>【３】設定手順が表示されるので、上から順番に設定してください。</h4>
        <img :src="`${rt}/img/manual/google-cv/no06/no02/02.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
        ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
