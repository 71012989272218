<template>
  <div class="tools02-domain-detail">
    <el-row>
      <el-col>
        <h2>
          <span>ドメイン </span>
          <span v-if="domainId === '0'">新規登録</span>
          <span v-else>編集</span>
        </h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button @click="toManual()">マニュアル</el-button>
      </el-col>
    </el-row>
    <DomainEditor :domainId="domainId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DomainEditor from "@/components/DomainDetail/DomainEditor.vue";

export default {
  components: {
    DomainEditor,
  },
  props: {
    domainId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  watch: {},
  methods: {
    toManual() {
      window.open('https://adtasukaru.wraptas.site/00b0309c3d5842cc93fae93e0b3cd3b5', '_blank');
    }
  },
};
</script>

<style lang="scss" scoped></style>
