<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>{{ tool.mediaName }}アカウント登録</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            アドタスカルに{{ tool.mediaName }}のアカウントを登録します
          </li>
        </ul>

        <h3>【所要時間】</h3>
        <p v-if="tool.codeKebab === 'google-cv'">５分</p>
        <p v-else-if="tool.codeKebab === 'yahoo-cv'">３分</p>

      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h4>
          【１】「<router-link to="/tools02/google-cv/accounts" target="_blank"
            >Google広告アカウント&amp;プログラム情報</router-link
          >」にアクセスしてください。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/general/accounts.png`" alt="" />
        <h4>【２】「新規追加」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no04/no01/01.png`" alt="" />
        <h4>
          【３】Google広告のアカウントID、アカウント名、種別を入力します。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no04/no01/02.png`" alt="" />
        <p>
          ▼アカウントIDとアカウント名はGoogle広告の管理画面右上に表示されています。
        </p>
        <img :src="`${rt}/img/manual/google-cv/no04/no01/03.png`" alt="" />
        <p class="bold">▼種別について</p>
        <p class="bold">【用語解説】</p>
        <p>サブアカウント：広告運用するためのアカウント</p>
        <p>MCCアカウント：サブアカウントを一元管理するためのアカウント（FB広告のビジネスマネージャーのようなもの）</p>
        <br />
        <p class="bold">【見分け方】</p>
        <p>管理画面右上のアイコンをクリックすると紐づいているアカウントが表示されます。</p>
        <p>アカウントIDの横に「MCC」が表示されていれば「MCCアカウント」</p>
        <p>何も表示されていなければ「サブアカウント」になります。</p>
        <img :src="`${rt}/img/manual/google-cv/no04/no01/04.png`" alt="" style="max-width: 600px;" />
        <p class="bold">【MCCアカウントをお使いの方へ】</p>
        <p>MCCアカウントを使っていてもコンバージョンをサブアカウントで管理している場合は、サブアカウントを登録してください。</p>
        <p>MCCアカウントでサブアカウントのコンバージョンを管理している場合は、MCCアカウントのみで大丈夫です。（サブアカウントの登録は不要）</p>
        <br />
        <p class="bold">【確認方法】</p>
        <p>MCCの管理画面TOP→「設定」→「サブアカウントの設定」の順にクリックします。</p>
        <p>「Google広告のコンバージョンアカウント」列を確認してください。</p>
        <p>・クライアント：サブアカウント</p>
        <p>・このMCC内で共有：MCC</p>
        <p>でコンバージョンを管理しています。</p>
        <img :src="`${rt}/img/manual/google-cv/no04/no01/05.png`" alt="" />
      </el-col>

      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <h4>
          【１】「<router-link to="/tools02/yahoo-cv/accounts"
            >Yahoo!検索広告アカウント&amp;ASPプログラム情報</router-link
          >」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/01.png`" alt="" />
        <h4>【２】「新規追加」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/02.png`" alt="" />

        <h4>【３】以下の画面が表示されます。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/03.png`" alt="" />

        <h4>
          【４】ヤフー広告管理画面から、「アカウントID」と「アカウント名」をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/04.png`" alt="" />

        <h4>
          【５】「アカウントID」と「アカウント名」をアドタスカルにペーストします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/05.png`" alt="" />

        <h4>【６】登録済の「Yahoo!JAPANビジネスID」を選択します。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/06.png`" alt="" />
        <p>
          ※選択できない場合は、先に<router-link to="/manual/yahoo-cv/04/02"
            >「Yahoo!JAPANビジネスID」</router-link
          >を登録してください。
        </p>

        <h4>
          【７】アドタスカルからYahoo!検索広告管理画面上に成果データを自動でアップロードする場合は「自動アップロード」を「ON」にし、アップロード時間を選択します。
        </h4>
        <p>
          例えば、「0」「6」「12」「18」を選択した場合、毎日「0:00」「6:00」「12:00」「18:00」に自動アップロードされます。
        </p>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/07.png`" alt="" />
        <p>
          ※アドタスカルとのAPI連携を行っていない場合は、自動アップロードできないので、まだの場合は、<router-link
            to="/manual/yahoo-cv/04/04"
            >API連携</router-link
          >を行ってください。
        </p>

        <h4>【８】「登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no01/08.png`" alt="" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p v-if="tool.codeKebab === 'google-cv'">これで設定は完了です。</p>
        <p v-else-if="tool.codeKebab === 'yahoo-cv'">
          以上で、「Yahoo!検索広告アカウントの登録」は完了です。
        </p>
        <p>
          次は、「<router-link :to="`/manual/${tool.codeKebab}/05/01`"
            >アドタスカルにプログラム情報を登録する</router-link
          >」に進んでください。
        </p>
        <!-- <p v-if="tool.codeKebab === 'yahoo-cv'" style="margin-top:50px;">
          Yahoo!検索広告とアドタスカルの連携の解除方法は<router-link to="/manual/yahoo-cv/04/03"
            >コチラ</router-link>をご確認ください。
        </p> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  @media screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
</style>
