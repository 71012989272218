<template>
  <div class="manual-tools01-popup02">
    <el-row>
      <el-col>
        <h2>{{tools[tool].name2}}設定マニュアル</h2>
        <h3 :id="`${tools[tool].code2}-01`">{{tools[tool].name2}}を設置するサイト・ページの新規登録</h3>
        <p>「新規設定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-2-01.jpg`" alt="">
        <p>新規設定画面が表示されます。以下について、入力と選択をしてください。</p>
        <table class="typeB">
          <tr>
            <th>ページ名</th>
            <td>{{tools[tool].name2}}を設置するページ名を入力</td>
          </tr>
          <tr>
            <th>設定ドメイン</th>
            <td>{{tools[tool].name2}}を設定するドメインを選択</td>
          </tr>
          <tr>
            <th>設定ページURL</th>
            <td>{{tools[tool].name2}}を設定するページのURLを入力</td>
          </tr>
        </table>
        <p>入力したら、「登録」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-2-02.jpg`" alt="">
        <p>以上で、{{tools[tool].name2}}の新規設定が完了しました。</p>
        <img :src="`${rt}/img/manual/manual2-2-03.jpg`" alt="">
        <h3 :id="`${tools[tool].code2}-02`">表示する{{tools[tool].name2}}の設定</h3>
        <p>「編集」をクリックして、表示する{{tools[tool].name2}}の詳細設定をしていきます。</p>
        <img :src="`${rt}/img/manual/manual2-2-04.jpg`" alt="">
        <h3>{{tools[tool].name2}}を設定するページ情報の入力</h3>
        <p>{{tools[tool].name2}}の新規設定時に入力した情報が反映されているので、必要に応じて編集をしてください。「一言メモ」には、{{tools[tool].name}}を設置したページの属性など、任意でメモを残すことができます。入力や編集した項目は「更新」をクリックして確定させてください。</p>
        <img :src="`${rt}/img/manual/manual2-2-05.jpg`" alt="">
        <h3>{{tools[tool].name}}からの飛び先URLの設定</h3>
        <p>設定する{{tools[tool].name}}からの飛び先URLを指定できます。「編集（クリックして編集画面へ）」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-2-06.jpg`" alt="">
        <p>飛び先URLの編集画面が立ち上がるので、飛び先として設定するURLをhttps://またはhttp://から入力し、「OK」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-2-07.jpg`" alt="">
        <p>飛び先URLの入力欄の横にある「更新」をクリックすると、飛び先URLの設定が完了します。</p>
        <p>続いて、{{tools[tool].name}}のからの飛び先URLのページの開き方について設定します。同一タブで開く設定と、新規タブで開く設定ができるので、どちらかを選択してください。「{{tools[tool].name}}からの飛び先URLメモ」は、飛び先URLについてのメモ書きを任意で残すことができます。</p>
        <img :src="`${rt}/img/manual/manual2-2-08.jpg`" alt="">
        <!-- ↓↓ {{tools[tool].name}}共通部分 ↓↓ -->
        <ManualTools01Popup :tool="tool"/>
        <!-- ↑↑ {{tools[tool].name}}共通部分 ↑↑ -->
        <h3>表示する{{tools[tool].name}}を設定する</h3>
        <p>フォーマット一覧のフォーマットの下部に「○印」があります。表示したいフォーマットの○にチェックを入れ、「登録」をクリックすると、表示フォーマットが設定されます。</p>
        <img :src="`${rt}/img/manual/manual2-2-09.jpg`" alt="">
        <p>選択中のフォーマットは以下のように、色付きで表示されます。</p>
        <img :src="`${rt}/img/manual/manual2-2-10.jpg`" alt="">
        <h3 :id="`${tools[tool].code2}-03`">{{tools[tool].name2}}の設置</h3>
        <p>以下のタグを設置することで、{{tools[tool].name2}}を稼働させることができます。</p>
        <table class="typeB">
          <tr>
            <th>ツール設置タグ</th>
            <td>ツール自体を稼働させるためのタグ</td>
          </tr>
          <tr>
            <th>コンバージョンタグ</th>
            <td>設定する{{tools[tool].name}}と紐づくコンバージョンを設定することができます（当機能を利用する場合のみ設定が必要）</td>
          </tr>
        </table>
        <p>{{tools[tool].name2}}のツール設置タグは、以下の画面から取得することができます。「タグ表示」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-2-11.jpg`" alt="">
        <p>ツール設置タグが表示されるので、タグをコピーして、{{tools[tool].name}}を登場させたい位置に以下のタグを設置してください。</p>
        <img :src="`${rt}/img/manual/manual2-2-12.jpg`" alt="">
        <p>ツール設置タグは、{{tools[tool].name2}}の編集画面からも取得ができます。編集画面の最下部にツール設置タグがあるので、こちらをコピーして{{tools[tool].name}}を登場させたい位置に以下のタグを設置してください。</p>
        <img :src="`${rt}/img/manual/manual2-2-13.jpg`" alt="">
        <p>コンバージョンタグは、共通マニュアルとして別途用意していますので、<router-link v-scroll-to="'#conversion'" to="">コチラ</router-link>を参照してください。</p>
        <p><router-link to="">{{tools[tool].name2}}に関するよくある質問はコチラ</router-link></p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ManualTools01Popup from '@/components/Manual/Tools01/Popup.vue'

export default {
  name: 'ManualTools01Popup02',
  components: {
    ManualTools01Popup
  },
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
