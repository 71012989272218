<template>
  <div class="sidebar">
    <nav :class="{ none: login == 0 || openSidebar === false }">
      <div :class="{ hamburger: type === 'hamburger' }">
        <div class="sidebar-body">
          <el-menu
            :default-active="active"
            class="el-menu-vertical-demo"
            unique-opened
          >
            <el-menu-item index="/" @click="move('/')">
              <i class="el-icon-s-home"></i>
              <span>管理画面トップ</span>
            </el-menu-item>

            <!-- ------------------------- ↓↓ ツール一覧 ↓↓ ------------------------- -->
            <el-submenu
              v-for="(toolData, index) in cartIncludedToolListInTopPageOrder"
              :key="index"
              :index="String(index)"
            >
              <template slot="title">
                <i class="el-icon-shopping-bag-2"></i>
                <span>{{ toolData.name }}</span>
              </template>
              <el-menu-item
                :index="`/tools02/${kebabCode(toolData.code)}`"
                @click="move(`/tools02/${kebabCode(toolData.code)}`)"
                >トップ</el-menu-item
              >
              <el-menu-item
                v-if="toolData.toolId === '10'"
                :index="`/tools02/${kebabCode(
                  toolData.code
                )}/bussiness-accounts`"
                @click="
                  move(
                    `/tools02/${kebabCode(toolData.code)}/bussiness-accounts`
                  )
                "
                >Yahoo!JAPAN ビジネスID 一覧</el-menu-item
              >
              <el-submenu
                v-if="
                  toolData.toolId === '7' ||
                  toolData.toolId === '10' ||
                  toolData.toolId === '12'
                "
                :index="`/tools02/${kebabCode(toolData.code)}/accounts`"
              >
                <template slot="title">
                  <span v-if="toolData.toolId === '12'">マネージャー</span>
                  <span v-else>{{ toolData.mediaName }}</span
                  >アカウント情報
                </template>
                <el-menu-item
                  v-for="account in accounts(toolData.toolId)"
                  :key="account.accountId"
                  :index="`/tools02/${kebabCode(
                    toolData.code
                  )}/account-detail/${account.accountId}`"
                  @click="
                    move(
                      `/tools02/${kebabCode(toolData.code)}/account-detail/${
                        account.accountId
                      }`
                    )
                  "
                  >{{ account.accountName | limitString(20) }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-if="toolData.toolId === '15'"
                :index="`/tools02/${kebabCode(toolData.code)}/accounts`"
                @click="move(`/tools02/${kebabCode(toolData.code)}/accounts`)"
                >{{ toolData.mediaName }} 広告アカウント一覧</el-menu-item
              >
              <template
                v-if="
                  toolData.toolId === '8' ||
                  toolData.toolId === '11' ||
                  toolData.toolId === '14' ||
                  toolData.toolId === '15'
                "
              >
                <el-menu-item
                  v-if="
                    toolData.toolId === '8' ||
                    toolData.toolId === '11' ||
                    toolData.toolId === '14'
                  "
                  :index="`/tools02/${kebabCode(toolData.code)}/tags`"
                  @click="move(`/tools02/${kebabCode(toolData.code)}/tags`)"
                >
                  <span
                    v-if="toolData.toolId === '8' || toolData.toolId === '11'"
                    >ピクセル</span
                  >
                  <span v-if="toolData.toolId === '14'">LINE Tag</span>
                  登録</el-menu-item
                >
                <el-menu-item
                  v-if="toolData.toolId === '8'"
                  :index="`/tools02/${kebabCode(toolData.code)}/units`"
                  @click="move(`/tools02/${kebabCode(toolData.code)}/units`)"
                  >ユニット登録</el-menu-item
                >
                <el-menu-item
                  :index="`/tools02/${kebabCode(toolData.code)}/promotions`"
                  @click="
                    move(`/tools02/${kebabCode(toolData.code)}/promotions`)
                  "
                  >プログラム登録</el-menu-item
                >
              </template>
              <el-submenu
                v-if="
                  toolData.toolId === '7' ||
                  toolData.toolId === '8' ||
                  toolData.toolId === '10' ||
                  toolData.toolId === '11' ||
                  toolData.toolId === '12' ||
                  toolData.toolId === '14' ||
                  toolData.toolId === '15' ||
                  toolData.toolId === '16'
                "
                :index="`/tools02/${kebabCode(toolData.code)}/parameters`"
              >
                <template slot="title">
                  <span>パラメータ</span>
                </template>
                <el-menu-item
                  v-if="toolData.toolId !== '16'"
                  :index="`/tools02/${kebabCode(
                    toolData.code
                  )}/parameter-manual`"
                  @click="
                    move(
                      `/tools02/${kebabCode(toolData.code)}/parameter-manual`
                    )
                  "
                  >引き継ぎ設定</el-menu-item
                >
                <el-menu-item
                  v-if="toolData.toolId !== '16'"
                  :index="`/tools02/${kebabCode(
                    toolData.code
                  )}/parameter-check`"
                  @click="
                    move(`/tools02/${kebabCode(toolData.code)}/parameter-check`)
                  "
                  >チェック</el-menu-item
                >
                <el-menu-item
                  :index="`/tools02/${kebabCode(toolData.code)}/parameter-log`"
                  @click="
                    move(`/tools02/${kebabCode(toolData.code)}/parameter-log`)
                  "
                  >ログ</el-menu-item
                >
              </el-submenu>
              <el-submenu
                v-if="
                  toolData.toolId === '7' ||
                  toolData.toolId === '8' ||
                  toolData.toolId === '10' ||
                  toolData.toolId === '11' ||
                  toolData.toolId === '12' ||
                  toolData.toolId === '14' ||
                  toolData.toolId === '15'
                "
                :index="`/tools02/${kebabCode(toolData.code)}/mission`"
              >
                <template slot="title">
                  <span>手動コース</span>
                </template>
                <el-menu-item
                  :index="`/tools02/${kebabCode(toolData.code)}/report`"
                  @click="move(`/tools02/${kebabCode(toolData.code)}/report`)"
                  >ASP成果インポート・エクスポート</el-menu-item
                >
              </el-submenu>
              <el-submenu
                :index="`/tools02/${kebabCode(toolData.code)}/automatic`"
              >
                <template slot="title">
                  <span>自動コース</span>
                </template>
                <el-menu-item
                  :index="`/tools02/${kebabCode(toolData.code)}/point-back`"
                  @click="
                    move(`/tools02/${kebabCode(toolData.code)}/point-back`)
                  "
                >
                  <span v-if="toolData.toolId === '13'"
                    >カート・計測システム連携設定</span
                  >
                  <span>ASP自動連携設定</span>
                </el-menu-item>
                <el-menu-item
                  :index="`/tools02/${kebabCode(
                    toolData.code
                  )}/auto-import-log`"
                  @click="
                    move(`/tools02/${kebabCode(toolData.code)}/auto-import-log`)
                  "
                  >自動登録履歴</el-menu-item
                >
              </el-submenu>
              <template
                v-if="
                  toolData.toolId === '8' ||
                  toolData.toolId === '11' ||
                  toolData.toolId === '14' ||
                  toolData.toolId === '15' ||
                  toolData.toolId === '16'
                "
              >
                <el-menu-item
                  :index="`/tools02/${kebabCode(toolData.code)}/bridges`"
                  @click="move(`/tools02/${kebabCode(toolData.code)}/bridges`)"
                  >atidログ</el-menu-item
                >
                <el-menu-item
                  :index="`/tools02/${kebabCode(toolData.code)}/api-histories`"
                  @click="
                    move(`/tools02/${kebabCode(toolData.code)}/api-histories`)
                  "
                  >API送信履歴</el-menu-item
                >
              </template>
              <el-submenu
                :index="`/tools02/${kebabCode(toolData.code)}/manual`"
              >
                <template slot="title">
                  <span>マニュアル</span>
                </template>
                <a
                  :href="toolData.manualData.initialSettingManualUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none; color: #303133"
                >
                  <el-menu-item
                    :index="`/tools02/${kebabCode(
                      toolData.code
                    )}/initial-setting-manual`"
                    >初期設定チュートリアル</el-menu-item
                  >
                </a>
                <a
                  v-if="toolData.toolId === '13'"
                  href="https://adtasukaru.wraptas.site/0cb303ae82d14b26998ce68e4183dfb7"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none; color: #303133"
                >
                  <el-menu-item
                    :index="`/tools02/${kebabCode(toolData.code)}/usage-guide`"
                    >利用設定・解約・休止について</el-menu-item
                  >
                </a>
                <el-menu-item
                  v-else
                  :index="`/tools02/${kebabCode(toolData.code)}/usage-guide`"
                  @click="
                    move(`/tools02/${kebabCode(toolData.code)}/usage-guide`)
                  "
                  >利用設定・利用ステータスなど
                </el-menu-item>
                <a
                  :href="toolData.manualData.manualHomeUrl"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="text-decoration: none; color: #303133"
                >
                  <el-menu-item
                    :index="`/tools02/${kebabCode(toolData.code)}/manual-home`"
                    >マニュアル</el-menu-item
                  >
                </a>
                <el-menu-item
                  v-if="toolData.toolId !== '13'"
                  :index="`/manual/${kebabCode(toolData.code)}/02/01`"
                  @click="move(`/manual/${kebabCode(toolData.code)}/02/01`)"
                  >ツール説明</el-menu-item
                >
              </el-submenu>
            </el-submenu>
            <!-- ------------------------- ↑↑ ツール一覧 ↑↑ ------------------------- -->

            <a
              href="https://adtasukaru.wraptas.site/7452f432f2e54ae8a82eab9f67aa41d9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <el-menu-item index="asp-list">
                <i class="el-icon-shopping-cart-2"></i>
                <span>ASP別一覧表</span>
              </el-menu-item>
            </a>
            <a
              href="https://adtasukaru.wraptas.site/0fc95495a1a14c7d96e69756674d3864"
              target="_blank"
              rel="noopener noreferrer"
            >
              <el-menu-item index="faq">
                <i class="el-icon-shopping-cart-2"></i>
                <span>FAQ</span>
              </el-menu-item>
            </a>
            <el-submenu index="101">
              <template slot="title">
                <i class="el-icon-user"></i>
                <span>アカウント</span>
              </template>
              <el-menu-item index="/account" @click="move('/account')"
                >トップ</el-menu-item
              >
              <el-menu-item index="/info" @click="move('/info')"
                >会員情報の編集</el-menu-item
              >
              <el-menu-item index="/history" @click="move('/history')"
                >購入・ポイント履歴</el-menu-item
              >
              <el-menu-item index="/change" @click="move('/change-password')"
                >パスワード変更</el-menu-item
              >
              <el-menu-item index="/privileges" @click="move('/privileges')"
                >特典</el-menu-item
              >
              <el-menu-item index="/affiliate" @click="move('/affiliate')"
                >アフィリエイト</el-menu-item
              >
            </el-submenu>
            <el-menu-item index="/payment" @click="move('/payment')">
              <i class="el-icon-shopping-cart-2"></i>
              <span>ポイント購入・プラン変更</span>
            </el-menu-item>
            <template v-if="type === `hamburger`">
              <el-menu-item
                @click="move('/account')"
                v-if="login == 0 && $route.path != '/login'"
              >
                <i class="el-icon-s-fold"></i>
                <span>ログイン</span>
              </el-menu-item>
              <el-menu-item @click="logout" v-if="login == 1">
                <i class="el-icon-s-unfold"></i>
                <span>ログアウト</span>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideBar",
  props: {
    path: String,
    type: String,
  },
  data() {
    return {
      active: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tools", "login", "openSidebar"]),
    ...mapGetters("tools02", [
      "bussinessAccountList",
      "cartIncludedToolListInTopPageOrder",
    ]),
    accounts() {
      return function (toolId) {
        toolId = String(toolId);
        return this.$store.getters["tools02/accounts"](toolId);
      };
    },
  },
  watch: {
    path(newValue) {
      if (newValue.includes("/tools01/popup01")) {
        this.active = "/tools01/popup01";
      } else if (newValue.includes("/tools01/popup02")) {
        this.active = "/tools01/popup02";
      } else if (newValue.includes("/tools01/overlay01")) {
        this.active = "/tools01/overlay01";
      } else if (newValue.includes("/tools01/overlay02")) {
        this.active = "/tools01/overlay02";
      } else if (newValue.includes("/tools01/gadget")) {
        this.active = "/tools01/gadget";
      } else {
        this.active = newValue;
      }
    },
  },
  methods: {
    move(path) {
      this.$router.push(path).catch((err) => {
        console.log(err);
      });
      this.$emit("switchDrawer");
    },
    switchOpenSidebar() {
      this.$store.dispatch("switchOpenSidebar");
    },
    logout() {
      this.$axios
        .get(`${this.rt}/api/logout.php`)
        .then((res) => {
          console.log(res);
          this.$store.dispatch("setLogin", 0);
          this.$router.push("/logout");
          this.$emit("switchDrawer");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    kebabCode(snakeCode) {
      const kebabCode = snakeCode.replace(/_/g, "-");
      return kebabCode;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-body,
ul.el-menu-vertical-demo {
  position: fixed;
  width: 295px;
  height: 100%;
  min-height: calc(100vh - 70px - 70px);
  overflow: hidden;
  overflow-y: scroll;
}
.el-menu {
  a {
    text-decoration: none;
    color: #303133;
  }
}
:deep(.el-submenu) {
  a {
    text-decoration: none;
  }
}
.hamburger {
  overscroll-behavior-y: contain;
  height: 50%;
  :deep(.sidebar-body),
  ul.el-menu-vertical-demo {
    border-right: none;
    position: static;
    white-space: nowrap;
    width: 295px;
  }
}
.el-menu-item a {
  color: inherit;
  text-decoration: none;
}
</style>
