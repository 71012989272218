<template>
    <div class="import">
        <div class="log-table-cover">
            <table class="total_tbl">
                <thead>
                    <tr>
                    <th>媒体</th>
                    <th style="width: 10%">ASP</th>
                    <th style="width: 120px">CV時間</th>
                    <th style="width: 15%">プログラム名</th>
                    <th style="width: 20%;">パラメータ</th>
                    <th style="width: 15%;">プログラムID</th>
                    <th style="width: 10%;">報酬額</th>
                    <th style="width: 10%;">実質<br />報酬額<br />（税抜）
                        <el-tooltip
                        class="item"
                        effect="dark"
                        content=""
                        placement="top"
                    >
                        <i class="el-icon-question"></i>
                    </el-tooltip></th>
                    <th style="width: 20%">インポート状況</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>
                        <div></div>
                    </td>
                    <td>
                        <div>{{ aspData.aspName }}</div>
                    </td>
                    <td>
                        <div>
                            <span v-if="conversionTime === undefined">2021/02/22 12:44:37</span>
                            <span v-else>{{ conversionTime }}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span v-if="promotionName === undefined">-</span>
                            <span v-else>{{ promotionName }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="parameter">
                            <span v-if="parameter === undefined">{{ tool.clickIdName }}_xxx</span>
                            <span v-else>{{ parameter }}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span v-if="aspPromotionName === undefined"></span>
                            <span v-else>{{ aspPromotionName }}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span v-if="conversionValue === undefined">0</span>
                            <span v-else>{{ conversionValue | localeNum }}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                            <span v-if="conversionValueReal === undefined">0</span>
                            <span v-else>{{ conversionValueReal | localeNum }}</span>
                        </div>
                    </td>
                    <td>
                        <div>
                        <div>
                            <el-button type="danger" round class="danger-icon"
                                >!</el-button
                                >
                            <span v-if="errorDescription === undefined" style="color: red">CLICK IDが取得不可</span>
                            <span style="color: red">{{ errorDescription }}</span>
                            
                        </div>
                        </div>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AutoImportLog",
  props: {
    aspId: String,
    conversionTime: String,
    promotionName: String,
    parameter: String,
    aspPromotionName: String,
    conversionValue: String,
    conversionValueReal: String,
    errorDescription: String,
  },
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    },
    aspData() {
      return this.$store.getters["aspData"](this.aspId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.import{
    width: 95%;
    margin-left: 40px;
    padding: 10px;
    font-size: 0.8em;
    border: 3px solid gray;
}
</style>