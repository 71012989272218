<template>
  <div class="history">
    <el-row>
      <el-col>
        <h2>購入・ポイント履歴</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <OrderAndPointList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OrderAndPointList from "@/components/History/OrderAndPointList.vue";

export default {
  components: {
    OrderAndPointList,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped lang="scss">
</style>