<template>
  <div class="tools02">
    <router-view :toolId="toolId" />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    toolId: String,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>