<template>
  <div class="ui-bread-crumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item
        v-for="(breadCrumbData, index) in breadCrumbList"
        :key="index"
      >
        <router-link :to="breadCrumbData.path">{{
          breadCrumbData.name
        }}</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UiBreadCrumb",
  props: {},
  data() {
    return {
      breadCrumbList: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    "$route.path"(newValue) {
      this.getBreadCrumbList(newValue);
    },
  },
  created() {
    this.getBreadCrumbList(this.$route.path);
  },
  methods: {
    getBreadCrumbList(newValue) {
      this.$axios
        .get(`${this.rt}/api/createBreadCrumb.php?path=${newValue}`)
        .then((res) => {
          console.log(res);
          this.breadCrumbList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-bread-crumb {
  margin-bottom: 10px;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
}
</style>
