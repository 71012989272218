<template>
  <div class="tools02-pixels">
    <el-row>
      <el-col>
        <h2>
          <span
            v-if="
              toolData.codeKebab === 'facebook-cv' || toolData.codeKebab === 'tiktok-cv'
            "
            >ピクセル</span
          >
          <span v-else-if="toolData.codeKebab === 'line-cv'">LINE Tag</span>
          <span v-else-if="toolData.codeKebab === 'yda-cv'">コンバージョン</span>
          <span v-else>タグ</span>一覧
        </h2>
      </el-col>
    </el-row>
    <TagList :toolId="toolId" :accountId="accountId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TagList from "@/components/Tools02/Tags/List.vue";

export default {
  components: {
    TagList,
  },
  props: {
    toolId: String,
    accountId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {},
  created() {
    if (this.toolData.codeKebab === "line-cv") {
      this.setTitle("広告成果連携ツール LINE Tag一覧");
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
