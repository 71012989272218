<template>
  <div class="login">
    <el-row type="flex" justify="center">
      <h1 v-if="loginType === 'normal'">ログイン</h1>
      <h1 v-else-if="loginType === 'ctrl'">ログイン</h1>
      <h1 v-else-if="loginType === 'secret'">シークレットログイン</h1>
    </el-row>
    <div class="login-cover">
      <div class="login">
        <el-input
          v-if="loginType === 'normal' || loginType === 'ctrl'"
          type="email"
          autocomplete="on"
          placeholder="登録メールアドレス"
          v-model="email"
          style="font-size: 16px;"
        >
          <template slot="prepend">ログインID</template>
        </el-input>
        <el-input
          v-if="loginType === 'secret'"
          type="text"
          autocomplete="on"
          placeholder="123"
          v-model="userId"
          style="font-size: 16px;"
        >
          <template slot="prepend">ユーザーID</template>
        </el-input>
      </div>
    </div>
    <div class="login-cover" style="margin-bottom: 5px">
      <div class="login">
        <el-input
          type="password"
          autocomplete="on"
          placeholder="123456789abcdefg"
          v-model="password"
          style="font-size: 16px;"
        >
          <template slot="prepend">パスワード</template>
        </el-input>
      </div>
    </div>
    <el-row type="flex" justify="center" style="margin-top: 10px">
      <el-col :span="6" style="text-align: center">
        <el-checkbox v-model="eternalLogin">ログイン状態を維持する</el-checkbox>
      </el-col>
    </el-row>
    <el-row v-show="loginType === 'normal'" type="flex" justify="center">
      <div class="login">
        <span class="desc">
          パスワードをお忘れの場合は
          <router-link to="/reissue-password/" class="here">コチラ</router-link
          >。
        </span>
      </div>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 30px">
      <el-col :span="6" style="text-align: center">
        <el-button @click="login" type="primary">ログイン</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Login",
  props: {
    loginType: String,
  },
  components: {},
  data() {
    return {
      userId: "",
      email: "",
      password: "",
      eternalLogin: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
  },
  created() {
    this.$store.dispatch("toggleNewsOpen", 1);
  },
  methods: {
    login() {
      let params = new URLSearchParams();
      params.append("login_type", this.loginType);
      params.append("user_id", this.userId);
      params.append("email", this.email);
      params.append("password", this.password);
      params.append("eternal_login", this.eternalLogin);
      this.$axios
        .post(`${this.rt}/api/login.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$store.dispatch("setVariousValuesForAllUsers");
            this.$store.dispatch("setVariousValuesForLoggedInUser", res.data);

            //遷移
            if (this.loginType === "normal" || this.loginType === "secret") {
              this.$router.push(res.data.redirectPath);
            } else if (this.loginType === "ctrl") {
              this.$store.dispatch("ctrl/setVariousData");
              this.$router.push("/ctrl");
            }
          } else if (
            res.data.status === 400 ||
            res.data.status === 401 ||
            res.data.status === 423
          ) {
            alert(res.data.message);
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.desc {
  color: #999;
}
a.here {
  text-decoration: underline;
}
.login-cover {
  display: flex;
  justify-content: center;
  // width: 90%;
  .login {
    width: 90%;
    max-width: 800px;
    margin: 5px 0;
    @media screen and (max-width: 767px) {
      max-width: initial;
      width: 100%;
    }
    :deep(.el-input-group__prepend) {
      width: 120px;
      text-align: center;
      @media screen and (max-width: 767px) {
        font-size: 11px;
        padding: 0 3px;
        width: 75px;
      }
    }
  }
}
</style>
