<template>
  <div class="manual-tools01-wordpress01">
    <el-row>
      <el-col>
        <h2 id="wordpress01">WordPressで個別ページにタグを設置する方法</h2>
        <p>対象とするサイトのWordPressの管理画面を開きます。</p>
        <img :src="`${rt}/img/manual/manual4-2-01.jpg`" alt="">
        <p>左サイドメニューの「外観」→「テーマの編集」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual4-2-03.jpg`" alt="">
        <p>右サイドバーの「テーマフッター」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual4-2-04.jpg`" alt="">
        <p>アドタスカルのタグを設置したい位置（通常は「/body」直前）に次のコードを設置します。（※ファイル編集前には必ずバックアップを取ってください。）</p>
        <p>なお、「”custom_fotter”」の部分はあくまで例であり、任意の名前でも構いません。後ほど使用します。</p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3 }"
          :value="code"
          onclick="this.select();"
        />
        <p>貼り付けが完了しだい、「ファイルを更新」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual4-2-05.jpg`" alt="">
        <p>続いて、アドタスカルのタグを設置したいページ編集画面へ移動します。</p>
        <img :src="`${rt}/img/manual/manual4-2-06.jpg`" alt="">
        <p>画面の右上にある「表示オプション」をクリックして開き、「カスタムフィールド」にチェックが入っていることを確認します。</p>
        <img :src="`${rt}/img/manual/manual4-2-07.jpg`" alt="">
        <p>そのままページ下部へ移動すると、「カスタムフィールド」という欄があるので、「新規追加」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual4-2-08.jpg`" alt="">
        <p>名前に「custom_footer（任意の名前にした場合はその名前）」と入力し、値にコピーしたタグを貼り付けます。</p>
        <p>入力が終わったら、「カスタムフィールドを追加」をクリックしてください。</p>
        <img :src="`${rt}/img/manual/manual4-2-09.jpg`" alt="">
        <p>カスタムフィールドが追加されたことを確認し、「更新」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual4-2-10.jpg`" alt="">
        <p>ページを実際に表示し、ツールが表示されることを確認してください。</p>
        <img :src="`${rt}/img/manual/manual4-2-11.jpg`" alt="">
        <p><router-link v-scroll-to="`#wordpress02`" to="">WordPressで特定ページでタグ設置を除外する方法</router-link></p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01Wordpress01',
  props: {
    tool: String
  },
  data() {
    return {
      code: '<?php\n$custom_field_foot = get_post_meta( $post->ID, "custom_footer", true );\nif ( trim( $custom_field_foot ) != "" ) {\n  echo $custom_field_foot;\n}\n?>'
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
