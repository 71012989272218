<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（CATS）</h2>
    <el-row>
      <el-col>
        <PromotionIdDescription :aspId="'13'"/>
        <h4>
          【１】<a
            href="https://pe-k.site/front/login/"
            target="_blank"
            rel="noopener noreferrer"
            >CATSの管理画面</a
          >
          にログインしたら、「レポート集計」→「広告・媒体別」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no17/01.png`" alt="" />

        <h4>
          【２】すでに提携しているプログラムが表示されるので、登録したいプログラム名探して、「広告名」をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no17/02.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【３】アドタスカルのASPプログラム名に貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no17/03.png`" alt="" />

        <h4>【４】プログラムIDはご自身で決めます。</h4>
        <p>◎決め方</p>
        <ul>
          <li>半角英数字</li>
          <li>同じASP内で実施している他の案件と被らないようにする</li>
        </ul>
        <h4 id="from-manual3">
          【５】アフィリエイトリンクの後ろに「&amp;param2=[プログラムID]」を追記する
        </h4>
        <h5>▼CATSのASPから発行されたアフィリリンク</h5>
        <p>
          https://ac.pe-k.site/cl/08IS5ffI924f8860/?bid=fB1JB6fb6cae2e58&amp;
        </p>
        <p>↓</p>
        <h5>
          ▼【３】で決めたプログラムIDを「&amp;param2=プログラムID」という形で付ける
        </h5>
        <p>https://ac.pe-k.site/cl/08IS5ffI924f8860/?bid=fB1JB6fb6cae2e58&amp;<span class="green"
            >&amp;param2=alomakuro</span
          >
        </p>

        <p>これを記事LPや中間ページに設置してください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromotionIdDescription from "@/components/Manual/Tools02/No05/Parts/PromotionIdDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PromotionIdDescription
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
    created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
