var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"format-display",class:{ mini: this.size == 'mini' }},[(
      _vm.format.toolId === '1' ||
      _vm.format.tool_id === '1' ||
      _vm.format.toolId === '6' ||
      _vm.format.tool_id === '6'
    )?_c('div',[(
        _vm.format.typeId === '1' || _vm.format.type_id === '1' || _vm.format.type === '1'
      )?_c('Format0101',{attrs:{"format":_vm.format}}):(
        _vm.format.typeId === '2' || _vm.format.type_id === '2' || _vm.format.type === '2'
      )?_c('Format0102',{attrs:{"format":_vm.format}}):(
        _vm.format.typeId === '3' || _vm.format.type_id === '3' || _vm.format.type === '3'
      )?_c('Format0103',{attrs:{"format":_vm.format}}):(
        _vm.format.typeId === '4' ||
        _vm.format.type_id === '4' ||
        _vm.format.type === '4' ||
        _vm.format.typeId === '5' ||
        _vm.format.type_id === '5' ||
        _vm.format.type === '5'
      )?_c('Format0104',{attrs:{"format":_vm.format}}):_vm._e()],1):(_vm.format.toolId === '2' || _vm.format.tool_id === '2')?_c('div',[(
        _vm.format.typeId === '1' || _vm.format.type_id === '1' || _vm.format.type === '1'
      )?_c('Format0201',{attrs:{"format":_vm.format}}):(
        _vm.format.typeId === '2' || _vm.format.type_id === '2' || _vm.format.type === '2'
      )?_c('Format0202',{attrs:{"format":_vm.format}}):_vm._e()],1):(_vm.format.toolId === '3' || _vm.format.tool_id === '3')?_c('div',[_c('Format0301',{attrs:{"format":_vm.format}})],1):(_vm.format.toolId === '4' || _vm.format.tool_id === '4')?_c('div',[(
        _vm.format.typeId === '1' || _vm.format.type_id === '1' || _vm.format.type === '1'
      )?_c('Format0401',{attrs:{"format":_vm.format}}):(
        _vm.format.typeId === '2' || _vm.format.type_id === '2' || _vm.format.type === '2'
      )?_c('Format0401',{attrs:{"format":_vm.format}}):(
        _vm.format.typeId === '3' || _vm.format.type_id === '3' || _vm.format.type === '3'
      )?_c('Format0401',{attrs:{"format":_vm.format}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }