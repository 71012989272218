<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>Yahoo!JAPANビジネスIDの登録</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
               <h3>【目的】</h3>

        <ul>
          <li>アドタスカルにYahoo!JAPANビジネスIDを登録します。</li>
          <li>登録したYahoo!JAPANビジネスIDとアドタスカルをAPI連携を行います。</li>
          <li>API連携をすることで、Yahoo!検索広告管理画面に自動でCVデータを送信することができるようになります。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>


    <el-row>
      <el-col>
           <h4>【１】「「<router-link to="/tools02/yahoo-cv/bussiness-accounts"
            >Yahoo!JAPANビジネスID</router-link>」をクリックします。</h4>
      <img :src="`${rt}/img/manual/yahoo-cv/no04/no02/01.png`" alt="" />

      <h4>【２】「新規追加」をクリックします。</h4>
      <img :src="`${rt}/img/manual/yahoo-cv/no04/no02/02.png`" alt="" />

<h4>【３】以下の画面が表示されます。</h4>
      <img :src="`${rt}/img/manual/yahoo-cv/no04/no02/03.png`" alt="" />

      <h4>【４】Yahoo広告の管理画面にて、「Yahoo!JAPANビジネスID」をコピーします。</h4>
      <img :src="`${rt}/img/manual/yahoo-cv/no04/no02/04.png`" alt="" />
      
      <h4>【５】コピーした「Yahoo!JAPANビジネスID」をアドタスカル管理画面にペーストし、登録をクリックします。</h4>
      <p>ペースト時に、不要なスペースなどが入っていないか確認ください。</p>
      <img :src="`${rt}/img/manual/yahoo-cv/no04/no02/05.png`" alt="" />


      </el-col>
    </el-row>

    <el-row>
      <el-col>
          <p>以上で、「Yahoo!JAPANビジネスID」の登録は完了です。</p>
        <p>
          次は、「<router-link :to="`/manual/${tool.codeKebab}/04/04`"
            >アドタスカルとYahoo!JAPANビジネスIDを連携する</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualTools02Contents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
