<template> 
  <el-button v-bind:type="type">{{msg}}</el-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    msg: String,
    type: String,
  }
}
</script>

<style lang="scss" scoped>
// 
</style>
