<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>MCCのサブアカウントの設定で「このMCC内で共有」が選択できないときの対処法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>質問</h3>
        <p>MCCのサブアカウントの設定で「このMCC内で共有」が選択できません。</p>
        <img :src="`${rt}/img/manual/google-cv/no09/no06/01.png`" alt="" />
        <h3>原因</h3>
        <p>MCCアカウントを作成して、１つもコンバージョンが無いと発生するエラーになります。</p>
        <h3>解決策</h3>
        <p>なので、１つコンバージョンを作成しましょう。</p>
        <p>このとき作成するコンバージョンは、<span class="red2">「ウェブサイト」</span>にしてください。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】「ツール」→「測定」→「コンバージョン」の順にクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no06/02.png`" alt="" />
        <h4>【２】「＋」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no06/03.png`" alt="" />
        <h4>【３】「ウェブサイト」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no06/04.png`" alt="" />
        <h4>【４】適当な情報を入力して「作成して続行」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no06/05.png`" alt="" />
        <img :src="`${rt}/img/manual/google-cv/no09/no06/06.png`" alt="" />
        <h4>【５】もう一度、サブアカウントの設定で「このMCC内で共有」を試してみてください。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no06/07.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
