<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ユニットとは？</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>垢BAN対策で複数のピクセルをユニットに登録しておくことで、すぐに復活させることができます。</li>
          <li>ユニットに複数のピクセルを登録することで、１つの成果を複数のピクセルにAPI送信することができます。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>ユニットとは？</h3>
        <p>ユニットとは、ピクセルをまとめる箱をイメージしてください。</p>
        <p>1ユニットに複数のピクセルを設置できます。</p>
        <div class="unit-table">
          <table id="error_tool_tag_empty" class="error f-left">
            <tr><th>ユニットA</th></tr>
            <tr><td></td></tr>
            <tr><td>ピクセル1</td></tr>
            <tr><td>ピクセル2</td></tr>
            <tr><td>ピクセル3</td></tr>
          </table>
          <div></div>
          <table id="error_tool_tag_empty" class="error">
            <tr><th>ユニットB</th></tr>
            <tr><td></td></tr>
            <tr><td>ピクセル4</td></tr>
            <tr><td>ピクセル5</td></tr>
            <tr><td>ピクセル1</td></tr>
          </table>
        </div>
        <h3>【想定ケース１】</h3>
        <ul>
          <li>FB広告はアカBAN（凍結）が多いためアカBANするとそのビジマネのピクセルが使えなくなる</li>
          <li>CVデータを貯め直さないといけなくなる</li>
          <li>事前に予備のピクセルを用意しておくことで、アカBANになってもすぐに復活できる</li>
        </ul>
        <p>ユニットについて理解したら、<router-link :to="`/manual/${tool.codeKebab}/04/06`">ユニット登録</router-link>へ進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.error {
  width: 100%;
  border-collapse: collapse;
  margin-left: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px){
    margin-left: 0!important;
  }
  th,
  td {
    border: 1px solid;
    padding: 15px;
    text-align: center;
  }
  th {
    background-color: #e5e5e5;
  }
}

.unit-table{
  display: grid;
  grid-template-columns: 45% 10% 45%;
}

</style>
