<template>
  <div class="squadbeyond-conversion-api-history-list">
    <el-table :data="conversionApiHistoryList" border>
      <el-table-column
        fixed
        label="API送信日時"
        min-width="180px"
        prop="sendDatetime"
      >
      </el-table-column>
      <el-table-column label="媒体">
        <template slot-scope="scope">
          <span>{{
            $store.getters.toolData(scope.row.reportData.toolId).alphaCode
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ASP">
        <template slot-scope="scope">
          <span v-if="scope.row.reportData.aspId !== '0'">{{
            $store.getters.aspData(scope.row.reportData.aspId).aspName
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="CV日時" width="200px">
        <template slot-scope="scope">
          <span>{{ scope.row.reportData.formattedConversionTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="プログラム名">
        <template slot-scope="scope">
          <span v-if="scope.row.reportData.tools02PromotionData !== null">{{
            scope.row.reportData.tools02PromotionData.promotionName
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="squadbeyond_uid" width="380px">
        <template slot-scope="scope">
          <el-input readonly v-model="scope.row.squadbeyondUid">
            <el-button
              @click="g_copyText(scope.row.squadbeyondUid)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="atid" width="270px">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.reportData.adtasukaruId !== ''"
            readonly
            v-model="scope.row.reportData.adtasukaruId"
          >
            <el-button
              @click="g_copyText(scope.row.reportData.adtasukaruId)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="atid生成日時" width="200px">
        <template slot-scope="scope">
          <span
            v-if="scope.row.reportData.formattedAdtasukaruIdCreatedAt !== null"
            :class="{
              red:
                isWithinTimeDifference(
                  scope.row.reportData.formattedAdtasukaruIdCreatedAt,
                  scope.row.createdAt,
                  24
                ) !== true,
            }"
            >{{ scope.row.reportData.formattedAdtasukaruIdCreatedAt }}</span
          >
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" min-width="80px" label="再送">
        <template slot-scope="scope">
          <el-button
            @click="resendConversionEvent(scope.row)"
            type="primary"
            size="mini"
            >再送</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "squadbeyond-conversion-api-history-list",
  components: {},
  props: {
    conversionApiHistoryList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
  },
  watch: {},
  methods: {
    resendConversionEvent(apiHistoryData) {
      //
      const promotionName =
        apiHistoryData.reportData.tools02PromotionData !== null
          ? apiHistoryData.reportData.tools02PromotionData.promotionName
          : "-";

      //
      let confirmText = "";
      confirmText += `API送信日時: ${apiHistoryData.sendDatetime}\n`;
      confirmText += `媒体: ${
        this.$store.getters.toolData(apiHistoryData.reportData.toolId).alphaCode
      }\n`;
      confirmText += `ASP: ${
        this.$store.getters.aspData(apiHistoryData.reportData.aspId).aspName
      }\n`;
      confirmText += `CV日時: ${apiHistoryData.reportData.formattedConversionTime}\n`;
      confirmText += `プログラム名: ${promotionName}\n`;
      confirmText += `sbidAT: ${apiHistoryData.reportData.squadbeyondUid}\n`;
      confirmText += `atid: ${apiHistoryData.reportData.adtasukaruId}\n`;
      confirmText += `\n`;
      confirmText += `のAPIを再送信します。よろしいですか？`;

      //
      let payload = {
        confirmText: confirmText,
        apiHistoryData: apiHistoryData,
      };
      this.$emit("resendConversionEvent", payload);
    },
    isWithinTimeDifference(startDateStr, endDateStr, timeDifferenceInHours) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);

      // 差をミリ秒単位で計算
      const timeDifference = Math.abs(endDate - startDate);

      // ミリ秒を時間に変換
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      // 判定
      return hoursDifference <= timeDifferenceInHours;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
