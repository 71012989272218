<template>
  <div class="ctrl-affiliate-rewards-user-summaries">
    <el-row type="flex" justify="center" align="middle">
      <el-col :span="8" style="text-align: center">
        <span>振込月: </span>
        <el-date-picker
          v-model="transferMonth"
          type="month"
          format="yyyy年MM月"
          value-format="yyyyMM01"
          placeholder="月を選択"
        />
      </el-col>
      <el-col :span="4" v-loading="loading" style="text-align: center">
        <el-button @click="downloadBankCsv" type="primary"
          >ダウンロード</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CtrlAffiliateDownloadBankCsvDownloadBankCsv",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      transferMonth: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  methods: {
    downloadBankCsv() {
      if (this.transferMonth !== "") {
        //ok.
      } else {
        alert("月を選択してください。");
        return;
      }
      this.loading = true;
      this.$axios
        .get(
          `${this.rt}/api/ctrl/affiliate/download-bank-csv.php?transfer_month=${this.transferMonth}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            location.href = `${this.rt}/api/tools02/downloadFile.php?type=affiliateBankCsv&file_name=${response.data.fileName}&download_file_name=${response.data.downloadFileName}`;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
