<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>既にクリックパラメータを付与している場合</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>▼説明動画</h4>
        <div v-if="tool.codeKebab === 'yahoo-cv'" style="margin-bottom: 15px">
          ※以下はGoogle広告(gclid)での説明動画ですが、Yahoo!検索広告(YCLID)の場合も仕組みは同じです。
        </div>
        <!-- <iframe
          src="https://player.vimeo.com/video/643114831?h=0a9bd3a8e2"
          width="100%"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe> -->
        <div style="padding: 62.5% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/643114831?h=0a9bd3a8e2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="10-01既にクリックパラメータを付与している場合.mp4"
          ></iframe>
        </div>
        <h4>▼記事URL</h4>
        <p>https://bi-body-navi.net/archives/123</p>
        <h4>▼入稿URL</h4>
        <p>https://bi-body-navi.net/archives/123?ad=A1</p>
        <p>https://bi-body-navi.net/archives/123?ad=A2</p>
        <p>https://bi-body-navi.net/archives/123?ad=A3</p>
        <p>https://bi-body-navi.net/archives/123?ad=A4</p>
        <p style="color: initial">※クリエイティブ識別パラメータ付与</p>
        <h4>
          ▼{{ tool.mediaName }}経由（{{ tool.clickIdName }}が付与される）
        </h4>
        <p>
          https://bi-body-navi.net/archives/123?ad=A1<span
            style="color: initial"
            >&amp;{{ tool.clickIdName }}=xxxxxxxxx</span
          >
        </p>
        <h4>▼アフィリエイトリンク</h4>
        <p class="break">
          https://t.felmat.net/fmcl?ak=E51280.1.V90629V.F74545V&amp;pb=ad_A1_{{
            tool.clickIdName
          }}_xxxxxxxxx
        </p>
        <h4>▼ASP管理画面のクリックパラメータの表示欄</h4>
        <p>ad_A1_{{ tool.clickIdName }}_xxxxxxxxx</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
p {
  color: var(--blue2);
}
.break {
  word-break: break-all;
}
</style>
