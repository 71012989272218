<template>
  <div class="parameter-log">
    <el-row>
      <el-col>
        <h2>ブラウザテストツール</h2>
      </el-col>
    </el-row>
    <BrowserTestTool />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BrowserTestTool from "@/components/Ctrl/BrowserTest/BrowserTestTool.vue";

export default {
  components: {
    BrowserTestTool,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
