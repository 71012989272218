<template>
  <div class="manual-tools02-contents">

<h2>
  プログラム情報登録<br class="sp-only">（mobee2）
</h2>
<el-row>
  <el-col>
    <h4>【１】 <a href="https://report.mobee2.jp" target="_blank">Mobee2の管理画面</a>にログインし、「広告検索」をクリックします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no12/01.png`" alt="" />

            <h4>【２】プログラム名を入力して「ENTER」or「検索」ボタンクリックします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no12/02.png`" alt="" />

            <h4>【３】広告名と広告IDをコピーします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no12/03.png`" alt="" />


                    <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>
<h4>【３】ASPプログラム名とプログラムIDに貼り付けます。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no12/04.png`" alt="" />


  </el-col>
</el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>

</style>
