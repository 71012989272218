<template>
  <div v-loading="loading" class="affiliate-status-tool-utilication-summary">
        <h3>ツール別利用状況</h3>
        <div class="cover">
          <div v-for="(toolData, toolIndex) in toolDataList" :key="toolIndex" class="tb">
            <h4>{{ toolData.toolName }}</h4>
            <table class="af-pop">
              <tr>
                <th>利用設定ON</th>
                <td>{{ toolData.numberOfSubscriptions }}人</td>
              </tr>
              <tr v-for="(bonusData, bonusIndex) in toolData.bonusDataList" :key="bonusIndex">
                <th>{{ bonusData.name }}</th>
                <td>{{ bonusData.num | localeNum }}人</td>
              </tr>
            </table>
          </div>
        </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateStatusToolUtilizationSummary",
  components: {},
  props: {
    dateRange: Array
  },
  data() {
    return {
      loading: true,
      toolDataList: []
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    dateRange: {
      handler(newDateRange) {
        this.getToolUtilizationSummary(newDateRange);
      },
      immediate: true,
    },
  },
  methods: {
    getToolUtilizationSummary(dateRange) {
      this.loading = true;
      this.$axios
        .get(`${this.rt}/api/affiliate/get-tool-utilization-summary.php?date_range=${encodeURIComponent(JSON.stringify(dateRange))}`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.toolDataList = res.data.toolDataList;
            this.loading = false;
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push('/login');
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.cover{
  display: flex;
  flex-wrap: wrap;
justify-content: space-between;
}
.tb{
  width: 48%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
}
table.af-pop {
  tr {
    th {
      width: 70%;
    }
  }
}
h4{
  @media screen and (max-width: 768px) {
    margin-bottom: 5px;
  }
}
</style>
