<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>キャンペーン単位のコンバージョン設定（説明）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>キャンペーン単位のコンバージョン設定を理解します</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【説明】</h3>
        <p>キャンペーン単位のコンバージョン設定はされていますでしょうか？</p>
        <ul>
          <li>一つのアカウントで複数の案件を扱っている</li>
          <li>最終CVで最適化しながらMCVを計測したい</li>
          <li>MCVで最適化したい</li>
        </ul>
        <p>という方は絶対に設定したほうが良い機能になります。</p>
        <p>特にアドタスカルを使う場合は、</p>
        <ul>
          <li>MCVで最適化する場合</li>
          <li>オフラインCV（最終CV）で最適化する場合</li>
        </ul>
        <p>
          どちらの場合もキャンペーン単位でコンバージョン設定を推薦しています。
        </p>
        <h4>キャンペーン単位のコンバージョン設定とは？</h4>
        <p>簡単に説明すると、</p>
        <p class="red">「Googleにこのコンバージョンで最適化したいです」</p>
        <p>と伝える設定です。</p>
        <p>
          この設定をしないと、ASPアカウント単位でのコンバージョン設定になるため、Googleが間違った最適化をしてしまいます。
        </p>
        <p>たとえば、キャンペーンAには、</p>
        <ul>
          <li>アフィリリンクのクリック（MCV）</li>
          <li>商品購入（最終CV）</li>
        </ul>
        <p>の２つの成果地点でコンバージョン作成しました。</p>
        <p>キャンペーンAに、コンバージョン設定をしていません。</p>
        <p>
          すると、MCVも最終CVもコンバージョン数は同じ１としてカウントされます。
        </p>
        <p>
          なので、本当は最終CVで最適化させたいのに、MCVの方が数が多いので、MCVで最適化されてしまいます。
        </p>
        <p>かと言って、MCVを作成しないと、スピーディーにPDCAが回せません。</p>
        <p>そこで、キャンペーン単位のコンバージョン設定が活躍します。</p>
        <h4>キャンペーン単位のコンバージョン設定の活用方法</h4>
        <p>キャンペーン単位のコンバージョンを行うことで、</p>
        <ul>
          <li>最適化対象にしたいコンバージョン</li>
          <li>計測用に数値だけ見たいコンバージョン</li>
        </ul>
        <p>を分けることができます。</p>
        <p>
          「最適化対象にしたいコンバージョン」を「キャンペーン単位のコンバージョン」として設定する。
        </p>
        <p>
          「計測用に数値だけ見たいコンバージョン」は、作成と設置をするだけ。（オフラインCVの場合、設置不要）
        </p>
        <h4>ケーススタディー</h4>
        <p>
          アフィリエイトの場合、MCVと最終CVで２つの成果地点でコンバージョンを作成している方が多いと思います。
        </p>
        <p>
          人によってどちらを最適化対象にするか変わってきますので、２つのケースで設定方法をみていきます。
        </p>
        <h5>ケース１：MCVで最適化して、最終CV（オフラインCV）は計測用</h5>
        <p>
          MCV：キャンペーン単位のコンバージョンに設定<br />最終CV（オフラインCV）：作成するだけ
        </p>
        <p class="red">⇒ MCVで最適化しつつ、最終CVでのデータも計測できる。</p>
        <h5>ケース２：最終CV（オフラインCV）で最適化して、MCVは計測用</h5>
        <p>
          MCV：作成して、中間ページに設置するだけ<br />最終CV（オフラインCV）：キャンペーン単位のコンバージョンに設定
        </p>
        <p class="red">⇒ 最終CVで最適化しつつ、MCVでのデータも計測できる。</p>
        <p>
          「キャンペーン単位のコンバージョン設定」について理解できたら、実際に設定してみましょう。
        </p>
        <router-link></router-link>
        <p>
          記事LPなど同一キャンペーンで１案件のみ扱う場合は、「<router-link
            to="/manual/google-cv/11/06"
            >キャンペーン単位のコンバージョン設定（設定：単品）</router-link
          >」をご覧ください。
        </p>
        <p>
          比較サイトやランキングサイトで同一キャンペーンで複数案件を扱う場合は、「<router-link
            to="/manual/google-cv/11/02"
            >キャンペーン単位のコンバージョン設定（設定：複数案件）</router-link
          >」をご覧ください。
        </p>
        <h4>▼参照ページ</h4>
        <p>
          [Google広告ヘルプ]キャンペーン単位のコンバージョン設定を有効にする
        </p>
        <a
          href="https://support.google.com/google-ads/answer/9143218?hl=ja"
          target="_blank"
          rel="noopener noreferrer"
          >https://support.google.com/google-ads/answer/9143218?hl=ja</a
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
