<template>
  <div>
    <el-row type="flex" justify="center">
      <el-col :span="12">
        <el-descriptions class="margin-top" :column="1" border>
          <el-descriptions-item>
            <template slot="label">
              <span>ドメイン</span>
              <Question :placement="'left'" :content="'を設置するページ名を入力してください。'" />
            </template>
            <el-input v-model="domain"></el-input>
            <ValidationErrorMessages
              :errorMessageList="validationErrorData.domain"
            />
          </el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="center">
          <el-button @click="register()" type="primary">登録</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

export default {
  name: 'DomainEditor',
  components: {
    ValidationErrorMessages,
  },
  props: {
    domainId: String
  },
  data() {
    return {
      domain: "",
      validationErrorData: {
        domain: []
      }
    }
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  methods: {
    register() {
      let params = new URLSearchParams();
      params.append("domain", this.domain);
      this.$axios
        .post(`${this.rt}/l/api/domain/register`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("setDomainList");
            this.$router.go(-1);
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>

<style lang="scss" scoped></style>
