<template>
  <div class="tests">
    <h2>Tests</h2>

    <a v-scroll-to="'#goal'" href="#goal" class="link-btn st-reflection"
      >100円キャンペーンの詳細を見る</a
    >
    <el-button @click="OriginalVueScrollTo">jump!</el-button>
    <el-row>
      <el-col>
        <h3>【Line API 送信テスト】</h3>
      </el-col>
      <el-col>
        <LineApi />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【Laravelファイルダウンロードテスト】</h3>
      </el-col>
      <el-col>
        <LaravelFileDownload />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【Google API 送信テスト】</h3>
      </el-col>
      <el-col>
        <GoogleApi />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【手動・自動インポート】</h3>
      </el-col>
      <el-col>
        <PointBack />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【TikTok API】</h3>
      </el-col>
      <el-col>
        <TiktokApi />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【Laravel test】</h3>
      </el-col>
      <el-col>
        <LaravelTest />
      </el-col>
    </el-row>
    <div id="goal"></div>
    <el-row>
      <el-col>
        <h3>【settleComplete.phpテスト】</h3>
      </el-col>
      <el-col>
        <PostSettleCompleteData />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【TinyMCE】</h3>
      </el-col>
      <el-col>
        <TinyMceEditor :text.sync="text" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【Draggable】</h3>
      </el-col>
      <el-col>
        <Draggable />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【SelectImage】</h3>
      </el-col>
      <el-col>
        <SelectImage />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LineApi from "@/components/Tests/LineApi.vue";
import GoogleApi from "@/components/Tests/GoogleApi.vue";
import TiktokApi from "@/components/Tests/TiktokApi.vue";
import LaravelTest from "@/components/Tests/LaravelTest.vue";
import PointBack from "@/components/Tests/PointBack.vue";
import PostSettleCompleteData from "@/components/Tests/PostSettleCompleteData.vue";
import TinyMceEditor from "@/components/Tests/TinyMceEditor.vue";
import Draggable from "@/components/Tests/Draggable.vue";
import SelectImage from "@/components/Tests/SelectImage.vue";
import LaravelFileDownload from "@/components/Tests/LaravelFileDownload.vue";

export default {
  components: {
    LineApi,
    GoogleApi,
    TiktokApi,
    LaravelTest,
    PointBack,
    PostSettleCompleteData,
    TinyMceEditor,
    Draggable,
    SelectImage,
    LaravelFileDownload,
  },
  props: {},
  data() {
    return {
      text: "aiueo",
    };
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  created() {
    this.$nextTick(() => {
      this.OriginalVueScrollTo(this.$route.query.position);
    });
  },
  methods: {
    OriginalVueScrollTo(id) {
      //
      let element = "#" + id;
      let VueScrollTo = require("vue-scrollto");

      //
      let duration = 0; // 実行までの時間（ms）

      //
      let option = {
        offset: -500, // ゴール地点からどれだけズレた位置に飛ぶか
      };

      // scroll実行
      VueScrollTo.scrollTo(element, duration, option);
    },

    //
    generateUserCredentials() {
      this.$axios
        .get(`${this.rt}/l/api/tools02/media/google/generate-user-credentials`)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
// h2 {
//   margin-top: 50px;
// }
// h3 {
//   margin: 50px 0 0px;
// }
.tests > .el-row {
  border: dotted 1px #666;
  padding: 20px;
}
</style>