<template>
  <div class="affiliate-notice">
    <h2>通知設定</h2>
    <el-row>
      <el-col>
        <NotificationConfig />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import NotificationConfig from "@/components/Affiliate/Notice/NotificationConfig.vue";

export default {
  components: {
    NotificationConfig
  },
  props: {},
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {},
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
