<template>
  <div class="payment-tool-point-list">
    <h3 class="back-gray">ツールごとの消費ポイント</h3>
    <table class="typeB">
      <tr>
        <th>
          {{ tools["googleCv"].name2 }}
        </th>
        <td>{{ tools["googleCv"].point | localeNum }}ポイント/日</td>
      </tr>
      <tr>
        <th>
          {{ tools["yahooCv"].name2 }}
        </th>
        <td>{{ tools["yahooCv"].point | localeNum }}ポイント/日</td>
      </tr>
      <tr>
        <th>
          {{ tools["facebookCv"].name2 }}
        </th>
        <td>{{ tools["facebookCv"].point | localeNum }}ポイント/日</td>
      </tr>
      <tr>
        <th>
          {{ tools["microsoftCv"].name2 }}
        </th>
        <td>{{ tools["microsoftCv"].point | localeNum }}ポイント/日</td>
      </tr>
      <tr>
        <th>
          {{ tools["tiktokCv"].name2 }}
        </th>
        <td>{{ tools["tiktokCv"].point | localeNum }}ポイント/日</td>
      </tr>
      <tr>
        <th>
          {{ tools["ydaCv"].name2 }}
        </th>
        <td>{{ tools["ydaCv"].point | localeNum }}ポイント/日</td>
      </tr>
      <tr>
        <th>
          {{ tools["squadbeyondCv"].name2 }}
        </th>
        <td>{{ tools["squadbeyondCv"].point | localeNum }}ポイント/日</td>
      </tr>
      <!-- <tr>
        <th>
          {{tools['popup'].name}}
        </th>
        <td>
          {{tools['popup'].point}}ポイント/表示
        </td>
      </tr>
      <tr>
        <th>
          {{tools['overlay'].name}}
        </th>
        <td>
          {{tools['overlay'].point}}ポイント/表示
        </td>
      </tr> -->
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PaymentToolPointList",
  components: {},
  props: {},
  data() {
    return {
      isCampaign: false,
    };
  },
  computed: {
    ...mapGetters(["rt", "tools"]),
  },
  created() {
    this.checkCampaign();
  },
};
</script>

<style lang="scss" scoped>
h3.back-gray {
  background-color: #808080;
  color: white;
  margin-bottom: 35px;
  margin-top: 40px;
  margin-bottom: 62px;
}

.typeB {
  width: 70%;

  tr {

    th,
    td {
      @media screen and (max-width: 767px) {
        display: block;
        border-right: 1px solid var(--gray);
        border-left: 1px solid var(--gray);
      }
    }

    th {
      width: 50%;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    td {
      width: 50%;
      text-align: center;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>
