<template>
  <div class="ctrl-home">
    <Buttons />
  </div>
</template>

<script>
import Buttons from "@/components/Ctrl/ApiHistories/Home/Buttons.vue";

export default {
  data() {
    return {};
  },
  components: {
    Buttons,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
