<template>
  <div class="tools02-parameter-check">
    <el-row>
      <el-col>
        <h2>パラメータチェック</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <Tools02ParameterCheckCheckingSystem :toolId="toolId"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02ParameterCheckCheckingSystem from "@/components/Tools02/ParameterCheck/CheckingSystem.vue";

export default {
  components: {
    Tools02ParameterCheckCheckingSystem,
  },
  props: {
    toolId: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
