<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（セレス）</h2>

    <el-row>
      <el-col>
        <h4>
          【１】
          <a href="https://admin.ad-track.jp/report/index.php" target="_blank"
            >セレスの管理画面</a
          >にログインし、「レポート」→「広告別レポート」の順にクリックします。
        </h4>

        <img :src="`${rt}/img/manual/google-cv/no05/no05/01.png`" alt="" />

        <h4>【２】「絞り込み」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no05/02.png`" alt="" />

        <h4>
          【３】登録したいプログラムが稼働していた期間を選択して、「絞り込み」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no05/03.png`" alt="" />

        <h4>
          【４】プログラム別のレポートが表示されたら、「CSV」をクリックして、ダウンロードします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no05/04.png`" alt="" />

        <p class="red" style="font-weight: bold">
          ※運用開始前の案件で、プログラム名が表示されない場合は、発行されたアフィリリンクを自分で数回クリックしてください。
        </p>

        <h4>
          【５】ダウンロードしたCSVを開いて、広告名（A列）をコピーします。
        </h4>
        <div class="mini-img">
          <img
            :src="`${rt}/img/manual/google-cv/no05/no05/05.png`"
            alt=""
          />
        </div>

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>
          【６】アドタスカルのASPプログラム名に貼り付けます。
          プログラムIDは、広告名の[]内の数値をコピペします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no05/06.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
