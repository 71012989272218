<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>MCCアカウントの作り方</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】Google 広告クライアント センター（MMC）アカウントにアクセスします。</h4>
        <a href="https://ads.google.com/home/tools/manager-accounts/" target="_blank">https://ads.google.com/home/tools/manager-accounts/</a>
        <h4>【２】「クライアント センター (MCC)を作成」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no02/01.png`" alt="" />
        <h4>【３】ビジネス情報を入力して、「送信」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no02/02.png`" alt="" />
        <h4>【４】「アカウントを確認」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no02/03.png`" alt="" />
        <h4>【５】MCCの管理画面TOPページに移動します。これでMCCアカウントの作成は完了です。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no02/04.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
