var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"format0301"},[_c('div',{staticClass:"format-cover2",style:(_vm.$route.name === 'tools01-format' ? 'height: 600px;' : 'height: 100px;')},[_c('div',{staticClass:"overlay-background",style:('height:' + _vm.format.height02 + 'px;background:' + _vm.format.color04 + ';')},[_c('div',{class:'txt txt' + _vm.format_id},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.format.text01)}}),_c('div',{staticClass:"number-zone",style:('font-size:' +
            _vm.format.int08 +
            'pt;color:' +
            _vm.format.color05 +
            ';font-weight:' +
            _vm.format.str02 +
            ';')}),_c('div',{domProps:{"innerHTML":_vm._s(_vm.format.text02)}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }