<template>
  <div class="manual-tools01-popup01">
    <el-row>
      <el-col>
        <h2>{{tools[tool].name2}}設定マニュアル</h2>
        <h3 :id="`${tools[tool].code2}-01`">{{tools[tool].name2}}のサイト・ページの新規登録</h3>
        <p>「新規設定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-1-01.jpg`" alt="">
        <p>新規設定画面が表示されます。以下について、入力と選択をしてください。</p>
        <table class="typeB">
          <tr>
            <th>ページ名</th>
            <td>{{tools[tool].name2}}を設置するページ名を入力</td>
          </tr>
          <tr>
            <th>設定ドメイン</th>
            <td>{{tools[tool].name2}}を設定するドメインを選択</td>
          </tr>
          <tr>
            <th>設定ページURL</th>
            <td>当{{tools[tool].name}}を設定するページのURLを入力</td>
          </tr>
        </table>
        <p>入力したら、「登録」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-1-02.jpg`" alt="">
        <p>以上で、{{tools[tool].name2}}の新規設定が完了しました。</p>
        <img :src="`${rt}/img/manual/manual2-1-03.jpg`" alt="">
        <h3 :id="`${tools[tool].code2}-02`">表示する{{tools[tool].name2}}の設定</h3>
        <p>「編集」をクリックして、表示する{{tools[tool].name2}}の詳細設定をしていきます。</p>
        <img :src="`${rt}/img/manual/manual2-1-04.jpg`" alt="">
        <h3>{{tools[tool].name2}}を設定するページ情報の入力</h3>
        <p>{{tools[tool].name2}}の新規設定時に入力した情報が反映されているので、必要に応じて編集をしてください。「一言メモ」には、{{tools[tool].name}}を設置したページの属性など、任意でメモを残すことができます。入力や編集した項目は「更新」をクリックして確定させてください。</p>
        <img :src="`${rt}/img/manual/manual2-1-05.jpg`" alt="">
        <!-- ↓↓ {{tools[tool].name}}共通部分 ↓↓ -->
        <ManualTools01Popup :tool="tool"/>
        <!-- ↑↑ {{tools[tool].name}}共通部分 ↑↑ -->
        <h3>表示する{{tools[tool].name}}を訪問者の到達位置によって変更する分割数の選択</h3>
        <p>{{tools[tool].name2}}では、はじめに、{{tools[tool].name}}の表示分割数を選択します。表示分割数とは、表示する{{tools[tool].name}}を訪問者の到達位置によって変更する分割数のことを意味します。2を選択すれば、訪問者の到達位置を2つに分けて、表示する{{tools[tool].name}}を変更することができます。ここでは2分割の場合を例にして説明していきます。</p>
        <p>「表示分割数選択」を選択します。「表示分割数選択」を2または3にすると、ページを訪問した訪問者の行動に合わた{{tools[tool].name}}の表示設定が可能になります。</p>
        <p>「ページを訪問したユーザーが到達した最下部の位置を判別して{{tools[tool].name}}を表示する」を選択した場合、ユーザーが分割領域2の位置まで到達し、その後、分割領域1の位置まで戻って離脱したとしても、分割領域2に設定した{{tools[tool].name}}を表示します。「離脱時にユーザーが滞在している位置を判別して{{tools[tool].name}}を表示する」を選択した場合、ユーザーが分割領域3の位置まで到達し、その後、分割領域1の位置まで戻って離脱すると、分割領域1に設定した{{tools[tool].name}}を表示します。</p>
        <img :src="`${rt}/img/manual/manual2-1-23.jpg`" alt="">
        <p>続いて、表示分割領域ごとに表示する{{tools[tool].name}}を設定していきます。</p>
        <p>分割領域１の＋をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-1-24.jpg`" alt="">
        <p>以下の画面が立ち上がり、登録済のフォーマットが表示されるので、設定したいフォーマットの下にある「決定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-1-25.jpg`" alt="">
        <p>以下のように、分割領域１のフォーマットが設定されました。</p>
        <img :src="`${rt}/img/manual/manual2-1-26.jpg`" alt="">
        <h3>{{tools[tool].name}}からの飛び先URLの設定</h3>
        <p>分割領域ごとに、{{tools[tool].name}}からの飛び先URLを指定できます。「編集（クリックして編集画面へ）」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-1-27.jpg`" alt="">
        <p>飛び先URLの編集画面が立ち上がるので、飛び先として設定するURLをhttps://またはhttp://から入力し、「OK」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-1-28.jpg`" alt="">
        <p>飛び先URLの入力欄の横にある「更新」をクリックすると、飛び先URLの設定が完了します。</p>
        <p>続いて、{{tools[tool].name}}のからの飛び先URLのページの開き方について設定します。同一タブで開く設定と、新規タブで開く設定ができるので、どちらかを選択してください。「{{tools[tool].name}}からの飛び先URLメモ」は、飛び先URLについてのメモ書きを任意で残すことができます。</p>
        <img :src="`${rt}/img/manual/manual2-1-29.jpg`" alt="">
        <p>「表示分割位置の仕切りタグ１」は、分割領域を分けたい位置に設置するタグです。設置方法は、<router-link v-scroll-to="'#popup01-03'" to="">タグ設置のマニュアル</router-link>に記載しています。「表示分圧位置の仕切りタグメモ１」は、表示分割位置の仕切りタグ1を設置した場所など、任意でメモを残すことができます。なお、表示分割数が１のときは、仕切りタグの設定は行いません。</p>
        <img :src="`${rt}/img/manual/manual2-1-30.jpg`" alt="">
        <p>ここまでの手順を繰り返すことで、分割領域２と分割領域３の設定を完了させます。</p>
        <p>設定後は、「登録」をクリックして、設定を確定してください。入力の都度、「登録」をクリックして、確定することもできます。</p>
        <img :src="`${rt}/img/manual/manual2-1-31.jpg`" alt="">
        <h3 :id="`${tools[tool].code2}-03`">{{tools[tool].name2}}の設置</h3>
        <p>以下のタグを設置することで、{{tools[tool].name2}}を稼働させることができます。</p>
        <table class="typeB">
          <tr>
            <th>ツール設置タグ</th>
            <td>ツール自体を稼働させるためのタグ</td>
          </tr>
          <tr>
            <th>表示分割位置の仕切りタグ</th>
            <td>訪問者の行動に合わせて表示する{{tools[tool].name}}を変更する位置を設定するタグ（当機能を利用する場合のみ設定が必要）</td>
          </tr>
          <tr>
            <th>コンバージョンタグ</th>
            <td>設定する{{tools[tool].name}}と紐づくコンバージョンを設定することができます（当機能を利用する場合のみ設定が必要）</td>
          </tr>
        </table>
        <p>{{tools[tool].name2}}のツール設置タグは、以下の画面から取得することができます。「タグ表示」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual2-1-32.jpg`" alt="">
        <p>ツール設置タグが表示されるので、タグをコピーして、ツールの配信を有効にしたいページの&lt;/body>の直前に以下のタグを設置してください。</p>
        <img :src="`${rt}/img/manual/manual2-1-33.jpg`" alt="">
        <p>ツール設置タグは、{{tools[tool].name2}}の編集画面からも取得ができます。【ステップ3】にツール設置タグがあるので、こちらをコピーしてツールの配信を有効にしたいページの&lt;/body>の直前に以下のタグを設置してください。</p>
        <img :src="`${rt}/img/manual/manual2-1-34.jpg`" alt="">
        <p>表示分割位置の仕切りタグは、{{tools[tool].name2}}の編集画面から取得ができます（表示分割機能を使用した場合のみ）。表示分割位置の仕切りタグは、表示を分ける位置に設置をします。2分割の場合は仕切りタグを1つ、3分割の場合は仕切りタグを2つ、それぞれ表示を分けたい位置に設置します。</p>
        <img :src="`${rt}/img/manual/manual2-1-35.jpg`" alt="">
        <p>コンバージョンタグは、共通マニュアルとして別途用意していますので、<router-link v-scroll-to="'#conversion'" to="">コチラ</router-link>を参照してください。</p>
        <p><router-link to="">{{tools[tool].name2}}に関するよくある質問はコチラ</router-link></p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ManualTools01Popup from '@/components/Manual/Tools01/Popup.vue'

export default {
  name: 'ManualTools01Popup01',
  components: {
    ManualTools01Popup
  },
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
