<template>
  <div class="format0202">
    <div
      class="format-cover2"
      :class="{ small: type == 1 }"
      :style="
        $route.name === 'tools01-format' ? 'height: 600px;' : 'height: 100px;'
      "
    >
      <div
        class="overlay-background"
        :style="
          'background:' + format.color04 + ';height:' + format.height02 + 'px;'
        "
      >
        <div class="overlay-outline">
          <img
            v-if="format.img == null || format.img == ''"
            :src="rt + '/img/no_photo.jpg'"
            class="format-img"
          />
          <img
            v-else
            :src="rt + uploadsFolder + format.img"
            class="format-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Format0202",
  props: {
    format: [Array, Object],
  },
  data() {
    return {
      type: 0,
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    uploadsFolder() {
      return this.$store.getters.uploadsFolder;
    },
  },
};
</script>

<style lang="scss" scoped>
.small {
  width: 360px !important;
  transform: scale(0.95);
}
.format-cover2 {
  width: 375px;
  background: white;
  margin: auto;
}
.overlay-background {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  bottom: 0;
  .overlay-outline {
    height: 100%;
    position: relative;
    .format-img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>