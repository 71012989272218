<template>
  <div class="tools02-csv-manual-links-manual-links">
    <el-row>
      <el-col>
        <p>ASP名をクリックすると、マニュアルを見ることができます。</p>
        <table>
          <tr>
            <th colspan="4">▼自社ASP</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/01">■Felmat</router-link></td>
            <td><router-link to="/manual/google-cv/07/02">■SLVRbullet</router-link></td>
            <td><router-link to="/manual/google-cv/07/03">■セレス</router-link></td>
            <td><router-link to="/manual/google-cv/07/04">■レントラックス</router-link></td>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/07">■サルクルー</router-link></td>
            <td><router-link to="/manual/google-cv/07/05">■SCAN</router-link></td>
            <td><router-link to="/manual/google-cv/07/11">■famAD</router-link></td>
            <td><router-link to="/manual/google-cv/07/09">■afb</router-link></td>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/08">■アクセストレード</router-link></td>
            <td><router-link to="/manual/google-cv/07/10">■Mobee2</router-link></td>
            <td><router-link to="/manual/google-cv/07/12">■A8.net</router-link></td>
            <td><router-link to="/manual/google-cv/07/13">■JAnet</router-link></td>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/18">■メディパートナー</router-link></td>
            <td><router-link to="/manual/google-cv/07/24">■TGアフィリエイト</router-link></td>
            <td><router-link to="/manual/google-cv/07/27">■TOSHOアフィリエイト</router-link></td>
            <td><a href="https://adtasukaru.wraptas.site/3268fb21c958442e932dfc155dac1dee" target="_blank" rel="noopener noreferrer">■成果報酬110番</a></td>
          </tr>
          <tr>
            <td><a href="https://adtasukaru.wraptas.site/e7da03f3a3e2478e906891b15978fcaa" target="_blank" rel="noopener noreferrer">■アドボンバー</a></td>
            <td><a href="https://adtasukaru.wraptas.site/fc92431c544f4d3cac281be3a264acaf" target="_blank" rel="noopener noreferrer">■threeate</a></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th colspan="4">▼Link-A</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/06">■Link-AG</router-link></td>
            <td><router-link to="/manual/google-cv/07/06">■BULK-AD</router-link></td>
            <td><router-link to="/manual/google-cv/07/06">■SIXPACK-C</router-link></td>
            <td></td>
          </tr>
          <tr>
            <th colspan="4">▼アフィリコード・システム</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/20">■@tension</router-link></td>
            <td><router-link to="/manual/google-cv/07/20">■Tempura-Link</router-link></td>
            <td><router-link to="/manual/google-cv/07/20">■twowin</router-link></td>
            <td><router-link to="/manual/google-cv/07/20">■ゴリラテ</router-link></td>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/20">■Arrows</router-link></td>
            <td><router-link to="/manual/google-cv/07/20">■i-counter</router-link></td>
            <td><router-link to="/manual/google-cv/07/20">■ADRIP</router-link></td>
            <td><router-link to="/manual/google-cv/07/20">■アシスタ</router-link></td>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/20">■Gain</router-link></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th colspan="4">▼アフィリコードプラス</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/25">■SSアフィリエイト</router-link></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th colspan="4">▼AFAD</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/23">■Self Leap</router-link></td>
            <td><router-link to="/manual/google-cv/07/23">■インペリアル</router-link></td>
            <td><router-link to="/manual/google-cv/07/23">■LADDER</router-link></td>
            <td><router-link to="/manual/google-cv/07/29">■PRESCO</router-link></td>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/23">■AD-LAVI</router-link></td>
            <td><router-link to="/manual/google-cv/07/23">■M-Ads</router-link></td>
            <td><router-link to="/manual/google-cv/07/23">■EQS</router-link></td>
            <td><router-link to="/manual/google-cv/07/23">■AIGATE</router-link></td>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/23">■Finebind</router-link></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <!-- <tr>
            <th colspan="4">▼AFAD（旧）</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/xx">■Valley Consulting</router-link></td><td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr> -->
          <tr>
            <th colspan="4">▼CATS</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/28">■akibito</router-link></td>
            <td><router-link to="/manual/google-cv/07/28">■MOG</router-link></td>
            <td><router-link to="/manual/google-cv/07/19">■ONDe</router-link></td>
            <td></td>
            <td></td>
          </tr>
          <!-- <tr>
            <th colspan="4">▼VENT</th>
          </tr>
          <tr>
            <td><router-link to="/manual/google-cv/07/xxx">■ONDe</router-link></td><td></td>
            <td></td>
            <td></td>
          </tr> -->
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "Tools02CsvManualLinksManualLinks",
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 95%;
  margin-left: auto;
  margin-left: auto;
  th{
    text-align: left;
  }
  td{
    padding: 25px;
  }
}
a {
  color: #409eff;
  font-weight: bold;
}
</style>
