<template>
  <div class="manual-google-cv-contents">
    <h2 style="margin-bottom: 80px">クリックパラメータ一覧</h2>
    <el-row>
      <el-col style="overflow: scroll">
        <table class="typeA" style="width: 1500px">
          <thead>
            <tr>
              <th>ASP</th>
              <th>パラメータ</th>
              <th>アフィリエイトリンク</th>
              <th>パラメータを付けたVER</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(asp, index) in aspParameterData" :key="index">
              <td>{{ asp.name }}</td>
              <td v-html="asp.parameter"></td>
              <td v-html="asp.affiliatelink"></td>
              <td v-html="asp.affiliatelink + asp.parameter"></td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  data() {
    return {
      aspParameterData: [
        {
          name: "Felmat",
          parameter:
            '<span class="red">&pb=</span><span class="green">●●</span>',
          affiliatelink:
            '<span class="blue">https://t.felmat.net/fmcl?ak=E51280.1.V90629xxxxx5V</span>',
        },
        {
          name: "SLVRbullet",
          parameter:
            '<span class="red">?parameter=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://tr.slvrbullet.com/cl/w000028xxx4/xxxx/9540/</span>",
        },
        {
          name: "セレス",
          parameter:
            '<span class="red">&suid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ad-track.jp/ad/p/r?_site=29638&_article=14187&_link=xxxxx&_image=xxxxx</span>",
        },
        {
          name: "レントラックス",
          parameter:
            '<span class="red">&uix=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://www.rentracks.jp/adx/r.html?idx=0.3908.252201.4xxx.63xx3&dna=xxxx</span>",
        },
        {
          name: "サルクルー",
          parameter:
            '<span class="red">&p1=[プログラムID]&p2=</span><span class="green">●●</span><span class="red">&mktag=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://rcv.monkey-ads.com/api/click?id=e95d1fe4f4f4cd32635632bf1ae7f31b5205a411563cf1fbffadc0bxxxxxx25</span>",
        },
        {
          name: "SCAN",
          parameter:
            '<span class="red">&user_id=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://act.scadnet.com/cl/click.php?b_id=wxxxXD&t_id=xxxxxxaTI34L</span>",
        },
        {
          name: "famAD",
          parameter:
            '<span class="red">&suid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ad-fam.com/ad/p/r?_site=1xxxx9&_article=xxx</span>",
        },
        {
          name: "Link-A",
          parameter:
            '<span class="red">&option=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://link-a.net/gate.php?guid=on&mcode=xxxxxxx&acode=xxxxxxxxxx&itemid=0</span>",
        },
        {
          name: "Link-AG",
          parameter: '<span class="red">/</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://cl.link-ag.net/click/afxxx2a/xxxxade4</span>",
        },
        {
          name: "afb",
          parameter:
            '<span class="red">&paid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://t.afi-b.com/visit.php?guid=ON&a=U4132q-Bxxxxx&p=G268590T</span>",
        },
        {
          name: "アクセストレード（要申請）",
          parameter:
            '<span class="red">&add=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://h.accesstrade.net/sp/cc?rk=xxxxxxxxxx</span>",
        },
        // {
        //   name: "SOLXIA（アフィリコードシステム）",
        //   parameter:
        //     '<span class="red">&pbid=</span><span class="green">●●</span>',
        //   affiliatelink: "調査中",
        //   affiliatelinkWithParameter: "調査中"
        // },
        {
          name: "Mobee2",
          parameter:
            '<span class="red">&suid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://mobee2.jp/ad/p/r?medium=9272&ad=4xxxxx&creative=xxx25</span>",
        },
        // {
        //   name: "CATS",
        //   parameter:
        //     '<span class="red">&param1=</span><span class="green">●●</span>',
        //   affiliatelink:
        //     "<span class='blue'>https://ac.pe-k.site/cl/xxxxxxxxxx/?bid=xxxxxxxxx</span>",
        // },
        {
          name: "A8.net",
          parameter:
            '<span class="red">&id1=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://px.a8.net/svt/ejp?a8mat=xxxxxxxxx</span>",
        },
        {
          name: "JANet(1)",
          parameter: '<span class="red">/</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://click.j-a-net.jp/xxxxxx/84xxx3</span>",
        },
        {
          name: "JANet(2)",
          parameter:
            '<span class="red">&u=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ex-click.j-a-net.jp/?a=xxxxx&d=xxxxx</span>",
        },
        {
          name: "メディパートナー",
          parameter:
            '<span class="red">&af=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://medipartner.jp/click.php?APID=xxx&affID=xxxxxx&STID=xxx</span>",
        },
        {
          name: "BULK-AD",
          parameter: '<span class="red">/</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://cl.bulk-ad.com/click/xxx/xxxxxx</span>",
        },
        {
          name: "@tension",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://atmarktension.com/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "Tempura-Link",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://tempura-link.com/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "SIXPACK-C",
          parameter: '<span class="red">/</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://cl.sixpack-c.work/click/xxxxx/xxxxx</span>",
        },
        {
          name: "twowin",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://twowin.jp/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "ゴリラテ",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://gorilatte.net/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "TGアフィリエイト",
          parameter: '<span class="red"></span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ad2.trafficgate.net/t/r/22/1/xxxxxx/0/識別子</span>",
        },
        {
          name: "LADDER",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac-ld.catsys.jp/xxx/cl/?bId=xxx</span>",
        },
        {
          name: "PRESCO",
          parameter:
            '<span class="red">&amp;afad_param_1=</span><span class="green">●●</span><span class="red">&amp;afad_param_2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ad.presco.asia/cl/?b_id=○○○&t_id=xxx</span>",
        },
        {
          name: "インペリアル",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.sfad-by.com/xxx/cl/?bId=xxx</span>",
        },
        {
          name: "Self Leap",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.affi-ads.com/xxx/cl/?bId=xxx</span>",
        },
        {
          name: "Arrows",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://arrows-mn.com/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "i-counter",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://i-counter.net/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "ADRIP",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ad-rip.jp/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "アシスタ",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ashisuta.com/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "SSアフィリエイト",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://affiliate.sonicsense.jp/link.php?i=xxxxxx&m=xxxxxx</span>",
        },
        {
          name: "Valley Consulting",
          parameter:
            '<span class="red">&amp;afad_param_1=</span><span class="green">●●</span><span class="red">&amp;afad_param_2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac-vc.catsys.jp/cl/?b_id=xxx</span>",
        },
        {
          name: "AdCent",
          parameter:
            '<span class="red">&adc=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://stg-secure1.adcent.jp/link?_vl=xxxxx</span>",
        },
        {
          name: "TOSHOアフィリエイト",
          parameter:
            '<span class="red">?sid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://af.tosho-trading.co.jp/click12/xxxxx/xxxxx/ref.NDE</span>",
        },
        {
          name: "akibito（CATS）",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.price-tag.site/cl/xxxxxx/?bid=xxxxx</span>",
        },
        {
          name: "AD-LAVI",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.ad-lavi.com/xxx/cl/?bId=xxx</span>",
        },
        {
          name: "M-Ads",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.m-ads.jp/xxx/cl/?bId=xxx</span>",
        },
        {
          name: "MOG",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.ad-mogra.com/cl/xxxxx/?bid=xxxxx</span>",
        },
        {
          name: "Gain",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ad-catch.jp/link.php?i=xxxxx&m=xxxxx</span>",
        },
        {
          name: "EQS",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span><span class="red">&amp;param2=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.ecoad.jp/xxx/cl/?bId=xxx</span>",
        },
        {
          name: "Gain",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ad-catch.jp/link.php?i=xxxxx&m=xxxxx</span>",
        },
        {
          name: "成果報酬110番",
          parameter:
            '<span class="red">&p=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://af-110.com/pg.php?pgid=xxx&adid=xxx&j=x&mid=xxx&p2=&p3=</span>",
        },
        {
          name: "アドボンバー",
          parameter:
            '<span class="red">&p=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://af-110.com/pg.php?pgid=xxx&adid=xxx&j=x&mid=xxx</span>",
        },
        {
          name: "threeate",
          parameter:
            '<span class="red">&suid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://tr.threeate.jp/ad/p/r?_site=xxx&_article=xxx&_link=xxx&_image=xxx</span>",
        },
        {
          name: "DecoTra",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://decotra.net/link.php?i=xxxxx&m=xxxxx</span>",
        },
        {
          name: "performerz",
          parameter:
            '<span class="red">&pbid=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://performerz.jp/link.php?i=xxxxx&m=xxxxx</span>",
        },
        {
          name: "AIGATE",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://tag.since2018.jp/cl/xxxxx</span>",
        },
        {
          name: "Finebind",
          parameter:
            '<span class="red">&amp;param1=</span><span class="green">●●</span>',
          affiliatelink:
            "<span class='blue'>https://ac.finebind.jp/xxxxx/cl/?bId=xxxxx</span>",
        },
      ],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(.blue) {
  color: var(--blue);
}
</style>
