<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>ピクセルに広告アカウントの権限を付与する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li><router-link :to="`/manual/${tool.codeKebab}/11/09`">コンバージョンAPIで最適化する方法</router-link>の【４】で、設定したいピクセルが表示されない場合、そのピクセルに対して、広告アカウントの権限を付与します。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <h4>【１】ビジネスマネージャ上のハンバガーメニュから、「ビジネス設定」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/01.png`" alt="" />
        </div>
        <h4>【２】「データソース」の中にある「ピクセル」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/02.png`" alt="" />
        </div>
        <h4>【３】権限を付与したい「ピクセル」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/03.png`" alt="" />
        <h4>【４】「アセットを追加」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/04.png`" alt="" />
        <h4>【５】「アセットタイプを選択」の欄が「広告アカウント」になっていることを確認し、権限を付与する広告アカウントをクリックし、「追加」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/05.png`" alt="" />
        </div>
        <h4>【６】「完了」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/06.png`" alt="" />
        </div>
        <h4>【７】「リンク済みのアセット」をクリックすると、広告アカウントが追加されたことが確認できます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/07.png`" alt="" />
        <h4>【８】「ユーザーを追加」のボタンが2箇所あるので、どちらかをクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/08.png`" alt="" />
        <h4>【９】権限を付与するユーザーをクリックし、「ピクセルを管理」にチェックを入れ、「アクセス許可を設定」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/09.png`" alt="" />
        </div>
        <h4>【１０】「完了」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/10.png`" alt="" />
        </div>
        <h4>【１１】以下のように、ピクセルに対してユーザーが付与されました。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no10/11.png`" alt="" />
        <p>以上で完了です。</p>
        <p>引き続き、<router-link :to="`/manual/${tool.codeKebab}/11/09`">コンバージョンAPIで最適化する方法</router-link>の設定を進めてください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.step {
  font-weight: bold;
  font-size: 25px;
  margin: 50px 0 0 0;
}
</style>
