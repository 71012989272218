<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（レントラックス）</h2>

    <el-row>
      <el-col>
        <h4>
          【１】
          <a href="https://manage.rentracks.jp/manage/login" target="_blank"
            >レントラックスの管理画面</a
          >にログインし、「広告案件一覧（リンク作成）」をクリックします。
        </h4>

        <img :src="`${rt}/img/manual/google-cv/no05/no06/01.png`" alt="" />

        <h4>【２】登録したい案件名を入力して、「検索」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no06/02.png`" alt="" />

        <h4>【３】プロモーション名をコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no06/03.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【4】ASPプログラム名に貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no06/04.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
