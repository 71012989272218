<template>
  <div class="outline">
    <div v-html="text" class="text"></div>
  </div>
</template>

<script>
export default {
  name: 'MenuButton',
  props: {
    text: String
  }
}
</script>

<style lang="scss" scoped>
.outline {
  border: solid 1px #999;
  border-radius: 10px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .text {
    font-size: 22px;
    color: #767676;
    font-weight: bold;
    text-align: center;
  }
}
</style>
