<template>
  <div class="manual-tools02-contents">
    <!-- <a href="https://www.loom.com/share/0cad366091ba4ebb9453743a54de6374" target="_blank">https://www.loom.com/share/0cad366091ba4ebb9453743a54de6374</a> -->

    <h2>プログラム情報登録<br class="sp-only">（Felmat）</h2>
    <el-row>
      <el-col>
        <h4>
          【１】
          <a href="https://www.felmat.net/publisher/login" target="_blank">
            Felmatの管理画面</a
          >にログインし、「広告管理（AD）」→「提携中」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no03/01.png`" alt="" />

        <h4>【２】プロモーション名をコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no03/02.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【３】ASPプログラム名に貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no03/03.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
