<template>
  <div class="manual-google-cv-contents">
    <h2>オフラインコンバージョンの注意点</h2>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <!-- <iframe
          src="https://player.vimeo.com/video/529600155?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="03-03オフラインコンバージョンの注意点.mp4"
        ></iframe> -->
        <div style="padding: 62.5% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/529600155?h=43aeb82782&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="03-03オフラインコンバージョンの注意点.mp4"
          ></iframe>
        </div>
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <p>
          オフラインコンバージョンの作成から4～5時間程度は、インポートしてもエラーとなり、インポートができません。
        </p>
        <p>4～5時間程度経過してから、アップロードをお願いします。</p>

        <img
          :src="`${rt}/img/manual/yahoo-cv/no03/no03/01.png`"
          alt=""
          style="margin-bottom: 50px"
        />
        <img :src="`${rt}/img/manual/yahoo-cv/no03/no03/02.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
