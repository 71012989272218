import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import axios from 'axios';
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    validities: {}
  },
  getters: {
    validities(state) {
      return state.validities;
    }
  },
  mutations: {
    setValidities(state, payload) {
      state.validities = payload.validities;
    }
  },
  actions: {
    setValidities({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/api/subscription/getValidities.php`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            // 
            const validities = response.data.validities;
      
            // 
            if (rootGetters["tools02/activeCartSystemSubscriptionData"] !== undefined) {
              validities.cartSystem = 1;
              console.log('cartSystemを1にしました。');
            }else {
              validities.cartSystem = 0;
              console.log('cartSystemを0にしました。');
            }
            console.log(validities);

            // 
            const payload = {
              validities: validities
            };
            commit('setValidities', payload);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            router.push('/login');
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    setSubscriptionActive({
      rootGetters
    }, payload) {
      return new Promise((resolve, reject) => {
        let params = new URLSearchParams();
        params.append("tool_id", payload.toolId);
        params.append("subscription_active", payload.active);
        axios
          .post(`${rootGetters.rt}/api/subscription/regSubscriptionActive.php`, params)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      })
    }
  }
}