<template>
  <div class="OverlayFunction">
      <div class="line_up">
        <div class="line_up_block">
          <p class="tool_name">カンタンタグ設置</p>
          <img :src="rt+'/img/lp/tool_img.jpg'" alt="ツールテストイメージ画像" />
          <p class="tool_txt" style="text-align: left;">オーバーレイを表示したいページへタグを設置するだけで使用することができます。GoogleAnalyticsと同じ設置方法です。</p>
        </div>
        <div class="line_up_block">
          <p class="tool_name">配信オン・オフ設定</p>
          <img :src="rt+'/img/lp/tool_img.jpg'" alt="ツールテストイメージ画像" />
          <p class="tool_txt" style="text-align: left;">オーバーレイの配信オン・オフはワンクリックで切り替えができます。</p>
        </div>
        <div class="line_up_block">
          <p class="tool_name">オーバーレイからのリンク先設定</p>
          <img :src="rt+'/img/lp/tool_img.jpg'" alt="ツールテストイメージ画像" />
          <p class="tool_txt" style="text-align: left;">オーバーレイから誘導するリンク先を自由に設定できます。</p>
        </div>
      </div>

      <div class="line_up">
        <div class="line_up_block">
          <p class="tool_name">オーバーレイの自由作成</p>
          <img :src="rt+'/img/lp/tool_img.jpg'" alt="ツールテストイメージ画像" />
          <p
            class="tool_txt"
            style="text-align: left;"
          >１枚ものの画像を設定して表示することはもちろん、エディター機能によってCSSベースで、ご希望のフォント・カラーにてオーバーレイを作成できます。テンプレートのフォーマットもご用意しています。</p>
        </div>
        <div class="line_up_block">
          <p class="tool_name">効果の確認</p>
          <img :src="rt+'/img/lp/tool_img.jpg'" alt="ツールテストイメージ画像" />
          <p
            class="tool_txt"
            style="text-align: left;"
          >管理画面から、広告サイト(URL)ごとに、オーバーレイの表示数、クリック数、クリック率を確認できます。</p>
        </div>
        <div class="line_up_block">
          <p class="tool_name">対応端末・ブラウザー</p>
          <img :src="rt+'/img/lp/tool_img.jpg'" alt="ツールテストイメージ画像" />
          <p class="tool_txt" style="text-align: left;">
            対応端末：iPhoneXXXX、AndroidXXXX、
            <br />ndowsXXX、MACXXXX
            <br />対応ブラウザ：Chrome、safari、edge
          </p>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "OverlayFunction",
  props: {},
  data() {
    return {
    }
  },
  computed: {
      rt() {
          return this.$store.getters.rt;
      }
  }
}
</script>

<style lang="scss" scoped>
.OverlayFunction {
  padding-bottom: 100px;
  
}
.title {
  // margin-top: 100px;
  
}
.line_up {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  
  .line_up_block {
    text-align: center;
    width: 355px;
    height: 390px;
    box-sizing: border-box;
    padding: 0 25px;
    background-color: #e1ecf6;
    border-radius: 8px;
    
    .tool_name {
      font-size: 20px;
      font-weight: bold;
      color: #1c6db5;
    }
    img {
      width: 100%;
    }
     .tool_txt {
      color: #4d4d4d;
      font-size: 15px;
    }
  }
}
</style>
