<template>
  <div class="tools02-report">
    <el-row>
      <el-col>
        <h2>ASP成果インポート・エクスポート</h2>
      </el-col>
    </el-row>
    <Tools02ReportImport :toolId="toolId"/>
    <Tools02ReportExport :toolId="toolId"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02ReportImport from "@/components/Tools02/Report/Import.vue";
import Tools02ReportExport from "@/components/Tools02/Report/Export.vue";

export default {
  components: {
    Tools02ReportImport,
    Tools02ReportExport,
  },
  props: {
    toolId: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
