<template>
  <div class="manual-tools01-register-domain">
    <el-row>
      <el-col>
        <h2 id="register-domain">ドメイン登録（各ツール共通）</h2>
        <h3 id="header01">ツールを設置するドメインを「ドメイン登録」する</h3>
        <p>管理画面の左サイドメニューまたはヘッド部分にある「アカウント」から、「ドメイン登録」の画面に進みます。ツールを設置したいドメインのドメイン登録を行っていない場合は、「新規追加」からドメインの登録を行います。</p>
        <img :src="`${rt}/img/manual/manual1-1-01.jpg`" alt="">
        <p>以下の入力画面が表示されるので、ツールを設置するドメイン名を、「https://」と「「http://」を含む形式で入力し、「OK」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual1-1-02.jpg`" alt="">
        <p>ドメイン登録が完了しました。</p>
        <img :src="`${rt}/img/manual/manual1-1-03.jpg`" alt="">
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01RegisterDomain',
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
