<template>
  <div class="tools01-format" v-if="componentStart == true">
    <el-row v-if="item.active == 0" :gutter="30" type="flex" justify="end">
      <el-col v-show="format_id != 0" :span="4">
        <div @click="delFormat">
          <Button style="background: #ff6666; color: white" msg="削除" />
        </div>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h2>{{ tools[tool].name2 }} フォーマット新規設定・編集</h2>
      </el-col>
    </el-row>

    <el-row type="flex" align="middle" style="margin: 15px 0">
      <el-col :span="1">
        <!-- ↓↓ <Question :placement="'left'" :content="tools[tool].name+'を設置するページ名を入力してください。'"/> ↓↓ -->
        <Question
          :placement="'left'"
          :content="
            '設定する' +
            tools[tool].name +
            'フォーマットに名前を設定してください。'
          "
        />
      </el-col>
      <el-col :span="23">
        <el-input v-model="item.name">
          <template slot="prepend">フォーマット名</template>
        </el-input>
      </el-col>
    </el-row>

    <!-- ↓↓ ↓↓↓離脱防止ポップアップ以外↓↓↓ ↓↓ -->
    <div v-if="tool == 'popup01' || tool == 'popup02' || tool == 'overlay01'">
      <!-- <el-row type="flex" align="middle" style="margin: 15px 0"> -->
      <!-- <el-col :span="1"> -->
      <!-- ↓↓ <Question :placement="'left'" :content="tools[tool].name+'を設置するページ名を入力してください。'"/> ↓↓ -->
      <!-- <Question
            :placement="'left'"
            :content="
              '設定する' +
              tools[tool].name +
              'からの遷移先を選択します。<br>' +
              tools[tool].name +
              'から直接LPへリンクする場合は「直LP」を選択し、<br>' +
              tools[tool].name +
              'から記事LPなどにリンクする場合は「記事LP」を選択してください。'
            "
          /> -->
      <!-- </el-col> -->
      <!-- <el-col :span="23">
          <div class="el-select-prepend-cover">
            <div class="el-select-prepend">
              {{ tools[tool].name }}からの遷移先タイプ
            </div>
            <el-select
              v-model="item.destination"
              placeholder=""
              style="width: 300px"
            >
              <el-option
                v-for="(ds, index) in destination"
                :key="index"
                :label="ds.name"
                :value="ds.id"
              ></el-option>
            </el-select>
          </div>
        </el-col> -->
      <!-- </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="1"> -->
      <!-- ↓↓ <Question :placement="'left'" :content="tools[tool].name+'を設置するページ名を入力してください。'"/> ↓↓ -->
      <!-- <Question
            :placement="'left'"
            :content="
              '設定する' +
              tools[tool].name +
              'と紐づくコンバージョンを設定することができます。'
            "
          />
        </el-col> -->
      <!-- <el-col :span="23">
          <div class="el-select-prepend-cover">
            <div class="el-select-prepend">紐付けるコンバージョン</div>
            <el-select v-model="item.cv_id" placeholder="" style="width: 300px">
              <el-option
                v-for="(cv, index) in cvs"
                :key="index"
                :label="cv.name"
                :value="cv.cv_id"
              ></el-option>
            </el-select>
          </div>
        </el-col>
      </el-row> -->
    </div>
    <!-- ↑↑↑離脱防止ポップアップ以外↑↑↑ -->

    <el-row v-show="format_id == 0" class="new-formats">
      <el-col>
        <h3 class="back-gray" style="margin-bottom: 40px">
          フォーマットタイプ選択
          <Question
            v-if="tool == 'popup02'"
            :placement="'right'"
            :content="`設定する${tools[tool].name}のフォーマットを選択してください。`"
          />
          <Question
            v-else
            :placement="'right'"
            :content="`設定する${tools[tool].name}のフォーマットを選択してください。${tools[tool].name}部分に表示する数値の変動の仕方がフォーマットによって異なります。それ以外は同じです。`"
          />
        </h3>

        <!-- ↓↓ popup01 ↓↓ -->
        <el-radio-group
          v-if="tool == 'popup01' || tool == 'popup02'"
          v-model="item.type"
          class="format-select-box"
        >
          <div v-for="item in formatType" :key="item.value" class="format">
            <label>
              <el-radio :label="item.value"
                ><span class="format-type-name">{{
                  item.label
                }}</span></el-radio
              >
              <br />
              <img
                :src="rt + '/img/tools01/popup/format0' + item.value + '.jpg'"
              />
            </label>
          </div>
        </el-radio-group>
        <!-- ↑↑ popup01 ↑↑ -->

        <!-- ↓↓ overlay01 ↓↓ -->
        <el-radio-group
          v-else-if="tool == 'overlay01'"
          v-model="item.type"
          class="format-select-box"
        >
          <div v-for="item in formatType" :key="item.value" class="format">
            <label>
              <el-radio :label="item.value"
                ><span class="format-type-name">{{
                  item.label
                }}</span></el-radio
              >
              <br />
              <img
                :src="
                  rt + '/img/tools01/' + tool + '/format0' + item.value + '.jpg'
                "
              />
            </label>
          </div>
        </el-radio-group>
        <!-- ↑↑ overlay01 ↑↑ -->

        <!-- ↓↓ overlay02 ↓↓ -->
        <el-radio-group
          v-else-if="tool == 'overlay02'"
          v-model="item.type"
          class="format-select-box"
        >
          <div v-for="item in formatType" :key="item.value" class="format">
            <label>
              <el-radio :label="item.value"
                ><span class="format-type-name">{{
                  item.label
                }}</span></el-radio
              >
              <br />
              <img
                :src="
                  rt + '/img/tools01/' + tool + '/format0' + item.value + '.jpg'
                "
              />
            </label>
          </div>
        </el-radio-group>
        <!-- ↑↑ overlay02 ↑↑ -->

        <!-- ↓↓ gadget ↓↓ -->
        <el-radio-group
          v-else-if="tool == 'gadget'"
          v-model="item.type"
          class="format-select-box"
        >
          <div v-for="item in formatType" :key="item.value" class="format">
            <label>
              <el-radio :label="item.value"
                ><span class="format-type-name">{{
                  item.label
                }}</span></el-radio
              >
              <br />
              <img
                :src="
                  rt + '/img/tools01/' + tool + '/format0' + item.value + '.jpg'
                "
              />
            </label>
          </div>
        </el-radio-group>
        <!-- ↑↑ gadget ↑↑ -->
      </el-col>
    </el-row>

    <!-- ↓↓ popup01 ↓↓ -->
    <div id="testdayon" style="margin-bottom: 120px"></div>
    <el-row v-show="item.type != ''">
      <el-col :span="4">
        <router-link
          :to="{ name: 'manual', query: { hash: `${tools[tool].code2}-02` } }"
          target="_blank"
        >
          <Button2 type2="1" msg="マニュアル" />
        </router-link>
      </el-col>
    </el-row>
    <div v-if="tool == 'popup01' || tool == 'popup02'">
      <!-- ↓↓ ↓フォーマット１↓ ↓↓ -->
      <el-row v-if="item.type == 1">
        <el-col>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <div class="format-cover">
                <Format0101 :format="item" />
              </div>
              <p>{{ note01 }}</p>
            </el-col>
            <el-col :span="14">
              <el-tabs type="border-card">
                <el-tab-pane label="ヘッダー">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color01"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color01 }}</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          @click="add_img(1)"
                          >画像を挿入</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row v-show="item.type == 1">
                    <el-col>
                      <Editor
                        :value="item.text01"
                        @input="text01"
                        :height="400"
                        :backcolor="item.color01"
                        :num="11"
                      />
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="本体">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color02"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color02 }}</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          @click="add_img(2)"
                          >画像を挿入</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <Editor
                      :value="item.text02"
                      @input="text02"
                      :height="400"
                      :backcolor="item.color02"
                      :num="12"
                    />
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="ボタン">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color03"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color03 }}</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          @click="add_img(3)"
                          >画像を挿入</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <Editor
                      :value="item.text03"
                      @input="text03"
                      :height="400"
                      :backcolor="item.color03"
                      :num="13"
                    />
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="幅">
                  <el-row>
                    <el-col :span="12">
                      <el-row>
                        <el-col></el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">①:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height01"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">②:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height02"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">③:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height04"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">④:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height05"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">⑤:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height06"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">⑥:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height07"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">ボタン幅:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.width02"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">ボタンの高さ:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height03"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">ボタンの角丸:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.int01"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="12">
                      <img
                        :src="rt + '/img/tools01/popup/type_A.jpg'"
                        width="100%"
                      />
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ フォーマット１ ↑↑ -->

      <!-- ↓↓ フォーマット２ ↓↓ -->
      <el-row v-else-if="item.type == 2">
        <el-col>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <div class="format-cover">
                <Format0102 :format="item" />
              </div>
              <p>{{ note01 }}</p>
            </el-col>
            <el-col :span="14">
              <el-tabs type="border-card">
                <el-tab-pane label="外枠">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color01"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color01 }}</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row v-show="item.type == 1">
                    <el-col>
                      <Editor :value="item.text01" @input="text01" :num="21" />
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="本体">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color02"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color02 }}</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          @click="add_img(5)"
                          >画像を挿入</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <Editor
                      :value="item.text02"
                      @input="text02"
                      :height="400"
                      :backcolor="item.color02"
                      :num="22"
                    />
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="ボタン">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color03"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color03 }}</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          @click="add_img(6)"
                          >画像を挿入</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <Editor
                      :value="item.text03"
                      @input="text03"
                      :height="400"
                      :backcolor="item.color03"
                      :num="23"
                    />
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="その他">
                  <el-row>
                    <el-col></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-row type="flex" align="middle">
                        <el-col :span="10">枠の幅:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.width01"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">①:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height01"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">②:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height02"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">③:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height04"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">④:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height05"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">ボタン幅:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.width02"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">ボタンの高さ:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.height03"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                      <el-row type="flex" align="middle">
                        <el-col :span="10">ボタンの角丸:</el-col>
                        <el-col :span="8">
                          <el-input
                            v-model="item.int01"
                            min="0"
                            type="number"
                          />
                        </el-col>
                        <el-col :span="2">
                          <span style="margin-left: 5px">px</span>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col :span="12">
                      <img
                        :src="rt + '/img/tools01/popup/type_B.jpg'"
                        width="100%"
                      />
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ フォーマット２ ↑↑ -->

      <!-- ↓↓ 画像のみ ↓↓ -->
      <el-row v-else-if="item.type == 3">
        <el-col>
          <el-row type="flex" justify="center">
            <el-col>
              <div style="text-align: center; margin-bottom: 20px">
                <Format0103 :format="item" />
              </div>
              <div style="text-align: center">
                <el-button @click="add_img(8)">画像を選択</el-button>
              </div>
            </el-col>
          </el-row>
          <el-row v-show="item.img != ''">
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ 画像のみ ↑↑ -->

      <!-- ↓↓ YFG風 ↓↓ -->
      <el-row v-else-if="item.type == 4 || item.type == 5">
        <el-col>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <div class="format-cover">
                <Format0104 :format="item" />
              </div>
              <p>{{ note01 }}</p>
            </el-col>
            <el-col :span="14">
              <el-tabs type="border-card">
                <el-tab-pane label="画像">
                  <el-row>
                    <el-col>
                      <div style="text-align: center">
                        <el-button @click="add_img(8)">画像を選択</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="タイトル">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color02"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color02 }}</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <Editor
                        :value="item.text02"
                        @input="text02"
                        :height="400"
                        :backcolor="item.color02"
                        :num="41"
                      />
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="説明文">
                  <el-row>
                    <el-col>
                      <Editor
                        :value="item.text03"
                        @input="text03"
                        :height="400"
                        :backcolor="item.color03"
                        :num="42"
                      />
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="ボタン">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color04"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color04 }}</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col>
                      <Editor
                        :value="item.text04"
                        @input="text04"
                        :height="400"
                        :backcolor="item.color04"
                        :num="43"
                      />
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <el-row v-show="item.img != ''">
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ YFG風 ↑↑ -->
    </div>
    <!-- ↓↓ popup01 ↓↓ -->

    <!-- ↓↓ overlay01 ↓↓ -->
    <div v-else-if="tool == 'overlay01'">
      <!-- ↓↓ フォーマット１ ↓↓ -->
      <el-row v-if="item.type == 1">
        <el-col>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <Format0201 :format="item" @text_width="text_width" />
            </el-col>
            <el-col :span="14">
              <el-tabs type="border-card">
                <el-tab-pane label="ボタン">
                  <el-row>
                    <el-col>
                      <div class="box01">
                        <div class="box02">
                          <span>背景色:</span>
                          <el-color-picker
                            v-model="item.color01"
                            :predefine="predefinedColors"
                          />
                          <span>{{ item.color01 }}</span>
                        </div>
                        <el-button
                          type="primary"
                          size="mini"
                          @click="add_img(7)"
                          >画像を挿入</el-button
                        >
                      </div>
                    </el-col>
                  </el-row>
                  <el-row>
                    <Editor
                      :value="item.text01"
                      @input="text01"
                      :backcolor="item.color01"
                      :height="400"
                    />
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="その他">
                  <el-row>
                    <el-col></el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">背景色:</el-col>
                    <el-col :span="2">
                      <el-color-picker
                        v-model="item.color04"
                        show-alpha
                        :predefine="predefinedColors2"
                      />
                    </el-col>
                    <el-col :span="10">
                      <span>{{ item.color04 }}</span>
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">背景の高さ:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.height02" type="number" />
                    </el-col>
                    <el-col :span="2">px</el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">ボタン幅:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.width02" type="number" />
                    </el-col>
                    <el-col :span="15"
                      >px（テキストからボタン内側までの距離で調整）</el-col
                    >
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">ボタンの高さ:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.height03" type="number" />
                    </el-col>
                    <el-col :span="2">px</el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">ボタンの角丸:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.int01" type="number" />
                    </el-col>
                    <el-col :span="2">px</el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
              <!-- ↓↓ <p style="color:red;">※現在のボタン幅の設定の場合、スマホ表示の際に画面幅を超えてしまう恐れがあります。</p> ↓↓ -->
            </el-col>
          </el-row>
          <el-row>
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ フォーマット１ ↑↑ -->

      <!-- ↓↓ 画像のみ ↓↓ -->
      <el-row v-else-if="item.type == 2">
        <el-col>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <Format0202 :format="item" />
            </el-col>
            <el-col :span="14">
              <el-tabs type="border-card">
                <el-tab-pane label="画像">
                  <el-row>
                    <el-col>
                      <div style="text-align: center">
                        <el-button @click="add_img(8)">画像を選択</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="その他">
                  <el-row>
                    <el-col></el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">背景色:</el-col>
                    <el-col :span="2">
                      <el-color-picker
                        v-model="item.color04"
                        show-alpha
                        :predefine="predefinedColors2"
                      />
                    </el-col>
                    <el-col :span="10">
                      <span>{{ item.color04 }}</span>
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">背景の高さ:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.height02" type="number" />
                    </el-col>
                    <el-col :span="2">px</el-col>
                  </el-row>
                  <!-- ↓↓ <el- ↓↓row>
                    <el-col style="text-align:center;">
                      <el-button type="primary" @click="register()">登録</el-button>
                    </el-col>
                  </el-> -->
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ 画像のみ ↑↑ -->
    </div>
    <!-- ↑↑ overlay01 ↑↑ -->

    <!-- ↓↓ ↓↓ overlay02 ↓↓ ↓↓ -->
    <div v-else-if="tool == 'overlay02'">
      <!-- ↓↓ フォーマット１ ↓↓ -->
      <el-row v-if="item.type == 1 || item.type == 2 || item.type == 3">
        <el-col>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <Format0301 :type="1" :format="item" />
            </el-col>
            <el-col :span="14">
              <el-tabs type="border-card">
                <el-tab-pane label="数値">
                  <el-row>
                    <el-col></el-col>
                  </el-row>
                  <el-row type="flex" align="middle">
                    <el-col :span="8">タイプ:</el-col>
                    <el-col v-if="format_id == '0'" :span="8">{{
                      formatType[item.type - 1].label
                    }}</el-col>
                    <el-select
                      v-else
                      v-model="item.type"
                      placeholder="選択してください。"
                    >
                      <el-option
                        v-for="(item, index) in formatType"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="8">アニメーション:</el-col>
                    <el-col :span="8">
                      <el-select
                        v-model="item.str01"
                        placeholder="選択してください。"
                      >
                        <el-option
                          v-for="(item, index) in str01"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                  <el-row
                    v-show="item.type != 1"
                    type="flex"
                    style="align-items: center"
                  >
                    <el-col :span="8">初期値:</el-col>
                    <el-col :span="3">
                      <el-input v-model="item.int02" type="number" />
                    </el-col>
                  </el-row>
                  <el-row
                    v-show="item.type == 3"
                    type="flex"
                    style="align-items: center"
                  >
                    <el-col :span="8">終了時の値:</el-col>
                    <el-col :span="3">
                      <el-input v-model="item.int03" type="number" />
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="8">
                      <span v-if="item.type == 1">数値の変動範囲:</span>
                      <span v-else-if="item.type == 2"
                        >数値の増加量の範囲:</span
                      >
                      <span v-else-if="item.type == 3"
                        >数値の減少量の範囲:</span
                      >
                    </el-col>
                    <el-col :span="5">
                      <el-input v-model="item.int04" type="number" min="1" />
                    </el-col>
                    <el-col :span="2" style="text-align: center">～</el-col>
                    <el-col :span="5">
                      <el-input v-model="item.int05" type="number" min="1" />
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="8">数値が変動する時間の間隔:</el-col>
                    <el-col :span="5">
                      <el-input v-model="item.int06" type="number" min="1" />
                    </el-col>
                    <el-col :span="2" style="text-align: center">～</el-col>
                    <el-col :span="5">
                      <el-input v-model="item.int07" type="number" min="1" />
                    </el-col>
                    <el-col :span="1">秒</el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="8">サイズ:</el-col>
                    <el-col :span="3">
                      <el-input v-model="item.int08" type="number" />
                    </el-col>
                    <el-col :span="2">pt</el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="8">色:</el-col>
                    <el-col :span="2">
                      <el-color-picker
                        v-model="item.color05"
                        :predefine="predefinedColors"
                      />
                    </el-col>
                    <el-col :span="10">
                      <span>{{ item.color05 }}</span>
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="8">太さ:</el-col>
                    <el-col :span="8">
                      <el-select
                        v-model="item.str02"
                        placeholder="選択してください。"
                      >
                        <el-option
                          v-for="(item, index) in weight"
                          :key="index"
                          :label="item"
                          :value="item"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="テキスト">
                  <el-row class="overlay2-editor">
                    <h3>【数値の左側に配置するテキスト】</h3>
                    <Editor
                      :value="item.text01"
                      :height="150"
                      @input="text01"
                    />
                  </el-row>
                  <el-row class="overlay2-editor">
                    <h3>【数値の右側に配置するテキスト】</h3>
                    <Editor
                      :value="item.text02"
                      :height="150"
                      @input="text02"
                    />
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="背景">
                  <el-row>
                    <el-col></el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">色:</el-col>
                    <el-col :span="2">
                      <el-color-picker
                        v-model="item.color04"
                        show-alpha
                        :predefine="predefinedColors2"
                      />
                    </el-col>
                    <el-col :span="10">
                      <span>{{ item.color04 }}</span>
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="5">高さ:</el-col>
                    <el-col :span="3">
                      <el-input v-model="item.height02" type="number" />
                    </el-col>
                    <el-col :span="2">px</el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ フォーマット１ ↑↑ -->
    </div>
    <!-- ↑↑ overlay02 ↑↑ -->

    <!-- ↓↓ gadget ↓↓ -->
    <div v-else-if="tool == 'gadget'">
      <!-- ↓↓ フォーマット１ ↓↓ -->
      <el-row v-if="item.type == 1 || item.type == 2 || item.type == 3">
        <el-col>
          <el-row type="flex" justify="space-between">
            <el-col :span="9">
              <Format0401 v-if="item.type == 1" :type="1" :format="item" />
              <Format0401 v-else-if="item.type == 2" :type="2" :format="item" />
              <Format0401 v-else-if="item.type == 3" :type="3" :format="item" />
            </el-col>
            <el-col :span="14">
              <el-tabs type="border-card">
                <el-tab-pane label="設定">
                  <el-row>
                    <el-col></el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="6">BOXの高さ:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.int10" type="number" min="0" />
                    </el-col>
                    <el-col :span="2">
                      <span class="ml">px</span>
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="6">初期数:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.int09" type="number" min="0" />
                    </el-col>
                    <el-col :span="2">
                      <span class="ml">個</span>
                    </el-col>
                  </el-row>
                  <el-row type="flex" style="align-items: center">
                    <el-col :span="6">回転速度:</el-col>
                    <el-col :span="4">
                      <el-input
                        v-model="item.float01"
                        type="number"
                        step="0.1"
                        min="0"
                      />
                    </el-col>
                    <el-col :span="4">
                      <span class="ml">秒に1回</span>
                    </el-col>
                  </el-row>
                  <el-row
                    v-show="item.type == 1"
                    type="flex"
                    style="align-items: center"
                  >
                    <el-col :span="6">スマホ注文の確率:</el-col>
                    <el-col :span="4">
                      <el-input v-model="item.int11" type="number" min="0" />
                    </el-col>
                    <el-col :span="4">
                      <span class="ml">%</span>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="都道府県">
                  <el-row>
                    <el-col>
                      <h3>都道府県</h3>
                      <el-checkbox-group v-model="item.array02">
                        <el-checkbox
                          v-for="pref in prefs"
                          :label="pref.id"
                          :key="pref.id"
                          >{{ pref.name }}</el-checkbox
                        >
                      </el-checkbox-group>
                    </el-col>
                  </el-row>
                </el-tab-pane>
                <el-tab-pane label="商品">
                  <el-row>
                    <el-col>
                      <h3>商品</h3>
                      <div class="product_edit_box">
                        <div class="products">
                          <div v-if="products.length == 0">
                            マイページより商品の登録をしてください。
                          </div>
                          <div
                            v-else
                            class="product"
                            v-for="item in products"
                            :key="item.id"
                            @click="addProduct(item.id, item.name, item.price)"
                          >
                            {{ item.name }}（{{ item.price }}円）
                          </div>
                        </div>
                        <div>
                          <p style="text-align: center">↓↓↓</p>
                        </div>
                        <div class="display">
                          <div
                            class="product"
                            v-for="(item, index) in products3"
                            @click="delProduct(index)"
                            :key="index"
                          >
                            {{ index + 1 }}: {{ item.name }}（{{
                              item.price
                            }}円）
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </el-tab-pane>
              </el-tabs>
            </el-col>
          </el-row>
          <el-row>
            <el-col class="right-button-cover" style="text-align: center">
              <el-button @click="register()" type="primary">登録</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- ↑↑ フォーマット１ ↑↑ -->
    </div>
    <!-- ↑↑ gadget ↑↑ -->

    <!-- ↓↓ イメージボックス ↓↓ -->
    <div class="media-box-back" v-show="media_box == 1" @click="close"></div>
    <div class="media-box" v-show="media_box == 1">
      <div class="media-box-inner">
        <div class="close" @click="close">×</div>
        <div class="btn in" v-show="addType == 1" @click="appendImg">
          <div class="white-back">
            <Button msg="画像を挿入" type="primary" />
          </div>
        </div>
        <label for="file_upload" v-show="addType == 0">
          <div v-show="uploadFile == null" class="file-select-label">
            <div>
              <div class="plus">+</div>
              <div class="txt">クリックしてファイルを選択</div>
            </div>
          </div>
          <div v-show="uploadFile != null" class="file-select-label2">
            <img
              :src="imageData"
              v-show="imageData"
              style="max-width: 100%; max-height: 100%"
            />
            <!-- ↓↓ <p>ファイルが選択されました。</p> ↓↓ -->
          </div>
        </label>
        <div class="btn" v-show="addType == 0" @click="upImg">
          <Button msg="アップロード" type="primary" />
        </div>
        <el-tabs type="border-card" @tab-click="handleClick">
          <el-tab-pane label="アップロード">
            <input
              class="file-select-input"
              v-on:change="selectedFile($event)"
              type="file"
              accept="image/*"
              id="file_upload"
            />
          </el-tab-pane>
          <el-tab-pane label="画像一覧">
            <div class="img-list">
              <div
                v-for="(img, index) in images"
                :key="index"
                class="img-box"
                :class="'img-box' + index"
                @click="selected(index, img.file_name)"
              >
                <div
                  class="del"
                  :class="'del' + index"
                  @click="delImg(img.file_name)"
                >
                  ×
                </div>
                <img :src="rt + uploadsFolder + img.file_name" />
              </div>
              <div class="img-box-dummy"></div>
              <div class="img-box-dummy"></div>
              <div class="img-box-dummy"></div>
              <div class="img-box-dummy"></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- ↑↑ イメージボックス ↑↑ -->
  </div>
</template>

<script>
import $ from "jquery";
import Button from "@/components/Ui/Button.vue";
import Button2 from "@/components/Ui/Button2.vue";
import Format0101 from "@/components/Ui/Tools01/Format0101.vue";
import Format0102 from "@/components/Ui/Tools01/Format0102.vue";
import Format0103 from "@/components/Ui/Tools01/Format0103.vue";
import Format0104 from "@/components/Ui/Tools01/Format0104.vue";
import Format0201 from "@/components/Ui/Tools01/Format0201.vue";
import Format0202 from "@/components/Ui/Tools01/Format0202.vue";
import Format0301 from "@/components/Ui/Tools01/Format0301.vue";
import Format0401 from "@/components/Ui/Tools01/Format0401.vue";
import Editor from "@/components/Ui/Tools01/Editor.vue";
import Question from "@/components/Ui/Question.vue";

export default {
  components: {
    Button,
    Button2,
    Editor,
    Format0101,
    Format0102,
    Format0103,
    Format0104,
    Format0201,
    Format0202,
    Format0301,
    Format0401,
    Question,
  },
  props: {
    tool: String,
    page_id: Number,
    format_id: Number,
  },
  data() {
    return {
      componentStart: false,
      //common
      page: [],
      note01:
        "※フォーマット上の枠線は、当編集画面上のみ表示され、実際に運用するサイト上では表示されません。",
      images: [],
      media_box: 0,
      imageData: "",
      count: 0,
      addType: 0,
      img_path: "",
      img_path2: "",
      target: 0,
      uploadFile: null,
      prefs: [],
      products: [], //全商品
      products2: [], //商品名のみ
      products3: [], //下のボックス用
      destination: [
        { id: "0", name: "直LP" },
        { id: "1", name: "記事LP" },
      ],
      cvs: [],
      str01: [],
      weight: ["normal", "bold"],
      formatType: [],
      overlay01_text_width: 0,

      //初期値
      item: [],
      formatSelected: 0,

      //overlay01
      overlayDisplay: [
        { value: "0", label: "常に表示" },
        { value: "1", label: "任意のタイミングで表示" },
      ],

      //predefinedColors
      predefinedColors: [
        "#bfedd2",
        "#fbeeb8",
        "#f8cac6",
        "#eccafa",
        "#c2e0f4",

        "#2dc26b",
        "#f1c40f",
        "#e03e2d",
        "#b96ad9",
        "#3598db",

        "#169179",
        "#e67e23",
        "#ba372a",
        "#843fa1",
        "#236fa1",

        "#ecf0f1",
        "#ced4d9",
        "#95a5a6",
        "#7e8c8d",
        "#34495e",

        "#000000",
        "#ffffff",
      ],
      predefinedColors2: [
        "rgb(191, 237, 210, 1)",
        "rgb(251, 238, 184, 1)",
        "rgb(248, 202, 198, 1)",
        "rgb(236, 202, 250, 1)",
        "rgb(194, 224, 244, 1)",

        "rgb(45, 194, 107, 1)",
        "rgb(241, 196, 15, 1)",
        "rgb(224, 62, 45, 1)",
        "rgb(185, 106, 217, 1)",
        "rgb(53, 152, 219, 1)",

        "rgb(22, 145, 121, 1)",
        "rgb(230, 126, 35, 1)",
        "rgb(186, 55, 42, 1)",
        "rgb(132, 63, 161, 1)",
        "rgb(35, 111, 161, 1)",

        "rgb(236, 240, 241, 1)",
        "rgb(206, 212, 217, 1)",
        "rgb(149, 165, 166, 1)",
        "rgb(126, 140, 141, 1)",
        "rgb(52, 73, 94, 1)",

        "rgb(0, 0, 0, 1)",
        "rgb(255, 255, 255, 1)",
      ],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
    formatEditFrom() {
      return this.$store.getters.formatEditFrom;
    },
    imgLimit() {
      return this.$store.getters.imgLimit;
    },
    imgLimitMessage() {
      return this.$store.getters.imgLimitMessage;
    },
    uploadsFolder() {
      return this.$store.getters.uploadsFolder;
    },
  },
  watch: {
    "item.type"(newValue, oldValue) {
      if (this.format_id != 0) {
        return;
      }

      if (this.tool == "popup01" || this.tool == "popup02") {
        //set
        if (oldValue != "") {
          if (oldValue == 1) {
            sessionStorage.setItem("format0101_text01", this.item.text01);
            sessionStorage.setItem("format0101_text02", this.item.text02);
            sessionStorage.setItem("format0101_text03", this.item.text03);
            sessionStorage.setItem("format0101_color01", this.item.color01);
            sessionStorage.setItem("format0101_color02", this.item.color02);
            sessionStorage.setItem("format0101_color03", this.item.color03);
            sessionStorage.setItem("format0101_height01", this.item.height01);
            sessionStorage.setItem("format0101_height02", this.item.height02);
            sessionStorage.setItem("format0101_height04", this.item.height04);
            sessionStorage.setItem("format0101_height05", this.item.height05);
            sessionStorage.setItem("format0101_height06", this.item.height06);
            sessionStorage.setItem("format0101_height07", this.item.height07);
            sessionStorage.setItem("format0101_height03", this.item.height03);
            sessionStorage.setItem("format0101_width02", this.item.width02);
            sessionStorage.setItem("format0101_int01", this.item.int01);
          } else if (oldValue == 2) {
            sessionStorage.setItem("format0102_text02", this.item.text02);
            sessionStorage.setItem("format0102_text03", this.item.text03);
            sessionStorage.setItem("format0102_color01", this.item.color01);
            sessionStorage.setItem("format0102_color02", this.item.color02);
            sessionStorage.setItem("format0102_color03", this.item.color03);
            sessionStorage.setItem("format0102_width01", this.item.width01);
            sessionStorage.setItem("format0102_width02", this.item.width02);
            sessionStorage.setItem("format0102_height01", this.item.height01);
            sessionStorage.setItem("format0102_height02", this.item.height02);
            sessionStorage.setItem("format0102_height04", this.item.height04);
            sessionStorage.setItem("format0102_height05", this.item.height05);
            sessionStorage.setItem("format0102_height03", this.item.height03);
            sessionStorage.setItem("format0102_int01", this.item.int01);
          } else if (oldValue == 3) {
            sessionStorage.setItem("format0103_img", this.item.img);
          } else if (oldValue == 4) {
            sessionStorage.setItem("format0104_img", this.item.img);
            sessionStorage.setItem("format0104_text02", this.item.text02);
            sessionStorage.setItem("format0104_text03", this.item.text03);
            sessionStorage.setItem("format0104_text04", this.item.text04);
            sessionStorage.setItem("format0104_color02", this.item.color02);
            sessionStorage.setItem("format0104_color04", this.item.color04);
          } else if (oldValue == 5) {
            sessionStorage.setItem("format0105_img", this.item.img);
            sessionStorage.setItem("format0105_text02", this.item.text02);
            sessionStorage.setItem("format0105_text03", this.item.text03);
            sessionStorage.setItem("format0105_text04", this.item.text04);
            sessionStorage.setItem("format0105_color02", this.item.color02);
            sessionStorage.setItem("format0105_color04", this.item.color04);
          }
        }

        //get
        if (newValue == 1) {
          this.item.text01 = sessionStorage.getItem("format0101_text01");
          this.item.text02 = sessionStorage.getItem("format0101_text02");
          this.item.text03 = sessionStorage.getItem("format0101_text03");
          this.item.color01 = sessionStorage.getItem("format0101_color01");
          this.item.color02 = sessionStorage.getItem("format0101_color02");
          this.item.color03 = sessionStorage.getItem("format0101_color03");
          this.item.height01 = sessionStorage.getItem("format0101_height01");
          this.item.height02 = sessionStorage.getItem("format0101_height02");
          this.item.height04 = sessionStorage.getItem("format0101_height04");
          this.item.height05 = sessionStorage.getItem("format0101_height05");
          this.item.height06 = sessionStorage.getItem("format0101_height06");
          this.item.height07 = sessionStorage.getItem("format0101_height07");
          this.item.height03 = sessionStorage.getItem("format0101_height03");
          this.item.width02 = sessionStorage.getItem("format0101_width02");
          this.item.int01 = sessionStorage.getItem("format0101_int01");
        } else if (newValue == 2) {
          this.item.text02 = sessionStorage.getItem("format0102_text02");
          this.item.text03 = sessionStorage.getItem("format0102_text03");
          this.item.color01 = sessionStorage.getItem("format0102_color01");
          this.item.color02 = sessionStorage.getItem("format0102_color02");
          this.item.color03 = sessionStorage.getItem("format0102_color03");
          this.item.width01 = sessionStorage.getItem("format0102_width01");
          this.item.width02 = sessionStorage.getItem("format0102_width02");
          this.item.height01 = sessionStorage.getItem("format0102_height01");
          this.item.height02 = sessionStorage.getItem("format0102_height02");
          this.item.height04 = sessionStorage.getItem("format0102_height04");
          this.item.height05 = sessionStorage.getItem("format0102_height05");
          this.item.height03 = sessionStorage.getItem("format0102_height03");
          this.item.int01 = sessionStorage.getItem("format0102_int01");
        } else if (newValue == 3) {
          this.item.img = sessionStorage.getItem("format0103_img");
        } else if (newValue == 4) {
          this.item.img = sessionStorage.getItem("format0104_img");
          this.item.text02 = sessionStorage.getItem("format0104_text02");
          this.item.text03 = sessionStorage.getItem("format0104_text03");
          this.item.text04 = sessionStorage.getItem("format0104_text04");
          this.item.color02 = sessionStorage.getItem("format0104_color02");
          this.item.color04 = sessionStorage.getItem("format0104_color04");
        } else if (newValue == 5) {
          this.item.img = sessionStorage.getItem("format0105_img");
          this.item.text02 = sessionStorage.getItem("format0105_text02");
          this.item.text03 = sessionStorage.getItem("format0105_text03");
          this.item.text04 = sessionStorage.getItem("format0105_text04");
          this.item.color02 = sessionStorage.getItem("format0105_color02");
          this.item.color04 = sessionStorage.getItem("format0105_color04");
        }
      }

      if (this.formatSelected == 1) {
        $("html, body").animate(
          { scrollTop: $("#testdayon").offset().top },
          1000
        );
      }
      this.formatSelected = 1;
    },
    "item.int04"() {
      this.item.int04 = parseInt(this.item.int04);
      if (this.item.int04 < 1) {
        this.item.int04 = 1;
      }
      if (this.item.int05 < this.item.int04) {
        this.item.int04 = this.item.int05;
      }
    },
    "item.int05"() {
      this.item.int05 = parseInt(this.item.int05);
      if (this.item.int05 < 1) {
        this.item.int05 = 1;
      }
      if (this.item.int05 < this.item.int04) {
        this.item.int05 = this.item.int04;
      }
    },
    "item.int06"() {
      this.item.int06 = parseInt(this.item.int06);
      if (this.item.int06 < 1) {
        this.item.int06 = 1;
      }
      if (this.item.int07 < this.item.int06) {
        this.item.int06 = this.item.int07;
      }
    },
    "item.int07"() {
      this.item.int07 = parseInt(this.item.int07);
      if (this.item.int07 < 1) {
        this.item.int07 = 1;
      }
      if (this.item.int07 < this.item.int06) {
        this.item.int07 = this.item.int06;
      }
    },
    "item.int08"() {
      this.item.int08 = parseInt(this.item.int08);
    },

    //↓ colorPickerの「クリア」対策 ↓
    "item.color01"(newValue) {
      if (newValue == null) {
        this.item.color01 = "#ffffff";
      }
    },
    "item.color02"(newValue) {
      if (newValue == null) {
        this.item.color02 = "#ffffff";
      }
    },
    "item.color03"(newValue) {
      if (newValue == null) {
        this.item.color03 = "#ffffff";
      }
    },
    "item.color04"(newValue) {
      if (newValue == null) {
        this.item.color04 = "#ffffff";
      }
    },
    //↑ colorPickerの「クリア」対策 ↑
  },
  created() {
    this.$axios
      .get(this.rt + "/api/getPrefList.php")
      .then((res) => {
        console.log(res);
        this.prefs = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });

    this.$axios
      .get(this.rt + "/api/mypage/getCvList.php?type=1")
      .then((res) => {
        console.log(res);
        this.cvs = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });

    this.$axios
      .get(this.rt + "/api/getAnimationList.php")
      .then((res) => {
        console.log(res);
        this.str01 = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });

    this.$axios
      .get(this.rt + "/api/getItemList.php")
      .then((res) => {
        console.log(res);
        this.products = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });

    //「ポップアップ」かつ、フォーマット新規登録の場合
    //初期値
    if (
      (this.tool == "popup01" || this.tool == "popup02") &&
      this.format_id == 0
    ) {
      //Format0101
      sessionStorage.setItem("format0101_text01", "ヘッダー");
      sessionStorage.setItem(
        "format0101_text02",
        "本体<br>本体<br>本体<br>本体<br>本体"
      );
      sessionStorage.setItem("format0101_text03", "ボタン");
      sessionStorage.setItem("format0101_color01", "");
      sessionStorage.setItem("format0101_color02", "");
      sessionStorage.setItem("format0101_color03", "");
      sessionStorage.setItem("format0101_height01", "10");
      sessionStorage.setItem("format0101_height02", "10");
      sessionStorage.setItem("format0101_height04", "10");
      sessionStorage.setItem("format0101_height05", "10");
      sessionStorage.setItem("format0101_height06", "0");
      sessionStorage.setItem("format0101_height07", "10");
      sessionStorage.setItem("format0101_height03", "80");
      sessionStorage.setItem("format0101_width02", "250");
      sessionStorage.setItem("format0101_int01", "10");

      //Format0102
      sessionStorage.setItem(
        "format0102_text02",
        "本体<br>本体<br>本体<br>本体<br>本体"
      );
      sessionStorage.setItem("format0102_text03", "ボタン");
      sessionStorage.setItem("format0102_color01", "");
      sessionStorage.setItem("format0102_color02", "");
      sessionStorage.setItem("format0102_color03", "");
      sessionStorage.setItem("format0102_width01", "10");
      sessionStorage.setItem("format0102_width02", "250");
      sessionStorage.setItem("format0102_height01", "10");
      sessionStorage.setItem("format0102_height02", "10");
      sessionStorage.setItem("format0102_height03", "80");
      sessionStorage.setItem("format0102_height04", "0");
      sessionStorage.setItem("format0102_height05", "30");
      sessionStorage.setItem("format0102_int01", "10");

      //Format0103
      sessionStorage.setItem("format0103_img", "");

      //Format0104
      sessionStorage.setItem("format0104_img", "");
      sessionStorage.setItem("format0104_text02", "タイトル");
      sessionStorage.setItem("format0104_text03", "説明文");
      sessionStorage.setItem("format0104_text04", "ボタン");
      sessionStorage.setItem("format0104_color02", "#999");
      sessionStorage.setItem("format0104_color04", "#999");

      //Format0105
      sessionStorage.setItem("format0105_img", "");
      sessionStorage.setItem("format0105_text02", "タイトル");
      sessionStorage.setItem("format0105_text03", "説明文");
      sessionStorage.setItem("format0105_text04", "ボタン");
      sessionStorage.setItem("format0105_color02", "#999");
      sessionStorage.setItem("format0105_color04", "#999");
    }

    this.$axios
      .get(`${this.rt}/api/tools01/getFormatType.php?tool=${this.tool}`)
      .then((res) => {
        console.log(res);
        this.formatType = res.data.list;

        //format_id=0の時は初期値を決定。それ以外の時は引っ張ってくる
        let params = new URLSearchParams();
        params.append("tool", this.tool);
        params.append("site_id", this.page_id);
        params.append("format_id", this.format_id);
        this.$axios
          .post(`${this.rt}/api/tools01/getFormatDetail.php`, params)
          .then((res) => {
            console.log(res);
            if (this.tool == "gadget") {
              this.products2 = res.data.products2;
              this.products3 = res.data.products3;
            }
            this.item = res.data.list;
            this.componentStart = true;
          })
          .catch((err) => {
            console.log(err);
          });
      });

    this.getUserImg();
  },
  methods: {
    register() {
      //
      if (this.item.name !== "") {
        // ok!
      } else {
        alert("フォーマット名を入力してください。");
        return;
      }

      //
      if (this.tool === "overlay01") {
        var width02_total = this.overlay01_text_width + this.item.width02 * 2;
        if (width02_total <= 375) {
          // no action.
        } else {
          if (
            confirm(
              "現在のボタン幅の設定の場合、スマホ表示の際に画面幅を超えてしまう恐れがあります。よろしいですか？"
            )
          ) {
            // ok!
          } else {
            return;
          }
        }
      }

      //
      let params = new URLSearchParams();
      params.append("tool", this.tool);
      params.append("site_id", this.page_id);
      params.append("format_id", this.format_id);
      var items = JSON.stringify(this.item);
      params.append("items", items);
      this.$axios
        .post(`${this.rt}/api/tools01/regFormatDetail.php`, params)
        .then((response) => {
          console.log(response);
          if (response.data === "OK") {
            this.$store
              .dispatch("tools01/setFormatList")
              .then((response) => {
                console.log(response);
                this.$router.go(-1);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            alert(response.data);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    upImg() {
      if (this.uploadFile === null) {
        alert("ファイルを選択してください。");
        return;
      } else {
        let formData = new FormData();
        formData.append("uploadFile", this.uploadFile);
        let config = { headers: { "content-type": "multipart/form-data" } };
        this.$axios
          .post(this.rt + "/api/uploadImg.php", formData, config)
          .then((res) => {
            console.log(res);
            if (res.data == "EXCESS") {
              alert(this.imgLimitMessage);
              return;
            }
            alert(
              "アップロードされました。「画像一覧」タブから画像を挿入してください。"
            );
            this.uploadFile = null;
            this.getUserImg();
            $(".img-box").removeClass("selected");
            $(".del").removeClass("show");
            this.img_path = "";
          })
          .catch(function (err) {
            console.log(err);
          });
      }
    },
    selected(val, val2) {
      $(".img-box").removeClass("selected");
      $(".img-box" + val).addClass("selected");
      $(".del").removeClass("show");
      $(".del" + val).addClass("show");
      var boxWidth = 0;
      var boxHeight = 0;
      switch (this.target) {
        case 1:
          boxWidth = 350;
          boxHeight = 112;
          break;
        case 2:
          boxWidth = 350;
          boxHeight = 225;
          break;
        case 3:
          boxWidth = 280;
          boxHeight = 80;
          break;
        case 5:
          boxWidth = 310;
          boxHeight = 307;
          break;
        case 6:
          boxWidth = 250;
          boxHeight = 72;
          break;
        case 7:
          boxWidth = this.overlay01_text_width;
          boxHeight = this.item.height03;
          break;
        case 8:
          boxWidth = 350;
          boxHeight = 450;
          break;
        case 9:
          boxWidth = 350;
          boxHeight = 180;
          break;
      }

      var imgPath = this.uploadsFolder + val2;

      let params = new URLSearchParams();
      params.append("imgPath", imgPath);
      params.append("boxWidth", boxWidth * 0.9);
      params.append("boxHeight", boxHeight * 0.9);
      this.$axios
        .post(this.rt + "/api/getJustImgSize.php", params)
        .then((res) => {
          console.log(res);
          this.img_path = `<p><img src="${this.rt}${imgPath}" style="width:${res.data.width}px;height:${res.data.height}px;"></p>`;
          this.img_path2 = val2;
          console.log(this.img_path);
          console.log(this.img_path2);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    appendImg() {
      if (this.img_path == "") {
        alert("画像を選択してください。");
        return;
      } else {
        if (this.target == 1) {
          this.item.text01 += this.img_path;
        } else if (this.target == 2) {
          this.item.text02 += this.img_path;
        } else if (this.target == 3) {
          this.item.text03 += this.img_path;
        } else if (this.target == 5) {
          this.item.text02 += this.img_path;
        } else if (this.target == 6) {
          this.item.text03 += this.img_path;
        } else if (this.target == 7) {
          this.item.text01 += this.img_path;
        } else if (this.target == 8 || this.target == 9) {
          this.item.img = this.img_path2;
        }
        this.media_box = 0;
      }
    },
    getUserImg() {
      this.$axios
        .get(this.rt + "/api/getUserImg.php")
        .then((res) => {
          console.log(res);
          this.images = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectedFile(e) {
      var size = e.target.files[0].size;
      if (size > this.imgLimit) {
        alert(this.imgLimitMessage);
        return;
      } else {
        e.preventDefault();
        let files = e.target.files;
        this.uploadFile = files[0];
        this.$forceUpdate();

        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(this.uploadFile);
      }
    },
    del() {
      if (confirm("この画像を削除します。よろしいですか？")) {
        let params = new URLSearchParams();
        params.append("id", 1);
        params.append("tool", this.tool);
        params.append("site_id", this.page_id);
        params.append("format_id", this.format_id);
        this.$axios
          .post(this.rt + "/api/tools01/delImage.php", params)
          .then((res) => {
            console.log(res);
            // alert("削除しました。");
            location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
    add_img(val) {
      if (this.media_box == 0) {
        this.media_box = 1;
      } else {
        this.media_box = 0;
      }
      this.target = val;
    },
    close() {
      this.media_box = 0;
    },

    delFormat() {
      if (confirm("このフォーマットを削除します。よろしいですか？")) {
        let params = new URLSearchParams();
        params.append("tool", this.tool);
        params.append("site_id", this.page_id);
        params.append("format_id", this.format_id);
        this.$axios
          .post(this.rt + "/api/tools01/delFormat.php", params)
          .then((res) => {
            console.log(res);
            if (res.data == "OK") {
              // alert("削除しました。");
              this.$router.push(`/tools01/${this.tool}/edit/${this.page_id}`);
            } else {
              alert(res.data);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },

    appendNumber() {
      this.item.text01_01 += '<div class="aiueo"></div>';
      $(".aiueo").text("aiueo");
    },

    delImg(val) {
      if (confirm("この画像を削除します。よろしいですか？")) {
        let params = new URLSearchParams();
        params.append("file_name", val);
        this.$axios
          .post(this.rt + "/api/delUserImg.php", params)
          .then((res) => {
            console.log(res);
            // alert("削除しました。");
            this.getUserImg();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    text01(val) {
      this.item.text01 = val;
    },
    text02(val) {
      this.item.text02 = val;
    },
    text03(val) {
      this.item.text03 = val;
    },
    text04(val) {
      this.item.text04 = val;
    },
    text_width(val) {
      this.overlay01_text_width = val;
    },

    handleClick(val, val2) {
      if (val.index == 0) {
        this.addType = 0;
      } else if (val.index == 1) {
        this.addType = 1;
      }
      console.log(val2);
    },

    addProduct(id, name, price) {
      console.log("add --- id:" + id + ", name:" + name + ", price:" + price);
      this.item.array01.push(id);
      this.products2.push(name + " ￥" + price);
      this.products3.push({ id: id, name: name, price: price });
      this.$forceUpdate();
    },

    delProduct(index) {
      console.log("del --- index:" + index);
      this.item.array01.splice(index, 1);
      this.products2.splice(index, 1);
      this.products3.splice(index, 1);
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.new-formats {
  .el-radio-group {
    .format {
      .el-radio {
        margin-bottom: 15px;
        .format-type-name {
          font-size: 16px;
          font-weight: bold;
          color: #4d4d4d;
        }
      }
      img {
        width: 180px;
      }
    }
  }
}
.format-select-box {
  display: flex;
  justify-content: space-around;
  .format {
    text-align: center;
  }
}
.ml {
  margin-left: 5px;
}
.product_edit_box {
  .products,
  .display {
    border: solid 1px #ccc;
    overflow: auto;
    padding: 5px 10px;
    .product {
      margin-top: 5px;
      cursor: pointer;
    }
  }
  .products {
    height: 100px;
  }
  .display {
    border: solid 1px #ccc;
    height: 150px;
  }
}
.el-checkbox-group {
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 10px;
}
.format-cover {
  background: #ddd;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .box02 {
    display: flex;
    align-items: center;
  }
  .el-button {
    margin-right: 30px;
  }
}
.media-box-back {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1000;
}
.media-box {
  width: 95%;
  height: 90%;
  background: #ddd;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1001;
  .media-box-inner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .close {
      position: absolute;
      top: 0.5%;
      right: 1%;
      cursor: pointer;
      font-weight: bold;
      font-size: 24px;
      z-index: 1100;
    }
    .file-select-label,
    .file-select-label2 {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 15%;
      margin: auto;
      width: 50%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: #ccc;
      font-weight: bold;
      .plus {
        font-weight: normal;
        text-align: center;
        font-size: 50px;
      }
    }
    .file-select-label {
      border: dashed 3px #ccc;
    }
    .file-select-label2 {
      background: #eee;
      color: white;
      cursor: unset;
    }
    .white-back {
      background-color: white;
      padding: 10px 0;
    }
    .btn {
      text-align: center;
      position: absolute;
      width: 20%;
      z-index: 1100;
      bottom: 10%;
    }
    .in {
      width: 100%;
      bottom: 0;
    }

    .el-tabs {
      width: 100%;
      height: 100%;
      overflow: scroll;
      .el-tabs__content {
        // height: 50vh;
        .el-tab-pane {
          margin-top: 70px;
          .img-list {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .img-box,
            .img-box-dummy {
              width: 300px;
              height: 300px;
              margin: 10px;
            }
            .img-box {
              position: relative;
              background: #ddd;
              cursor: pointer;
              .del {
                position: absolute;
                top: -4%;
                right: -4%;
                width: 25px;
                height: 25px;
                background: red;
                border-radius: 50%;
                z-index: 1050;
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                display: none;
              }
              img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: auto;
                width: auto;
                max-width: 100%;
                max-height: 100%;
                margin: auto;
              }
            }
          }
          input.file-select-input {
            display: none;
          }
        }
      }
    }
  }
}
.selected {
  outline: solid 5px #6495ed;
}
.show {
  display: flex !important;
}
.overlay2-editor {
  .tinymce-editor {
    .tox-tinymce {
      height: 100px !important;
    }
  }
}
</style>
