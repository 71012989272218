<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>{{ tool.mediaName }}へ<br class="sp-only">自動アップロードの設定</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>アドタスカルに登録されている成果データを自動で{{ tool.mediaName }}にアップロードされるように設定します。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p v-if="tool.codeKebab === 'google-cv'">３～１５分</p>
        <p v-else-if="tool.codeKebab === 'yahoo-cv'">５分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h4>
          【１】「
          <router-link to="/tools02/google-cv/accounts" target="_blank">Google広告アカウント＆プログラム情報</router-link>」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/01.png`" alt />
        <h4>【２】設定したいGoogle広告アカウントの「編集」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/02.png`" alt />
        <h4>【３】「自動アップロード用URL」をコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/03.png`" alt />
        <div
          class="kokokara"
        >--------------------------------<br class="sp-only">ここからGoogle広告に移動<br class="sp-only">--------------------------------</div>
        <h4>【４】設定したいGoogle広告のアカウントを開き、「ツール」→「測定」の「コンバージョン」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/04.png`" alt />
        <h4>【５】「アップロード」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/05.png`" alt />
        <h4>【６】「日程」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/06.png`" alt />
        <h4>
          【７】
          <img
            :src="`${rt}/img/manual/google-cv/no08/no16/13.png`"
            class="plus-button"
            alt
          />ボタンをクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/07.png`" alt />
        <h4>【８】「ソースの選択」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/08.png`" alt />
        <h4>【９】「HTTPS」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/14.png`" alt />
        <h4>【１０】アップロードのスケジュール設定を行います。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/09.png`" alt />
        <h4>【１１】「スケジュールを保存しました」と表示されたら「OK」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/10.png`" alt />
        <h4>【１２】「登録したURL」と「頻度」が表示されたら登録は完了です。</h4>
        <p class="red">※すぐに管理画面に反映されない場合もあります。しばらく待ってから確認してください。</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/11.png`" alt />
        <p>指定した時間になると、自動でアップロードしてくれます。</p>
        <h4>【１３】最低でも１日１回分は設定しましょう。</h4>
        <p>最多で１時間おきに設定できます。CV数が多い人は１時間おきに設定するのがオススメです。以下の画像のように、「0:00」「1:00」・・と1時間おきに24回設定していただく必要があります。</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/12.png`" alt />
        <p>これで、Google広告への自動アップロードの設定は完了です。</p>
        <p>これで、成果発生→Google広告へアップロードが全て自動化できました。</p>

        <h4>【１４】設定した時間になったらGoogleにアップロードされているか確認してみましょう。</h4>
        <p class="red">※下の画像にもある通り、実際にアップロードされる時間は、1分のときもあれば、20分、30分、40分などバラバラです。</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/15.png`" alt />

        <h4>【１５】「結果」をクリックしてアップロードの結果を確認してみましょう。</h4>
        <div class="mini-img" style="margin-bottom:50px;">
          <img :src="`${rt}/img/manual/google-cv/no08/no16/16.png`" alt />
        </div>
        <img :src="`${rt}/img/manual/google-cv/no08/no16/17.png`" alt />

        <p>エラーが発生していた場合は、以下のマニュアルを御覧ください。</p>
        <p>
          ⇒
          <router-link :to="`/manual/${tool.codeKebab}/07/22`">Google広告へのアップロード時のエラーと対処法</router-link>
        </p>
      </el-col>
      <el-col v-else-if="tool.codeKebab === 'yahoo-cv'">
        <h4>【１】アドタスカルとYahoo!JAPANビジネスIDを連携します。</h4>
        <p>以下のマニュアルに沿って、アドタスカルとYahoo!JAPANビジネスIDを連携してください。</p>
        <router-link
          :to="`/manual/yahoo-cv/04/04`"
        >https://tools.adtasukaru.com/manual/yahoo-cv/04/04</router-link>

        <h4>【２】アドタスカル管理画面にてYahoo!検索広告アカウントへの自動アップロード設定を行う。</h4>
        <p>以下のマニュアルに沿って、自動アップデートをONにし、アップロード時間の設定をおこなってください。</p>
        <router-link
          :to="`/manual/yahoo-cv/04/01`"
        >https://tools.adtasukaru.com/manual/yahoo-cv/04/01</router-link>

        <h4>【３】これで毎日指定した時間の00分に自動でアップロードしてくれます。</h4>
        <p>自動アップロードの場合、ファイル名が「adtasukaru-yahoo-cv.csv」になります。</p>
        <img :src="`${rt}/img/manual/yahoo-cv/no08/no16/01.png`" alt />
        <p>※自動アップロードの対象は直近２日＋本日分になります。それより過去の成果に関しては手動アップロードしてください。</p>
        <p>
          これで、Yahoo!検索広告への自動アップロードの設定は完了です。
          <br />成果発生→Yahoo!検索広告へアップロードが全て自動化できました。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.plus-button{
  width: 44px!important;
  margin-right: 10px;
  margin-bottom:-15px;
  margin-left: 0!important;
}
</style>
