<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>広告マネージャーへのアクセス方法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>アドタスカルの設定でよく利用する「広告マネージャ」へのアクセス方法を理解する。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>1分</p>
        <p>広告マネージャーへのアクセス方法として、以下の2つの手順を紹介します。</p>
        <ul>
          <li>ビジネスマネージャから広告マネージャへアクセスする方法</li>
          <li>Meta Business Suiteから広告マネージャへアクセスする方法</li>
        </ul>
        <h4>ビジネスマネージャから広告マネージャへアクセスする方法</h4>
        <h4>【１】ビジネスマネージャ上のハンバガーメニューから、「広告マネージャ」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no03/01.png`" alt="" />
        </div>
        <h4>【２】広告マネージャの画面に遷移したら、以下の赤枠の箇所から操作する広告アカウントを選択します。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no03/02.png`" alt="" />
        <p style="color: red; text-decoration: underline;">※よく使うページなので、ブックマークに登録しておくと便利です。</p>
        <h4>Meta Business Suiteから広告マネージャへアクセスする方法</h4>
        <h4>【１】Meta Business Suite上の左サイドメニューから、「すべてのツール」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no03/03.png`" alt="" />
        </div>
        <h4>【２】以下の画面が表示されるので、「広告マネージャ」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no03/04.png`" alt="" />
        <p style="color: red; text-decoration: underline;">※よく使うページなので、ブックマークに登録しておくと便利です。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.error {
  width: 100%;
  border-collapse: collapse;
  margin-left: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px){
    margin-left: 0!important;
  }
  & + .error {
    margin-top: 20px;
  }
  th,
  td {
    border: 1px solid;
    padding: 15px;
  }
  th {
    background-color: #e5e5e5;
  }
}
</style>
