<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（AFAD）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CommonDescription :systemType="'afad'" />
        <h4>
          【１】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/point-back`"
            >ASP自動連携設定</router-link
          >」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`"
          alt=""
        />
        <h4>【２】該当するASPの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no21/01.png`" alt="" />
        <h4>【３】以下の文言を担当者に連絡します。</h4>
        <p>
          -----------------------<br />
          アドタスカルのポイントバック連携、希望です。<br />
          テスト案件でのテストコード発行もお願いします。<br />
          <br />
          （【２】でコピーした通知URL）<br />
          -----------------------
        </p>

        <h4>【４】連携が完了すると、テスト案件用のURLが発行してもらえます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no08/no21/02.png`" alt="" />
        </div>

        <h4>
          【５】発行されたURLに「<span class="red">&amp;param1={{tool.clickIdName}}_xxx&amp;param2=test</span>」を追記してください。
        </h4>
        <h5>▼発行されたテストURL</h5>
        <p class="blue">https://ac.affi-ads.com/●●●/cl/?bId=●●●</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p class="blue">
          https://ac.affi-ads.com/●●●/cl/?bId=●●●<span class="red">&amp;param1={{tool.clickIdName}}_xxx&amp;param2=test</span>
        </p>

        <p>※自分で付けたパラメータを覚えておきましょう</p>
        <h4>
          【６】パラメータを付けたテストURLにアクセスし、テスト規定に従って購入を完了させてください。
        </h4>

        <h4>
          【７】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/auto-import-log`"
            >自動登録履歴</router-link
          >」を確認します。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`"
          alt=""
        />

        <h4>
          【８】数分後に、テストCVが登録されていれば問題なく連携完了です。
        </h4>
        <AutoImportLog 
          :aspId="'38'"
          :aspPromotionName="'test'"
        />
        <ul>
          <li>ASP：Self Leap</li>
          <li>
            CV時間：テストした時間になっているか？（多少の誤差はあり）
          </li>
          <li>プログラム名：-</li>
          <li>パラメータ：【５】で付けたパラメータと一致しているか？</li>
          <li>プログラムID： test</li>
          <li>報酬額：0</li>
          <li>
            インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です。
          </li>
        </ul>

        <h4>【９】連携完了</h4>
        <p>担当さんに一報入れましょう。これで連携は完了です。</p>

        <PointbackSettingDescription :aspId="'38'" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonDescription from "@/components/Manual/Tools02/Parts/CommonDescription.vue";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    CommonDescription,
    PointbackSettingDescription,
    AutoImportLog,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.blue {
  color: var(--blue);
}

</style>
