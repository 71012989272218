<template>
  <div class="tools01-copy-detail">
    <el-row>
      <el-col>
        <h2>フォーマット一覧</h2>
      </el-col>
    </el-row>
    <FormatList
      :type="0"
      :toolId="$store.getters.toolIdFromToolCode(copyPageTool)"
      :pageId="copyPageId"
      :buttonName="'コピー'"
      @formatDecide="copyFormatData"
    />
  </div>
</template>

<script>
import FormatList from "@/components/Ui/Tools01/FormatList.vue";

export default {
  components: {
    FormatList,
  },
  props: {
    tool: String,
    pageId: String,
    parameter: String,
    copyPageTool: String,
    copyPageId: String,
  },
  data() {
    return {
      list: [],
      formats: 0,
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  methods: {
    copyFormatData(formatId) {
      let params = new URLSearchParams();
      params.append("parameter", this.parameter);
      params.append("tool", this.tool);
      params.append("page_id", this.pageId);
      params.append("format_id", formatId);
      this.$axios
        .post(`${this.rt}/api/tools01/regFormatCopy.php`, params)
        .then((response) => {
          console.log(response);
          this.$store
            .dispatch("tools01/setFormatList")
            .then((response) => {
              console.log(response);
              history.go(-2);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  margin-top: 30px;
  input {
    width: 50%;
  }
}
</style>
