<template>
  <div>
    <div class="affiliate-link-provider-name-config">
      <el-row>
        <el-col>
          <h3>
            アフィリエイトリンクからの遷移先ページで表示する「紹介者名」の設定
          </h3>
          <p>
            アフィリエイトリンクをユーザーがクリックすると、その遷移先ページでは、あなたからの紹介だとユーザーがわかるように、紹介者名を表示させることができます。設定は任意です。
          </p>
          <span>紹介者名の表示設定：</span>
          <span>
            <span style="margin-right: 5px">OFF</span>
            <el-switch v-model="privilegeData.isProviderNameDisplay"></el-switch>
            <span style="margin-left: 5px">ON</span>
          </span>
          <span v-show="privilegeData.isProviderNameDisplay === true" style="margin-left: 5px;">（アフィリエイトリンクの遷移先ページに紹介者名が表示されます）</span>
          <span v-show="privilegeData.isProviderNameDisplay === false" style="margin-left: 5px;">（アフィリエイトリンクの遷移先ページに紹介者名が表示されません）</span>
          <table v-show="privilegeData.isProviderNameDisplay === true" class="af-pop">
            <tr>
              <th style="width: 30%">
                紹介者名<br />（全角10文字・半角20文字以内）
              </th>
              <td>
                <el-input v-model="privilegeData.providerName" />
              </td>
            </tr>
          </table>
          <div v-show="privilegeData.isProviderNameDisplay === true"
            v-for="(
              providerNameErrorMessage, index
            ) in validationErrorData.providerName"
            :key="index"
            class="red"
          >
            {{ providerNameErrorMessage }}
          </div>
          <div class="register-button-cover">
            <el-button @click="registerPrivilegeData" type="primary"
              >登録</el-button
            >
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <span>※表示箇所（SP時はオーバーレイ部分に表示されます）</span>
          <img :src="`${rt}/img/affiliate/link/01.gif`" style="margin-top: 50px;" alt=""/>
        </el-col>
      </el-row>
    </div>
    <AffiliateLinks />
    <div class="affiliate-link-privilege-config">
      <el-row>
        <el-row>
          <h3>特典設定</h3>
          <p>
            獲得したユーザーがいずれかのツールの「自動インポートに成功」したら、紹介者から特典をプレゼントすることができます。成約率向上、利用率向上のために特典の設定をおすすめします。
          </p>
          <p>
            設定できる特典は1つです。特典のダウンロード先のURLをご指定ください。
          </p>
          <p>
            特典の設定をONにしている間に、上記のアフィリエイトリンクから申し込みがあったユーザーに対して特典が付与されます。
          </p>
          <p>
            特典を設定した場合、以下の「※表示箇所」のようにアドタスカル公式ページ上に表示されます。
          </p>
        </el-row>
      </el-row>
      <el-row>
        <el-col>
          <div>
            <span>特典設定：</span>
            <span>
              <span style="margin-right: 5px">OFF</span>
              <el-switch v-model="givesPrivilege"></el-switch>
              <span style="margin-left: 5px">ON</span>
            </span>
            <span v-show="givesPrivilege === true"
              >（アフィリエイトリンクからの申し込みに対して特典が付与されます）</span
            >
            <span v-show="givesPrivilege === false"
              >（アフィリエイトリンクからの申し込みに対して特典が付与されません）</span
            >
          </div>
          <table v-show="givesPrivilege === true" class="af-pop">
            <!-- <tr>
              <th style="width: 30%">特典提供者名（上限30文字）</th>
              <td>
                <el-input v-model="privilegeData.providerName" />
              </td>
            </tr> -->
            <tr>
              <th style="width: 30%">特典名（上限50文字）</th>
              <td>
                <el-input v-model="privilegeData.privilegeName" />
              </td>
            </tr>
            <tr>
              <th>特典詳細説明（上限300文字）</th>
              <td>
                <el-input
                  v-model="privilegeData.description"
                  type="textarea"
                  :autosize="{ minRows: 6 }"
                />
              </td>
            </tr>
            <tr>
              <th>特典のダウンロードURL</th>
              <td>
                <el-input v-model="privilegeData.privilegeUrl" />
              </td>
            </tr>
          </table>
          <div class="register-button-cover">
            <el-button @click="registerPrivilegeData" type="primary"
              >更新</el-button
            >
          </div>
          <!-- <p>
            獲得したユーザーのアドタスカル管理画面上、および、アフィリエイターのアフィリエイトリンクからアドタスカル公式ページへ遷移した場合、アドタスカル公式ページ上に表示されます。
          </p> -->
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <span>※表示箇所（申込みフォームの下部に掲載されます）</span>
          <div class="mini-img">
            <img :src="`${rt}/img/affiliate/link/02.jpg`" alt=""/>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AffiliateLinks from "@/components/Affiliate/Link/AffiliateLinks.vue";

export default {
  name: "AffiliateLinkPrivilegeConfig",
  components: {
    AffiliateLinks,
  },
  props: {},
  data() {
    return {
      validationErrorData: {
        providerName: [],
      },
      givesPrivilege: false,
      privilegeData: {
        isProviderNameDisplay: false,
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  created() {
    //
    this.givesPrivilege = JSON.parse(
      JSON.stringify(this.$store.getters.userData.givesPrivilege)
    );

    //
    this.privilegeData = JSON.parse(
      JSON.stringify(this.$store.getters["affiliate/privilegeData"])
    );
  },
  methods: {
    registerPrivilegeData() {
      let params = new URLSearchParams();
      params.append(
        "gives_privilege",
        this.givesPrivilege === true ? "1" : "0"
      );
      params.append("privilege_data", JSON.stringify(this.privilegeData));
      this.$axios
        .post(
          `${this.rt}/api/affiliate/privilege/reg-privilege-data.php`,
          params
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            alert("更新しました。");
            this.$store.dispatch("setUserData");
            this.$store.dispatch("affiliate/setPrivilegeData");
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.af-pop {
  margin-top: 5px;
}
img{
    width: 95%;
    margin-left: 10px;
    border: 3px solid gray;
  }
  .mini-img{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    img {
      width: auto;
    }
  }
</style>
