<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
    <h2>YouTube広告の入稿手順</h2>

      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>YouTube広告の場合、直LPでの出稿がメインになるかと思います。</li>
          <li>{{tool.clickIdName}}を引継ぐための入稿方法があります。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】YouTube広告の広告入稿画面から説明します。YouTubeにアップロードした動画のURLを入力します。</h4>
        <img :src="`${rt}/img/manual/google-cv/no06/no05/01.png`" alt="" />
        <h4>【２】広告内容を入力します。</h4>
        <ol>
          <li>最終ページURL：広告主LPのURL（アフィリエイトリンクをクリックして、URLをコピペします）</li>
          <li>表示URL：広告主LPのドメイン</li>
          <li>行動を促すフレーズ：任意</li>
          <li>広告見出し：任意</li>
          <li>広告のURLオプション（詳細設定）をクリックする</li>
          <li class="red">トラッキングテンプレートにアフィリエイトリンクを入力する（※入力方法は【３】参照）</li>
        </ol>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no05/02.png`" alt="" />
        <h4>【３】アフィリエイトリンクの入力</h4>
        <p>ここが今回の最重要ポイントになります。</p>
        <h5>＜レントラックスの場合＞</h5>
        <h5>①アフィリエイトリンクが発行される</h5>
        <p>https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172</p>
        <h5>②レントラックスのクリックパラメータ「<span class="red">&amp;uix=</span>」を付ける</h5>
        <p>https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172<span class="red">&amp;uix=</span></p>
        
        <div class="blue-box"><router-link :to="`/manual/${tool.codeKebab}/06/06`" >▶レントラックス以外の<br class="sp-only">クリックパラメータはこちら</router-link></div>
        <h5>③クリックパラメータの次に「<span class="green">{{tool.clickIdName}}_{<span v-text="tool.clickIdName"></span>}</span>」を<span class="red2">そのまま</span>付ける</h5>
        <p>https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172<span class="red">&amp;uix=</span><span class="green">{{tool.clickIdName}}_{<span v-text="tool.clickIdName"></span>}</span></p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no05/03.png`" alt="" />
        <h5>④成果が発生すると<span class="green">{<span v-text="tool.clickIdName"></span>}</span>の部分に実際の{{tool.clickIdName}}が挿入される</h5>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no05/04.png`" alt="" />
        <p>これでYouTube広告の入稿は完了です。</p>
        <p>実際に、運用開始しましょう。</p>
        <p>
          成果が発生したら、「<router-link
            :to="`/manual/${tool.codeKebab}/07/00`"
            >ASPの成果データをダウンロードする</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
        ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.blue-box{
  border: solid 2px #4169e1;
  padding: 13px 40px;
  width: 50%;
  text-align: center;
  a{
    color: #4169e1;
  text-decoration: none;
  font-weight: bold;

  }
  @media screen and (max-width: 768px){
      width: 100%!important;
      padding: 10px;
  }
}

.blue-box:hover{
  background: #4169e1;
  transition: .4s;
  a{
    color: #fff;
  }
}



</style>
