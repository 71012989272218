<template>
  <div>
    <div>now → index: {{targetImgIndex}}, path: {{targetImgPath}}</div>
    <div v-for="(imgPath, imgIndex) in imageList" :key="imgIndex">
      <span :class="{selected: targetImgIndex === imgIndex}" @click="selected(imgIndex, imgPath)">{{imgPath}}</span>
    </div>
    <div style="text-align: center;margin-bottom: 5px;">
      <el-button @click="addImg" type="primary">メディアを追加</el-button>
    </div>
    <div style="text-align: center;">
      <el-button @click="delImg" type="danger">削除</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectImage",
  components: {
  },
  props: {},
  data() {
    return {
      imageList: [
        "AAA",
        "BBB",
        "CCC",
        "DDD",
        "EEE",
        "FFF",
        "GGG",
        "HHH",
        "III",
      ],
      targetImgIndex: 0,
      targetImgPath: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  methods: {
    selected(imgIndex, imgPath) {
      this.targetImgIndex = imgIndex;
      this.targetImgPath = imgPath;
    },
    addImg() {
      let imgTag = `<img src="${this.targetImgPath}">`;
      alert(imgTag+"を挿入！");
    },
    delImg() {
      if (confirm('Are you OK?')) {
        alert(this.targetImgPath+"を削除しました。");
      }else {
        alert('削除しました。');
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.selected {
  border: solid 1px blue;
}
</style>
