<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>{{ tool.mediaName }}へのアップロード時のエラーと対処法</h2>
      </el-col>
    </el-row>

    <el-row>
      <h3>エラー表示</h3>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <section>
          <h4>エラー：「このクリックはまだ処理中です。６時間たってからもう一度お試しください。」</h4>
          <div class="cause">
            <p>原因：広告がクリックされてから、６時間以上経過しないと、アップロードすることができません。</p>
          </div>
          <div class="remedy">
            <p>対処法：時間をおいて再アップロードしてください。</p>
          </div>
          <img :src="`${rt}/img/manual/google-cv/no07/no16/12.png`" alt />
        </section>
        <section>
          <h4>エラー：「このコンバージョンは管理しているアカウントのものではありません」</h4>
          <div class="cause">
            <p>原因：このアカウント以外の成果をアップロードしています。</p>
          </div>
          <div class="remedy">
            <p>対処法：違うアカウントにアップロードしなおしてください。</p>
          </div>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no07/no16/13.png`" alt />
          </div>
        </section>
        <section>
          <h4>エラー：「このクリックのGCLIDは変更されているか無効です。」</h4>
          <div class="cause">
            <p>原因：外的要因でGCLIDが書き換えられている可能性があります。</p>
          </div>
          <div class="remedy">
            <p>対処法：残念ながら対処法はありません。</p>
          </div>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no07/no16/14.png`" alt />
          </div>
        </section>
        <section>
          <h4>エラー：「このコンバージョン アクションはまだ処理中です。4時間経ってからもう一度お試しください。」</h4>
          <div class="cause">
            <p>原因：オフラインコンバージョン作成後、４時間経過しないとアップロードできません。</p>
          </div>
          <div class="remedy">
            <p>対処法：時間をおいて再アップロードしてください。</p>
          </div>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no07/no16/15.png`" alt />
          </div>
        </section>
        <section>
          <h4>エラー：「タイムスタンプおよび GCLIDが同じコンバージョンがすでに存在します。ファイルから削除するか、タイムスタンプが正しいことを確認してください」</h4>
          <div class="cause">
            <p>原因：過去にアップロードした成果の場合に発生するエラーになります。</p>
            <p>アドタスカルの自動アップロードを設定している場合、2日前～現在までの成果をアップロードするようになっています。そうなると、高い確率で成果が重複してアップロードされてしまうため、このエラーが発生します。</p>
            <p>ただ、重複した成果がアップロードされていないだけなので問題ございません。</p>
          </div>
          <div class="remedy">
            <p>対処法：何もしなくて大丈夫です。</p>
          </div>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no07/no16/16.png`" alt />
          </div>
        </section>
        <section>
          <h4>エラー：「列「Conversion Time」の値「○○○○」が無効です。[Conversion Value]列の値「○○○○」を解析できません。対象ファイルの言語や地域に合った形式で数値を入力してください。」</h4>
          <div class="cause">
            <p>原因：コンバージョン名に「カンマ（,）」が入っている場合に発生するエラーになります。</p>
          </div>
          <div class="remedy">
            <p>対処法：コンバージョン名から「カンマ（,）」を除いてください。アドタスカルのプログラム登録のオフラインCV名も修正してください。</p>
          </div>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no07/no16/17.png`" alt />
          </div>
        </section>
        <section>
          <h4>エラー：「この名前のコンバージョンアクションは関連付けられているアカウント内に存在しません」</h4>
          <div class="cause">
            <p>原因：オフラインコンバージョン名に誤りがある可能性が高いです。</p>
          </div>
          <div class="remedy">
            <p>対処法：一度、以下のマニュアルを参考にGoogleのCV名を確認お願いします。</p>
            <p><router-link :to="`/manual/${tool.codeKebab}/05/02`"
              >オフラインCV名のミスしない入力方法</router-link
            ></p>
          </div>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no07/no16/18.jpg`" alt />
          </div>
        </section>
        <section>
          <h4>エラー：「失敗しました。このスプレッドシートにはヘッダー行がありません」</h4>
          <div class="cause">
            <p>原因：ASPのCSVをアップロードしているので、発生したエラーになります。</p>
          </div>
          <div class="remedy">
            <p>対処法：GoogleフォーマットのCSVをアップロードしてください。</p>
          </div>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no07/no16/19.png`" alt />
          </div>
        </section>
      </el-col>
      <el-col v-if="tool.codeKebab === 'yahoo-cv'">
        <table>
          <tr class="error" valign="top">
            <th>エラー</th>
            <td class="colon">:</td>
            <td>「同じ発生日時のコンバージョンが存在します。別のコンバージョンとしてアップロードする場合は異なる発生日時を指定してください。」</td>
          </tr>
          <tr valign="top">
            <th>原因</th>
            <td class="colon">:</td>
            <td>過去にアップロードした成果の場合に発生するエラーになります。<br />アドタスカルの自動アップロードを設定している場合、2日前～現在までの成果をアップロードするようになっています。そうなると、高い確率で成果が重複してアップロードされてしまうため、このエラーが発生します。<br />ただ、重複した成果がアップロードされていないだけなので問題ございません。</td>
          </tr>
          <tr valign="top">
            <th>対処法</th>
            <td class="colon">:</td>
            <td>何もしなくて大丈夫です。</td>
          </tr>
        </table>

        <table>
          <tr class="error">
            <th>エラー</th>
            <td class="colon">:</td>
            <td>「異なるアカウントで発生したYCLIDが指定されています。」</td>
          </tr>
          <tr>
            <th>原因</th>
            <td class="colon">:</td>
            <td>アップロードしたデータとは異なるYahoo広告アカウントで発生したYCLIDが指定されています。</td>
          </tr>
          <tr valign="top">
            <th>対処法</th>
            <td class="colon">:</td>
            <td>正しいアカウントでインポートしてください。（なお、同一ASPの同一案件を複数のアカウントにプログラム登録した場合、登録した全てのアカウントにインポートされます。そのため、このエラーが発生しますが、CV計測に影響はありません。）</td>
          </tr>
        </table>

        <table>
          <tr class="error">
            <th>エラー</th>
            <td class="colon">:</td>
            <td>「存在しないコンバージョン名が指定されています。」</td>
          </tr>
          <tr valign="top">
            <th>原因</th>
            <td class="colon">:</td>
            <td>①Yahoo広告でオフラインCVを作成していない、または、②アドタスカルへのオフラインCV名の入力が間違えている、のどちからが原因と考えられます。</td>
          </tr>
          <tr valign="top">
            <th rowspan="2">対処法</th>
            <td class="colon" rowspan="2">:</td>
            <td>
              ①Yahoo広告で
              <router-link to="/manual/yahoo-cv/03/02">オフラインコンバージョンを作成</router-link>してください。
            </td>
          </tr>
          <tr>
            <td>
              ②
              <router-link to="/manual/yahoo-cv/05/01">アドタスカルにプログラム情報を登録する</router-link>、または、
              <router-link to="/manual/yahoo-cv/05/02">オフラインCV名のミスしない入力方法</router-link>を参照して、オフラインCV名を正しく入力してください。
            </td>
          </tr>
        </table>
        <table>
          <tr class="error">
            <th>エラー</th>
            <td class="colon">:</td>
            <td>「新規設定したコンバージョン測定は数時間後、インポート可能になります。」</td>
          </tr>
          <tr>
            <th>原因</th>
            <td class="colon">:</td>
            <td>コンバージョン測定を新規設定してから、一定の時間を経過していないためです。</td>
          </tr>
          <tr valign="top">
            <th>対処法</th>
            <td class="colon">:</td>
            <td>4～5時間程度経過してからインポートをしてください。なお、自動インポートを設定している場合は、インポート可能になった時点でインポートされます。</td>
          </tr>
        </table>
        <table>
          <tr class="error">
            <th>エラー</th>
            <td class="colon">:</td>
            <td>「クリックが発生してから24時間経過後、インポートが可能になります。」</td>
          </tr>
          <tr>
            <th>原因</th>
            <td class="colon">:</td>
            <td>クリックが発生してから、一定の時間を経過していないためです。</td>
          </tr>
          <tr valign="top">
            <th>対処法</th>
            <td class="colon">:</td>
            <td>実際には1～2時間程度でインポート可能になります。時間を置いて再インポートしてください。なお、自動インポートを設定している場合は、インポート可能になった時点でインポートされます。</td>
          </tr>
        </table>

        <table>
          <tr class="error">
            <th>エラー</th>
            <td class="colon">:</td>
            <td>「テンプレートに入力された項目列は削除しないでください。」</td>
          </tr>
          <tr>
            <th>原因</th>
            <td class="colon">:</td>
            <td>コンバージョン名に「カンマ（,）」が入っている場合に発生するエラーになります。</td>
          </tr>
          <tr valign="top">
            <th>対処法</th>
            <td class="colon">:</td>
            <td>コンバージョン名から「カンマ（,）」を除いてください。アドタスカルのプログラム登録のオフラインCV名も修正してください。</td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <p>Google広告アカウントへアップロードが完了後、概ね、数時間以内にすぐ反映されます。</p>
        <p>ただ、新しく作成したばかりのコンバージョンアクションに関しては少し時間がかかることが多いようです、</p>
        <p>基本的には半日以内には反映されるはずです。</p>
      </el-col>
      <el-col v-if="tool.codeKebab === 'yahoo-cv'">
        <p>エラーが発生せず、正常にアップロードできたことを確認したら完了です。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 50px;
  tr {
    th {
      width: 50px;
      font-weight: normal;
      text-align: left;
    }
    .colon {
      width: 10px;
      text-align: left;
    }
  }
  .error {
    th,
    td {
      font-weight: bold;
      padding-bottom: 10px;
    }
  }
}
</style>
