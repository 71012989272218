<template>
  <div class="tools02-report-import-manual-send-conversion-event-system">
    <el-collapse accordion>
      <el-collapse-item :title="`手動でAPI送信する${forAdminText}`">
        <el-tooltip class="item" effect="dark" placement="right"
          ><div slot="content">
            <ul>
              <li>（※）は、必須項目です。</li>
            </ul>
          </div>
          <i class="el-icon-question"></i
        ></el-tooltip>
        <!-- ↓↓ Inputs ↓↓ -->
        <el-row
          v-if="tool.codeKebab !== 'squadbeyond-cv'"
          type="flex"
          align="middle"
        >
          <el-col :span="3"
            >{{ g_getTools02TagCategoryName(toolId) }}名（※）</el-col
          >
          <el-col :span="10">
            <el-select
              v-model="tools02TagId"
              placeholder="選択してください"
              style="width: 75%"
              clearable
            >
              <el-option
                v-for="tools02TagData in tools02TagList"
                :key="tools02TagData.id"
                :label="tools02TagData.name"
                :value="tools02TagData.id"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <TiktokInputs
          v-if="tool.codeKebab === 'tiktok-cv'"
          @sendConversionEvent="sendConversionEvent($event)"
          :resultData="resultData"
          :type="type"
        />
        <LineInputs
          v-else-if="tool.codeKebab === 'line-cv'"
          @sendConversionEvent="sendConversionEvent($event)"
          :resultData="resultData"
          :type="type"
          :conversionDataType="conversionDataType"
        />
        <YdaInputs
          v-else-if="tool.codeKebab === 'yda-cv'"
          @sendConversionEvent="sendConversionEvent($event)"
          :resultData="resultData"
          :type="type"
        />
        <SquadbeyondInputs
          v-else-if="tool.codeKebab === 'squadbeyond-cv'"
          @sendConversionEvent="sendConversionEvent($event)"
          :resultData="resultData"
          :type="type"
        />
        <!-- ↑↑ Inputs ↑↑ -->
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TiktokInputs from "@/components/Tools02/Report/Import/ManualSendConversionEventSystem/TiktokInputs.vue";
import LineInputs from "@/components/Tools02/Report/Import/ManualSendConversionEventSystem/LineInputs.vue";
import YdaInputs from "@/components/Tools02/Report/Import/ManualSendConversionEventSystem/YdaInputs.vue";
import SquadbeyondInputs from "@/components/Tools02/Report/Import/ManualSendConversionEventSystem/SquadbeyondInputs.vue";

export default {
  name: "tools02-report-import-manual-send-conversion-event-system",
  components: {
    TiktokInputs,
    LineInputs,
    YdaInputs,
    SquadbeyondInputs,
  },
  props: {
    toolId: String,
    type: String,
    conversionDataType: String,
  },
  data() {
    return {
      tools02TagId: "",
      resultData: undefined,
      forAdminText: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
    ...mapGetters("tools02", []),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },
    tools02TagList() {
      return this.$store.getters["tools02/allTagListInTool"](this.tool.id);
    },
  },
  watch: {},
  created() {
    //
    if (this.type === "ctrl") {
      this.forAdminText = "（管理者用）";
    } else {
      // no action.
    }

    //
    if (this.conversionDataType === "handWriting") {
      this.forAdminText += "（手書き）";
    } else {
      // no action.
    }
  },
  methods: {
    sendConversionEvent(payload) {
      //
      let tools02TagId = "0";
      if (this.tools02TagId !== "") {
        tools02TagId = this.tools02TagId;
      } else {
        if (this.tool.codeKebab === "squadbeyond-cv") {
          // ok.
        } else {
          alert(
            `${this.g_getTools02TagCategoryName(
              this.toolId
            )}を選択してください。`
          );
          return;
        }
      }

      //
      let params = new URLSearchParams();
      params.append("tool_id", this.tool.id);
      params.append("tools02_tag_id", tools02TagId);
      params.append("conversion_data_type", this.conversionDataType);
      params.append("conversion_data", JSON.stringify(payload.conversionData));
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/conversion-api/manual-send-conversion-event`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.resultData = response.data.resultData;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
