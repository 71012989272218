<template>
  <div class="manual-tools02-contents">

<h2>
  プログラム情報登録<br class="sp-only">（JAnet）
</h2>
<el-row>
  <el-col>
    <h4>【１】<a href="https://j-a-net.jp/" target="_blank">JAnetの管理画面</a>にログインし、「広告検索」をクリックします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no15/01.png`" alt="" />

            <h4>【２】プログラム名を入力して「この条件で探す」をクリックします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no15/02.png`" alt="" />

            <h4>【３】プログラム名とサンクスIDをコピーします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no15/03.png`" alt="" />

                    <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>
<h4>【４】アドタスカルのASPプログラム名とプログラムIDに貼り付けます。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no15/04.png`" alt="" />

            <h3>１つのプログラムに複数の成果地点がある場合</h3>
            <p>JANetには以下の案件のように、複数の成果地点があるプログラムがあります。
その場合、成果地点ごとにアドタスカルに登録してください。</p>
            <img :src="`${rt}/img/manual/google-cv/no05/no15/05.png`" alt="" />

            <h4>まず、成果地点①の入力をします。</h4>
            <p>ASPプログラム名は、他の成果地点と被らないように、プログラム名の後に成果地点名を入力します。ご自身が分かりやすい名前で大丈夫です。</p>
            <img :src="`${rt}/img/manual/google-cv/no05/no15/06.png`" alt="" />

            <h4>次に成果地点②の入力をします。手順は同じです。</h4>
            <p>ASPプログラム名は、他の成果地点と被らないように、プログラム名の後に成果地点名を入力します。ご自身が分かりやすい名前で大丈夫です。</p>
            <img :src="`${rt}/img/manual/google-cv/no05/no15/07.png`" alt="" />

            <h4>すべての成果地点の情報を登録したら完了です。</h4>




  </el-col>
</el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
