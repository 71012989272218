<template>
  <div class="ctrl-affiliate-home">
    <el-row>
      <el-col>
        <h2>アフィリエイト</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <LinkButtons />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import LinkButtons from "@/components/Ctrl/Affiliate/Home/LinkButtons.vue";

export default {
  data() {
    return {};
  },
  components: {
    LinkButtons,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
