<template>
  <div class="format0103">
    <div class="format01">
      <div class="format-cover">
        <div class="format-img-box">
          <p v-if="format.img == ''">画像が登録されていません</p>
          <img v-else :src="rt+uploadsFolder+format.img" class="format-img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Format0103",
  props: {
    format: [Array, Object]
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    uploadsFolder() {
      return this.$store.getters.uploadsFolder;
    }
  }
}
</script>

<style lang="scss" scoped>
.format01 {
  height: 450px;
  .format-cover {
    background: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    .format-img-box {
      position: relative;
      width: 350px;
      height: 450px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .format-img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: auto;
        width: auto;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  }
}
</style>
