<template>
  <div class="qa-qa-list">
    <el-collapse>
      <el-collapse-item class="parent" v-for="(qas, toolCode, index) in QAList" :key="index" :title="`${tools[toolCode].name2}についてよくある質問`">
        <el-collapse class="children" :key="index">
          <el-collapse-item v-for="(qa, index) in qas" :key="index" :title="qa.question">
            <div class="answer">{{qa.answer}}</div>
          </el-collapse-item>
        </el-collapse>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>

export default {
  name: 'QAQAList',
  components: {
  },
  props: {
  },
  data()  {
    return {
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
    QAList() {
      return this.$store.getters.QAList;
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.el-collapse-item__content) {
  padding-bottom: 0;
}
:deep(.el-collapse.children) {
  padding-left: 30px;
  .answer {
    padding-left: 30px;
  }
}
</style>
