<template>
  <div class="payment-bonus-point-list">
    <el-row>
      <el-col>
        <h3 class="back-gray">ボーナスポイント</h3>
        <p>
          アドタスカルの利用に際して、以下のボーナスポイントを付与しています。
        </p>
        <div class="table-cover">
          <table>
            <thead>
              <tr>
                <th><div>対象</div></th>
                <th><div>ツール</div></th>
                <th><div>ボーナスポイント名</div></th>
                <th style="width: 400px"><div>獲得条件</div></th>
                <th><div>獲得ポイント</div></th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="(bonusToolData, bonusToolDataIndex) in bonusToolList"
              >
                <template
                  v-for="(toolData, toolDataIndex) in bonusToolData.toolList"
                >
                  <template
                    v-for="(
                      bonusPointData, bonusDataIndex
                    ) in toolData.bonusPointList"
                  >
                    <tr :key="bonusPointData.bonusId">
                      <td
                        v-if="toolDataIndex === 0 && bonusDataIndex === 0"
                        :rowspan="bonusToolData.bonusNum"
                      >
                        <div v-if="bonusToolDataIndex === 0">全体</div>
                        <div v-else-if="bonusToolDataIndex === 1">
                          成果連携ツール
                        </div>
                      </td>
                      <td
                        v-if="bonusDataIndex === 0"
                        :rowspan="toolData.bonusPointList.length"
                      >
                        <div v-if="bonusToolDataIndex === 0">-</div>
                        <div v-else-if="bonusToolDataIndex === 1">
                          {{ toolData.name2 }}
                        </div>
                      </td>
                      <td>
                        <div>{{ bonusPointData.name }}</div>
                      </td>
                      <td>
                        <div class="left">
                          {{ bonusPointData.description }}
                        </div>
                      </td>
                      <td>
                        <div>{{ bonusPointData.point }}ポイント</div>
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
            </tbody>
          </table>
        </div>
        <div style="margin-top: 10px">
          ボーナスポイントは、獲得条件を満たしたら即時獲得となります。その旨の告知は、アドタスカルの管理画面にログインした際に画面右下に表示されます。
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "PaymentBonusPointList",
  components: {},
  props: {},
  data() {
    return {
      toolCodeList: [],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
    bonusToolList() {
      //↓↓ 手動で定義 ↓↓
      let toolCodeLists = [
        [],
        // ["tools"],
        ["googleCv", "facebookCv", "yahooCv", "microsoftCv", "tiktokCv", "ydaCv", "squadbeyondCv"],
      ];
      //↑↑ 手動で定義 ↑↑

      let bonusToolList = [];
      toolCodeLists.forEach((toolCodeList) => {
        let bonusNum = 0;
        let toolList = [];
        toolCodeList.forEach((toolCode) => {
          toolList.push(this.tools[toolCode]);
          bonusNum += this.tools[toolCode].bonusPointList.length;
        });
        let bonusToolData = {
          bonusNum: bonusNum,
          toolList: toolList,
        };
        bonusToolList.push(bonusToolData);
      });
      return bonusToolList;
    },
  },
};
</script>

<style lang="scss" scoped>
h3.back-gray {
  background-color: #808080;
  color: white;
  margin-bottom: 55px;
  margin-top: 40px;
}

h4 {
  margin: 20px 0 3px;
  background-color: #808080;
  color: white;
  padding: 5px 20px;
}
table {
  width: 100%;
  border: solid 1px #ddd;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: solid 1px #ddd;
      padding: 0 10px;
      @media screen and (max-width: 767px) {
        font-size: 12px;
      }
      div {
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  thead {
    tr {
      th {
        background-color: #f0f0f0;
      }
    }
  }
  tbody {
    tr {
      td {
        .left {
          justify-content: flex-start;
        }
      }
    }
  }
}
.table-cover {
  overflow: auto;
  table {
    width: 1000px;
  }
}
</style>
