<template>
  <div class="history-detail">
    <el-row>
      <el-col>
        <h2>購入・ポイント履歴 詳細</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <OrderData :orderId="orderId"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OrderData from '@/components/HistoryDetail/OrderData.vue'

export default {
  components: {
    OrderData
  },
  props: {
    orderId: String
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
table.typeB {
  tr {
    td {
      padding-left: 30px;
    }
  }
}
</style>
