<template>
  <div class="cv-detail">
    <el-row>
      <el-col class="right-button-cover">
        <el-button v-if="cv_id != 0" @click="del" type="danger">削除</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <table class="typeB">
          <tr>
            <th>コンバージョン名<br>または<br>マイクロコンバージョン名</th>
            <td>
              <div>
                <el-input type="text" v-model="item.name" />
              </div>
            </td>
          </tr>
          <tr>
            <th>1コンバージョンあたりの価格</th>
            <td>
              <div>
                <el-input
                  type="number"
                  v-model="item.price"
                  style="width:120px;margin-right:10px;"
                  min="0"
                />円
              </div>
            </td>
          </tr>
          <tr>
            <th>コンバージョンメモ<br>（任意でお使いください）</th>
            <td>
              <div>
                <el-input type="text" v-model="item.memo" />
              </div>
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row v-if="cv_id != 0">
      <el-col>
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>ツール名</th>
              <th>ページ名</th>
              <th>フォーマット名</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>{{item.tool}}</td>
              <td>{{item.page}}</td>
              <td>{{item.format}}</td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="text-align:center;">
        <el-button type="primary" @click="register">登録</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    cv_id: Number
  },
  data() {
    return {
      item: [],
      list: [],
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    }
  },
  created() {
    this.$axios
      .get(
        this.rt +
          "/api/mypage/getCvDetail.php?cv_id=" +
          this.cv_id
      )
      .then((res) => {
        console.log(res);
        this.item = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });

    //このCVに関連したフォーマットを取ってくる
    this.$axios
      .get(
        this.rt +
          "/api/mypage/getRelatedFormats.php?cv_id=" +
          this.cv_id
      )
      .then((res) => {
        console.log(res);
        this.list = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    register() {
      if (this.item.name == "") {
        alert("入力されていない項目があります。");
      } else {
        let params = new URLSearchParams();
        params.append("cv_id", this.cv_id);
        params.append("item", JSON.stringify(this.item));
        this.$axios
          .post(this.rt + "/api/mypage/regCvDetail.php", params)
          .then((res) => {
            console.log(res);
            if(res.data == "OK") {
              this.$router.go(-1);
            }else {
              alert(res.data);
              this.$router.push('/cv/');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    del() {
      if (confirm("このCVを削除します。よろしいですか？")) {
        let params = new URLSearchParams();
        params.append("cv_id", this.cv_id);
        this.$axios
          .post(this.rt + "/api/mypage/delCvDetail.php", params)
          .then((res) => {
            console.log(res);
            // alert('削除しました。');
            this.$router.go(-1);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
table.typeB {
  margin: 0;
}
</style>
