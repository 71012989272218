<template>
  <div class="manual-tools01-wordpress02">
    <el-row>
      <el-col>
        <h2 id="wordpress02">WordPressで特定ページでタグ設置を除外する方法</h2>
        <p>「<router-link v-scroll-to="`#wordpress01`" to="">WordPressで個別ページにタグを設置する方法</router-link>」では、1ページ1ページ、異なるタグを貼り付ける方法をご紹介しました。</p>
        <p>しかし、ページ数が多すぎる場合、「基本的に全てのページで同じフォーマットを表示して、特定のページだけ除外したい」という場合もあるかと思います。</p>
        <p>ここでは、その方法について説明していきます。</p>
        <h3>フッターにタグを設置する</h3>
        <p>FTPソフトを使用（ここではFilezillaを使用）してサーバーに接続し、テーマのディレクトリまで移動します。</p>
        <p>一般的には、「（ドメイン名）→public_html→wp-content→themes」から移動可能です。</p>
        <p>テーマ一覧が表示されるので、現在使用しているテーマのフォルダに入ります。</p>
        <p>（現在使用中のテーマは「WordPress管理画面」→「（左サイドメニューの）外観」から確認可能です。）</p>
        <img :src="`${rt}/img/manual/manual4-1-01.jpg`" alt="">
        <p>以下のようなファイル・フォルダ一覧画面が表示されていることを確認します。</p>
        <img :src="`${rt}/img/manual/manual4-1-02.jpg`" alt="">
        <p>上記ファイルを一式、PCにダウンロードしてください。</p>
        <p>ダウンロードしたファイルを編集・新規追加し、アップロードを行います。</p>
        <img :src="`${rt}/img/manual/manual4-1-03.jpg`" alt="">
        <h3>2種類のフッターを用意する</h3>
        <p>上記ファイルのうち、「footer.php」では、デフォルトで全ての投稿・固定ページのフッターの役割を担っています。</p>
        <p>こちらをコピーし、「footer-xxx.php」を作成してください。（「xxx」は任意の名前で構いません。今回は「footer-notag.php」で作成を進めます。）</p>
        <img :src="`${rt}/img/manual/manual4-1-04.jpg`" alt="">
        <p>「footer.php」を開き、&lt;/body>直前にアドタスカルのタグを貼り付け、保存します。</p>
        <img :src="`${rt}/img/manual/manual4-1-05.jpg`" alt="">
        <p>この過程で、タグの貼られてるフッター（footer.php）と、タグの貼られていないフッター（footer-notag.php）ができました。</p>
        <h3>テンプレートを作成する</h3>
        <p>続いて、タグの貼られていないフッター（footer-notag.php）を読み込むテンプレートを作成していきます。</p>
        <p>こちらを作成することにより、各投稿・固定ページの編集画面にて、テンプレート選択が可能になります。</p>
        <img :src="`${rt}/img/manual/manual4-1-06.jpg`" alt="">
        <el-tabs style="margin: 20px 0;">
          <el-tab-pane label="固定ページの場合">
            <p>「page.php」をコピーし、「page-xxx.php」を作成します。（「xxx」の部分は任意の名前で構いません。今回は「page-notag.php」で進めます。）</p>
            <img :src="`${rt}/img/manual/manual4-1-07.jpg`" alt="">
            <p>「page-notag.php」を開き、先頭に次の内容を追記します。</p>
            <p>「タグなしテンプレート」の部分は任意の名前で構いません。</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              :value="code"
              onclick="this.select();"
            />
            <img :src="`${rt}/img/manual/manual4-1-08.jpg`" alt="">
            <p>次に、最下部にある「&lt;?php get_footer(); ?>」を「&lt;?php get_footer("notag"); ?>」に書き換えます。</p>
            <img :src="`${rt}/img/manual/manual4-1-09.jpg`" alt="">
            <p>これで、このテンプレートでは先ほど作成した「footer-notag.php」が読み込まれるようになりました。</p>
            <p>（同様に、「&lt;?php get_footer("abcdefg"); ?>」と書くと、「footer-abcdefg.php」を読み込みます。）</p>
            <p>編集が完了したら、ファイルを保存します。</p>
          </el-tab-pane>
          <el-tab-pane label="投稿ページの場合">
            <p>「single.php」をコピーし、「single-xxx.php」を作成します。（「xxx」の部分は任意の名前で構いません。今回は「single-notag.php」で進めます。）</p>
            <img :src="`${rt}/img/manual/manual4-1-10.jpg`" alt="">
            <p>「single-notag.php」を開き、先頭に次の内容を追記します。</p>
            <p>「タグなしテンプレート」の部分は任意の名前で構いません。</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 3 }"
              :value="code"
              onclick="this.select();"
            />
            <img :src="`${rt}/img/manual/manual4-1-11.jpg`" alt="">
            <p>次に、最下部にある「&lt;?php get_footer(); ?>」を「&lt;?php get_footer("notag"); ?>」に書き換えます。</p>
            <img :src="`${rt}/img/manual/manual4-1-12.jpg`" alt="">
            <p>これで、このテンプレートでは先ほど作成した「footer-notag.php」が読み込まれるようになりました。</p>
            <p>（同様に、「&lt;?php get_footer("abcdefg"); ?>」と書くと、「footer-abcdefg.php」を読み込みます。）</p>
            <p>編集が完了したら、ファイルを保存します。</p>
          </el-tab-pane>
        </el-tabs>
        <h3>管理画面でタグを除外したいテンプレートを設定する</h3>
        <p>今回作成した「footer.php」「footer-notag.php」「page-notag.php」（「single-notag.php」）をアップロードします。</p>
        <p>（※アップロード前には、必ずファイルのバックアップを取るようにしてください。）</p>
        <p>WordPressの管理画面へ戻り、タグを除外したいページの編集画面へ移り、右サイドメニューの「ページ属性」にて、テンプレートが選択できるようになっていることを確認してください。</p>
        <img :src="`${rt}/img/manual/manual4-1-13.jpg`" alt="">
        <p>テンプレートを「タグなしテンプレート」に切り替え、「更新」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual4-1-14.jpg`" alt="">
        <p>ページを開き、ツールが作動しないことを確認してください。</p>
        <p><router-link v-scroll-to="`#wordpress01`" to="">WordPressで個別ページにタグを設置する方法</router-link></p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01Wordpress02',
  props: {
    tool: String
  },
  data() {
    return {
      code: '<?php\n/*\nTemplate Name: タグなしテンプレート\nTemplate Post Type: page\n*/\n?>',
      code2: '<?php\n/*\nTemplate Name: タグなしテンプレート\nTemplate Post Type: post, news\n*/\n?>'
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
