<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>
          {{ tool.mediaName }}に<br class="sp-only" />アップロードする
        </h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li v-if="tool.codeKebab === 'google-cv'">
            アドタスカルでエクスポートしたGoogle広告アップロード用データを実際に、アップロードすること。
          </li>
          <li v-if="tool.codeKebab === 'yahoo-cv'">
            アドタスカルでエクスポートしたYahoo!検索広告アップロード用データをYahoo!検索広告の管理画面にアップロードします。
          </li>
        </ul>
        <h3>【所要時間】</h3>
        <p>5分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <h4>
          【１】Google広告の管理画面から「ツール」→「測定」→「コンバージョン」の順にクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no16/01.png`" alt />
        <h4>【２】「アップロード」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no16/02.png`" alt />
        </div>
        <h4>
          【３】
          <img
            :src="`${rt}/img/manual/google-cv/no07/no16/03.png`"
            class="plus-button"
            alt
          />ボタンをクリックします。
        </h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no16/04.png`" alt />
        </div>
        <h4>【４】「ソースの選択」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no16/05.png`" alt />
        </div>
        <h4>【５】「ファイルをアップロード」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no16/06.png`" alt />
        </div>
        <p
          class="red"
          style="font-weight: bold; margin-top: 50px; margin-left: 30px"
        >
          【注意事項１】以下の画像のように、「顧客データに関する規約」の注意表示がでましたら、規約に同意する必要があります。
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no16/20.png`" alt />
        <p style="margin-left: 40px">
          <span style="font-weight: bold"
            >詳細は以下のマニュアルをご確認ください。</span
          ><br />
          ⇒<router-link :to="`/manual/${tool.codeKebab}/03/05`"
            >顧客データに関する規約に同意する</router-link
          >
        </p>

        <p style="font-weight: bold; margin: 40px 0 5px 30px">【注意事項２】</p>
        <p style="margin: 0 40px 20px 40px">
          「このファイルに含まれているデータは Google
          のポリシーに基づいて収集、共有されたものであることをここに確認します。」というチェックボックが表示された場合、<span
            class="red2"
            ><b>チェックしないでください。</b></span
          >（チェックしてアップロードするとエラーが発生します。）
        </p>

        <img :src="`${rt}/img/manual/google-cv/no07/no16/21.png`" alt />

        <h4>【６】アドタスカルからエクスポートしたCSVを選択します。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no16/07.png`" alt />
        </div>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no16/08.png`" alt />
        </div>
        <h4>【７】「プレビュー」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no16/09.png`" alt />
        <h4>
          【８】プレビュー結果が表示されます。「適用」をクリックしてください。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no16/10.png`" alt />
        <h4>【９】これでアップロード成功です。</h4>
        <p class="red2">最低でも毎日１回はアップロードをしてください。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no16/11.png`" alt />
      </el-col>
      <el-col v-if="tool.codeKebab === 'yahoo-cv'">
        <h4>
          【１】Yahoo広告の管理画面から「検索広告」→「ツール」の横にある「▼」→「コンバージョン測定」の順にクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no07/no16/01.png`" alt />

        <h4>
          【２】「オフラインコンバージョンのインポート」のタブをクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no07/no16/02.png`" alt />

        <h4>【３】「アップロード」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no07/no16/03.png`" alt />

        <h4>
          【４】「ファイルを選択」をクリックし、アップロードするファイルを指定し、「アップロード」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no07/no16/04.png`" alt />

        <h4>【５】ファイルがアップロードされます。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no07/no16/05.png`" alt />

        <p style="font-weight: bold">◎「ファイル名」について</p>
        <p>
          アドタスカルからエクスポートした手動アップロード用のファイル名は、「数字5桁＋数字6桁.csv」となります。また、「作成者」が表示されます。
        </p>
        <p>
          アドタスカルから自動でアップロードしたファイル名は、「adtasukaru-yahoo-cv.csv」となります。「作成者」は表示されません。
        </p>

        <h4>【６】エラーの発生確認をします。</h4>
        <p style="font-weight: bold">◎エラーが発生した場合</p>
        <p>
          「インポート結果（エラー）」の欄に「エラーファイルをダウンロード」と表示されます。エラーファイルをダウンロードし、エラー理由をご確認ください。
        </p>
        <img :src="`${rt}/img/manual/yahoo-cv/no07/no16/06.png`" alt />

        <p>
          「エラーメッセージ」の欄にエラー理由が記載されていますので、対応の上、再度アップロードをしてください。
        </p>
        <img :src="`${rt}/img/manual/yahoo-cv/no07/no16/07.png`" alt />
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="tool.codeKebab === 'google-cv'">
        <p>
          Google広告アカウントへアップロードが完了後、概ね、数時間以内にすぐ反映されます。
        </p>
        <p>
          ただ、新しく作成したばかりのコンバージョンアクションに関しては少し時間がかかることが多いようです、
        </p>
        <p>基本的には半日以内には反映されるはずです。</p>
      </el-col>
      <el-col v-if="tool.codeKebab === 'yahoo-cv'">
        <p>
          エラーが発生せず、正常にアップロードできたことを確認したら完了です。
        </p>
      </el-col>
      <el-col>
        <p class="red">
          ※エラーが発生していた場合は、以下のマニュアルを御覧ください。
        </p>
        <p>
          ⇒<router-link :to="`/manual/${tool.codeKebab}/07/22`"
            >{{
              tool.mediaName
            }}へのアップロード時のエラーと対処法</router-link
          >
        </p>
      </el-col>
    </el-row>
    <p>
      次に、「
      <router-link :to="`/manual/${tool.codeKebab}/07/17`"
        >アップロードした成果を確認する</router-link
      >」に進みましょう。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
table {
  margin-bottom: 50px;
  tr {
    th {
      width: 50px;
      font-weight: normal;
      text-align: left;
    }
    .colon {
      width: 10px;
      text-align: left;
    }
  }
  .error {
    th,
    td {
      font-weight: bold;
      padding-bottom: 10px;
    }
  }
}
.plus-button {
  width: 44px !important;
  margin-left: 0;
  margin-right: 10px;
  margin-bottom: -20px;
}
</style>
