<template>
  <div class="format0101">
    <div class="format01">
      <div class="zone01" :style="'background:'+format.color01+';padding-top:'+format.height01+'px;padding-bottom:'+format.height02+'px;'"><div v-html="format.text01"></div></div>
      <div class="zone02" :style="'background:'+format.color02+';padding-top:'+format.height04+'px;padding-bottom:'+format.height05+'px;'"><div v-html="format.text02"></div></div>
      <div class="wrap" :style="'background:'+format.color02+';padding-top:'+format.height06+'px;padding-bottom:'+format.height07+'px;'">
        <div class="zone03" :style="'background:'+format.color03+';width:'+format.width02+'px;height:'+format.height03+'px;border-radius:'+format.int01+'px;'"><div v-html="format.text03"></div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Format0101",
  props: {
    format: [Array, Object]
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.format01 {
  background: white;
  .zone01 {
    border: solid 1px #000;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .zone02 {
    border: solid 1px #000;
    box-sizing: border-box;
    border-top: none;
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .wrap {
    border: solid 1px #000;
    box-sizing: border-box;
    border-top: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .zone03 {
      border: solid 1px #000;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}
</style>
