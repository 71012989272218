<template>
  <div class="manual-home">
    <el-row>
      <el-col>
        <ManualTools01
          v-if="
            tool.codeKebab === 'popup01' ||
            tool.codeKebab === 'popup02' ||
            tool.codeKebab === 'overlay01' ||
            tool.codeKebab === 'overlay02'
          "
        />
        <ManualTools02
          v-else-if="
            tool.codeKebab === 'google-cv' ||
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'yahoo-cv' ||
            tool.codeKebab === 'microsoft-cv'
          "
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ManualTools01 from "@/components/Manual/Tools01.vue";
import ManualTools02 from "@/components/Manual/Tools02.vue";

export default {
  components: {
    ManualTools01,
    ManualTools02,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>