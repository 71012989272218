<template>
  <div v-loading="loading" class="affiliate-result-achievements">
    <h4>成果確認</h4>
    <el-row>
      <el-col>
        <table class="two-d">
          <thead>
            <tr>
              <th>ツール名／リンク先ページ</th>
              <th>クリック数</th>
              <th>獲得ユーザー数</th>
              <th>成約率</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>サマリー</th>
              <td>{{ totalData.clicks | localeNum }}</td>
              <td>{{ totalData.conversions | localeNum }}</td>
              <td>{{ totalData.cvr }}%</td>
            </tr>
            <tr
              v-for="(achievementData, index) in achievementList"
              :key="index"
            >
              <th>{{ achievementData.toolName }}</th>
              <td>{{ achievementData.clicks | localeNum }}</td>
              <td>{{ achievementData.conversions | localeNum }}</td>
              <td>{{ achievementData.cvr }}%</td>
            </tr>
          </tbody>
        </table>
        <!-- 
        <p>
          クリック数は、同一のIPアドレスからクリック、かつ、「utm_source」「utm_medium」が同じ場合は、アフィリエイトリンクがクリックされても重複計上されません。獲得ユーザー数は、使用したアフィリエイトリンクを基準に表示しています。最終のコンバージョンページが基準ではありません。
        </p>
        -->
        <p>
          クリック数は、同一のIPアドレスからクリック、かつ、「utm_source」「utm_medium」が同じ場合は、アフィリエイトリンクがクリックされても重複計上されません。獲得ユーザー数は、使用したアフィリエイトリンクを基準に表示しています。最終のコンバージョンページが基準ではありません。ユーザーがアフィリエイトリンクを閲覧・クリックする媒体によって、アフィリエイトリンクのクリック時に自動でリダイレクトされ、クリック数が重複計上されることがあります。成果の計測モレはございません。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateResultAchievements",
  components: {},
  props: {
    dateRange: Array,
  },
  data() {
    return {
      totalData: {},
      achievementList: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    dateRange: {
      handler(newDateRange) {
        this.getAchievementData(newDateRange);
      },
      immediate: true,
    },
  },
  methods: {
    getAchievementData(dateRange) {
      this.loading = true;
      this.$axios
        .get(
          `${
            this.rt
          }/api/affiliate/get-achievement-data.php?date_range=${encodeURIComponent(
            JSON.stringify(dateRange)
          )}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.totalData = response.data.totalData;
            this.achievementList = response.data.achievementList;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  th,
  td {
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
</style>
