<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>自動登録履歴の確認</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】「自動登録履歴」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no14/01.png`" alt="" />
        <template v-if="tool.codeKebab === 'facebook-cv'">
          <p style="color: red;">※「自動登録履歴」は媒体に関わらず統一なので、他の成果連携ツールの「自動登録履歴」から入っても大丈夫です</p>
          <h4>【２】アドタスカルと連携しているASPから成果が発生すると登録されます。</h4>
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no14/05.png`" alt="" />
          <h4>【３】インポート状況</h4>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no14/02.png`" alt="" />
          </div>
          <p>問題なくアドタスカルにインポートされています。</p>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no14/06.png`" alt="" />
          </div>
          <p>エラーが表示された場合は、各エラーをクリックすると原因と対処法のページに飛びます。</p>
        </template> 
        <template v-else>
          <h4>【２】ポイントバック連携が完了しているASPから成果が発生すると登録されます。</h4>
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no14/05.png`" alt="" />
          <h4>【３】インポート状況</h4>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no08/no14/02.png`" alt="" />
          </div>
          <p>問題なくアドタスカルにインポートされています。</p>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no14/03.png`" alt="" />
          </div>
          <p>パラメータに「{{tool.clickIdName}}_」がないと表示されるエラーです。</p>
          <h5>対処方法</h5>
          <ul>
            <li>自動タグがオンになっているか確認してください。</li>
            <li>パラメータ引継ぎ設定が正常に行われているか確認してください。</li>
          </ul>
          <div class="mini-img-left">
            <img :src="`${rt}/img/manual/google-cv/no08/no14/04.png`" alt="" />
          </div>
          <p>プログラム情報が正確に登録されていないと表示されるエラーです。</p>
          <h5>対処方法</h5>
          <p>「プログラム名/プログラムID」欄とプログラム情報と「プログラム名とプログラムID」が一致しているか確認してください。</p>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
