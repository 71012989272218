import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import store from '@/store'
import router from '@/router'
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    accounts: [],
    accountInitialValues: {},
    accountTypeNames: [],
    withTrashedPromotionList: [],
    promotionInitialValues: {},
    subscriptionList: [],
    parameterPassingLogTypeList: [],

    // -------------------------- ↓↓ LINE ↓↓ --------------------------
    tagList: [],
    unitList: [],
    // -------------------------- ↑↑ LINE ↑↑ --------------------------

    // ------------- ↓↓ Facebook専用（いずれ消える予定） ↓↓ -------------
    units: [],
    withTrashedFacebookUnitList: [],
    unitInitialValues: {},
    pixels: [],
    withTrashedFacebookPixelList: [],
    pixelInitialValues: {},
    // ------------- ↑↑ Facebook専用（いずれ消える予定） ↑↑ -------------

    bussinessAccountList: [],
    bussinessAccountInitialData: {},
    autoImportCodes: [],
    initialSettingTutorialStatuses: [],
    facebookEventList: [],
    tiktokEventList: [],
    cartSystemSubscriptionList: [],
    cartSystemProductList: [],
    importResultDefinitionList: [],
  },
  getters: {
    // accounts(state) {
    //   return state.accounts;
    // },
    accounts: (state) => (toolId) => {
      let accountListInTool = state.accounts.filter(function (accountData) {
        return accountData.toolId === String(toolId);
      });
      return accountListInTool;
    },
    accountData: (state) => (accountId) => {
      if (accountId === '0') {
        return state.accountInitialValues;
      } else {
        return state.accounts.find(account => account.accountId === accountId);
      }
    },
    accountTypeNames(state) {
      return state.accountTypeNames;
    },
    accountTypeNames2(state) {
      let accountTypeNames = JSON.parse(JSON.stringify(state.accountTypeNames));
      let accountTypeNames2 = [];
      accountTypeNames.forEach(accountType => {
        accountTypeNames2[accountType.accountType] = accountType.accountTypeName;
      });
      return accountTypeNames2;
    },
    promotionList(state) {
      let promotionList = state.withTrashedPromotionList.filter(function (promotionData) {
        return promotionData.enable === true;
      });
      return promotionList;
    },
    withTrashedPromotionList(state) {
      return state.withTrashedPromotionList;
    },
    promotions(state, getters) {
      return getters.promotionList;
    },
    promotionListByToolId: (state, getters) => (toolId) => {
      let allPromotionList = JSON.parse(JSON.stringify(getters.promotionList));
      let promotionList = [];
      allPromotionList.forEach(promotionData => {
        if (promotionData.toolId === toolId) {
          promotionList.push(promotionData);
        } else {
          //no action.
        }
      });
      return promotionList;
    },
    promotionsInTool: (state, getters) => (toolId) => {
      return getters.promotionListByToolId(toolId);
    },
    promotionsInAccount: (state, getters) => (toolId, accountId) => {
      let promotionsInTool = getters.promotionsInTool(toolId);
      let promotionsInAccount = [];
      promotionsInTool.forEach(promotion => {
        if (promotion.accountId === accountId) {
          promotionsInAccount.push(promotion);
        } else {
          //no action.
        }
      });
      return promotionsInAccount;
    },
    promotionData: (state, getters) => (promotionId) => {
      let promotionList = getters.promotionList;
      return promotionList.find(promotionData => promotionData.promotionId === promotionId);
    },
    withTrashedPromotionData: (state, getters) => (promotionId) => {
      return getters.withTrashedPromotionList.find(promotionData => promotionData.promotionId === promotionId);
    },
    subscriptionList(state) {
      return state.subscriptionList;
    },
    subscriptionData: (state) => (toolId, dateStringJaJp) => {
      //
      let subscriptionData = state.subscriptionList.filter((subscriptionData) => {

        //
        let subscriptionDate = new Date(subscriptionData.startDate);

        //
        if (
          subscriptionData.toolId === toolId &&
          subscriptionDate.toLocaleDateString("ja-JP") ===
          dateStringJaJp
        ) {
          return true;
        } else {
          return false;
        }
      });
      // console.log(subscriptionData);

      return subscriptionData;
    },
    subscriptionHistoryList: (state, getters, rootState, rootGetters) => (toolId) => {
      //
      let days = rootGetters.constData.tools02.subscription.listDays;

      //
      let subscriptionHistoryList = [];
      for (let i = 0; i < days; i++) {
        let date = new Date();
        date.setDate(date.getDate() - i);

        // 
        let subscriptionData = getters.subscriptionData(toolId, date.toLocaleDateString("ja-JP"));

        //
        let subscriptionHistoryData = {
          toolId: subscriptionData.toolId,
          date: date.toLocaleDateString("ja-JP"),
          validity: false,
        };
        if (subscriptionData.length > 0) {
          subscriptionHistoryData.validity = true;
        } else {
          // no action.
        }

        //
        subscriptionHistoryList.push(subscriptionHistoryData);
      }

      //
      return subscriptionHistoryList;
    },
    parameterPassingLogTypeList(state) {
      return state.parameterPassingLogTypeList;
    },
    parameterPassingLogTypeData: (state) => (typeId) => {
      return state.parameterPassingLogTypeList.find(typeData => typeData.id === typeId);
    },

    // --------------- ↓↓ Facebook専用（いずれ消える予定） ↓↓ ---------------
    pixels(state) {
      return state.pixels;
    },
    pixelDetail: (state) => (pixelId) => {
      if (pixelId === '0') {
        return state.pixelInitialValues;
      } else {
        return state.pixels.find(pixel => pixel.pixelId === pixelId);
      }
    },
    pixelDetailByPixelIdForApi: (state) => (pixelIdForApi) => {
      return state.pixels.find(pixel => pixel.pixelIdForApi === pixelIdForApi);
    },
    withTrashedFacebookPixelDataByPixelIdForApi: (state) => (pixelIdForApi) => {
      return state.withTrashedFacebookPixelList.find(data => data.pixelIdForApi === pixelIdForApi);
    },
    units(state) {
      return state.units;
    },
    unitsInTool: (state) => (toolId) => {
      let units = JSON.parse(JSON.stringify(state.units));
      let unitsInTool = [];
      units.forEach(unit => {
        if (unit.toolId === toolId) {
          unitsInTool.push(unit);
        } else {
          //no action.
        }
      });
      return unitsInTool;
    },
    unitDetail: (state) => (unitId) => {
      if (unitId == 0) {
        return state.unitInitialValues;
      } else {
        return state.units.find(unit => unit.unitId == unitId);
      }
    },
    unitDataByToken: (state) => (token) => {
      return state.units.find(unit => unit.unitToken === token);
    },
    withTrashedFacebookUnitDataByToken: (state) => (token) => {
      return state.withTrashedFacebookUnitList.find(withTrashedUnitData => withTrashedUnitData.unitToken === token);
    },
    unitDataByName: (state) => (name) => {
      return state.units.find(unit => unit.unitName === name);
    },
    // --------------- ↑↑ Facebook専用（いずれ消える予定） ↑↑ ---------------

    // -------------------------- ↓↓ それ以外 ↓↓ --------------------------
    tagList(state) {
      return state.tagList;
    },
    tagListInTool: (state) => (toolId, accountId) => {
      toolId = String(toolId);
      let tagListInTool = state.tagList.filter(tagData => tagData.toolId === toolId && tagData.accountId === accountId);
      return tagListInTool;
    },
    allTagListInTool: (state) => (toolId) => {
      toolId = String(toolId);
      let allTagListInTool = state.tagList.filter(tagData => tagData.toolId === toolId);
      return allTagListInTool;
    },
    tagData: (state, getters) => (toolId, tagId) => {
      let tagData = undefined;
      if (toolId === '8') {
        const facebookPixelData = getters.pixelDetail(tagId);

        //
        tagData = {
          name: facebookPixelData.pixelName,
          mediaTagId: facebookPixelData.pixelIdForApi,
          accessToken: facebookPixelData.accessToken,
          testCode: facebookPixelData.testCode,
        };
      } else {
        tagData = state.tagList.find(tagData => tagData.id === tagId);
      }
      return tagData;
    },
    unitList(state) {
      return state.unitList;
    },
    unitListInTool: (state) => (toolId) => {
      toolId = String(toolId);
      let unitListInTool = state.unitList.filter(unitData => unitData.toolId === toolId);
      return unitListInTool;
    },
    unitData: (state) => (unitId) => {
      return state.unitList.find(unitData => unitData.id === unitId);
    },
    // -------------------------- ↑↑ それ以外 ↑↑ --------------------------

    autoImportCodes(state) {
      return state.autoImportCodes;
    },
    cartAutoImportCodes(state) {
      return state.autoImportCodes.filter(autoImportCodes => autoImportCodes.isCartSystem === '1');
    },
    initialSettingTutorialStatuses(state) {
      return state.initialSettingTutorialStatuses;
    },
    bussinessAccountList(state) {
      return state.bussinessAccountList;
    },
    bussinessAccountData: (state) => (bussinessAccountId) => {
      if (bussinessAccountId === '0') {
        return state.bussinessAccountInitialData;
      } else {
        return state.bussinessAccountList.find(bussinessAccountData => bussinessAccountData.bussinessAccountId === bussinessAccountId);
      }
    },
    toolList(state, getters, rootState, rootGetters) {
      const toolList = rootGetters.toolList.filter(toolData => toolData.consumptionType === '2' && toolData.toolId !== '9' && toolData.toolId !== '13');
      return toolList;
    },
    cartIncludedToolList(state, getters, rootState, rootGetters) {
      const toolList = rootGetters.toolList.filter(toolData => toolData.consumptionType === '2' && toolData.toolId !== '9');
      return toolList;
    },
    toolListInTopPageOrder(state, getters) {
      let toolList = getters.toolList.sort(function (a, b) {
        return a.topPage - b.topPage;
      });
      return toolList;
    },
    sbExcludedToolListInTopPageOrder(state, getters) {
      let toolList = getters.toolListInTopPageOrder.filter(
        (toolData) => toolData.toolId !== "16" // SBは削除
      );
      return toolList;
    },
    cartIncludedToolListInTopPageOrder(state, getters) {
      let cartIncludedToolList = getters.cartIncludedToolList.sort(function (a, b) {
        return a.topPage - b.topPage;
      });
      return cartIncludedToolList;
    },
    facebookEventList(state) {
      return state.facebookEventList;
    },
    facebookEventDataByEventName: (state) => (eventName) => {
      return state.facebookEventList.find(facebookEventData => facebookEventData.eventName === eventName);
    },
    tiktokEventList(state) {
      return state.tiktokEventList;
    },
    tiktokEventData: (state) => (id) => {
      return state.tiktokEventList.find(tiktokEventData => tiktokEventData.id === id);
    },
    tiktokEventDataByEvent: (state) => (event) => {
      return state.tiktokEventList.find(tiktokEventData => tiktokEventData.event === event);
    },
    cartSystemSubscriptionList(state) {
      return state.cartSystemSubscriptionList;
    },
    activeCartSystemSubscriptionData(state) {
      let cartSystemSubscriptionList = state.cartSystemSubscriptionList;
      return cartSystemSubscriptionList.find(cartSystemSubscriptionData => cartSystemSubscriptionData.statusId === '1');
    },
    applyingCartSystemSubscriptionData(state) {
      let cartSystemSubscriptionList = state.cartSystemSubscriptionList;
      return cartSystemSubscriptionList.find(cartSystemSubscriptionData => cartSystemSubscriptionData.startDate === null && cartSystemSubscriptionData.endDate === null);
    },
    activeContinuationCartSystemSubscriptionData(state) {
      let cartSystemSubscriptionList = state.cartSystemSubscriptionList;
      return cartSystemSubscriptionList.find(cartSystemSubscriptionData => cartSystemSubscriptionData.statusId === '8');
    },
    cartSystemProductList(state) {
      return state.cartSystemProductList;
    },
    cartSystemProductData: (state) => (id) => {
      return state.cartSystemProductList.find(cartSystemProductData => cartSystemProductData.id === id);
    },
    importResultDefinitionList(state) {
      return state.importResultDefinitionList;
    },
    importResultDefinitionData: (state) => (resultCode) => {
      return state.importResultDefinitionList.find(importResultDefinitionData => importResultDefinitionData.code === resultCode);
    },
  },
  mutations: {
    setAccountTypeNames(state, payload) {
      state.accountTypeNames = payload;
    },
    setAccounts(state, payload) {
      state.accounts = payload;
    },
    setAccountInitialValues(state, payload) {
      state.accountInitialValues = payload;
    },
    setWithTrashedPromotionList(state, payload) {
      state.withTrashedPromotionList = payload.list;
    },
    setPromotionInitialValues(state, payload) {
      state.promotionInitialValues = payload;
    },
    setSubscriptionList(state, payload) {
      state.subscriptionList = payload.subscriptionList;
    },
    setParameterPassingLogTypeList(state, payload) {
      state.parameterPassingLogTypeList = payload.list;
    },

    // -------------------------- ↓↓ LINE ↓↓ --------------------------
    setTagList(state, payload) {
      state.tagList = payload.tagList;
    },
    setUnitList(state, payload) {
      state.unitList = payload.unitList;
    },
    // -------------------------- ↑↑ LINE ↑↑ --------------------------

    // -------------- ↓↓ Facebook専用（いずれ消える予定） ↓↓ --------------
    setPixels(state, payload) {
      state.pixels = payload;
    },
    setPixelInitialValues(state, payload) {
      state.pixelInitialValues = payload;
    },
    setWithTrashedFacebookPixelList(state, payload) {
      state.withTrashedFacebookPixelList = payload.list;
    },
    setUnits(state, payload) {
      state.units = payload;
    },
    setWithTrashedFacebookUnitList(state, payload) {
      state.withTrashedFacebookUnitList = payload.list;
    },
    setUnitInitialValues(state, payload) {
      state.unitInitialValues = payload;
    },
    // -------------- ↑↑ Facebook専用（いずれ消える予定） ↑↑ --------------

    setAutoImportCodes(state, payload) {
      state.autoImportCodes = payload;
    },
    setBussinessAccountList(state, payload) {
      state.bussinessAccountList = payload;
    },
    setBussinessAccountInitialData(state, payload) {
      state.bussinessAccountInitialData = payload;
    },
    setFacebookEventList(state, payload) {
      state.facebookEventList = payload.facebookEventList;
    },
    setTiktokEventList(state, payload) {
      state.tiktokEventList = payload.list;
    },
    setCartSystemSubscriptionList(state, payload) {
      state.cartSystemSubscriptionList = payload.list;
    },
    setCartSystemProductList(state, payload) {
      state.cartSystemProductList = payload;
    },
    // やっぱりstoreのgettersに任せる
    // setSubscriptionHistoryList(state, payload) {
    //   state.subscriptionHistoryList = payload.subscriptionHistoryList;
    // }
    setImportResultDefinitionList(state, payload) {
      state.importResultDefinitionList = payload.importResultDefinitionList;
    }
  },
  actions: {
    setInitialData({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/api/tools02/get-initial-data.php`)
        .then(res => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setAccountInitialValues', res.data.googleCvAccountInitialValues);
            commit('setPromotionInitialValues', res.data.tools02PromotionInitialValues);
            commit('setUnitInitialValues', res.data.tools02UnitInitialValues);
            commit('setPixelInitialValues', res.data.facebookPixelInitialValues);
            commit('setBussinessAccountInitialData', res.data.bussinessAccountInitialData);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(err => {
          console.log(err);
          return;
        });
    },
    setAccounts({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/account/get-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            commit('setAccounts', response.data.list);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            router.push('/login');
          } else {
            alert(store.getters.apiErrorMessage);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    setWithTrashedPromotionList({
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        axios
          // .get(`${rootGetters.rt}/api/tools02/getPromotions.php`)
          .get(`${rootGetters.rt}/l/api/tools02/promotion/get-list?with_trashed=1`)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              const payload = {
                list: response.data.list
              };
              commit('setWithTrashedPromotionList', payload);
            } else if (response.data.status === 401) {
              alert(response.data.message);
              router.push('/login');
            } else {
              alert(store.getters.apiErrorMessage);
            }
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      })
    },
    setSubscriptionList({
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootGetters.rt}/l/api/tools02/subscription/get-list`)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              let payload = {
                subscriptionList: response.data.list
              }
              commit('setSubscriptionList', payload);
              resolve();
            } else if (response.data.status === 401) {
              alert(response.data.message);
              router.push('/login');
            } else {
              alert(store.getters.apiErrorMessage);
            }
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      })
    },
    setParameterPassingLogTypeList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/parameter-passing/get-log-type-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              list: response.data.list
            }
            commit('setParameterPassingLogTypeList', payload);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            router.push('/login');
          } else {
            alert(store.getters.apiErrorMessage);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // -------------------------- ↓↓ LINE ↓↓ --------------------------
    setTagList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/tag/get-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              tagList: response.data.list
            };
            commit('setTagList', payload);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            router.push('/login');
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    setUnitList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/unit/get-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              unitList: response.data.list
            };
            commit('setUnitList', payload);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            router.push('/login');
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    // -------------------------- ↑↑ LINE ↑↑ --------------------------

    // -------------- ↓↓ Facebook専用（いずれ消える予定） ↓↓ --------------
    setUnits({
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootGetters.rt}/api/tools02/getUnits.php`)
          .then(res => {
            console.log(res);
            if (res.data.status === 200) {
              commit('setUnits', res.data.unitList);
              commit('setWithTrashedFacebookUnitList', {
                list: res.data.withTrashedList
              });
            } else if (res.data.status === 401) {
              alert(res.data.message);
              router.push('/login');
            } else {
              alert(store.getters.apiErrorMessage);
            }
            resolve();
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    },
    setPixels({
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootGetters.rt}/api/tools02/getPixels.php`)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              // 
              commit('setPixels', response.data.pixelList);

              // 
              let payload = {
                list: response.data.withTrashedList
              };
              commit('setWithTrashedFacebookPixelList', payload);
            } else if (response.data.status === 401) {
              alert(response.data.message);
              router.push('/login');
            } else {
              alert(store.getters.apiErrorMessage);
            }
            resolve();
          })
          .catch(error => {
            console.log(error);
            reject();
          });
      })
    },
    // -------------- ↑↑ Facebook専用（いずれ消える予定） ↑↑ --------------

    setAutoImportCodes({
      commit
    }, payload) {
      commit('setAutoImportCodes', payload);
    },
    setBussinessAccountList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/api/tools02/yahoo-cv/get-bussiness-account-list.php`)
        .then(res => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setBussinessAccountList', res.data.bussinessAccountList);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            router.push('/login');
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setFacebookEventList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/media/facebook/get-event-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              facebookEventList: response.data.facebookEventList
            };
            commit('setFacebookEventList', payload);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setTiktokEventList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/media/tiktok/get-event-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              list: response.data.list
            };
            commit('setTiktokEventList', payload);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setCartSystemSubscriptionList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/cart-system/get-subscription-list?type=default`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              list: response.data.list
            }
            commit('setCartSystemSubscriptionList', payload);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
    },
    setCartSystemProductList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/cart-system/get-product-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            commit('setCartSystemProductList', response.data.cartSystemProductList);
            console.log(response.data.cartSystemProductList);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
    },
    // やっぱりstoreのgettersに任せる
    // setSubscriptionHistoryList({
    //   commit,
    //   rootGetters
    // }) {
    //   axios
    //     .get(`${rootGetters.rt}/l/api/tools02/subscription/get-subscription-history-list`)
    //     .then(response => {
    //       console.log(response);
    //       if (response.data.status === 200) {
    //         let payload = {
    //           subscriptionHistoryList: response.data.subscriptionHistoryList
    //         };
    //         commit('setSubscriptionHistoryList', payload);
    //         return;
    //       } else {
    //         alert(rootGetters.apiErrorMessage);
    //         return;
    //       }
    //     })
    // },
    setImportResultDefinitionList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/l/api/tools02/import/get-result-definition-list`)
        .then(response => {
          console.log(response);
          if (response.data.status === 200) {
            let payload = {
              importResultDefinitionList: response.data.list
            }
            commit('setImportResultDefinitionList', payload);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
    },
  },
  modules: {}
}