<template>
  <div class="ctrl-qa">
    <h2>よくある質問</h2>
    <el-row :gutter="20" type="flex" justify="space-between" align="middle">
      <el-col :span="4">
        <router-link to="/ctrl">
          <Button3 msg="戻る" type="success" />
        </router-link>
      </el-col>
      <el-col :span="6" style="text-align:center">
        <el-radio v-model="radio" label="1">質問を閉じる</el-radio>
      </el-col>
      <el-col :span="10">
        <el-select v-model="value" placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>で絞り込み
      </el-col>
      <el-col :span="4">
        <router-link to="/ctrl">
          <Button3 msg="並び替え確定" type="warning" />
        </router-link>
      </el-col>
    </el-row>

    <el-row>
      <!-- カテゴリ１ -->
      <el-collapse
        v-model="activeName1"
        accordion
        v-for="(list1,index) in cateLists"
        :key="index"
        :span="24"
      >
        <el-collapse-item :title="list1.cate01_name" :name="list1.cate01">
          <!-- カテゴリ１のカテゴリ２ -->
          <el-collapse v-model="activeName2">
            <el-row
              v-for="(list2,index) in list1.list2"
              :key="index"
              type="flex"
              justify="end"
              class="cate02_row"
            >
              <el-col :span="4">&nbsp;</el-col>
              <el-col :span="20">
                <el-collapse-item
                  :title="list2.cate02_name"
                  :name="list2.cate02"
                  class="cate02_name"
                >
                  <!-- FAQ -->
                  <el-row
                    v-for="(list3,index) in list2.list3"
                    :key="index"
                    type="flex"
                    justify="end"
                    align="middle"
                    class="FAQ_row"
                  >
                    <el-col :span="6">&nbsp;</el-col>
                    <el-col :span="16" class="FAQ">{{list3.title}}</el-col>
                    <el-col :span="2"><el-button type="info" plain class="detailBTN" @click="detail(list3.id,list3.cate01,list3.cate02)">詳細</el-button></el-col>
                    
                  </el-row>
                  <!-- FAQ -->
                </el-collapse-item>
              </el-col>
            </el-row>
            <!-- <el-collapse-item :title="list2.cate02_name" :name="list2.cate02"> -->
          </el-collapse>
          <!-- カテゴリ１のカテゴリ２ -->
        </el-collapse-item>
      </el-collapse>
      <!-- カテゴリ１ -->
    </el-row>
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  name: "CtrlQA",
  data() {
    return {
      tableData: [],

      options: [
        {
          value: "カテゴリ1",
          label: "カテゴリ1",
        },
        {
          value: "カテゴリ2",
          label: "カテゴリ2",
        },
        {
          value: "カテゴリ3",
          label: "カテゴリ3",
        },
        {
          value: "カテゴリ4",
          label: "カテゴリ4",
        },
        {
          value: "カテゴリ5",
          label: "カテゴリ5",
        },
      ],
      radio: "",
      value: "",

      cateLists: [],
      activeName1: "",
      activeName2: "",
      activeName3: "",
    }
  },
  components: {
    Button3,
  },
  created() {
  },
  mounted() {
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getPostList.php")
      .then((res) => {
        console.log(res);
        this.tableData = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });

    //カテゴリを取得
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getCategory.php")
      .then((res) => {
        console.log(res);
        console.log(res.data.list);
        console.log(res.data.list.list2);
        this.cateLists = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    detail:function(id,cate01,cate02){
      // sessionStorage.setItem("FAQ_id",id);
      this.$router.push("/ctrl/qa-edit/"+id+"/"+cate01+"/"+cate02);
    }
  },
}
</script>

<style lang="scss" scoped>
.cate02_row {
  margin-bottom: 0;
}
.cate02_name {
  border-bottom: 1px solid #ebeef5;
  padding-bottom: 0;
}

.FAQ_row {
  margin-bottom: 0;
}
.FAQ {
  border-bottom: 1px solid #ebeef5;
  padding: 15px;
}
.el-collapse-item__content {
  padding-bottom: 0!important;
}
.detailBTN{
  padding: 5px 10px;
}
</style>
