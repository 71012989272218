<template>
  <div class="manual-google-cv">
    <el-row>
      <el-col>
        <p class="title">よくある質問</p>
        <p class="sub"></p>
        <table class="typeA">
          <thead>
            <tr>
              <th class="title">項目</th>
              <th class="kubun">区分・対象</th>
              <th style="text-align: center"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="title">よくある質問</td>
              <td class="kubun">説明</td>
              <td class="manual">
                <el-button @click="toQA" type="primary">閲覧</el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <el-row
      v-for="(sectionData, index) in tools[tool.codeCamel].manualSectionList"
      :key="index"
    >
      <el-col>
        <p class="title">{{ sectionData.title }}</p>
        <p class="sub">{{ sectionData.subscription }}</p>
        <table class="typeA">
          <thead>
            <tr>
              <th class="title">項目</th>
              <th class="kubun">区分・対象</th>
              <th style="text-align: center">マニュアル</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(manualData, index2) in sectionData.manualList"
              :key="index2"
            >
              <td class="title">{{ manualData.title }}</td>
              <td class="kubun">{{ manualData.kubun }}</td>
              <td align="center" class="manual">
                <router-link
                  v-if="manualData.cate01 !== 'url'"
                  :to="`/manual/${tool.codeKebab}/${manualData.cate01}/${manualData.cate02}`"
                >
                  <el-button type="primary">閲覧</el-button>
                </router-link>
                <a v-else :href="manualData.cate02" target="_blank" rel="noopener noreferrer">
                  <el-button type="primary">閲覧</el-button>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="title">カートシステム・計測システムとの連携</p>
        <p class="sub"></p>
        <table class="typeA">
          <thead>
            <tr>
              <th class="title">項目</th>
              <th class="kubun"></th>
              <th style="text-align: center"></th>
            </tr>
          </thead>
          <tbody v-for="(cartData, cartIndex) in cartSystemSubscriptionList"
          :key="cartIndex">
            <tr>
              <td class="title">{{cartData.aspName}}との連携</td>
              <td class="kubun">アドタスカル×{{cartData.aspName}}</td>
              <td align="center">
                <router-link
                  v-if="cartData.status === 2"
                  :to="`/manual/${tool.codeKebab}${cartData.manual}`"
                >
                  <el-button type="primary">閲覧</el-button>
                </router-link>
                <router-link 
                v-else
                :to="`/tools02/cart-system/${cartData.aspId}`">
                  <el-button>契約</el-button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p class="title">特典</p>
        <p class="sub"></p>
        <table class="typeA">
          <thead>
            <tr>
              <th class="privileger">特典名</th>
              <th class="provider">提供者名</th>
              <th class="condition">閲覧条件</th>
              <th style="text-align: center">ステータス</th>
            </tr>
          </thead>
          <tbody v-for="(privilege, privilegeIndex) in privilegeList"
          :key="privilegeIndex">
            <tr v-if="String(tool.id) === privilege.toolId || privilege.toolId === '0'">
              <td class="">{{privilege.privilegeName}}</td>
              <td class="">{{privilege.providerName}}</td>
              <td class="">{{privilege.conditionName}}</td>
              <td v-if="privilege.acquired === '1'"  class="privilege-status">
                <el-button @click="g_toPrivilegePage(privilege.privilegeId)" type="primary">閲覧</el-button>
              </td>
              <td v-else class="privilege-status">
                <el-button>条件未達</el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualTools02",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools", "apiErrorMessage", "privilegeList"]),
    ...mapGetters("tools02", ["cartSystemSubscriptionList"]),
  },
  methods: {
    toQA() {
      if (this.tool.codeKebab === "google-cv") {
        window.open(
          "https://docs.google.com/spreadsheets/d/1qJYOSIeLsthboFSx1sQaTxZMmFuvKSPstgx-Q6M6SWw/edit#gid=345091283",
          "_blank"
        );
      } else if (this.tool.codeKebab === "yahoo-cv") {
        window.open(
          "https://docs.google.com/spreadsheets/d/1qJYOSIeLsthboFSx1sQaTxZMmFuvKSPstgx-Q6M6SWw/edit#gid=2041655061",
          "_blank"
        );
      } else if (this.tool.codeKebab === "facebook-cv") {
        window.open(
          "https://docs.google.com/spreadsheets/d/1qJYOSIeLsthboFSx1sQaTxZMmFuvKSPstgx-Q6M6SWw/edit#gid=965431596",
          "_blank"
        );
      } else {
        alert("error.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mokuji {
  .toc-box {
    img {
      width: 100%;
      cursor: pointer;
    }
    .desc {
      text-align: center;
      font-size: 14px;
    }
  }
  .el-col {
    margin-bottom: 20px;
  }
}
p {
  margin-bottom: 5px;
  margin-top: 5px;
}
.title {
  color: rgb(64, 158, 255);
  font-weight: bold;
  font-size: 19px;
  margin-top: 30px;
}

tbody {
  .title {
    color: rgb(77, 77, 64);
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
table.typeA {
  tr {
    th,
    td {
      @media screen and (max-width: 820px) {
        font-size: 11px;
      }
    }
    th {
      @media screen and (max-width: 768px) {
        padding: 5px 10px;
      }
    }
  }
  .title {
    width: 65%;
    @media screen and (max-width: 768px) {
      font-size: 11px !important;
      padding: 5px;
    }
  }
  .kubun {
    width: 19%;
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 11px !important;
      padding: 5px 0;
    }
  }
  .manual {
    text-align: center;
    padding: 5px 10px;
    @media screen and (max-width: 768px) {
      padding: 5px 10px;
      display: flex;
      justify-content: center;
    }
    a {
      @media screen and (max-width: 768px) {
        text-decoration: none;
      }
    }
    .el-button {
      @media screen and (max-width: 768px) {
        width: 55px;
        display: flex;
        justify-content: center;
      }
    }
  }

  th {
    // background-color: rgb(77, 77, 64);
    background-color: gray;
    color: white;
    font-weight: bold;
    padding: 5px 20px;
  }
}
.privilege-status{
  width: 15%;
  text-align: center;
}
</style>
