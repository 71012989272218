import { render, staticRenderFns } from "./SubscriptionHistoryList.vue?vue&type=template&id=06421df9&scoped=true&"
import script from "./SubscriptionHistoryList.vue?vue&type=script&lang=js&"
export * from "./SubscriptionHistoryList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06421df9",
  null
  
)

export default component.exports