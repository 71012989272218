<template>
  <div class="item">
    <!-- 2020/12/14現在、router/index.js にて、リダイレクトしています。 -->
    <el-row>
      <el-col>
        <router-link to="/item-detail/0">
          <el-button type="primary">新規追加</el-button>
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>当画面で商品の登録・編集をすることにより、「ガジェット」のフォーマット編集画面にて商品選択が可能となります。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>商品名</th>
              <th>価格</th>
              <th>画像</th>
              <th>詳細</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>
                {{item.name}}
              </td>
              <td>
                {{item.price}}
              </td>
              <td>
                {{item.img}}
              </td>
              <td class="btn">
                <router-link :to="'/item-detail/'+item.id">
                  <el-button>編集</el-button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>

      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      list: []
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    }
  },
  created() {
    this.$axios
      .get(this.rt + "/api/getItemList.php")
      .then(res => {
        console.log(res);
        this.list = res.data.list;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
