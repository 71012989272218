<template>
  <div class="ctrl-users-user-list">
    <!-- ------------------- ↓↓ 検索部分 ↓↓ ------------------- -->
    <div class="log-search-box">
      <el-row type="flex" align="middle">
        <el-col :span="3">
          <span>ユーザーID: </span>
        </el-col>
        <el-col :span="3">
          <el-input
            min="0"
            type="number"
            v-model="searchConditionData.userId"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>姓: </span>
        </el-col>
        <el-col :xs="15" :sm="4">
          <el-input
            placeholder="空白で検索対象外"
            v-model="searchConditionData.name01"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>名: </span>
        </el-col>
        <el-col :xs="15" :sm="4">
          <el-input
            placeholder="空白で検索対象外"
            v-model="searchConditionData.name02"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="9" :sm="3">
          <span>メールアドレス: </span>
        </el-col>
        <el-col :xs="15" :sm="12">
          <el-input
            placeholder="空白で検索対象外"
            v-model="searchConditionData.email"
            clearable
          ></el-input>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :xs="5" :sm="3">
          <span>登録日時：</span>
        </el-col>
        <el-col :xs="19" :sm="9">
          <el-date-picker
            v-model="searchConditionData.registerDatetimeRange"
            type="datetimerange"
            align="right"
            start-placeholder="開始日時"
            end-placeholder="終了日"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-col>
      </el-row>

      <el-row>
        <el-col style="text-align: center">
          <el-button @click="getUserList(1)" type="primary">検索</el-button>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex" v-if="searchedDatetimeRange.length > 0">
        <el-col :span="2">検索期間：</el-col>
        <el-col>
          {{ searchedDatetimeRange[0] }} ～
          {{ searchedDatetimeRange[1] }}
        </el-col>
      </el-row>
    </div>
    <!-- ------------------- ↑↑ 検索部分ここまで ↑↑ ------------------- -->

    <div
      v-if="loading !== loaded"
      v-loading="loading !== loaded"
      style="width: 100%; height: 150px"
    ></div>
    <div v-else class="width-max-cover">
      <el-row>
        <el-col>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-pagination
              :page-size="pageSize"
              :pager-count="pagerCount"
              :total="totalCount"
              :current-page.sync="currentPage"
              layout="prev, pager, next"
            />
            <span style="margin-right: 15px">
              件数：
              <span>{{ totalCount | localeNum }}</span
              >件
            </span>
          </div>
          <el-table :data="userList" border>
            <el-table-column prop="userId" label="ID" width="50px">
            </el-table-column>
            <el-table-column prop="name01" label="姓"></el-table-column>
            <el-table-column prop="name02" label="名"></el-table-column>
            <el-table-column prop="email" label="メールアドレス">
            </el-table-column>
            <el-table-column
              prop="formattedCreatedAt"
              label="登録日時"
              width="200px"
            >
            </el-table-column>
          </el-table>
          <el-pagination
            :page-size="pageSize"
            :pager-count="pagerCount"
            :total="totalCount"
            :current-page.sync="currentPage"
            layout="prev, pager, next"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UserList",
  components: {},
  props: {},
  data() {
    return {
      loading: null,
      loaded: null,
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      totalCount: 0,

      // 検索条件
      searchConditionData: {
        userId: "",
        name01: "",
        name02: "",
        email: "",
        registerDatetimeRange: this.g_getDatetimeRange("all"),
      },

      // 検索結果
      userList: [],
      searchedDatetimeRange: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    currentPage: {
      handler(newValue) {
        //
        this.getUserList(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    getUserList(currentPage) {
      var loadingToken = this.g_generateRandomPassword(4); // axiosの中まで貫きたいのでvarにする
      this.loading = loadingToken;

      //
      const userId =
        this.searchConditionData.userId === ""
          ? "0"
          : this.searchConditionData.userId;

      //
      const registerDatetimeRange = JSON.stringify(
        this.searchConditionData.registerDatetimeRange
      );

      //
      let parameters = "";
      parameters += `?user_id=${userId}`;
      parameters += `&name01=${this.searchConditionData.name01}`;
      parameters += `&name02=${this.searchConditionData.name02}`;
      parameters += `&email=${this.searchConditionData.email}`;
      parameters += `&register_datetime_range=${registerDatetimeRange}`;
      parameters += `&page_size=${this.pageSize}`;
      parameters += `&current_page=${currentPage}`;
      this.$axios
        .get(`${this.rt}/l/api/user/get-list${parameters}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.userList = response.data.list;
            this.totalCount = response.data.totalCount;
            this.searchedDatetimeRange = response.data.searchedDatetimeRange;
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.loaded = loadingToken;
          return;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
