import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
// import store from '@/store'
// import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    userList: [],
    classificationList: [],
  },
  getters: {
    userList(state) {
      return state.userList
    },
    realUserList(state) {
      let realUserList = state.userList.filter(userData => userData.test === '0');
      return realUserList;
    },
    classificationList(state) {
      return state.classificationList
    },
  },
  mutations: {
    setUserList(state, payload) {
      state.userList = payload;
    },
    setClassificationList(state, payload) {
      state.classificationList = payload;
    },
    setInitialSendMailData(state, payload) {
      state.initialSendMailData = payload;
    },
  },
  actions: {
    setVariousData({dispatch}) {
      dispatch("setUserList");
      dispatch("setClassificationList");
    },
    setUserList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/api/ctrl/get-user-list.php`)
        .then(res => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setUserList', res.data.userList);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(err => {
          console.log(err);
          return;
        });
    },
    setClassificationList({
      commit,
      rootGetters
    }) {
      axios
        .get(`${rootGetters.rt}/api/ctrl/get-classification-list.php`)
        .then(res => {
          console.log(res);
          if (res.data.status === 200) {
            commit('setClassificationList', res.data.classificationList);
            return;
          } else {
            alert(rootGetters.apiErrorMessage);
            return;
          }
        })
        .catch(err => {
          console.log(err);
          return;
        });
    },
  },
  modules: {}
}