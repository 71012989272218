<template>
  <div class="tools02-tag-test">
    <el-row>
      <el-col>
        <h2>{{ g_getTools02TagCategoryName(toolId) }} テスト送信</h2>
      </el-col>
    </el-row>
    <TagTestSystem :toolId="toolId" :tagId="tagId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TagTestSystem from "@/components/Tools02/TagTest/TagTestSystem.vue";

export default {
  components: {
    TagTestSystem,
  },
  props: {
    toolId: String,
    tagId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
