import { render, staticRenderFns } from "./BussinessAccountList.vue?vue&type=template&id=37ae53e5&scoped=true&"
import script from "./BussinessAccountList.vue?vue&type=script&lang=js&"
export * from "./BussinessAccountList.vue?vue&type=script&lang=js&"
import style0 from "./BussinessAccountList.vue?vue&type=style&index=0&id=37ae53e5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ae53e5",
  null
  
)

export default component.exports