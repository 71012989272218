<template>
  <div class="mailList">
    <h2>連絡先アドレス</h2>
    <table class="typeB">
      <template v-if="mailList.length === 0">
        <tr>
          <td><el-input v-model="newMail" /></td>
          <td>
            <el-button type="success" @click="addNewMail()">追加</el-button>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr v-for="(mailData, mailIndex) in mailList" :key="mailIndex">
          <td>{{ mailData.email }}</td>
          <td>
            <el-button type="danger" @click="deleteMail(mailData.id)"
              >削除</el-button
            >
          </td>
        </tr>
        <tr>
          <td><el-input v-model="newMail" /></td>
          <td>
            <el-button type="success" @click="addNewMail()">追加</el-button>
          </td>
        </tr>
      </template>
    </table>
    <div class="description">
      <p>
        ※ログインID用のメールアドレス以外にアドタスカルの連絡を受け取りたいメールアドレスを登録してください。
      </p>
      <p>※複数登録することができます。</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EmailList",
  components: {},
  props: {},
  data() {
    return {
      mailList: [],
      newMail: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "userData"]),
  },
  watch: {},
  mounted() {
    this.getMailList();
  },
  methods: {
    getMailList() {
      this.$axios
        .get(`${this.rt}/l/api/user/get-mail-list`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.mailList = response.data.list;
          } else {
            //
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return this.mailList;
    },
    addNewMail() {
      // メールドメインの妥当性チェック
      if (
        this.newMail.indexOf("outlook.jp") === -1 &&
        this.newMail.indexOf("hotmail.co.jp") === -1 &&
        this.newMail.indexOf("hotmail.com") === -1
      ) {
        // no action.
      } else {
        alert("outlook.jpやhotmailのメールはご利用いただけません");
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("email", this.newMail);
      this.$axios
        .post(`${this.rt}/l/api/user/create-user-email`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.mailList = response.data.list;
            this.newMail = "";
          } else {
            //
            if (response.data.status === 400) {
              alert(response.data.validationErrorData.email);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteMail(userEmailId) {
      let params = new URLSearchParams();
      params.append("user_email_id", userEmailId);

      this.$axios
        .post(`${this.rt}/l/api/user/delete-user-email`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.mailList = response.data.list;
          } else {
            //
            if (response.data.status === 400) {
              alert(response.data.validationErrorData.email);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h2 {
  border: none;
  margin-bottom: 0;
}
span.input {
  font-size: 18px;
  font-weight: bold;
}
table.typeB {
  tr {
    th {
      @media screen and (max-width: 767px) {
        font-size: 12px;
        padding-left: 0px;
        padding-right: 0px;
      }
    }
    td {
      padding-left: 30px;
      @media screen and (max-width: 767px) {
        padding-left: 8px;
      }
    }
  }
}
.newmail {
  width: 70%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
</style>
