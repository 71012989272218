<template>
  <div class="tools02-home-description">
    <template v-if="tool.codeKebab === 'cart-system'">
      <el-row>
        <el-col>
          <p>
            利用開始日を起点日として、起点日の5日前までの申し出で、次の起点日から休止・解約が可能になります。
          </p>
          <p>
            解約の場合は、残りの契約月数分の料金をポイントで換算し、ポイントで返却します。
          </p>
        </el-col>
        <el-col>
          <p>返却したポイントは、各成果連携ツールにて使用可能です。</p>
        </el-col>
      </el-row>
    </template>
    <template v-else>
      <el-row>
        <el-col>
          <h3 class="back-gray">
            {{ tools[tool.codeCamel].name2 }}の消費ポイント
          </h3>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <div>
            {{ tools[tool.codeCamel].name2 }}は、1日あたり{{
              tools[tool.codeCamel].point | localeNum
            }}ポイントでご利用できます。
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3 class="back-gray">「利用ステータス」について</h3>
        </el-col>
      </el-row>
      <div class="status-cover">
        <div class="status-description status">
          <h4 class="active">
            <span>稼働中</span>
            <div></div>
          </h4>
          <div>
            {{ tools[tool.codeCamel].name2 }}の全機能をご利用いただけます。
          </div>
          <h4 class="non-active">
            <span>利用停止中</span>
            <div></div>
          </h4>
          <div>
            {{
              tools[tool.codeCamel].name2
            }}のうち、右記の表の機能がご利用いただけなくなります。
          </div>
        </div>
        <div class="status">
          <table class="description">
            <thead>
              <tr>
                <th style="width: 260px"></th>
                <th class="active">稼働中</th>
                <th class="non-active">利用停止中</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>管理画面へのアクセス</th>
                <td>○</td>
                <td>×</td>
              </tr>
              <tr>
                <th>パラメータ引継ぎ機能</th>
                <template v-if="tool.codeKebab === 'squadbeyond-cv'">
                  <td colspan="2">お使いの媒体の成果連携ツールが<br>ONの場合利用可能</td>
                </template>
                <template v-else>
                  <td>○</td>
                  <td>×</td>
                </template>
              </tr>
              <tr>
                <th>
                  成果データの<br />手動インポート機能<br />（ASP→アドタスカル）
                </th>
                <template v-if="tool.codeKebab === 'squadbeyond-cv'">
                  <td colspan="2">お使いの媒体の成果連携ツールが<br>ONの場合利用可能</td>
                </template>
                <template v-else>
                  <td>○</td>
                  <td>×</td>
                </template>
              </tr>
              <tr>
                <th>
                  成果データの<br />自動インポート機能<br />（ASP→アドタスカル）
                </th>
                <template v-if="tool.codeKebab === 'squadbeyond-cv'">
                  <td colspan="2">お使いの媒体の成果連携ツールが<br>ONの場合利用可能</td>
                </template>
                <template v-else>
                  <td>○</td>
                  <td>×</td>
                </template>
              </tr>
              <tr>
                <th>
                  成果データの<br />自動アップロード<br /><span
                    style="font-size: 15px"
                    >（アドタスカル→{{ tool.mediaName }}）</span
                  >
                </th>
                <td>○</td>
                <td>×</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <el-row>
        <el-col>
          <h3 class="back-gray">「利用設定（ON/OFF）」について</h3>
        </el-col>
      </el-row>
      <div class="cover">
        <img :src="`${rt}/img/tools02/yajirushi-on.png`" alt="" />
        <p>稼働中</p>
        <img :src="`${rt}/img/tools02/yajirushi-off.png`" alt="" />
        <p>利用停止中</p>
      </div>
      <el-row class="graphic-description">
        <el-col>
          <h4>
            【ONの場合】毎日0:00に自動で{{
              tools[tool.codeCamel].point | localeNum
            }}ポイント消化されます。
          </h4>
          <div class="img-cover">
            <img :src="`${rt}/img/tools02/infographic01.png`" alt="" />
          </div>
          <h4 style="margin-top: 60px">
            【OFFの場合】ポイントの消化はありません。
          </h4>
          <div class="img-cover">
            <img :src="`${rt}/img/tools02/infographic02.png`" alt="" />
          </div>
          <h4 style="margin-top: 60px">
            【1日の途中でONからOFFに変更した場合】翌日0:00から機能が停止します（当日の23:59時まで、全機能がご利用できます）。
          </h4>
          <div class="img-cover">
            <img :src="`${rt}/img/tools02/infographic03.png`" alt="" />
          </div>
          <h4 style="margin-top: 60px">
            【1日の途中でOFFからONに変更した場合】今すぐに全機能がご利用いただきます（即時に{{
              tools[tool.codeCamel].point | localeNum
            }}ポイント消化されます）。
          </h4>
          <div class="img-cover">
            <img :src="`${rt}/img/tools02/infographic04.png`" alt="" />
          </div>
          <h4 style="margin-top: 60px">
            【1日の途中で何度もONとOFFを繰り返した場合】ポイントが消化されるのは1回のみです（{{
              tools[tool.codeCamel].point | localeNum
            }}ポイント）。
          </h4>
          <div class="img-cover">
            <img :src="`${rt}/img/tools02/infographic05.png`" alt="" />
          </div>
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02HomeDescription",
  components: {},
  props: {},
  data() {
    return {
      isCampaign: false,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools"]),
  },
  created() {
    this.checkCampaign();
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
table.description {
  width: 100%;
  border-collapse: collapse;
  tr {
    th,
    td {
      border: 1px solid #999;
    }
    th {
      background-color: #ddd;
      padding: 5px 0;
      @media screen and (max-width: 767px) {
        font-size: 12px !important;
        width: 200px;
      }
    }
    td {
      text-align: center;
    }
  }
  thead {
    tr {
      th,
      td {
        color: white;
      }
      th {
        font-weight: normal;
        letter-spacing: 0.15em;
      }
      th.active {
        width: 140px;
        background-color: #cd5a63;
      }
      th.non-active {
        width: 140px;
        background-color: #598fb5;
      }
    }
  }
  tbody {
    tr {
      th,
      td {
      }
      th {
      }
    }
  }
}
.status-description {
  width: 50%;
  @media screen and (max-width: 1148px) {
    margin-bottom: 20px;
    width: 100%;
  }
  h4.active {
    span {
      border-bottom: 3px solid #cd5a63;
    }
    div {
      height: 1px;
      margin-top: 3px;
      background-color: #cd5a63;
    }
  }
  h4.non-active {
    span {
      border-bottom: 3px solid #598fb5;
    }
    div {
      height: 1px;
      margin-top: 3px;
      background-color: #598fb5;
    }
  }

  .caution {
    font-size: 15px;
    padding: 10px 20px;
    background-color: #f2f2f2;
    border-radius: 8px;
  }
}
.graphic-description {
  background-color: #f2f2f2;
  padding: 20px 30px 40px;
  @media screen and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  h4 {
    color: #444;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .img-cover {
    background-color: white;
    padding: 20px 40px;
  }
}
.status-cover {
  display: flex;
  @media screen and (max-width: 1148px) {
    flex-direction: column;
  }
  .status {
    padding: 0 10px;
    @media screen and (max-width: 1148px) {
      width: 100%;
    }
  }
}
.cover {
  display: flex;
  align-items: center;
  img {
    width: 200px;
    @media screen and (max-width: 767px) {
      width: 90px;
    }
  }
  p {
    width: 20%;
    @media screen and (max-width: 767px) {
      width: 25%;
    }
  }
}
</style>
