<template>
  <div class="manual-tools02-contents">

<h2>
  プログラム情報登録<br class="sp-only">（afb）
</h2>
<el-row>
  <el-col>
    <h4>【１】 <a href="https://www.afi-b.com/" target="_blank">afbの管理画面</a>にログインし、登録したい案件名を入力して「プログラム検索」をします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no11/01.png`" alt="" />

            <h4>【２】プログラム名とプログラムIDをコピーします。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no11/02.png`" alt="" />

                    <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>
<h4>【３】ASPプログラム名とプログラムIDに貼り付けます。</h4>
            <img :src="`${rt}/img/manual/google-cv/no05/no11/03.png`" alt="" />


  </el-col>
</el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>

</style>
