<template>
  <div class="ai-form">
    <div id="chatform"> 

      <div style="display:flex; align-items:top;">
        <div style="width:50px;"><img :src="rt+'/img/face.png'" style="width:40px;"></div>
        <div id="load01" class="load load-stop"><div class="loader"></div></div>
        <div id="ai01" class="ai ai-stop">こんにちは。<br>アドタスカルチャットボットです。</div> 
      </div>

      <div class="flex_inner">
        <div style="width:50px;"></div>
        <div id="load02" class="load load-stop"><div class="loader"></div></div> 
        <div id="ai02" class="ai ai-stop">何についてお困りでしょうか？</div> 
      </div>

      <div id="user01" class="user user-stop cp_ipradio">
        <div class="center">
          <ul>
            <li v-for="(c, index) in faq" :key="index" class="list_item"><label class="job01"><input type="radio" v-model="cate01" :value="c.cate01" v-on:click="rev01(index)" class="option-input" name="cpipr05">{{c.name}}</label></li>
          </ul>
        </div>
        <div class="btn"><button type="button" id="btn01" class="hide" v-on:click="dec01">送信</button></div>
      </div>
      
      <div style="display:flex; align-items:top;">
        <div style="width:50px;"><img id="img03" class="ai-stop" :src="rt+'/img/face.png'" style="width:40px;"></div>
        <div id="load03" class="load load-stop"><div class="loader"></div></div>
        <div id="ai03" class="ai ai-stop">ありがとうございます。</div>
      </div>
      <div class="flex_inner">
        <div style="width:50px;">&nbsp;</div>
        <div id="load04" class="load load-stop"><div class="loader"></div></div>
        <div id="ai04" class="ai ai-stop">"{{cate01_name}}"の何についてお困りでしょうか？</div>
      </div>

      <div id="user02" class="user user-stop cp_ipradio">
        <div class="center">
          <ul>
            <li v-for="(c, index) in cate02_list" :key="index" class="list_item"><label class="job01"><input type="radio" v-model="cate02" :value="c.cate02" v-on:click="rev02(index)" class="option-input" name="cpipr05">{{c.name}}</label></li>
          </ul>
        </div>
        <div class="btn"><button type="button" id="btn02" class="hide" v-on:click="dec02(cate01, cate02)">送信</button></div>
      </div>

      <div style="display:flex; align-items:top;">
        <div style="width:50px;"><img id="img05" class="ai-stop" :src="rt+'/img/face.png'" style="width:40px;"></div>
        <div id="load05" class="load load-stop"><div class="loader"></div></div>
        <div id="ai05" class="ai ai-stop">ありがとうございます。</div>
      </div>
      <div class="flex_inner">
        <div style="width:50px;">&nbsp;</div>
        <div id="load06" class="load load-stop"><div class="loader"></div></div> 
        <div id="ai06" class="ai ai-stop">"{{cate01_name}}"の"{{cate02_name}}"について、よくある質問とその回答をご案内いたします。</div> 
      </div>

    </div>
  </div>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'AiForm',
  props: {
    aiform: Number
  },
  components: {
  },
  data() {
    return {
      cate01_name: '',
      cate02_name: '',
      cate02_list: [],

      //日時を取得
      year: '',
      month: '',
      date: '',
      hours: '',
      minutes: '',
      seconds: '',

      //FAQ
      faq: [],

      //入力情報
      cate01: '',
      cate02: ''
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt
    }
  },
  mounted() {
    this.$axios.get(this.rt+"/api/getFAQCategory.php")
    .then(res => {
      console.log(res);
      this.faq = res.data.list;
    })
    .catch(err => {
      console.log(err);
    });

    var datetime = new Date();
    this.year = datetime.getFullYear();
    this.month = datetime.getMonth()+1;
    this.date = datetime.getDate();
    this.hours = datetime.getHours();
    this.minutes = datetime.getMinutes();
    this.seconds = datetime.getSeconds();

    $('#load01').removeClass('load-stop').addClass('load01');
    $('#ai01').removeClass('ai-stop').addClass('ai01');
    $('#load02').removeClass('load-stop').addClass('load02');
    $('#ai02').removeClass('ai-stop').addClass('ai02');
    $('#user01').removeClass('user-stop').addClass('user01');
  },
  methods: {
    rev01(i) {
      this.cate01_name = this.faq[i].name;
      this.cate02_list = this.faq[i].cate02;
      $('#btn01').removeClass('hide');

      $('#load03').removeClass('load01').addClass('load-stop');
      $('#ai03').removeClass('ai01').addClass('ai-stop');
      $('#img03').addClass('ai-stop');
      $('#load04').removeClass('load02').addClass('load-stop');
      $('#ai04').removeClass('ai02').addClass('ai-stop');
      $('#user02').removeClass('user01').addClass('user-stop');

    },
    rev02(i) {
      this.cate02_name = this.cate02_list[i].name;
      $('#btn02').removeClass('hide');
    },
    dec01() {
      setTimeout(function() {
        $('#load03').removeClass('load-stop').addClass('load01');
        $('#ai03').removeClass('ai-stop').addClass('ai01');
        $('#img03').removeClass('ai-stop');
        $('#load04').removeClass('load-stop').addClass('load02');
        $('#ai04').removeClass('ai-stop').addClass('ai02');
        $('#user02').removeClass('user-stop').addClass('user01');
      }, 100);
    },
    dec02(cate01, cate02) {
      setTimeout(function() {
        $('#load05').removeClass('load-stop').addClass('load01');
        $('#ai05').removeClass('ai-stop').addClass('ai01');
        $('#img05').removeClass('ai-stop');
        $('#load06').removeClass('load-stop').addClass('load02');
        $('#ai06').removeClass('ai-stop').addClass('ai02');
        $('#user03').removeClass('user-stop').addClass('user01');
        setTimeout(function() {
          this.$router.push(`/contact/${cate01}/${cate02}`);
        }.bind(this), 3000);
      }.bind(this), 100);
    },
  },
}
</script>

<style lang="scss" scoped>
#app {
  width: 375px;
  margin: 0 auto;
}
body {
  font-family:"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}
.flex_inner {
  display: flex;  
}
.hide {
  display: none;
}
header {
  background: var(--blue2);//naka追加
  padding: 10px;
  p {
    color: white;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.2em;//naka追加
    line-height: 1.2;//naka追加
  }
  p:nth-child(2) {//naka追加
    color: white;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.25em;
  }
}
footer {
  background: var(--blue2);//naka追加
  padding: 10px;
  p {
    color: white;
    font-weight: bold;
    text-align: center;
  }
}
#chatform {
  width: 70%;
  min-height: 1500px;
  background-color: white;//naka追加
  margin: auto;
  padding-top: 15px;

  //チャットのタイトル
  .title {
    text-align: center;
    font-size: 1.3em;
    color: #ffffff;
    font-weight: bold;
  }

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.0s infinite ease-in-out;
  }
  .loader {
    color: #ffffff;
    margin: 0 auto 25px;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
    }
    &:before {
      left: -3.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    &:after {
      left: 3.5em;
    }
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  .ai {
    color: #555;
    border-radius: 0 10px 10px 10px;
    line-height: 1.5;//naka追加
    background: var(--blue3);//naka追加
    & + .ai {
      margin-top: -5px;
    }
    & + .load {
      margin-top: -5px;
    }
  }
  .load {
    margin-top: 5px;
    color: #555;
    background: var(--blue3);//naka追加
    border-radius: 0 10px 10px 10px;
    & + .ai {
      margin-top: -5px;
    }
    & + .load {
      margin-top: -5px;
    }
  }
  //ユーザー側
  .user {
    padding: 15px 10px;
    color: #666666;
    font-size: 16px;
    background: #F4F4F4;//naka追加
    border-radius: 10px 0 10px 10px;
    input {//naka追加
      border: none;
      border-radius: 5px;
      font-size: 14px;
      color: #555;
    }
    & + .user {
      margin-top: -5px;
    }
    textarea {
      box-sizing: border-box;
      width: 100%;
      min-height: 75px;
      padding: 3px;
      border: 1px solid var(--blue2);
      font-size: 14px;
      color: #555;
    }
    .center {
      // margin-top: 5px; naka削除
      padding: 10px;//naka追加
      background: white;//naka追加
      ul {
        li {
          text-align: left;
          input {
            width: auto;
          }
        }
      }
    }
    .shadow {
      box-sizing: border-box;
      padding: 5px;
      width: 100%;
      margin-right: 10px;
    }
    .shadow:focus, textarea:focus {//naka追加
      box-shadow: 0 0 0 4px var(--blue3);
      outline: none;
    }
  }
}
.center {
  text-align: center;
}
.btn {
  text-align: right;
  margin-top: 10px;
  button {//naka追加
    letter-spacing: 0.2em;
    padding: 2px 20px 2px 22px;
    border-radius: 14px;
    background-color: var(--blue2);
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
.load-stop {
  width: 0px;
  min-height: 0px;
  font-size: 0;
}
//load
.load01 {
  font-size: 0;
  // animation: load_fade 1500ms ease 300ms normal;
  animation: load_fade 600ms ease 100ms normal;
  // animation: load_fade 300ms ease 300ms normal;
  // animation: load_fade 100ms ease 100ms normal;
  animation-fill-mode: forwards;
}
.load02 {
  width: 0px;
  min-height: 0px;
  font-size: 0px;
  margin-top: 5px;
  // animation: load_fade 1500ms ease 2100ms normal;
  animation: load_fade 500ms ease 820ms normal;
  animation-fill-mode: forwards;
}
@keyframes load_fade {
  0% {
    width: 0;
    min-height: 0;
    font-size: 0;
    margin: 0;
  }
  10% {
    width: 150px;
    min-height: 60px;
    font-size: 10px;
    margin: 5px 0 5px 10px;
  }
  90% {
    width: 150px;
    min-height: 60px;
    font-size: 10px;
    margin: 5px 0 5px 10px;
  }
  91% {
    font-size: 0px;
  }
  100% {
    width: 0;
    min-height: 0;
    margin: 0;
  }
}

//AI
.ai-stop {
  width: 0;
  height: 0;
  font-size: 0;
}

.ai01 {
  width: 0;
  height: 0;
  font-size: 0;
  // animation: ai_fade 100ms ease 1800ms normal;
  animation: ai_fade 100ms ease 720ms normal;
  animation-fill-mode: forwards;
}
.ai02 {
  font-size: 0;
  animation: ai_fade 100ms ease 3600ms normal;
  animation: ai_fade 100ms ease 1320ms normal;
  animation-fill-mode: forwards;
}
@keyframes ai_fade {
  0% {
    width: 0;
    height: 0;
    font-size: 0;
    padding: 0;
  }
  99% {
    font-size: 0px;
  }
  100% {
    width: 250px;
    font-size: 16px;
    font-size: 14px;
    height: auto;
    padding: 10px;
    margin: 5px 0 5px 10px;
  }
}
//ユーザー
.user-stop {
  transform: scale(0);
  width: 0;
  font-size: 0;
  padding: 0;
}
.user01 {
  transform: scale(0);
  animation: user_fade 100ms ease 1540ms normal;
  animation-fill-mode: forwards;
}
@keyframes user_fade {
  0% {
    transform: scale(0);
    width: 0;
    font-size: 0;
    padding: 0;
  }
  100% {
    transform: scale(1);
    max-width: 250px;
    font-size: 14px;
    padding: 10px;
    margin: 25px 10px 50px auto;
  }
}
// ラジオボタン naka追加
.cp_ipradio {
	width: 90%;
	margin: 2em auto;
  text-align: left;
  ul {
    padding-left: 0;
    li.list_item {
      padding: 5px 0;
      border-bottom: 1px solid #F4F4F4;
      list-style: none;
      &:nth-child(1) {
        padding-top: 0;
      }
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      label {
        line-height: 135%;
        position: relative;
        margin: 0.5rem;
        cursor: pointer;
        letter-spacing: 0.1em;
        &:hover {
          color: var(--blue2);
        }
        .option-input {
          position: relative;
          margin: 0 1rem 0 0;
          cursor: pointer;
          &:before {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0.125rem;
            width: 0.75rem;
            height: 0.75rem;
            content: '';
            -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
            transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
            -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
            border-radius: 50%;
            background: var(--blue2);
          }
          &:checked:before {
            -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
          }
          &:after {
            position: absolute;
            top: -0.25rem;
            left: -0.125rem;
            width: 1rem;
            height: 1rem;
            content: '';
            border: 2px solid #f2f2f2;
            border-radius: 50%;
            background: #ffffff;
          }
        }
      }
    }
  }
}
.keep {
  color: var(--blue2);
}

</style>
