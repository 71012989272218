<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（CATS）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CommonDescription :systemType="'cats'" />
        <h4>
          【１】<a href="https://price-tag.site/front/login/" target="_blank" rel="noopener noreferrer">管理画面</a
          >にログインし、「ログ集計」→「成果ログ」をクリックします。
        </h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no28/01.png`" alt="" />
        </div>
        <h4>【２】検索期間を選択し「実行」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no28/02.png`" alt="" />
        <h4>【３】CSVを生成します。「CSV生成」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no28/03.png`" alt="" />
        <h4>【４】CSVをダウンロードします。「成功」をクリックし、xxxxxxx.csvのリンクをクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no28/04.png`" alt="" />

        <p>これで成果CSVのダウンロードは完了です。</p>
        「
        <router-link :to="`/manual/${tool.codeKebab}/07/14`"
          >ASPの成果データをアドタスカルにインポートする</router-link
        >」に進んでください。
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonDescription from "@/components/Manual/Tools02/Parts/CommonDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    CommonDescription,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
