<template>
  <div class="tools02-bussiness-account-detail-bussiness-account-editor">
    <el-row type="flex" justify="center">
      <el-col :span="18">
        <table class="typeB">
          <tr>
            <th style="width: 250px">
              <span>Yahoo!JAPAN ビジネスID</span>
            </th>
            <td>
              <el-input v-model="bussinessAccountData.bussinessId"></el-input>
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="regBussinessAccountData" type="primary">登録</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BussinessAccountEditor",
  components: {},
  props: {
    bussinessAccountId: String,
  },
  data() {
    return {
      bussinessAccountData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
    // ...mapGetters("tools02", ["accountTypeNames"]),
  },
  watch: {
    bussinessAccountId: {
      handler(newValue) {
        this.bussinessAccountData = JSON.parse(
          JSON.stringify(
            this.$store.getters["tools02/bussinessAccountData"](newValue)
          )
        );
      },
      immediate: true,
    },
  },
  methods: {
    regBussinessAccountData() {
      let params = new URLSearchParams();
      params.append(
        "bussiness_account_data",
        JSON.stringify(this.bussinessAccountData)
      );
      this.$axios
        .post(`${this.rt}/api/tools02/yahoo-cv/reg-bussiness-account-data.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$store.dispatch("tools02/setBussinessAccountList");
            this.$store.dispatch("tools02/setAccounts");
            this.$router.go(-1);
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
