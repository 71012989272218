<template>
  <div class="payment">
    <el-row>
      <el-col>
        <h2>ポイント購入・プラン変更</h2>
      </el-col>
      <el-col>
        <p>
          ポイントを購入して使用することで、アドタスカルのすべてのツール/機能を利用することができます。ポイントの有効期限は購入後1年間です。
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <ActivePlanData />
      </el-col>
      <el-col>
        <h4>■クレジットカードの変更について</h4>
        <span>一度、自動更新設定を停止したあとに、 新しいクレジットカードで自動更新決済をお願いします。[<a href="https://adtasukaru.wraptas.site/616138afafb04a8e9f91cfb102edc6b9" target="_blank" rel="noopener noreferrer">詳細</a>]</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <ProductsTable />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <ToolPointList />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <BonusPointList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ActivePlanData from "@/components/Payment/ActivePlanData.vue";
import ProductsTable from "@/components/Ui/ProductsTable.vue";
import ToolPointList from "@/components/Payment/ToolPointList.vue";
import BonusPointList from "@/components/Payment/BonusPointList.vue";

export default {
  components: {
    ActivePlanData,
    ProductsTable,
    ToolPointList,
    BonusPointList,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "userData"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(h3.back-gray) {
  background-color: #808080;
  color: white;
  margin-bottom: 35px;
}
</style>
