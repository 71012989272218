var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manual-home"},[_c('el-row',[_c('el-col',[(
          _vm.tool.codeKebab === 'popup01' ||
          _vm.tool.codeKebab === 'popup02' ||
          _vm.tool.codeKebab === 'overlay01' ||
          _vm.tool.codeKebab === 'overlay02'
        )?_c('ManualTools01'):(
          _vm.tool.codeKebab === 'google-cv' ||
          _vm.tool.codeKebab === 'facebook-cv' ||
          _vm.tool.codeKebab === 'yahoo-cv' ||
          _vm.tool.codeKebab === 'microsoft-cv'
        )?_c('ManualTools02'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }