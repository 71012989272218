<template>
  <div class="account">
    <el-row>
      <el-col>
        <h2>{{toolData.name2}} レポート</h2>
      </el-col>
    </el-row>
    <Reports :pageId="pageId" />
  </div>
</template>

<script>
import Reports from "@/components/Tools01/Report/Reports.vue";

export default {
  components: {
    Reports,
  },
  props: {
    toolId: String,
    pageId: String,
  },
  data() {
    return {};
  },
  computed: {
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
