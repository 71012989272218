<template>
  <div class="affiliate-link-affiliate-links">
    <el-row>
      <el-col>
        <h3>アフィリエイトリンク</h3>
        <p>以下のアフィリエイトリンクをご利用ください。</p>
        <table class="two-d">
          <thead>
            <tr>
              <th style="width: 30%">ツール名／リンク先ページ</th>
              <th>アフィリエイトリンク</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(affiliateToolData, index) in affiliateToolList"
              :key="index"
            >
              <template v-if="affiliateToolData.toolId !== '8'">
                <th v-if="affiliateToolData.toolId === '0'">
                  アドタスカルトップページ
                </th>
                <th v-else>{{ affiliateToolData.name2 }}</th>
                <td>
                  <div class="link-cover">
                    <el-input
                      :value="affiliateToolData.affiliateLink"
                      onclick="this.select();"
                    />
                    <el-button
                      @click="copyLink(affiliateToolData.affiliateLink)"
                      >コピー</el-button
                    >
                  </div>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
        <p>
          「utm_source」「utm_medium」は任意で設定することができます。クリック数は、同一のIPアドレスからクリック、かつ、「utm_source」「utm_medium」が同じ場合は、アフィリエイトリンクがクリックされても重複計上されません。アフィリエイトリンクをクリックしたユーザーがアドタスカル公式サイト内を回遊した場合、URLバーにアフィリエイトリンクではないURLが表示されますが、正常にトラッキングされています。
        </p>
        <p>
          成果のトラッキングは、「ユーザーがアフィリエイトリンクをクリックして、遷移先のページを閉じるまで」が有効となります。Cookieによるトラッキングをしていません。
        </p>
        <p>
          アフィリエイトリンクをクリックした遷移先のページでは、上で設定した「紹介者名」と、下で設定できる「特典」を表示させることができます。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateLinkAffiliateLinks",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    ...mapGetters("affiliate", ["affiliateToolList"]),
  },
  watch: {},
  created() {},
  methods: {
    copyLink(affiliateLink) {
      navigator.clipboard.writeText(affiliateLink);
      alert("コピーしました。");
    },
  },
};
</script>

<style lang="scss" scoped>
.link-cover {
  display: flex;
}
</style>
