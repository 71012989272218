<template>
  <div v-loading="loading === true" class="ctrl-send-mail-send-mail-system">
    <el-row>
      <el-col>
        <router-link :to="`/ctrl/send-mail-detail/send/0`">
          <el-button type="primary">新規作成</el-button>
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="logsTotal"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
        <table class="typeA">
          <thead>
            <tr>
              <th style="width: 100px">日時</th>
              <th style="width: 60px">送信数</th>
              <th>件名</th>
              <th style="width: 100px">
                本文コピー&amp;
                <br />新規作成
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="sendMailData in sendMailLogList"
              :key="sendMailData.sendId"
            >
              <td>{{ sendMailData.sendDatetime }}</td>
              <td>{{ sendMailData.sendUserIdList.length | localeNum }}</td>
              <td>
                <router-link
                  :to="`/ctrl/send-mail-detail/history/${sendMailData.sendId}`"
                >{{ sendMailData.mailData.title }}</router-link>
              </td>
              <td>
                <div style="text-align: center">
                  <router-link :to="`/ctrl/send-mail-detail/send/${sendMailData.sendId}`">
                    <el-button>コピー</el-button>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="logsTotal"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      pageSize: 10,
      pagerCount: 7,
      logsTotal: 0,
      currentPage: 1, //1: 0~4, 2: 5~9, pageSize*(currentPage-1)
      sendMailLogList: []
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
  },
  watch: {
    currentPage() {
      this.getSendMailLogList();
    }
  },
  created() {
    this.getSendMailLogList();
  },
  methods: {
    getSendMailLogList() {
      this.loading = true;
      this.$axios
        .get(`${this.rt}/api/ctrl/get-send-mail-logs.php?page_size=${this.pageSize}&current_page=${this.currentPage}`)
        .then(res => {
          console.log(res);
          if (res.data.status === 200) {
            this.sendMailLogList = res.data.sendMailLogList;
            this.logsTotal = res.data.logsTotal;
            this.loading = false;
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch(err => {
          console.log(err);
          return;
        });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
