<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（SCAN）</h2>

    <el-row>
      <el-col>
        <h4>
          【１】<a href="https://www.scadnet.com/login/" target="_blank"
            >SCANの管理画面</a
          >にログインし、「広告管理」→「広告検索」をクリックします。
        </h4>

        <img :src="`${rt}/img/manual/google-cv/no05/no07/01.png`" alt="" />

        <h4>
          【２】プログラム名を入力して「指定した条件で検索する。」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no07/02.png`" alt="" />

        <h4>【３】プログラム名をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no07/03.png`" alt="" />

        <h4>【４】プログラム名とプログラムIDをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no07/04.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【５】ASPプログラム名とプログラムIDに貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no07/05.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
