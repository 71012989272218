<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>{{tool.mediaName}}アップロード用データをエクスポート</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>アドタスカルにインポートした成果データを確認します。</li>
          <li>{{tool.mediaName}}へのアップロード用のデータをエクスポートします。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】「<router-link :to="`/${tool.codeKebab}`"
            >
            <span v-if="tool.codeKebab === 'facebook-cv'">Facebook広告コンバージョンAPI連携ツール</span>
            <span v-else>{{tool.mediaName}}成果連携ツール</span>
            </router-link
          >」の「<router-link :to="`/tools02/${tool.codeKebab}/report`"
            >ASP成果インポート・エクスポート</router-link
          >」をクリックします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/report.png`"
          alt=""
        />
        <h4>【２】表示したい条件を選択して、「検索」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no15/01.png`" alt="" />
        <h4>【３】アドタスカルにインポートされたCVが表示されます。</h4>
        <p>
          「エクスポート」をクリックすると{{tool.mediaName}}アップロード用CSVがダウンロードされます。
        </p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no15/02.png`" alt="" />
        <h4>【４】CSVを開いてみましょう。（毎回確認しなくても大丈夫です。）</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no15/03.png`" alt="" />
        </div>
        <h4>
          【５】{{tool.mediaName}}アップロード用のテンプレートの形式に沿ったCSVになっています。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no15/04.png`" alt="" />
        <p>
          これで、{{tool.mediaName}}アップロード用のデータをエクスポートできました。
        </p>
        <p>
          次に、「<router-link :to="`/manual/${tool.codeKebab}/07/16`"
            >{{tool.mediaName}}にアップロードする</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
