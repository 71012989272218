<template>
  <div class="tools02-initial-setting">
    <el-row>
      <el-col>
        <h2>
          <img
            :src="`${rt}/img/tools02/initial.png`"
            class="beginner-mark"
          />初期設定チュートリアル
          <br class="min-width-only">
          <span
            >※閲覧が完了した項目にチェックをしていただくことで、進歩状況を管理できます。</span
          >
        </h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <Tools02InitialSettingContents />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02InitialSettingContents from "@/components/Tools02/InitialSetting/Contents.vue";

export default {
  components: {
    Tools02InitialSettingContents,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 26px;
  margin-bottom: 3px;
  img.beginner-mark {
    height: 25px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    margin-left: 10px;
  }
}
.min-width-only{
display: none;
@media screen and (max-width: 1210px){
display: initial;
}
}
</style>
