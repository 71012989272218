<template>
  <div class="button3">
    <el-button v-bind:type="type">{{msg}}</el-button>
  </div>
</template>

<script>
export default {
  name: 'Button3',
  props: {
    msg: String,
    type: String,
  }
}
</script>

<style lang="scss" scoped>
  .el-button {
    padding: 0.5em 0;
    font-size: 18px;
    width: 150px;
  }
  // .is-round {
  //     border-radius: 50px;
  // }
</style>
