<template>
  <div v-loading="loading" class="affiliate-result-rewards">
    <el-row>
      <el-col>
        <h4>報酬確認</h4>
        <table class="af-pop">
          <tr>
            <th>確定報酬金額</th>
            <td>{{ affiliateRewardData.rewardTotalWithTax | localeNum }}円</td>
            <!-- <th>確定報酬率</th>
            <td>{{ rewardRate }}%</td> -->
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div
          v-for="(
            rewardData, rewardDataIndex
          ) in affiliateRewardData.rewardDataList"
          :key="rewardDataIndex"
        >
          <p style="font-weight: bold; margin-bottom: 2px">
            報酬率: {{ rewardData.rewardRate }}%（{{
              rewardData.displayStartDate
            }}
            ~ {{ rewardData.displayEndDate }}）
          </p>
          <table class="two-d">
            <thead>
              <tr>
                <th>プラン名</th>
                <th>報酬単価</th>
                <th>確定件数</th>
                <th>報酬金額小計</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  planRewardData, planRewardDataIndex
                ) in rewardData.planRewardList"
                :key="planRewardDataIndex"
              >
                <th>
                  <span>{{ planRewardData.productData.planName }}（</span>
                  <span
                    v-if="planRewardData.productData.forOnetimeOffer === false"
                    >{{ planRewardData.toolPriceWithTax | localeNum }}</span
                  >
                  <span v-else>-</span>
                  <span>円）</span>
                </th>
                <td>
                  <span
                    v-if="planRewardData.productData.forOnetimeOffer === false"
                    >{{ planRewardData.unitRewardWithTax | localeNum }}</span
                  >
                  <span v-else>-</span>
                  <span>円</span>
                </td>
                <td>{{ planRewardData.achievements | localeNum }}</td>
                <td>
                  {{ planRewardData.rewardSubTotalWithTax | localeNum }}円
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <p>獲得したユーザーが決済した金額の{{ rewardRate }}%をアフィリエイト報酬としてお支払いします。</p> -->
        <!-- <p>
          ユーザーがアドタスカルを利用している間は報酬が発生します（ライフタイムコミッション制）。月末締めで集計し、振込手数料として{{
            fee | localeNum
          }}円を差し引いた金額を翌月末日にお振り込みします。月間の報酬金額が{{
            payBorder | localeNum
          }}円未満の場合は翌月の報酬と合算してお振り込みとなります。金額はすべて税込み表示です。
        </p> -->
        <p>
          ユーザーがアドタスカルを利用している間は報酬が発生します（ライフタイムコミッション制）。報酬のお支払いと受取方法は<router-link
            to="/affiliate/bank-account"
            >コチラ</router-link
          >を参照ください。金額はすべて税込み表示です。
        </p>
        <p>当画面をキャプチャしてアフィリエイト報酬の税務資料としてお使いください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateResultRewards",
  components: {},
  props: {
    dateRange: Array,
  },
  data() {
    return {
      rewardRate: 0,
      fee: 0,
      payBorder: 0,
      affiliateRewardData: {
        rewardTotalWithTax: 0,
        rewardDataList: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "userData"]),
  },
  watch: {
    dateRange: {
      handler(newDateRange) {
        this.getRewardData(newDateRange);
      },
      immediate: true,
    },
  },
  methods: {
    getRewardData(dateRange) {
      this.loading = true;
      this.$axios
        .get(
          `${
            this.rt
          }/api/affiliate/get-reward-data.php?date_range=${encodeURIComponent(
            JSON.stringify(dateRange)
          )}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.fee = response.data.fee;
            this.payBorder = response.data.payBorder;
            this.affiliateRewardData = response.data.affiliateRewardData;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  th,
  td {
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
.af-pop {
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
</style>
