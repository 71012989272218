var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"format-list"},[(_vm.type === 1 || _vm.type === 2)?_c('el-row',{staticStyle:{"margin-top":"30px"}},[_c('el-col',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.regFormat(0)}}},[_vm._v("新規作成")]),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.regFormat(1)}}},[_vm._v("他の"+_vm._s(_vm.toolData.name)+"から転用")])],1)],1):_vm._e(),_c('el-row',[_c('el-col',[_c('h3',{staticClass:"back-gray",staticStyle:{"margin-top":"10px"}},[_vm._v(" フォーマット "),(_vm.type === 0)?_c('Question',{attrs:{"placement":'right',"content":`コピーしたいフォーマットの下部にある「コピー」をクリックすると、フォーマットのコピーができます。`}}):_c('Question',{attrs:{"placement":'right',"content":`以下に作成したフォーマットのみ、表示する${_vm.toolData.name}として選択できるようになります。`}})],1)])],1),_c('el-row',[_c('el-col',[_c('draggable',{staticClass:"format-list-box",attrs:{"group":"myGroup","options":_vm.options},model:{value:(_vm.formatList),callback:function ($$v) {_vm.formatList=$$v},expression:"formatList"}},_vm._l((_vm.formatList),function(formatData,index){return _c('div',{key:index,staticClass:"draggable",class:{
            selected:
              formatData.formatId === _vm.display_now && _vm.toolData.code2 !== 'popup01',
          },staticStyle:{"width":"33%","margin-top":"30px","margin-bottom":"40px"}},[_c('div',{staticClass:"format-list-name",class:{
              selected_name:
                formatData.formatId === _vm.display_now &&
                _vm.toolData.code2 !== 'popup01',
            }},[_vm._v(" フォーマット名: "),_c('span',[_vm._v(_vm._s(formatData.name))])]),_c('div',{staticClass:"format-display-cover",style:(_vm.toolData.code2 === 'overlay01' || _vm.toolData.code2 === 'overlay02'
                ? 'height: 100px;'
                : '')},[_c('FormatDisplay',{attrs:{"size":`mini`,"format":formatData}})],1),_c('div',{staticClass:"format-list-select-btn"},[_c('el-button',{staticStyle:{"margin-right":"20px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.formatDecide(formatData.formatId)}}},[_vm._v(_vm._s(_vm.buttonName))]),_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.deleteFormat(formatData)}}},[_vm._v("削除")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 1 && _vm.toolData.code2 !== 'popup01'),expression:"type === 1 && toolData.code2 !== 'popup01'"}],staticStyle:{"text-align":"center"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.display),expression:"display"}],attrs:{"type":"radio"},domProps:{"value":formatData.formatId,"checked":_vm._q(_vm.display,formatData.formatId)},on:{"change":function($event){_vm.display=formatData.formatId}}})])])}),0)],1)],1),_c('el-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.formats !== 0 && _vm.type === 1 && _vm.toolData.code2 !== 'popup01'),expression:"formats !== 0 && type === 1 && toolData.code2 !== 'popup01'"}],attrs:{"type":"flex","justify":"center"}},[_c('el-col',{staticStyle:{"text-align":"center"},attrs:{"span":8}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.regDisplayFormat('dis')}}},[_vm._v("更新")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }