<template>
  <div class="manual-tools01-other">
    <el-row>
      <el-col>
        <h2 id="other">運用（編集・削除・レポート確認）マニュアル</h2>
        <h3>設定したツールを編集する</h3>
        <p>各ツールの一覧画面にて、編集したいものの「編集」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-1-01.jpg`" alt="">
        <p>編集画面に進むので、編集したい項目を編集し、「更新」または「登録」をクリックして更新を確定させます。</p>
        <img :src="`${rt}/img/manual/manual3-1-02.jpg`" alt="">
        <p>以上で編集は完了です。タグを貼り付けてあるページのツールの情報が更新されます。タグを張り替える必要はありません。</p>
        <h3>設定したツールを編集する</h3>
        <p>各ツールの一覧画面にて、削除したいものの「削除」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-1-03.jpg`" alt="">
        <p>確認画面が表示されるので、「OK」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-1-04.jpg`" alt="">
        <p>以上で削除されます。</p>
        <h3>効果を確認する（レポート確認）</h3>
        <p>各ツールの一覧画面にて、直近7日間の効果を確認することができます。</p>
        <img :src="`${rt}/img/manual/manual3-1-05.jpg`" alt="">
        <p>「レポート」をクリックすると、レポートの詳細画面が表示されます。</p>
        <img :src="`${rt}/img/manual/manual3-1-06.jpg`" alt="">
        <p>期間選択ができ、レポートの詳細を確認できます。</p>
        <img :src="`${rt}/img/manual/manual3-1-07.jpg`" alt="">
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01Other',
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
