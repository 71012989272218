<template>
  <div class="tools02-accounts">
    <el-row>
      <el-col>
        <h2>Yahoo!JAPAN ビジネスID 一覧</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <BussinessAccountList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BussinessAccountList from "@/components/Tools02/BussinessAccounts/BussinessAccountList.vue";

export default {
  components: {
    BussinessAccountList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
