import Vue from 'vue'
import App from './App.vue'
import Router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/animate.css'
import VueScrollTo from 'vue-scrollto/vue-scrollto.js'
import lang from 'element-ui/lib/locale/lang/ja'
import locale from 'element-ui/lib/locale'
import VueHead from 'vue-head'
import axios from 'axios'
import {mapGetters} from 'vuex'

//Vue.use
Vue.use(VueHead)
Vue.use(VueScrollTo, {
  offset: -100
})

//animate.css
// import VAnimateCss from 'animate.css'
// Vue.use(VAnimateCss);


//Vue.filter
Vue.filter('localeNum', function(val) {
  let int = parseInt(val);
  return int.toLocaleString()
})

Vue.filter('limitString', function(string, length) {
  if(string.length <= length) {
    //no action.
  }else {
    string = string.substr(0, length)+"...";
  }
  return string;
})



//グローバル登録
axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRF-Token";
Vue.prototype.$axios = axios;

Vue.prototype.$mapGetters = mapGetters;


//その他
locale.use(lang)

import {globalMixins} from'./mixins/index.js'
Vue.mixin(globalMixins);

Vue.config.productionTip = false
new Vue({
  router: Router,
  store,
  render: h => h(App)
}).$mount('#app')