<template>
  <div class="format0102">
    <div class="format01">
      <div class="zone01" :style="'border: solid '+format.width01+'px '+format.color01">
        <div class="zone02" :style="'background:'+format.color02+';padding-top:'+format.height01+'px;padding-bottom:'+format.height02+'px;'"><div v-html="format.text02"></div></div>
        <div class="wrap" :style="'background:'+format.color02+';padding-top:'+format.height04+'px;padding-bottom:'+format.height05+'px;'">
          <div class="zone03" :style="'background:'+format.color03+';width:'+format.width02+'px;height:'+format.height03+'px;border-radius:'+format.int01+'px;'"><div v-html="format.text03"></div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Format0102",
  props: {
    format: [Array, Object]
  },
  data() {
    return {
      body_height: 0
    }
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.format01 {
  background: white;
  .zone01 {
    height: 100%;
    box-sizing: border-box;
    text-align: center;
    .zone02 {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      .zone03 {
        border: solid 1px #000;
        border-radius: 10px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
}
</style>
