<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録（A8.net）</h2>
<el-row>
  <el-col>
    <h4>【１】<a href="https://www.a8.net/" target="_blank" rel="noopener noreferrer">A8.net管理画面</a>にログインしたら、プログラム名を入力して「検索」をクリックします。</h4>
    <img :src="`${rt}/img/manual/google-cv/no05/no14/01.png`" alt="" />

    <h4>【２】広告名と広告IDをコピーします。</h4>
    <p>広告ID：sから始まる数字。（）は不要です。</p>
    <img :src="`${rt}/img/manual/google-cv/no05/no14/02.png`" alt="" />

    <h4>【３】ASPプログラム名とプラグラムIDに貼り付けます。</h4>
    <img :src="`${rt}/img/manual/google-cv/no05/no14/03.png`" alt="" />





  </el-col>
</el-row>


  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
