import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
// import store from '@/store'
// import router from '@/router'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    rewardFeeWithTax: 0,
    rewardBorderWithTax: 0,
    privilegeData: {
      privilegeId: "0",
      providerName: "",
      privilegeName: "",
      description: "",
      privilegeUrl: "",
      isProviderNameDisplay: false
    }
  },
  getters: {
    rewardFeeWithTax(state) {
      return state.rewardFeeWithTax;
    },
    rewardBorderWithTax(state) {
      return state.rewardBorderWithTax;
    },
    privilegeData(state) {
      return state.privilegeData
    },
    affiliateToolList(state, getters, rootState, rootGetters) {
      let affiliateToolList = [];

      //
      rootState.toolList.forEach(toolData => {
        if (toolData.sortInAffiliate !== "0") {
          toolData.affiliateLink = `${rootGetters.rt}/api/affiliate/link.php?utm_source=${rootGetters.userData.affiliateCode}&utm_medium=&utm_campaign=&tool_id=${toolData.toolId}`;
          affiliateToolList.push(toolData);
        } else {
          //no action.
        }
      });

      //
      affiliateToolList.sort(function (a, b) {
        return a.sortInAffiliate - b.sortInAffiliate;
      })

      //
      return affiliateToolList;
    },
  },
  mutations: {
    setConsts(state, payload) {
      state.rewardFeeWithTax = payload.rewardFeeWithTax;
      state.rewardBorderWithTax = payload.rewardBorderWithTax;
    },
    setPrivilegeData(state, payload) {
      state.privilegeData = payload.privilegeData;
    },
  },
  actions: {
    setPrivilegeData({
      commit,
      rootGetters
    }) {

      // 
      let privilegeData = {
        privilegeId: "0",
        providerName: "",
        privilegeName: "",
        description: "",
        privilegeUrl: "",
        isProviderNameDisplay: false
      };

      // 
      axios
        .get(`${rootGetters.rt}/api/affiliate/privilege/get-privilege-data.php`)
        .then(response => {
          console.log(response);

          // 
          if (response.data.status === 200) {
            privilegeData = response.data.privilegeData;
          } else {
            // no action.
          }

          // 
          let payload = {
            privilegeData: privilegeData
          };
          commit('setPrivilegeData', payload);
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  modules: {}
}