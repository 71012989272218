<template>
  <div class="test-laravel-test">
    <el-button @click="download" type="primary">download</el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "test-laravel-file-download",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl", "rt", "apiErrorMessage"]),
  },
  watch: {},
  methods: {
    download() {
      location.href = `${this.rt}/l/api/test/file-download`;

      // // 
      // this.$axios
      //   .get(`${this.rt}/l/api/test/file-download`)
      //   .then((response) => {
      //     console.log(response);
      //     return;
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-add {
  margin-top: 20px;
}
</style>
