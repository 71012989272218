<template>
  <div class="tools02-account-detail">
    <el-row>
      <el-col>
        <h2 v-if="bussinessAccountId === '0'">
          Yahoo!JAPAN ビジネスID 新規登録
        </h2>
        <h2 v-else>Yahoo!JAPAN ビジネスID 編集</h2>
      </el-col>
    </el-row>
    <BussinessAccountEditor :bussinessAccountId="bussinessAccountId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BussinessAccountEditor from "@/components/Tools02/BussinessAccountDetail/BussinessAccountEditor.vue";

export default {
  components: {
    BussinessAccountEditor,
  },
  props: {
    bussinessAccountId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "aspNames"]),
    ...mapGetters("subscription", ["validities"]),
  },
  watch: {},
  created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.letter {
  font-size: 14px;
}
</style>
