<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <p>「7.手動コース」では、手動で連携する方法を説明しました。</p>
        <p>単純な作業ですが、成果が発生するたびに行うのは少し手間ですね？</p>
        <p v-if="tool.codeKebab === 'facebook-cv'">
          そこで、成果発生→{{
            tool.mediaName
          }}アップロードを完全自動化する方法を説明します。
        </p>
        <p v-else>
          そこで、成果発生→アドタスカルのインポート{{
            tool.mediaName
          }}へのAPI送信を完全自動化する方法を説明します。
        </p>
        <p>成果が発生したときに自動でアドタスカルに登録されるようになります。</p>
        <h3>【所要時間】</h3>
        <p>15分（ASPに依頼するので設定完了までに２～３営業日ほどかかる可能性がございます）</p>
        <p>ASPごとに手順が異なるので、ご自身が使っているASPをクリックしてください。</p>
        <ul>
          <li
            v-for="(manualData, index) in manualList"
            :key="index"
            v-show="manualData.name !== ''"
          >
            <template v-if="manualData.cate01 === 'url'">
              <a :href="manualData.cate02" target="_blank" rel="noopener noreferrer">{{ manualData.name }}</a>
            </template>
            <template v-else>
              <router-link
                :to="`/manual/${tool.codeKebab}/${manualData.cate01}/${manualData.cate02}`"
              >{{ manualData.name }}</router-link>
            </template>
          </li>
        </ul>
        <p v-if="tool.codeKebab === 'facebook-cv'">
          これで、成果発生→アドタスカル登録→{{tool.mediaName}}へのAPI送信が自動化できました。
        </p>
        <p v-else>
          これで、成果発生→アドタスカル登録は自動化できました。
        </p>
        <p>
          実際に成果が発生したら「
          <router-link :to="`/tools02/${tool.codeKebab}/auto-import-log`">自動登録履歴</router-link>」を確認してください。自動登録履歴の確認方法は
          <router-link :to="`/manual/${tool.codeKebab}/08/14`">コチラ</router-link>をご覧ください。
        </p>
        <p v-if="tool.codeKebab === 'facebook-cv'">
        </p>
        <p v-else>
          次は「
          <router-link :to="`/manual/${tool.codeKebab}/08/16`">{{ tool.mediaName }}へ自動アップロードの設定</router-link>
          」に進んでください。アドタスカル→{{
            tool.mediaName
          }}アップロードを自動化しましょう。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "tools",
      "asps",
      "apiErrorMessage",
      "breadCrumbList",
    ]),
    manualList() {
      let manualSectionData = this.tools[this.tool.codeCamel].manualSectionList.find(
        (sectionData) => sectionData.section === "j"
      )
      let manualList = manualSectionData.manualList;

      let additionalManualList = [
        {
          cate01: "08",
          cate02: "08",
          kubun: "ASP→アドタスカル",
          name: "BULK-AD"
        },
        {
          cate01: "08",
          cate02: "08",
          kubun: "ASP→アドタスカル",
          name: "SIXPACK-C"
        },
        {
          cate01: "08",
          cate02: "19",
          kubun: "ASP→アドタスカル",
          name: "@tension"
        },
        {
          cate01: "08",
          cate02: "19",
          kubun: "ASP→アドタスカル",
          name: "Tempura-Link"
        },
        {
          cate01: "08",
          cate02: "19",
          kubun: "ASP→アドタスカル",
          name: "twowin"
        }
      ];

      additionalManualList.forEach(additionalManualData => {
        manualList.push(additionalManualData);
      });
      return manualList;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
