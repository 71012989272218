<template>
  <div>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="getSubscriptionList" type="primary">更新</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="width-max-cover">
          <el-table :data="subscriptionList" v-loading="loading">
            <el-table-column label="userId" prop="userId" width="80px"></el-table-column>
            <el-table-column label="名前" width="130px">
              <template slot-scope="scope">
                {{ scope.row.userData.name01 }}{{ scope.row.userData.name02 }}
              </template>
            </el-table-column>
            <el-table-column label="プラン">
              <template slot-scope="scope">
                {{
          $store.getters["tools02/cartSystemProductData"](
            scope.row.cartSystemProductId
          ).name
        }}
              </template>
            </el-table-column>
            <el-table-column label="申し込み日時" prop="formattedCreatedAt"></el-table-column>
            <el-table-column label="対象ツール">
              <template slot-scope="scope">
                <span v-if="scope.row.aspId === '0'">全て</span>
                <span v-else>
                  <span v-if="$store.getters.aspDataFromAllAspList(scope.row.aspId) !== undefined">{{
          $store.getters.aspDataFromAllAspList(scope.row.aspId).aspName }}</span>
                  <span v-else>-</span>
                </span>
              </template>
            </el-table-column>
            <el-table-column label="startDate" prop="startDate" width="150px"></el-table-column>
            <el-table-column label="endDate" prop="endDate" width="150px"></el-table-column>
            <el-table-column label="ステータス" width="150px">
              <template slot-scope="scope">
                <span v-if="scope.row.statusId === '7'">入金確認・<br />領収書待ち</span>
                <span v-else-if="scope.row.statusId === '5'">グルチャ作成待ち</span>
                <span v-else-if="scope.row.statusId === '4'">提供待ち</span>
                <span v-else-if="scope.row.statusId === '3'">利用開始待ち</span>
                <span v-else-if="scope.row.statusId === '2'">継続準備完了待ち</span>
                <span v-else-if="scope.row.statusId === '8'">継続準備完了</span>
                <span v-else-if="scope.row.statusId === '1'">稼働中</span>
                <span v-else-if="scope.row.statusId === '9'">有効期間外</span>
              </template>
            </el-table-column>
            <el-table-column label="領収書" width="150px">
              <template slot-scope="scope">
                <div v-if="scope.row.statusId === '7'" style="text-align: center" v-loading="uploading">
                  <input @change="selectedFile($event, scope.row.id)" type="file" accept="application/pdf" />
                  <el-button v-if="uploadFileId === scope.row.id" type="primary" size="mini" style="margin-top: 3px"
                    @click="uploadReceipt(scope.row.id)">UP</el-button>
                </div>
                <div v-if="scope.row.statusId === '5' ||
          scope.row.statusId === '4' ||
          scope.row.statusId === '3' ||
          scope.row.statusId === '2'
          " style="text-align: center" v-loading="uploading">
                  <el-button type="" size="mini" @click="downloadReceipt(scope.row.id)">領収書確認</el-button><br />
                  <el-button type="danger" size="mini" style="margin-top: 3px"
                    @click="deleteReceipt(scope.row)">領収書削除</el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="アクション" width="150px">
              <template slot-scope="scope">
                <div style="text-align: center">
                  <el-button v-if="scope.row.statusId === '5' ||
          scope.row.statusId === '4' ||
          scope.row.statusId === '2'
          " @click="createChatworkRoom(scope.row)" type="primary">提供</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CartSystemSubscriptionList",
  components: {},
  props: {},
  data() {
    return {
      loading: false,
      uploading: false,
      subscriptionList: [],
      uploadFileId: null,
      uploadFile: null,
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  created() {
    this.getSubscriptionList();
  },
  methods: {
    getSubscriptionList() {
      this.loading = true;
      this.$axios
        .get(
          `${this.rt}/l/api/tools02/cart-system/get-subscription-list?type=ctrl`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.subscriptionList = response.data.list;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.loading = false;
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createChatworkRoom(cartSystemSubscriptionData) {
      let confirmMessage = "";
      confirmMessage += this.getConfirmMessageHeader(
        cartSystemSubscriptionData
      );
      confirmMessage += `\n`;
      confirmMessage += `この申し込みに対して以下のアクションを実行します。\n`;
      confirmMessage += `よろしいですか？\n`;
      confirmMessage += `\n`;

      //
      let actionMessage = "";
      switch (cartSystemSubscriptionData.statusId) {
        case "5":
          actionMessage += "・グループチャットの作成\n";
          actionMessage += "・グループチャットへスタートガイドの投稿\n";
          actionMessage += "・招待メールの送信\n";
          break;
        case "4":
          actionMessage += "・グループチャットへスタートガイドの投稿\n";
          break;
        case "2":
          actionMessage += "・継続分の準備完了\n";
          break;

        default:
          // no action.
          break;
      }

      //
      confirmMessage += actionMessage;
      if (confirm(confirmMessage)) {
        //
        this.loading = true;

        //
        let params = new URLSearchParams();
        params.append(
          "cart_system_subscription_id",
          cartSystemSubscriptionData.id
        );
        this.$axios
          .post(`${this.rt}/l/api/tools02/cart-system/post-start-guide`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              alert(response.data.message);
              this.getSubscriptionList();
            } else if (response.data.status === 400) {
              alert(response.data.message);
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
            }
            this.loading = false;
            return;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        return;
      }
    },

    //
    selectedFile(event, cartSystemSubscriptionId) {
      event.preventDefault();
      let files = event.target.files;
      this.uploadFile = files[0];
      this.uploadFileId = cartSystemSubscriptionId;
      this.$forceUpdate();
    },
    uploadReceipt(cartSystemSubscriptionId) {
      this.uploading = true;
      let formData = new FormData();
      formData.append("cart_system_subscription_id", cartSystemSubscriptionId);
      formData.append("upload_file", this.uploadFile);
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/cart-system/upload-receipt`,
          formData,
          config
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert(response.data.message);
            this.getSubscriptionList();
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.uploading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.uploading = false;
        });
    },

    //
    downloadReceipt(cartSystemSubscriptionId) {
      location.href = `${this.rt}/l/api/tools02/cart-system/download-receipt?cart_system_subscription_id=${cartSystemSubscriptionId}`;
    },

    //
    deleteReceipt(cartSystemSubscriptionData) {
      let confirmMessage = "";
      confirmMessage += this.getConfirmMessageHeader(
        cartSystemSubscriptionData
      );
      confirmMessage += "\n";
      confirmMessage += "この申し込みの領収書を削除します。よろしいですか？";
      if (confirm(confirmMessage)) {
        // through.
      } else {
        return;
      }
      this.uploading = true;
      let params = new URLSearchParams();
      params.append(
        "cart_system_subscription_id",
        cartSystemSubscriptionData.id
      );
      this.$axios
        .post(`${this.rt}/l/api/tools02/cart-system/delete-receipt`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert(response.data.message);
            this.getSubscriptionList();
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.uploading = false;
        })
        .catch(function (error) {
          console.log(error);
          this.uploading = false;
        });
    },

    //
    getConfirmMessageHeader(cartSystemSubscriptionData) {
      const aspData = this.$store.getters.aspData(
        cartSystemSubscriptionData.aspId
      );
      let confirmMessage = "";
      confirmMessage += `userId: ${cartSystemSubscriptionData.userId}\n`;
      confirmMessage += `名前: ${cartSystemSubscriptionData.userData.name01}${cartSystemSubscriptionData.userData.name02}\n`;
      confirmMessage += `プラン: ${this.$store.getters["tools02/cartSystemProductData"](
        cartSystemSubscriptionData.cartSystemProductId
      ).name
        }\n`;
      confirmMessage += `申し込み日時: ${cartSystemSubscriptionData.formattedCreatedAt}\n`;
      confirmMessage += `対象ツール: ${aspData === undefined ? "全て" : aspData.aspName
        }\n`;
      return confirmMessage;
    },
  },
};
</script>

<style lang="scss" scoped></style>
