<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>すでに最終CV最適化しているキャンペーンのオフラインCVの活用方法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>すでに最終CVで最適化しているキャンペーンのオフラインCVの活用方法を知ること</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【説明】</h3>
        <p>すでに最終CV運用しているキャンペーンの場合、オフラインコンバージョンの活用パターンは２つあります。</p>
        <h4>【１】最適化を既存の最終CVからオフラインCVに変更します（コンバージョンアクションを既存のCVからオフラインコンバージョンに変えます）</h4>
        <h5>＜メリット＞</h5>
        <ul>
          <li>正確な最終CVで最適化できます</li>
        </ul>
        <h5>＜デメリット＞</h5>
        <ul>
          <li>最初、オフラインCVにはデータがないので、CVデータが貯まるまで最適化に時間がかかります</li>
          <li>すでに上手く運用ができている場合は、最適化が崩れる可能性があります。</li>
        </ul>
        <h5>＜管理画面の見方＞</h5>
        <ul>
          <li>コンバージョン：オフラインCVのデータ</li>
          <li>すべてのコンバージョン：オフラインCVとMCVの合算値（MCVタグを設置している場合）</li>
        </ul>
        <h4>【２】最適化は既存の最終CVのままで、管理画面上で報酬額、利益、ROASを確認するためにオフラインコンバージョンを使います</h4>
        <h5>＜メリット＞</h5>
        <ul>
          <li>
            オフラインCVを使うことで、「キーワード、広告、デモグラ」など管理画面で正確な最終CV数、報酬額が管理画面で見れます。<br>
            →正確な精査ができます<br>
            今までは乖離があったため、正確な精査ができませんでした。<br>
            オフラインCVを使った場合は、正しい数値が管理画面上に表示されるので、精査な精査ができます。
          </li>
          <li>CVしたクエリが見れるオフラインCVとMCVの合算値（MCVタグを設置してない場合） も同様に、今までは乖離があったので、CVクエリの信憑性が低いです。<br>
しかし、オフラインCVを使った場合は、正しいCVクリエを確認することができます。
          </li>
        </ul>
        <h5>＜デメリット＞</h5>
        <ul>
          <li>最終CVで最適化ができません</li>
        </ul>
        <h5>＜管理画面の見方＞</h5>
        <ul>
          <li>コンバージョン：既存の最終CVのデータ</li>
          <li>すべてのコンバージョン：オフラインCVとMCVの合算値（MCVタグを設置している場合）<br style="margin-right: 30px;">オフラインCVとMCVの合算値（MCVタグを設置してない場合）</li>
        </ul>
        <h5>【３】リスクを減らす切り替え方</h5>
        <p>まずは、【２】の方法で運用していきます。そうすると、オフラインCVにデータが溜まっていきます。</p>
        <p>ある程度、オフラインCVにデータが溜まった状態で、最適化対象をオフラインCVに変更することで、最適化されるまでの期間が短くなります。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【まとめ】</h3>
        <p>「どう運用していくか？」は運用者自身の考え方、資金、目標、リスク許容度によって異なるため明確な答えは出せません。</p>
        <p>また、最適化対象をMCVからオフラインCVに変えて、最適化が崩れても責任を取ることはできません。</p>
        <p>なので、最初は【２】の方法で、正確な成果計測をするためにオフラインCVを活用してみてはどうでしょうか？</p>
        <p>そして、新規キャンペーンに関しては、最初からオフラインCVを設定するのがリスクが低いと思います。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
