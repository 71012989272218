<template>
  <div class="info">
    <el-row>
      <el-col>
        <h2>会員情報</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <UserData />
      </el-col>
    </el-row>
    <el-row style="margin-top: 50px">
      <el-col>
        <EmailList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import UserData from "@/components/Info/UserData.vue";
import EmailList from "@/components/Info/EmailList.vue";

export default {
  components: {
    UserData,
    EmailList,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(.description) {
  p {
    font-size: 12px;
    margin: 5px auto;
  }
}
</style>
