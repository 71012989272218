<template> 
  <el-button
    size="mini"
    @click="back"
    >戻る</el-button
  >
</template>

<script>

export default {
  name: 'BackButton',
  props: {
  },
  methods: {
    back() {

      let router = this.$router;
      let path = this.$route.path;
      let params = this.$route.params;

      let list = [
        [/^\/tools02\/(google-cv|yahoo-cv|facebook-cv|microsoft-cv|line-cv|yda-cv|cart-system)$/, '/'],
        [/^\/tools02\/(google-cv|yahoo-cv|facebook-cv|microsoft-cv|line-cv|yda-cv|cart-system)\/*?(parameter-manual|parameter-check|parameter-log|point-back|auto-import-log|report|initial-setting|usage-guide|bridges|api-histories|accounts)$/, '/tools02/__TOOL__'],
        [/^\/tools02\/(google-cv|yahoo-cv|facebook-cv|microsoft-cv|line-cv|yda-cv|cart-system)\/csv-manual-links/, '/tools02/__TOOL__/report'],

        [/^\/tools02\/(google-cv|yahoo-cv|facebook-cv|microsoft-cv|line-cv|yda-cv)\/account-detail\/(\d{1,}|undefined)$/, '/tools02/__TOOL__/accounts'],
        [/^\/tools02\/(google-cv|yahoo-cv|facebook-cv|microsoft-cv|line-cv|yda-cv)\/account-detail\/(\d{1,}|undefined)\/promotion\//, '/tools02/__TOOL__/account-detail/__ACCOUNT__'],

        [/^\/tools02\/(facebook-cv|line-cv|yda-cv)\/tags$/, '/tools02/__TOOL__'],
        [/^\/tools02\/(facebook-cv|line-cv|yda-cv)\/tag-detail*?/, '/tools02/__TOOL__/tags'],
        [/^\/tools02\/(facebook-cv|line-cv|yda-cv)\/units$/, '/tools02/__TOOL__'],
        [/^\/tools02\/(facebook-cv|line-cv|yda-cv)\/unit-detail*?/, '/tools02/__TOOL__/units'],
        [/^\/tools02\/(facebook-cv|line-cv|yda-cv)\/promotions$/, '/tools02/__TOOL__'],
        [/^\/tools02\/(facebook-cv|line-cv|yda-cv)\/promotion-detail*?/, '/tools02/__TOOL__/promotions'],

        [/^\/manual\/(google-cv|yahoo-cv|facebook-cv|microsoft-cv|line-cv|yda-cv)$/, '/tools02/__TOOL__'],
        [/^\/manual\/(google-cv|yahoo-cv|facebook-cv|microsoft-cv|line-cv|yda-cv)\/\d{1,}/, '/manual/__TOOL__'],

        [/^\/tools02\/cart-system\/.*?/, '/tools02/cart-system/'],

        [/^\/news$/, '/'],
        [/^\/news-detail/, '/news'],
        [/^\/account/, '/'],
        [/^\/info/, '/account'],
        [/^\/history/, '/account'],
        [/^\/change-password/, '/account'],
        [/^\/privileges/, '/account'],
        [/^\/affiliate/, '/account'],
        [/^\/affiliate\/.+/, '/affiliate'],
        [/^\/payment/, '/'],

        [/^\/ctrl\/affiliate\/.*?/, '/ctrl/affiliate'],
      ];

      list.forEach(function(data){

        let result = path.match(data[0]);
        
        if (result !== null) {

          if (params['toolCode'] !== undefined) {
            data[1] = data[1].replace('__TOOL__', params['toolCode']);
          } else {
            // no action.
          }

          if (params['accountId'] !== undefined) {
            data[1] = data[1].replace('__ACCOUNT__', params['accountId']);
          } else {
            // no action.
          }

          router.push(data[1]);

        } else {
          // no action.
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  margin: 10px 0;
}
</style>
