<template>
  <div class="cv">
    <el-row>
      <el-col :span="4">
        <router-link :to="{name: 'manual', params:{tool: lastVisitedToolCode},  query: {hash: 'conversion'}}">
          <Button2 type2="1" msg="マニュアル"/>
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h2>コンバージョン設定</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div>当画面で設定したコンバージョンおよびマイクロコンバージョンを、離脱防止ポップアップ、特定位置登場ポップアップ、ボタンタイプオーバーレイのコンバージョンおよびマイクロコンバージョンとして設定することができます。</div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between">
      <el-col :span="4">
        <router-link to="/cv-detail/0">
          <el-button type="primary">新規追加</el-button>
        </router-link>
        <Question
          :placement="'right'"
          :content="'「新規設定」から新しいコンバージョンの設定ができます。'"
        />
      </el-col>
      <el-col :span="10">
        <div style="text-align: right;">
          <Question
            :placement="'left'"
            :content="'マイクロコンバージョン測定用のタグです。<br>マイクロコンバージョン（中間コンバージョン）計測をしたいボタンがあるページに設置してください。<br>なお、設定する全てのマイクロコンバージョンにおいて共通のタグを使用します。'"
          />
          <el-button type="warning" @click="dialogVisible2 = true">MCV計測用タグ</el-button>
          <Question
            :placement="'top-end'"
            :content="'コンバージョン測定用のタグです。<br>コンバージョンを計測するサンクスページに設置してください。<br>なお、設定する全てのコンバージョンにおいて共通のタグを使用します。'"
          />
          <el-button type="success" @click="dialogVisible = true">CV計測用タグ</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>オフラインCV名</th>
              <th>1CVの金額</th>
              <th>一言メモ</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="index">
              <td>
                {{item.name}}
              </td>
              <td>
                {{item.price}}
              </td>
              <td>
                {{item.memo}}
              </td>
              <td class="btn">
                <router-link :to="`/cv-detail/${item.cv_id}`">
                  <el-button>編集</el-button>
                </router-link>
              </td>
              <td class="btn">
                <el-button type="danger" @click="del(item.cv_id)">削除</el-button>
              </td>
            </tr>
          </tbody>
        </table>

      </el-col>
    </el-row>

    <!-- ↓↓↓ CV用タグ ↓↓↓ -->
    <el-dialog
      title="CV用タグ"
      :visible.sync="dialogVisible"
      width="50%">

      <div>
        <div>
          <h3>【計測用タグ】</h3>
          <p>CV計測をしたいページに貼り付けてください。</p>
          <el-input type="textarea" :autosize="{ minRows: 3}" v-model="cv_tag" onclick="this.select();"></el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" style="padding: 10px 15px!important;">OK</el-button>
      </span>
    </el-dialog>
    <!-- ↑↑↑ CV用タグ ↑↑↑ -->

    <!-- ↓↓↓ MCV用タグ ↓↓↓ -->
    <el-dialog
      title="MCV用タグ"
      :visible.sync="dialogVisible2"
      width="50%">

      <div>
        <div>
          <h3>【計測用タグ】</h3>
          <p>MCV計測タグです。MCV計測をしたいボタンやテキストリンクなどのクリック要素があるページに設置してください。当タグを設置することで、MCVの計測が可能となります。</p>
          <el-input type="textarea" :autosize="{ minRows: 3}" v-model="mcv_tag" onclick="this.select();"></el-input>
          <!-- <p>jqueryを使用する場合、以下のタグをご使用ください。</p>
          <el-input type="textarea" :autosize="{ minRows: 3}" v-model="mcv_tag2" onclick="this.select();"></el-input> -->
        </div>
      </div>

      <div style="margin-top: 50px;">
        <div>
          <h3>【計測用ID】</h3>
          <p>MCVを計測したいリンク（ボタン・テキストなど）に計測用IDを付与してください。</p>
          <el-input type="textarea" :autosize="{ minRows: 1}" v-model="mcv_count" onclick="this.select();"></el-input>
        </div>
      </div>

      <div style="margin-top: 50px;">
        <div>
          <h3>【設置コードの例】</h3>
          <p>&lt;a href="/lp/" <span style="color:red;">id="mcvCountByAdtasukaru"</span>&gt;&lt;img src="/img/lp_button01.png" alt="LPへのリンクボタン"&gt;&lt;/a&gt;</p>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible2 = false" style="padding: 10px 15px!important;">OK</el-button>
      </span>
    </el-dialog>
    <!-- ↑↑↑ MCV用タグ ↑↑↑ -->

  </div>
</template>

<script>
import Button2 from "@/components/Ui/Button2.vue";
import Question from "@/components/Ui/Question.vue";

export default {
  components: {
    Button2,
    Question
  },
  data() {
    return {
      list: [],
      dialogVisible: false,
      dialogVisible2: false,
      mcv_tag: "",
      mcv_tag2: "",
      mcv_count: "",
      cv_tag: "",
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    lastVisitedToolCode() {
      return this.$store.getters.lastVisitedToolCode;
    }
  },
  created() {
    this.$axios
      .get(this.rt + "/api/mypage/getCvList.php")
      .then((res) => {
        console.log(res);
        this.list = res.data.list;
        this.mcv_tag = res.data.mcv_tag;
        this.mcv_tag2 = res.data.mcv_tag2;
        this.mcv_count = res.data.mcv_count;
        this.cv_tag = res.data.cv_tag;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    del(cv_id) {
      if(confirm('このCVを削除します。よろしいですか？')) {
        let params = new URLSearchParams();
				params.append('cv_id', cv_id);
				this.$axios.post(this.rt+'/api/mypage/delCvDetail.php', params)
				.then(res => {
          console.log(res);
          location.reload();
				})
				.catch(function (err) {
          console.log(err);
				});
      }
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
