<template>
  <div class="info">
    <el-row>
      <el-col>
        <h2>特典付与</h2>
      </el-col>
    </el-row>
    <div v-if="loading === true" v-loading="true" class="loading">
    </div>
    <el-row v-else>
      <el-col v-if="privilegeList.length > 0">
        <p>特典が獲得できました。</p>
        <p>
          「<router-link to="/privileges">特典一覧</router-link>」のページ内にあなたが受け取れる特典がすべて表示されます。
        </p>
        <p>
          各特典ごとに条件が明記されていますので、その条件を達成すると、特典を受け取ることができます。
        </p>
      </el-col>
      <el-col v-else>
        <p>キャンペーンが終了しています。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      loading: true,
      privilegeList: []
    };
  },
  props: {
    utmSource: String,
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  created() {
    let params = new URLSearchParams();
    params.append("privilege_id", this.utmSource);
    this.$axios
      .get(`${this.rt}/api/campaign/get-campaign-privileges.php?utm_source=${this.utmSource}`)
      .then((response) => {
        console.log(response);

        // 
        this.privilegeList = response.data.privilegeList;

        // 
        this.loading = false;

        // 
        if (this.privilegeList.length > 0) {

          // ============================================= ↓↓ 特典付与 ↓↓ =============================================
          this.privilegeList.forEach(privilegeData => {
            let params = new URLSearchParams();
            params.append("privilege_id", privilegeData.privilegeId);
            this.$axios
              // すみません、以前の話ではリクエストパスの名前を「ユーザー目線」（今回なら「get-privilege」）という話でしたが、
              // LaravelのControllerの名前とズレてややこしくなるかな？と思ったので、
              // 「リクエストパス」-「Controllerのメソッド名」-「サービスのメソッド名」は、原則同じでいきましょう。
              .post(`${this.rt}/l/api/privilege/create-privilege`, params)
              .then((response) => {
                console.log(response);
                if (response.data.status === 200) {
                  //
                } else {
                  return;
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
          // ============================================= ↑↑ 特典付与 ↑↑ =============================================

        } else {
          // no action.
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.loading {
  height: 200px;
}
.el-row {
  .el-col {
    text-align: center;
  }
}
</style>
