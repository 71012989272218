<template>
  <div class="tools01-abtest">
    <el-row>
      <el-col>
        <h2>ABテスト</h2>
      </el-col>
    </el-row>
    <AbTestList :toolId="toolId" :pageId="pageId" />
  </div>
</template>

<script>
import AbTestList from "@/components/Tools01/AbTest/AbTestList.vue";

export default {
  components: {
    AbTestList,
  },
  props: {
    toolId: String,
    pageId: String,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
