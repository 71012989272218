<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>プログラム情報登録<br class="sp-only">（TGアフィリエイト）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a
            href="https://www.trafficgate.net/affiliate/login.cgi"
            target="_blank"
            rel="noopener noreferrer"
            >管理画面</a
          >にログインしたら、「提携管理」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no20/01.png`" alt="" />
        <h4>
          【２】「提携中マーチャントを表示」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no20/02.png`" alt="" />
        <h4>
          【３】マーチャント一覧が表示されるので、登録したいマーチャントを探して、「詳細はこちらをご覧下さい」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no20/03.png`" alt="" />
        <h4>
          【４】プログラム名とプログラムIDをコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no20/04.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">
          --------------------------------
        </p>
        <h4>【５】アドタスカルのASPプログラム名とプログラムIDに貼り付けます。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no05/no20/05.png`" alt="" />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  components: {},
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
  created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
