<template>
  <div class="ctrl-contact">
    <h2>お問い合わせ管理</h2>
    <el-row :gutter="50" type="flex" justify="space-between" align="middle">
      <el-col :span="4">
        <router-link to="/ctrl">
          <Button3 msg="戻る" type="success" />
        </router-link>
      </el-col>
      <el-col :span="8">&nbsp;</el-col>
      <el-col :span="6">
        <h3>カテゴリ1</h3>
        <el-col :span="24" style="padding-left: 0px">
          <el-select
            v-model="cateSelect1"
            placeholder="選択してください"
            @change="change1"
            value-key="index"
          >
            <!-- <el-select
            v-model="cateSelect1"
            placeholder="選択してください"
            @change="change1(cateSelect1)"
            value-key="index"
            >-->
            <el-option
              v-for="(list1, index) in cateLists"
              :key="index"
              :label="list1.cate01_name"
              :value="index"
            ></el-option>
            <!-- <el-option
              v-for="(list1,index) in cateLists"
              :key="index"
              :label="list1.cate01_name"
              :value="list1.cate01"
            ></el-option>-->
          </el-select>
        </el-col>
      </el-col>
      <el-col :span="6">
        <h3>カテゴリ2</h3>
        <el-col :span="24" style="padding-left: 0px">
          <el-select v-model="cateSelect2" placeholder="選択してください">
            <el-option
              v-for="(list2, index) in subLists"
              :key="index"
              :label="list2.cate02_name"
              :value="list2.cate02"
            ></el-option>
          </el-select>
        </el-col>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="3">ステータス</el-col>
      <el-col :span="2"
        ><el-radio v-model="status" label="1">未対応</el-radio></el-col
      >
      <el-col :span="2"
        ><el-radio v-model="status" label="2">回答済</el-radio></el-col
      >
      <el-col :span="2"
        ><el-radio v-model="status" label="3">解決済</el-radio></el-col
      >
      <el-col :span="2"
        ><el-radio v-model="status" label="4">その他</el-radio></el-col
      >
    </el-row>

    <el-row>
      <el-col>
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>日時</th>
              <th>ステータス</th>
              <th>カテゴリ１</th>
              <th>カテゴリ２</th>
              <th>お問い合わせ者</th>
              <th>質問内容</th>
              <th>回答者</th>
              <th>詳細</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in contactLists" :key="index">
              <td>
                {{ item.date }}
              </td>
              <td>
                {{ item.status }}
              </td>
              <td>
                {{ item.category1 }}
              </td>
              <td>
                {{ item.category2 }}
              </td>
              <td>
                {{ item.contact_name }}
              </td>
              <td>
                {{ item.question }}
              </td>
              <td>
                {{ item.respondent }}
              </td>
              <td>
                <router-link :to="'/ctrl/contact-edit/' + item.user_id">
                  <el-button type="info" plain>確認</el-button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  data() {
    return {
      cateLists: [],
      contactLists: [],
      subLists: [],
      cateSelect1: "", //セレクトタグ用
      cateSelect2: "", //セレクトタグ用
      cateSelect1_select: "", //持ち運び用
      status: "", //ステータス
    }
  },
  components: {
    Button3,
  },
  mounted() {
    //セレクト用のカテゴリを取得
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getCategory.php")
      .then((res) => {
        console.log(res);
        console.log(res.data.list);
        this.cateLists = res.data.list;
        // this.List1 = res.data.list.list1;
      })
      .catch((err) => {
        console.log(err);
      });
    //お知らせ一覧を取得
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getContactList.php")
      .then((res) => {
        console.log(res);
        console.log(res.data.list);
        this.contactLists = res.data.list;
        // this.List1 = res.data.list.list1;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    change1: function (val) {
      // console.log(this.cateLists[val].cate01_name);
      // console.log(this.cateLists[val].cate01);
      this.cateSelect1_select = this.cateLists[val].cate01;
      this.subLists = this.cateLists[val].list2.map((e) => {
        console.log(e);
        return e;
      });
      console.log(this.subLists);
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
