<template>
  <div class="tools02-parameter-log">
    <el-row>
      <el-col>
        <h2>パラメータログ</h2>
      </el-col>
    </el-row>
    <ParameterPassingLogList :type="'default'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ParameterPassingLogList from "@/components/Tools02/ParameterLog/ParameterPassingLogList.vue";

export default {
  components: {
    ParameterPassingLogList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.total_tbl {
  tr {
    th,
    td {
      @media screen and (max-width: 767px) {
        font-size: 12px !important;
      }
    }
  }
}
</style>
