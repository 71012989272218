<template>
  <header :class="{ noSidebarHeader: login == 0 }">
    <div class="left">
      <router-link to="/">
        <img :src="`${rt}/img/logo_wh.png`" alt width="270px" />
      </router-link>
    </div>
    <div class="right">
      <div v-if="login == 1" class="point-box">
        <span>ポイント残数</span>
        <div class="point">{{ stockPoint | localeNum }}</div>
        <span>ポイント</span>
      </div>
      <div v-if="login == 1" class="header-btn" @click="move('/payment')">
        ポイント購入
      </div>
      <a
        v-show="login == 1"
        href="https://adtasukaru.wraptas.site/7452f432f2e54ae8a82eab9f67aa41d9"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img :src="`${rt}/img/asp.png`" alt />
      </a>
      <img
        v-show="login == 1"
        :src="`${rt}/img/account.gif`"
        @click="move('/account')"
        alt
      />
      <img
        v-show="login == 1"
        :src="`${rt}/img/subscription-histories.gif`"
        @click="move('/subscription-histories')"
        alt
      />
      <img
        v-show="login == 1"
        :src="`${rt}/img/privilege.gif`"
        @click="move('/privileges')"
        alt
      />
      <a
        v-show="login == 1"
        href="https://adtasukaru.wraptas.site/0fc95495a1a14c7d96e69756674d3864"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img :src="`${rt}/img/faq.gif`" alt />
      </a>
      <img
        v-show="login == 1"
        :src="`${rt}/img/affiliate.gif`"
        @click="move('/affiliate')"
        alt
      />
      <img
        v-if="login == 0 && $route.path != '/login'"
        :src="`${rt}/img/login.gif`"
        @click="move('/login')"
        alt
      />
      <img
        v-if="login == 1"
        :src="`${rt}/img/logout.gif`"
        @click="logout"
        alt
      />
    </div>
    <div class="hamburger" v-if="login == 1">
      <UiDrawer />
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import UiDrawer from "@/components/Ui/Drawer.vue";
export default {
  name: "Header",
  props: {
    pageName: String,
  },
  data() {
    return {};
  },
  components: {
    UiDrawer,
  },
  computed: {
    ...mapGetters(["rt", "login", "openSidebar", "stockPoint"]),
  },
  watch: {},
  methods: {
    move(path) {
      this.$router.push(path).catch((err) => {
        console.log(err);
      });
    },
    logout() {
      this.$axios
        .get(`${this.rt}/api/logout.php`)
        .then((res) => {
          console.log(res);
          this.$store.dispatch("setLogin", 0);
          this.$router.push("/logout");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
header {
  background-color: var(--black);
  color: white;
  width: 100%;
  height: 70px;
  padding: 10px 5px;
  // padding-right: 280px;
  box-sizing: border-box;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 999;
  &.noSidebarHeader {
    padding-right: 10px !important;
  }

  .left {
    margin-right: auto;
    @media screen and (max-width: 1240px) {
      margin-left: 10px;
    }
    a {
      display: flex;
      align-items: center;
    }
  }
  .right {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1240px) {
      display: none;
    }
    .point-box {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .point {
        font-size: 26px;
        padding: 2px 15px;
        border: solid 1px white;
        border-radius: 12px;
        margin: auto 12px;
      }
    }
    .header-btn {
      border: solid 1px white;
      padding: 7px 18px;
      border-radius: 50px;
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
    }
    img {
      margin: 0 3px;
      cursor: pointer;
      width: 72px;
      &:hover {
        opacity: 0.7;
      }
    }
    a {
      display: flex;
    }
  }
}
.hamburger {
  display: none;
  @media screen and (max-width: 1240px) {
    display: initial;
    margin-right: 10px;
  }
}

.img02 {
  display: none;

  @media screen and (max-width: 767px) {
    .btn {
      position: static;
      bottom: 0;
      left: 0;
      margin-top: 20px;
    }
  }
}
</style>
