<template>
  <div class="yda-conversion-api-history-list">
    <el-table :data="conversionApiHistoryList" border>
      <el-table-column
        fixed
        label="API送信日時"
        min-width="160px"
        prop="sendDatetime"
      >
      </el-table-column>
      <el-table-column label="アカウント名">
        <template slot-scope="scope">
          <span>{{
            scope.row.tools02TagData.tools02AccountData.accountName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="プログラム名">
        <template slot-scope="scope">
          <span v-if="scope.row.reportData.tools02PromotionData === null"
            >-</span
          >
          <span v-else>{{
            scope.row.reportData.tools02PromotionData.promotionName
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="コンバージョン名">
        <template slot-scope="scope">
          <span>{{ scope.row.tools02TagData.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="yahoo_ydn_conv_io">
        <template slot-scope="scope">
          <span>{{ scope.row.tools02TagMediaTagId }}</span>
        </template>
      </el-table-column>
      <el-table-column label="yahoo_ydn_conv_label">
        <template slot-scope="scope">
          <span>{{ scope.row.tools02TagAccessToken }}</span>
        </template>
      </el-table-column>
      <el-table-column label="API送信結果">
        <template slot-scope="scope">
          <span v-if="scope.row.eventReceived === true">成功</span>
          <span v-else>失敗</span>
        </template>
      </el-table-column>
      <el-table-column width="500px" label="エラーメッセージ">
        <template slot-scope="scope">
          <el-input readonly v-model="scope.row.errorMessage">
            <el-button
              @click="g_copyText(scope.row.errorMessage)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column min-width="200px" label="CV発生日時">
        <template slot-scope="scope">
          <span v-if="scope.row.reportData.formattedAdtasukaruIdCreatedAt === null"
            >{{ scope.row.eventDatetime }}</span
          >
          <span v-else>
            <span
              :class="scope.row.eventDatetime < scope.row.reportData.formattedAdtasukaruIdCreatedAt
                  ? 'red'
                  : ''
              "
              >{{ scope.row.eventDatetime }}</span
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column
        min-width="160px"
        prop="yahooYdnConvValue"
        label="報酬額"
      >
      </el-table-column>
      <el-table-column min-width="200px" label="atid生成日時">
        <template slot-scope="scope">
          <span v-if="scope.row.reportData.formattedAdtasukaruIdCreatedAt === null">-</span>
          <span v-else>{{ scope.row.reportData.formattedAdtasukaruIdCreatedAt }}</span>
        </template>
      </el-table-column>
      <el-table-column label="atid">
        <template slot-scope="scope">
          <span>{{ scope.row.reportData.adtasukaruId }}</span>
        </template>
      </el-table-column>
      <el-table-column min-width="300px" label="yclid">
        <template slot-scope="scope">
          <el-input readonly :value="scope.row.yclid">
            <el-button
              @click="g_copyText(scope.row.yclid)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column min-width="210px" label="yjr">
        <template slot-scope="scope">
          <el-input readonly :value="scope.row.yjrYjad" style="width: 185px">
            <el-button
              @click="g_copyText(scope.row.yjrYjad)"
              slot="append"
              icon="el-icon-document-copy"
            ></el-button>
          </el-input>
        </template>
      </el-table-column>
      <el-table-column fixed="right" min-width="80px" label="再送">
        <template slot-scope="scope">
          <el-button
            @click="resendConversionEvent(scope.row)"
            type="primary"
            size="mini"
            >再送</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "yda-conversion-api-history-list",
  components: {},
  props: {
    conversionApiHistoryList: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  watch: {},
  methods: {
    resendConversionEvent(apiHistoryData) {
      console.log(apiHistoryData);

      //
      const promotionName =
        apiHistoryData.reportData.tools02PromotionData !== null
          ? apiHistoryData.reportData.tools02PromotionData.promotionName
          : "-";

      //
      let confirmText = "";
      confirmText += `API送信日時: ${apiHistoryData.sendDatetime}\n`;
      confirmText += `アカウント名: ${apiHistoryData.tools02TagData.tools02AccountData.accountName}\n`;
      confirmText += `プログラム名: ${promotionName}\n`;
      confirmText += `コンバージョン名: ${apiHistoryData.tools02TagData.name}\n`;
      confirmText += `yahoo_ydn_conv_io: ${apiHistoryData.tools02TagData.mediaTagId}\n`;
      confirmText += `yahoo_ydn_conv_label: ${apiHistoryData.tools02TagData.accessToken}\n`;
      confirmText += `CV発生日時: ${apiHistoryData.adtasukaruIdCreatedDatetime}\n`;
      confirmText += `報酬額: ${apiHistoryData.yahooYdnConvValue}\n`;
      confirmText += `atid: ${apiHistoryData.reportData.adtasukaruId}\n`;
      confirmText += `yclid: ${apiHistoryData.yclid}\n`;
      confirmText += `yjr: ${apiHistoryData.yjrYjad}\n`;
      confirmText += `\n`;
      confirmText += `のAPIを再送信します。よろしいですか？`;

      //
      let payload = {
        confirmText: confirmText,
        apiHistoryData: apiHistoryData,
      };
      this.$emit("resendConversionEvent", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
