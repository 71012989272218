<template>
  <div class="tools02-parameter-manual-set-lp-tag-in-wp">
    <h3 class="back-gray">設置完了</h3>
    <p>これでパラメータ引継ぎ設定は完了です。</p>
    <p>
      「<router-link :to="`/tools02/${tool.codeKebab}/parameter-check`"
        >パラメータチェック</router-link
      >」で設定に不備がないか確認しましょう。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02ParameterManualSetLpTagInWP",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
