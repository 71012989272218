<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（メディパートナー）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/point-back`"
            >ASP自動連携設定</router-link
          >」にアクセスします。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`"
          alt=""
        />
        <h4>【２】該当するASPの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no17/01.png`" alt="" />
        <h4>【３】メディパートナーの担当者さんに以下の依頼文とセットで連絡する</h4>
        <p>▼依頼文<br />
          -----------------------<br />
          アドタスカルとの連携と成果テストをお願い致します。<br />
          <br />
          （【２】でコピーしたをメディパートナーの通知URL）<br />
          <br />
          案件名：◯◯<br />
          -----------------------
        </p>
        <p class="red">※メディパートナーでは案件ごとにアドタスカルの連携が必要になります。</p>
        <h4>【４】連携が完了すると、メディパートナーさんでCVテストしてくれます。</h4>
        <h4>
          【５】アドタスカルの「<router-link
            :to="`/tools02/${tool.codeKebab}/auto-import-log`"
            >自動登録履歴</router-link
          >」を確認します。
        </h4>
        <img
          :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`"
          alt=""
        />
        <h4>【６】数分後に、テストCVが登録されていれば問題なく連携完了です。</h4>
        <AutoImportLog 
          :aspId="'22'"
          :parameter="'gclid_1234'"
          :aspPromotionName="'test'"
          :conversionValue="'1'"
        />
        <ul>
          <li>ASP：メディパートナー</li>
          <li>
            CV時間：テストした時間になっているか？（多少の誤差はあり）
          </li>
          <li>プログラム名：-</li>
          <li>パラメータ：「gclid_xxxx」（xxxxの部分は変わります）</li>
          <li>プログラムID：test</li>
          <li>報酬額：1</li>
          <li>
            インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です。
          </li>
        </ul>
        <h4>【７】連携完了報告をしましょう。</h4>
        <p>無事、連携できたことを報告しましょう！</p>
        <p>これでアドタスカルとメディパートナーさんとの連携は完了です。</p>
        <PointbackSettingDescription :aspId="'22'" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PointbackSettingDescription,
    AutoImportLog,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
