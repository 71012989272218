<template>
  <div class="tools02-pixel-detail">
    <el-row>
      <el-col>
        <h2>
          <span
            v-if="
              tool.codeKebab === 'facebook-cv' || tool.codeKebab === 'tiktok-cv'
            "
            >ピクセル</span
          >
          <span v-else-if="tool.codeKebab === 'line-cv'">LINE Tag </span>
          <span v-else-if="tool.codeKebab === 'yda-cv'">コンバージョン</span>
          <span v-if="tagId === '0'">新規登録</span>
          <span v-else>編集</span>
        </h2>
      </el-col>
    </el-row>
    <TagEditor :toolId="toolId" :accountId="accountId" :tagId="tagId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TagEditor from "@/components/Tools02/TagDetail/Editor.vue";

export default {
  components: {
    TagEditor,
  },
  props: {
    toolId: String,
    accountId: String,
    tagId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {
    if (this.tool.codeKebab === "line-cv") {
      this.setTitle("広告成果連携ツール LINE Tag詳細");
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
