<template>
  <div
    v-loading="loading"
    class="affiliate-status-affiliated-user-list-affiliated-user-data"
  >
    <el-row>
      <el-col>
        <h4>獲得NO：{{ affiliatedUserData.affiliatedId }}</h4>
        <table class="af-pop">
          <tr>
            <th>登録日</th>
            <td>{{ affiliatedUserData.registeredAt }}</td>
            <th>残ポイント</th>
            <td>{{ affiliatedUserData.stockPoints | localeNum }}</td>
          </tr>
          <tr>
            <th>自動更新設定</th>
            <td>
              <span v-if="affiliatedUserData.subscriptionStatus === true"
                >ON</span
              >
              <span v-else>OFF</span>
            </td>
            <th>設定中の自動更新プラン</th>
            <td>{{ affiliatedUserData.planName }}</td>
          </tr>
        </table>
        <div style="text-align: right; margin-top: 5px">
          「設定中の自動更新プラン」は自動更新を設定中のプランが表示されます。
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="10">
        <h4>決済情報</h4>
        <table class="af-pop">
          <tr
            v-for="(
              settlementData, settlementIndex
            ) in affiliatedUserData.settlementList"
            :key="settlementIndex"
          >
            <th>{{ settlementData.planName }}</th>
            <td>{{ settlementData.times }}</td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col
        v-for="(toolData, toolIndex) in affiliatedUserData.toolDataList"
        :key="toolIndex"
        :span="12"
      >
        <h4>{{ toolData.toolName }}</h4>
        <table class="af-pop">
          <tr>
            <th style="width: 70%;">利用設定</th>
            <td>
              <span v-if="toolData.isActive === true">ON</span>
              <span v-else>OFF</span>
            </td>
          </tr>
        </table>
        <table class="af-pop" style="margin-top: 20px;">
          <thead>
            <tr>
              <th>ステップ</th>
              <th>達成ステータス</th>
              <th>達成日時</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(bonusData, bonusIndex) in toolData.bonusDataList"
              :key="bonusIndex"
            >
              <th>{{ bonusData.name }}</th>
              <td>
                <span v-if="bonusData.status === '0'">×</span>
                <span v-else-if="bonusData.status === '1'">○</span>
              </td>
              <td>{{ bonusData.getDatetime }}</td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateStatusAffiliatedUserListAffiliatedUserData",
  components: {},
  props: {
    affiliatedUserId: String,
  },
  data() {
    return {
      loading: true,
      affiliatedUserData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    affiliatedUserId: {
      handler(newValue) {
        this.getAffiliatedUserData(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    getAffiliatedUserData(affiliatedUserId) {
      this.loading = true;
      this.$axios
        .get(
          `${this.rt}/api/affiliate/get-affiliated-user-data.php?affiliated_user_id=${affiliatedUserId}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.affiliatedUserData = res.data.affiliatedUserData;
            this.loading = false;
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
