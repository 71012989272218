<template>
  <div class="tests-post-settle-complete-data">
    <table>
      <tr>
        <th>ShopID</th>
        <td><el-input v-model="ShopID"></el-input></td>
      </tr>
      <tr>
        <th>ShopPass</th>
        <td><el-input v-model="ShopPass"></el-input></td>
      </tr>
      <tr>
        <th>AccessID</th>
        <td><el-input v-model="AccessID"></el-input></td>
      </tr>
      <tr>
        <th>AccessPass</th>
        <td><el-input v-model="AccessPass"></el-input></td>
      </tr>
      <tr>
        <th class="red">OrderID</th>
        <td><el-input v-model="OrderID"></el-input></td>
      </tr>
      <tr>
        <th>Status</th>
        <td><el-input v-model="Status"></el-input></td>
      </tr>
      <tr>
        <th>JobCd</th>
        <td><el-input v-model="JobCd"></el-input></td>
      </tr>
      <tr>
        <th class="red">Amount</th>
        <td><el-input v-model="Amount"></el-input></td>
      </tr>
      <tr>
        <th class="red">Tax</th>
        <td><el-input v-model="Tax"></el-input></td>
      </tr>
      <tr>
        <th>Currency</th>
        <td><el-input v-model="Currency"></el-input></td>
      </tr>
      <tr>
        <th>Forward</th>
        <td><el-input v-model="Forward"></el-input></td>
      </tr>
      <tr>
        <th>Method</th>
        <td><el-input v-model="Method"></el-input></td>
      </tr>
      <tr>
        <th>PayTimes</th>
        <td><el-input v-model="PayTimes"></el-input></td>
      </tr>
      <tr>
        <th>TranID</th>
        <td><el-input v-model="TranID"></el-input></td>
      </tr>
      <tr>
        <th>Approve</th>
        <td><el-input v-model="Approve"></el-input></td>
      </tr>
      <tr>
        <th class="red">TranDate</th>
        <td><el-input v-model="TranDate"></el-input></td>
      </tr>
      <tr>
        <th>ErrCode</th>
        <td><el-input v-model="ErrCode"></el-input></td>
      </tr>
      <tr>
        <th>ErrInfo</th>
        <td><el-input v-model="ErrInfo"></el-input></td>
      </tr>
      <tr>
        <th>PayType</th>
        <td><el-input v-model="PayType"></el-input></td>
      </tr>
    </table>
    <div style="text-align: center; margin-top: 20px">
      <el-button @click="postSettleCompleteData" type="primary">送信</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tests-post-settle-complete-data",
  components: {},
  props: {},
  data() {
    return {
      ShopID: "9200002644627",
      ShopPass: "**********",
      AccessID: "baed856e168a8761e2889228b91ebc18",
      AccessPass: "********************************",
      OrderID: "",
      Status: "CAPTURE",
      JobCd: "CAPTURE",
      Amount: "",
      Tax: "",
      Currency: "JPN",
      Forward: "2s77334",
      Method: "1",
      PayTimes: "",
      TranID: "2203150000602257249317850078",
      Approve: "0002011",
      TranDate: "",
      ErrCode: "",
      ErrInfo: "",
      PayType: "0",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  methods: {
    postSettleCompleteData() {
      if (
        this.ShopID !== "" &&
        this.OrderID !== "" &&
        this.Status !== "" &&
        this.JobCd !== "" &&
        this.Amount !== "" &&
        this.Tax !== "" &&
        this.TranDate !== ""
      ) {
        //no action.
      } else {
        alert("入力されていない項目があります。");
        return;
      }
      let params = new URLSearchParams();
      params.append("ShopID", this.ShopID);
      params.append("ShopPass", this.ShopPass);
      params.append("AccessID", this.AccessID);
      params.append("AccessPass", this.AccessPass);
      params.append("OrderID", this.OrderID);
      params.append("Status", this.Status);
      params.append("JobCd", this.JobCd);
      params.append("Amount", this.Amount);
      params.append("Tax", this.Tax);
      params.append("Currency", this.Currency);
      params.append("Forward", this.Forward);
      params.append("Method", this.Method);
      params.append("PayTimes", this.PayTimes);
      params.append("TranID", this.TranID);
      params.append("Approve", this.Approve);
      params.append("TranDate", this.TranDate);
      params.append("ErrCode", this.ErrCode);
      params.append("ErrInfo", this.ErrInfo);
      params.append("PayType", this.PayType);
      params.append("test_password", "bKdsJs3A37t9t9xaADE427AffbqdATxT");
      this.$axios
        .post(`${this.rt}/api/payment/settleComplete.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data === 0) {
            alert("成功！");
            return;
          }else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  tr {
    th {
      width: 25%;
    }
    td {
    }
  }
}
</style>
