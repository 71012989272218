<template>
  <div class="tools02-promotion-detail">
    <el-row
      v-show="
        toolData.codeKebab === 'facebook-cv' ||
        toolData.codeKebab === 'tiktok-cv' ||
        toolData.codeKebab === 'line-cv' ||
        toolData.codeKebab === 'yda-cv'
      "
    >
      <el-col>
        <h2 v-if="promotionId === '0'">プログラム 新規登録</h2>
        <h2 v-else>プログラム 編集</h2>
      </el-col>
    </el-row>
    <Tools02PromotionDetailPromotionEditor
      :toolId="toolId"
      :accountId="accountId"
      :promotionId="promotionId"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02PromotionDetailPromotionEditor from "@/components/Tools02/PromotionDetail/PromotionEditor.vue";

export default {
  components: {
    Tools02PromotionDetailPromotionEditor,
  },
  props: {
    toolId: String,
    accountId: String,
    promotionId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
