<template>
  <div class="tools02-parameter-manual-set-lp-tag-in-wp">
    <h3 class="back-gray">
      <span>LPタグの設置（</span>
      <span v-if="category01 === 1">WordPress</span>
      <span v-else-if="category01 === 2">HTML、PHP</span>
      <span v-else-if="category01 === 3">Squad beyond</span>
      <span>）</span>
    </h3>
    <el-row>
      <el-col>
        <h4>【１】以下のアドタスカルLPタグをコピーしてください。</h4>
        <div class="left-space">
          <el-input
            type="textarea"
            :autosize="{ minRows: 1 }"
            v-model="normalTagString"
            onclick="this.select();"
          />
        </div>

        <template v-if="tool.codeKebab === 'facebook-cv'"> </template>
        <template v-else>
          <p class="left-space2">
            コピーしたタグを
            <span v-if="category01 === 3">「JavaScript body」</span>
            <span v-else>&lt;/body>の直前</span>
            に設置します。
          </p>
          <p v-show="category01 === 1" class="red sp-red">
            ※SWELLをお使いの場合、&lt;/body>は「footer.php」だけでなく、「single-lp.php」にも存在します。<br
              class="pc-only"
            />
            LPをご使用の場合、「single-lp.php」内を編集するようにしてください。
          </p>
        </template>
      </el-col>

      <template v-if="category01 === 1">
        <template v-if="tool.codeKebab === 'facebook-cv'">
          <el-col>
            <h4>
              【２】出稿する記事の編集画面を開きます。入力欄の最下部にLPタグを貼り付けます。
            </h4>
            <p class="red left-space">
              ※LPタグはユニットごとに異なるので、ご注意ください
            </p>
            <p class="red left-space">
              ※WordPressのテーマやプラグインによるタグの入力欄がある場合、そちらに設置しても大丈夫です。
            </p>
            <img :src="`${rt}/img/tools02/parameter-manual1-4.png`" alt="" />
            <h4>【３】「更新」をクリックしてください。</h4>
            <img :src="`${rt}/img/tools02/parameter-manual1-5.png`" alt="" />
            <p class="left-space">これでLPタグの設置は完了です。</p>
          </el-col>
        </template>
        <template v-else>
          <el-col>
            <h4>【２】WordPressの管理画面に移動してください。</h4>
          </el-col>
          <el-col>
            <h4>【３】「外観」→「テーマエディター」の順にクリックします。</h4>
            <img :src="`${rt}/img/tools02/parameter-manual1-1.png`" alt="" />
          </el-col>
          <el-col>
            <h4>
              【４】テーマを編集から「テーマフッター（footer.php）」を開きます。
            </h4>
            <img
              :src="`${rt}/img/tools02/parameter-manual1-2.png`"
              alt=""
              class="big sp"
            />
          </el-col>
          <el-col>
            <h4>【５】先程コピーしたタグを&lt;/body>の直前に設置します。</h4>
            <p>[ファイルを更新]をクリックして保存しましょう。</p>
            <img
              v-if="tool.codeKebab === 'google-cv'"
              :src="`${rt}/img/tools02/parameter-manual1-3.png`"
              alt=""
              class="big sp"
            />
            <h4 class="red">【注意点１】</h4>
            <p class="red" style="margin-left: 35px">
              WordPressのテーマのアップデート時にLPタグが外れるケースがございます。<br />テーマをアップデートした際は、LPタグの再設定をお願いいたします。
            </p>
            <h4 class="red">【注意点２】</h4>
            <p class="red" style="margin-left: 35px">
              WordPressのテーマによっては、テーマフッターがなかったり、
              テーマフッターを編集しても反映されない場合があります。
            </p>
            <p class="red" style="margin-left: 35px">
              その場合は、直接記事の下部にLPタグを設置しても動作します。
            </p>
            <img
              v-if="tool.codeKebab === 'google-cv'"
              :src="`${rt}/img/tools02/parameter-manual3-3.png`"
              alt=""
              class="big"
            />
          </el-col>
        </template>
      </template>
      <template v-else-if="category01 === 2">
        <el-col>
          <h4>【２】コピーしたタグを&lt;/body>の直前に設置します。</h4>
          <img :src="`${rt}/img/tools02/parameter-manual2-1.png`" alt="" />
          <p class="kome">
            ※ランキングサイトや比較サイトなど複数ページがある場合、<span
              style="color: red; text-decoration: underline"
              >全てのページ</span
            >に設置してください。
          </p>
        </el-col>
      </template>
      <template v-else-if="category01 === 3">
        <el-col>
          <h4>【２】設定したいbeyondページの「Version」に移動してください。</h4>
          <img
            :src="`${rt}/img/tools02/parameter-manual3-4.png`"
            alt=""
            style="width: 95%"
          />
          <h4>【３】「タグ設定」をクリックします。</h4>
          <img :src="`${rt}/img/tools02/parameter-manual3-5.png`" alt="" />
          <h4>
            【４】JavaScript
            bodyに先程コピーしたLPタグを貼り付けて、「保存」をクリックします。
          </h4>
          <img
            v-if="tool.codeKebab === 'facebook-cv'"
            :src="`${rt}/img/tools02/parameter-manual3-6-2.png`"
            alt=""
          />
          <img
            v-else
            :src="`${rt}/img/tools02/parameter-manual3-6.png`"
            alt=""
          />

          <p class="kome">
            ※ランキングサイトや比較サイトなど複数ページがある場合、<span
              style="color: red; text-decoration: underline"
              >全てのページ</span
            >に設置してください。
          </p>
        </el-col>
        <el-col>
          <h3 class="back-gray">Squad beyondの基本方法の設定</h3>
          <p>「基本情報」ボタンをクリックします。</p>
          <img :src="`${rt}/img/tools02/parameter-manual3-7.png`" alt="" />
          <p>
            トラッキング項目で「計測ツール・ASP」は<span class="red2"
              >何も選択しない</span
            >でください。
          </p>
          <img :src="`${rt}/img/tools02/parameter-manual3-8.png`" alt="" />
          <p>
            もし、選択されている場合は「×」をクリックしてください。<br /><span
              class="red"
              >Beyond側の仕様とバッティングして、アドタスカルのパラメータ引き継ぎが動かなくなります。</span
            >
          </p>
          <img :src="`${rt}/img/tools02/parameter-manual3-9.png`" alt="" />
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02ParameterManualSetLpTagInWP",
  components: {},
  props: {
    category01: Number,
    normalTag: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    normalTagString() {
      return this.normalTag;
    }
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
img,
.tag {
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

h4 {
  margin-top: 40px;
  margin-bottom: 15px;
}
img {
  border: 3px solid gray;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
}

.big {
  width: 95%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.tag {
  border: 2px solid gray;
  background-color: #f8f8ff;
  width: 70%;
  padding: 10px;
}
.kome {
  font-weight: bold;
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}
.left-space {
  padding-left: 40px;
  @media screen and (max-width: 768px) {
    padding-left: 0 !important;
  }
}
.left-space2 {
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
  }
}
.sp-red {
  font-weight: bold;
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 5px;
    word-wrap: break-word;
  }
}
</style>
