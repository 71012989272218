<template>
  <div class="ctrl-affiliate-home-link-buttons">
    <el-row :gutter="20">
      <el-col :span="8">
        <router-link to="/ctrl/affiliate/rewards">
          <MenuButton :text="`ユーザー報酬額確認`" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/affiliate/payment-information">
          <MenuButton :text="`報酬支払情報管理`" />
        </router-link>
      </el-col>
      <el-col :span="8">
        <router-link to="/ctrl/affiliate/download-bank-csv">
          <MenuButton :text="`報酬処理用CSVダウンロード`" />
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuButton from "@/components/Ui/MenuButton.vue";

export default {
  name: "CtrlAffiliateHomeLinkButtons",
  components: {
    MenuButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
</style>
