<template>
  <div class="format0401">
    <div class="format-cover3">
      <div :id="'OrdersOutlineByAdtasukaru'+format_id" style="width: 100%;border: solid 1px #999;box-sizing: border-box;">
        <div
          v-if="format.type == 1"
          class="header"
          style="width:100%;height:30px;background:#777;display:flex;justify-content:center;align-items:center;"
        >
          <p class="status">
            <span style="margin-right: 0.5em;">現在の注文状況</span>
            {{datetime}}更新
          </p>
        </div>
        <div v-if="format.type == 2 || format.type == 3">
          <div
            class="header"
            style="width:100%;height:30px;background:#777;display:flex;justify-content:center;align-items:center;"
          >
            <p class="status">
              <span style="margin-right: 0.5em;">現在の注文状況</span>
              {{datetime}}更新
            </p>
          </div>
          <div
            v-if="format.type == 2"
            class="header"
            style="width:100%;height:30px;display:flex;justify-content:center;align-items:center;"
          >
            <table class="gadget-table">
              <tr>
                <th class="col01" style="border-right: solid 1px #aaa;">時間</th>
                <th class="col02" style="border-right: solid 1px #aaa;">都道府県</th>
                <th class="col03">注文情報</th>
              </tr>
            </table>
          </div>
        </div>

        <div class="order-outline" :style="'overflow:hidden;height:'+format.int10+'px;'"></div>
      </div>
      <!-- #OrdersOutlineByAdtasukaru -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Format0401",
  props: {
    format: [Array, Object],
  },
  data() {
    return {
      pref_list: [],
      item_list: [],
      datetime: "",
      format_id: ''
    }
  },
  coumputed: {
    rt() {
      return this.$store.getters.rt;
    }
  },
  mounted() {
    var seed = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var password = '';
    var length = 10;
    for (var i = 0; i < length; i++) {
      password += seed.charAt(Math.floor(Math.random() * seed.length));
    }
    this.format_id = password;

    this.$axios.get(this.rt + "/api/getItemList.php").then(res => {
      console.log(res);
      this.item_list = res.data.list;

      this.$axios.get(this.rt + "/api/getPrefList2.php").then(res => {
        console.log(res);
        this.pref_list = res.data.list;
        this.callorders(0, 0);
      });
    });
  },
  methods: {
    callorders(type, cnt) {
      var ms = this.format.float01 * 1000;

      //定義
      var removeBox =
        "#OrdersOutlineByAdtasukaru"+this.format_id+" .order-outline > .order-box:last-child";
      var appendArea = "#OrdersOutlineByAdtasukaru"+this.format_id+" .order-outline";
      var appendArea2 =
        "#OrdersOutlineByAdtasukaru"+this.format_id+" .order-outline > .order-box:first-child";
      if (this.format.type == 1) {
        var padding = "padding:5px;";
      }
      var appendContent =
        '<div class="order-box" style="width: 100%;height: 85px;box-sizing: border-box;' +
        padding +
        '"></div>';

      if (type == 0) {
        //初期状態

        for (let index = 0; index < this.format.int09; index++) {
          var appendContent2 = this.getDPP();
          var firstOrders =
            '<div class="order-box" style="width: 100%;height: 85px;box-sizing: border-box;' +
            padding +
            '">' +
            appendContent2 +
            "</div>";
          $(appendArea).append(firstOrders);
        }
        this.callorders(1, cnt);
      } else {
        //追加・削除

        appendContent2 = this.getDPP();

        setTimeout(
          function() {
            if (cnt != 0) {
              $(removeBox).remove();
            }
            $(removeBox).fadeOut((ms * 1) / 4);

            setTimeout(
              function() {
                $(appendContent)
                  .prependTo(appendArea)
                  .hide()
                  .slideDown((ms * 1) / 3);

                setTimeout(
                  function() {
                    $(appendContent2)
                      .appendTo(appendArea2)
                      .hide()
                      .fadeIn((ms * 1) / 4);
                  }.bind(this),
                  (ms * 1) / 3
                );

                this.callorders(1, 1);
              }.bind(this),
              (ms * 1) / 4
            );
          }.bind(this),
          ms
        );
      }
    },
    getDPP() {
      var date_obj = new Date();
      var year = date_obj.getFullYear();
      var month = date_obj.getMonth() + 1;
      var day = date_obj.getDate();
      var hour = date_obj.getHours();
      var minute = date_obj.getMinutes();

      this.datetime =
        year + "年" + month + "月" + day + "日 " + hour + "時" + minute + "分";

      //デバイス画像の決定
      var n = Math.random();
      var int112 = this.format.int11 / 100;
      if (n < int112) {
        var device = "sp";
      } else {
        device = "pc";
      }

      //都道府県の決定
      var prefs = this.format.array02;
      var pref = prefs[Math.floor(Math.random() * prefs.length)];
      var pref_name = this.pref_list[pref - 1];

      //商品の決定
      var products = this.format.array01;
      if (products.length == 0 || products[0] == '') {
        var product = { id: 0, name: "undefined", img2: 0 }
      } else {
        console.log("来たよ！！！！！！！！！！！！！！！！！！！！！！");
        var item_id = products[Math.floor(Math.random() * products.length)];
        product = this.item_list.find(e => {
          return e.id == item_id;
        });
      }

      //画像の決定
      if (product.img2 == 0) {
        var img = this.rt + "/uploads/user/item/active/no_photo.jpg";
      } else {
        img = this.rt + "/uploads/user/item/active/" + product.img2;
      }
      console.log(img);

      if (this.format.type == 1) {
        var appendContent2 =
          '<div class="order" style="width: 100%;height: 100%;border: solid 1px #aaa;border-bottom: none;box-sizing: border-box;padding: 5px 10px;display: flex;align-items: center;">' +
          '<img src="' +
          this.rt +
          "/img/" +
          device +
          '.png" style="width: 50px;">' +
          '<div class="order-detail" style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;text-align: center;">' +
          '<p style="font-size: 12px;color: #666;font-weight: bold;">' +
          "「" +
          pref_name +
          '」のお客様より<span class="product" style="font-size: 15px;font-weight: bold;color: red;margin: 0 5px;">' +
          product.name +
          "</span>のご注文をいただきました！<br>" +
          '<span class="order-number" style="color: #999;">ご注文番号：EN20180******</span>' +
          "</p>" +
          "</div>" +
          "</div>";
      } else if (this.format.type == 2) {
        appendContent2 =
          '<table class="gadget-table" style="box-sizing: border-box;border-collapse: collapse;border-bottom: solid 1px #aaa;border-top: solid 1px #aaa;width: 100%;height: 100%;">' +
          "<tr>" +
          '<td style="font-size:14px;border-right: solid 1px #aaa;width:20%;text-align:center;">' +
          month +
          "月" +
          day +
          "日<br>" +
          hour +
          "時" +
          minute +
          "分" +
          "</td>" +
          '<td style="border-right: solid 1px #aaa;width:20%;text-align:center;">' +
          pref_name +
          "</td>" +
          '<td style="padding:5px;">「' +
          product.name +
          "」<br>をご注文いただきました！</td>" +
          "</tr>" +
          "</table>";
      } else if (this.format.type == 3) {
        appendContent2 =
          '<table class="gadget-table" style="box-sizing: border-box;border-collapse: collapse;border-bottom: solid 1px #aaa;border-top: solid 1px #aaa;width: 100%;height: 100%;">' +
          "<tr>" +
          '<td style="border-right: solid 1px #aaa;width:20%;text-align:center;padding:2px;">' +
          '<div style="position: relative;width:100%;height:100%;">' +
          '<img src="' +
          img +
          '" style="position: absolute;top: 0;bottom: 0;left: 0;right: 0;height: auto;width: auto;max-width: 100%;max-height: 100%;margin: auto;">' +
          "</div>" +
          "</td>" +
          '<td style="font-size:14px;border-right: solid 1px #aaa;width:20%;text-align:center;">' +
          month +
          "月" +
          day +
          "日<br>" +
          hour +
          "時" +
          minute +
          "分<br>" +
          pref_name +
          "</td>" +
          '<td style="padding:5px;">「' +
          product.name +
          "」<br>をご注文いただきました！</td>" +
          "</tr>" +
          "</table>";
      }

      return appendContent2;
    }
  }
}
</script>

<style lang="scss" scoped>
.format-cover3 {
  background: white;
}
.gadget-table {
  box-sizing: border-box;
  border-collapse: collapse;
  border-bottom: solid 1px #aaa;
  width: 100%;
  height: 100%;
  .col01 {
    width: 20%;
  }
  .col02 {
    width: 20%;
  }
}
p.status {
  margin: 0;
  color: white;
  font-weight: bold;
  font-size: 14.5px;
}
</style>