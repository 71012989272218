<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>プログラム情報登録<br class="sp-only">（CATS）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CommonDescription :systemType="'cats'" />
        <h4>
          【１】<a
            href="https://price-tag.site/front/login/"
            target="_blank"
            rel="noopener noreferrer"
            >管理画面</a
          >にログインしたら、「レポート集計」の「広告・媒体別」をクリックします。
        </h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no05/no24/01.png`" alt="" />
        </div>
        <h4>
          【２】登録したい広告を探して、「広告名」をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no24/02.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">
          --------------------------------
        </p>
        <h4>【３】アドタスカルのASPプログラム名に貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no24/03.png`" alt="" />
        <h4>【４】プログラムIDはご自身で決めます。</h4>
        <p>◎決め方</p>
        <ul>
          <li>半角英数字</li>
          <li>同じASP内で実施している他の案件と被らないようにする</li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonDescription from "@/components/Manual/Tools02/Parts/CommonDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    CommonDescription,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
  created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
