<template>
  <div class="manual-tools01-editor">
    <el-row>
      <el-col>
        <h2 id="editor">フォーマット作成エディターの使い方（各ツール共通）</h2>
        <h3>フォーマット作成エディターの使い方</h3>
        <p>アドタスカルの各フォーマットを作成・編集するエディター機能の使用方法について、離脱防止ポップアップの本体部分を例として解説します。</p>
        <img :src="`${rt}/img/manual/manual3-2-01.jpg`" alt="">
        <h3>編集対象の選択</h3>
        <p>タブをクリックするとことで、編集の対象を変更することができます。</p>
        <img :src="`${rt}/img/manual/manual3-2-02.jpg`" alt="">
        <h3>背景色の設定</h3>
        <p>「背景色」を選択することで、選択している編集対象の背景色を変更することができます。選択後に「OK」をクリックして確定してください（※「背景色」が選択できないエディターもあります）。</p>
        <img :src="`${rt}/img/manual/manual3-2-03.jpg`" alt="">
        <h3>テキストの設定</h3>
        <p>エディタ―上に、デフォルトで設定されているテキストを編集することで、フォーマットに表示するテキストを変更できます。キーボードのエンターを押すと改行されます。</p>
        <img :src="`${rt}/img/manual/manual3-2-04.jpg`" alt="">
        <h3>テキストの装飾</h3>
        <p>装飾したいテキスト部分を選択します。</p>
        <img :src="`${rt}/img/manual/manual3-2-05.jpg`" alt="">
        <p>選択状態にした上で、エディター内にある「フォントカラー設定」や「フォントサイズ設定」などを選択することで、テキストを装飾することができます。</p>
        <img :src="`${rt}/img/manual/manual3-2-06.jpg`" alt="">
        <h3>画像の挿入・設定</h3>
        <p>「画像を挿入」をクリックすると、画像エディターが立ち上がり、フォーマットに画像を挿入することができます。</p>
        <img :src="`${rt}/img/manual/manual3-2-07.jpg`" alt="">
        <p>画像エディターが立ち上がったら、「＋クリックしてファイル選択」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-2-08.jpg`" alt="">
        <p>画像の選択画面が表示されるので、アップロードする画像を指定します。</p>
        <img :src="`${rt}/img/manual/manual3-2-09.jpg`" alt="">
        <p>指定した画像が表示されるので、「アップロード」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-2-10.jpg`" alt="">
        <p>以下の画面が表示されるので「OK」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-2-11.jpg`" alt="">
        <p>「画像一覧」のタブをクリックすると、アップロードした画像が表示されます。</p>
        <img :src="`${rt}/img/manual/manual3-2-12.jpg`" alt="">
        <p>アップロードした画像をクリックすると、以下のように選択状態となるので、「画像挿入」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-2-13.jpg`" alt="">
        <p>画像が挿入されました。</p>
        <img :src="`${rt}/img/manual/manual3-2-14.jpg`" alt="">
        <p>挿入した画像をクリックすると表示される、四角形の部分をマウスで調整すると、画像サイズを変更することができます。</p>
        <img :src="`${rt}/img/manual/manual3-2-15.jpg`" alt="">
        <h3>フォーマットのサイズ調整</h3>
        <p>「その他」のタブをクリックすると、フォーマットのサイズ調整ができます。図に従って、サイズ調整を行ってください。</p>
        <img :src="`${rt}/img/manual/manual3-2-16.jpg`" alt="">
        <h3>作成・編集を確定させる</h3>
        <p>「登録」をクリックすると、作成・編集が確定します。</p>
        <img :src="`${rt}/img/manual/manual3-2-17.jpg`" alt="">
        <p>以上が、エディター機能の基本的な使い方です。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01Editor',
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
