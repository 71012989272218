<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（CATS）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a href="https://pe-k.site/front/login/" target="_blank"
            >CATSの管理画面</a
          >にログインしたら、「ログ集計」→「成果ログ」の順にクリックします。
        </h4>
        <img
          :src="`${rt}/img/manual/google-cv/no07/no19/01.png`"
          alt=""
          class="mini-img"
        />
        <h4>【２】成果期間を選択→「実行」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no19/02.png`" alt="" />
        <h4>
          【３】成果が表示されたら、「CL付加情報」にクリックパラメータ（gclid_xxx）が表示されているか確認します。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no19/03.png`" alt="" />
        <p class="red">
          ※パラメータが表示されていない場合、パラメータ引継ぎ設定を間違えている可能性があります。
          <br />「<router-link :to="`/manual/${tool.codeKebab}/06/02`"
            >パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。
        </p>
        <h4>
          【４】CSVをダウンロードします。「CSV生成」を選択して、「実行」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no19/04.png`" alt="" />

        <p>これで成果CSVのダウンロードは完了です。</p>
        「
        <router-link :to="`/manual/${tool.codeKebab}/07/14`"
          >ASPの成果データをアドタスカルにインポートする</router-link
        >」に進んでください。
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.mini-img {
  width: 50%;
}
</style>
