<template>
  <div class="home-news-list">
    <div class="news_header">
      <div>お知らせ</div>
      <div
        @click="toggle"
        class="toggle"
      >
        <span v-if="newsOpen == 1">
          閉じる
          <img
            :src="`${rt}/img/close.png`"
            width="12px"
          />
        </span>
        <span v-else>
          開く
          <img
            :src="`${rt}/img/open.png`"
            width="12px"
          />
        </span>
      </div>
    </div>
    <div
      class="news_outline"
      style="display: none"
    >
      <table class="typeB">
        <tr
          v-for="(news, index) in newsList"
          :key="index"
          v-show="news.confirm == 0"
          :class="{ opend: news.opend == 1 }"
        >
          <td>{{ news.release_date }}</td>
          <td>
            <router-link
              :to="`/news-detail/${news.id}`"
              :class="{ unique: news.post_id == 0 }"
            >{{ news.title }}</router-link>
          </td>
        </tr>
      </table>
      <div
        align="center"
        style="margin-bottom:15px;margin-top:15px;"
      >
        <el-button @click="GoNewsList">もっと見る</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "HomeNewsList",
  components: {},
  props: {},
  data() {
    return {
      newsList: [],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
    newsOpen() {
      return this.$store.getters.newsOpen;
    },
  },
  created() {
    this.$axios
      .get(`${this.rt}/api/getUsersPostList.php`)
      .then((res) => {
        console.log(res);
        if (res.data.count == 0) {
          this.$store.dispatch("toggleNewsOpen", 0);
        } else {
          this.newsList = res.data.list;
          if (this.newsOpen == 1) {
            $(".news_outline").slideDown();
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    confirm(index, id) {
      //表示を消す
      this.newsList[index].confirm = 1;

      //データベースのconfirm=1
      let params = new URLSearchParams();
      params.append("id", id);
      this.$axios
        .post(`${this.rt}/api/confirmPost.php`, params)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggle() {
      if (this.newsOpen == 0) {
        $(".news_outline").slideDown();
        this.$store.dispatch("toggleNewsOpen", 1);
      } else {
        $(".news_outline").slideUp();
        this.$store.dispatch("toggleNewsOpen", 0);
      }
    },
    GoNewsList: function () {
      //お知らせ一覧へ飛ぶ
      this.$router.push({ name: "news" });
    },
  },
};
</script>

<style lang="scss" scoped>
.news_header {
  color: var(--black);
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  .toggle {
    cursor: pointer;
    span {
      font-size: 16px;
      font-weight: normal;
    }
  }
}
.news_outline {
  padding: 40px;
  box-sizing: border-box;
   @media screen and (max-width: 787px){
     padding: 40px 10px;
   }
  .typeB {
    width: 100%;
    tr {
      font-weight: bold;
      td {
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          a {
            color: var(--black);
            text-decoration: none;
          }
          a.unique {
            color: #4595f9;
          }
        }
        &:nth-child(3) {
          width: 10%;
          text-align: center;
        }
      }
    }
    tr.opend {
      font-weight: normal;
      background-color: #eee;
    }
  }
  h3 {
    margin: 0;
  }
  .single {
    margin-top: 15px;
  }
}
</style>
