<template>
  <div class="ctrl-users">
    <h2>ユーザー検索</h2>
    <UserList />
  </div>
</template>

<script>
import UserList from "@/components/Ctrl/Users/UserList.vue";

export default {
  data() {
    return {};
  },
  components: {
    UserList
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
