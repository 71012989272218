<template>
  <div class="manual-home">
    <el-row>
      <el-col>
        <!-- ================================ ↓↓ tools01 ↓↓ ================================ -->
        <div
          v-if="
            tool.codeKebab === 'popup01' ||
            tool.codeKebab === 'popup02' ||
            tool.codeKebab === 'overlay01' ||
            tool.codeKebab === 'overlay02'
          "
        ></div>
        <!-- ================================ ↓↓ tools01 ↓↓ ================================ -->

        <!-- ================================ ↓↓ tools02 ↓↓ ================================ -->
        <div
          v-else-if="
            tool.codeKebab === 'google-cv' ||
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'yahoo-cv'
          "
        >
          <!-- ↓↓ No01 ↓↓ -->
          <div v-if="category01 === '01'">
            <T02N01N01 v-if="category02 === '01'" />
            <T02N01N02 v-if="category02 === '02'" />
            <T02N01N03 v-if="category02 === '03'" />
          </div>
          <!-- ↑↑ No01 ↑↑ -->

          <!-- ↓↓ No02 ↓↓ -->
          <div v-else-if="category01 === '02'">
            <T02N02N01 v-if="category02 === '01'" />
          </div>
          <!-- ↑↑ No02 ↑↑ -->

          <!-- ↓↓ No03 ↓↓ -->
          <div v-else-if="category01 === '03'">
            <T02N03N01 v-if="category02 === '01'" />
            <T02N03N02 v-else-if="category02 === '02'" />
            <T02N03N03 v-else-if="category02 === '03'" />
            <T02N03N05 v-else-if="category02 === '05'" />
            <T02N03N06 v-else-if="category02 === '06'" />
            <T02N03N07 v-else-if="category02 === '07'" />
            <T02N03N08 v-else-if="category02 === '08'" />
            <T02N03N09 v-else-if="category02 === '09'" />
            <T02N03N10 v-else-if="category02 === '10'" />
            <T02N03N11 v-else-if="category02 === '11'" />
            <T02N03N12 v-else-if="category02 === '12'" />
          </div>
          <!-- ↑↑ No03 ↑↑ -->

          <!-- ↓↓ No04 ↓↓ -->
          <div v-else-if="category01 === '04'">
            <T02N04N01 v-if="category02 === '01'" />
            <T02N04N02 v-else-if="category02 === '02'" />
            <T02N04N03 v-else-if="category02 === '03'" />
            <T02N04N04 v-else-if="category02 === '04'" />
            <T02N04N05 v-else-if="category02 === '05'" />
            <T02N04N06 v-else-if="category02 === '06'" />
          </div>
          <!-- ↑↑ No04 ↑↑ -->

          <!-- ↓↓ No05 ↓↓ -->
          <div v-else-if="category01 === '05'">
            <T02N05N01 v-if="category02 === '01'" />
            <T02N05N02 v-else-if="category02 === '02'" />
            <T02N05N03 v-else-if="category02 === '03'" />
            <T02N05N04 v-else-if="category02 === '04'" />
            <T02N05N05 v-else-if="category02 === '05'" />
            <T02N05N06 v-else-if="category02 === '06'" />
            <T02N05N07 v-else-if="category02 === '07'" />
            <T02N05N08 v-else-if="category02 === '08'" />
            <T02N05N09 v-else-if="category02 === '09'" />
            <T02N05N10 v-else-if="category02 === '10'" />
            <T02N05N11 v-else-if="category02 === '11'" />
            <T02N05N12 v-else-if="category02 === '12'" />
            <T02N05N13 v-else-if="category02 === '13'" />
            <T02N05N14 v-else-if="category02 === '14'" />
            <T02N05N15 v-else-if="category02 === '15'" />
            <T02N05N16 v-else-if="category02 === '16'" />
            <T02N05N17 v-else-if="category02 === '17'" />
            <T02N05N18 v-else-if="category02 === '18'" />
            <T02N05N19 v-else-if="category02 === '19'" />
            <T02N05N20 v-else-if="category02 === '20'" />
            <T02N05N21 v-else-if="category02 === '21'" />
            <T02N05N22 v-else-if="category02 === '22'" />
            <T02N05N23 v-else-if="category02 === '23'" />
            <T02N05N24 v-else-if="category02 === '24'" />
            <T02N05N25 v-else-if="category02 === '25'" />
            <T02N05N26 v-else-if="category02 === '26'" />
          </div>
          <!-- ↑↑ No05 ↑↑ -->

          <!-- ↓↓ No06 ↓↓ -->
          <div v-else-if="category01 === '06'">
            <T02N06N01 v-if="category02 === '01'" />
            <T02N06N02 v-else-if="category02 === '02'" />
            <T02N06N03 v-else-if="category02 === '03'" />
            <T02N06N04 v-else-if="category02 === '04'" />
            <T02N06N05 v-else-if="category02 === '05'" />
            <T02N06N06 v-else-if="category02 === '06'" />
          </div>
          <!-- ↑↑ No06 ↑↑ -->

          <!-- ↓↓ No07 ↓↓ -->
          <div v-else-if="category01 === '07'">
            <T02N07N00 v-if="category02 === '00'" />
            <T02N07N01 v-else-if="category02 === '01'" />
            <T02N07N02 v-else-if="category02 === '02'" />
            <T02N07N03 v-else-if="category02 === '03'" />
            <T02N07N04 v-else-if="category02 === '04'" />
            <T02N07N05 v-else-if="category02 === '05'" />
            <T02N07N06 v-else-if="category02 === '06'" />
            <T02N07N07 v-else-if="category02 === '07'" />
            <T02N07N08 v-else-if="category02 === '08'" />
            <T02N07N09 v-else-if="category02 === '09'" />
            <T02N07N10 v-else-if="category02 === '10'" />
            <T02N07N11 v-else-if="category02 === '11'" />
            <T02N07N12 v-else-if="category02 === '12'" />
            <T02N07N13 v-else-if="category02 === '13'" />
            <T02N07N14 v-else-if="category02 === '14'" />
            <T02N07N15 v-else-if="category02 === '15'" />
            <T02N07N16 v-else-if="category02 === '16'" />
            <T02N07N17 v-else-if="category02 === '17'" />
            <T02N07N18 v-else-if="category02 === '18'" />
            <T02N07N19 v-else-if="category02 === '19'" />
            <T02N07N20 v-else-if="category02 === '20'" />
            <T02N07N21 v-else-if="category02 === '21'" />
            <T02N07N22 v-else-if="category02 === '22'" />
            <T02N07N23 v-else-if="category02 === '23'" />
            <T02N07N24 v-else-if="category02 === '24'" />
            <T02N07N25 v-else-if="category02 === '25'" />
            <T02N07N26 v-else-if="category02 === '26'" />
            <T02N07N27 v-else-if="category02 === '27'" />
            <T02N07N28 v-else-if="category02 === '28'" />
            <T02N07N29 v-else-if="category02 === '29'" />
          </div>
          <!-- ↑↑ No07↑↑ -->

          <!-- ↓↓ No08 ↓↓ -->
          <div v-else-if="category01 === '08'">
            <T02N08N00 v-if="category02 === '00'" />
            <T02N08N01 v-else-if="category02 === '01'" />
            <T02N08N02 v-else-if="category02 === '02'" />
            <T02N08N03 v-else-if="category02 === '03'" />
            <T02N08N04 v-else-if="category02 === '04'" />
            <T02N08N05 v-else-if="category02 === '05'" />
            <T02N08N06 v-else-if="category02 === '06'" />
            <T02N08N07 v-else-if="category02 === '07'" />
            <T02N08N08 v-else-if="category02 === '08'" />
            <T02N08N09 v-else-if="category02 === '09'" />
            <T02N08N10 v-else-if="category02 === '10'" />
            <T02N08N11 v-else-if="category02 === '11'" />
            <T02N08N12 v-else-if="category02 === '12'" />
            <T02N08N13 v-else-if="category02 === '13'" />
            <T02N08N14 v-else-if="category02 === '14'" />
            <T02N08N15 v-else-if="category02 === '15'" />
            <T02N08N16 v-else-if="category02 === '16'" />
            <T02N08N17 v-else-if="category02 === '17'" />
            <T02N08N18 v-else-if="category02 === '18'" />
            <T02N08N19 v-else-if="category02 === '19'" />
            <T02N08N20 v-else-if="category02 === '20'" />
            <T02N08N21 v-else-if="category02 === '21'" />
            <T02N08N22 v-else-if="category02 === '22'" />
            <T02N08N23 v-else-if="category02 === '23'" />
            <T02N08N24 v-else-if="category02 === '24'" />
            <T02N08N25 v-else-if="category02 === '25'" />
            <T02N08N26 v-else-if="category02 === '26'" />
            <T02N08N27 v-else-if="category02 === '27'" />
            <T02N08N28 v-else-if="category02 === '28'" />
          </div>
          <!-- ↑↑ No08↑↑ -->

          <!-- ↓↓ No09 ↓↓ -->
          <div v-else-if="category01 === '09'">
            <T02N09N01 v-if="category02 === '01'" />
            <T02N09N02 v-else-if="category02 === '02'" />
            <T02N09N03 v-else-if="category02 === '03'" />
            <T02N09N04 v-else-if="category02 === '04'" />
            <T02N09N05 v-else-if="category02 === '05'" />
            <T02N09N06 v-else-if="category02 === '06'" />
            <T02N09N07 v-else-if="category02 === '07'" />
          </div>
          <!-- ↑↑ No09↑↑ -->

          <!-- ↓↓ No10 ↓↓ -->
          <div v-else-if="category01 === '10'">
            <T02N10N01 v-if="category02 === '01'" />
            <T02N10N02 v-if="category02 === '02'" />
            <T02N10N03 v-if="category02 === '03'" />
          </div>
          <!-- ↑↑ No10 ↑↑ -->

          <!-- ↓↓ No11 ↓↓ -->
          <div v-else-if="category01 === '11'">
            <T02N11N01 v-if="category02 === '01'" />
            <T02N11N02 v-if="category02 === '02'" />
            <T02N11N03 v-if="category02 === '03'" />
            <T02N11N04 v-if="category02 === '04'" />
            <T02N11N05 v-if="category02 === '05'" />
            <T02N11N06 v-if="category02 === '06'" />
            <T02N11N07 v-if="category02 === '07'" />
            <T02N11N08 v-if="category02 === '08'" />
            <T02N11N09 v-if="category02 === '09'" />
            <T02N11N10 v-if="category02 === '10'" />
          </div>
          <!-- ↑↑ No11 ↑↑ -->

          <!-- ↓↓ No12 ↓↓ -->
          <div v-else-if="category01 === '12'">
            <T02N12N01 v-if="category02 === '01'" />
            <T02N12N02 v-if="category02 === '02'" />
            <T02N12N03 v-if="category02 === '03'" />
          </div>
          <!-- ↑↑ No12 ↑↑ -->
        </div>
        <!-- ================================ ↑↑ tools02 ↑↑ ================================ -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import T02N01N01 from "@/components/Manual/Tools02/No01/No01.vue";
import T02N01N02 from "@/components/Manual/Tools02/No01/No02.vue";
import T02N01N03 from "@/components/Manual/Tools02/No01/No03.vue";
import T02N02N01 from "@/components/Manual/Tools02/No02/No01.vue";
import T02N03N01 from "@/components/Manual/Tools02/No03/No01.vue";
import T02N03N02 from "@/components/Manual/Tools02/No03/No02.vue";
import T02N03N03 from "@/components/Manual/Tools02/No03/No03.vue";
import T02N03N05 from "@/components/Manual/Tools02/No03/No05.vue";
import T02N03N06 from "@/components/Manual/Tools02/No03/No06.vue";
import T02N03N07 from "@/components/Manual/Tools02/No03/No07.vue";
import T02N03N08 from "@/components/Manual/Tools02/No03/No08.vue";
import T02N03N09 from "@/components/Manual/Tools02/No03/No09.vue";
import T02N03N10 from "@/components/Manual/Tools02/No03/No10.vue";
import T02N03N11 from "@/components/Manual/Tools02/No03/No11.vue";
import T02N03N12 from "@/components/Manual/Tools02/No03/No12.vue";
import T02N04N01 from "@/components/Manual/Tools02/No04/No01.vue";
import T02N04N02 from "@/components/Manual/Tools02/No04/No02.vue";
import T02N04N03 from "@/components/Manual/Tools02/No04/No03.vue";
import T02N04N04 from "@/components/Manual/Tools02/No04/No04.vue";
import T02N04N05 from "@/components/Manual/Tools02/No04/No05.vue";
import T02N04N06 from "@/components/Manual/Tools02/No04/No06.vue";
import T02N05N01 from "@/components/Manual/Tools02/No05/No01.vue";
import T02N05N02 from "@/components/Manual/Tools02/No05/No02.vue";
import T02N05N03 from "@/components/Manual/Tools02/No05/No03.vue";
import T02N05N04 from "@/components/Manual/Tools02/No05/No04.vue";
import T02N05N05 from "@/components/Manual/Tools02/No05/No05.vue";
import T02N05N06 from "@/components/Manual/Tools02/No05/No06.vue";
import T02N05N07 from "@/components/Manual/Tools02/No05/No07.vue";
import T02N05N08 from "@/components/Manual/Tools02/No05/No08.vue";
import T02N05N09 from "@/components/Manual/Tools02/No05/No09.vue";
import T02N05N10 from "@/components/Manual/Tools02/No05/No10.vue";
import T02N05N11 from "@/components/Manual/Tools02/No05/No11.vue";
import T02N05N12 from "@/components/Manual/Tools02/No05/No12.vue";
import T02N05N13 from "@/components/Manual/Tools02/No05/No13.vue";
import T02N05N14 from "@/components/Manual/Tools02/No05/No14.vue";
import T02N05N15 from "@/components/Manual/Tools02/No05/No15.vue";
import T02N05N16 from "@/components/Manual/Tools02/No05/No16.vue";
import T02N05N17 from "@/components/Manual/Tools02/No05/No17.vue";
import T02N05N18 from "@/components/Manual/Tools02/No05/No18.vue";
import T02N05N19 from "@/components/Manual/Tools02/No05/No19.vue";
import T02N05N20 from "@/components/Manual/Tools02/No05/No20.vue";
import T02N05N21 from "@/components/Manual/Tools02/No05/No21.vue";
import T02N05N22 from "@/components/Manual/Tools02/No05/No22.vue";
import T02N05N23 from "@/components/Manual/Tools02/No05/No23.vue";
import T02N05N24 from "@/components/Manual/Tools02/No05/No24.vue";
import T02N05N25 from "@/components/Manual/Tools02/No05/No25.vue";
import T02N05N26 from "@/components/Manual/Tools02/No05/No26.vue";
import T02N06N01 from "@/components/Manual/Tools02/No06/No01.vue";
import T02N06N02 from "@/components/Manual/Tools02/No06/No02.vue";
import T02N06N03 from "@/components/Manual/Tools02/No06/No03.vue";
import T02N06N04 from "@/components/Manual/Tools02/No06/No04.vue";
import T02N06N05 from "@/components/Manual/Tools02/No06/No05.vue";
import T02N06N06 from "@/components/Manual/Tools02/No06/No06.vue";
import T02N07N00 from "@/components/Manual/Tools02/No07/No00.vue";
import T02N07N01 from "@/components/Manual/Tools02/No07/No01.vue";
import T02N07N02 from "@/components/Manual/Tools02/No07/No02.vue";
import T02N07N03 from "@/components/Manual/Tools02/No07/No03.vue";
import T02N07N04 from "@/components/Manual/Tools02/No07/No04.vue";
import T02N07N05 from "@/components/Manual/Tools02/No07/No05.vue";
import T02N07N06 from "@/components/Manual/Tools02/No07/No06.vue";
import T02N07N07 from "@/components/Manual/Tools02/No07/No07.vue";
import T02N07N08 from "@/components/Manual/Tools02/No07/No08.vue";
import T02N07N09 from "@/components/Manual/Tools02/No07/No09.vue";
import T02N07N10 from "@/components/Manual/Tools02/No07/No10.vue";
import T02N07N11 from "@/components/Manual/Tools02/No07/No11.vue";
import T02N07N12 from "@/components/Manual/Tools02/No07/No12.vue";
import T02N07N13 from "@/components/Manual/Tools02/No07/No13.vue";
import T02N07N14 from "@/components/Manual/Tools02/No07/No14.vue";
import T02N07N15 from "@/components/Manual/Tools02/No07/No15.vue";
import T02N07N16 from "@/components/Manual/Tools02/No07/No16.vue";
import T02N07N17 from "@/components/Manual/Tools02/No07/No17.vue";
import T02N07N18 from "@/components/Manual/Tools02/No07/No18.vue";
import T02N07N19 from "@/components/Manual/Tools02/No07/No19.vue";
import T02N07N20 from "@/components/Manual/Tools02/No07/No20.vue";
import T02N07N21 from "@/components/Manual/Tools02/No07/No21.vue";
import T02N07N22 from "@/components/Manual/Tools02/No07/No22.vue";
import T02N07N23 from "@/components/Manual/Tools02/No07/No23.vue";
import T02N07N24 from "@/components/Manual/Tools02/No07/No24.vue";
import T02N07N25 from "@/components/Manual/Tools02/No07/No25.vue";
import T02N07N26 from "@/components/Manual/Tools02/No07/No26.vue";
import T02N07N27 from "@/components/Manual/Tools02/No07/No27.vue";
import T02N07N28 from "@/components/Manual/Tools02/No07/No28.vue";
import T02N07N29 from "@/components/Manual/Tools02/No07/No29.vue";
import T02N08N00 from "@/components/Manual/Tools02/No08/No00.vue";
import T02N08N01 from "@/components/Manual/Tools02/No08/No01.vue";
import T02N08N02 from "@/components/Manual/Tools02/No08/No02.vue";
import T02N08N03 from "@/components/Manual/Tools02/No08/No03.vue";
import T02N08N04 from "@/components/Manual/Tools02/No08/No04.vue";
import T02N08N05 from "@/components/Manual/Tools02/No08/No05.vue";
import T02N08N06 from "@/components/Manual/Tools02/No08/No06.vue";
import T02N08N07 from "@/components/Manual/Tools02/No08/No07.vue";
import T02N08N08 from "@/components/Manual/Tools02/No08/No08.vue";
import T02N08N09 from "@/components/Manual/Tools02/No08/No09.vue";
import T02N08N10 from "@/components/Manual/Tools02/No08/No10.vue";
import T02N08N11 from "@/components/Manual/Tools02/No08/No11.vue";
import T02N08N12 from "@/components/Manual/Tools02/No08/No12.vue";
import T02N08N13 from "@/components/Manual/Tools02/No08/No13.vue";
import T02N08N14 from "@/components/Manual/Tools02/No08/No14.vue";
import T02N08N15 from "@/components/Manual/Tools02/No08/No15.vue";
import T02N08N16 from "@/components/Manual/Tools02/No08/No16.vue";
import T02N08N17 from "@/components/Manual/Tools02/No08/No17.vue";
import T02N08N18 from "@/components/Manual/Tools02/No08/No18.vue";
import T02N08N19 from "@/components/Manual/Tools02/No08/No19.vue";
import T02N08N20 from "@/components/Manual/Tools02/No08/No20.vue";
import T02N08N21 from "@/components/Manual/Tools02/No08/No21.vue";
import T02N08N22 from "@/components/Manual/Tools02/No08/No22.vue";
import T02N08N23 from "@/components/Manual/Tools02/No08/No23.vue";
import T02N08N24 from "@/components/Manual/Tools02/No08/No24.vue";
import T02N08N25 from "@/components/Manual/Tools02/No08/No25.vue";
import T02N08N26 from "@/components/Manual/Tools02/No08/No26.vue";
import T02N08N27 from "@/components/Manual/Tools02/No08/No27.vue";
import T02N08N28 from "@/components/Manual/Tools02/No08/No28.vue";
import T02N09N01 from "@/components/Manual/Tools02/No09/No01.vue";
import T02N09N02 from "@/components/Manual/Tools02/No09/No02.vue";
import T02N09N03 from "@/components/Manual/Tools02/No09/No03.vue";
import T02N09N04 from "@/components/Manual/Tools02/No09/No04.vue";
import T02N09N05 from "@/components/Manual/Tools02/No09/No05.vue";
import T02N09N06 from "@/components/Manual/Tools02/No09/No06.vue";
import T02N09N07 from "@/components/Manual/Tools02/No09/No07.vue";
import T02N10N01 from "@/components/Manual/Tools02/No10/No01.vue";
import T02N10N02 from "@/components/Manual/Tools02/No10/No02.vue";
import T02N10N03 from "@/components/Manual/Tools02/No10/No03.vue";
import T02N11N01 from "@/components/Manual/Tools02/No11/No01.vue";
import T02N11N02 from "@/components/Manual/Tools02/No11/No02.vue";
import T02N11N03 from "@/components/Manual/Tools02/No11/No03.vue";
import T02N11N04 from "@/components/Manual/Tools02/No11/No04.vue";
import T02N11N05 from "@/components/Manual/Tools02/No11/No05.vue";
import T02N11N06 from "@/components/Manual/Tools02/No11/No06.vue";
import T02N11N07 from "@/components/Manual/Tools02/No11/No07.vue";
import T02N11N08 from "@/components/Manual/Tools02/No11/No08.vue";
import T02N11N09 from "@/components/Manual/Tools02/No11/No09.vue";
import T02N11N10 from "@/components/Manual/Tools02/No11/No10.vue";
import T02N12N01 from "@/components/Manual/Tools02/No12/No01.vue";
import T02N12N02 from "@/components/Manual/Tools02/No12/No02.vue";
import T02N12N03 from "@/components/Manual/Tools02/No12/No03.vue";

export default {
  components: {
    T02N01N01,
    T02N01N02,
    T02N01N03,
    T02N02N01,
    T02N03N01,
    T02N03N02,
    T02N03N03,
    T02N03N05,
    T02N03N06,
    T02N03N07,
    T02N03N08,
    T02N03N09,
    T02N03N10,
    T02N03N11,
    T02N03N12,
    T02N04N01,
    T02N04N02,
    T02N04N03,
    T02N04N04,
    T02N04N05,
    T02N04N06,
    T02N05N01,
    T02N05N02,
    T02N05N03,
    T02N05N04,
    T02N05N05,
    T02N05N06,
    T02N05N07,
    T02N05N08,
    T02N05N09,
    T02N05N10,
    T02N05N11,
    T02N05N12,
    T02N05N13,
    T02N05N14,
    T02N05N15,
    T02N05N16,
    T02N05N17,
    T02N05N18,
    T02N05N19,
    T02N05N20,
    T02N05N21,
    T02N05N22,
    T02N05N23,
    T02N05N24,
    T02N05N25,
    T02N05N26,
    T02N06N01,
    T02N06N02,
    T02N06N03,
    T02N06N04,
    T02N06N05,
    T02N06N06,
    T02N07N00,
    T02N07N01,
    T02N07N02,
    T02N07N03,
    T02N07N04,
    T02N07N05,
    T02N07N06,
    T02N07N07,
    T02N07N08,
    T02N07N09,
    T02N07N10,
    T02N07N11,
    T02N07N12,
    T02N07N13,
    T02N07N14,
    T02N07N15,
    T02N07N16,
    T02N07N17,
    T02N07N18,
    T02N07N19,
    T02N07N20,
    T02N07N21,
    T02N07N22,
    T02N07N23,
    T02N07N24,
    T02N07N25,
    T02N07N26,
    T02N07N27,
    T02N07N28,
    T02N07N29,
    T02N08N00,
    T02N08N01,
    T02N08N02,
    T02N08N03,
    T02N08N04,
    T02N08N05,
    T02N08N06,
    T02N08N07,
    T02N08N08,
    T02N08N09,
    T02N08N10,
    T02N08N11,
    T02N08N12,
    T02N08N13,
    T02N08N14,
    T02N08N15,
    T02N08N16,
    T02N08N17,
    T02N08N18,
    T02N08N19,
    T02N08N20,
    T02N08N21,
    T02N08N22,
    T02N08N23,
    T02N08N24,
    T02N08N25,
    T02N08N26,
    T02N08N27,
    T02N08N28,
    T02N09N01,
    T02N09N02,
    T02N09N03,
    T02N09N04,
    T02N09N05,
    T02N09N06,
    T02N09N07,
    T02N10N01,
    T02N10N02,
    T02N10N03,
    T02N11N01,
    T02N11N02,
    T02N11N03,
    T02N11N04,
    T02N11N05,
    T02N11N06,
    T02N11N07,
    T02N11N08,
    T02N11N09,
    T02N11N10,
    T02N12N01,
    T02N12N02,
    T02N12N03,
  },
  props: {
    category01: String,
    category02: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(h4) {
  margin-top: 40px;
  margin-bottom: 15px;
}

:deep(img) {
  width: 95%;
  margin-left: 40px;
  border: 3px solid gray;
  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
    width: 100% !important;
  }
}

:deep(p),
ul {
  margin-left: 20px;
  @media screen and (max-width: 768px) {
    margin-left: 10px !important;
  }
}
:deep(a),
ul,
p {
  @media screen and (max-width: 768px) {
    word-break: break-all;
  }
}

:deep(.green) {
  color: rgb(65, 173, 28);
}

:deep(.kokokara) {
  text-align: center;
  margin: 40px;
  font-size: 20px;
  font-weight: bold;
  color: var(--blue2);
  @media screen and (max-width: 768px) {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
}

:deep(a) {
  color: var(--blue2);
}

:deep(.mini-img) {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  img {
    width: auto;
  }
  @media screen and (max-width: 768px) {
    width: 60% !important;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }
}

:deep(.mini-img-left) {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    width: 60% !important;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
  }
  img {
    margin-left: 20px;
    width: auto;
  }
}
</style>
