<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（アフィリコードシステム）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <CommonDescription :systemType="'affilicode'" />
        <h4>
          【１】<a href="https://atmarktension.com/" target="_blank">管理画面</a
          >にログインし、「成果管理」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no20/01.png`" alt="" />
        <h4>【２】「検索」を開きます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no20/02.png`" alt="" />
        <h4>【３】期間を選択して、「検索する」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no20/03.png`" alt="" />
        <h4>
          【４】CSVをダウンロードします。「検索結果をCSVダウンロード」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no20/04.png`" alt="" />

        <p>これで成果CSVのダウンロードは完了です。</p>
        「
        <router-link :to="`/manual/${tool.codeKebab}/07/14`"
          >ASPの成果データをアドタスカルにインポートする</router-link
        >」に進んでください。
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonDescription from "@/components/Manual/Tools02/Parts/CommonDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    CommonDescription,
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.mini-img {
  width: 50%;
}
</style>
