<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>9-3.管理画面のレポートの見方</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>管理画面の[コンバージョン][すべてのコンバージョン]の違いを理解する</li>
          <li>自分が見たいデータを表示することができる</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【説明】</h3>
        <p>「キャンペーン単位のコンバージョン設定」のマニュアルでキャンペーン単位のコンバージョン設定ができました。次に、管理画面上でどう反映されるのか？を理解する必要があります。</p>
        <p>管理画面でコンバージョンのデータを見たい場合２つの指標があります。</p>
        <ul>
          <li>[コンバージョン]</li>
          <li>[すべてのコンバージョン]</li>
        </ul>
        <p>この２つの違いは、「キャンペーン単位のコンバージョン設定」しているかどうかになります。</p>
        <p>[コンバージョン]には、キャンペーン単位のコンバージョン設定したCVが反映されます。</p>
        <p>[すべてのコンバージョン]は、名前の通り、そのキャンペーン経由で発生した全てのCVが反映されます。</p>
        <p>表にすると以下のようになります。</p>
        <table>
          <tr>
            <td></td>
            <td>[コンバージョン]</td>
            <td>[すべてのコンバージョン]</td>
          </tr>
          <tr>
            <td>設定したコンバージョン</td>
            <td>○</td>
            <td>○</td>
          </tr>
          <tr>
            <td>設定していないコンバージョン</td>
            <td>×</td>
            <td>○</td>
          </tr>
        </table>
        <h4>ケーススタディー</h4>
        <p>それでは、実際のケースを想定して、[コンバージョン] と[すべてのコンバージョン] にどんな数値が反映されるか確認しましょう。</p>
        <h5>ケース１：MCVで最適化して、最終CVは計測用</h5>
        <p>設定は以下の通りになります。</p>
        <p>MCV：キャンペーン単位のコンバージョンに設定<br>最終CV（オフラインCV）：作成するだけ。</p>
        <p>表にすると以下のようになります。</p>
        <table>
          <tr>
            <td>[コンバージョン]</td>
            <td>[すべてのコンバージョン]</td>
          </tr>
          <tr>
            <td>MCV</td>
            <td>MCVと最終CVの合計</td>
          </tr>
        </table>
        <h5>ケース２：最終CVで最適化して、MCVは計測用</h5>
        <p>設定は以下の通りになります。</p>
        <p>MCV：作成して設置するだけ。<br>最終CV（オフラインCV）：キャンペーン単位のコンバージョンに設定</p>
        <p>表にすると以下のようになります。</p>
        <table>
          <tr>
            <td>[コンバージョン]</td>
            <td>[すべてのコンバージョン]</td>
          </tr>
          <tr>
            <td>最終CV</td>
            <td>MCVと最終CVの合計</td>
          </tr>
        </table>
        <p>このようにキャンペーン単位のコンバージョンに設定したCVが[コンバージョン]に反映され、そのキャンペーン経由で発生したすべてのCVが[すべてのコンバージョン]に反映されます。</p>
        <h4>レポートの設定方法</h4>
        <p>それでは、実際に管理画面上で表示させてみましょう。</p>
        <p>【１】「表示項目」→「表示項目の変更」をクリックします。</p>
        <img :src="`${rt}/img/manual/google-cv/no11/no03/01.png`" alt="" />
        <p>【２】「コンバージョン」の項目を開くと、[コンバージョン]に関する項目と[すべてのコンバージョン]に関する項目があります。</p>
        <p>ご自身で見たい項目にチェックをいれましょう。</p>
        <img :src="`${rt}/img/manual/google-cv/no11/no03/02.png`" alt="" />
        <p>【３】表示項目の設定を保存」にチェックを入れて、名前を付けて「保存して適応」をクリックしましょう。</p>
        <div class="mini-img">
        <img :src="`${rt}/img/manual/google-cv/no11/no03/03.png`" alt="" />

        </div>
        <p>【４】これで先程、設定した項目が表示されます。</p>
        <h4>CVごとの数値を確認する方法</h4>
        <p>「[すべてのコンバージョン]でMCVと最終CVの合計が表示されるのは理解できました。」</p>
        <p>「でも、それぞれの数値を見るにはどうしたらいいんだろう・・・」</p>
        <p>と思ったかもしれませんね。</p>
        <p>その設定方法を解説していきます。</p>
        <p>【１】「分割」→「コンバージョン」→「コンバージョンアクション」の順にクリックします。</p>
        <img :src="`${rt}/img/manual/google-cv/no11/no03/04.png`" alt="" />
        <p>【２】すると、以下のようにCVごとに数値が表示されます。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;
  tr {
    @media screen and (max-width: 768px){
      font-size: 12px;
    }

    td {
      border: solid 1px #999;
      padding: 10px 15px;
      @media screen and (max-width: 768px){
        width: 30%;
      }
    }
  }
}
</style>
