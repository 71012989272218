<template>
  <div class="affiliate-payment-type-config">
    <el-row>
      <el-col>
        <span style="font-weight: bold;margin-right: 30px;">報酬の受取方法の設定：</span>
        <el-radio v-model="affiliatePaymentType" label="0"
          >現金で受け取る</el-radio
        >
        <el-radio v-model="affiliatePaymentType" label="1"
          >ポイントで受け取る</el-radio
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button @click="registerAffiliatePaymentType" type="primary"
          >登録</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "affiliate-payment-type-config",
  components: {},
  props: {},
  data() {
    return {
      affiliatePaymentType: "0",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "bankAccountTypeList"]),
  },
  watch: {},
  created() {
    let userData = JSON.parse(JSON.stringify(this.$store.getters["userData"]));
    this.affiliatePaymentType = userData.affiliatePaymentType;
  },
  methods: {
    registerAffiliatePaymentType() {
      if (confirm("報酬の受け取り方を変更します。よろしいですか？")) {
        let params = new URLSearchParams();
        params.append("affiliate_payment_type", this.affiliatePaymentType);
        this.$axios
          .post(`${this.rt}/l/api/user/register-affiliate-payment-type`, params)
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              this.$store.dispatch("setUserData");
              alert("登録しました。");
              return;
            } else if (res.data.status === 401) {
              alert(res.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }else {
        // no action.
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
