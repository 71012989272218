<template>
  <div class="test-point-back">
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <el-button @click="setTestData" type="success"
          >テストデータをセット</el-button
        >
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>lineTagId</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="lineTagId" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>accessToken</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="accessToken" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>ipAddress</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="ipAddress" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>userAgent</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="userAgent" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>url</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="url" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>deduplicationKey</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="deduplicationKey" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>conversionDatetime</h4>
      </el-col>
      <el-col :span="20">
        <el-date-picker
          v-model="conversionDatetime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="Select date and time"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>browserId</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="browserId" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>clickId</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="clickId" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>externalId</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="externalId" />
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>value</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="value" type="number" min="0" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="text-align: center">
          <el-button @click="send" type="primary">送信</el-button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>レスポンス</h4>
        <p v-if="responseData === null">成功！</p>
        <p v-else>{{ responseData }}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TestTiktokApi",
  components: {},
  props: {},
  data() {
    return {
      // PATH PARAMETERS
      lineTagId: "",

      // HEADER PARAMETERS
      accessToken: "",

      // web
      ipAddress: "",
      userAgent: "",
      url: "",

      // event
      deduplicationKey: "",
      conversionDatetime: "",

      // user
      browserId: "",
      clickId: "",
      externalId: "",

      // custom
      value: 0,

      //
      responseData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  methods: {
    setTestData() {
      // web
      this.ipAddress = "127.0.0.0";
      this.userAgent =
        "Mozilla/5.0 (iPhone; CPU iPhone OS 15_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Instagram 259.0.0.18.104 (iPhone12,1; iOS 15_1; en_IN; en-IN; scale=2.00; 828x1792; 413505119)";
      this.url = "https://adtasukaru.com";

      // event
      this.deduplicationKey = "test";
      this.conversionDatetime = "2022-11-30 13:27:00";

      // user
      this.browserId = "12345678-2983-2938-2938-293847562982";
      this.clickId = "testClickId";
      this.externalId = "testExternalId";

      // custom
      this.value = 1000;
    },
    send() {
      let params = new URLSearchParams();
      params.append("lineTagId", this.lineTagId);
      params.append("accessToken", this.accessToken);
      params.append("ipAddress", this.ipAddress);
      params.append("userAgent", this.userAgent);
      params.append("url", this.url);
      params.append("deduplicationKey", this.deduplicationKey);
      params.append("conversionDatetime", this.conversionDatetime);
      params.append("browserId", this.browserId);
      params.append("clickId", this.clickId);
      params.append("externalId", this.externalId);
      params.append("value", this.value);
      this.$axios
        .post(`${this.rt}/api/test/tools02/line-cv/send-api.php`, params)
        .then((response) => {
          console.log(response);
          this.responseData = response.data.curlResponseData;
          return;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0;
}
.el-row {
  margin: 10px 0;
}
</style>
