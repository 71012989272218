<template>
  <div class="manual-google-cv-contents">
    <h2>ピクセルテストを行う</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>アドタスカルの管理画面に登録したピクセル情報とビジネスマネージャとの連携の確認のためのテストをします。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <h4>【１】ビジネスマネージャ上のデータソースの画面から、該当のピクセルの「テストイベント」をクリックします（データソースへのアクセスは、「<router-link :to="`/manual/${tool.codeKebab}/01/02`">データソースへのアクセス方法</router-link>」を参照）。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/01.png`" alt="" />
        <h4>【２】画面上に表示されたテストコードをコピーします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/02.png`" alt="" />
        <p class="kokokara">
          --------------------------------ここからアドタスカル--------------------------------
        </p>
        <h4>【３】アドタスカル管理画面の「<router-link :to="`/tools02/${tool.codeKebab}/tags`">ピクセル一覧</router-link>」から該当のピクセルの「編集」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/03.png`" alt="" />
        <h4>【４】ピクセル編集の画面にて、【２】で取得したテストコードを貼り付け、「テスト送信」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/04.png`" alt="" />
        <h4>【５】「テスト送信に成功しました。」と表示されたら、「OK」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/05.png`" alt="" />
        </div>
        <p style="color: red;">※「テスト送信に失敗しました。」と表示されたら、アクセストークンとピクセルIDのどちらかが間違っている可能性があります。再確認してください。</p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/06.png`" alt="" />
        </div>
        <h4>【６】「登録」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/07.png`" alt="" />
        <p class="kokokara">
          --------------------------------ここからビジネスマネージャ--------------------------------
        </p>
        <h4>【７】ピクセルのテストイベントに「処理が完了しました」と表示されたら、テストは完了です（1～5分程度で反映されます）。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no09/08.png`" alt="" />
        <p>反映されない場合は、アクセストークン、ピクセルID、テストコードが間違っている可能性があるので、確認して再度、テスト送信を行ってください。</p>
        <ul>
          <li><router-link :to="`/manual/${tool.codeKebab}/03/07`">ピクセルIDとアクセストークンの取得方法</router-link></li>
          <li><router-link :to="`/manual/${tool.codeKebab}/03/09`">テストコードの確認方法</router-link></li>
        </ul>
        <p>以上で、ピクセルテストは完了です。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/03/11`">ドメイン認証</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
