<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>Yahoo!検索広告とアドタスカルの連携を解除する</h2>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>設定したYahoo!検索広告とアドタスカルの連携を解除します。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h4>【１】Yahoo!検索広告の管理画面にアクセスします。</h4>
        <h4>【２】「アカウント管理」をクリックします。</h4>

        <img :src="`${rt}/img/manual/yahoo-cv/no04/no03/01.png`" alt="" />
        <h4>
          【３】「ツール」内にある「アクセス可能なアプリケーション」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no03/02.png`" alt="" />
        <h4>
          【４】アプリケーション名「アドタスカル」の「アクセスを解除」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no03/03.png`" alt="" />
        <h4>【５】「アクセスを解除」をクリックします。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no03/04.png`" alt="" />
        <h4>【６】以下の画面が表示され、連携の解除が完了となります。</h4>
        <img :src="`${rt}/img/manual/yahoo-cv/no04/no03/05.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualTools02Contents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
