import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import axios from 'axios';

import {
  setTitle,
  setDescription
} from '@/mixins/index.js'



//root
import Account from '@/views/Account.vue'
import AiReception from '@/views/AiReception.vue'
import AspDisplaySettings from '@/views/AspDisplaySettings.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import Cv from '@/views/Cv.vue'
import CvDetail from '@/views/CvDetail.vue'
import Description from '@/views/Description.vue'
import History from '@/views/History.vue'
import HistoryDetail from '@/views/HistoryDetail.vue'
import Home from '@/views/Home.vue'
import Info from '@/views/Info.vue'
import Item from '@/views/Item.vue'
import ItemDetail from '@/views/ItemDetail.vue'
import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Manual from '@/views/Manual.vue'
import ManualHome from '@/views/Manual/Home.vue'
import ManualCategory01 from '@/views/Manual/Category01.vue'
import ManualCategory02 from '@/views/Manual/Category02.vue'
//お知らせ・News
import News from '@/views/News/News.vue'
import NewsDetail from '@/views/News/NewsDetail.vue'
import ReissuePassword from '@/views/ReissuePassword.vue'
import Payment from '@/views/Payment.vue'
import Privileges from '@/views/Privileges.vue'
import GetPrivilege from '@/views/GetPrivilege.vue'
// import PrivilegeDetail from '@/views/PrivilegeDetail.vue'
import QA from '@/views/QA.vue'
import Secret from '@/views/Secret.vue'
import SubscriptionHistories from '@/views/SubscriptionHistories.vue'
import Tests from '@/views/Tests.vue'



// tools01
import Tools01 from '@/views/Tools01.vue'
import Tools01Home from '@/views/Tools01/Home.vue'
import Tools01Select from '@/views/Tools01/Select.vue'
import Tools01GeneralReport from '@/views/Tools01/GeneralReport.vue'
import Tools01Report from '@/views/Tools01/Report.vue'
import Tools01New from '@/views/Tools01/New.vue'
import Tools01Edit from '@/views/Tools01/Edit.vue'
import Tools01Format from '@/views/Tools01/Format.vue'
import Tools01Copy from '@/views/Tools01/Copy.vue'
import Tools01CopyDetail from '@/views/Tools01/CopyDetail.vue'
import Tools01ABtest from '@/views/Tools01/ABtest.vue'
import Tools01ABtestDetail from '@/views/Tools01/ABtestDetail.vue'
import Tools01ABtestReport from '@/views/Tools01/ABtestReport.vue'



// tools02
import Tools02 from '@/views/Tools02.vue'
import Tools02AccountDetail from '@/views/Tools02/AccountDetail.vue'
import Tools02Accounts from '@/views/Tools02/Accounts.vue'
import Tools02ApiHistories from '@/views/Tools02/ApiHistories.vue'
import Tools02ApiHistoryDetail from '@/views/Tools02/ApiHistoryDetail.vue'
import Tools02Home from '@/views/Tools02/Home.vue'
import Tools02Authorization from '@/views/Tools02/Authorization.vue'
import Tools02AutoImportLog from '@/views/Tools02/AutoImportLog.vue'
import Tools02BussinessAccountDetail from '@/views/Tools02/BussinessAccountDetail.vue'
import Tools02CartSystem from '@/views/Tools02/CartSystem.vue'
import Tools02BussinessAccounts from '@/views/Tools02/BussinessAccounts.vue'
import Tools02GetTokens from '@/views/Tools02/GetTokens.vue'
import Tools02ParameterManual from '@/views/Tools02/ParameterManual.vue'
import Tools02ParameterCheck from '@/views/Tools02/ParameterCheck.vue'
import Tools02ParameterLog from '@/views/Tools02/ParameterLog.vue'
import Tools02PointBack from '@/views/Tools02/PointBack.vue'
import Tools02PromotionDetail from '@/views/Tools02/PromotionDetail.vue'
import Tools02Promotions from '@/views/Tools02/Promotions.vue'
import Tools02TagDetail from '@/views/Tools02/TagDetail.vue'
import Tools02TagFire from '@/views/Tools02/TagFire.vue'
import Tools02Tags from '@/views/Tools02/Tags.vue'
import Tools02TagTest from '@/views/Tools02/TagTest.vue'
import Tools02Report from '@/views/Tools02/Report.vue'
import Tools02ResendOfflineConversions from '@/views/Tools02/ResendOfflineConversions.vue'
import Tools02CsvManualLinks from '@/views/Tools02/CsvManualLinks.vue'
import Tools02UnitDetail from '@/views/Tools02/UnitDetail.vue'
import Tools02Units from '@/views/Tools02/Units.vue'
import Tools02InitialSetting from '@/views/Tools02/InitialSetting.vue'
import Tools02UsageGuide from '@/views/Tools02/UsageGuide.vue'
import Tools02Bridges from '@/views/Tools02/Bridges.vue'
import Tools02CopyPromotion from '@/views/Tools02/CopyPromotion.vue'
import Tools02Domains from '@/views/Tools02/Domains.vue'
import Tools02DomainDetail from '@/views/Tools02/DomainDetail.vue'



//affiliate
import Affiliate from '@/views/Affiliate.vue'
import AffiliateBankAccount from '@/views/Affiliate/BankAccount.vue'
import AffiliateHome from '@/views/Affiliate/Home.vue'
import AffiliateLink from '@/views/Affiliate/Link.vue'
import AffiliateNotice from '@/views/Affiliate/Notice.vue'
import AffiliateResult from '@/views/Affiliate/Result.vue'
import AffiliateReward from '@/views/Affiliate/Reward.vue'
import AffiliateStatus from '@/views/Affiliate/Status.vue'



//===================== ↓↓ ctrl ↓↓ =====================
import Ctrl from '@/views/Ctrl.vue'
import CtrlActiveConfirm from '@/views/Ctrl/ActiveConfirm.vue'
import CtrlApiHistories from '@/views/Ctrl/ApiHistories.vue'
import CtrlApiHistoriesHome from '@/views/Ctrl/ApiHistories/Home.vue'
import CtrlApiHistoriesGoogleApiHistories from '@/views/Ctrl/ApiHistories/GoogleApiHistories.vue'
import CtrlApiHistoriesFacebookApiHistories from '@/views/Ctrl/ApiHistories/FacebookApiHistories.vue'
import CtrlContact from '@/views/Ctrl/Contact.vue'
import CtrlContactEdit from '@/views/Ctrl/ContactEdit.vue'
import CtrlGivesPoints from '@/views/Ctrl/GivesPoints.vue'
import CtrlHome from '@/views/Ctrl/Home.vue'
import CtrlLogin from '@/views/Ctrl/Login.vue'
import CtrlPost from '@/views/Ctrl/Post.vue'
import CtrlPostEdit from '@/views/Ctrl/PostEdit.vue'
import CtrlQA from '@/views/Ctrl/QA.vue'
import CtrlQAEdit from '@/views/Ctrl/QAEdit.vue'
import CtrlUser from '@/views/Ctrl/User.vue'
import CtrlUserEdit from '@/views/Ctrl/UserEdit.vue'
import CtrlUsers from '@/views/Ctrl/Users.vue'
import CtrlParameterLog from '@/views/Ctrl/ParameterLog.vue'
import CtrlAutoImportLog from '@/views/Ctrl/AutoImportLog.vue'
import CtrlUserSummary from '@/views/Ctrl/UsageStatus.vue'
// import CtrlSendMailList from '@/views/Ctrl/Mail/SendMailList.vue'
// import CtrlSendMail from '@/views/Ctrl/Mail/SendMail.vue'
import CtrlSendMails from '@/views/Ctrl/SendMails.vue'
import CtrlSendMailDetail from '@/views/Ctrl/SendMailDetail.vue'
import CtrlConciseAnalysis from '@/views/Ctrl/ConciseAnalysis.vue'
import CtrlTools02Bridges from '@/views/Ctrl/Tools02/Bridges.vue'
import CtrlBrowserTestTool from '@/views/Ctrl/BrowserTestTool.vue'

//affiliate
import CtrlAffiliate from '@/views/Ctrl/Affiliate.vue'
import CtrlAffiliateHome from '@/views/Ctrl/Affiliate/Home.vue'
import CtrlAffiliateRewards from '@/views/Ctrl/Affiliate/Rewards.vue'
import CtrlAffiliateDownloadBankCsv from '@/views/Ctrl/Affiliate/DownloadBankCsv.vue'
import CtrlAffiliatePaymentInformation from '@/views/Ctrl/Affiliate/PaymentInformation.vue'

// tools02
import CtrlTools02 from '@/views/Ctrl/Tools02.vue'
import CtrlTools02CartSystem from '@/views/Ctrl/Tools02/CartSystem.vue'
import CtrlTools02CartSystemHome from '@/views/Ctrl/Tools02/CartSystem/Home.vue'
import CtrlTools02CartSystemSubscriptions from '@/views/Ctrl/Tools02/CartSystem/Subscriptions.vue'




function convertKebabToCamel(p) {
  return p.replace(/-./g,
    function (s) {
      return s.charAt(1).toUpperCase();
    }
  );
}
Vue.use(VueRouter)



const routes = [

  //↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ root ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'アカウント',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/ai-reception',
    redirect: '/qa', //リダイレクト
    name: 'ai-reception',
    component: AiReception,
    meta: {
      title: 'AI',
      description: ''
    }
  },
  {
    path: '/asp-display-settings',
    name: 'asp-display-settings',
    component: AspDisplaySettings,
    meta: {
      title: 'ASP表示設定',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword,
    meta: {
      title: 'パスワード変更',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/cv',
    name: 'cv',
    component: Cv,
    meta: {
      title: 'コンバージョン設定',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/cv-detail/:cv_id',
    name: 'cv-detail',
    component: CvDetail,
    props: route => ({
      cv_id: Number(route.params.cv_id)
    }),
    meta: {
      title: 'コンバージョン設定 詳細',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/description/:tool',
    name: 'description',
    component: Description,
    props: route => ({
      tool: String(route.params.tool)
    }),
    meta: {
      title: 'ツール説明',
      description: ''
    }
  },
  {
    path: '/history',
    name: 'history',
    component: History,
    meta: {
      title: '購入・ポイント履歴',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/history-detail/:orderId',
    name: 'history-detail',
    component: HistoryDetail,
    props: route => ({
      orderId: String(route.params.orderId)
    }),
    meta: {
      title: '購入・ポイント履歴 詳細',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'ホーム',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/info',
    name: 'info',
    component: Info,
    meta: {
      title: '会員情報',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/item',
    redirect: '/', //リダイレクト
    name: 'item',
    component: Item,
    meta: {
      title: '登録済み商品一覧',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/item-detail/:item_id',
    redirect: '/', //リダイレクト
    name: 'item-detail',
    component: ItemDetail,
    meta: {
      title: '商品詳細',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter(to, from, next) { //ログインしていてもログインしていない状態を実現
      store.dispatch('setLogin', 0);
      next();
    },
    meta: {
      title: 'ログイン',
      description: ''
    }
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
    meta: {
      title: 'ログアウト',
      description: ''
    }
  },
  {
    path: '/manual/:toolCode',
    name: 'manual',
    component: Manual,
    props: route => ({
      toolCodeKebab: String(route.params.toolCode),
      toolCodeCamel: String(convertKebabToCamel(route.params.toolCode)),
    }),
    meta: {
      title: 'マニュアル',
      description: '',
      savedPosition: true,
    },
    children: [{
        path: '',
        name: 'manual-home',
        component: ManualHome,
        meta: {
          title: `マニュアル ホーム`,
          description: ''
        }
      },
      {
        path: ':category01',
        name: 'manual-category01',
        component: ManualCategory01,
        props: route => ({
          category01: String(route.params.category01)
        }),
        meta: {
          title: `マニュアル カテゴリー01`,
          description: ''
        },
      },
      {
        path: ':category01/:category02',
        name: 'manual-category01-category02',
        component: ManualCategory02,
        props: route => ({
          category01: String(route.params.category01),
          category02: String(route.params.category02)
        }),
        meta: {
          title: `マニュアル カテゴリー02`,
          description: ''
        },
      },
    ]
  },
  {
    path: '/news',
    name: 'news',
    component: News,
    meta: {
      title: 'お知らせ一覧',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/news-detail/:id',
    name: 'news-detail',
    component: NewsDetail,
    props: route => ({
      id: Number(route.params.id)
    }),
    meta: {
      title: 'お知らせ内容',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/reissue-password',
    name: 'reissue-password',
    component: ReissuePassword,
    meta: {
      title: 'パスワード再発行',
      description: ''
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: Payment,
    meta: {
      title: 'ご契約・購入・プラン変更',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/privileges',
    name: 'privileges',
    component: Privileges,
    meta: {
      title: '特典一覧',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/get-privilege/:utmSource',
    name: 'get-privilege',
    component: GetPrivilege,
    props: route => ({
      utmSource: String(route.params.utmSource)
    }),
    meta: {
      title: '特典付与',
      description: '',
      requiresAuth: true
    }
  },
  // {
  //   path: '/privilege-detail/:privilegeId',
  //   name: 'privilege-detail',
  //   component: PrivilegeDetail,
  //   beforeEnter(to, from, next) {
  //     this.$axios
  //       .get(`${store.getters.rt}/api/mypage/check-privilege-authorization.php?privilege_id=${to.params.privilegeId}`)
  //       .then(res => {
  //         console.log(res);
  //         if (res.data.status === 200) {
  //           next();
  //         }else {
  //           next(`/privileges`);
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err);
  //         next(`/privileges`);
  //       });
  //   },
  //   props: route => ({
  //     privilegeId: Number(route.params.privilegeId)
  //   }),
  //   meta: {
  //     title: '特典詳細',
  //     description: '',
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/qa',
    name: 'qa',
    component: QA,
    meta: {
      title: 'よくある質問',
      description: '',
      requiresAuth: true
    }
  },
  {
    path: '/redirect',
    name: 'redirect',
    beforeEnter(to, from, next) {
      store.dispatch('redirect').then((path) => {
        store.dispatch('setProductList');
        store.dispatch("setRegisteredCardList");
        store.dispatch('setActivePlanData');
        next({
          path: path
        });
      });
    },
    meta: {
      title: 'リダイレクト',
      description: ''
    }
  },
  {
    path: '/secret/:password',
    name: 'secret',
    component: Secret,
    beforeEnter(to, from, next) {
      if (to.params.password === 'vG2VLmg79U6prMwHpkVS8CZeRRw8v7838kACavsP') {
        next();
      } else {
        next('/login');
      }
    },
    meta: {
      title: 'シークレット',
      description: ''
    }
  },
  {
    path: '/subscription-histories',
    name: 'subscription-histories',
    component: SubscriptionHistories,
    meta: {
      title: '利用ステータス履歴',
      description: ''
    }
  },
  {
    path: '/tests/:password',
    name: 'tests',
    component: Tests,
    beforeEnter(to, from, next) {
      if (to.params.password === 'W66QMFNThEK7BGru8HyfxXB6eXT6fv5x') {
        next();
      } else {
        next('/login');
      }
    },
    meta: {
      title: 'テスト',
      description: ''
    }
  },
  //↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ root ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑









  //↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ tools01 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  {
    path: '/tools01/:tool',
    name: 'tools01',
    component: Tools01,
    props: route => ({
      tool: String(route.params.tool),
      toolId: store.getters.toolIdFromToolCode(route.params.tool)
    }),
    meta: {
      requiresAuth: true
    },
    children: [{
        path: 'abtest/:pageId',
        name: 'tools01-abtest',
        component: Tools01ABtest,
        props: route => ({
          pageId: String(route.params.pageId)
        }),
        meta: {
          title: 'ABテスト',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'abtest-detail/:pageId/:testId',
        name: 'tools01-abtest-detail',
        component: Tools01ABtestDetail,
        props: route => ({
          pageId: String(route.params.pageId),
          testId: String(route.params.testId)
        }),
        meta: {
          title: 'ABテスト設定・編集',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'abtest-report/:toolPageId/:toolPageTestId',
        name: 'tools01-abtest-report',
        component: Tools01ABtestReport,
        props: route => ({
          toolPageId: String(route.params.toolPageId),
          toolPageTestId: String(route.params.toolPageTestId)
        }),
        meta: {
          title: 'ABテストレポート',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'copy/:userPageId',
        name: 'tools01-copy',
        component: Tools01Copy,
        props: route => ({
          userPageId: String(route.params.userPageId)
        }),
        meta: {
          title: 'フォーマットコピー元ページ一覧',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'copy-detail/:pageId/:parameter',
        name: 'tools01-copy-detail',
        component: Tools01CopyDetail,
        beforeEnter(to, from, next) {
          store.dispatch('setCopyPageInfo', to.params.parameter).then(() => {
            next();
          });
        },
        props: route => ({
          pageId: String(route.params.pageId),
          parameter: String(route.params.parameter),
          copyPageTool: String(store.getters.copyPageInfo.tool),
          copyPageId: String(store.getters.copyPageInfo.id),
        }),
        meta: {
          title: 'フォーマットコピー フォーマット一覧',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'report',
        name: 'tools01-general-report',
        component: Tools01GeneralReport,
        meta: {
          title: '全体レポート',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'report/:pageId',
        name: 'tools01-report',
        component: Tools01Report,
        props: route => ({
          pageId: String(route.params.pageId)
        }),
        meta: {
          title: 'レポート',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'edit/:page_id',
        name: 'tools01-edit',
        component: Tools01Edit,
        props: route => ({
          pageId: String(route.params.page_id)
        }),
        meta: {
          title: '編集',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'format/:site_id/:format_id',
        name: 'tools01-format',
        component: Tools01Format,
        props: route => ({
          page_id: Number(route.params.site_id),
          format_id: Number(route.params.format_id)
        }),
        meta: {
          title: 'フォーマット新規設定・編集',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: '',
        name: 'tools01-home',
        component: Tools01Home,
        props: route => ({
          pageName: String(route.name)
        }),
        meta: {
          title: '設定一覧',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'new/',
        name: 'tools01-new',
        component: Tools01New,
        meta: {
          title: '新規設定',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'select/',
        name: 'tools01-select',
        component: Tools01Select,
        meta: {
          title: 'タイプ選択',
          description: '',
          requiresAuth: true
        }
      },
    ]
  },
  //↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ tools01 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑









  //↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ tools02 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  {
    path: '/tools02/:toolCode',
    name: 'tools02',
    component: Tools02,
    meta: {
      requiresAuth: true
    },
    props: route => ({
      toolId: store.getters.toolIdFromToolCode(route.params.toolCode)
    }),
    children: [{
        path: '',
        name: 'tools02-home',
        component: Tools02Home,
        meta: {
          title: `広告成果連携ツール トップ`,
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'initial-setting',
        name: 'tools02-initial-setting',
        component: Tools02InitialSetting,
        meta: {
          title: `広告成果連携ツール 初期設定チュートリアル`,
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'accounts',
        name: 'tools02-accounts',
        component: Tools02Accounts,
        beforeEnter(to, from, next) {
          store.dispatch("setUserData");
          next();
        },
        meta: {
          title: `広告成果連携ツール アカウント一覧`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'account-detail/:accountId',
        component: Tools02AccountDetail,
        beforeEnter(to, from, next) {
          store.dispatch("setUserData");
          next();
        },
        props: route => ({
          accountId: String(route.params.accountId)
        }),
        meta: {
          requiresAuth: true,
          tools02Auth: true
        },
        children: [{
            path: '',
            name: 'tools02-account-detail-promotions',
            component: Tools02Promotions,
            meta: {
              title: `広告成果連携ツール アカウント詳細 プログラム一覧`,
              description: '',
              requiresAuth: true
            }
          },
          {
            path: 'promotion/:promotionId',
            name: 'tools02-account-detail-promotion-detail',
            component: Tools02PromotionDetail,
            props: route => ({
              promotionId: String(route.params.promotionId)
            }),
            meta: {
              title: `広告成果連携ツール プログラム情報 編集`,
              description: '',
              requiresAuth: true,
              savedPosition: true
            }
          },
          {
            path: 'tags',
            name: 'tools02-account-detail-tags',
            component: Tools02Tags,
            props: route => ({
              accountId: String(route.params.accountId)
            }),
            meta: {
              title: `広告成果連携ツール アカウント詳細 プログラム一覧`,
              description: '',
              requiresAuth: true
            }
          },
          {
            path: 'tag-detail/:tagId',
            name: 'tools02-account-detail-tag-detail',
            component: Tools02TagDetail,
            props: route => ({
              tagId: String(route.params.tagId)
            }),
            meta: {
              title: `広告成果連携ツール ピクセル詳細`,
              description: '',
              requiresAuth: true,
              tools02Auth: true
            }
          },
        ]
      },
      {
        path: 'api-histories',
        name: 'tools02-api-histories',
        component: Tools02ApiHistories,
        meta: {
          title: `広告成果連携ツール API送信履歴一覧`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'api-history-detail/:historyId',
        name: 'tools02-api-history-detail',
        component: Tools02ApiHistoryDetail,
        props: route => ({
          historyId: String(route.params.historyId)
        }),
        meta: {
          title: `広告成果連携ツール API送信履歴詳細`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'bussiness-accounts',
        name: 'tools02-bussiness-accounts',
        component: Tools02BussinessAccounts,
        beforeEnter(to, from, next) {
          store.dispatch('tools02/setBussinessAccountList');
          next();
        },
        meta: {
          title: ``,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'bussiness-account-detail/:bussinessAccountId',
        name: 'tools02-bussiness-account-detail',
        component: Tools02BussinessAccountDetail,
        props: route => ({
          bussinessAccountId: String(route.params.bussinessAccountId)
        }),
        meta: {
          title: ``,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'get-tokens',
        name: 'tools02-get-tokens',
        component: Tools02GetTokens,
        meta: {
          title: ``,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'authorization',
        name: 'tools02-authorization',
        component: Tools02Authorization,
        meta: {
          title: ``,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'report',
        name: 'tools02-report',
        component: Tools02Report,
        meta: {
          title: `広告成果連携ツール レポート`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'csv-manual-links',
        name: 'tools02-csv-manual-links',
        component: Tools02CsvManualLinks,
        meta: {
          title: `広告成果連携ツール ASP ダウンロード`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'auto-import-log',
        name: 'tools02-auto-import-log',
        component: Tools02AutoImportLog,
        meta: {
          title: `広告成果連携ツール 自動登録履歴`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'parameter-manual',
        name: 'tools02-parameter-manual',
        component: Tools02ParameterManual,
        props: route => ({
          category01: Number((route.query.category01 === '1' || route.query.category01 === '2' || route.query.category01 === '3') ? route.query.category01 : 0),
          category02: Number((route.query.category02 === '1' || route.query.category02 === '2') ? route.query.category02 : 0),
          unitId: Number(route.query.unitId !== undefined ? route.query.unitId : 0),
        }),
        meta: {
          title: `広告成果連携ツール パラメータ引継ぎ設定 マニュアル`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        },
      },
      {
        path: 'parameter-check',
        name: 'tools02-parameter-check',
        component: Tools02ParameterCheck,
        meta: {
          title: `広告成果連携ツール パラメータチェック`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'parameter-log',
        name: 'tools02-parameter-log',
        component: Tools02ParameterLog,
        meta: {
          title: `広告成果連携ツール パラメータログ`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'tag-detail/:tagId',
        name: 'tools02-tag-detail',
        component: Tools02TagDetail,
        props: route => ({
          accountId: '0',
          tagId: String(route.params.tagId)
        }),
        meta: {
          title: `広告成果連携ツール ピクセル詳細`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'tag-fire/:tagId',
        name: 'tools02-tag-fire',
        component: Tools02TagFire,
        props: route => ({
          accountId: '0',
          tagId: String(route.params.tagId)
        }),
        meta: {
          title: `広告成果連携ツール ピクセルテスト発火`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'tags',
        name: 'tools02-tags',
        component: Tools02Tags,
        props: {
          accountId: '0'
        },
        meta: {
          title: `広告成果連携ツール ピクセル一覧`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'tag-test/:tagId',
        name: 'tools02-tag-test',
        component: Tools02TagTest,
        props: route => ({
          tagId: String(route.params.tagId)
        }),
        meta: {
          title: `広告成果連携ツール テスト`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'point-back',
        name: 'tools02-point-back',
        component: Tools02PointBack,
        meta: {
          title: `広告成果連携ツール ASP自動連携設定`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'promotion-detail/:promotionId',
        name: 'tools02-promotion-detail',
        component: Tools02PromotionDetail,
        props: route => ({
          accountId: '0',
          promotionId: String(route.params.promotionId)
        }),
        meta: {
          title: `広告成果連携ツール プログラム詳細`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'promotions',
        name: 'tools02-promotions',
        component: Tools02Promotions,
        beforeEnter(to, from, next) {
          store.dispatch("setUserData");
          next();
        },
        props: {
          accountId: '0',
        },
        meta: {
          title: `広告成果連携ツール プログラム一覧`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'copy-promotion/:accountId',
        name: 'tools02-copy-promotion',
        component: Tools02CopyPromotion,
        props: route => ({
          accountId: String(route.params.accountId)
        }),
        meta: {
          title: `広告成果連携ツール プログラムコピー`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'usage-guide',
        name: 'tools02-usage-guide',
        component: Tools02UsageGuide,
        meta: {
          title: `広告成果連携ツール 利用設定・利用ステータス・ポイント消化について`,
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'unit-detail/:unitId',
        name: 'tools02-unit-detail',
        component: Tools02UnitDetail,
        props: route => ({
          unitId: String(route.params.unitId)
        }),
        meta: {
          title: `広告成果連携ツール ユニット詳細`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'units',
        name: 'tools02-units',
        component: Tools02Units,
        meta: {
          title: `広告成果連携ツール ユニット一覧`,
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: 'bridges',
        name: 'tools02-bridges',
        component: Tools02Bridges,
        meta: {
          title: 'atidログ',
          description: '',
          requiresAuth: true,
          tools02Auth: true
        }
      },
      {
        path: '/tools02/cart-system/:aspId',
        name: 'tools02-cart-system',
        component: Tools02CartSystem,
        props: route => ({
          aspId: String(route.params.aspId)
        }),
        meta: {
          title: `広告成果連携ツール カートシステム契約`,
          description: '',
          requiresAuth: true
          // ,
          // tools02Auth: true
        }
      },
      {
        path: 'resend-offline-conversions',
        name: 'tools02-resend-offline-conversions',
        component: Tools02ResendOfflineConversions,
        meta: {
          title: '',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'domains',
        name: 'domains',
        component: Tools02Domains,
        meta: {
          title: 'ドメイン一覧',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: 'domain-detail/:domainId',
        name: 'domain-detail',
        component: Tools02DomainDetail,
        props: route => ({
          domainId: String(route.params.domainId)
        }),
        meta: {
          title: 'ドメイン詳細',
          description: '',
          requiresAuth: true
        }
      },
    ]
  },
  //↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ tools02 ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑









  //↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ affiliate ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  {
    path: '/affiliate/',
    name: 'affiliate',
    component: Affiliate,
    children: [{
        path: 'bank-account',
        name: 'affiliate-bank-account',
        component: AffiliateBankAccount,
        meta: {
          title: 'アフィリエイト 報酬の振込先となる銀行口座情報の登録',
          description: '',
          requiresAuth: true
        }
      },
      {
        path: '',
        name: 'affiliate-home',
        component: AffiliateHome,
        meta: {
          title: 'アフィリエイト ホーム',
          description: '',
          requiresAuth: true,
          bankAccountAuth: true
        }
      },
      {
        path: 'link',
        name: 'affiliate-link',
        component: AffiliateLink,
        meta: {
          title: 'アフィリエイト アフィリエイトリンク・特典設定',
          description: '',
          requiresAuth: true,
          bankAccountAuth: true
        }
      },
      {
        path: 'notice',
        name: 'affiliate-notice',
        component: AffiliateNotice,
        meta: {
          title: 'アフィリエイト 通知設定',
          description: '',
          requiresAuth: true,
          bankAccountAuth: true
        }
      },
      {
        path: 'result',
        name: 'affiliate-result',
        component: AffiliateResult,
        meta: {
          title: 'アフィリエイト アフィリエイト報酬・成果確認',
          description: '',
          requiresAuth: true,
          bankAccountAuth: true
        }
      },
      {
        path: 'reward',
        name: 'affiliate-reward',
        component: AffiliateReward,
        meta: {
          title: 'アフィリエイト アフィリエイト報酬明細',
          description: '',
          requiresAuth: true,
          bankAccountAuth: true
        }
      },
      {
        path: 'status',
        name: 'affiliate-status',
        component: AffiliateStatus,
        meta: {
          title: 'アフィリエイト 獲得ユーザーの利用状況（サマリー・個別）',
          description: '',
          requiresAuth: true,
          bankAccountAuth: true
        }
      },
    ]
  },
  //↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ affiliate ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑









  //↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ ctrl ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  {
    path: '/ctrl/',
    name: 'ctrl',
    component: Ctrl,
    children: [{
        path: 'active-confirm/',
        name: 'ctrl-active-confirm',
        component: CtrlActiveConfirm,
        meta: {
          title: '活性確認',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'contact',
        name: 'ctrl-contact',
        component: CtrlContact,
        meta: {
          title: '',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'browser-test-tool',
        name: 'ctrl-browser-test-tool',
        component: CtrlBrowserTestTool,
        meta: {
          title: '',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'contact-edit/:user_id',
        name: 'ctrl-contact-edit',
        component: CtrlContactEdit,
        meta: {
          title: '',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: '',
        name: 'ctrl-home',
        component: CtrlHome,
        meta: {
          title: 'GH管理画面トップ',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'login',
        name: 'ctrl-login',
        component: CtrlLogin,
        meta: {
          title: 'GH管理画面ログイン',
          description: ''
        }
      },
      {
        path: 'post',
        name: 'ctrl-post',
        component: CtrlPost,
        meta: {
          title: 'お知らせ投稿',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'post-edit/:id',
        name: 'ctrl-post-edit',
        component: CtrlPostEdit,
        meta: {
          title: 'お知らせ投稿（編集）',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'qa',
        name: 'ctrl-qa',
        component: CtrlQA,
        meta: {
          title: 'よくある質問',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'qa-edit/:qa_id/:cate01/:cate02',
        name: 'ctrl-qa-edit',
        component: CtrlQAEdit,
        meta: {
          title: 'お知らせ投稿（編集）',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'user',
        name: 'ctrl-user',
        component: CtrlUser,
        meta: {
          title: 'ユーザー情報',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'users',
        name: 'ctrl-users',
        component: CtrlUsers,
        meta: {
          title: 'ユーザー検索',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'user-edit/:user_id',
        name: 'ctrl-user-edit',
        component: CtrlUserEdit,
        meta: {
          title: 'ユーザー情報詳細',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'gives-points',
        name: 'ctrl-gives-points',
        component: CtrlGivesPoints,
        meta: {
          title: 'ポイント付与',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'parameter-log',
        name: 'ctrl-parameter-log',
        component: CtrlParameterLog,
        meta: {
          title: 'パラメータログ',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'auto-import-log',
        name: 'ctrl-auto-import-log',
        component: CtrlAutoImportLog,
        meta: {
          title: '自動登録履歴',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'usage-summary',
        name: 'ctrl-usage-summary',
        component: CtrlUserSummary,
        meta: {
          title: '利用状況確認',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      // {
      //   path: 'send-mail-list',
      //   name: 'ctrl-send-mail-list',
      //   component: CtrlSendMailList,
      //   meta: {
      //     title: 'ユーザー一斉送信履歴・メニュー',
      //     description: '',
      //     requiresAuth: true,
      //     requiresType: 1
      //   }
      // },
      // {
      //   path: 'send-mail',
      //   name: 'ctrl-send-mail',
      //   component: CtrlSendMail,
      //   meta: {
      //     title: 'ユーザー一斉送信',
      //     description: '',
      //     requiresAuth: true,
      //     requiresType: 1
      //   }
      // },
      {
        path: 'send-mails',
        name: 'ctrl-send-mails',
        component: CtrlSendMails,
        meta: {
          title: 'メール一斉送信（一覧）',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'send-mail-detail/:mode/:sendId',
        name: 'ctrl-send-mail-detail',
        props: route => ({
          mode: String(route.params.mode),
          sendId: String(route.params.sendId)
        }),
        component: CtrlSendMailDetail,
        meta: {
          title: 'メール一斉送信（詳細）',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'concise-analysis',
        name: 'ctrl-concise-analysis',
        component: CtrlConciseAnalysis,
        meta: {
          title: '簡易確認',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'api-histories',
        name: 'api-histories',
        component: CtrlApiHistories,
        meta: {
          title: '',
          description: '',
          requiresAuth: true,
          requiresType: 1
        },
        children: [{
            path: '',
            name: 'home',
            component: CtrlApiHistoriesHome,
            meta: {
              title: 'ホーム',
              description: '',
              requiresAuth: true,
              requiresType: 1
            }
          },
          {
            path: 'google',
            name: 'google',
            component: CtrlApiHistoriesGoogleApiHistories,
            meta: {
              title: 'Google API送信履歴',
              description: '',
              requiresAuth: true,
              requiresType: 1
            }
          },
          {
            path: 'facebook',
            name: 'facebook',
            component: CtrlApiHistoriesFacebookApiHistories,
            meta: {
              title: 'Facebook API送信履歴',
              description: '',
              requiresAuth: true,
              requiresType: 1
            }
          },
        ]
      },
      {
        path: 'bridges',
        name: 'ctrl-tools02-bridges',
        component: CtrlTools02Bridges,
        meta: {
          title: 'adtasukaruId',
          description: '',
          requiresAuth: true,
          requiresType: 1
        }
      },
      {
        path: 'affiliate',
        name: 'ctrl-affiliate',
        component: CtrlAffiliate,
        meta: {
          title: '',
          description: '',
          requiresAuth: true,
          requiresType: 1
        },
        children: [{
          path: '',
          name: 'ctrl-affiliate-home',
          component: CtrlAffiliateHome,
          meta: {
            title: '',
            description: '',
            requiresAuth: true,
            requiresType: 1
          }
        }, {
          path: 'rewards',
          name: 'ctrl-affiliate-rewards',
          component: CtrlAffiliateRewards,
          meta: {
            title: '',
            description: '',
            requiresAuth: true,
            requiresType: 1
          }
        }, {
          path: 'download-bank-csv',
          name: 'ctrl-affiliate-download-bank-csv',
          component: CtrlAffiliateDownloadBankCsv,
          meta: {
            title: '',
            description: '',
            requiresAuth: true,
            requiresType: 1
          }
        }, {
          path: 'payment-information',
          name: 'ctrl-affiliate-payment-information',
          component: CtrlAffiliatePaymentInformation,
          meta: {
            title: '',
            description: '',
            requiresAuth: true,
            requiresType: 1
          }
        }, ]
      },
      {
        path: 'tools02',
        name: 'ctrl-tools02',
        component: CtrlTools02,
        meta: {
          title: '',
          description: '',
          requiresAuth: true,
          requiresType: 1
        },
        children: [{
          path: 'cart-system',
          name: 'ctrl-tools02-cart-system',
          component: CtrlTools02CartSystem,
          meta: {
            title: '',
            description: '',
            requiresAuth: true,
            requiresType: 1
          },
          children: [{
            path: '',
            name: 'ctrl-tools02-cart-system',
            component: CtrlTools02CartSystemHome,
            meta: {
              title: '',
              description: '',
              requiresAuth: true,
              requiresType: 1
            }
          }, {
            path: 'subscriptions',
            name: 'ctrl-tools02-cart-system-subscriptions',
            component: CtrlTools02CartSystemSubscriptions,
            meta: {
              title: '',
              description: '',
              requiresAuth: true,
              requiresType: 1
            }
          }]
        }]
      },
    ]
  },
  //↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ ctrl ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

];



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition || to.meta.savedPosition == true) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }

})

router.beforeEach((to, from, next) => {

  //ログインフラグが立っている時はいつでもセッション切れチェック
  if (store.getters.login === 1) {
    store.dispatch('checkSession').then((result) => {
      if (result === 1) {
        console.log("SESSION OK.");
      } else {
        store.dispatch('setLogin', 0);
        next(`/login`);
      }
    });
  } else {
    console.log("sessionチェックしません。");
  }

  setTitle(to.meta.title);
  setDescription(to.meta.description);
  // console.log("raw: " + to.fullPath);
  // let toLowerCasedFullPath = to.fullPath.toLowerCase();
  // console.log("converted: " + toLowerCasedFullPath);

  // 
  store.dispatch("setRt");
  store.dispatch("setRootUrl");

  // // 
  // let toolCodeKebab = undefined;
  // if (to.params.tool !== undefined) {
  //   toolCodeKebab = to.params.tool;
  // } else if (to.params.toolCode !== undefined) {
  //   toolCodeKebab = to.params.toolCode;
  // } else {
  //   // no action.
  // }
  // store.dispatch("setCurrentPageToolData", {toolCodeKebab: toolCodeKebab});

  //現在の残ポイントを更新
  store.dispatch('setStockPoint');
  store.dispatch('setSpentAmountsThisMonthInEachTool');

  //
  store.dispatch("toggleOptions", to.name);


  // ================================= ↓↓ いずれ消したい ↓↓ =================================

  // 
  let toolData = {
    codeKebab: '',
    codeCamel: '',
    mediaName: '',
  };
  if (to.params.tool !== undefined) {

    //最後に訪れたtoolのcode2を記録
    if (
      to.params.tool === 'popup01' ||
      to.params.tool === 'popup02' ||
      to.params.tool === 'overlay01' ||
      to.params.tool === 'overlay01'
    ) {
      store.dispatch('setLastVisitedToolCode', to.params.tool);
    } else {
      console.log('');
    }

    toolData.codeKebab = to.params.tool;
    toolData.codeCamel = convertKebabToCamel(to.params.tool);
    toolData.mediaName = "";

  } else if (to.params.toolCode !== undefined) {

    toolData.codeKebab = to.params.toolCode;
    toolData.codeCamel = convertKebabToCamel(to.params.toolCode);
    let mediaName = "";
    let clickIdName = "";
    switch (to.params.toolCode) {
      case 'google-cv':
        mediaName = "Google広告";
        clickIdName = "gclid";
        break;
      case 'facebook-cv':
        mediaName = "Facebook広告";
        clickIdName = "atid";
        break;
      case 'yahoo-cv':
        mediaName = "Yahoo!検索広告";
        clickIdName = "yclid";
        break;
      case 'tiktok-cv':
        mediaName = "TikTok広告";
        clickIdName = "tclid";
        break;
      case 'microsoft-cv':
        mediaName = "Microsoft広告";
        clickIdName = "msclkid";
        break;
      case 'cart-system':
        mediaName = "カート";
        clickIdName = '';
        break;
      case 'line-cv':
        mediaName = "LINE広告";
        clickIdName = "ldtag_cl";
        break;
      case 'yda-cv':
        mediaName = "YDA";
        clickIdName = "yclid";
        break;
      case 'squadbeyond-cv':
        mediaName = "Squad beyond";
        clickIdName = "";
        break;
      default:
        break;
    }
    toolData.mediaName = mediaName;
    toolData.clickIdName = clickIdName;

  } else {

    toolData.codeKebab = '';
    toolData.codeCamel = '';
    toolData.mediaName = '';

  }

  // 
  store.dispatch("setToolData", toolData);

  // ================================= ↑↑ いずれ消したい ↑↑ =================================

  // 
  let requiresAuth = to.meta.requiresAuth === undefined ? false : to.meta.requiresAuth;
  if (requiresAuth === true) {

    let requiresType = to.meta.requiresType === undefined ? 0 : to.meta.requiresType;
    axios
      .get(`${store.getters.rt}/api/login-check.php?login_type=${requiresType}&to_path=${to.path}`)
      .then(response => {
        console.log(response);
        if (response.data.status === 200) {
          console.log("login check OK.");
          console.log(to.meta.tools02Auth);

          // 
          let tools02Auth = to.meta.tools02Auth === undefined ? false : to.meta.tools02Auth;
          if (tools02Auth === true) {
            if (store.getters['subscription/validities'][toolData.codeCamel] === 1) {
              console.log("tools02 auth OK.");
              store.dispatch('setLogin', 1); //ログインチェック対象のページでのみ、login状態の監視をするようにする
              next();
            } else {
              alert("有効期限外です");
              next(`/tools02/${toolData.codeKebab}`);
            }
          } else {
            console.log("no tools02 auth.");

            // 口座情報登録チェック
            let bankAccountAuth = to.meta.bankAccountAuth === undefined ? 0 : to.meta.bankAccountAuth;
            if (bankAccountAuth === true) {
              if (store.getters.userData.bankCode !== "") {
                next();
              } else {
                next('/affiliate/bank-account?firstTime=true');
              }
            } else {
              next();
            }
          }

        } else {
          console.log("login check NG.");
          store.dispatch('setLogin', 0); //ログインチェック対象のページでのみ、login状態の監視をするようにする
          next({
            path: response.data.redirectUrl
          });
        }
      })
      .catch(error => {
        console.log(error);
      });

  } else {
    store.dispatch("setVariousValuesForAllUsers");
    next(); //認証の確認が必要ないルートならnext()で遷移
  }
});

export default router