<template>
  <div>
    <el-button type="primary" @click="$router.push(`/tools02/${toolData.codeKebab}/domain-detail/0`)">新規登録</el-button>
    <el-button @click="toManual()">マニュアル</el-button>
    <el-table :data="domainList">
      <el-table-column label="ドメイン">
        <template slot-scope="scope">
          {{ scope.row.domain }}
        </template>
      </el-table-column>
      <el-table-column width="150px">
        <template slot-scope="scope">
          <div class="center">
            <AuthTagButton :domainId="scope.row.id"/>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="120px">
        <template slot-scope="scope">
          <div class="center">
            <span v-if="scope.row.auth === true">認証済み</span>
            <span v-else style="color: red;">未認証</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100px">
        <template slot-scope="scope">
          <div class="center">
            <el-button type="danger" @click="deleteDomain(scope.row)">削除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AuthTagButton from "@/components/Domains/DomainList/AuthTagButton.vue";

export default {
  name: 'DomainList',
  components: {
    AuthTagButton,
  },
  props: {
    toolId: String
  },
  computed: {
    ...mapGetters(["rt", "domainList"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    }
  },
  methods: {
    toManual() {
      window.open('https://adtasukaru.wraptas.site/00b0309c3d5842cc93fae93e0b3cd3b5', '_blank');
    },
    deleteDomain(domainData) {
      // 
      let message = ``;
      message += `ドメイン「${domainData.domain}」を削除します。よろしいですか？`;
      if (confirm(message)) {
        // ok!
      } else {
        return;
      }

      // 
      let params = new URLSearchParams();
      params.append("domain_id", domainData.id);
      this.$axios
        .post(`${this.rt}/l/api/domain/delete`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("setDomainList");
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
}
</script>

<style lang="scss" scoped></style>
