<template>
  <div class="payment-active-plan-data">
    <h3 class="back-gray">現在のご契約</h3>
    <div class="payment-active-cover">
      <div class="payment-active-item">
        <div v-if="activePlanData.length > 0">
          <table
            class="typeD"
            v-for="(item, index) in activePlanData"
            :key="index"
          >
            <tr>
              <th>自動更新設定</th>
            </tr>
            <tr>
              <td>
                <div class="flex-between">
                  <div
                   
                    class="
                      animate__animated
                      animate__flash
                      animate__slower
                      animate__infinite
                      stop
                    "
                  >
                    <span>設定中</span>
                  </div>
                  <div style="min-width:82px">
                    <span
                      @click="
                        stopAutoPayment(
                          item.type,
                          item.planName,
                          item.productId
                        )
                      "
                      style="text-align: center"
                    >
                      <el-button type="info" size="mini" width="100"
                        >停止</el-button
                      >
                    </span>
                    <Question
                      :placement="'top'"
                      :content="'「停止」をクリックすると、現在、自動更新設定で契約中のプランの自動更新が停止となります。停止後、別のプランでのポイント購入が可能となります。'"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div v-else>
          <table class="typeD">
            <tr>
              <th>自動更新設定</th>
            </tr>
            <tr>
              <td>
                未設定
                <Question
                  :placement="'right'"
                  :content="'「自動更新決済での購入」をすると、自動更新が設定されます。'"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="payment-active-item">
        <table class="typeD">
          <tr>
            <th>自動更新設定中のプラン名</th>
          </tr>
          <tr v-if="activePlanData.length === 0">
            <td>なし（自動更新未設定）</td>
          </tr>
          <tr
            v-else-if="activePlanData.length > 0"
            v-for="planData in activePlanData"
            :key="planData.productId"
          >
            <td>{{ planData.planName }}</td>
          </tr>
        </table>
      </div>
      <div class="payment-active-item">
        <table class="typeD">
          <tr>
            <th>ポイント残数</th>
          </tr>
          <tr>
            <td>{{ stockPoint | localeNum }}ポイント</td>
          </tr>
        </table>
      </div>
    </div>
    <el-row>
      <el-col>
        <div class="flex-between">
          <div></div>
          <div v-if="registeredCardList.length > 0">
            <div
              v-for="cardData in registeredCardList"
              :key="cardData.cardSeq"
              class="status"
            >
              現在決済されるカード番号:{{ cardData.cardNo }}
            </div>
          </div>
          <div v-else class="no-card">カード情報の登録がありません</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Question from "@/components/Ui/Question.vue";

export default {
  name: "PaymentActivePlanData",
  components: {
    Question,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "rt",
      "stockPoint",
      "userData",
      "activePlanData",
      "apiErrorMessage",
      "registeredCardList",
    ]),
  },
  methods: {
    stopAutoPayment(planType, planName, productId) {
      var typeName;
      switch (planType) {
        case "0":
          typeName = "利用制限フリープラン";
          break;
        case "1":
          typeName = "ポイントプラン（自動更新）";
          break;
      }
      if (
        confirm(
          `${typeName}の${planName}の自動決済を停止します。よろしいですか？`
        )
      ) {
        let params = new URLSearchParams();
        params.append("product_id", productId);
        this.$axios
          .post(`${this.rt}/api/payment/stopAP.php`, params)
          .then((res) => {
            console.log(res);
            this.$store.dispatch("setActivePlanData");
            this.$store.dispatch("setRegisteredCardList");
            this.$store.dispatch("setProductList");
            return;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px){
    justify-content: center;
  }
  .stop{
    width: 70%;
     font-weight: bold;
      color: red;
       @media screen and (max-width: 767px){
         width: 100px;
       }
    
  }
}
table.typeD {
  text-align: center;
  min-height: 90px;
  tr {
    th,
    td {
      padding: 3px 3px;
    }
    th{
      font-size: 16px;
    }
  }
}
.payment-active-cover{
display: flex;
align-items: center;
justify-content: space-between;

@media screen and (max-width: 767px){
flex-direction: column;
}
.payment-active-item{
width: 32%;
@media screen and (max-width: 767px){
  width: 80%;
  margin-bottom: 15px;
}
}
}
</style>
