<template>
  <el-tooltip placement="top">
    <div slot="content">
      <div v-html="htmlText"></div>
      <div style="margin-top: 10px; text-align: center">
        <img
          :src="`${rt}/img/tools02/${imgPath}`"
          alt=""
        />
      </div>
    </div>
    <i class="el-icon-question"></i>
  </el-tooltip>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "OriginalToolTip",
  components: {
  },
  props: {
    htmlText: String,
    imgPath: String
  },
  data() {
    return {
      account: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
  },
  watch: {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>
