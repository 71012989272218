<template>
  <div class="popup-description02">
    <p class="title">特定位置登場ポップアップとは</p>
    <p>
      特定位置登場ポップアップとは、サイトを訪問しているユーザーが特定の位置に到達したら、指定のポップアップを表示して、訴求したい内容を告知したり、見せたいコンテンツへ誘導したりすることで、パフォーマンスを向上できるツールです。
    </p>
    <div class="popup02-01">
      <img :src="rt + '/img/description/popup02/shieti_popup00.png'" />
    </div>
    <p class="pin_style">
      「ここまで読んでくれた閲覧者のみに表示する」というように、ポップアップを表示させる到達位置を自由に設定できます。特定位置登場ポップアップを導入することで、読ませたいユーザーに読ませたいコンテンツを見せることができ、パフォーマンス改善へとつながります。
    </p>

    <div class="ResultAndEffect">
      <p class="title">アドタスカルは成果計測とA/Bテスト機能で改善効果◎</p>

      <p class="pin_style2">
        アドタスカルのポップアップは、コンバージョン測定機能、A/Bテスト機能、パラメータ保持機能があるため、成果計測と改善がしやすいのも大きな特徴です。
      </p>

      <Template02
        :TemplateTitle="'コンバージョン測定機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'コンバージョン測定機能で発行したコンバージョンタグをサンクスページに設置することで、コンバージョンの計測をすることができます。'"
      />
      <Template02
        :TemplateTitle="'A/Bテスト機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'アドタスカルはA/Bテスト機能を搭載しているため、テストによってパフォーマンス改善を追求することができます。A/Bテスト機能を利用することで、広告スキルやコンテンツ制作スキルもアップします。'"
      />
      <Template02
        :TemplateTitle="'パラーメター保持機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'パラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入る'"
      />
    </div>

    <p class="title">機能について</p>
    <PopupFunction />
  </div>
</template>

<script>
import Template02 from "@/components/Description/Parts/Template02.vue";
import PopupFunction from "@/components/Description/Parts/PopupFunction.vue";

export default {
  name: "popupDescription01",
  components: {
    Template02,
    PopupFunction,
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 20px;
}
.title {
  font-size: 36px;
  margin-bottom: 50px;
  color: #4d4d4d;
  font-weight: bold;
  text-align: center;
  // border-top: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
}
.popup02-01 {
  text-align: center;
  margin: 60px 0;
  img {
    width: 100％;
  }
}

.pin_style {
  margin-bottom: 150px;
}

.pin_style2 {
  margin-bottom: 70px;
}

.ResultAndEffect {
  margin: 120px 0;
}
</style>