<template>
  <div class="Ctrl-parameter-log">
    <el-row>
      <el-col>
        <h2>カートシステム申し込み一覧</h2>
      </el-col>
    </el-row>
    <CartSystemSubscriptionList />
  </div>
</template>

<script>
import CartSystemSubscriptionList from "@/components/Tools02/CartSystem/CartSystemSubscriptionList.vue";

export default {
  components: {
    CartSystemSubscriptionList,
  },
  props: {},
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
