<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ポイントバック連携とは？</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <!-- <iframe
          src="https://player.vimeo.com/video/529601289?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          width="100%"
          height="720"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          title="08-01ポイントバック連携とは？.mp4"
        ></iframe> -->
        <div style="padding: 56.25% 0 0 0; position: relative">
          <iframe
            src="https://player.vimeo.com/video/529601289?h=0652a125d0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            title="08-01ポイントバック連携とは？.mp4"
          ></iframe>
        </div>

      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
