<template>
  <el-row>
    <el-col>
      <p class="title">よくある質問</p>
      <p class="sub"></p>
      <table class="typeA">
        <thead>
          <tr>
            <th class="title">項目</th>
            <th style="width: 100px;" class="kubun">区分・対象</th>
            <th style="width: 50px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(faqData, index) in faqList" :key="index">
            <td class="title">{{ faqData.title }}</td>
            <td class="kubun">説明</td>
            <td class="manual">
              <a :href="faqData.url" target="_blank" rel="noopener noreferrer">
                <el-button type="primary">閲覧</el-button>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </el-col>
  </el-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FAQ",
  props: {
    faqList: Array,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: rgb(64, 158, 255);
  font-weight: bold;
  font-size: 19px;
  margin-top: 30px;
}
tbody {
  .title {
    color: rgb(77, 77, 64);
    font-size: 16px;
    @media screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
}
.typeA {
  .title {
    width: 65%;
  }
  .kubun {
    width: 20%;
    text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 11px!important;
    padding: 5px 0;
  }
  }
  .manual {
    text-align: center;
        @media screen and (max-width: 768px){
      padding: 5px 10px;
      display: flex;
      justify-content: center;

    }
  }

  th {
    // background-color: rgb(77, 77, 64);
    background-color: gray;
    color: white;
    font-weight: bold;
    padding: 5px 20px;
  }
    tr{
    th,td{
      @media screen and (max-width: 820px) {
      font-size: 11px;
      }
    }
    th{
      @media screen and (max-width: 768px){
        padding: 5px 10px;
      }
    }
  }
}
</style>
