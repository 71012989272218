<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（TGアフィリエイト）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a href="https://www.trafficgate.net/affiliate/login.cgi" target="_blank" rel="noopener noreferrer">管理画面</a
          >にログインし、「成果リスト」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no24/01.png`" alt="" />
        <h4>【２】ダウンロードを行いたいマーチャントを選択し「CSVでダウンロード」を選択後に「実行」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no24/02.png`" alt="" />

        <p>これで成果CSVのダウンロードは完了です。</p>
        「
        <router-link :to="`/manual/${tool.codeKebab}/07/14`"
          >ASPの成果データをアドタスカルにインポートする</router-link
        >」に進んでください。
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  components: {},
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.mini-img {
  width: 50%;
}
</style>
