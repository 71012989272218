<template>
  <div class="ctrl-contact-edit">
    <h2>お問い合わせ管理詳細</h2>
    <el-row :gutter="50" type="flex" justify="space-between" align="middle">
      <el-col :span="4">
        <router-link to="/ctrl/contact">
          <Button3 msg="戻る" type="success" />
        </router-link>
      </el-col>
    </el-row>

    <el-row style="margin-bottom: 10px">
      <el-col :span="8">お名前：{{ contactDetail.contact_name }}</el-col>
      <el-col :span="8">ヨミガナ：{{ contactDetail.name_reading }}</el-col>
    </el-row>

    <!-- <div class="border"></div> -->

    <!-- ここから質問と回答繰り返し -->
    <div v-for="(list, index) in contactDetail.list2" :key="index">
      <div class="border"></div>

      <el-row type="flex" justify="start" align="middle">
        <el-col :span="8">日時：{{ list.date }}</el-col>
        <el-col :span="12"
          >ステータス：
          <el-radio v-model="list.status" label="1">未対応</el-radio>
          <el-radio v-model="list.status" label="2">回答済</el-radio>
          <el-radio v-model="list.status" label="3">解決済</el-radio>
          <el-radio v-model="list.status" label="4">その他</el-radio>
        </el-col>
        <el-col :span="4">
          <el-button type="warning" class="detailBTN" @click="detail()"
            >更新</el-button
          >
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">カテゴリ1：{{ list.category1 }}</el-col>
        <el-col :span="8">カテゴリ2：{{ list.category2 }}</el-col>
      </el-row>

      <el-row>
        <el-col>
          <el-input
            type="textarea"
            :rows="10"
            placeholder="お問い合わせ内容が表示"
            v-model="list.question"
          ></el-input>
        </el-col>
      </el-row>

      <!-- ここから回答 -->
      <el-row type="flex" justify="start" align="middle">
        <el-col :span="8">日時：{{ list.A_date }}</el-col>
        <el-col :span="8"
          >回答者：
          <el-select v-model="respondent" placeholder="選択してください">
            <el-option
              v-for="(respondent, index) in respondentList"
              :key="index"
              :label="respondent.name"
              :value="respondent.GH_id"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">カテゴリ1：{{ list.category1 }}</el-col>
        <el-col :span="8">カテゴリ2：{{ list.category2 }}</el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-input
            type="textarea"
            :rows="10"
            placeholder="グレイトヘルプからの回答"
            v-model="list.answer"
          ></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col>
          <div style="text-align: center">
            <!-- <router-link to="/register" style="margin-right: 50px;">
            <el-button type="info">修正</el-button>
            </router-link>-->
            <el-button
              v-if="$route.params.id == 0"
              type="primary"
              class="detailBTN"
              >送信</el-button
            >
            <el-button v-else type="primary" class="detailBTN">送信</el-button>
          </div>
        </el-col>
      </el-row>
      <!-- <div class="border"></div> -->
    </div>
    <!-- ここまで繰り返し -->
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  data() {
    return {
      status: "", //ステータス
      question: "",
      answer: "",
      contactDetail: [],
      respondentList: [],
      respondent: "",
    }
  },
  components: {
    Button3,
  },
  created() {},
  mounted() {
    //お知らせ一覧を取得
    this.$axios
      .get(
        this.$store.getters.rt +
          "/api/ctrl/getContactList.php?userID=" +
          this.$route.params.user_id
      )
      .then((res) => {
        console.log(res);
        console.log(res.data.list);
        this.contactDetail = res.data.list;
        this.respondentList = res.data.respondent;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    change1: function (val) {
      // console.log(this.cateLists[val].cate01_name);
      // console.log(this.cateLists[val].cate01);
      this.cateSelect1_select = this.cateLists[val].cate01;
      this.subLists = this.cateLists[val].list2.map((e) => {
        console.log(e);
        return e;
      });
      console.log(this.subLists);
    },
  },
}
</script>

<style lang="scss" scoped>
.border {
  border-bottom: solid 2px var(--gray);
  margin-bottom: 20px;
}
.detailBTN {
  padding: 10px 30px;
}
</style>
