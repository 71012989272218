<template>
  <div class="tools02-api-history-detail-google-log-table">
    <el-row>
      <el-col>
        <p>API送信日時: {{ googleApiHistoryData.sendDatetime }}</p>
        <p>アカウント名: {{ googleApiHistoryData.accountData.accountName }}</p>
      </el-col>
    </el-row>

    <!-- ---------- ↓↓ 検索部分 ↓↓ ---------- -->
    <el-row type="flex" align="middle">
      <el-col :span="2"> 表示: </el-col>
      <el-col :span="22">
        <el-radio-group v-model="statusType">
          <el-radio-button label="">全て</el-radio-button>
          <el-radio-button label="0">エラー</el-radio-button>
          <el-radio-button label="1">成功</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <!-- ---------- ↑↑ 検索部分 ↑↑ ---------- -->

    <div
      v-if="loading === true"
      v-loading="loading"
      style="width: 100%; height: 150px"
    ></div>
    <div v-else>
      <el-row v-if="logsTotal > 0">
        <el-col>
          <el-table :data="googleApiHistoryDetailList" border>
            <el-table-column prop="clickId" label="クリックID">
            </el-table-column>
            <el-table-column label="種別">
              <template slot-scope="scope">
                <span v-if="scope.row.clickIdType === '0'">gclid</span>
                <span v-else-if="scope.row.clickIdType === '1'">wbraid</span>
                <span v-else-if="scope.row.clickIdType === '2'">gbraid</span>
              </template>
            </el-table-column>
            <el-table-column prop="cvName" label="CV名"> </el-table-column>
            <el-table-column prop="conversionDatetime" label="成果日時">
            </el-table-column>
            <el-table-column prop="conversionValue" label="コンバージョン値">
            </el-table-column>
            <el-table-column label="結果">
              <template slot-scope="scope">
                <span v-if="scope.row.errorId === '0'">成功</span>
                <span v-else-if="scope.row.errorId === '9999'"
                  >不明なエラー</span
                >
                <a v-else :href="scope.row.errorData.manualPath" target="_blank" rel="noopener noreferrer">{{
                  scope.row.errorData.message
                }}</a>
              </template>
            </el-table-column>
            <el-table-column label="エラーコード">
              <template slot-scope="scope">
                <p style="text-align: center">
                  <span v-if="scope.row.errorCodeKey !== ''"
                    >{{ scope.row.errorCodeValue }}<br /><span
                      style="font-size: 12px"
                      >({{ scope.row.errorCodeKey }})</span
                    ></span
                  >
                  <span v-else>-</span>
                </p>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row v-else>
        <el-col>
          <p style="text-align: center">ログがありません。</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Tools02ApiHistoriesGoogleLogTable",
  components: {},
  props: {
    googleApiHistoryId: String,
    pageType: {
      validator(value) {
        return ["default", "ctrl"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      // logsTotal: 0,
      logsTotal: 2,

      // ------- ↓↓ 検索用 ↓↓ -------
      statusType: "",
      // ------- ↑↑ 検索用 ↑↑ -------

      googleApiHistoryData: {
        sendDatetime: "",
        accountData: {
          accountName: "",
        },
        googleApiHistoryDetailList: [],
      },
      searchedDatetimeRange: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
    googleApiHistoryDetailList() {
      let googleApiHistoryDetailList = [];

      //
      if (this.statusType === "") {
        googleApiHistoryDetailList =
          this.googleApiHistoryData.googleApiHistoryDetailList;
      } else if (this.statusType === "0") {
        googleApiHistoryDetailList =
          this.googleApiHistoryData.googleApiHistoryDetailList.filter(
            (googleApiHistoryDetailData) =>
              googleApiHistoryDetailData.errorId !== "0"
          );
      } else if (this.statusType === "1") {
        googleApiHistoryDetailList =
          this.googleApiHistoryData.googleApiHistoryDetailList.filter(
            (googleApiHistoryDetailData) =>
              googleApiHistoryDetailData.errorId === "0"
          );
      }
      return googleApiHistoryDetailList;
    },
  },
  created() {
    this.getApiHistoryDetailList();
  },
  methods: {
    changeDatetimeRange(term) {
      this.dateTimeRange = this.g_getDatetimeRange(term);
    },
    getApiHistoryDetailList() {
      this.loading = true;
      let url = `${this.rt}/l/api/tools02/media/google/get-api-history-data`;
      url += `?google_api_history_id=${this.googleApiHistoryId}`;
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.googleApiHistoryData = response.data.googleApiHistoryData;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
