<template>
  <div class="test-laravel-test">
    <el-button @click="laravel" type="primary">laravel</el-button>
    <el-button @click="session" type="primary">session</el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TestPointBack",
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rootUrl", "rt", "apiErrorMessage"]),
  },
  watch: {},
  methods: {
    laravel() {
      this.$axios
        .get(`${this.rt}/l/api/test/response`)
        .then((response) => {
          console.log(response);
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    session() {
      this.$axios
        .get(`${this.rootUrl}/api/test/session`)
        .then((response) => {
          console.log(response);
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-add {
  margin-top: 20px;
}
</style>
