<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>パラメータ<br class="sp-only">引き継ぎ設定とは？</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>パラメータ引継ぎ設定の概要を知ること。</li>
          <li>{{tool.mediaName}}成果連携のキモになります。</li>
          <template v-if="tool.codeKebab === 'facebook-cv'">
            <li>成果連携に必要な情報を含んだクリックID（atid）の発行</li>
            <li>クリックID（atid）とASPの成果の紐づけ</li>
          </template>
          <template v-else>
            <li>この設定をすることで、{{tool.mediaName}}の{{tool.clickIdName}}をASPの成果に紐付けることができます。</li>
          </template>
        </ul>
        <h3>【所要時間】</h3>
        <p>１５分</p>
        <h3>【説明】</h3>
        <p style="font-size: 20px;">パラメータ引き継ぎ機能とは？</p>
        <template v-if="tool.codeKebab === 'facebook-cv'">
          <p>{{tool.mediaName}}と成果連携するためには、様々なユーザー情報を取得する必要があります。</p>
          <p>アドタスカルの専用のタグを設置することで、ユーザー情報を含んだクリックID（atid）が発行され、記事のURLに付与されます。</p>
          <p>{{tool.mediaName}}をクリックしたときにURLにアドタスカルのクリックID（atid）を付与し、それをアフィリリンクの後ろに付与することで、成果発生時にASP管理画面に反映される機能です。</p>
        </template>
        <template v-else>
          <p>{{tool.mediaName}}をクリックしたときにURLに付与される{{tool.clickIdName}}をアフィリリンクの後ろに付与することで、成果発生時にASP管理画面に反映される機能です。</p>
        </template>
        <p>実際のアフィリリンクを例に説明していきます。</p>
        <h4>①{{tool.mediaName}}がクリックされると、{{tool.clickIdName}}が付与されます。</h4>
        <p>https://hogehoge.com/?<span class="green">{{tool.clickIdName}}=xxxxxxxxx</span></p>
        <template v-if="tool.codeKebab === 'facebook-cv'">
          <h4>②アドタスカルのパラメータ引き継ぎ設定をすると、自動でatidが付与されます。</h4>
          <p>https://hogehoge.com/?<span class="green">atid=xxxxxxx</span>&fbclid=xxxxxxxxx</p>
          <p>URLに付与されているパラメータを取得して、アフィリリンクに付与していきます。</p>
          <h4>③レントラックスのアフィリエイトリンクを貼っていると仮定します。</h4>
          <p class="blue">https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172</p>
          <h4>④レントラックスのクリックパラメータ名「<span class="red">&amp;uix=</span>」を付けます。</h4>
          <p class="blue">https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172<span class="red">&amp;uix=</span></p>
          <h4>⑤クリックパラメータ名の次に{{tool.clickIdName}}（<span class="green">{{tool.clickIdName}}_xxxxxxxxx</span>）を付けます。</h4>
          <p class="blue">https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172<span class="red">&amp;uix=</span><span class="green">{{tool.clickIdName}}_xxxxxxxxx</span></p>
          <h4>⑥成果が発生すると「<span class="red">&amp;uix=</span>」の後ろに付けた{{tool.clickIdName}}（<span class="green">{{tool.clickIdName}}_xxxxxxxxx</span>）がASP管理画面に反映されます。</h4>
        </template>
        <template v-else>
          <p>URLに付与されているパラメータを取得して、アフィリリンクに付与していきます。</p>
          <h4>②レントラックスのアフィリエイトリンクを貼っていると仮定します。</h4>
          <p class="blue">https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172</p>
          <h4>③レントラックスのクリックパラメータ名「<span class="red">&amp;uix=</span>」を付けます。</h4>
          <p class="blue">https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172<span class="red">&amp;uix=</span></p>
          <h4>④クリックパラメータ名の次に{{tool.clickIdName}}（<span class="green">{{tool.clickIdName}}_xxxxxxxxx</span>）を付けます。</h4>
          <p class="blue">https://www.rentracks.jp/adx/r.html?idx=0.xxxx.zzzzzzz.4284.6393&amp;dna=93172<span class="red">&amp;uix=</span><span class="green">{{tool.clickIdName}}_xxxxxxxxx</span></p>
          <h4>⑤成果が発生すると「<span class="red">&amp;uix=</span>」の後ろに付けた{{tool.clickIdName}}（<span class="green">{{tool.clickIdName}}_xxxxxxxxx</span>）がASP管理画面に反映されます。</h4>
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no06/no01/01.png`" alt="" />
          </template>
        <p>このようにして、{{tool.mediaName}}とASPの成果を紐付けることができます。</p>
        <p>※クリックパラメータ名「<span class="red">&amp;uix=</span>」はASPごとに異なります。</p>
        <p>上記内容が理解できなくても大丈夫です！</p>
        <p><router-link :to="`/manual/${tool.codeKebab}/06/06`">ASP別パラメータ一覧</router-link></p>
        <template v-if="tool.codeKebab === 'facebook-cv'">
          <p>アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/parameter-manual`">パラメータ引き継ぎ設定</router-link>」をしていただくことで、記事LPのURLに付与している{{tool.clickIdName}}を自動でアフィリリンクの後ろにして、成果発生時に管理画面で確認できるようになります。</p>
          <p>実際に、「<router-link :to="`/tools02/${tool.codeKebab}/parameter-manual`">パラメータ引継ぎタグを設置する</router-link>」に進んでパラメータ引継ぎ設定を行ってください。</p>
        </template>
        <template v-else>
          <p>アドタスカルの「<router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引き継ぎ設定</router-link>」をしていただくことで、記事LPのURLに付与している{{tool.clickIdName}}を自動でアフィリリンクの後ろにして、成果発生時に管理画面で確認できるようになります。</p>
          <p>実際に、「<router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link>」に進んでパラメータ引継ぎ設定を行ってください。</p>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.blue {
  color: var(--blue2);
}
</style>
