<template>
  <div class="tools01-abtest-report-list">
    <el-row>
      <el-col :span="4">
        <router-link
          :to="`/tools01/${toolData.code2}/abtest-detail/${toolPageId}/${toolPageTestId}`"
        >
          <el-button type="primary">確認</el-button>
        </router-link>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <el-descriptions
          title="合計"
          direction="vertical"
          :column="5"
          size="mini"
          border
        >
          <el-descriptions-item label="ABテスト名">{{
            abTestData.name
          }}</el-descriptions-item>
          <el-descriptions-item label="テスト開始日">{{
            abTestData.start
          }}</el-descriptions-item>
          <el-descriptions-item label="テスト終了日">{{
            abTestData.end
          }}</el-descriptions-item>
          <el-descriptions-item label="終了条件回数"
            >{{ abTestData.amountLimit }}</el-descriptions-item
          >
          <el-descriptions-item label="ステータス">{{
            abTestData.statusText
          }}</el-descriptions-item>
        </el-descriptions>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        <h4>レポート</h4>
        <el-table :data="reportList[1].list" border>
          <!-- ------------ ↓↓ フォーマット別 ↓↓ ------------ -->
          <template>
            <el-table-column fixed label="フォーマット名">
              <template slot-scope="scope">
                <span>{{ scope.row.formatName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-if="pageData.toolId === '1'"
              fixed
              label="分割位置"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.section }}</span>
              </template>
            </el-table-column>
          </template>
          <!-- ------------ ↑↑ フォーマット別 ↑↑ ------------ -->

          <!-- ------------ ↓↓ 共通部分 ↓↓ ------------ -->
          <el-table-column label="表示回数">
            <template slot-scope="scope">
              <span>{{ scope.row.displayedCount | localeNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="クリック数">
            <template slot-scope="scope">
              <span>{{ scope.row.clickedCount | localeNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="クリック率">
            <template slot-scope="scope">
              <span>{{ scope.row.ctr }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="CV数">
            <template slot-scope="scope">
              <span>{{ scope.row.conversionCount | localeNum }}</span>
            </template>
          </el-table-column>
          <el-table-column label="CVR">
            <template slot-scope="scope">
              <span>{{ scope.row.tcvr }}%</span>
            </template>
          </el-table-column>
          <el-table-column label="報酬額">
            <template slot-scope="scope">
              <span>{{ scope.row.rewardTotal | localeNum }}円</span>
            </template>
          </el-table-column>
          <el-table-column label="EPP">
            <template slot-scope="scope">
              <span>{{ scope.row.epp }}円</span>
            </template>
          </el-table-column>
          <!-- ------------ ↑↑ 共通部分 ↑↑ ------------ -->
        </el-table>
      </el-col>
    </el-row>
    <ReportColumnDescription :toolId="toolId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReportColumnDescription from "@/components/Tools01/Report/ReportColumnDescription.vue";

export default {
  name: "abtest-report-list",
  components: {
    ReportColumnDescription,
  },
  props: {
    toolId: String,
    toolPageId: String,
    toolPageTestId: String,
  },
  data() {
    return {
      reportList: [],
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
    pageData() {
      return this.$store.getters["tools01/pageDataBySomeIds"](
        this.toolId,
        this.toolPageId
      );
    },
    abTestData() {
      return this.$store.getters["tools01/abTestDataBySomeIds"](
        this.toolId,
        this.toolPageId,
        this.toolPageTestId
      );
    },
  },
  created() {
    this.getReportList();
  },
  methods: {
    getReportList() {
      //
      this.loading = true;

      //
      let parameters = "";
      parameters += `?page_id=${this.pageData.id}`;
      parameters += `&tool_id=${this.toolId}`;
      parameters += `&ab_list=${JSON.stringify(["a", "b"])}`;
      parameters += `&date_range=${JSON.stringify([
        this.abTestData.start,
        this.abTestData.end,
      ])}`;
      this.$axios
        .get(`${this.rt}/l/api/tools01/log/get-report-list${parameters}`)
        .then((response) => {
          console.log(response);
          this.reportList = response.data.list;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.typeA {
  tr {
    th,
    td {
      padding: 10px;
    }
  }
}
</style>
