<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>プログラム情報登録<br class="sp-only">（TOSHOアフィリエイト）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a
            href="https://af.tosho-trading.co.jp/affiliator/login.php"
            target="_blank"
            rel="noopener noreferrer"
            >管理画面</a
          >にログインしたら、「アフィリエイト一覧」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no23/01.png`" alt="" />
        <h4>
          【２】登録したいアフィリエイトを探して、「プログラムID」と「プログラム名」をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no23/02.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">
          --------------------------------
        </p>
        <h4>【３】アドタスカルのASPプログラム名、プログラムIDに貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no23/03.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  components: {
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
  created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
