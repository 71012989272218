<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>キャンペーン固有の目標設定（設定方法）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>「キャンペーン固有の目標設定」を設定できるようになること</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【設定方法】</h3>
        <p>実際の設定方法を解説していきます。</p>

        <p class="step">■STEP1：カスタム目標を作成する</p>
        <p>まず、「カスタム目標」を作成します。</p>
        <h4>
          【１】Google広告の管理画面のメニューから「ツールと設定」→「測定」→「コンバージョン」の順にクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no08/01.png`" alt="" />
        <h4>
          【２】「コンバージョンの概要」ページが表示されるので、一番下までスクロールします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no08/02.png`" alt="" />
        <h4>【３】ページの最下部にある「カスタム目標」をクリックします。</h4>
        <h4>【４】「カスタム目標を追加します」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no08/03.png`" alt="" />
        <h4>
          【５】カスタム目標の名前を入力して、最適化の対象にしたいCVにチェックを付けます。できたら「保存」をクリックします。
        </h4>
        <ul>
          <li>MCVで最適化したい場合は、MCV</li>
          <li>最終CVで最適化したい場合は、オフラインコンバージョン</li>
        </ul>
        <p>を選択してください。</p>

        <h4>＜MCVを最適化したい場合＞</h4>
        <div class="mini-img" style="margin-top: 5px">
          <img :src="`${rt}/img/manual/google-cv/no11/no08/04.png`" alt="" />
        </div>
        <h4>＜最終CVを最適化したい場合＞</h4>
        <div class="mini-img" style="margin-top: 5px">
          <img :src="`${rt}/img/manual/google-cv/no11/no08/05.png`" alt="" />
        </div>
        <p>※事前に案件ごとにオフラインコンバージョンを作成しておいてください。</p>
        <p style="margin-left:36px;">まだの場合は、「<router-link to="/manual/google-cv/03/02"
            >オフラインコンバージョンの作成</router-link
          >」をご覧ください。</p>

        <h4>＜複数案件のコンバージョンを最適化対象にしたい場合＞</h4>
        <p>比較サイトやランキングサイトで同一キャンペーンで複数案件を扱う場合は、最適化させたいコンバージョンを全て選択してください。</p>
          <img :src="`${rt}/img/manual/google-cv/no11/no08/06.png`" alt="" />
          <p>これで「カスタム目標」の設定が完了しました。</p>

<p class="step">■STEP2：キャンペーンに固有の目標設定をする</p>
<p>次に、作成した「カスタム目標」をキャンペーンに設定していきます。</p>

<h4>【１】設定したいキャンペーン→「設定」をクリックします。</h4>
          <img :src="`${rt}/img/manual/google-cv/no11/no08/07.png`" alt="" />
<h4>【２】「目標」をクリックします。</h4>
          <img :src="`${rt}/img/manual/google-cv/no11/no08/08.png`" alt="" />
<h4>【３】「キャンペーン固有の目標設定を使用」にチェックを入れて、「キャンペーン目標を選択」をクリックします。</h4>
          <img :src="`${rt}/img/manual/google-cv/no11/no08/09.png`" alt="" />
<h4>【４】STEP1で作成した「カスタム目標」を選択して、「保存」をクリックします。</h4>
         <div class="mini-img"><img :src="`${rt}/img/manual/google-cv/no11/no08/10.png`" alt="" /></div>
         <h4>【５】選択したカスタム目標が選択された状態になるので、「保存」をクリックします。</h4>
         <img :src="`${rt}/img/manual/google-cv/no11/no08/11.png`" alt="" />
         <h4>【６】これでキャンペーン固有の目標設定は完了です。</h4>
         <p>選択したカスタム目標に含まれるコンバージョンのみが最適化の対象になります。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.step {
  font-weight: bold;
  font-size: 25px;
  margin: 50px 0 0 0;
}
</style>
