<template>
  <div class="tools02-parameter-manual-manual-system">
    <template
      v-if="
        category01 === 0 ||
        category02 === 0 ||
        (tool.codeKebab === 'facebook-cv' && unitId === 0)
      "
    >
      <ManualSelector
        :category01="category01"
        :category02="category02"
        :unitId="unitId"
      />
    </template>
    <template v-else>
      <!-- <el-row type="flex" justify="center" style="font-weight: bold">
        <el-col :span="8">
          <span>サイト作成ツール：</span>
          <span v-if="category01 === 1">WordPress</span>
          <span v-else-if="category01 === 2">HTML,PHP</span>
          <span v-else-if="category01 === 3">Squad beyond</span>
        </el-col>
        <el-col :span="8">
          <span>アフィリリンク：</span>
          <span v-if="category02 === 1">サイトに直接設置する</span>
          <span v-else-if="category02 === 2">中間ページ</span>
        </el-col>
        <el-col
          :span="8"
          v-if="tool.codeKebab === 'facebook-cv' && unitId !== ''"
        >
          <span>ユニット：</span>
          <span>{{ unitDetail["unitName"] }}</span>
        </el-col>
      </el-row> -->
      <div class="herf-cover">
        <div class="herf">
          <span>サイト作成ツール：</span>
          <span v-if="category01 === 1">WordPress</span>
          <span v-else-if="category01 === 2">HTML,PHP</span>
          <span v-else-if="category01 === 3">Squad beyond</span>
        </div>
        <div class="herf">
          <span>アフィリリンク：</span>
          <span v-if="category02 === 1">サイトに直接設置する</span>
          <span v-else-if="category02 === 2">中間ページ</span>
        </div>
        <div
          class="herf"
          v-if="tool.codeKebab === 'facebook-cv' && unitId !== ''"
        >
          <span>ユニット：</span>
          <span>{{ unitDetail["name"] }}</span>
        </div>
      </div>
      <el-row v-if="category01 === 3">
        <el-col>
          <h3 class="back-gray">注意事項</h3>
          <p>
            アドタスカルもSquad
            BeyondもASPのポイントバック機能を使っているのですが、
            ASP側で１つしか設定できないので、どちらかのツール片方しか連携できない形になります。
          </p>
          <p>
            なので、アドタスカルを使用する場合、 Squad
            Beyondの成果計測は、CVタグ設置で行うことになりますのでご了承ください。
          </p>
          <p>※Squad Beyond上での乖離は起きてしまいます。</p>
          <p>
            今後、Squad Beyondユーザーが増えてきましたら、 アドタスカルとSquad
            Beyondとの連携も視野にいれています。
          </p>
        </el-col>
      </el-row>
      <RecommendedSettings v-show="category02 === 2" />
      <SetLpTag :category01="category01" :normalTag="normalTag" />
      <SetAffiliateLink v-show="category02 === 1" :category01="category01" />
      <MiddlePage
        v-show="category02 === 2"
        :unitId="unitId"
        :category01="category01"
        :middlePageTag="middlePageTag"
      />
      <InternalLink />
      <Complete />
      <el-row>
        <el-col>
          <h3 class="back-gray" style="margin-top: 120px">
            条件を変えて検索する
          </h3>
          <ManualSelector
            :category01="category01"
            :category02="category02"
            :unitId="unitId"
          />
        </el-col>
      </el-row>
    </template>
    <FAQ :faqList="faqList"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ManualSelector from "@/components/Tools02/ParameterManual/ManualSystem/Selector.vue";
import RecommendedSettings from "@/components/Tools02/ParameterManual/Manual/RecommendedSettings.vue";
import SetLpTag from "@/components/Tools02/ParameterManual/Manual/SetLpTag.vue";
import MiddlePage from "@/components/Tools02/ParameterManual/Manual/MiddlePage.vue";
import SetAffiliateLink from "@/components/Tools02/ParameterManual/Manual/SetAffiliateLink.vue";
import InternalLink from "@/components/Tools02/ParameterManual/Manual/InternalLink.vue";
import Complete from "@/components/Tools02/ParameterManual/Manual/Complete.vue";
import FAQ from "@/components/Ui/FAQ.vue";

export default {
  name: "Tools02ParameterManualManualSystem",
  components: {
    ManualSelector,
    RecommendedSettings,
    SetLpTag,
    SetAffiliateLink,
    MiddlePage,
    InternalLink,
    Complete,
    FAQ,
  },
  props: {
    category01: Number,
    category02: Number,
    unitId: Number,
  },
  data() {
    return {
      faqList: [
        {
          title: "パラメータ引き継ぎについてよくある質問（全ツール共通）",
          url: "https://adtasukaru.wraptas.site/951b25bed0f647b7acda1399f0f242c3",
        },
      ],
      normalTag: "",
      middlePageTag: "",
      middlePageLinkTag: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools"]),
    toolId() {
      return this.tools[this.tool.codeCamel].id;
    },
    unitDetail() {
      let unitId = String(this.unitId);
      let unitData = {};
      if (this.tool.codeKebab === "facebook-cv") {
        let unitDetail = this.$store.getters["tools02/unitDetail"](unitId);
        unitData = {
          name: unitDetail.unitName,
        };
      } else {
        unitData = this.$store.getters["tools02/unitData"](unitId);
      }
      console.log(unitData);

      //
      return unitData;
    },
  },
  watch: {
    //ユニットID決定後に取得
    unitId: {
      handler(newValue) {
        if (
          this.tool.codeKebab === "google-cv" ||
          this.tool.codeKebab === "yahoo-cv" ||
          this.tool.codeKebab === "microsoft-cv" ||
          (this.tool.codeKebab === "facebook-cv" && this.unitId !== 0) ||
          this.tool.codeKebab === "tiktok-cv" ||
          this.tool.codeKebab === "line-cv" ||
          this.tool.codeKebab === "yda-cv"
        ) {
          this.getTags(this.toolId, newValue); //newValue=0はgoogle-cv用
        } else {
          //no action.
        }
      },
      immediate: true,
    },
  },
  methods: {
    getTags(toolId, unitId) {
      this.$axios
        .get(
          `${this.rt}/l/api/tools02/parameter-passing/get-adtasukaru-tag-data?tool_id=${toolId}&facebook_unit_id=${unitId}`
          // `${this.rt}/api/tools02/getTags.php?tool_id=${toolId}&unit_id=${unitId}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.normalTag = res.data.adtasukaruTagData.articleLpTag;
            this.middlePageTag = res.data.adtasukaruTagData.middlePageTag;
            this.middlePageLinkTag =
              res.data.adtasukaruTagData.middlePageLinkTag;
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.herf-cover {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 1054px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .herf {
    font-weight: bold;
    @media screen and (max-width: 1054px) {
      margin-bottom: 10px;
    }
  }
}
</style>
