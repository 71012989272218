<template>
  <div class="tools02-bridges-bridge-data-list">
    <el-row :gutter="18">
      <el-col style="width: 170px">
        <a
          :href="toolData.manualData.bridgeLogManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル"
        /></a>
      </el-col>
    </el-row>

    <!-- ------------------- ↓↓ 検索部分 ↓↓ ------------------- -->
    <div class="log-search-box">
      <el-row type="flex" align="middle">
        <el-col>
          <el-button
            @click="searchConditionData.dateTimeRange = g_getDatetimeRange(30)"
            type="primary"
            >全件（過去30日）</el-button
          >
          <el-button
            @click="
              searchConditionData.dateTimeRange =
                g_getDatetimeRange('thisMonth')
            "
            type="primary"
            >今月</el-button
          >
          <el-button
            @click="
              searchConditionData.dateTimeRange = g_getDatetimeRange('today')
            "
            type="primary"
            >本日</el-button
          >
          <el-button
            @click="
              searchConditionData.dateTimeRange =
                g_getDatetimeRange('yesterday')
            "
            type="primary"
            >昨日</el-button
          >
        </el-col>
      </el-row>

      <el-row v-if="type === 'ctrl'" type="flex" align="middle">
        <el-col :span="3">ユーザーID: </el-col>
        <el-col :span="21">
          <el-input-number
            v-model="searchConditionData.userId"
            :min="0"
          ></el-input-number>
        </el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="3">検索日時: </el-col>
        <el-col :span="21">
          <el-date-picker
            v-model="searchConditionData.dateTimeRange"
            type="datetimerange"
            align="right"
            start-placeholder="開始日時"
            end-placeholder="終了日"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-col>
      </el-row>

      <el-row
        v-if="type === 'ctrl' || tool.codeKebab === 'squadbeyond-cv'"
        type="flex"
        align="middle"
      >
        <el-col :span="3">媒体:</el-col>
        <el-col :span="21">
          <el-select
            v-model="searchConditionData.toolId"
            placeholder="選択してください。"
            clearable
          >
            <el-option
              v-for="tool in toolListInTopPageOrder"
              :key="tool.toolId"
              :label="tool.mediaName"
              :value="tool.toolId"
            ></el-option> </el-select
        ></el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="3">atid:</el-col>
        <el-col :span="5"
          ><el-input
            placeholder="空白で検索対象外となります"
            v-model="searchConditionData.adtasukaruId"
            clearable
          ></el-input
        ></el-col>
      </el-row>

      <el-row type="flex" align="middle">
        <el-col :span="3">クリックID:</el-col>
        <el-col :span="21"
          ><el-input
            placeholder="空白で検索対象外となります"
            v-model="searchConditionData.clickId"
            clearable
          ></el-input
        ></el-col>
      </el-row>

      <el-row>
        <el-col style="text-align: center">
          <el-button @click="getBridgeDataList('default', 1)" type="primary"
            >検索</el-button
          >
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row type="flex" v-if="searchedDatetimeRange.length > 0">
        <el-col
          >検索期間:
          <span
            >{{ searchedDatetimeRange[0] }} ～<br class="sp-only" />
            {{ searchedDatetimeRange[1] }}</span
          ></el-col
        >
      </el-row>
    </div>
    <!-- ------------------- ↑↑ 検索部分ここまで ↑↑ ------------------- -->

    <div
      v-if="loading === true"
      v-loading="loading"
      style="width: 100%; height: 150px"
    ></div>
    <div v-else class="width-max-cover">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="totalCount"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
        <div style="padding: 5px">
          <span style="margin-right: 15px">
            件数：
            <span>{{ totalCount }}</span
            >件
          </span>
          <el-button @click="getBridgeDataList('csv', 1)" type="success"
            >CSVダウンロード</el-button
          >
        </div>
      </div>
      <el-table :data="bridgeDataList" border>
        <el-table-column
          fixed
          v-if="type === 'ctrl'"
          label="user_id"
          prop="userId"
        >
        </el-table-column>
        <el-table-column
          fixed
          min-width="175px"
          label="atid生成日時"
          prop="formattedCreatedAt"
        >
        </el-table-column>
        <el-table-column
          v-if="type === 'ctrl' || tool.codeKebab === 'squadbeyond-cv'"
          label="媒体"
          min-width="80px"
        >
          <template slot-scope="scope">
            <span>{{
              $store.getters.toolData(scope.row.toolId).alphaCode
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="atid" min-width="260px">
          <template slot-scope="scope">
            <el-input readonly :value="scope.row.adtasukaruId">
              <el-button
                @click="g_copyText(scope.row.adtasukaruId)"
                slot="append"
                icon="el-icon-document-copy"
              ></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="クリックID"
          :min-width="tool.codeKebab === 'line-cv' ? '330px' : '700px'"
        >
          <template slot-scope="scope">
            <el-input readonly :value="scope.row.clickId">
              <el-button
                @click="g_copyText(scope.row.clickId)"
                slot="append"
                icon="el-icon-document-copy"
              ></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            type === 'ctrl' ||
            tool.codeKebab === 'squadbeyond-cv' ||
            tool.codeKebab === 'facebook-cv'
          "
          min-width="120px"
          label="unit_token"
          prop="unitToken"
        >
        </el-table-column>
        <el-table-column
          v-if="
            type === 'ctrl' ||
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'yda-cv' ||
            tool.codeKebab === 'squadbeyond-cv'
          "
          min-width="300px"
          :label="getColumnHeaderName('cookie_value')"
        >
          <template slot-scope="scope">
            <el-input readonly :value="scope.row.fbc">
              <el-button
                @click="g_copyText(scope.row.fbc)"
                slot="append"
                icon="el-icon-document-copy"
              ></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            type === 'ctrl' ||
            tool.codeKebab === 'squadbeyond-cv' ||
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'line-cv'
          "
          min-width="390px"
          :label="getColumnHeaderName('browser_id')"
        >
          <template slot-scope="scope">
            <el-input readonly :value="scope.row.fbp">
              <el-button
                @click="g_copyText(scope.row.fbp)"
                slot="append"
                icon="el-icon-document-copy"
              ></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            type === 'ctrl' ||
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'tiktok-cv' ||
            tool.codeKebab === 'line-cv' ||
            tool.codeKebab === 'squadbeyond-cv'
          "
          min-width="160px"
          label="IP"
          prop="ipAddress"
        >
        </el-table-column>
        <el-table-column
          v-if="
            type === 'ctrl' ||
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'tiktok-cv' ||
            tool.codeKebab === 'line-cv' ||
            tool.codeKebab === 'squadbeyond-cv'
          "
          min-width="300px"
          label="UA"
          prop="userAgent"
        >
          <template slot-scope="scope">
            <el-input readonly :value="scope.row.userAgent">
              <el-button
                @click="g_copyText(scope.row.userAgent)"
                slot="append"
                icon="el-icon-document-copy"
              ></el-button>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column min-width="200px" label="squadbeyond_uid">
          <template slot-scope="scope">
            <span v-if="scope.row.squadbeyondUid !== 'undefined'">{{
              scope.row.squadbeyondUid
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :page-size="pageSize"
        :pager-count="pagerCount"
        :total="totalCount"
        :current-page.sync="currentPage"
        layout="prev, pager, next"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "tools02-bridges-bridge-data-list",
  components: {
    Button2,
  },
  props: {
    type: String,
  },
  data() {
    return {
      bridgeDataList: [],
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      totalCount: 0,

      // ------- ↓↓ 検索用 ↓↓ -------
      searchConditionData: {
        dateTimeRange: [],
        userId: "",
        toolId: "",
        adtasukaruId: "",
        clickId: "",
      },
      // ------- ↑↑ 検索用 ↑↑ -------

      searchedDatetimeRange: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters([
      "rootUrl",
      "rt",
      "tool",
      "apiErrorMessage",
      "tools",
      "aspNames",
    ]),
    ...mapGetters("tools02", ["toolListInTopPageOrder"]),
    toolData() {
      return this.$store.getters["toolData"](this.tool.id);
    },
  },
  watch: {
    currentPage: {
      handler(newValue) {
        this.getBridgeDataList("default", newValue);
      },
      immediate: true,
    },
  },
  methods: {
    changeDatetimeRange(term) {
      this.dateTimeRange = this.g_getDatetimeRange(term);
    },
    getBridgeDataList(mode, currentPage) {
      this.currentPage = currentPage;
      this.loading = true;

      //
      let userId =
        this.searchConditionData.userId === ""
          ? "0"
          : this.searchConditionData.userId;

      //
      let toolId = "0";
      if (this.type === "ctrl" || this.tool.codeKebab === "squadbeyond-cv") {
        if (this.searchConditionData.toolId !== "") {
          toolId = this.searchConditionData.toolId;
        } else {
          // no action.
        }
      } else {
        toolId = this.tool.id;
      }

      //
      let url = `${this.rt}/l/api/tools02/parameter-passing/get-bridge-data-list`;
      url += `?type=${this.type}`;
      url += `&mode=${mode}`;
      url += `&datetime_range=${JSON.stringify(
        this.searchConditionData.dateTimeRange
      )}`;
      url += `&user_id=${userId}`;
      url += `&tool_id=${toolId}`;
      url += `&adtasukaru_id=${this.searchConditionData.adtasukaruId}`;
      url += `&click_id=${this.searchConditionData.clickId}`;
      url += `&current_page=${currentPage}`;
      url += `&page_size=${this.pageSize}`;
      console.log(url);

      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            if (mode === "default") {
              this.bridgeDataList = response.data.bridgeDataList;
              this.totalCount = response.data.totalCount;
              this.searchedDatetimeRange = response.data.searchedDatetimeRange;
              this.loading = false;
            } else if (mode === "csv") {
              location.href = `${this.rt}/l/api/file/download`;
              this.loading = false;
            } else {
              console.log("???");
            }
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getColumnHeaderName(column) {
      //
      let headerName = "";

      //
      switch (column) {
        case "browser_id":
          headerName = "browser_id";
          if (this.type === "ctrl") {
            headerName = "browser_id（fbp）";
          } else {
            if (this.tool.codeKebab === "facebook-cv") {
              headerName = "fbp";
            } else if (this.tool.codeKebab === "line-cv") {
              headerName = "ブラウザID";
            } else {
              //
            }
          }
          break;
        case "cookie_value":
          headerName = "fbc";
          if (this.type === "ctrl") {
            //
          } else {
            if (this.tool.codeKebab === "yda-cv") {
              headerName = "yjr";
            } else {
              //
            }
          }
          break;

        default:
          break;
      }

      //
      return headerName;
    },
  },
};
</script>

<style lang="scss" scoped>
.parameter {
  width: 300px;
  word-break: break-all;
  margin: 0 auto;
}

.navi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.sp-only {
  display: none !important;
  @media screen and (max-width: 530px) {
    display: initial !important;
  }
}

.row-cover {
  margin: 12px 0;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    // padding-left:10px;
    flex-direction: column;
    align-items: flex-start;
  }
  .item {
    min-width: 120px;
  }
  .item-entry {
    width: calc(100% - 120px);
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    display: flex;
    button {
      width: 50px;
    }
  }
}
.total_tbl {
  width: 5000px;
  tr {
    th,
    td {
      @media screen and (max-width: 767px) {
        font-size: 12px !important;
      }
    }
  }
}
</style>
