<template>
  <div class="tools01-copy">
    <el-row>
      <el-col>
        <h2>フォーマットコピー元ページ一覧</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <table class="typeA stripe">
          <thead>
            <tr>
              <th style="width: 23%;">種別</th>
              <th>
                フォーマットコピー元ページ一覧<Question
                  :placement="'right'"
                  :content="'コピーしたいフォーマットを設定しているページ名をクリックしてください。'"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(pageData, index) in pageList" :key="index">
              <td>
                <span v-if="pageData.toolId === '1'">離脱防止ポップアップ</span>
                <span v-else-if="pageData.toolId === '6'"
                  >特定位置登場ポップアップ</span
                >
              </td>
              <td>
                <router-link
                  :to="`/tools01/${toolData.code2}/copy-detail/${userPageId}/${pageData.parameter}`"
                  >{{ pageData.name }}</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Question from "@/components/Ui/Question.vue";

export default {
  components: {
    Question,
  },
  props: {
    toolId: String,
    userPageId: String,
  },
  data() {
    return {
      list: [],
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
    pageList() {
      return this.$store.getters["tools01/similarToolPageList"](this.toolId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.code {
  margin-top: 30px;
  input {
    width: 50%;
  }
}
.page-name {
  color: var(--link-blue);
  cursor: pointer;
  text-decoration: underline;
}
</style>
