<template>
  <div class="tools02-conversion-api-history-list">
    <!-- 検索部分 -->
    <div class="log-search-box">
      <el-row type="flex" align="middle">
        <el-col>
          <el-button @click="changeDatetimeRange(180)" type="primary"
            >全件（過去１８０日）</el-button
          >
          <el-button @click="changeDatetimeRange('today')" type="primary"
            >本日</el-button
          >
          <el-button @click="changeDatetimeRange('yesterday')" type="primary"
            >昨日</el-button
          >
        </el-col>
      </el-row>

      <!-- ------------------ ↓↓ 検索項目 ↓↓ ------------------ -->
      <el-row
        v-if="
          tool.codeKebab === 'tiktok-cv' ||
          tool.codeKebab === 'line-cv' ||
          tool.codeKebab === 'yda-cv'
        "
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span>検索日時: </span>
        </el-col>
        <el-col :span="20">
          <el-date-picker
            v-model="searchConditionData.dateTimeRange"
            type="datetimerange"
            align="right"
            start-placeholder="開始日時"
            end-placeholder="終了日"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-col>
      </el-row>
      <el-row
        v-if="tool.codeKebab === 'squadbeyond-cv'"
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span>CV日時: </span>
        </el-col>
        <el-col :span="20">
          <el-date-picker
            v-model="searchConditionData.conversionDateTimeRange"
            type="datetimerange"
            align="right"
            start-placeholder="開始日時"
            end-placeholder="終了日"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-col>
      </el-row>
      <el-row
        v-if="tool.codeKebab === 'squadbeyond-cv'"
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span>媒体: </span>
        </el-col>
        <el-col :span="20">
          <el-select
            v-model="searchConditionData.toolId"
            placeholder="選択してください。"
            clearable
          >
            <el-option
              v-for="tool in toolListForSearch"
              :key="tool.toolId"
              :label="tool.mediaName"
              :value="tool.toolId"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        v-if="tool.codeKebab === 'squadbeyond-cv'"
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span>ASP: </span>
        </el-col>
        <el-col :span="20">
          <el-select
            v-model="searchConditionData.aspId"
            placeholder="選択してください。"
            clearable
          >
            <el-option
              v-for="asp in aspList"
              :key="asp.aspId"
              :label="asp.aspName"
              :value="asp.aspId"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row
        v-if="
          tool.codeKebab === 'tiktok-cv' ||
          tool.codeKebab === 'line-cv' ||
          tool.codeKebab === 'yda-cv'
        "
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span v-if="tool.codeKebab === 'tiktok-cv'">ピクセル名</span>
          <span v-else-if="tool.codeKebab === 'line-cv'">LINE Tag名</span>
          <span v-else-if="tool.codeKebab === 'yda-cv'">コンバージョン名</span>
          <span v-else>タグ名</span>
          <span>: </span>
        </el-col>
        <el-col :span="12">
          <el-input
            v-model="searchConditionData.tools02TagName"
            clearable
          ></el-input>
        </el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="4">
          <span>プログラム名: </span>
        </el-col>
        <el-col :span="12">
          <el-input
            v-model="searchConditionData.tools02PromotionName"
            clearable
          ></el-input>
        </el-col>
      </el-row>
      <el-row
        v-if="
          tool.codeKebab === 'tiktok-cv' ||
          tool.codeKebab === 'line-cv' ||
          tool.codeKebab === 'yda-cv' ||
          tool.codeKebab === 'squadbeyond-cv'
        "
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span>atid: </span>
        </el-col>
        <el-col :span="12">
          <el-input
            v-model="searchConditionData.adtasukaruId"
            clearable
          ></el-input>
        </el-col>
      </el-row>
      <el-row
        v-if="tool.codeKebab === 'squadbeyond-cv'"
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span>squadbeyond_uid: </span>
        </el-col>
        <el-col :span="12">
          <el-input
            v-model="searchConditionData.squadbeyondUid"
            clearable
          ></el-input>
        </el-col>
      </el-row>
      <el-row
        v-if="
          tool.codeKebab === 'tiktok-cv' ||
          tool.codeKebab === 'line-cv' ||
          tool.codeKebab === 'yda-cv'
        "
        type="flex"
        align="middle"
      >
        <el-col :span="4">
          <span>API送信結果: </span>
        </el-col>
        <el-col :span="12">
          <el-select
            v-model="searchConditionData.eventReceived"
            clearable
            placeholder="選択してください。"
          >
            <el-option
              v-for="eventReceivedData in eventReceivedList"
              :key="eventReceivedData.id"
              :label="eventReceivedData.name"
              :value="eventReceivedData.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <!-- ------------------ ↑↑ 検索項目 ↑↑ ------------------ -->

      <!--  -->
      <el-row>
        <el-col style="text-align: center">
          <el-button
            @click="getConversionApiHistoryList('default', 1)"
            type="primary"
            >検索</el-button
          >
        </el-col>
      </el-row>
      <!--  -->

      <el-row
        v-if="tool.codeKebab === 'squadbeyond-cv'"
        type="flex"
        justify="center"
      >
        <el-col :span="16">
          <ManualSendConversionEventSystem :toolId="toolId" :type="'default'"/>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <el-row type="flex" v-if="searchedDatetimeRange.length > 0">
        <el-col :span="2">検索期間：</el-col>
        <el-col>
          {{ searchedDatetimeRange[0] }} ～
          {{ searchedDatetimeRange[1] }}
        </el-col>
      </el-row>
    </div>
    <!-- 検索部分ここまで -->

    <div
      v-if="loading === true"
      v-loading="loading"
      style="width: 100%; height: 150px"
    ></div>
    <div v-else class="width-max-cover">
      <el-row>
        <el-col>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <el-pagination
              :page-size="pageSize"
              :pager-count="pagerCount"
              :total="totalCount"
              :current-page.sync="currentPage"
              layout="prev, pager, next"
            />
            <div style="padding: 5px">
              <span style="margin-right: 15px">
                件数：
                <span>{{ totalCount }}</span
                >件
              </span>
              <el-button
                v-if="totalCount > 0"
                @click="getConversionApiHistoryList('csv', 1)"
                type="success"
                >CSVダウンロード</el-button
              >
            </div>
          </div>

          <!-- ----------- ↓↓ table ↓↓ ----------- -->
          <div v-loading="apiSending">
            <TiktokConversionApiHistoryList
              v-if="toolId === '11'"
              :conversionApiHistoryList="conversionApiHistoryList"
              @resendConversionEvent="resendConversionEvent($event)"
            />
            <LineConversionApiHistoryList
              v-if="toolId === '14'"
              :conversionApiHistoryList="conversionApiHistoryList"
              @resendConversionEvent="resendConversionEvent($event)"
            />
            <YdaConversionApiHistoryList
              v-else-if="toolId === '15'"
              :conversionApiHistoryList="conversionApiHistoryList"
              @resendConversionEvent="resendConversionEvent($event)"
            />
            <SquadbeyondConversionApiHistoryList
              v-else-if="toolId === '16'"
              :conversionApiHistoryList="conversionApiHistoryList"
              @resendConversionEvent="resendConversionEvent($event)"
            />
          </div>
          <!-- ----------- ↑↑ table ↑↑ ----------- -->

          <el-pagination
            :page-size="pageSize"
            :pager-count="pagerCount"
            :total="totalCount"
            :current-page.sync="currentPage"
            layout="prev, pager, next"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TiktokConversionApiHistoryList from "@/components/Tools02/ApiHistories/Tools02ConversionApiHistoryList/TiktokConversionApiHistoryList.vue";
import LineConversionApiHistoryList from "@/components/Tools02/ApiHistories/Tools02ConversionApiHistoryList/LineConversionApiHistoryList.vue";
import YdaConversionApiHistoryList from "@/components/Tools02/ApiHistories/Tools02ConversionApiHistoryList/YdaConversionApiHistoryList.vue";
import SquadbeyondConversionApiHistoryList from "@/components/Tools02/ApiHistories/Tools02ConversionApiHistoryList/SquadbeyondConversionApiHistoryList.vue";
import ManualSendConversionEventSystem from "@/components/Tools02/Report/Import/ManualSendConversionEventSystem.vue";

export default {
  name: "tools02-conversion-api-history-list",
  components: {
    TiktokConversionApiHistoryList,
    LineConversionApiHistoryList,
    YdaConversionApiHistoryList,
    SquadbeyondConversionApiHistoryList,
    ManualSendConversionEventSystem,
  },
  props: {
    toolId: String,
  },
  data() {
    return {
      loading: true,
      apiSending: false,
      conversionApiHistoryList: [],
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      totalCount: 0,
      eventReceivedList: [
        {
          id: 0,
          name: "成功",
          value: "1", // getで送信するのでそのまま
        },
        {
          id: 1,
          name: "失敗",
          value: "0", // getで送信するのでそのまま
        },
      ],

      // -------- ↓↓ 検索用 ↓↓ --------
      searchConditionData: {
        dateTimeRange: [],
        conversionDateTimeRange: [],
        toolId: "",
        aspId: "",
        tools02TagName: "",
        tools02PromotionName: "",
        adtasukaruId: "",
        squadbeyondUid: "",
        eventReceived: "",
      },
      // -------- ↑↑ 検索用 ↑↑ --------

      searchedDatetimeRange: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
    aspList() {
      return this.$store.getters.aspList;
    },
    toolListForSearch() {
      let toolListInTopPageOrder = JSON.parse(
        JSON.stringify(this.$store.getters["tools02/toolListInTopPageOrder"])
      );

      //
      let toolListForSearch = [];
      for (const toolData of toolListInTopPageOrder) {
        if (toolData.toolId !== "16") {
          toolListForSearch.push(toolData);
        } else {
          // no action.
        }
      }

      //
      return toolListForSearch;
    },
  },
  watch: {
    currentPage: {
      handler(newValue) {
        this.getConversionApiHistoryList("default", newValue);
      },
      immediate: true,
    },
  },
  methods: {
    changeDatetimeRange(term) {
      //
      const datetimeRange = this.g_getDatetimeRange(term);

      //
      if (this.tool.codeKebab === "squadbeyond-cv") {
        this.searchConditionData.conversionDateTimeRange = datetimeRange;
      } else {
        this.searchConditionData.dateTimeRange = datetimeRange;
      }
    },
    getConversionApiHistoryList(mode, currentPage) {
      this.currentPage = currentPage;
      this.loading = true;

      //
      const toolId =
        this.searchConditionData.toolId === ""
          ? "0"
          : this.searchConditionData.toolId;
      const aspId =
        this.searchConditionData.aspId === ""
          ? "0"
          : this.searchConditionData.aspId;

      //
      let url = `${this.rt}/l/api/tools02/conversion-api/get-api-history-list`;
      url += `?mode=${mode}`;
      url += `&tool_id=${this.toolId}`;
      url += `&search_tool_id=${toolId}`;
      url += `&asp_id=${aspId}`;
      url += `&datetime_range=${JSON.stringify(
        this.searchConditionData.dateTimeRange
      )}`;
      url += `&conversion_datetime_range=${JSON.stringify(
        this.searchConditionData.conversionDateTimeRange
      )}`;
      url += `&tools02_tag_name=${this.searchConditionData.tools02TagName}`;
      url += `&tools02_promotion_name=${this.searchConditionData.tools02PromotionName}`;
      url += `&adtasukaru_id=${this.searchConditionData.adtasukaruId}`;
      url += `&squadbeyond_uid=${this.searchConditionData.squadbeyondUid}`;
      url += `&event_received=${this.searchConditionData.eventReceived}`;
      url += `&current_page=${currentPage}`;
      url += `&page_size=${this.pageSize}`;
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            if (mode === "default") {
              this.conversionApiHistoryList = response.data.list;
              this.totalCount = response.data.totalCount;
              this.searchedDatetimeRange = response.data.searchedDatetimeRange;
            } else if (mode === "csv") {
              location.href = `${this.rt}/l/api/file/download`;
            } else {
              console.log("???");
            }
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resendConversionEvent(payload) {
      //
      if (confirm(payload.confirmText)) {
        this.apiSending = true;
      } else {
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("tool_id", this.tool.id);
      params.append("api_history_id", payload.apiHistoryData.id);
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/conversion-api/resend-conversion-event?tool_id=${this.tool.id}&api_history_id=${payload.apiHistoryData.id}`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert("処理しました。");
            this.getConversionApiHistoryList("default", 1);
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.apiSending = false;
        })
        .catch((err) => {
          console.log(err);
          this.apiSending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
