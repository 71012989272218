<template>
  <span class="tools01-page-list-active-switch">
    <el-switch v-model="active"></el-switch>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ActiveSwitch",
  props: {
    pageId: String,
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
    pageData() {
      return this.$store.getters["tools01/pageData"](this.pageId);
    },
    toolData() {
      return this.$store.getters.toolData(this.pageData.toolId);
    },
    active: {
      get() {
        return this.pageData.active;
      },
      set(newValue) {
        let params = new URLSearchParams();
        params.append("tool", this.toolData.code2);
        params.append("val", "act");
        params.append("site_id", this.pageData.siteId);
        params.append("active", newValue === true ? '1' : '0');
        this.$axios
          .post(`${this.rt}/api/tools01/regSiteDetail.php`, params)
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.$store.dispatch("tools01/setPageList");
            } else {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
