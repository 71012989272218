<template>
  <div class="affiliate-link">
    <h2>アフィリエイトリンク・特典設定</h2>
    <el-row>
      <el-col>
        <PrivilegeConfig />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PrivilegeConfig from "@/components/Affiliate/Link/PrivilegeConfig.vue";

export default {
  components: {
    PrivilegeConfig,
  },
  props: {},
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    ...mapGetters("affiliate", ["privilegeData"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
