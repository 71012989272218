<template>
  <div class="tools01-new">
    <el-row>
      <el-col>
        <h2>{{ toolData.name2 }} 新規設定</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4">
        <router-link
          :to="{ name: 'manual', query: { hash: `${toolData.code2}-01` } }"
        >
          <Button2 type2="1" msg="マニュアル" />
        </router-link>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item>
            <!-- <div>
              「ページ名」には、{{
                toolData.name2
              }}を設置するページ名を入力してください。
            </div> -->
          </el-form-item>
          <el-form-item :label="`${toolData.name}名`">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: center">
              <el-button type="primary" @click="onSubmit">登録</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  components: {
    Button2,
  },
  props: {
    toolId: String,
  },
  data() {
    return {
      form: {
        name: "",
        domain_id: "",
        path: "",
      },
      list: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },
  },
  methods: {
    onSubmit() {
      //
      if (this.form.name !== "") {
        //
      } else {
        alert("入力されていない項目があります。");
        return;
      }

      // 
      const duplicatePageData = this.$store.getters['tools01/pageDataByToolIdAndName'](
        this.toolId, this.form.name
      );
      console.log(duplicatePageData);
      if (duplicatePageData === undefined) {
        // ok!
      } else {
        alert(`同じ${this.toolData.name}名がすでに登録されています。`);
        return;
      }

      //
      let params = new URLSearchParams();
      params.append("tool", this.toolData.code2);
      params.append("name", this.form.name);
      params.append("domain", this.form.domain_id);
      params.append("path", this.form.path);
      this.$axios
        .post(`${this.rt}/api/tools01/regSite.php`, params)
        .then((response) => {
          console.log(response);
          this.$store
            .dispatch("tools01/setPageList")
            .then(() => {
              this.$router.push(`/tools01/${this.toolData.code2}`);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.code {
  margin-top: 30px;
  input {
    width: 50%;
  }
}
.el-form {
  :deep(.el-form-item__content) {
    line-height: 1.5em;
  }
}
</style>
