<template>
  <div class="affiliate-reward">
    <h2>アフィリエイト報酬明細</h2>
    <el-row>
      <el-col>
        <el-date-picker
          type="monthrange"
          v-model="monthRange"
          align="right"
          unlink-panels
          range-separator="～"
          start-placeholder="開始月"
          end-placeholder="終了月"
          value-format="yyyyMM"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row v-if="monthRange.length !== 0">
      <el-col>
        <Report :monthRange="monthRange" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Report from "@/components/Affiliate/Reward/Report.vue";

export default {
  components: {
    Report,
  },
  props: {},
  data() {
    return {
      monthRange: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    // let date = new Date();
    // let month = date.getFullYear() + (date.getMonth() + 1);
    // let newMonthRange = [];
    // for (let index = 0; index < 2; index++) {
    //   newMonthRange.push(month);
    // }
    // this.monthRange = newMonthRange;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>

