<template>
  <div class="ctrl-post-edit">
    <el-row :gutter="30" type="flex" justify="space-between">
      <el-col v-show="$route.params.id != 0" :span="4">
        <div @click="del">
          <Button3 msg="削除" type="danger" />
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【公開日時】</h3>
        <el-date-picker
          v-model="item.release_datetime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="選択してください。"
        ></el-date-picker>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【タイトル】</h3>
        <el-input placeholder="タイトルを入力してください。" v-model="item.title"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【本文】</h3>
        <el-input type="textarea" :rows="10" placeholder="本文を入力してください。" v-model="item.content"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="text-align: center;">
          <!-- <router-link to="/register" style="margin-right: 50px;">
            <el-button type="info">修正</el-button>
          </router-link>-->
          <el-button v-if="$route.params.id == 0" type="primary" @click="post">投稿</el-button>
          <el-button v-else type="primary" @click="post">更新</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  data() {
    return {
      item: {
        release_datetime: "",
        title: "",
        content: ""
      }
    }
  },
  components: {
    Button3
  },
  created() {
  },
  mounted() {
    let params = new URLSearchParams();
    params.append("post_id", this.$route.params.id);
    this.$axios
      .post(this.$store.getters.rt + "/api/ctrl/getPostDetail.php", params)
      .then(res => {
        console.log(res);
        this.item = res.data.list;
      })
      .catch(err => {
        console.log(err);
      });
  },
  methods: {

    post() {
      if (this.item.title === "") {
        alert("タイトルを入力してください。");
        return;
      } else {
        let params = new URLSearchParams();
        params.append("post_id", this.$route.params.id);
        params.append("release_datetime", this.item.release_datetime);
        params.append("title", this.item.title);
        params.append("content", this.item.content);
        this.$axios
          .post(this.$store.getters.rt + "/api/ctrl/regPostDetail.php", params)
          .then(res => {
            console.log(res);
            this.$router.push("/ctrl/post");
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    del() {
      if (confirm("この投稿を削除します。よろしいですか？")) {
        let params = new URLSearchParams();
        params.append("post_id", this.$route.params.id);
        this.$axios
          .post(this.$store.getters.rt + "/api/ctrl/delPost.php", params)
          .then(res => {
            console.log(res);
            this.$router.push("/ctrl/post");
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
