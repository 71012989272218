<template>
  <div v-loading="loading" class="ctrl-affiliate-rewards-user-summaries">
    <el-row>
      <el-col>
        <div style="overflow: scroll;">
          <table class="two-d">
            <thead>
              <tr>
                <th>支払対象年月</th>
                <th>ユーザーID</th>
                <th>ユーザー名</th>
                <th>受取方法</th>
                <th>振込金額</th>
                <th>繰越金額</th>
                <th>付与ポイント</th>
                <th>銀行名</th>
                <th>支店名</th>
                <th>口座区分</th>
                <th>口座番号</th>
                <th>口座名義</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(userPaymentData, index) in userPaymentList"
                :key="index"
              >
                  <td>{{ userPaymentData.year + "年" + userPaymentData.month + "月" }}</td>
                  <td>{{ userPaymentData.userId }}</td>
                  <td>{{ userPaymentData.userData.name01 + userPaymentData.userData.name02}}</td>
                  <td>{{ (userPaymentData.paymentType === '1')?"ポイント付与":"銀行振込" }}</td>
                  <td>{{ (userPaymentData.paymentType === '1')?"-":userPaymentData.transferWithTax }}</td>
                  <td>{{ (userPaymentData.paymentType === '1')?"-":userPaymentData.carriedForwardWithTax }}</td>
                  <td>{{ (userPaymentData.paymentType === '1')?userPaymentData.paidPoint:"-" }}</td>
                  <template v-if="userPaymentData.paymentType === '0'">
                    <td>{{ userPaymentData.userData.bankName + "（" + userPaymentData.userData.bankCode + "）" }}</td>
                    <td>{{ userPaymentData.userData.branchName + "（" + userPaymentData.userData.branchCode + "）" }}</td>
                    <td>{{ (userPaymentData.userData.accountType === '1')?"普通":"当座" }}</td>
                    <td>{{ userPaymentData.userData.accountNumber }}</td>
                    <td>{{ userPaymentData.userData.accountName }}</td>
                  </template>
                  <template v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </template>
              </tr>
            </tbody>
          </table>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CtrlAffiliatePaymentList",
  components: {},
  props: {
    dateRange: Array,
  },
  data() {
    return {
      loading: true,
      userPaymentList: [],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    dateRange: {
      handler(newDateRange) {
        this.getUserPaymentList(newDateRange);
      },
      immediate: true,
    },
  },
  methods: {
    getUserPaymentList() {
      this.loading = true;
      this.$axios
        .get(
          `${this.rt}/l/api/affiliate/get-payment-list`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.userPaymentList = response.data.paymentList;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
table.two-d {
  width: 2000px;
  tbody {
    tr {
      td {
        text-align: center;
      }
    }
  }
}
</style>
