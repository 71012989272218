<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>コンバージョンAPIで最適化する方法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>コンバージョンAPIで広告の最適化をします。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>5分</p>
        <h3>【前提】</h3>
        <ul>
          <li>Facebook広告の仕様で、すに配信が開始されている広告セットでは、コンバージョンの設定を変更することができません。</li>
          <li>そのため、新しく広告セットを作り直してください。</li>
        </ul>
        <p>以下の2つの手順をぞれぞれ説明します。</p>
        <ul>
          <li>新規でキャンペーンを作成して、広告セットに対して、コンバージョンAPIでの最適化を設定する場合</li>
          <li>作成済のキャンペーンに対して、新しい広告セットを作成し、コンバージョンAPIでの最適化を設定する場合</li>
        </ul>
        <h4>新規でキャンペーンを作成して、広告セットに対して、コンバージョンAPIでの最適化を設定する場合</h4>
        <h4>【１】<router-link :to="`/manual/${tool.codeKebab}/01/02`">広告マネージャ</router-link>の画面にアクセスし、「作成」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/01.png`" alt="" />
        <h4>【２】キャンペーンの目的を選択し、「次へ」をクリックします。アフィリエイトの場合は、基本的に「コンバージョン」を選択します。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/02.png`" alt="" />
        </div>
        <h4>【３】キャンペーンの設定画面が表示されるので、必要な情報を任意で入力し、「次へ」をクリックします。※以下の画面は、マニュアル用のため、情報の入力を行っていません。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/03.png`" alt="" />
        <h4>【４】広告セットの設定画面が表示されるので、「ピクセル」の欄をクリックし、表示されたピクセルから、アドタスカルに登録した、当広告セットで使用したいピクセルを選択します。</h4>
        <p>「ピクセル」の欄に、選択したいピクセルが表示されていない場合は、そのピクセルの権限が当広告アカウントに付与されていないことが原因です。その場合は、ピクセルに対して権限付与をする必要があります。</p>
        <p>マニュアル：<router-link :to="`/manual/${tool.codeKebab}/11/10`">ピクセルに広告アカウントの権限付与</router-link></p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/04.png`" alt="" />
        <h4>【５】ピクセルを選択すると、「コンバージョンイベント」の欄が表示されるのでクリックし、表示された選択肢の中から「購入」を選択します。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/05.png`" alt="" />
        </div>
        <p>ここまでの設定で、コンバージョンAPIでの最適化の設定ができましたので、引き続き広告セットおよび広告の設定を完了させてください。</p>
        <h4>作成済のキャンペーンに対して、新しい広告セットを作成し、コンバージョンAPIでの最適化を設定する場合</h4>
        <h4>【１】<router-link :to="`/manual/${tool.codeKebab}/01/02`">広告マネージャ</router-link>の画面にアクセスし、該当のキャンペーンをクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/06.png`" alt="" />
        <h4>【２】広告セットの画面に遷移するので、「＋作成」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/07.png`" alt="" />
        <h4>【３】「次へ」をクリックします。</h4>
        <p>この画面で広告セットと広告に名前を設定することもできます（任意）。</p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/08.png`" alt="" />
        </div>
        <h4>【４】広告セットの設定画面が表示されるので、「ピクセル」の欄をクリックし、表示されたピクセルから、アドタスカルに登録をした、当広告セットで使用したいピクセルを選択します。</h4>
        <p>「ピクセル」の欄に、選択したいピクセルが表示されていない場合は、そのピクセルの権限が当広告アカウントに付与されていないことが原因です。その場合は、ピクセルに対して権限付与をする必要があります。</p>
        <p>マニュアル：<router-link :to="`/manual/${tool.codeKebab}/11/10`">ピクセルに広告アカウントの権限付与</router-link></p>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/09.png`" alt="" />
        <h4>【５】ピクセルを選択すると、「コンバージョンイベント」の欄が表示されるのでクリックし、表示された選択肢の中から「購入」を選択します。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no11/no09/10.png`" alt="" />
        </div>
        <p>ここまでの設定で、コンバージョンAPIでの最適化の設定ができましたので、引き続き広告セットおよび広告の設定を完了させてください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.step {
  font-weight: bold;
  font-size: 25px;
  margin: 50px 0 0 0;
}
</style>
