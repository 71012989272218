<template>
  <div class="change">
    <el-row>
      <el-col>
        <h2>パスワード変更</h2>
      </el-col>
    </el-row>
    <PasswordEditor />
  </div>
</template>

<script>
import PasswordEditor from "@/components/ChangePassword/PasswordEditor.vue";
export default {
  components: {
    PasswordEditor,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
