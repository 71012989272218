<template>
  <div v-loading="loading" class="affiliate-home-summary">
    <el-row>
      <el-col>
        <table class="af-pop">
          <tr>
            <th>本日の確定報酬金額</th>
            <td>{{ summaryData.daily.reward | localeNum }}円(税込)</td>
            <th>当月の確定報酬金額</th>
            <td>{{ summaryData.monthly.reward | localeNum }}円(税込)</td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <table class="two-d">
          <thead>
            <tr>
              <th></th>
              <th>クリック数</th>
              <th>獲得<br class="sp-kaigyo">ユーザー数</th>
              <th>成約率</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>本日の<br class="sp-kaigyo">サマリー</th>
              <td>{{ summaryData.daily.clicks | localeNum }}</td>
              <td>{{ summaryData.daily.conversions | localeNum }}</td>
              <td>{{ summaryData.daily.cvr }}</td>
            </tr>
            <tr>
              <th>当月の<br class="sp-kaigyo">サマリー</th>
              <td>{{ summaryData.monthly.clicks | localeNum }}</td>
              <td>{{ summaryData.monthly.conversions | localeNum }}</td>
              <td>{{ summaryData.monthly.cvr }}</td>
            </tr>
          </tbody>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateHomeSummary",
  components: {},
  props: {},
  data() {
    return {
      loading: true,
      summaryData: {
        daily: {
          reward: 0,
          clicks: 0,
          conversions: 0,
          cvr: "-",
        },
        monthly: {
          reward: 0,
          clicks: 0,
          conversions: 0,
          cvr: "-",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  created() {
    this.getSummaryData();
  },
  methods: {
    getSummaryData() {
      this.loading = true;
      this.$axios
        .get(`${this.rt}/api/affiliate/get-summary-data.php`)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.summaryData = response.data.summaryData;
            this.loading = false;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.af-pop {
  tr {
    th,
    td {
      width: 25%;
      @media screen and (max-width: 768px) {
        display: block;
        width: 100%;
      }
    }
  }
}
table.two-d {
  tr {
    th,
    td {
      width: 25%;
      @media screen and (max-width: 768px){
        font-size: 13px;
      }
    }
  }
}
.sp-kaigyo{
  display: none;
  @media screen and (max-width: 450px){
display: initial;
  }
}
</style>
