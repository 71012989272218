<template>
  <div class="send-mails">
    <el-row>
      <el-col>
        <h2>メール一斉送信</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <SendMailLogList />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SendMailLogList from "@/components/Ctrl/SendMails/SendMailLogList.vue";

export default {
  components: {
    SendMailLogList,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
