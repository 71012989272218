<template>
  <div class="tools02-get-token">
    <el-row>
      <el-col>
        <h2>トークン取得</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col style="text-align: center;">
        <router-link to="/tools02/yahoo-cv/authorization">
          <el-button>もう一度認証する</el-button>
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {
    let newQuery = this.$route.hash.replace("#", "?");
    this.$axios
      .get(`${this.rt}/api/tools02/yahoo-cv/get-tokens.php${newQuery}`)
      .then((res) => {
        console.log(res);
        if (
          res.data.status === 200 ||
          res.data.status === 400 ||
          res.data.status === 401 ||
          res.data.status === 500
        ) {
          alert(res.data.message);
          return;
        } else {
          alert(this.apiErrorMessage);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
