<template>
  <div class="tools01-edit">
    <el-row>
      <el-col>
        <h2>{{ toolData.name2 }} 編集</h2>
      </el-col>
    </el-row>
    <PageEditor :toolId="toolId" :pageId="pageId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageEditor from "@/components/Tools01/Edit/PageEditor.vue";

export default {
  components: {
    PageEditor,
  },
  props: {
    toolId: String,
    pageId: String,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
