<template>
  <div class="manual-tools01-abtest">
    <el-row>
      <el-col>
        <h2 id="abtest">ABテスト機能（各ツール共通）</h2>
        <h3>ABテストの設定</h3>
        <p>各ツールの一覧画面から、ABテストをしたいものについて「ABテスト」をクリックします（※テキストタイプオーバーレイはABテスト機能がありません）。</p>
        <img :src="`${rt}/img/manual/manual3-3-01.jpg`" alt="">
        <p>ABテストの一覧画面に移動するので、「新規設定」をクリックします。</p>
        <img :src="`${rt}/img/manual/manual3-3-02.jpg`" alt="">
        <p>ABテストの設定・編集画面に移動するので、ABテスト設定時に必要な以下の項目を入力してください。</p>
        <table class="typeB">
          <tr>
            <th>テスト名</th>
            <td>実施するABテストに名前を設定。</td>
          </tr>
          <tr>
            <th>テスト終了時の処理</th>
            <td>テスト終了条件に達した後の処理を設定。「Aを配信する」と「配信を止める」から選択。</td>
          </tr>
          <tr>
            <th>テスト期間</th>
            <td>テストを実施する期間を設定。設定した期間でABテストが終了。</td>
          </tr>
          <tr>
            <th>終了条件回数</th>
            <td>テストの終了条件となる回数を設定。設定した回数に達すると、テスト期間の終了を待たずしてテストが終了。</td>
          </tr>
        </table>
        <p>※テスト開始日は翌日以降から選択可能となります。データ集計の問題から当日は選択できません。</p>
        <img :src="`${rt}/img/manual/manual3-3-03.jpg`" alt="">
        <p>後続する設定項目については、ツールごとに異なり、各ツールのマニュアルにそってA側とB側の設定をしてください。設定後は、必ず画面下部にある「登録」をクリックして、設定を確定させてください。</p>
        <img :src="`${rt}/img/manual/manual3-3-04.jpg`" alt="">
        <h3>ABテストの一覧画面の操作</h3>
        <p>ABテストの一覧画面にも記載のとおり、それぞれのボタンの役割は以下となります。</p>
        <table class="typeB">
          <tr>
            <th>停止</th>
            <td>ABテスト終了後に「A側を配信」と設定してある場合、A側の配信を「停止」します。停止後は、設定済の通常フォーマットが配信されます。</td>
          </tr>
          <tr>
            <th>終了</th>
            <td>設定したテスト期間が終了する前に、実行中のABテストを終了します。</td>
          </tr>
          <tr>
            <th>レポ</th>
            <td>ABテスト結果を確認することができます。</td>
          </tr>
          <tr>
            <th>確認</th>
            <td>設定したABテストの内容を確認・編集できます。</td>
          </tr>
          <tr>
            <th>コピー</th>
            <td>ABテストの内容をコピーして、新しいABテストを設定することができます。</td>
          </tr>
          <tr>
            <th>削除</th>
            <td>ABテスト結果および設定したABテストの内容を削除します。</td>
          </tr>
        </table>
        <img :src="`${rt}/img/manual/manual3-3-05.jpg`" alt="">
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ManualTools01ABtest',
  props: {
    tool: String
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
