<template>
  <div class="resend-google-offline-conversions">
    <el-row>
      <el-col>
        <h2>API手動アップロード</h2>
      </el-col>
      <el-col style="width: 170px">
        <a
          href="https://adtasukaru.wraptas.site/e1035229093f4aefaa4e066b0d0012f6"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <!-- 検索部分 -->
    <el-row class="log-search-box">
      <el-col>
        <el-row type="flex" align="middle">
          <el-col>
            <el-button @click="changeDatetimeRange('180')" type="primary"
              >全件（過去６０日）</el-button
            >
            <el-button @click="changeDatetimeRange('today')" type="primary"
              >本日</el-button
            >
            <!--  -->
            <el-button @click="changeDatetimeRange('yesterday')" type="primary"
              >昨日</el-button
            >
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">
            <span>検索日時：</span>
          </el-col>
          <el-col :span="21">
            <el-date-picker
              v-model="dateTimeRange"
              type="datetimerange"
              align="right"
              start-placeholder="開始日時"
              end-placeholder="終了日"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
            ></el-date-picker>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">アカウント名: </el-col>
          <el-col :span="20">
            <el-select
              placeholder="選択してください"
              v-model="accountId"
              clearable
            >
              <el-option
                v-for="(accountData, accountIndex) in accountList"
                :key="accountIndex"
                :label="accountData.accountName"
                :value="accountData.accountId"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">オフラインCV名: </el-col>
          <el-col :span="20">
            <el-select
              placeholder="選択してください"
              v-model="promotionId"
              :disabled="accountId === ''"
              clearable
            >
              <el-option
                v-for="(promotionData, promotionIndex) in promotionList"
                :key="promotionIndex"
                :label="promotionData.cvName"
                :value="promotionData.promotionId"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">種別: </el-col>
          <el-col :span="20">
            <el-checkbox-group v-model="clickIdTypeList">
              <el-checkbox
                v-for="(
                  referenceClickIdTypeData, index
                ) in referenceClickIdTypeList"
                :key="index"
                :label="referenceClickIdTypeData.type"
                >{{ referenceClickIdTypeData.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="text-align: center">
            <el-button @click="getReportList(1)" type="primary">検索</el-button>
          </el-col>
        </el-row>
        <!--  -->
        <el-divider></el-divider>
        <el-row type="flex" v-if="searchedDatetimeRange.length > 0">
          <el-col :span="2">検索期間：</el-col>
          <el-col>
            {{ searchedDatetimeRange[0] }} ～
            {{ searchedDatetimeRange[1] }}
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 検索部分ここまで -->

    <div
      v-if="loading === true"
      v-loading="loading"
      style="width: 100%; height: 150px"
    ></div>
    <div v-else v-loading="apiSending" class="width-max-cover">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <el-pagination
          :page-size="pageSize"
          :pager-count="pagerCount"
          :total="logsTotal"
          :current-page.sync="currentPage"
          layout="prev, pager, next"
        />
        <div style="padding: 5px">
          <span style="margin-right: 15px">
            件数：
            <span>{{ logsTotal }}</span
            >件
          </span>
          <el-button @click="resendAllOfflineConversions" type="primary"
            >検索結果を全件送信</el-button
          >
        </div>
      </div>
      <el-table :data="reportList" border>
        <el-table-column label="種別" width="70">
          <template slot-scope="scope">
            {{ scope.row.clickIdType === "1" ? "wbraid" : "gbraid" }}
          </template>
        </el-table-column>
        <el-table-column prop="gclid" label="クリックID"></el-table-column>
        <el-table-column label="オフラインCV名" width="300">
          <template slot-scope="scope">
            {{ scope.row.promotionData.cvName }}
          </template>
        </el-table-column>
        <el-table-column prop="conversionTime" label="CV日時" width="200">
        </el-table-column>
        <el-table-column label="実質報酬額" width="100">
          <template slot-scope="scope">
            {{ scope.row.realConversionValue | localeNum }}
          </template>
        </el-table-column>
        <el-table-column label="アカウント名" width="200">
          <template slot-scope="scope">
            {{ scope.row.accountData.accountName }}
          </template>
        </el-table-column>
        <el-table-column label="送信" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              @click="resendOneOfflineConversion(scope.row)"
              type="primary"
              size="mini"
              >送信</el-button
            >
            <div style="text-align: center"></div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :page-size="pageSize"
        :pager-count="pagerCount"
        :total="logsTotal"
        :current-page.sync="currentPage"
        layout="prev, pager, next"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
export default {
  name: "resend-google-offline-conversions",
  components: {
    Button2,
  },
  props: {
    pageType: {
      validator(value) {
        return ["default", "ctrl"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      pageSize: 100,
      pagerCount: 7,
      currentPage: 1,
      logsTotal: 0,
      referenceClickIdTypeList: [
        {
          type: "1",
          name: "wbraid",
        },
        {
          type: "2",
          name: "gbraid",
        },
      ],

      // ===== ↓↓ 検索用 ↓↓ =====
      userId: 0,
      dateTimeRange: [],
      accountId: "",
      promotionId: "",
      aspId: "",
      clickIdTypeList: ["1", "2"],
      // ===== ↑↑ 検索用 ↑↑ =====

      reportList: [],
      searchedDatetimeRange: [],
      loading: true,
      apiSending: false,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
    accountList() {
      return this.$store.getters["tools02/accounts"]("7");
    },
    promotionList() {
      let promotionList = [];

      //
      if (this.accountId !== "") {
        promotionList = this.$store.getters["tools02/promotionsInAccount"](
          "7",
          this.accountId
        );
      } else {
        // no action.
      }

      //
      return promotionList;
    },
  },
  watch: {
    currentPage: {
      handler(currentPage) {
        this.getReportList(currentPage);
      },
      immediate: true,
    },
  },
  methods: {
    changeDatetimeRange(term) {
      this.dateTimeRange = this.g_getDatetimeRange(term);
    },
    getReportList(currentPage, resendAllOfflineConversions = false) {
      // 
      if (this.clickIdTypeList.length > 0) {
        // ok.
      } else {
        alert("「種別」は１つ以上選択してください。");
        return;
      }
      
      //
      this.currentPage = currentPage;
      if (resendAllOfflineConversions === true) {
        this.apiSending = true;
      } else {
        this.loading = true;
      }

      //
      let url = `${this.rt}/l/api/tools02/report/get-report-list`;
      url += `?mode=default`;
      url += `&datetime_range=${JSON.stringify(this.dateTimeRange)}`;
      url += `&user_id=${this.userId}`;
      url += `&tool_id=7`;
      url += `&click_id_type_list=${JSON.stringify(this.clickIdTypeList)}`;
      url += `&account_id=${this.accountId === "" ? "0" : this.accountId}`;
      url += `&promotion_id=${
        this.promotionId === "" ? "0" : this.promotionId
      }`;
      url += `&asp_id=${this.aspId === "" ? "0" : this.aspId}`;
      if (resendAllOfflineConversions === true) {
        url += `&current_page=1`;
        url += `&page_size=999999999`;
      } else {
        url += `&current_page=${currentPage}`;
        url += `&page_size=${this.pageSize}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            //
            if (resendAllOfflineConversions === true) {
              //
              let reportIdList = [];

              //
              response.data.reportList.forEach((reportData) => {
                reportIdList.push(reportData.reportId);
              });

              //
              this.resendOfflineConversions(reportIdList);
            } else {
              this.logsTotal = response.data.totalCount;
              this.reportList = response.data.reportList;
              this.searchedDatetimeRange = response.data.searchedDatetimeRange;
              this.loading = false;
            }
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resendAllOfflineConversions() {
      if (confirm("現在、検索結果に表示されている成果を全件送信します。よろしいですか？")) {
        this.getReportList(1, true); // もっといい方法があれば知りたい
      } else {
        // no action.
      }
    },
    resendOneOfflineConversion(reportData) {
      let confirmText = "";
      confirmText += `CLICK ID: ${reportData.gclid}\n`;
      confirmText += `オフラインCV名: ${reportData.promotionData.cvName}\n`;
      confirmText += `CV日時: ${reportData.conversionTime}\n`;
      confirmText += `実質報酬額: ${reportData.realConversionValue.toLocaleString()}\n`;
      confirmText += `アカウント名: ${reportData.accountData.accountName}\n`;
      confirmText += `\n`;
      confirmText += `の成果を送信します。よろしいですか？`;
      if (confirm(confirmText)) {
        this.apiSending = true;
        this.resendOfflineConversions([reportData.reportId]);
      } else {
        // no action.
      }
    },
    resendOfflineConversions(reportIdList) {
      //
      let params = new URLSearchParams();
      params.append("report_id_list", JSON.stringify(reportIdList));
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/report/resend-offline-conversions`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            alert("API送信が完了しました。API送信履歴を確認してください。");
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
          }
          this.apiSending = false;
          return;
        })
        .catch((error) => {
          console.log(error);
          this.apiSending = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
