<template>
  <div class="ctrl-gives-points">
    <el-row>
      <el-col>
        <h2>ポイント付与</h2>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" :gutter="30">
      <el-col :span="6">
        <el-input type="number" placeholder="123" v-model="userId" min="1">
          <template slot="prepend">ユーザーID</template>
        </el-input>
      </el-col>
      <el-col :span="12">
        <el-input type="number" placeholder="100000" v-model="pointsToGive" min="1">
          <template slot="prepend">付与するポイント数</template>
        </el-input>
      </el-col>
      <el-col :span="3">
        <el-button @click="givesPoints" type="primary">実行</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      userId: 1,
      pointsToGive: 1
    }
  },
  components: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    }
  },
  methods: {
    givesPoints() {
      if(this.pointsToGive > 0) {
        console.log("points OK.");
      }else {
        alert("付与するポイント数は1以上にしてください。");
        return;
      }
      if(confirm(`user_id: ${this.userId} のユーザーに${this.pointsToGive}ポイント付与します。よろしいですか？`)) {

        let params = new URLSearchParams();
        params.append("user_id", this.userId);
        params.append("points_to_give", this.pointsToGive);
        this.$axios
          .post(`${this.rt}/api/ctrl/givesPointsToSelectedUser.php`, params)
          .then(res => {
            console.log(res);
            alert("付与しました。");
            return;
          })
          .catch(err => {
            console.log(err);
          });

      }else {
        return;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
