import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
// import store from '@/store'
import router from '@/router'
Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    pageList: [],
    formatList: [],
    abTestList: []
  },
  getters: {
    allPageList(state) {
      return state.pageList;
    },
    pageList: (state) => (toolId) => {
      let pageList = state.pageList.filter(function (pageData) {
        return pageData.toolId === toolId;
      });
      return pageList;
    },
    similarToolPageList: (state) => (toolId) => {
      let pageList = state.pageList.filter(function (pageData) {
        if (
          toolId === '1' ||
          toolId === '6'
        ) {
          return pageData.toolId === '1' || pageData.toolId === '6';
        } else if (
          toolId === '2' ||
          toolId === '3'
        ) {
          return pageData.toolId === '2' || pageData.toolId === '3';
        } else {
          // 
        }
      });
      return pageList;
    },
    pageData: (state) => (id) => {
      let pageData = state.pageList.find(pageData => pageData.id === id);
      return pageData;
    },
    pageDataByToolIdAndName: (state) => (toolId, name) => {
      let pageData = state.pageList.find(pageData => pageData.toolId === toolId && pageData.name === name);
      return pageData;
    },
    pageDataBySomeIds: (state) => (toolId, pageId) => {
      let pageData = state.pageList.find(pageData => pageData.toolId === toolId && pageData.siteId === pageId);
      return pageData;
    },
    formatListByPageId: (state, getters) => (pageId) => {
      let pageData = getters.pageData(pageId);
      let formatList = state.formatList.filter(function (formatData) {
        return formatData.toolId === pageData.toolId && formatData.siteId === pageData.siteId;
      });
      return formatList;
    },
    abTestListBySomeIds: (state) => (toolId, pageId) => {
      let abTestList = state.abTestList.filter(function (abTestData) {
        return abTestData.toolId === toolId && abTestData.pageId === pageId;
      });
      return abTestList;
    },
    abTestDataBySomeIds: (state) => (toolId, pageId, testId) => {
      let abTestData = state.abTestList.find(abTestData => abTestData.toolId === toolId && abTestData.pageId === pageId && abTestData.testId === testId);
      return abTestData;
    },
  },
  mutations: {
    setPageList(state, payload) {
      state.pageList = payload.list;
    },
    setFormatList(state, payload) {
      state.formatList = payload.list;
    },
    setAbTestList(state, payload) {
      state.abTestList = payload.list;
    },
  },
  actions: {
    setPageList({
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootGetters.rt}/l/api/tools01/page/get-list`)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              let payload = {
                list: response.data.list
              }
              commit('setPageList', payload);
            } else if (response.data.status === 401) {
              alert(response.data.message);
              router.push('/login');
            } else {
              alert(rootGetters.apiErrorMessage);
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
    setFormatList({
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootGetters.rt}/l/api/tools01/format/get-list`)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              let payload = {
                list: response.data.list
              }
              commit('setFormatList', payload);
            } else if (response.data.status === 401) {
              alert(response.data.message);
              router.push('/login');
            } else {
              alert(rootGetters.apiErrorMessage);
            }
            resolve(response);
          })
          .catch(error => {
            console.log(error);
            reject(error);
          });
      })
    },
    setAbTestList({
      commit,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${rootGetters.rt}/l/api/tools01/ab-test/get-list`)
          .then(response => {
            console.log(response);
            if (response.data.status === 200) {
              let payload = {
                list: response.data.list
              }
              commit('setAbTestList', payload);
            } else if (response.data.status === 401) {
              alert(response.data.message);
              router.push('/login');
            } else {
              alert(rootGetters.apiErrorMessage);
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      })
    },
  },
  modules: {}
}