<template>
  <div
    class="tools02-report-import-manual-send-conversion-event-system-yda-inputs"
  >
    <el-row type="flex" align="middle">
      <el-col :span="3">CV時間（※）</el-col>
      <el-col :span="10"
        ><el-date-picker
          v-model="conversionData.eventTime"
          type="datetime"
          value-format="timestamp"
          placeholder=""
        >
        </el-date-picker
      ></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">報酬額（※）</el-col>
      <el-col :span="4"
        ><el-input v-model="conversionData.yahooYdnConvValue" type="number"
      /></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">yclid（※）</el-col>
      <el-col :span="10"><el-input v-model="conversionData.yclid" /></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="3">yjr</el-col>
      <el-col :span="10"><el-input v-model="conversionData.yjrYjad" /></el-col>
    </el-row>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="sendConversionEvent" type="primary">送信</el-button>
      </el-col>
    </el-row>
    <el-row v-if="resultData !== undefined">
      <el-col>
        <h3>送信結果</h3>
        <div v-if="resultData.eventReceived === true">送信成功！</div>
        <div v-else>{{ resultData.errorMessage }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tools02-report-import-manual-send-conversion-event-system-yda-inputs",
  components: {},
  props: {
    resultData: Object,
  },
  data() {
    return {
      tools02TagId: "",
      conversionData: {
        yclid: "",
        yjrYjad: "",
        eventTime: "",
        yahooYdnConvValue: 0,
        adtasukaruIdCreatedAt: null,
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
    ...mapGetters("tools02", []),
    tools02TagList() {
      return this.$store.getters["tools02/allTagListInTool"](this.tool.id);
    },
  },
  methods: {
    sendConversionEvent() {
      //
      const conversionData = JSON.parse(JSON.stringify(this.conversionData));

      //
      conversionData.eventTime = Math.floor(conversionData.eventTime / 1000);

      //
      const payload = {
        tools02TagId: this.tools02TagId,
        conversionData: conversionData,
      };
      this.$emit("sendConversionEvent", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
