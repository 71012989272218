<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（サルクルー）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/point-back`">ASP自動連携設定</router-link>」にアクセスします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`" alt="" />
        <h4>【２】サルクルーの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no07/01.png`" alt="" />
        <h4>【３】以下の文言をサルクルーの担当者に連絡します。</h4>
        <p>-----------------------<br>
          アドタスカルのポイントバック連携を希望します。<br>
          <br>
          （【２】でコピーしたサルクルーの通知URL）<br>
          -----------------------</p>
        <h4>【４】連携が完了したら、既存のアフィリリンクからテスト購入をしてください。※テスト規定は各案件によって異なるので担当さんに確認ください。</h4>
        <div class="min-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no08/no07/02.png`" alt="" />
        </div>
        <h4 style="
  word-break: break-all;">【５】発行されたURLに「<span class="red">&amp;p1=test&amp;p2={{tool.clickIdName}}_xxx&amp;mktag={{tool.clickIdName}}_xxx</span>」を追記してください。</h4>
        <h5>▼発行されたアフィリリンク</h5>
        <p>https://rcv.monkey-ads.com/api/click?id=○○○○○○○○○<span class="green">&amp;mktag=●●●</span></p>
        <h5>▼「<span class="green">&amp;mktag=●●●</span>」が付いていたら削除する</h5>
        <p>https://rcv.monkey-ads.com/api/click?id=○○○○○○○○○</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p>https://rcv.monkey-ads.com/api/click?id=○○○○○○○○○<span class="red">&amp;p1=test&amp;p2={{tool.clickIdName}}_xxx&amp;mktag={{tool.clickIdName}}_xxx</span></p>
        <p class="red">※自分で付けたパラメータを覚えておきましょう</p>
        <h4>【６】パラメータを付けたテストURLにアクセスして、テスト購入します。</h4>
        <h4>【７】Thanksページが表示されたらテスト完了です。</h4>
        <h4>【８】アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/auto-import-log`">自動登録履歴</router-link>」を確認します。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`" alt="" />
        <h4>【９】数分後に、テストCVが登録されていれば問題なく連携完了です。</h4>
        <AutoImportLog 
          :aspId="'5'"
          :aspPromotionName="'test'"
        />
        <ul>
          <li>ASP：サルクルー</li>
          <li>CV時間：テストした時間になっているか？（多少の誤差はあり）</li>
          <li>プログラム名：-</li>
          <li>パラメータ：{{tool.clickIdName}}_xxx</li>
          <li>プログラムID： test</li>
          <li>報酬額：テストした案件の報酬額</li>
          <li>インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です</li>
        </ul>
        <h4>【１０】注意点</h4>
        <p>サルクルーは自動連携の際、報酬額が登録されません。そのため、オフラインコンバージョン作成時に、報酬額を入力してください。</p>
        <p>①「すべてのコンバージョンに同一の価値を割り当てる」にチェック</p>
        <p>②報酬額を入力</p>
        <img :src="`${rt}/img/manual/google-cv/no08/no07/03.png`" alt="" />
        <PointbackSettingDescription :aspId="'5'" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PointbackSettingDescription,
    AutoImportLog
  },
  props: {
  },
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>

</style>
