<template>
  <div class="concise-analysis">
    <table class="typeA stripe">
      <thead>
        <tr>
          <th>タイトル</th>
          <th style="width: 50px;"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(data, index) in tableData" :key="index">
          <td>
            {{ data.title }}
          </td>
          <td>
            <a :href="rt + data.path" target="_blank" rel="noopener noreferrer">
              <el-button type="primary">確認</el-button>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "concise-analysis",
  components: {
  },
  props: {},
  data() {
    return {
      tableData: [
        {
          title: "ユーザー登録状況（全員分）",
          path: "/api/test/confirm/confirm-special-guests-all.php?password=PLz54e8GsAxJyByS",
        },
        {
          title: "ユーザー登録状況（モニター）",
          path: "/api/test/confirm/confirm-special-guests.php?password=PLz54e8GsAxJyByS",
        },
        {
          title: "ユーザー登録状況（先行リリース）",
          path: "/api/test/confirm/confirm-special-guests2.php?password=PLz54e8GsAxJyByS",
        },
        {
          title: "ユーザー登録状況（月間アフィリ）",
          path: "/api/test/confirm/confirm-special-guests3.php?password=PLz54e8GsAxJyByS",
        },
        {
          title: "パラメータ引継ぎ使用量ランキング（過去30日間）",
          path: "/api/test/confirm/confirm-users-metrics.php",
        },
      ],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
