<template>
  <div class="tools01-home">
    <el-row>
      <el-col>
        <div class="width-max-cover">
          <div v-if="displayType === 'general'" class="daterange-search-box">
            <div class="buttons-cover">
              <el-button
                @click="dateRange = g_getDateRange('thisMonth')"
                type="primary"
                >今月</el-button
              >
              <el-button
                @click="dateRange = g_getDateRange('lastMonth')"
                type="primary"
                >先月</el-button
              >
              <el-button
                @click="dateRange = g_getDateRange('today')"
                type="primary"
                >今日</el-button
              >
              <el-button
                @click="dateRange = g_getDateRange('yesterday')"
                type="primary"
                >昨日</el-button
              >
              <el-button @click="dateRange = g_getDateRange(7)" type="primary"
                >過去7日間</el-button
              >
              <el-button @click="dateRange = g_getDateRange(30)" type="primary"
                >全期間（過去30日間）</el-button
              >
            </div>
            <div class="datepicker-cover">
              <el-date-picker
                v-model="dateRange"
                type="daterange"
                align="right"
                start-placeholder="開始日"
                end-placeholder="終了日"
                value-format="yyyy-MM-dd"
                clearable
              >
              </el-date-picker>
              <el-button @click="getPageList()" type="primary">検索</el-button>
            </div>
          </div>
          <div
            v-if="loading === true"
            v-loading="loading === true"
            style="height: 300px"
          ></div>
          <div v-else>
            <el-descriptions
              v-if="displayType === 'general'"
              title="合計"
              direction="vertical"
              :column="8"
              size="mini"
              border
            >
              <el-descriptions-item label="利用ポイント">{{
                totalData.usedPoint | localeNum
              }}</el-descriptions-item>
              <el-descriptions-item label="表示回数">{{
                totalData.displayedCount | localeNum
              }}</el-descriptions-item>
              <el-descriptions-item label="クリック数">{{
                totalData.clickedCount | localeNum
              }}</el-descriptions-item>
              <el-descriptions-item label="クリック率"
                >{{ totalData.ctr }}%</el-descriptions-item
              >
              <el-descriptions-item label="CV数">{{
                totalData.conversionCount | localeNum
              }}</el-descriptions-item>
              <el-descriptions-item label="CVR"
                >{{ totalData.tcvr }}%</el-descriptions-item
              >
              <el-descriptions-item label="報酬額">{{
                totalData.rewardTotal | localeNum
              }}</el-descriptions-item>
              <el-descriptions-item label="EPP">{{
                totalData.epp
              }}</el-descriptions-item>
            </el-descriptions>
            <el-table
              :data="
                pageList.filter(
                  (pageData) =>
                    !pageName ||
                    pageData.name.toLowerCase().includes(pageName.toLowerCase())
                )
              "
              style="width: 100%"
            >
              <el-table-column label="ON・OFF" width="100px">
                <template slot-scope="scope">
                  <ActiveSwitch :pageId="scope.row.id" />
                </template>
              </el-table-column>
              <el-table-column :label="`${toolData.name}名`">
                <template slot-scope="scope">
                  <a
                    :href="`/tools01/${toolData.code2}/edit/${scope.row.siteId}`"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ scope.row.name }}</a
                  >
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="usedPoint"
                :label="`利用ポイント`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.usedPoint | localeNum }}P</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="displayedCount"
                :label="`表示回数`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.displayedCount | localeNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="clickedCount"
                :label="`クリック数`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.clickedCount | localeNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="ctr"
                :label="`クリック率`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.ctr }}%</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="conversionCount"
                :label="`CV数`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.conversionCount | localeNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="tcvr"
                :label="`CVR`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.tcvr }}%</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="rewardTotal"
                :label="`報酬額`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.rewardTotal | localeNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                sortable
                prop="epp"
                :label="`EPP`"
                v-if="displayType === 'general'"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.epp }}</span>
                </template>
              </el-table-column>
              <el-table-column label="配信フォーマット" width="180px">
                <template slot-scope="scope">
                  <span v-if="scope.row.abTestList.length > 0">
                    ABテスト「{{ scope.row.abTestList[0].name }}」配信中
                  </span>
                  <span v-else>通常フォーマット配信中</span>
                </template>
              </el-table-column>
              <el-table-column align="right" width="420px">
                <template slot="header" slot-scope="scope">
                  <el-input
                    v-model="pageName"
                    size="mini"
                    placeholder="ポップアップ名検索"
                    :key="scope.$index"
                    clearable
                  />
                </template>
                <template slot-scope="scope">
                  <div class="buttons-cover">
                    <DisplayTagButton :pageId="scope.row.id" />
                    <a
                      :href="`/tools01/${toolData.code2}/report/${scope.row.id}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <el-button type="success">レポート</el-button>
                    </a>
                    <a
                      :href="`/tools01/${toolData.code2}/abtest/${scope.row.siteId}`"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <el-button type="warning">ABテスト</el-button>
                    </a>
                    <el-button @click="deletePage(scope.row)" type="danger"
                      >削除</el-button
                    >
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ActiveSwitch from "@/components/Tools01/Home/PageList/ActiveSwitch.vue";
import DisplayTagButton from "@/components/Tools01/Home/PageList/DisplayTagButton.vue";

export default {
  components: {
    ActiveSwitch,
    DisplayTagButton,
  },
  props: {
    toolId: String,
    displayType: String,
  },
  data() {
    return {
      loading: false,
      dateRange: [],
      pageName: "",

      //
      pageList: [],
      totalData: {
        usedPoint: 0,
        displayedCount: 0,
        clickedCount: 0,
        ctr: "0.0",
        conversionCount: 0,
        tcvr: "0.0",
        rewardTotal: 0,
        epp: "0.0",
      },
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
    useTotalData() {
      const useTotalData = {
        amount: 0,
        point: 0,
      };

      //
      for (const pageData of this.pageList) {
        useTotalData.amount += pageData.usedAmount;
        useTotalData.point += pageData.usedPoint;
      }

      //
      return useTotalData;
    },
  },
  watch: {
    toolId: {
      handler() {
        this.getPageList();
      },
      immediate: true,
    },
  },
  methods: {
    getPageList() {
      if (this.displayType === "general") {
        //
        this.loading = true;

        //
        this.$axios
          .get(
            `${
              this.rt
            }/l/api/tools01/page/get-list-with-general-report?tool_id=${
              this.toolId
            }&date_range=${JSON.stringify(this.dateRange)}`
          )
          .then((response) => {
            console.log(response);
            if (response.data.status === 200) {
              this.pageList = response.data.list;
              this.totalData = response.data.totalData;
              this.dateRange = response.data.searchedDateRange;
            } else if (response.data.status === 401) {
              alert(response.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
            }
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else {
        this.pageList = JSON.parse(
          JSON.stringify(this.$store.getters["tools01/pageList"](this.toolId))
        );
      }
    },
    deletePage(pageData) {
      if (confirm(`「${pageData.name}」を削除します。よろしいですか？`)) {
        this.$axios
          .get(
            `${this.rt}/api/tools01/delSite.php?tool=${this.toolData.code2}&site_id=${pageData.siteId}`
          )
          .then((response) => {
            console.log(response);
            this.$store
              .dispatch("tools01/setPageList")
              .then((response) => {
                console.log(response);
                location.reload();
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.setting-description {
  font-size: 14px;
}
.header-top {
  margin-top: 30px;
  @media screen and (max-width: 767px) {
    margin-top: 10px;
  }
}
.bar-cover {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
  font-size: 13px;
  text-align: center;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    text-align: left;
  }
  .ad-switch {
    width: 100px;
    white-space: nowrap;
  }
  .site-name {
    width: calc(90% - 600px);
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .format {
    width: 145px;
    text-align: left;
    white-space: nowrap;
  }
  .button-box {
    display: flex;
    justify-content: space-between;
    width: 320px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.width-max-cover {
  .daterange-search-box {
    .datepicker-cover {
      margin-top: 10px;
      .el-range-editor.el-input__inner {
        margin-right: 10px;
      }
    }
  }
  .name-search-box {
    margin-top: 20px;
    .el-input {
      width: 700px;
    }
  }
  :deep(.el-descriptions) {
    margin-top: 20px;
    .el-descriptions__header {
      margin-bottom: 5px;
    }
  }
  :deep(.el-table) {
    margin-top: 15px;
    .buttons-cover {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
