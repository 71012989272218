<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>データソースへのアクセス方法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>アドタスカルの設定でよく利用する「データソース」へのアクセス方法を理解する。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>1分</p>
        <h4>【１】ビジネスマネージャ上のハンバガーメニュから、「ビジネス設定」をクリックします。</h4>
        <div class="mini-img">
            <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no02/01.png`" alt="" />
        </div>
        <h4>【２】「データソース」の中にある「ピクセル」をクリックします。</h4>
        <div class="mini-img">
            <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no02/02.png`" alt="" />
        </div>
        <h4>【３】ピクセルを選択し、「イベントマネージャで開く」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no02/03.png`" alt="" />
        <h4>【４】データソースの画面が表示されます。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no01/no02/04.png`" alt="" />
        <p style="color: red; text-decoration: underline;">※よく使うページなので、ブックマークに登録しておくと便利です。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.error {
  width: 100%;
  border-collapse: collapse;
  margin-left: 20px;
  margin-bottom: 20px;
  @media screen and (max-width: 768px){
    margin-left: 0!important;
  }
  & + .error {
    margin-top: 20px;
  }
  th,
  td {
    border: 1px solid;
    padding: 15px;
  }
  th {
    background-color: #e5e5e5;
  }
}
</style>
