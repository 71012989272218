<template>
  <div class="affiliate-bank-account">
    <template id="affiliate-point-reward">
      <template v-if="$route.query.firstTime === 'true'"></template>
      <!-- no -->
      <template v-else>
        <h2>報酬の受取方法の設定</h2>
        <AffiliatePaymentTypeConfig />
        <PointPaymentDescription
          :displayConfigurableDescription="false"
          style="margin-top: 30px"
        />
        <PointPaymentSimulation />
      </template>
    </template>
    <h2>報酬の振込先となる銀行口座情報の登録</h2>
    <el-row>
      <el-col>
        <BankAccountInformation />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AffiliatePaymentTypeConfig from "@/components/Affiliate/BankAccount/AffiliatePaymentTypeConfig.vue";
import PointPaymentDescription from "@/components/Affiliate/Parts/PointPaymentDescription.vue";
import PointPaymentSimulation from "@/components/Affiliate/Parts/PointPaymentSimulation.vue";
import BankAccountInformation from "@/components/Affiliate/BankAccount/BankAccountInformation.vue";

export default {
  components: {
    AffiliatePaymentTypeConfig,
    PointPaymentDescription,
    PointPaymentSimulation,
    BankAccountInformation,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["userData"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
