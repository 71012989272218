<template>
  <div class="account">
    <el-row>
      <el-col>
        <h2>{{ toolData.name2 }} 全体レポート</h2>
      </el-col>
    </el-row>
    <PageList :toolId="toolId" :displayType="'general'"/>
  </div>
</template>

<script>
import PageList from "@/components/Tools01/Home/PageList.vue";

export default {
  components: {
    PageList,
  },
  props: {
    toolId: String,
    pageId: String,
  },
  data() {
    return {};
  },
  computed: {
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
