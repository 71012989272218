<template>
  <div class="tools02-api-history-detail">
    <el-row>
      <el-col>
        <h2 v-if="tool.codeKebab === 'google-cv'">
          Google API送信履歴 詳細ログ
        </h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <GoogleLogTable
          v-if="tool.codeKebab === 'google-cv'"
          :googleApiHistoryId="historyId"
          :pageType="'default'"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GoogleLogTable from "@/components/Tools02/ApiHistoryDetail/GoogleLogTable.vue";

export default {
  components: {
    GoogleLogTable,
  },
  props: {
    historyId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>