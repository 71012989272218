<template>
  <div class="ctrl-login">
    <Login :loginType="'ctrl'"/>
  </div>
</template>

<script>
import Login from '@/components/Ui/Login.vue'

export default {
  data() {
    return {
    }
  },
  components: {
    Login
  },
  created() {
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
