<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>成果データのダウンロード方法（レントラックス）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>
          【１】<a href="https://manage.rentracks.jp/manage/login" target="_blank" rel="noopener noreferrer"
            >レントラックスの管理画面</a
          >にログインし、「注文リスト」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no04/01.png`" alt="" />
        <h4>【２】期間を選択して、「再表示」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no04/02.png`" alt="" />
        <h4>
          【３】成果が表示されたら、「備考」にクリックパラメータ（{{tool.clickIdName}}_xxx）が表示されているか確認します。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no07/no04/03.png`" alt="" />
        <p class="red">
          ※パラメータが表示されていない場合、パラメータ引継ぎ設定を間違えている可能性があります。
        </p>
        <p>
          「<router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link
          >」をご確認ください。
        </p>
        <h4>
          【４】CSVをダウンロードします。「ダウンロード」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no07/no04/04.png`" alt="" />
        <p>
          これで成果CSVのダウンロードは完了です。「<router-link
            :to="`/manual/${tool.codeKebab}/07/14`"
            >ASPの成果データをアドタスカルにインポートする</router-link
          >」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
