<template>
  <div class="tools02-parameter-manual-manual-system-selector">
    <div class="manual-btn">
      <div style="width: 140px">
        <a href="#manual">
          <Button2 type2="1" msg="設定方法" />
        </a>
      </div>
    </div>
    <div v-show="tool.codeKebab === 'facebook-cv'" class="manual-categories">
      <p>
        {{
          tool.mediaName
        }}成果連携ツールでは、ユニットごとにタグが変わります。設置したいユニットを選択して、「表示」をクリックしてください。
      </p>
      <div class="category03">
        <div class="title-cover">
          <p class="title">ユニット選択</p>
          <p class="colon">：</p>
        </div>
        <div class="select-box">
          <el-select v-model="unitIdForSelector" placeholder="選択してください">
            <el-option
              v-for="(unitData, unitIndex) in unitList"
              :key="unitIndex"
              :label="unitData.name+`（${unitData.token}）`"
              :value="unitData.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="unit-select">
          <el-button @click="getTags()" type="primary">表示</el-button>
        </div>
      </div>
    </div>
    <h4 v-if="tool.codeKebab === 'facebook-cv'">
      ▼{{
        tool.mediaName
      }}LPタグ：&lt;/body>の直前に設置（ユニットごとに変わります。）
    </h4>
    <h4 v-else>▼LPタグ：&lt;/body>の直前に設置（共通）</h4>
    <div class="left-space">
      <el-input
        type="textarea"
        :autosize="{ minRows: 1, maxRows: 10 }"
        v-model="normalTag"
        onclick="this.select();"
      />
    </div>
    <div v-for="(aspType, index) in ['normal']" :key="index">
      <h4>
        ▼<span v-show="aspType === 'medipartner'">メディパートナー専用</span
        >中間ページタグ（<span v-if="tool.codeKebab === 'facebook-cv'"
          >ユニットごとに変わります。</span
        ><span v-else>共通</span>）
      </h4>
      <div class="left-space">
        <el-input
          v-if="aspType === 'normal'"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 10 }"
          v-model="middlePageTag"
          onclick="this.select();"
        />
        <el-input
          v-else-if="aspType === 'medipartner'"
          type="textarea"
          :autosize="{ minRows: 1, maxRows: 10 }"
          v-model="medipartnerMiddlePageTag"
          onclick="this.select();"
        />
      </div>
      <h4>
        ▼<span v-show="aspType === 'medipartner'">メディパートナー専用</span
        >中間ページファイル（<span v-if="tool.codeKebab === 'facebook-cv'"
          >ユニットごとに変わります。</span
        ><span v-else>共通</span>）
      </h4>
      <p
        v-if="
          tool.codeKebab === 'facebook-cv' &&
          normalTag === '※ユニットを選択してください※'
        "
      >
        ※ユニットを選択してください※
      </p>
      <ul v-else>
        <li>
          <div
            @click="downloadSampleFile('1', aspType, 1, 'php')"
            class="sample"
          >
            PHP版（テキストリンクあり）
          </div>
        </li>
        <li>
          <div
            @click="downloadSampleFile('1', aspType, 0, 'php')"
            class="sample"
          >
            PHP版（テキストリンクなし）
          </div>
        </li>
        <li>
          <div
            @click="downloadSampleFile('1', aspType, 1, 'html')"
            class="sample"
          >
            HTML版（テキストリンクあり）
          </div>
        </li>
        <li>
          <div
            @click="downloadSampleFile('1', aspType, 0, 'html')"
            class="sample"
          >
            HTML版（テキストリンクなし）
          </div>
        </li>
      </ul>
    </div>
    <el-row>
      <el-col>
        <h4>
          ▼直LP用リダイレクトタグ（
          <span v-if="tool.codeKebab === 'facebook-cv'"
            >ユニットごとに変わります。</span
          >
          <span v-else>共通</span>
          ）
        </h4>
        <div class="left-space">
          <el-input
            type="textarea"
            :autosize="{ minRows: 1, maxRows: 10 }"
            v-model="directLpTag"
            onclick="this.select();"
          />
        </div>
        <h4>
          ▼直LP用リダイレクトファイル（
          <span v-if="tool.codeKebab === 'facebook-cv'"
            >ユニットごとに変わります。</span
          >
          <span v-else>共通</span>）
        </h4>
        <p v-if="normalTag === '※ユニットを選択してください※'">
          ※ユニットを選択してください※
        </p>
        <ul v-else>
          <li>
            <div
              @click="downloadSampleFile('2', 'normal', 1, 'php')"
              class="sample"
            >
              PHP版（テキストリンクあり）
            </div>
          </li>
          <li>
            <div
              @click="downloadSampleFile('2', 'normal', 0, 'php')"
              class="sample"
            >
              PHP版（テキストリンクなし）
            </div>
          </li>
          <li>
            <div
              @click="downloadSampleFile('2', 'normal', 1, 'html')"
              class="sample"
            >
              HTML版（テキストリンクあり）
            </div>
          </li>
          <li>
            <div
              @click="downloadSampleFile('2', 'normal', 0, 'html')"
              class="sample"
            >
              HTML版（テキストリンクなし）
            </div>
          </li>
        </ul>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h2 id="manual">設定方法</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>
          パラメータ引き継ぎ設定をするサイトの「サイト作成ツール」と「アフィリリンク」のタイプを選択し、「検索」すると、その設定方法が表示されます。
        </p>
        <p>
          MCV（アフィリリンクのクリック）を計測したい場合は、「中間ページ」を選択してください。
        </p>
      </el-col>
    </el-row>
    <el-row class="manual-categories">
      <el-col>
        <div class="category01">
          <div class="title-cover">
            <p class="title">サイト作成ツール</p>
            <p class="colon">：</p>
          </div>
          <div class="select-box">
            <el-radio-group v-model="category01ForSelector">
              <el-radio-button
                v-for="(tool, index) in manualUrlData.default"
                :key="index"
                :label="index"
                :value="index"
                >{{ tool.label }}</el-radio-button
              >
            </el-radio-group>
          </div>
        </div>
        <template>
          <div
            v-show="category01ForSelector === 'wordpress'"
            class="category04"
          >
            <div class="title-cover">
              <p class="title">テーマ</p>
              <p class="colon">：</p>
            </div>
            <el-select
              v-model="themeIdForSelector"
              placeholder="選択してください"
            >
              <el-option
                v-for="(theme, index) in manualUrlData.default.wordpress
                  .themeList"
                :key="index"
                :label="theme.label"
                :value="index"
              />
            </el-select>
          </div>
        </template>
        <div class="category02">
          <div class="title-cover">
            <p class="title">アフィリリンク</p>
            <p class="colon">：</p>
          </div>
          <div class="select-box">
            <el-radio-group v-model="category02ForSelector">
              <el-radio-button :label="1">サイトに直接設置する</el-radio-button>
              <el-radio-button :label="2">中間ページ</el-radio-button>
              <el-radio-button :label="3">直LP</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <el-row>
          <el-col style="text-align: center">
            <el-button @click="jumpToParameterManual" type="primary"
              >検索</el-button
            >
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "tools02ParameterManualManualSystemSelector",
  components: {
    Button2,
  },
  props: {
    category01: Number,
    category02: Number,
    unitId: Number,
  },
  data() {
    return {
      unitIdForSelector: "",
      category01ForSelector: 0,
      category02ForSelector: 0,
      themeIdForSelector: "",

      //
      normalTag: "",
      middlePageTag: "",
      lineSafetyScript: "",
      directLpTag: "",

      //
      manualUrlData: {
        default: {
          wordpress: {
            label: "WordPress",
            themeList: [
              {
                label: "ウラオモテ",
                normalUrl:
                  "https://adtasukaru.wraptas.site/897ad43d14b947cd8a75bf84df723748",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/1b99c8f104d6474ab18acb1e561b1925",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/f06ee44508e64c869b45e777669f4e6e",
              },
              {
                label: "Cocoon",
                normalUrl:
                  "https://adtasukaru.wraptas.site/1b0d27adfb1f45fd946dd93deb49f098",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/d308f6efea05418ba83b883897cdf397",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/5c5064999f8f4979a909f0b2510923b9",
              },
              {
                label: "SWELL",
                normalUrl:
                  "https://adtasukaru.wraptas.site/82953c41bebf4c959ecf90512e1795a6",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/037a24b4912f4083805873ba11640bc3",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/68e57f8ddab040449bd32cf924de2627",
              },
              {
                label: "その他",
                normalUrl:
                  "https://adtasukaru.wraptas.site/1af9fec626544052ab4cd33ba1bbfa92",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/add25afdd9a4487bac9951c9679d6282",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/62835d5957df404896e2e8ce9f096f6f",
              },
            ],
          },
          htmlphp: {
            label: "HTML,PHP",
            normalUrl:
              "https://adtasukaru.wraptas.site/873c83e7c8104a6d96d2cdd8fe770123",
            middlePageUrl:
              "https://adtasukaru.wraptas.site/ba7c1079fd85462dbdf40b267579c09c",
            directLpManualUrl:
              "https://adtasukaru.wraptas.site/9eb3b129e50443f58bb11c0c589bff78",
          },
          squadBeyond: {
            label: "Squad beyond",
            normalUrl:
              "https://adtasukaru.wraptas.site/c80c35cb62ad490caccc8ad4f383189a",
            middlePageUrl:
              "https://adtasukaru.wraptas.site/f0f6808de52a4875bf8a7fb0a39fc0b5",
            directLpManualUrl:
              "https://adtasukaru.wraptas.site/11f74d4276344f369829239a0c65fb7e",
          },
        },
        facebook: {
          wordpress: {
            label: "WordPress",
            themeList: [
              {
                label: "ウラオモテ",
                normalUrl:
                  "https://adtasukaru.wraptas.site/87557c79a02e456daaef746fb3f246a0",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/ad118c5cef8f465a87b555854c815b48",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/4c066bc981eb450db38e348fd365c700",
              },
              {
                label: "Cocoon",
                normalUrl:
                  "https://adtasukaru.wraptas.site/a6cdb971b22c46f5941ab5b7fff2d500",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/97b146e56e7a4b46ae49bcd393cac06e",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/e25ed48f6ded4713ba6082b151f0cf02",
              },
              {
                label: "SWELL",
                normalUrl:
                  "https://adtasukaru.wraptas.site/c0cf10cb85f04aa8ae2466910550597c",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/985e18e6ca954bb19ef078748ff86b0a",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/4e3e509ddad442bbab4021fab5c5b925",
              },
              {
                label: "その他",
                normalUrl:
                  "https://adtasukaru.wraptas.site/0fdd32a8ecc542bba2641640b06975a8",
                middlePageUrl:
                  "https://adtasukaru.wraptas.site/6ef35f494ba7461f9e9b9f71c28fe8b3",
                directLpManualUrl:
                  "https://adtasukaru.wraptas.site/c0f7f6388ab94535827463b3c8b7f754",
              },
            ],
          },
          htmlphp: {
            label: "HTML,PHP",
            normalUrl:
              "https://adtasukaru.wraptas.site/e1d60313a2d449ecabaf998cfa08c7da",
            middlePageUrl:
              "https://adtasukaru.wraptas.site/e354f78f5e19478a9d6ce373584c457c",
            directLpManualUrl:
              "https://adtasukaru.wraptas.site/e0cdec0be7a94d409024f7ae148b2309",
          },
          squadBeyond: {
            label: "Squad beyond",
            normalUrl:
              "https://adtasukaru.wraptas.site/7ec21b94d81d4a11a50aea1bac4496b0",
            middlePageUrl:
              "https://adtasukaru.wraptas.site/fa804ad5de1644718fabc0bc8bc1a3f6",
            directLpManualUrl:
              "https://adtasukaru.wraptas.site/7216f9d6e99b4d0eb988fc684a51de68",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "tools", "apiErrorMessage"]),
    unitList() {
      let unitList = [];
      if (this.tool.codeKebab !== "facebook-cv") {
        unitList = this.$store.getters["tools02/unitListInTool"](this.tool.id);
      } else {
        let units = this.$store.getters["tools02/unitsInTool"](
          String(this.tool.id)
        );
        units.forEach((unit) => {
          let unitData = {
            id: unit.unitId,
            name: unit.unitName,
            token: unit.unitToken,
          };
          unitList.push(unitData);
        });
      }
      return unitList;
    },
  },
  watch: {
    unitId: {
      handler(newValue) {
        this.unitIdForSelector = newValue === 0 ? "" : String(newValue);
      },
      immediate: true,
    },
    unitIdForSelector() {
      this.normalTag = "※ユニットを選択してください※";
      this.middlePageTag = "※ユニットを選択してください※";
      this.directLpTag = "※ユニットを選択してください※";
    },
    category01ForSelector: {
      handler(newValue) {
        console.log(newValue);
      },
    },
    themeIdForSelector: {
      handler(newValue) {
        this.themeIdForSelector = newValue;
        console.log(newValue);
      },
    },
    "tool.id": {
      handler() {
        this.normalTag = "";
        this.middlePageTag = "";
        this.lineSafetyScript = "";
        if (this.tool.codeKebab === "facebook-cv") {
          this.unitIdForSelector = "";
          this.normalTag = "※ユニットを選択してください※";
          this.middlePageTag = "※ユニットを選択してください※";
          this.directLpTag = "※ユニットを選択してください※";
        } else {
          this.getTags();
        }
      },
    },
  },
  created() {
    if (this.tool.codeKebab === "facebook-cv") {
      this.normalTag = "※ユニットを選択してください※";
      this.middlePageTag = "※ユニットを選択してください※";
      this.directLpTag = "※ユニットを選択してください※";
    } else {
      this.getTags();
    }
  },
  methods: {
    jumpToParameterManual() {
      // category選択のバリデーション
      if (
        this.category01ForSelector !== 0 &&
        this.category02ForSelector !== 0
      ) {
        console.log("categoryのバリデーションOK！");
      } else {
        alert("マニュアルを選択してください。");
        return;
      }

      // https://www.chatwork.com/#!rid167061098-1734837872894148608
      // if (
      //   this.tool.codeKebab === "tiktok-cv" ||
      //   this.tool.codeKebab === "line-cv" ||
      //   this.tool.codeKebab === "yda-cv"
      // ) {
      //   this.jumpToTypeFacebookManual();
      //   return;
      // } else {
      //   // チェックなし
      // }

      let urlData = {};
      switch (this.category01ForSelector) {
        case "wordpress":
          if (this.themeIdForSelector === "") {
            alert("テーマを選択してください。");
            return;
          } else {
            if (this.tool.codeKebab === "facebook-cv") {
              urlData =
                this.manualUrlData.facebook[this.category01ForSelector]
                  .themeList[this.themeIdForSelector];
            } else {
              urlData =
                this.manualUrlData.default[this.category01ForSelector]
                  .themeList[this.themeIdForSelector];
            }
          }
          break;
        default:
          if (this.tool.codeKebab === "facebook-cv") {
            urlData = this.manualUrlData.facebook[this.category01ForSelector];
          } else {
            urlData = this.manualUrlData.default[this.category01ForSelector];
          }
          break;
      }

      //
      let manualUrl = null;
      if (this.category02ForSelector === 1) {
        manualUrl = urlData.normalUrl;
      } else if (this.category02ForSelector === 2) {
        manualUrl = urlData.middlePageUrl;
      } else if (this.category02ForSelector === 3) {
        manualUrl = urlData.directLpManualUrl;
      } else {
        // no action.
      }

      //
      if (manualUrl !== null) {
        window.open(`${manualUrl}`, "_blank", "noreferrer");
      } else {
        alert("マニュアルが存在しません。");
      }
      return;
    },
    jumpToTypeFacebookManual() {
      let category01 = 0;
      switch (this.category01ForSelector) {
        case "wordpress":
          category01 = 1;
          break;

        case "htmlphp":
          category01 = 2;
          break;

        case "squadBeyond":
          category01 = 3;
          break;

        default:
          break;
      }
      let middlePageUrl = `/tools02/${this.tool.codeKebab}/parameter-manual?category01=${category01}&category02=${this.category02ForSelector}&unitId=${this.unitIdForSelector}`;
      window.open(`${middlePageUrl}`, "_blank", "noreferrer");
    },
    getTags() {
      let unitIdParameter = "";
      if (this.tool.codeKebab === "facebook-cv") {
        if (this.unitIdForSelector !== "") {
          console.log("ユニット選択チェックOK");
          unitIdParameter = `&facebook_unit_id=${this.unitIdForSelector}`;
        } else {
          alert("ユニットを選択してください。");
          return;
        }
      } else {
        unitIdParameter = `&facebook_unit_id=0`;
      }

      this.$axios
        .get(
          `${this.rt}/l/api/tools02/parameter-passing/get-adtasukaru-tag-data?tool_id=${this.tool.id}${unitIdParameter}`
          // `${this.rt}/api/tools02/getTags.php?tool_id=${this.tool.id}${unitIdParameter}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.normalTag = response.data.adtasukaruTagData.articleLpTag;
            this.middlePageTag = response.data.adtasukaruTagData.middlePageTag;
            this.medipartnerMiddlePageTag =
              response.data.adtasukaruTagData.medipartnerMiddlePageTag;
            this.directLpTag = response.data.adtasukaruTagData.directLpTag;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downloadSampleFile(fileType, aspType, useTextLink, extension) {
      //
      let facebookUnitId = "0";
      if (this.unitIdForSelector !== "") {
        facebookUnitId = this.unitIdForSelector;
      } else {
        // no action.
      }

      //
      this.$axios
        .get(
          `${
            this.rt
          }/l/api/tools02/parameter-passing/create-sample-file?tool_id=${
            this.tools[this.tool.codeCamel].id
          }&facebook_unit_id=${facebookUnitId}&page_type=${fileType}&asp_type=${aspType}&use_text_link=${useTextLink}&sample_file_extension=${extension}`
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            location.href = `${this.rt}/l/api/file/download`;
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.manual-categories) {
  .category01,
  .category02 {
    .el-radio-group {
      .el-radio-button {
        span {
          width: 200px;
          @media screen and (min-width: 768px) and (max-width: 1142px) {
            width: 150px;
          }
          @media screen and (max-width: 767px) {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }
  // .category02 {
  //   .el-radio-group {
  //     .el-radio-button {
  //       span {
  //         width: 300px;
  //         @media screen and (min-width: 768px) and (max-width: 1142px) {
  //           width: 225px;
  //         }
  //         @media screen and (max-width: 767px) {
  //           width: 100%;
  //           min-width: 170px;
  //         }
  //       }
  //     }
  //   }
  // }
  .category03 {
    // width: ;
    .el-input {
      width: 600px;
      @media screen and (min-width: 768px) and (max-width: 1142px) {
        width: 450px;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        min-width: 170px;
      }
    }
  }
  .category04 {
    .el-input {
      width: 600px;
    }
  }
  .category01,
  .category02,
  .category03,
  .category04 {
    display: flex;
    align-items: center;
    // margin-left: 40px;
    justify-content: center;
    margin-bottom: 10px;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      // justify-content: flex-start;
    }
    .title-cover {
      width: 180px;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        display: block;
        text-align: center;
      }
    }
  }
  .colon {
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .unit-select {
    height: 56px;
    line-height: 56px;
    width: 120px;
    text-align: center;
  }
}
.sample {
  color: var(--link-blue);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
.manual-btn {
  display: flex;
  justify-content: flex-end;
}
</style>
