var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products-table"},[_c('h3',{staticClass:"back-gray",staticStyle:{"margin-top":"50px","margin-bottom":"55px"}},[_vm._v(" ポイント購入・プラン変更 ")]),_c('el-tabs',{attrs:{"type":"border-card"}},[_c('el-tab-pane',{attrs:{"label":"ポイントプラン"}},[_c('div',{staticClass:"point-cover"},[_c('table',{staticClass:"typeA stripe"},[_c('thead',[_c('tr',[_c('th',{staticClass:"center"},[_vm._v("プラン名")]),_c('th',{staticClass:"center"},[_vm._v("料金（税込）")]),_c('th',{staticClass:"center"},[_vm._v("付与ポイント")]),_c('th',{staticClass:"center"},[_vm._v("自動更新決済での購入")]),_c('th',{staticClass:"center"},[_vm._v("都度決済での購入")])])]),_c('tbody',_vm._l((_vm.productList),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"center"},[_vm._v(" "+_vm._s(item.planName)+" ")]),_c('td',{staticClass:"center"},[_vm._v(_vm._s(_vm._f("localeNum")(item.price))+"円")]),_c('td',{staticClass:"center"},[_vm._v(" "+_vm._s(_vm._f("localeNum")(item.point))+"ポイント "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.discount !== 0),expression:"item.discount !== 0"}],staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" （"+_vm._s(_vm._f("localeNum")(item.discount))+"ポイントお得） ")])]),_c('td',{staticClass:"center"},[(_vm.activePlanData.length > 0)?_c('div',[(
                    _vm.activePlanData[0].productId === item.productId ||
                    (_vm.activePlanData[0].productId === '21' &&
                      item.productId === '19')
                  )?_c('div',[_c('span',[_vm._v("契約中")]),_c('Question',{attrs:{"placement":'right',"content":'自動更新設定を解除することで契約停止となります。'}})],1):_c('div',[_c('span',{staticStyle:{"padding":"0 1.25em"}},[_vm._v("-")]),_c('Question',{attrs:{"placement":'right',"content":'契約中の自動更新プランを「停止」することで、契約可能となります。'}})],1)]):_c('div',{on:{"click":function($event){return _vm.settle(item.productId)}}},[_c('el-button',{attrs:{"type":"primary"}},[_vm._v("購入")])],1)]),_c('td',{staticStyle:{"text-align":"center"}},[(item.productId2 !== '20')?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.settle(item.productId2)}}},[_vm._v("購入")]):_c('div',{attrs:{"type":"primary"}},[_vm._v("-")])],1)])}),0)])])])],1),(_vm.activePlanData.length === 0)?_c('div',[_c('h4',[_vm._v("■自動更新の設定について")]),_vm._m(0)]):_c('div',[_c('h4',[_vm._v("■自動更新の設定について")]),_c('p',[_vm._v(" 自動更新の設定中のため、ポイント残高が0になる直前に決済が執行されます。そのため、ポイント残高不足によるツールの利用ストップがないので安心してお使いいただけます。 ")]),_c('h4',[_vm._v("■自動更新のプラン変更について")]),_vm._m(1)]),_c('h4',[_vm._v("■都度決済での購入")]),_c('p',[_vm._v(" 自動更新の設定を問わず、いつでもしていただくことができます。決済完了後に、購入したポイントが付与されます。 ")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" ポイント残高が不足すると各種ツールの利用がストップしてしまうので、自動更新の設定をオススメします。"),_c('br'),_vm._v("自動更新の設定中は、ポイント残高が0になる直前に決済が執行されます。そのため、ポイント残高不足によるツールの利用ストップがないので安心してお使いいただけます。 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 自動更新の設定時にプランを変更したい場合は、一度、契約中の自動更新設定を「停止」してください。その後、新たに契約したいプランの「購入」をクリックし、決済をしてください。"),_c('br'),_vm._v(" 決済完了によって、新たに契約したプランのポイントが付与されます。"),_c('br'),_vm._v(" 自動更新による契約時は、それ以降、ポイントの残高がなくなるタイミングで自動更新が行われます。 ")])
}]

export { render, staticRenderFns }