<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>MCCアカウントに既存のサブアカウントを紐付ける</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】MCCの管理画面TOPページへアクセスします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/01.png`" alt="" />
        <h4>【２】「既存のアカウントをリンク」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/02.png`" alt="" />
        <h4>【３】サブアカウントのIDを入力します（複数入力も可能です）。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no09/no03/03.png`" alt="" />
        </div>
        <p>※サブアカウントのIDはサブアカウントの右上に表示されています。</p>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/04.png`" alt="" />
        <h4>【４】「保留中のリンク リクエスト」が表示されればOKです。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/05.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからGmail<br class="sp-only">--------------------------------
        </p>
        <h4>【５】登録しているGmailにメールが届いています。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/06.png`" alt="" />
        <h4>【６】メールの中にある「リクエストを承諾」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/07.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからサブアカウント<br class="sp-only">--------------------------------
        </p>
        <h4>【７】サブアカウントのアクセスとセキュリティに移動します。</h4>
        <p>「同意する」をクリックしてください。</p>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/08.png`" alt="" />
        <h4>【８】「アクセスを許可」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/09.png`" alt="" />
        <h4>【９】これで紐付け完了です。</h4>
        <p>一度、MCCアカウントに移動しましょう。</p>
        <p>右上のアイコン→MCCアカウントをクリックします。</p>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/10.png`" alt="" />
        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからMCCアカウント<br class="sp-only">--------------------------------
        </p>
        <h4>【１０】これで紐付け完了です。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no03/11.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
