<template>
  <draggable v-model="categoryList" element="ul" :options="{ animation: 300 }">
    <li v-for="(categoryData, index) in categoryList" :key="index">
      <p>{{ categoryData.name }}</p>
      <draggable
        v-model="categoryData.postList"
        element="ul"
        :options="{ animation: 300 }"
      >
        <li v-for="(postData, index2) in categoryData.postList" :key="index2">
          {{ postData.title }}
        </li>
      </draggable>
    </li>
  </draggable>
</template>

<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "Draggable",
  components: {
    draggable,
  },
  props: {},
  data() {
    return {
      categoryList: [
        {
          name: "カテゴリー０１",
          postList: [
            {
              title: "投稿０１",
            },
            {
              title: "投稿０２",
            },
            {
              title: "投稿０３",
            },
          ],
        },
        {
          name: "カテゴリー０２",
          postList: [
            {
              title: "投稿０１",
            },
            {
              title: "投稿０２",
            },
            {
              title: "投稿０３",
            },
          ],
        },
        {
          name: "カテゴリー０３",
          postList: [
            {
              title: "投稿０１",
            },
            {
              title: "投稿０２",
            },
            {
              title: "投稿０３",
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
