var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools02-account-detail"},[(
      _vm.$route.name === 'tools02-account-detail-tags' ||
      _vm.$route.name === 'tools02-account-detail-tag-detail'
    )?void 0:[_c('el-row',[_c('el-col',[_c('h2',[(_vm.tool.codeKebab === 'microsoft-cv')?_c('span',[_vm._v("マネージャーアカウント")]):_c('span',[_vm._v("広告アカウント")]),(_vm.accountId === '0')?_c('span',[_vm._v("新規登録")]):_c('span',[_vm._v("編集")])])])],1),_c('Tools02AccountDetailAccountEditor',{staticStyle:{"margin-bottom":"20px"},attrs:{"accountId":_vm.accountId}}),(
        _vm.accountId !== '0' &&
        (_vm.tool.codeKebab === 'google-cv' || _vm.tool.codeKebab === 'microsoft-cv')
      )?_c('Tools02AccountDetailAutoUploadUrl',{attrs:{"accountId":_vm.accountId}}):_vm._e()],(_vm.tool.codeKebab === 'yda-cv')?[(
        _vm.$route.name === 'tools02-account-detail-tags' ||
        _vm.$route.name === 'tools02-account-detail-tag-detail'
      )?[_c('router-view',{attrs:{"toolId":_vm.toolId,"accountId":_vm.accountId}})]:_vm._e(),void 0]:[(_vm.accountId !== '0')?_c('hr'):_vm._e(),(_vm.accountId !== '0')?_c('div',[_c('h2',{staticStyle:{"border-bottom":"none","padding-bottom":"0","margin-bottom":"50px"},attrs:{"id":"promotions"}},[_vm._v(" プログラム情報 編集 ")]),_c('router-view',{attrs:{"toolId":_vm.toolId,"accountId":_vm.accountId}})],1):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }