<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>アップロードした成果を<br class="sp-only">確認する</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
       <h4>【１】<a href="https://manage.medipartner.jp/login/affiliate" target="_blank"
            >メディパートナーの管理画面</a>にログインし、「レポート出力」をクリックします。</h4>
             <img :src="`${rt}/img/manual/google-cv/no07/no18/01.png`" alt="" />
             <h4>【２】「トランザクション」をクリックします。</h4>
             <img :src="`${rt}/img/manual/google-cv/no07/no18/02.png`" alt="" />
             <h4>【３】CSVをダウンロードします。「CSV出力」をクリックします。</h4>
             <img :src="`${rt}/img/manual/google-cv/no07/no18/03.png`" alt="" />
             <p>これで成果CSVのダウンロードは完了です。「<router-link
            :to="`/manual/${tool.codeKebab}/07/14`"
            >ASPの成果データをアドタスカルにインポートする</router-link
          >」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
