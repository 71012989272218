<template>
  <div class="tools02-home-contents">
    <div class="sub-title">マニュアル・利用方法</div>
    <!-- <el-row class="items" type="flex" align="middle"> -->
    <div class="items">
      <div class="button-cover">
        <a
          :href="toolData.manualData.initialSettingManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <el-button class="initial">
            <div class="line">
              <img :src="`${rt}/img/tools02/initial.png`" alt height="20px" />
              <span>初期設定チュートリアル</span>
            </div>
          </el-button>
        </a>
      </div>
      <div class="button-cover">
        <router-link :to="`/tools02/${tool.codeKebab}/usage-guide`">
          <el-button class="doble">
            <div class="line">
              <span>
                <i class="el-icon-user-solid"></i>
              </span>
              <span style="text-align: center">
                利用設定・利用ステータス
                <br />・ポイント消化について
              </span>
            </div>
          </el-button>
        </router-link>
      </div>

      <div class="button-cover">
        <a
          :href="toolData.manualData.manualHomeUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <el-button class="initial">
            <div class="line">
              <span>
                <i class="el-icon-s-order"></i>
              </span>
              <span>マニュアル・よくある質問</span>
            </div>
          </el-button>
        </a>
      </div>
    </div>

    <div
      class="register-cover"
      v-if="
        tool.codeKebab === 'google-cv' ||
        tool.codeKebab === 'facebook-cv' ||
        tool.codeKebab === 'yahoo-cv' ||
        tool.codeKebab === 'tiktok-cv' ||
        tool.codeKebab === 'microsoft-cv' ||
        tool.codeKebab === 'line-cv' ||
        tool.codeKebab === 'yda-cv'
      "
    >
      <div class="sub-title">登録</div>
      <!-- ↓↓ Google ↓↓ -->
      <div
        v-if="
          tool.codeKebab === 'google-cv' || tool.codeKebab === 'microsoft-cv'
        "
        class="items"
      >
        <div class="button-cover">
          <el-button
            class="doble"
            :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
            @click="move(`/tools02/${tool.codeKebab}/accounts`)"
          >
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span style="text-align: center">
                <span v-if="tool.codeKebab === 'google-cv'"
                  >Google広告アカウント</span
                >
                <span v-if="tool.codeKebab === 'microsoft-cv'"
                  >マネージャーアカウント</span
                >
                ＆
                <br />ASPプログラム情報
              </span>
            </div>
          </el-button>
        </div>
      </div>
      <!-- ↑↑ Google ↑↑ -->

      <!-- ↓↓ Facebookゾーン ↓↓ -->
      <div
        v-else-if="
          tool.codeKebab === 'facebook-cv' ||
          tool.codeKebab === 'tiktok-cv' ||
          tool.codeKebab === 'line-cv' ||
          tool.codeKebab === 'yda-cv'
        "
        class="items"
        :class="tool.codeKebab !== 'facebook-cv' ? 'items-left' : ''"
      >
        <div class="button-cover">
          <el-button
            class="doble"
            :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
            @click="
              move(
                tool.codeKebab === 'yda-cv'
                  ? `/tools02/${tool.codeKebab}/accounts`
                  : `/tools02/${tool.codeKebab}/tags`
              )
            "
          >
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span>
                <span
                  v-if="
                    tool.codeKebab === 'facebook-cv' ||
                    tool.codeKebab === 'tiktok-cv'
                  "
                  >ピクセル</span
                >
                <span v-else-if="tool.codeKebab === 'line-cv'">LINE Tag</span>
                <span v-else-if="tool.codeKebab === 'yda-cv'"
                  >YDA広告アカウント＆<br />コンバージョン</span
                >
                <span>登録</span>
              </span>
            </div>
          </el-button>
        </div>
        <div v-if="tool.codeKebab === 'facebook-cv'" class="button-cover">
          <el-button
            class="doble"
            :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
            @click="move(`/tools02/${tool.codeKebab}/units`)"
          >
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span>ユニット登録</span>
            </div>
          </el-button>
        </div>
        <div class="button-cover">
          <el-button
            class="doble"
            :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
            @click="move(`/tools02/${tool.codeKebab}/promotions`)"
          >
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span>プログラム登録</span>
            </div>
          </el-button>
        </div>
        <div v-if="tool.codeKebab === 'tiktok-cv'" class="button-cover">
          <el-button
            class="doble"
            :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
            @click="move(`/tools02/${tool.codeKebab}/domains`)"
          >
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span>ドメイン認証</span>
            </div>
          </el-button>
        </div>
      </div>
      <!-- ↑↑ Facebookゾーン ↑↑ -->

      <!-- ↓↓ Yahoo!ゾーン ↓↓ -->
      <div v-else-if="tool.codeKebab === 'yahoo-cv'" class="items">
        <div class="button-cover">
          <el-button
            class="doble"
            :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
            @click="move(`/tools02/${tool.codeKebab}/bussiness-accounts`)"
          >
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span>Yahoo!JAPAN ビジネスID</span>
            </div>
          </el-button>
        </div>
        <div class="button-cover">
          <el-button
            class="doble"
            :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
            @click="move(`/tools02/${tool.codeKebab}/accounts`)"
          >
            <div class="line">
              <span>
                <i class="el-icon-s-tools"></i>
              </span>
              <span style="text-align: center">
                Yahoo!検索広告アカウント＆
                <br />ASPプログラム情報
              </span>
            </div>
          </el-button>
        </div>
        <div class="button-cover"></div>
        <!-- <el-col :span="9"></el-col> -->
      </div>
      <!-- ↑↑ Yahoo!ゾーン ↑↑ -->
      <template v-if="userData.bonusPointData[tool.codeCamel] !== undefined">
        <el-row v-if="userData.bonusPointData[tool.codeCamel].auth === false">
          <el-col>
            <UiBonusAlert
              :toolCode="tool.codeCamel"
              :bonusCode="'auth'"
              boxStyle="wide"
            />
          </el-col>
        </el-row>
      </template>
      <template v-if="userData.bonusPointData[tool.codeCamel] !== undefined">
        <el-row v-if="userData.bonusPointData[tool.codeCamel].pixel === false">
          <el-col>
            <UiBonusAlert
              :toolCode="tool.codeCamel"
              :bonusCode="'pixel'"
              boxStyle="wide"
            />
          </el-col>
        </el-row>
      </template>
      <template v-if="userData.bonusPointData[tool.codeCamel] !== undefined">
        <el-row
          v-if="
            tool.codeKebab === 'facebook-cv' &&
            userData.bonusPointData[tool.codeCamel].unit === false
          "
        >
          <el-col>
            <UiBonusAlert
              :toolCode="tool.codeCamel"
              :bonusCode="'unit'"
              boxStyle="wide"
            />
          </el-col>
        </el-row>
      </template>
      <template v-if="userData.bonusPointData[tool.codeCamel] !== undefined">
        <el-row
          v-if="userData.bonusPointData[tool.codeCamel].account === false"
        >
          <el-col>
            <UiBonusAlert
              :toolCode="tool.codeCamel"
              :bonusCode="'account'"
              boxStyle="wide"
            />
          </el-col>
        </el-row>
      </template>
      <template v-if="userData.bonusPointData[tool.codeCamel] !== undefined">
        <el-row
          v-if="userData.bonusPointData[tool.codeCamel].promotion === false"
        >
          <el-col>
            <UiBonusAlert
              :toolCode="tool.codeCamel"
              :bonusCode="'promotion'"
              boxStyle="wide"
            />
          </el-col>
        </el-row>
      </template>
    </div>

    <div class="parameter-passing-cover">
      <div class="items-cover">
        <div v-if="tool.codeKebab === 'squadbeyond-cv'" class="item1">
          <div class="sub-title" style="box-sizing: border-box">
            パラメータ引き継ぎ
          </div>
          <div class="items">
            <div class="button-cover2">
              <el-button
                class="initial"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/parameter-log`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-coin"></i>
                  </span>
                  <span>パラメータログ</span>
                </div>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="
          tool.codeKebab === 'google-cv' ||
          tool.codeKebab === 'facebook-cv' ||
          tool.codeKebab === 'yahoo-cv' ||
          tool.codeKebab === 'tiktok-cv' ||
          tool.codeKebab === 'microsoft-cv' ||
          tool.codeKebab === 'line-cv' ||
          tool.codeKebab === 'yda-cv'
        "
      >
        <div class="sub-title">パラメータ引継ぎ</div>
        <div class="items" style="postion: relative">
          <div class="button-cover">
            <el-button
              class="initial"
              :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
              @click="move(`/tools02/${tool.codeKebab}/parameter-manual`)"
            >
              <div class="line">
                <span>
                  <i class="el-icon-s-tools"></i>
                </span>
                <span>パラメータ引継ぎ設定</span>
              </div>
            </el-button>
          </div>
          <span class="yazirushi">＞</span>
          <div class="button-cover">
            <el-button
              class="initial"
              :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
              @click="move(`/tools02/${tool.codeKebab}/parameter-check`)"
            >
              <div class="line">
                <span>
                  <i class="el-icon-success"></i>
                </span>
                <span>パラメータチェック</span>
              </div>
            </el-button>
          </div>
          <span class="yazirushi">＞</span>
          <div class="button-cover">
            <el-button
              class="initial"
              :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
              @click="move(`/tools02/${tool.codeKebab}/parameter-log`)"
            >
              <div class="line">
                <span>
                  <i class="el-icon-coin"></i>
                </span>
                <span>パラメータログ</span>
              </div>
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="import-cover">
      <div class="items-cover">
        <div v-if="tool.codeKebab === 'squadbeyond-cv'" class="item1">
          <div class="sub-title2" style="box-sizing: border-box">
            自動コース
          </div>
          <div class="items">
            <div class="button-cover2">
              <el-button
                class="initial"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/auto-import-log`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-coin"></i>
                  </span>
                  <span>自動登録履歴</span>
                </div>
              </el-button>
            </div>
          </div>
        </div>
        <div v-else class="item1">
          <div class="sub-title2" style="box-sizing: border-box">
            手動コース
          </div>
          <div class="items" style="padding-right: 10px; padding-left: 10px">
            <div class="button-cover2">
              <el-button
                class="doble"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/report`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-refresh"></i>
                  </span>
                  <span style="text-align: center">
                    ASP成果
                    <br />インポート・エクスポート
                  </span>
                </div>
              </el-button>
            </div>
          </div>
        </div>
        <div
          v-if="
            tool.codeKebab === 'google-cv' ||
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'yahoo-cv' ||
            tool.codeKebab === 'tiktok-cv' ||
            tool.codeKebab === 'microsoft-cv' ||
            tool.codeKebab === 'line-cv' ||
            tool.codeKebab === 'yda-cv'
          "
          class="item2"
        >
          <div class="sub-title2" style="box-sizing: border-box">
            自動コース
          </div>
          <div class="items">
            <div class="button-cover3">
              <el-button
                class="initial"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/point-back`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-s-tools"></i>
                  </span>
                  <span>ASP自動連携設定</span>
                </div>
              </el-button>
            </div>
            <span class="yazirushi">＞</span>
            <div class="button-cover3">
              <el-button
                class="initial"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/auto-import-log`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-coin"></i>
                  </span>
                  <span>自動登録履歴</span>
                </div>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="items-cover2">
      <div class="item2 margin">
        <template v-if="tool.codeKebab === 'google-cv'">
          <div class="sub-title2">APIアップロード</div>
          <div class="items">
            <div class="button-cover2">
              <el-button
                class="initial"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="
                  move(`/tools02/${tool.codeKebab}/resend-offline-conversions`)
                "
              >
                <div class="line">
                  <span>
                    <i class="el-icon-s-tools"></i>
                  </span>
                  <span>API手動アップロード</span>
                </div>
              </el-button>
            </div>
            <div class="button-cover2">
              <el-button
                class="initial"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/api-histories`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-coin"></i>
                  </span>
                  <span>API送信履歴</span>
                </div>
              </el-button>
            </div>
          </div>
        </template>
        <template
          v-else-if="
            tool.codeKebab === 'facebook-cv' ||
            tool.codeKebab === 'tiktok-cv' ||
            tool.codeKebab === 'line-cv' ||
            tool.codeKebab === 'yda-cv' ||
            tool.codeKebab === 'squadbeyond-cv'
          "
        >
          <div class="sub-title2" style="width: 252px; box-sizing: border-box">
            ログ
          </div>
          <div class="items">
            <div class="button-cover2">
              <el-button
                class="doble"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/bridges`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-document"></i>
                  </span>
                  <span style="text-align: center">atidログ</span>
                </div>
              </el-button>
            </div>
            <div class="button-cover2">
              <el-button
                class="doble"
                :class="{ 'not-allowed': validities[tool.codeCamel] === 0 }"
                @click="move(`/tools02/${tool.codeKebab}/api-histories`)"
              >
                <div class="line">
                  <span>
                    <i class="el-icon-document"></i>
                  </span>
                  <span style="text-align: center">API送信履歴</span>
                </div>
              </el-button>
            </div>
          </div>
        </template>
      </div>

      <div
        class="item1"
        v-if="
          tool.codeKebab === 'google-cv' ||
          tool.codeKebab === 'facebook-cv' ||
          tool.codeKebab === 'yahoo-cv' ||
          tool.codeKebab === 'tiktok-cv' ||
          tool.codeKebab === 'microsoft-cv' ||
          tool.codeKebab === 'line-cv' ||
          tool.codeKebab === 'yda-cv'
        "
      >
        <div class="sub-title2" style="box-sizing: border-box">特典</div>
        <div class="items">
          <div class="button-cover2">
            <router-link :to="`/privileges`">
              <el-button class="doble">
                <div class="line">
                  <span>
                    <i class="el-icon-present"></i>
                  </span>
                  <span style="text-align: center">特典一覧</span>
                </div>
              </el-button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <template v-if="userData.bonusPointData[tool.codeCamel] !== undefined">
      <el-row v-if="userData.bonusPointData[tool.codeCamel].manual === false">
        <el-col>
          <UiBonusAlert
            :toolCode="tool.codeCamel"
            :bonusCode="'manual'"
            boxStyle="wide"
          />
        </el-col>
      </el-row>
    </template>
    <template v-if="userData.bonusPointData[tool.codeCamel] !== undefined">
      <el-row v-if="userData.bonusPointData[tool.codeCamel].auto === false">
        <el-col>
          <UiBonusAlert
            :toolCode="tool.codeCamel"
            :bonusCode="'auto'"
            boxStyle="wide"
          />
        </el-col>
      </el-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UiBonusAlert from "@/components/Ui/BonusAlert.vue";

export default {
  name: "Tools02HomeContents",
  components: {
    UiBonusAlert,
  },
  props: {},
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "userData"]),
    ...mapGetters("subscription", ["validities"]),
    toolData() {
      return this.$store.getters["toolData"](this.tool.id);
    },
  },
  created() {
    //
  },
  methods: {
    //
    move(link) {
      if (this.validities[this.tool.codeCamel] === 1) {
        this.$router.push(link);
      } else {
        console.log("not allowed.");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-top: 0;
}
.items-left {
  justify-content: flex-start !important;
  .button-cover + .button-cover {
    margin-left: 5px;
  }
}
.items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border: solid 1px;
  border-radius: 0px 5px 5px 5px;
  margin-bottom: 20px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .button-cover,
  .button-cover2,
  .button-cover3 {
    .el-button {
      padding: 0 15px;
      border: solid 1px #999;
      height: 54px;
      width: 100%;
      color: #777;
      font-weight: bold;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;

      img {
        margin-right: 5px;
      }

      .line {
        align-items: center;
        display: flex;
      }

      .el-icon-user-solid,
      .el-icon-s-order,
      .el-icon-s-tools,
      .el-icon-success,
      .el-icon-coin,
      .el-icon-refresh,
      .el-icon-present,
      .el-icon-document {
        font-size: 20px;
        padding-right: 5px;
      }
    }
  }
  .button-cover {
    width: 33%;
    @media screen and (max-width: 767px) {
      width: 80%;
      margin-bottom: 5px;
    }
  }
  .button-cover2 {
    width: 100%;
    @media screen and (max-width: 767px) {
      width: 80%;
      margin-bottom: 5px;
    }
    &+.button-cover2 {
      margin-left: 15px;
    }
  }
  .button-cover3 {
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 80%;
      margin-bottom: 5px;
    }
  }

  .not-allowed {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .initial {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 20px auto;
  }

  .doble {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin: 20px auto;
  }
}
.items-cover,
.items-cover2 {
  display: flex;

  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .item1 {
    width: 33%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .item2 {
    width: 66%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
.items-cover {
  justify-content: space-between;
}
.items-cover2 {
  justify-content: flex-start;
}

.sub-title,
.sub-title2 {
  box-sizing: border-box;
  background-color: #1c6db5;
  width: 20%;
  min-width: 212px;
  padding: 5px 0;
  text-align: center;
  border-radius: 5px 5px 0px 0px;
  color: white;
  margin-top: 0px;
  font-weight: bold;
}
.sub-title {
  background-color: #1c6db5;
}
.sub-title2 {
  background-color: #f15e62;
}
a {
  text-decoration: none;
}
.yazirushi {
  font-size: 20px;
  font-weight: bold;
  margin-left: 1px;
  @media screen and (max-width: 767px) {
    transform: rotate(90deg);
  }
}
.margin {
  margin-right: 10px;
  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}
</style>
