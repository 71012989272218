<template>
  <div class="tools02-cart-system-contents">
    <el-row>
      <el-col
        >カート・計測システム連携にお申し込みいただくことで、ASPの自動連携設定と同じ仕様でお使いいただくことができます。</el-col
      >
    </el-row>
    <el-row>
      <el-col><h4>対応しているカート・計測システム</h4></el-col>
      <el-col>現在は以下の{{ cartAutoImportCodes.length }}種類です。</el-col>
      <el-col>
        <span
          v-for="(asp, index) in cartAutoImportCodes"
          :key="asp.aspId"
          :index="index"
          >{{ asp.aspName
          }}<span v-if="index != Object.keys(cartAutoImportCodes).length - 1"
            >、</span
          ></span
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col><h4>連携できる成果連携ツール</h4></el-col>
      <el-col
        >利用設定がONになっている全ての成果連携ツール（<span
          v-for="(toolData, index) in toolList"
          :key="index"
          >{{ toolData.mediaName
          }}<span v-if="index + 1 < toolList.length">、</span></span
        >）でご利用いただくことができます。</el-col
      >
    </el-row>
    <el-row>
      <el-col><h4>ご利用料金について</h4></el-col>
      <el-col
        v-for="(cartSystemProductData, index) in cartSystemProductList"
        :key="index"
        >{{ cartSystemProductData.name }}：月{{
          cartSystemProductData.pointTax | localeNum
        }}円（税込）・・・<span v-if="cartSystemProductData.id === '1'"
          >1つのカート・計測システムのみ利用したい方向け</span
        ><span v-else-if="cartSystemProductData.id === '2'"
          >全てのツールが利用できるので２つ以上のカート・計測システムを利用したい方向け</span
        ></el-col
      >
    </el-row>
    <el-row>
      <el-col><h4>契約期間について</h4></el-col>
      <el-col>３ヶ月毎の契約になっています。</el-col>
      <el-col
        >利用期間終了の1ヶ月前になりましたら、アドタスカルから利用継続に関するご連絡を差し上げます。利用の更新または解約のご返信をお願いします。解約の場合は、契約期間終了の5日前までに、解約の旨をお伝えください。</el-col
      >
    </el-row>
    <el-row
      v-for="(
        cartSystemProductData, cartSystemProductIndex
      ) in cartSystemProductList"
      :key="cartSystemProductIndex"
    >
      <table class="description">
        <caption>
          {{
            cartSystemProductData.name
          }}<span v-if="cartSystemProductData.id === '1'">：ecforce</span
          >を申し込んだ場合
        </caption>
        <thead>
          <tr>
            <th>ツール名</th>
            <th>利用設定</th>
            <th v-for="asp in cartAutoImportCodes" :key="asp.aspId">
              {{ asp.aspName }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(toolData, index) in toolList" :key="index">
            <td>{{ toolData.name }}</td>
            <td>
              <span v-if="index < 2">ON</span>
              <span v-else>OFF</span>
            </td>
            <td v-for="asp in cartAutoImportCodes" :key="asp.aspId">
              <span v-if="index < 2">
                <span v-if="asp.aspName === 'ecforce'">◯</span>
                <span v-else>
                  <span v-if="cartSystemProductData.id === '2'">◯</span>
                  <span v-else>×</span>
                </span>
              </span>
              <span v-else>×</span>
            </td>
          </tr>
        </tbody>
      </table>
    </el-row>

    <el-row>
      <el-col><h4>サポートについて</h4></el-col>
      <el-col>サポートは、個別のチャットワークグループにて行います。</el-col>
      <el-col
        >カート・計測システム連携以外のアドタスカルに関する内容のサポートも、ご契約中はチャットワークグループにて行わせていただきます。</el-col
      >
      <el-col
        >カート・計測システム連携に関してはクライアント様も関わってきますので、必要に応じてZoomなどでのサポートも行います。</el-col
      >
    </el-row>
    <el-row>
      <el-col><h4>お申し込みについて</h4></el-col>
      <el-col>＜STEP1:お申し込み＞</el-col>
      <el-col
        >お申し込みをご希望の場合は、以下のフォームからお申し込みをお願いいたします。</el-col
      >
      <el-col>料金のお支払いは銀行振込のみとなります。</el-col>
      <el-col><br /></el-col>
      <el-col>＜STEP2:ご入金＞</el-col>
      <el-col
        >お申込み後、すぐにご登録メールアドレス宛にお振込み先を記載したメールをお送りいたしますので、ご入金をお願いいたします。</el-col
      >
      <el-col><br /></el-col>
      <el-col
        >メールが届かない場合は、迷惑メールボックスの中をご確認ください。</el-col
      >
      <el-col
        >迷惑メールボックスにもメールが届いていない場合は、サポートまでお問い合わせをお願いいたします。</el-col
      >
      <el-col><br /></el-col>
      <el-col>＜STEP3:システム利用開始＞</el-col>
      <el-col
        >ご入金の確認後、3営業日以内にシステムの提供とサポートチャットワークグループの作成をいたします。</el-col
      >
    </el-row>
    <el-row>
      <table class="description compare">
        <tbody>
          <tr>
            <th>プラン</th>
            <template
              v-for="(cartSystemProductData, index) in cartSystemProductList"
            >
              <td
                :key="index"
                :class="{
                  single: cartSystemProductData.id === '1',
                  premium: cartSystemProductData.id === '2',
                }"
              >
                {{ cartSystemProductData.name }}
              </td>
            </template>
          </tr>
          <tr>
            <th>利用可能ツール</th>
            <td>
              <span v-if="this.aspData === undefined"
                >1つのカート・計測システム</span
              >
              <span v-else>{{ aspData.aspName }}</span>
            </td>
            <td>全カート・計測システム</td>
          </tr>
          <tr>
            <th>サポート</th>
            <td colspan="2">チャットワークグループ<br />必要に応じてZoom</td>
          </tr>
          <tr>
            <th>料金</th>
            <template
              v-for="(cartSystemProductData, index) in cartSystemProductList"
            >
              <td :key="index">
                <div>
                  月{{
                    cartSystemProductData.pointTax | localeNum
                  }}円×3ヶ月間={{
                    (cartSystemProductData.pointTax * 3) | localeNum
                  }}円（税込）
                </div>
                <div
                  v-if="g_todayIsBefore('2023-11-01') === true"
                  style="color: red; font-weight: bold"
                >
                  10/31まで+1ヶ月分プレゼント
                </div>
              </td>
            </template>
          </tr>
          <tr>
            <th>開始時期</th>
            <td colspan="2">
              ご入金の確認後、3営業日以内にシステムの提供とサポートチャットワークグループの作成をいたします。
            </td>
          </tr>
          <tr>
            <th>解約・休止</th>
            <td colspan="2">
              契約期間終了の5日前までの申し出でにより、休止・解約が可能になります。<br />解約の場合は、残りの契約月数分の料金をポイントで換算し、ポイントで返却します。
            </td>
          </tr>
          <tr>
            <th>解約時のポイントバック</th>
            <template
              v-for="(cartSystemProductData, index) in cartSystemProductList"
            >
              <td :key="index">
                残りの契約月数×{{
                  cartSystemProductData.point | localeNum
                }}ポイント
              </td>
            </template>
          </tr>
          <tr>
            <th>途中でのツール変更</th>
            <td>利用中に別のカートや計測システムに変更可能</td>
            <td>不要</td>
          </tr>
          <tr>
            <th>決済方法</th>
            <td colspan="2">銀行振込（振込手数料のご負担をお願いします）</td>
          </tr>
          <tr>
            <th>お申し込み</th>
            <template
              v-for="(cartSystemProductData, index) in cartSystemProductList"
            >
              <td :key="index" style="vertical-align: bottom">
                <el-row
                  v-if="
                    activeCartSystemSubscriptionData === undefined &&
                    applyingCartSystemSubscriptionData === undefined
                  "
                >
                  <el-col
                    v-if="
                      cartSystemProductData.id === '1' && aspData === undefined
                    "
                  >
                    使用したいツールを選択してください。
                    <el-select v-model="aspId" placeholder="選択してください。">
                      <el-option
                        v-for="asp in cartAutoImportCodes"
                        :key="asp.aspId"
                        :label="asp.aspName"
                        :value="asp.aspId"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col>
                    <p>
                      <el-checkbox v-model="checkedList[index]"
                        >申し込みを希望の場合はチェックを入れてください。</el-checkbox
                      >
                    </p>
                    <div style="text-align: center">
                      <button
                        class="el-button el-button--primary"
                        @click="
                          addCartSystemSubscriptions(cartSystemProductData.id)
                        "
                        :class="{
                          '': checkedList[index] === true,
                          'is-disabled': checkedList[index] === false,
                        }"
                        :disabled="checkedList[index] === false"
                      >
                        <span
                          >{{ cartSystemProductData.name }}<br />申し込む</span
                        >
                      </button>
                    </div>
                  </el-col>
                </el-row>
                <el-row v-else>
                  <el-col>
                    <div style="text-align: center">
                      <span>
                        <span>現在</span>
                        <template
                          v-if="activeCartSystemSubscriptionData === undefined"
                        >
                          <span
                            v-if="
                              applyingCartSystemSubscriptionData !== undefined
                            "
                            >{{
                              $store.getters["tools02/cartSystemProductData"](
                                applyingCartSystemSubscriptionData.cartSystemProductId
                              ).name
                            }}申込中</span
                          >
                        </template>
                        <span v-else
                          >{{
                            $store.getters["tools02/cartSystemProductData"](
                              activeCartSystemSubscriptionData.cartSystemProductId
                            ).name
                          }}契約中</span
                        >
                        <span>です。</span>
                      </span>
                    </div>
                  </el-col>
                </el-row>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02CartSystemContents",
  components: {},
  props: {
    aspData: Object,
  },
  data() {
    return {
      aspId: "",
      checkedList: [false, false],
      singleFlag: false,
      premiumFlag: false,
      apply: true,
      statusText: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "userData"]),
    ...mapGetters("tools02", [
      "activeCartSystemSubscriptionData",
      "applyingCartSystemSubscriptionData",
      "cartSystemProductList",
      "cartAutoImportCodes",
    ]),
    ...mapGetters("subscription", ["validities"]),
    toolList() {
      const topPageToolList = JSON.parse(
        JSON.stringify(this.$store.getters.topPageToolList)
      );
      const toolList = topPageToolList.filter(function (topPageToolData) {
        return (
          topPageToolData.toolId !== "11" &&
          topPageToolData.toolId !== "13" &&
          topPageToolData.toolId !== "14"
        );
      });
      return toolList;
    },
  },
  watch: {},
  methods: {
    addCartSystemSubscriptions(productId) {
      //
      let aspId = "0";
      if (this.aspData === undefined) {
        if (productId === "1") {
          aspId = this.aspId;
        } else if (productId === "2") {
          // no action.
        }
      } else {
        aspId = this.aspData.aspId;
      }

      //
      let params = new URLSearchParams();
      params.append("product_id", productId);
      params.append("asp_id", aspId);
      this.$axios
        .post(`${this.rt}/l/api/tools02/cart-system/apply`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            let alertMessage =
              "お申し込みありがとうございます！\n" +
              "ご登録のメールアドレス宛にお振込み先を記載したメールを送付いたしました。\n" +
              "ご確認お願いいたします。\n" +
              "メールが届かない場合は、迷惑メールボックスの中をご確認ください。";
            alert(alertMessage);
            this.apply = false;
            this.statusText = "申込中";
            this.$store.dispatch("tools02/setCartSystemSubscriptionList");
          } else {
            alert("申込処理に失敗しました。");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.description {
  width: 100%;
  border-collapse: collapse;
  caption {
    text-align: left;
    font-weight: bold;
  }
  tr {
    th,
    td {
      border: 1px solid #999;
    }
    th {
      background-color: #ddd;
      padding: 5px 0;
      @media screen and (max-width: 767px) {
        font-size: 12px !important;
        width: 200px;
      }
    }
    td {
      text-align: center;
    }
  }
  thead {
    tr {
      th {
        font-weight: normal;
        letter-spacing: 0.15em;
      }
      th.active {
        width: 140px;
        background-color: #cd5a63;
      }
      th.non-active {
        width: 140px;
        background-color: #598fb5;
      }
    }
  }
}
table.compare {
  margin-top: 30px;
  tr {
    th,
    td {
      padding: 20px 0;
    }
    th {
      width: 24%;
    }
    td {
      width: 38%;
    }
    td.single {
      background-color: #bbdba5;
    }
    td.premium {
      background-color: #fbddcc;
    }
    button {
      width: 60%;
    }
    button span {
      line-height: 200%;
    }
  }
}
</style>
