<template>
  <div class="ctrl-browser-test-tool">
    <el-row>
      <el-col>
        <h3>テスト用URL</h3>
        <a
          href="https://info100.xsrv.jp/adtasukaru-test/tools02/parameter-passing-test/home.php"
          target="_blank"
          rel="noopener noreferrer"
          >https://info100.xsrv.jp/adtasukaru-test/tools02/parameter-passing-test/home.php</a
        >
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>テストコード</h3>
        <el-input
          v-model="testCode"
          style="width: 150px; margin-right: 5px"
        ></el-input>
      </el-col>
    </el-row>
    <div v-if="testCode !== ''">
      <el-row>
        <el-col>
          <h3>famCSV & ログ解析</h3>
          <div class="select-box">
            <div>解析テストコード: {{ testCode }}</div>
            <div>
              <input
                v-on:change="selectedFile($event)"
                type="file"
                accept="text/csv"
              />
              <el-button
                @click="analyze"
                type="primary"
                v-loading="analyzingCsv"
                >解析</el-button
              >
            </div>
          </div>
          <div v-if="csvAnalysisResultList.length > 0">
            <h4>CSV解析結果</h4>
            <el-table :data="csvAnalysisResultList" class="result-table">
              <el-table-column label="サーバー" width="80px">
                <template slot-scope="scope">
                  <span v-if="scope.row.serverCondition === 'healthy'"
                    >正常</span
                  >
                  <span v-else-if="scope.row.serverCondition === 'loading'"
                    >過負荷</span
                  >
                  <span v-else-if="scope.row.serverCondition === 'down'"
                    >ダウン</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="testNo" prop="testNo" width="80px">
              </el-table-column>
              <el-table-column label="結果" width="300px">
                <template slot-scope="scope">
                  <span v-if="scope.row.noError === true"
                    ><el-button
                      type="success"
                      icon="el-icon-check"
                      circle
                      class="success-icon"
                    />{{ scope.row.message }}</span
                  >
                  <span v-else style="color: red"
                    ><el-button type="danger" round class="danger-icon"
                      >!</el-button
                    >{{ scope.row.message }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="メディア端末ID" prop="parameter">
              </el-table-column>
              <el-table-column label="リファラ" prop="referrer">
              </el-table-column>
            </el-table>
            <h4>ログ解析結果</h4>
            <el-table :data="logAnalysisResultList" class="result-table">
              <el-table-column label="testNo" prop="testNo" width="80px">
              </el-table-column>
              <el-table-column label="ページタイプ" width="100px">
                <template slot-scope="scope">
                  <span v-if="scope.row.logTypeKey === 0">記事LP</span>
                  <span v-else-if="scope.row.logTypeKey === 1">中間ページ</span>
                  <span v-else-if="scope.row.logTypeKey === 2"
                    >中間ページ２</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="結果" width="320px">
                <template slot-scope="scope">
                  <span v-if="scope.row.noError === true"
                    ><el-button
                      type="success"
                      icon="el-icon-check"
                      circle
                      class="success-icon"
                    />{{ scope.row.message }}</span
                  >
                  <span v-else style="color: red"
                    ><el-button type="danger" round class="danger-icon"
                      >!</el-button
                    >{{ scope.row.message }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label="リファラ" prop="referrer">
              </el-table-column>
              <el-table-column label="飛び先URL" prop="completeLink">
              </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ctrl-browser-test-tool",
  components: {},
  props: {},
  data() {
    return {
      testCode: "",
      // testCode: "YMCA",

      //
      uploadFile: null,
      analyzingCsv: false,
      csvAnalysisResultList: [],
      // csvAnalysisResultList: [
      //   {
      //     serverCondition: "healthy",
      //     message: "異常なし",
      //     noError: true,
      //     parameter:
      //       "userpara_aiueo.gclid_xxx_wcB.testCodeAT_{$serverCondition}-{$testCode}.testNo_{$testNo}.spAT_assigned",
      //     referrer:
      //       "https://info100.xsrv.jp/adtasukaru-test/tools02/parameter-passing-test/adtasukaru-wp/mypage.php?userpara=aiueo&gclid=xxx_wcB&testCodeAT={$serverCondition}-{$testCode}&testNo={$testNo}&spAT=assigned",
      //     testNo: "1",
      //   },
      //   {
      //     serverCondition: "healthy",
      //     message: "該当のリファラが存在しませんでした。",
      //     noError: false,
      //     parameter: "",
      //     referrer: "",
      //     testNo: "2",
      //   },
      //   {
      //     serverCondition: "loading",
      //     message: "異常なし",
      //     noError: true,
      //     parameter:
      //       "userpara_aiueo.gclid_xxx_wcB.testCodeAT_{$serverCondition}-{$testCode}.testNo_{$testNo}.spAT_assigned",
      //     referrer:
      //       "https://info100.xsrv.jp/adtasukaru-test/tools02/parameter-passing-test/adtasukaru-wp/mypage.php?userpara=aiueo&gclid=xxx_wcB&testCodeAT={$serverCondition}-{$testCode}&testNo={$testNo}&spAT=assigned",
      //     testNo: "3",
      //   },
      //   {
      //     serverCondition: "loading",
      //     message: "該当のリファラが存在しませんでした。",
      //     noError: false,
      //     parameter: "",
      //     referrer: "",
      //     testNo: "4",
      //   },
      //   {
      //     serverCondition: "down",
      //     message: "異常なし",
      //     noError: true,
      //     parameter:
      //       "userpara_aiueo.gclid_xxx_wcB.testCodeAT_{$serverCondition}-{$testCode}.testNo_{$testNo}.spAT_assigned",
      //     referrer:
      //       "https://info100.xsrv.jp/adtasukaru-test/tools02/parameter-passing-test/adtasukaru-wp/mypage.php?userpara=aiueo&gclid=xxx_wcB&testCodeAT={$serverCondition}-{$testCode}&testNo={$testNo}&spAT=assigned",
      //     testNo: "5",
      //   },
      //   {
      //     serverCondition: "down",
      //     message: "該当のリファラが存在しませんでした。",
      //     noError: false,
      //     parameter: "",
      //     referrer: "",
      //     testNo: "6",
      //   },
      // ],

      //
      analyzingLog: false,
      logAnalysisResultList: [],
      // logAnalysisResultList: [
      //   {
      //     testNo: "1",
      //     logTypeKey: 0,
      //     referrer: "a",
      //     completeLink: "b",
      //     noError: true,
      //     message: "異常なし",
      //   },
      //   {
      //     testNo: "2",
      //     logTypeKey: 0,
      //     referrer: "a",
      //     completeLink: "b",
      //     noError: false,
      //     message: "リファラなし",
      //   },
      //   {
      //     testNo: "6",
      //     logTypeKey: 1,
      //     referrer: "a",
      //     completeLink: "b",
      //     noError: false,
      //     message: "該当のページタイプが見つかりませんでした。",
      //   },
      //   {
      //     testNo: "6",
      //     logTypeKey: 2,
      //     referrer: "a",
      //     completeLink: "b",
      //     noError: true,
      //     message: "異常なし",
      //   },
      // ],
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {},
  methods: {
    generateRandomPassword(length) {
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let password = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset.charAt(randomIndex);
      }

      return password;
    },
    selectedFile(e) {
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
      this.$forceUpdate();
    },
    analyze() {
      //
      if (this.uploadFile !== null) {
        // through.
      } else {
        alert("ファイルを選択してください。");
        return;
      }

      //
      this.analyzingCsv = true;
      let formData = new FormData();
      formData.append("test_code", this.testCode);
      formData.append("upload_file", this.uploadFile);
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(`${this.rt}/l/api/tools02/browser-test/analyze`, formData, config)
        .then((response) => {
          console.log(response);
          this.analyzingCsv = false;
          if (response.data.status === 200) {
            this.csvAnalysisResultList = response.data.csvAnalysisResultList;
            this.logAnalysisResultList = response.data.logAnalysisResultList;
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    analyzeLog() {
      this.analyzingLog = true;
      let formData = new FormData();
      formData.append("test_code", this.testCode);
      formData.append("upload_file", this.uploadFile);
      let config = { headers: { "content-type": "multipart/form-data" } };
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/browser-test/analyze-csv`,
          formData,
          config
        )
        .then((response) => {
          console.log(response);
          this.analyzingLog = false;
          if (response.data.status === 200) {
            alert("解析完了");
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row + .el-row {
  margin-top: 50px;
}
.el-row {
  h3 {
    border-radius: 3px;
    background-color: #ddd;
    padding: 3px 8px;
  }
  h4 {
    margin: 0;
    margin-top: 20px;
  }
  .test-url-list {
    h5 {
      margin: 0;
      margin-top: 10px;
    }
  }
  .result-box {
    padding: 10px;
    border: solid 1px #999;
    font-size: 13px;
    .server-condition-box {
      & + .server-condition-box {
        margin-top: 30px;
      }
      .server-condition-name {
        font-weight: bold;
        font-size: 16px;
        background-color: skyblue;
        padding: 2px 5px;
      }
      .test-no-box {
        margin-top: 10px;
        .test-no {
          .message {
            margin-left: 5px;
          }
        }
        .title {
          font-weight: bold;
        }
      }
    }
  }
}
.select-box {
  div {
    margin-top: 10px;
  }
}
.result-table {
  font-size: 13px;
}
</style>
