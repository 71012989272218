<template>
  <div class="gadget-description">
    <el-row>
      <el-col>
        <h3 class="title">{{tools['googleCv'].name2}}とは</h3>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: "GadgetDescription",
  props: {},
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>
