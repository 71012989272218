<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>MCCアカウントを使おう</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>用語解説</h3>
        <ul>
          <li>MCCアカウント：サブアカウントをまとめるアカウント（FB広告のビジマネと同じ）</li>
          <li>サブアカウント：実際に広告を運用するためのアカウント</li>
        </ul>
        <h3>MCCアカウントを使うメリット</h3>
        <ul>
          <li>コンバージョンを一括管理できます</li>
          <li>オフラインコンバージョンのアップロードをMCCアカウントにまとめられます</li>
          <li>サブアカウントを簡単に増やすことができます</li>
        </ul>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>コンバージョンを一括管理できます</h4>
        <ul>
          <li>MCCアカウントで作成したコンバージョンをサブアカウントで使うことができます</li>
        </ul>
        <h4>オフラインコンバージョンのアップロードをMCCアカウントにまとめられます</h4>
        <h5>＜サブアカウントでコンバージョンを管理した場合＞</h5>
        <p>サブアカウントごとにコンバージョンをアップロードする必要があります</p>
        <h5>＜MCCアカウントでコンバージョンを管理した場合＞</h5>
        <p>すべてのサブアカウントの成果を一括でアップロードできます→楽</p>
        <h4>サブアカウントを簡単に増やすことができます</h4>
        <router-link to="/manual/google-cv/09/04">MCCアカウントから新規のサブアカウントを開設する</router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
