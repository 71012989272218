<template>
  <div class="ctrl-affiliate-download-bank-csv">
    <h2>報酬処理用CSVダウンロード</h2>
    <el-row>
      <el-col>
        <DownloadBankCsv />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import DownloadBankCsv from "@/components/Ctrl/Affiliate/DownloadBankCsv/DownloadBankCsv.vue";

export default {
  data() {
    return {};
  },
  components: {
    DownloadBankCsv,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
