<template>
  <div
    class="tools02-report-import-manual-send-conversion-event-system-line-inputs"
  >
    <div v-if="conversionDataType === 'handWriting'">
      <el-input
        type="textarea"
        :rows="24"
        placeholder="Please input"
        v-model="handWrittenConversionDataString"
      >
      </el-input>
    </div>
    <div v-else>
      <el-row type="flex" align="middle">
        <el-col :span="3">CV発生日時（※）</el-col>
        <el-col :span="10"
          ><el-date-picker
            v-model="conversionData.eventTimestamp"
            type="datetime"
            value-format="timestamp"
            placeholder=""
          >
          </el-date-picker
        ></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">報酬額</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.value" type="number" min="0"
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">currency</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.currency" disabled
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">CLICK ID</el-col>
        <el-col :span="18"
          ><el-input v-model="conversionData.clickId"
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">browser_id</el-col>
        <el-col :span="6"
          ><el-input v-model="conversionData.browserId"
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">User Agent</el-col>
        <el-col :span="18"
          ><el-input v-model="conversionData.userAgent"
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">IPアドレス</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.ipAddress"
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">CVページURL</el-col>
        <el-col :span="18"><el-input v-model="conversionData.url" /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">deduplication_key</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.deduplicationKey"
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">event_type</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.eventType" disabled
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">event_name</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.eventName" disabled
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">source_type</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.sourceType" disabled
        /></el-col>
      </el-row>
      <el-row type="flex" align="middle">
        <el-col :span="3">external_id</el-col>
        <el-col :span="4"
          ><el-input v-model="conversionData.externalId"
        /></el-col>
      </el-row>
    </div>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="sendConversionEvent" type="primary">送信</el-button>
      </el-col>
    </el-row>
    <el-row v-if="resultData !== undefined">
      <el-col>
        <h3>送信結果</h3>
        <div v-if="resultData.eventReceived === true">送信成功！</div>
        <div v-else>{{ resultData.errorMessage }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tools02-report-import-manual-send-conversion-event-system-line-inputs",
  components: {},
  props: {
    resultData: Object,
    type: String,
    conversionDataType: String,
  },
  data() {
    return {
      tools02TagId: "",
      eventList: [
        { label: "注文", value: "PlaceAnOrder" },
        { label: "注文開始", value: "InitiateCheckout" },
      ],
      conversionData: {
        eventTimestamp: "",
        value: 0,
        currency: "JPY",
        clickId: "",
        browserId: "",
        userAgent: "",
        ipAddress: "",
        url: "",
        deduplicationKey: "",
        eventType: "conversion",
        eventName: "Conversion",
        sourceType: "web",
        externalId: "",
      },
      handWrittenConversionDataString: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
    ...mapGetters("tools02", []),
    tools02TagList() {
      return this.$store.getters["tools02/allTagListInTool"](this.tool.id);
    },
  },
  created() {
    //
    this.handWrittenConversionDataString = `{
  "web": {
    "ip_address": "",
    "user_agent": "",
    "url": "https://adtasukaru.com/cv"
  },
  "event": {
    "deduplication_key": "",
    "event_type": "conversion",
    "event_name": "Conversion",
    "source_type": "web",
    "event_timestamp": ${Math.floor(Date.now() / 1000)}
  },
  "user": {
    "browser_id": "12345678-1234-1234-1234-123456789012",
    "click_id": "",
    "external_id": ""
  },
  "custom": {
    "currency": "JPY",
    "value": 10 
  }
}`;
  },
  methods: {
    sendConversionEvent() {
      //
      let conversionData;

      //
      if (this.conversionDataType === "handWriting") {
        //
        let convertedHandWrittenConversionDataString =
          this.handWrittenConversionDataString.replace(/\n/g, "");
        convertedHandWrittenConversionDataString =
          convertedHandWrittenConversionDataString.replace(/\s/g, "");

        //
        conversionData = JSON.parse(convertedHandWrittenConversionDataString);
      } else {
        //
        conversionData = JSON.parse(JSON.stringify(this.conversionData));

        //
        if (conversionData.eventTimestamp !== "") {
          conversionData.eventTimestamp = Math.floor(
            conversionData.eventTimestamp / 1000
          );
        } else {
          conversionData.eventTimestamp = 0;
        }

        //
        if (conversionData.value !== "") {
          // no action.
        } else {
          conversionData.value = 0;
        }
      }

      //
      const payload = {
        tools02TagId: this.tools02TagId,
        conversionData: conversionData,
      };
      this.$emit("sendConversionEvent", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
