<template>
  <div class="tools01-page-list-display-tag-button">
    <el-button class="button" type="primary" @click="dialogVisible = true"
      >タグ表示</el-button
    >

    <!-- ↓↓ dialog ↓↓ -->
    <el-dialog
      :title="`【${toolData.name2} タグ：${pageData.name}】`"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div>
        <p>
          <span v-if="toolData.code2 === 'popup01'">
            ツール配信を有効にしたいページの&lt;/body>の直前に以下のタグを設置してください。
          </span>
          <span v-else>
            {{
              toolData.name
            }}を登場させたい位置に以下のタグを設置してください。
            <span v-if="toolData.code2 !== 'popup02'"
              >{{
                toolData.name
              }}を常にしたい場合は、任意の位置に設置してください（&lt;/body>の直前など）。</span
            >
          </span>
          <a href="http://" target="_blank" rel="noopener noreferrer"
            >【マニュアル】</a
          >
        </p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3 }"
          v-model="pageData.installTag"
          onclick="this.select();"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="dialogVisible = false"
          >閉じる</el-button
        >
      </span>
    </el-dialog>
    <!-- ↑↑ dialog ↑↑ -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DisplayTagButton",
  props: {
    pageId: String,
  },
  components: {},
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["rt"]),
    pageData() {
      return this.$store.getters["tools01/pageData"](this.pageId);
    },
    toolData() {
      return this.$store.getters.toolData(this.pageData.toolId);
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(.el-dialog) {
  text-align: left;
}
</style>
