<template>
  <div class="tools02-initial-setting-contents-tools02">
    <div class="initial-table-cover">
      <div
        class="initial-table"
        v-for="(tutorialUnit, tutorialIndex) in [
          [tutorialList[0], tutorialList[1], tutorialList[2]],
          [tutorialList[3], tutorialList[4], { title: '特典' }],
        ]"
        :key="tutorialIndex"
        :span="12"
      >
        <div
          v-for="(categoryData, categoryIndex) in tutorialUnit"
          :key="categoryIndex"
          class="tutorial-unit"
        >
          <div
            v-if="
              tool.codeKebab === 'yahoo-cv' &&
              tutorialIndex === 1 &&
              categoryIndex === 0
            "
          ></div>
          <div v-else-if="tutorialIndex === 1 && categoryIndex === 2">
            <template v-if="conditionGroupedPrivilegeList.length !== 0">
              <h3>{{ categoryData.title }}</h3>
              <div>
                <table
                  v-for="(conditionData, conditionIndex) in conditionGroupedPrivilegeList"
                  :key="conditionIndex"
                >
                  <thead>
                    <tr>
                      <th colspan="2">{{ conditionData.conditionName }}</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(privilegeData, privilegeIndex) in conditionData.privilegeList"
                    :key="privilegeIndex"
                  >
                    <tr v-if="privilegeData.acquired === '1'" class="aquired">
                      <th class="menu-no">達成</th>
                      <td @click="g_toPrivilegePage(privilegeData.privilegeId)">
                        <span>{{ privilegeData.privilegeName }}</span>
                      </td>
                    </tr>
                    <tr v-else class="unaquired">
                      <th class="menu-no"><span>未達</span></th>
                      <td>{{ privilegeData.privilegeName }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </div>
          <div v-else>
            <h3>{{ categoryData.title }}</h3>
            <table
              v-for="(sectionData, sectionIndex) in categoryData.sectionList"
              :key="sectionIndex"
            >
              <thead>
                <tr>
                  <th colspan="2">{{ sectionData.title }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(manualData, manualIndex) in sectionData.manualList"
                  :key="manualIndex"
                >
                  <th class="menu-no">
                    <el-checkbox v-model="manualData.checked"></el-checkbox
                    >{{ manualData.index }}
                  </th>
                  <td>
                    <router-link
                      v-if="manualData.url === undefined"
                      :to="`/manual/${tool.codeKebab}/${manualData.cate01}/${manualData.cate02}`"
                    >
                      {{ manualData.title }}
                    </router-link>
                    <a v-else :href="manualData.url" target="_blank" rel="noopener noreferrer">
                      {{ manualData.title }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02",
  components: {},
  props: {},
  data() {
    return {
      tutorialList: [],
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "tool",
      "apiErrorMessage",
      "tools",
      "conditionGroupedPrivilegeList",
    ]),
    ...mapGetters("tools02", ["initialSettingTutorialStatuses"]),
  },
  watch: {
    tutorialList: {
      handler() {
        var formData = new FormData();
        formData.append("tool_id", this.tool.id);
        formData.append("tutorial_list", JSON.stringify(this.tutorialList));
        this.$axios
          .post(`${this.rt}/api/tools02/reg-tutorial-statuses.php`, formData)
          .then((res) => {
            console.log(res);
            if (res.data.status === 200) {
              this.$store.dispatch("setTutorialList");
              return;
            } else if (res.data.status === 401) {
              alert(res.data.message);
              this.$router.push("/login");
            } else {
              alert(this.apiErrorMessage);
              return;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      deep: true,
    },
  },
  created() {
    this.tutorialList = JSON.parse(
      JSON.stringify(this.$store.getters["tutorialList"](this.tool.id))
    );
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.initial-table-cover {
  display: flex;
  @media screen and (max-width: 1148px) {
    flex-direction: column;
  }
  .initial-table {
    width: 50%;
    padding: 0 10px;
    @media screen and (max-width: 1148px) {
      width: 100%;
      padding: 0;
    }
  }
}
h3 {
  @media screen and (max-width: 1148px) {
    margin-top: 20px !important;
  }
}
.menu-no {
  width: 90px;
}
.aquired span {
  cursor: pointer;
  text-decoration: underline;
  color: -webkit-link;
}
.unaquired span {
  color: #4d4d4d;
}
</style>
