<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（メディパートナー）</h2>
    <el-row>
      <el-col>
        <PromotionIdDescription :aspId="'22'"/>
        <h4>
          【１】<a href="https://manage.medipartner.jp/login/affiliate" target="_blank" rel="noopener noreferrer">メディパートナーの管理画面</a>
          にログインし、「アフィリエイトプログラム」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no16/01.png`" alt="" />

        <h4>【２】「マイアフィリエイト」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no16/02.png`" alt="" />

        <h4>
          【３】「提携中プログラム」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no16/03.png`" alt="" />

        <h4>
          【４】すでに提携しているプログラムが表示されるので、登録したいプログラム名を探してコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no16/04.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【５】アドタスカルのASPプログラム名に貼り付けます。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no16/05.png`" alt="" />

        <h4 id="from-manual2">【６】プログラムIDはご自身で決めます。</h4>
        <p>◎決め方</p>
        <ul>
          <li>半角英数字</li>
          <li>同じASP内で実施している他の案件と被らないようにする</li>
        </ul>

        <h4>【7】アフィリエイトリンクの後ろに「&amp;campaign=[プログラムID]」を追記する</h4>
        <h5>▼メディパートナーから発行されたアフィリリンク</h5>
        <p>https://www.medipartner.jp/click.php?APID=01234&affID=0003210&STID=12345</p>
        <p>↓</p>
        <h5>▼【6】で決めたプログラムIDを「<span class="green">&amp;campaign=プログラムID</span>」という形で付ける</h5>
        <p>https://www.medipartner.jp/click.php?APID=01234&affID=0003210&STID=12345<span class="green">&amp;campaign=mamanic</span></p>
        <p>これを記事LPや中間ページに設置してください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PromotionIdDescription from "@/components/Manual/Tools02/No05/Parts/PromotionIdDescription.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PromotionIdDescription
  },
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
      created() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        const hash = this.$route.hash;
        this.$scrollTo(hash, 0, {});
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>
