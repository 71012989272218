<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>パラメータ引継ぎタグの<br class="sp-only">チェックをする</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>パラメータ引継ぎタグが正常に設置できているかチェックすること</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】パラメータ引継ぎタグを設置した記事LP、中間ページのソースコードを表示します。</h4>
        <p>Chrome、Firefox、Safari、Microsoft Edge：</p>
        <p>①検索バーに「view-source:記事LPや中間ページのURL」と入力して、[Enter]</p>
        <p>②[Ctrl]+[U]</p>
        <img :src="`${rt}/img/manual/google-cv/no06/no03/01.png`" alt />
        <h4>【２】ソースコードを全てコピーします。（[Ctrl]+[A]→[Ctrl]+[C]）</h4>
        <img :src="`${rt}/img/manual/google-cv/no06/no03/02.png`" alt />
        <h4>
          【３】「
          <router-link :to="`/tools02/${tool.codeKebab}/parameter-check`">パラメータチェック</router-link>」にアクセスしてください。
        </h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/parameter-check.png`" alt />
        <h4>【４】チェック対象を選択して、コピーしたソースコードを貼り付けて、「チェック」ボタンをクリックしてください。</h4>
        <img :src="`${rt}/img/manual/google-cv/no06/no03/03.png`" alt />
        <h4>【５】ポップアップが表示されるので、「OK」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no06/no03/04.png`" alt />
        </div>
        <p>エラーが検出された場合は、エラーが表示されますので、修正してください。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>エラー別対処方法</h3>
        <h4>■記事LP・サイト・中間ページ共通</h4>
        <table id="error_tool_tag_empty" class="error">
          <tr>
            <th>エラー名</th>
            <td>（ツール名）のタグを貼り付けてください。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>指定されたツール専用のタグを貼り付けてください。</td>
          </tr>
        </table>
        <table id="error_adtasukaru_tag_multiple" class="error">
          <tr>
            <th>エラー名</th>
            <td>アドタスカルのLPタグが2つ以上設置されています。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td v-if="tool.id === 8">アドタスカルのLPタグを2つ以上設置しているとパラメータ引き継ぎができない可能性があるので、1つだけ設置するように修正してください。<br />
            尚、同一記事で複数の広告に出稿している場合は、いずれか１つのLPタグだけで大丈夫です。<br />
            パラメータ引き継ぎはどのタグを使っても同じ挙動です。<br />
            Google広告用のLPタグを設置していてもYahoo!検索広告・Facebook広告のアクセスも正常にパラメータ引き継ぎされます。<br />
            <br />
            ただし、パラメータログに関しては、設置したタグのツール側に残る仕様になっていますのでご注意ください。<br />
            例えば、Facebook広告の記事にGoogle広告用のLPタグを設置した場合、Google広告のパラメータログにログが残ります。</td>
            <td v-else>アドタスカルのLPタグを2つ以上設置しているとパラメータ引き継ぎができない可能性があるので、1つだけ設置するように修正してください。<br />
            尚、同一記事でGoogle広告とYahoo!検索広告に出稿している場合は、どちらかのLPタグだけで大丈夫です。<br />
            パラメータ引き継ぎはどちらのタグを使っても同じ挙動です。<br />
            Google広告用のLPタグを設置していてもYahoo!検索広告のアクセスも正常にパラメータ引き継ぎされます。<br />
            <br />
            ただし、パラメータログに関しては、設置したタグのツール側に残る仕様になっていますのでご注意ください。<br />
            例えば、Yahoo!検索広告の記事にGoogle広告用のLPタグを設置した場合、Google広告のパラメータログにログが残ります。</td>
          </tr>
        </table>
        <table id="error_noreferrer_exist" class="error">
          <tr>
            <th>エラー名</th>
            <td>「rel="noreferrer"」を外してください。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              リンク先の設定で「新しいタブで開く」の設定（「target=”_blank”」）にしていると自動で「rel="noreferrer"」が付いてしまいます。<br />
              そのため、以下のどちらかで対処いただけますでしょうか？<br />
              <br />
              ①「新しいタブで開く」の設定（「target=”_blank”」）を外す<br />
              ②<a href="https://tanweb.net/2019/09/11/28539/" target="_blank">こちらの記事</a>を参考に「rel=”noreferrer”」を自動付与されなくする<br />
            </td>
          </tr>
        </table>
        <table id="error_targetblank_exist" class="error">
          <tr>
            <th>エラー名</th>
            <td>リンクの設定で「新しいタブで開く」の設定（「target="_blank"」）は非推奨となっています。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              リンクの設定時に「新しいタブで開く」の設定を外してください。<br />
              <br />
              WordPressのテーマによっては、記事で「新しいタブで開く」の設定をしていなくても全体のデフォルト設定で「新しいタブで開く」になっている場合があるのでご注意ください。
            </td>
          </tr>
        </table>
        <table id="error_parameter_empty" class="error">
          <tr>
            <th>エラー名</th>
            <td>パラメータ「〇〇」が付与されていません。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              ASPによっては、ご自身でプログラム登録の際にプログラムIDを決め、そのプログラムIDをアフィエイトリンクの後ろに付与しなければいけないものがございます。詳しくは
              <router-link :to="`/manual/${tool.codeKebab}`">マニュアル</router-link>の「初期設定（プログラム情報登録）」→「プログラム情報登録（該当ASP名）」をご覧ください。
            </td>
          </tr>
        </table>
        <table id="error_kaspersky_tag_exist" class="error">
          <tr>
            <th>エラー名</th>
            <td>カスペルスキータグを削除してください。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>ウイルスソフト「カスペルスキー」がインストールされているPCでサイト作成した場合、余計なコードが挿入されことがあります。このコードが挿入されるとサイト表示が遅延するので、削除してください。</td>
          </tr>
        </table>
        <table v-if="tool.codeKebab === 'facebook-cv'" id="error_adtasukaru_tag_old" class="error">
        </table>
        <table v-else id="error_adtasukaru_tag_old" class="error">
          <tr>
            <th>エラー名</th>
            <td>アドタスカルの（記事LP or 中間ページ）タグが旧バージョンです。最新の（記事LP or 中間ページ）タグに差し替えてください。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              旧バージョンのタグを使用していると、パラメータが引き継がれなかったり、リダイレクトがされなかったりといった不具合が生じますので、最新バージョンのタグに張り替えてください。詳しくは、「
              <router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link>」をご確認ください。
            </td>
          </tr>
        </table>

        <h4>■記事LP・サイト</h4>
        <table id="error_lp_tag_empty" class="error">
          <tr>
            <th>エラー名</th>
            <td>アドタスカルのLPタグがありません。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              アドタスカルのLPタグが正常に設置されていません。「
              <router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link>」をご確認ください。
            </td>
          </tr>
        </table>
        <table id="error_fb_tag_empty" class="error">
          <tr>
            <th>エラー名</th>
            <td>Facebookセーフティータグがありません。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>Facebookセーフティータグが正常に設置されていません。「 <a
            :href="`https://docs.google.com/spreadsheets/d/1eAVeCT7L-lt7s9XxxFlwJwppRqAwzowcQ16Oe7nsZQk/edit#gid=568204228`"
            target="_blank"
            rel="noopener noreferrer"
            >パラメータ引継ぎタグを設置する</a>」をご確認ください。</td>
          </tr>
        </table>
        <table id="error_lap_tag_empty" class="error">
          <tr>
            <th>エラー名</th>
            <td>LINE広告セーフティータグがありません。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>LINE広告セーフティータグが正常に設置されていません。「 <a
            :href="`https://docs.google.com/spreadsheets/d/1eAVeCT7L-lt7s9XxxFlwJwppRqAwzowcQ16Oe7nsZQk/edit#gid=568204228`"
            target="_blank"
            rel="noopener noreferrer"
            >パラメータ引継ぎタグを設置する</a>」をご確認ください。</td>
          </tr>
        </table>
        <table id="error_kaspersky_tag_exist" class="error">
          <tr>
            <th>エラー名</th>
            <td>カスペルスキータグを削除してください。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>ウイルスソフト「カスペルスキー」がインストールされているPCでサイト作成した場合、余計なコードが挿入されことがあります。このコードが挿入されるとサイト表示が遅延するので、削除してください。</td>
          </tr>
        </table>
        <h4>■中間ページ</h4>
        <table id="error_middlepage_tag_empty" class="error">
          <tr>
            <th>エラー名</th>
            <td>アドタスカルの中間ページタグがありません。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              アドタスカルの中間ページタグが正常に設置されていません。「
              <router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link>」をご確認ください。
            </td>
          </tr>
        </table>
        <table id="error_meta_refresh_exist" class="error">
          <tr>
            <th>エラー名</th>
            <td>meta refreshを使ったリダイレクトを削除してください。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              meta
              refreshを使ったリダイレクトを使うと、パラメータ引継ぎができません。アドタスカルで用意している中間ページ（リダイレクトページ）を使ってください。「
              <router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link>」をご確認ください。
            </td>
          </tr>
        </table>
        <table id="error_header_location_exist" class="error">
          <tr>
            <th>エラー名</th>
            <td>header locationを使ったリダイレクトを削除してください。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              header
              locationを使ったリダイレクトを使うと、パラメータ引継ぎができません。アドタスカルで用意している中間ページ（リダイレクトページ）を使ってください。「
              <router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link>」をご確認ください。
            </td>
          </tr>
        </table>

        <table id="error_affiliate_link_empty" class="error">
          <tr>
            <th>エラー名</th>
            <td>●個のアフィリリンクが設置されていません。</td>
          </tr>
          <tr>
            <th>対処法</th>
            <td>
              中間ページにアフィリリンクが設置されていません。アフィリリンクを設置してください。「
              <router-link :to="`/manual/${tool.codeKebab}/06/02`">パラメータ引継ぎタグを設置する</router-link>」をご確認ください。
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>これで、パラメータチェックは完了です。</p>
        <p>
          次は、「
          <router-link :to="`/manual/${tool.codeKebab}/06/04`">パラメータログを確認する</router-link>」に進んでください。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
    tools() {
      return this.$store.getters.tools;
    },
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      if (this.$route.hash !== "") {
        setTimeout(function(){
          const hash = this.$route.hash;
          this.$scrollTo(hash, 0, {});
        }.bind(this), 1000,);
      }
    });
  },
  methods: {
    scrl: function(){
    }
  },
};
</script>

<style lang="scss" scoped>
.error {
  width: 100%;
  border-collapse: collapse;
  margin-left: 20px;
  @media screen and (max-width: 768px){
    margin-left: 0!important;
  }
  & + .error {
    margin-top: 20px;
  }
  th,
  td {
    border: 1px solid;
    padding: 15px;
  }
  th {
    background-color: #e5e5e5;
    width: 20%;
  }
  td {
    width: 80%;
  }
}
</style>
