<template>
  <div class="tools02-pixels">
    <el-row>
      <el-col>
        <h2 v-if="aspId === '0'">カート・計測システム連携のお申し込み</h2>
        <h2 v-else>{{ aspData.aspName }}のお申し込み</h2>
      </el-col>
    </el-row>
    <Tools02CartSystem :aspData="aspData" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02CartSystem from "@/components/Tools02/CartSystem/Contents.vue";

export default {
  components: {
    Tools02CartSystem,
  },
  props: {
    aspId: String,
  },
  data() {
    return {
      aspData: {},
    };
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {
    aspId: {
      handler(newValue) {
        this.aspData = this.$store.getters.aspData(newValue);
      },
      immediate: true,
    },
  },
  methods: {},
};

</script>

<style lang="scss" scoped>
</style>
