<template>
  <div class="test-point-back">
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>アカウント</h4>
      </el-col>
      <el-col :span="20">
        <el-select v-model="accountId">
          <el-option
            v-for="accountData in accountList"
            :key="accountData.accountId"
            :label="
              accountData.accountName + '（' + accountData.accountCode + '）'
            "
            :value="accountData.accountId"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>clickIdType</h4>
      </el-col>
      <el-col :span="20">
        <el-select v-model="clickIdType">
          <el-option
            v-for="clickIdTypeData in clickIdTypeList"
            :key="clickIdTypeData.id"
            :label="clickIdTypeData.name"
            :value="clickIdTypeData.id"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>clickIdValue</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="clickIdValue"></el-input>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>conversionActionId</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionActionId"></el-input>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>conversionTime</h4>
      </el-col>
      <el-col :span="20">
        <el-date-picker
          v-model="conversionTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder=""
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="4">
        <h4>conversionValue</h4>
      </el-col>
      <el-col :span="20">
        <el-input v-model="conversionValue" type="number" min="0"></el-input>
      </el-col>
    </el-row>
    <div style="text-align: center">
      <el-button @click="send" type="primary">送信</el-button>
    </div>
    <h3>結果</h3>
    <div>{{ result }}</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TestPointBack",
  components: {},
  props: {},
  data() {
    return {
      clickIdTypeList: [
        { id: "0", name: "gclid" },
        { id: "1", name: "wbraid" },
        { id: "2", name: "gbraid" },
      ],
      accountId: "",
      clickIdType: "1",
      clickIdValue: "",
      conversionActionId: "",
      conversionTime: "",
      conversionValue: 0,

      //
      result: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    accountList() {
      return this.$store.getters["tools02/accounts"]("7");
    },
  },
  watch: {},
  methods: {
    send() {
      let params = new URLSearchParams();
      params.append("account_id", this.accountId);
      params.append("click_id_type", this.clickIdType);
      params.append("click_id_value", this.clickIdValue);
      params.append("conversion_action_id", this.conversionActionId);
      params.append("conversion_time", this.conversionTime);
      params.append("conversion_value", this.conversionValue);
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/media/google/test-upload-offline-conversion`,
          params
        )
        .then((response) => {
          console.log(response);
          this.result = response.data.result;
          return;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-add {
  margin-top: 20px;
}
</style>
