<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>すでにサブアカウント内のキャンペーンに<br class="pc-only">サブアカウントのコンバージョンが設定されている場合</h2>
        <p>すでにサブアカウント内のキャンペーンにサブアカウントのコンバージョンが設定されている場合、以下のようなエラーが発生します。</p>
        <img :src="`${rt}/img/manual/google-cv/no09/no07/01.png`" alt="" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>対策１</h3>
        <p>このサブアカウントのコンバージョンは、MCCで管理せずに、サブアカウントで管理します。</p>
        <p>この場合、サブアカウント情報をアドタスカルに登録しましょう。</p>
        <img :src="`${rt}/img/manual/google-cv/no09/no07/02.png`" alt="" />
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>対策２</h3>
        <p>サブアカウントの全キャンペーンで「アカウントで設定した目標を使用する」に変更します。</p>
        <p class="red2">※今まで使っていたコンバージョンデータが使えなくなります。そのため、新しく学習させる必要があるので、やる前に慎重に検討ください。最悪、最適化が崩れる可能性もあります。</p>
        <h4>【１】キャンペーンを開く→「設定」→「目標」→「アカウントで設定した目標を使用する」→「保存」の順にクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no07/03.png`" alt="" />
        <h4>【２】これを全キャンペーンで行います。</h4>
        <h4>【３】再度、MCCアカウントで、コンバージョンの設定を行います。</h4>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
