<template>
  <div class="tools02-parameter-manual-set-affiliate-link">
    <h3 class="back-gray">アフィリリンクの設置</h3>
    <template v-if="category01 === 3">
      <p>アフィリリンクを設置します。</p>
      <p>
        「外部」→URLにアフィリリンクを入力→「計測機能付きリンク」にチェック→「リンクを追加」
      </p>
      <img
        :src="`${rt}/img/tools02/parameter-manual3-10.png`"
        alt=""
        class="size"
      />
    </template>
    <template v-else>
      <p>記事LPやサイトにアフィリリンクを設置してください。</p></template
    >

    <!-- <div
      style="
        border: 1px solid #999;
        margin-left: 40px;
        padding: 15px;
        display: inline-block;
      "
    >
      &lt;a href="<span style="color: blue"
        >"https://t.felmat.net/fmcl?ak=XXXX"></span
      >公式サイト&lt;/a>
    </div> -->
    <h4>【注意事項1】</h4>
    <p>以下のASPは、プログラムIDの付与が必要になります。詳しい設定方法は、ASPごとのマニュアルをご覧ください。</p>
    <ul>
    <li>
      <router-link
      :to="`/manual/${tool.codeKebab}/05/09#from-manual1`"
      style="text-decoration: underline"
      target="_blank"
      rel="noopener noreferrer">
      サルクルー</router-link
    >
    </li>
    <li>
      <router-link
      :to="`/manual/${tool.codeKebab}/05/16#from-manual2`"
      style="text-decoration: underline"
      target="_blank"
      rel="noopener noreferrer">
      メディパートナー</router-link
    >
    </li>
    <li>
      <router-link
      :to="`/manual/${tool.codeKebab}/05/17#from-manual3`"
      style="text-decoration: underline"
      target="_blank"
      rel="noopener noreferrer">
      CATS</router-link
    >
    </li>
    <li>
      <router-link
      :to="`/manual/${tool.codeKebab}/05/19#from-manual1`"
      style="text-decoration: underline"
      target="_blank"
      rel="noopener noreferrer">
      AFAD（Self Leap、LADDER、インペリアル、AD-LAVI、M-Ads、EQS）</router-link
    >
    </li>
    <!-- <router-link :to="`/manual/${tool.codeKebab}/05/`" style="text-decoration: none" target="_blank" rel="noopener noreferrer">・AFAD（旧）（Valley Consulting）</router-link><br />-->
    </ul>
    <h4>【注意事項2】</h4>
    <p>「新しいタブで開く」の設定（「target=”_blank”」）は非推奨となっています。</p>

    <div class="memo"
    >
      <div>
        【OK】&lt;a href="<span style="color: blue"
          >https://t.felmat.net/fmcl?ak=XXXX</span
        >">公式サイト&lt;/a>
      </div>
      <div>
        【NG】&lt;a href="<span style="color: blue"
          >https://t.felmat.net/fmcl?ak=XXXX</span
        >" <span class="red bold"> target=”_blank”</span>>公式サイト&lt;/a>
      </div>
    </div>
      <p v-if="category01 === 1">WordPressのテーマによっては、記事で「新しいタブで開く」の設定をしていなくても<br />全体のデフォルト設定で「新しいタブで開く」になっている場合があるのでご注意ください。</p>
      <h4>【注意事項３】</h4>
      <p><span class="red2 bold">「rel="noreferrer"」「rel="noopener noreferrer"」</span>を付けないようにお願いします。</p>
      <div class="memo">
        <div>
          【OK】&lt;a href="<span class="blue">https://t.felmat.net/fmcl?ak=XXXX"</span>>公式サイト&lt;/a>
        </div>
        <div>
          【NG】&lt;a href="<span class="blue">https://t.felmat.net/fmcl?ak=XXXX"</span>
          <span class="red bold"> rel="noopener noreferrer"</span>>公式サイト&lt;/a>
        </div>
        <div>
          【NG】&lt;a href="<span class="blue">https://t.felmat.net/fmcl?ak=XXXX"</span>
          <span class="red bold"> rel="noreferrer"</span>>公式サイト&lt;/a>
        </div>
      </div>
      <template v-if="category01 === 1">
      <p>WordPressの場合、自動で付与される可能性があるので、必ずご確認ください。</p>
      <p>
        WordPressの場合、リンク先の設定で「新しいタブで開く」の設定（「target=”_blank”」）にしていると自動で「rel="noreferrer"」が付いてしまいます。<br />
        そのため、以下のどちらかで対処お願いします。
      </p>
      <p>
        ①「新しいタブで開く」の設定（「target=”_blank”」）を外す<br />
        ②<a href="https://tanweb.net/2019/09/11/28539/" target="_blank">こちら</a>の記事を参考に「rel=”noreferrer”」を自動付与されなくする（<a href="https://tanweb.net/2019/09/11/28539/" target="_blank">https://tanweb.net/2019/09/11/28539/</a>）
      </p>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02ParameterManualSetAffiliateLink",
  components: {},
  props: {
    normalTag: String,
    category01: Number,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.memo{
          border: 1px solid #999;
        margin-left: 40px;
        padding: 15px;
        display: inline-block;
        @media screen and (max-width: 768px) {
          margin-left: 0;
        }
}
.size{
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    width:100%;
  }
}
.blue{
  color: blue;
}
</style>
