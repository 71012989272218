<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>キャンペーン単位のコンバージョン設定（複数案件）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            ランキングサイトや比較サイトで複数案件を1つのキャンペーンで扱う場合の「キャンペーン単位のコンバージョン設定」を設定できるようにします
          </li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【設定方法】</h3>
        <h4>【１】設定したいキャンペーン→「設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no02/01.png`" alt="" />
        <h4>【２】「その他の設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no02/02.png`" alt="" />
        <h4>【３】「コンバージョン」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no02/03.png`" alt="" />
        <h4>
          【４】「このキャンペーンのコンバージョンアクションを選択する」にチェックをして、「コンバージョンアクションを選択」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no02/04.png`" alt="" />
        <h4>
          【５】ランキングサイトや比較サイトで扱っている案件のCVを全て選択します。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no02/05.png`" alt="" />
        <p>
          ※事前に案件ごとにオフラインコンバージョンを作成しておいてください。まだの場合は、「<router-link
            to="/manual/google-cv/03/02"
            >オフラインコンバージョンの作成</router-link
          >」をご覧ください。
        </p>
        <h4>
          【６】先程、作成したコンバージョングループが表示されたら、「保存」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no02/06.png`" alt="" />
        <h4>
          【７】一度、作成した「コンバージョンアクショングループ」は、次から選択することができます。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no02/07.png`" alt="" />
        <p>
          これで１つのキャンペーンで複数案件を扱うときの「キャンペーン単位でコンバージョン設定」は完了です。
        </p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
