<template>
  <div class="send-mails">
    <el-row>
      <el-col>
        <h2>メール一斉送信</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <SendMailSystem
          :mode="mode"
          :sendId="sendId"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SendMailSystem from "@/components/Ctrl/SendMails/SendMailSystem.vue";

export default {
  components: {
    SendMailSystem,
  },
  props: {
    mode: String,
    sendId: String,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
  },
};
</script>
