<template>
  <div class="tools02-get-token">
    <el-row>
      <el-col>
        <h2>Token取得</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <YahooAuth v-if="tool.codeKebab === 'yahoo-cv'"/>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import YahooAuth from "@/components/Tools02/Authorization/Yahoo.vue";

export default {
  components: {
    YahooAuth,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
