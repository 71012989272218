<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>キャンペーン単位のコンバージョン設定（設定：単品）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            記事LPなど、1つのキャンペーンで1案件だけ扱う場合の「キャンペーン単位のコンバージョン設定」を設定できるようにします
          </li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【設定方法】</h3>
        <p>実際の設定方法を解説していきます。</p>
        <h4>【１】設定したいキャンペーン→「設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no06/01.png`" alt="" />
        <h4>【２】「その他の設定」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no06/02.png`" alt="" />
        <h4>【３】「コンバージョン」をクリックします。</h4>
        <p>※お使いのGoogle広告アカウントによっては「コンバージョン」の表示がなく、「目標」という項目になってます。</p>
        <img :src="`${rt}/img/manual/google-cv/no11/no06/03.png`" alt="" />
        <h4>
          【４】「このキャンペーンのコンバージョンアクションを選択する」にチェックをして、「コンバージョンアクションを選択」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no06/04.png`" alt="" />
        <h4>【５】最適化したいCVを選択して、「保存」をクリックします。</h4>
        <ul>
          <li>MCVで最適化したい場合は、MCVを選択します</li>
          <li>最終CV（オフラインCV）で最適化した場合は、オフラインCVを選択します</li>
        </ul>
        <img :src="`${rt}/img/manual/google-cv/no11/no06/05.png`" alt="" />
        <p>
          ※事前に案件ごとにオフラインコンバージョンを作成しておいてください。まだの場合は、「<router-link
            to="/manual/google-cv/03/02"
            >オフラインコンバージョンの作成</router-link
          >」をご覧ください。
        </p>
        <p>
          比較サイトやランキングサイトで同一キャンペーンで複数案件を扱う場合は、「コンバージョンアクショングループ」を使うのがおすすめです。
        </p>
        <p>
          詳しい説明は「<router-link to="/manual/google-cv/11/02"
            >キャンペーン単位のコンバージョン設定（複数案件）</router-link
          >」をご覧ください。
        </p>
        <h4>
          【６】先程、選択したコンバージョンが表示されたら、「保存」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no11/no06/06.png`" alt="" />
        <p>これでキャンペーン単位でコンバージョン設定は完了です。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
