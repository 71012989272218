<template>
  <div class="manual">
    <router-view />
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  watch: {},
  created() {
    const hash = "#" + this.$route.query.hash;
    this.$nextTick(() => {
      this.$scrollTo(hash, 0, {});
    });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(h3) {
  margin-top: 50px;
  &:before {
    content: "▶";
  }
}
:deep(.v-scroll-to) {
  color: #00e;
  cursor: pointer;
}
:deep(.typeB) {
  margin: 20px 0;
}
:deep(.el-textarea) {
  margin: 20px 0;
}
img.border {
  width: 100%;
  box-sizing: border-box;
  border: solid 3px #ddd;
}
</style>
