<template>
  <div
    class="tools02-report-import-manual-send-conversion-event-system-yda-inputs"
  >
    <el-row type="flex" align="middle">
      <el-col :span="6">媒体（※）</el-col>
      <el-col :span="12"
        ><el-select
          v-model="conversionData.toolId"
          placeholder="選択してください。"
          clearable
        >
          <el-option
            v-for="toolData in toolListForSearch"
            :key="toolData.toolId"
            :label="toolData.mediaName"
            :value="toolData.toolId"
          ></el-option></el-select
      ></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="6">ASP（※）</el-col>
      <el-col :span="12"
        ><el-select
          v-model="conversionData.aspId"
          placeholder="選択してください。"
          clearable
        >
          <el-option
            v-for="aspData in asps"
            :key="aspData.aspId"
            :label="aspData.aspName"
            :value="aspData.aspId"
          ></el-option></el-select
      ></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="6">CV日時（※）</el-col>
      <el-col :span="12">
        <el-date-picker
          v-model="conversionData.conversionDatetime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder=""
          clearable
        >
        </el-date-picker
      ></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="6">プログラム名（※）</el-col>
      <el-col :span="12"
        ><el-input v-model="conversionData.promotionName" clearable
      /></el-col>
    </el-row>
    <el-row type="flex" align="middle">
      <el-col :span="6">squadbeyond_uid（※）</el-col>
      <el-col :span="12"
        ><el-input v-model="conversionData.squadbeyondUid" clearable
      /></el-col>
    </el-row>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="sendConversionEvent" type="primary">送信</el-button>
      </el-col>
    </el-row>
    <el-row v-if="resultData !== undefined">
      <el-col>
        <h3>送信結果</h3>
        <div v-if="resultData.eventReceived === true">送信成功！</div>
        <div v-else>{{ resultData.errorMessage }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tools02-report-import-manual-send-conversion-event-system-yda-inputs",
  components: {},
  props: {
    resultData: Object,
  },
  data() {
    return {
      tools02TagId: "",
      conversionData: {
        toolId: "",
        aspId: "",
        conversionDatetime: "",
        promotionName: "",
        squadbeyondUid: ""
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "asps", "apiErrorMessage"]),
    ...mapGetters("tools02", ["toolListInTopPageOrder"]),
    tools02TagList() {
      return this.$store.getters["tools02/allTagListInTool"](this.tool.id);
    },
    toolListForSearch() {
      let toolList = JSON.parse(
        JSON.stringify(this.$store.getters["tools02/toolListInTopPageOrder"])
      );

      //
      let newToolList = toolList.filter((toolData) => toolData.toolId !== "16");

      //
      return newToolList;
    },
  },
  methods: {
    sendConversionEvent() {
      //
      const pattern =
        /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
      if (pattern.test(this.conversionData.squadbeyondUid) === true) {
        // ok.
      } else {
        alert("squadbeyond_uidが不完全です。（8桁-4桁-4桁-4桁-12桁）");
        return;
      }
      console.log(this.conversionData);

      //
      const payload = {
        tools02TagId: this.tools02TagId,
        conversionData: this.conversionData,
      };
      this.$emit("sendConversionEvent", payload);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
