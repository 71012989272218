<template>
  <div class="asp-display-settings-register">
    <el-row>
      <el-col style="width: 170px">
        <a
          href="https://adtasukaru.wraptas.site/8d6d457da2df47558364a4582a0744d7"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-descriptions :column="1" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          ASP表示設定
        </template>
        <div>
          <el-radio v-model="displayAllAsps" :label="true"
            >全てのASPを表示する</el-radio
          ><br />
          <el-radio v-model="displayAllAsps" :label="false"
            >表示するASPを指定する</el-radio
          >
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <div v-if="displayAllAsps === false" class="asp-list-cover">
      <el-checkbox-group v-model="displayAspIdList">
        <div
          v-for="(aspData, index) in cartDiscardedAspList"
          :key="index"
          class="asp-cover"
        >
          <el-checkbox :label="aspData.aspId">{{
            aspData.aspName
          }}</el-checkbox>
        </div>
      </el-checkbox-group>
    </div>
    <div class="button-cover">
      <el-button @click="register" type="primary">変更</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "AspDisplaySettingsRegister",
  components: {
    Button2
  },
  props: {},
  data() {
    return {
      displayAllAsps: true,
      displayAspIdList: [],
    };
  },
  computed: {
    ...mapGetters([
      "rt",
      "cartDiscardedAspList",
      "apiErrorMessage",
      "userData",
    ]),
  },
  created() {
    let userData = JSON.parse(JSON.stringify(this.userData));
    this.displayAllAsps = userData.displayAllAsps;
    this.displayAspIdList = userData.displayAspIdList;
  },
  methods: {
    register() {
      let params = new URLSearchParams();
      params.append(
        "display_all_asps",
        this.displayAllAsps === true ? "1" : "0"
      );
      params.append(
        "display_asp_id_list",
        JSON.stringify(this.displayAspIdList)
      );
      this.$axios
        .post(`${this.rt}/l/api/user/register-asp-display-settings`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.$store.dispatch("setUserData");
            this.$router.go(-1);
          } else if (response.data.status === 400) {
            alert(response.data.message);
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push(`/login`);
          } else {
            alert(this.apiErrorMessage);
          }
          return;
        })
        .catch((error) => {
          console.log(error);
          return;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-descriptions) {
  margin-top: 20px;
  .el-descriptions__body {
    table {
      tr {
        th,
        td {
          padding: 13px 20px;
        }
        th {
          width: 20%;
        }
        td {
          .el-radio {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
.asp-list-cover {
  margin-top: 20px;
  border: solid 1px #aaa;
  padding: 15px 20px 25px;
  :deep(.el-checkbox-group) {
    display: flex;
    flex-wrap: wrap;

    .asp-cover {
      margin-top: 10px;
      margin-right: 20px;
      .el-checkbox {
        margin-right: 5px;
        .el-checkbox__label {
          padding-left: 7px;
        }
      }
    }
  }
}
.button-cover {
  margin-top: 15px;
  text-align: center;
}
</style>
