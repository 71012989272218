<template>
  <div class="manual-tools02-common-description">
    <p>
      対象ASP：「{{ mainAspName }}」<span
        v-for="(aspName, index) in aspNameList"
        :key="index"
        >「{{ aspName }}」</span
      >
    </p>
    <p class="red">
      以下、「{{ mainAspName }}」を例に解説をしていますが、<span
        v-for="(aspName, index) in aspNameList"
        :key="index"
        >「{{ aspName }}」</span
      >とも同じ手順になります。適宜、「{{
        mainAspName
      }}」→（該当するASP名）に読み替えて設定を行ってください。
    </p>
  </div>
</template>

<script>
export default {
  name: "manual-tools02-common-description",
  data() {
    return {
      mainAspName: "",
      aspNameList: [],
    };
  },
  props: {
    systemType: String,
  },
  created() {
    switch (this.systemType) {
      case "affilicode":
        this.mainAspName = "@tension";
        this.aspNameList = [
          "Tempura-Link",
          "twowin",
          "ゴリラテ",
          "Arrows",
          "i-counter",
          "クラウン",
          "ADRIP",
          "アシスタ",
          "Gain",
          "DecoTra",
          "performerz",
        ];
        break;
      case "linka":
        this.mainAspName = "";
        this.aspNameList = [];
        break;
      case "afad":
        this.mainAspName = "Self Leap";
        this.aspNameList = ["インペリアル","LADDER","AD-LAVI","M-Ads","EQS","AIGATE","Finebind"];
        break;
      case "cats":
        this.mainAspName = "akibito";
        this.aspNameList = ["MOG"];
        break;
      default:
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
