<template>
  <div class="news">
    <el-row>
      <el-col>
        <div class="first-line">
          <div
            @click="changeDust(false)"
            class="oshirase"
            :class="{enable: isDust==false}"
          >お知らせ</div>
          <div
            @click="changeDust(true)"
            class="dustbox"
            :class="{enable: isDust}"
          >ゴミ箱</div>
        </div>

      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <!--aaaa
        <div>{{list.release_datetime}}</div>
        <h2>{{list.title}}</h2>
        <div v-html="list.content2"></div>
        -->
        <table class="typeB">
          <!-- <transition name="toggle1"> -->
          <!--            v-show="view_confirm == true || news.confirm == 0"-->
          <tr
            v-for="(news, index) in view_list"
            :key="index"
            :class="{ opend: news.opend == 1 }"
          >
            <td>{{ news.release_date }}</td>
            <td>
              <router-link
                :to="`/news-detail/${news.id}`"
                :class="{ unique: news.post_id == 0 }"
              >{{ news.title }}</router-link>
            </td>
            <td>
              <el-button
                v-show="news.confirm == 0"
                size="mini"
                v-if="news.user_id != 0"
                @click="confirm(index, news.id,1)"
              ><i class="el-icon-delete"> ゴミ箱へ</i></el-button>
              <el-button
                v-show="news.confirm == 1"
                size="mini"
                v-if="news.user_id != 0"
                @click="confirm(index, news.id,0)"
              ><i class="el-icon-refresh-left"> 戻す</i></el-button>
            </td>
          </tr>
          <!-- </transition> -->
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isDust: false, //お知らせ有効から開始
      view_confirm: false,
      list: {
        release_datetime: "",
        title: "",
        content2: "",
      },
      view_list: [],
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    }
  },
  mounted: function () {
    console.log("mounted");
    this.getList();
  },
  created: function () {
    console.log("created");
  },
  methods: {
    getList: function () {
      this.$axios
        .get(this.rt + "/api/getUsersPostList.php?mode=all")
        .then((res) => {
          console.log(res);
          this.list = res.data.list;
          this.list = this.list.map(function (v) {
            return v;
          });

          this.makeList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm: function (index, id, val) {
      console.log("index:" + index + " id:" + id);
      
      //表示を消す
      this.list[index].confirm = val;

      //データベースのconfirm=1
      let params = new URLSearchParams();
      params.append("id", id);
      params.append("val", val);
      this.$axios
        .post(`${this.rt}/api/confirmPost.php`, params)
        .then((res) => {
          console.log(res);
          this.getList();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeDust: function (val) {
      this.isDust = val;
      this.makeList();
    },
    makeList: function () {
      console.log("makeList");
      if (this.isDust) {
        //ゴミ箱有効
        this.view_list = this.list.filter(function (v) {
          return v.confirm == "1"; //削除済みを選択
        });
      } else {
        //お知らせ有効
        this.view_list = this.list.filter(function (v) {
          return v.confirm == "0"; //未削除を選択
        });
      }

      console.log(this.view_list.length);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-delete,
.el-icon-refresh-left {
  font-size: 1.5em;
}
h2 {
  border-bottom: solid 1px #ccc;
  margin-bottom: 40px;
}

.first-line {
  display: flex;
  justify-content: center;
  .oshirase,
  .dustbox {
    cursor: pointer;
    width: 50%;
    background-color: #aaaaaa;
    color: white;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid #4d4d4d;
    padding: 10px 0;
  }
  .dustbox {
    border-left: none;
  }
  .enable {
    background-color: #fff;
    color: black;
  }
}
.typeB {
  width: 100%;
  tr {
    font-weight: bold;
    td {
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        a {
          color: var(--black);
          text-decoration: none;
        }
        a.unique {
          color: #4595f9;
        }
      }
      &:nth-child(3) {
        width: 10%;
        text-align: center;
      }
    }
  }
  tr.opend {
    font-weight: normal;
    background-color: #eee;
  }
}
</style>
