<template>
  <div class="tools02-api-histories">
    <el-row>
      <el-col>
        <h2>{{ toolData.mediaName }} API送信履歴</h2>
      </el-col>
      <el-col style="width: 170px">
        <a
          :href="toolData.manualData.apiHistoryManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <GoogleLogTable
          v-if="toolData.codeKebab === 'google-cv'"
          :pageType="'default'"
        />
        <FacebookLogTable
          v-else-if="toolData.codeKebab === 'facebook-cv'"
          :pageType="'default'"
        />
        <Tools02ConversionApiHistoryList
          v-else-if="
            toolData.codeKebab === 'tiktok-cv' ||
            toolData.codeKebab === 'line-cv' ||
            toolData.codeKebab === 'yda-cv' ||
            toolData.codeKebab === 'squadbeyond-cv'
          "
          :toolId="toolId"
          :pageType="'default'"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";
import GoogleLogTable from "@/components/Tools02/ApiHistories/GoogleLogTable.vue";
import FacebookLogTable from "@/components/Tools02/ApiHistories/FacebookLogTable.vue";
import Tools02ConversionApiHistoryList from "@/components/Tools02/ApiHistories/Tools02ConversionApiHistoryList.vue";

export default {
  components: {
    Button2,
    GoogleLogTable,
    Tools02ConversionApiHistoryList,
    FacebookLogTable,
  },
  props: {
    toolId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(.table-cover) {
  // overflow: scroll;
  table.total_tbl {
    width: 5000px;
    .word-break {
      word-break: break-all;
      margin: 0 auto;
    }
  }
}
:deep(.parameter) {
  width: 300px;
  word-break: break-all;
  margin: 0 auto;
}
</style>