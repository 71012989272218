<template>
  <div v-loading="loading" class="affiliate-status-registrations-and-plans">
    <el-row>
      <el-col>
        <el-row>
            <table class="af-pop">
              <tr>
                <th>登録者数</th>
                <td>{{ totalOfRegistrations | localeNum }}人</td>
              </tr>
            </table>
        </el-row>
        <el-row>
         
            <table class="af-pop">
              <tr>
                <th>自動更新設定ON</th>
                <td>{{ totalOfSubscriptions | localeNum }}人</td>
              </tr>
              <tr v-for="(planData, index) in planDataList" :key="index">
                <th>{{ planData.planName }}（{{ planData.priceWithTax | localeNum }}円）</th>
                <td>{{ planData.numberOfSubscriptions | localeNum }}人</td>
              </tr>
            </table>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateStatusRegistrationsAndPlans",
  components: {},
  props: {
    dateRange: Array
  },
  data() {
    return {
      loading: true,
      totalOfRegistrations: 0,
      totalOfSubscriptions: 0,
      planDataList: []
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  watch: {
    dateRange: {
      handler(newDateRange) {
        this.getRegistrationsAndPlans(newDateRange);
      },
      immediate: true,
    },
  },
  methods: {
    getRegistrationsAndPlans(dateRange) {
      this.loading = true;
      this.$axios
        .get(`${this.rt}/api/affiliate/get-registrations-and-plans.php?date_range=${encodeURIComponent(JSON.stringify(dateRange))}`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.totalOfRegistrations = res.data.totalOfRegistrations;
            this.totalOfSubscriptions = res.data.totalOfSubscriptions;
            this.planDataList = res.data.planDataList;
            this.loading = false;
            return;
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push('/login');
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table.af-pop {
  width: 50%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  tr {
    th {
      width: 70%;
    }
  }
}
</style>
