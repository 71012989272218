<template>
  <div class="tools02-initial-setting-contents">
    <Tools02 />
  </div>
</template>

<script>
import Tools02 from "@/components/Tools02/InitialSetting/Contents/Tools02.vue";

export default {
  name: "Tools02InitialSettingContents",
  components: {
    Tools02,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
:deep(p) {
  text-align: center;
  margin-top: 3px;
}
:deep(dt) {
  padding: 20px;
  font-weight: bold;
  font-size: 17px;
}
:deep(dd) {
  padding: 7px;
}
:deep(.el-checkbox) {
  padding-right: 8px;
}
:deep(.tutorial-unit) {
  & + & {
    margin-top: 30px;
  }
  h3 {
    background-color: var(--o-blue);
    color: white;
    font-size: 16px;
    text-align: center;
    width: 50%;
    border-radius: 5px 5px 0 0;
    padding: 5px 0;
    margin: 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      th,
      td {
        font-size: 14px;
        border: 1px solid #ccc;
        border-right: none;
        border-left: none;
        padding: 7px 0 7px 20px;
        text-align: left;
      }
    }
    thead {
      tr {
        th {
          color: var(--o-blue);
        }
      }
    }
    tbody {
      tr {
        th {
          color: var(--o-blue);
          border-right: 1px solid #ccc;
        }
      }
    }
  }
}
</style>
