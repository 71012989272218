<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>サブアカウントのコンバージョンをMCCで管理する</h2>
        <h4>【１】MCCの管理画面TOPにアクセスします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no05/01.png`" alt="" />
        <h4>【２】「このMCC内で共有」をクリックします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no05/02.png`" alt="" />
        <h4>【３】同様に設定したいサブアカウントを全て行います。</h4>
        <img :src="`${rt}/img/manual/google-cv/no09/no05/03.png`" alt="" />
        <p>※これでMCCアカウントで<span class="red2">全てのサブアカウントのコンバージョンを管理する</span>ことができます</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>よくあるエラー①</h4>
        <h5>MCCのサブアカウントの設定で「このMCC内で共有」が選択できないときの対処法</h5>
        <router-link to="/manual/google-cv/09/06">MCCのサブアカウントの設定で「このMCC内で共有」が選択できないときの対処法</router-link>
        <img :src="`${rt}/img/manual/google-cv/no09/no05/04.png`" alt="" />
        <h4>よくあるエラー②</h4>
        <p>※以下のエラーが出た場合は、<router-link to="/manual/google-cv/09/07">こちらのマニュアル</router-link>を参照ください。</p>
        <img :src="`${rt}/img/manual/google-cv/no09/no05/05.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
</style>
