<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>【よくある質問】<br class="sp-only">キャンペーンにオフラインCVが反映されません。</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <p>【よくある質問】キャンペーンにオフラインCVが反映されません。</p>
        <p>
          様々な要因が考えられますので、『確認１』から確認をお願いいたします。
        </p>

        <h4 class="gray-back">
          確認１：成果データを{{
            tool.mediaName
          }}にアップロードしてから１２時間以上経過していますか？
        </h4>
        <p>
          <span class="bold">→YES</span><br />
          <span class="bold">『確認２』</span>に進んでください。
        </p>
        <p>
          <span class="bold">→NO</span><br />
          キャンペーンに反映されるまでに３時間～半日ほど掛かります。<br />
          アップロードした直後でしたら、一旦、様子見してください。
        </p>
        <h4 class="gray-back">
          確認２：コンバージョンページに反映されていますか？
        </h4>
        <p>
          <span class="bold">【確認方法】</span><br />
          「ツールと設定」→「測定」→「コンバージョン」の順にクリックしてください。
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/01.png`" alt="" />
        <p class="top">
          コンバージョンの概要ページが表示されます。<span class="red bold"
            >期間を広めに設定してください。</span
          >（理由はコンバージョンの発生日時ではなく、広告がクリックされた日時で反映されるからです。）
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/02.png`" alt="" />
        <p class="top">
          少し下にスクロールして「インポートされた見込み顧客」or「コンバージョンに至った見込み顧客」を見てください。該当のコンバージョンを探して、「すべてのコンバージョン」にアップロードしたCV数が反映されていますか？
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/03.png`" alt="" />
        <img :src="`${rt}/img/manual/google-cv/no07/no21/11.png`" alt="" />

        <p class="top">
          <span class="bold">→YES</span><br />
          正常にアップロードされています。キャンペーンでのコンバージョンの確認方法に誤りがありますので、<span
            class="bold"
            >『確認３』</span
          >に進んでください。
        </p>
        <p>
          <span class="bold">→NO</span><br />
          キャンペーンに反映されるまでに３時間～半日ほど掛かります。<br />
          アップロードした直後でしたら、一旦、様子見してください。
        </p>
        <h4 class="gray-back">
          確認３：キャンペーンでのすべてのコンバージョンに反映されていますか？
        </h4>
        <p class="bold">【確認方法】</p>
        <p>
          キャンペーンの一覧を開きます。<br />
          <span class="red bold">期間を広めに設定してください。</span
          >（理由はコンバージョンの発生日時ではなく、広告がクリックされた日時で反映されるからです。）
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/04.png`" alt="" />
        <p class="top">
          すべてのコンバージョンが表示されていない場合は、「すべてのコンバージョン」を表示させてください。
        </p>
        <p>「表示項目」→「表示項目の変更」</p>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no07/no21/05.png`" alt="" />
        </div>
        <p class="top">
          「コンバージョン」→「すべてのコンバージョン」を選択→「適応」
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/06.png`" alt="" />

        <p class="top">「すべてのコンバージョン」が追加されます。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/07.png`" alt="" />

        <p class="top">
          次に、コンバージョンアクションごとのデータを表示します。
        </p>
        <p>
          「分類」→「コンバージョン」→「コンバージョン
          アクション」に順にクリックしてください。
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/08.png`" alt="" />

        <p class="top">
          キャンペーンにコンバージョンアクションごとの数値が表示されますので、<br />
          オフラインコンバージョンの「すべてのコンバージョン」にCV数が反映されていますか？
        </p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/09.png`" alt="" />

        <p class="top">
          <span class="bold">→YES</span><br />
          正常にキャンペーンにも成果が反映されています。
        </p>
        <p>
          <span class="bold">→NO</span><br />
          正常に成果がアップロードされていません。<span class="bold"
            >『確認４』</span
          >に進んでください。
        </p>

        <h4 class="gray-back">
          確認４：再度、{{
            tool.mediaName
          }}に成果をアップロードしてステータスはどうなりましたか？
        </h4>
        <p>
          <span class="bold">【手順】</span><br />
          再度、{{ tool.mediaName }}に成果をアップロードします。
        </p>

        <p>
          まず、以下のマニュアルを参考に、{{
            tool.mediaName
          }}アップロード用データをアドタスカルからエクスポートしてください。
        </p>

        <h5 style="margin-left: 20px">
          ▼{{ tool.mediaName }}アップロード用データをエクスポート
        </h5>
        <router-link :to="`/manual/${tool.codeKebab}/07/15`">
          <p v-if="tool.codeKebab === 'google-cv'">
            https://tools.adtasukaru.com/manual/google-cv/07/15
          </p>
          <p v-else-if="tool.codeKebab === 'yahoo-cv'">
            https://tools.adtasukaru.com/manual/yahoo-cv/07/15
          </p>
        </router-link>
        <p>
          次に、以下のマニュアルを参考に、{{
            tool.mediaName
          }}にアップロードしてください。
        </p>
        <h5 style="margin-left: 20px">
          ▼{{ tool.mediaName }}にアップロードする
        </h5>
        <router-link :to="`/manual/${tool.codeKebab}/07/16`">
          <p v-if="tool.codeKebab === 'google-cv'">
            https://tools.adtasukaru.com/manual/google-cv/07/16
          </p>
          <p v-else-if="tool.codeKebab === 'yahoo-cv'">
            https://tools.adtasukaru.com/manual/yahoo-cv/07/16
          </p>
        </router-link>
        <p>アップロードしたときに、ステータスを確認してください。</p>
        <img :src="`${rt}/img/manual/google-cv/no07/no21/10.png`" alt="" />

        <table>
          <tr>
            <th>ステータス</th>
            <th>結果</th>
            <th class="last">ネクストアクション</th>
          </tr>
          <tr>
            <td>受理</td>
            <td>元々、アップロードできていませんでした。</td>
            <td class="last">
              １２時間後に、成果が管理画面に反映されるか確認してください。
            </td>
          </tr>
          <tr>
            <td>タイムスタンプおよび{{ tool.clickIdName }}・・・</td>
            <td>
              正常にアップロードできていますが、まだ、管理画面に反映されていません。
            </td>
            <td class="last">もう少し時間をおいて再度確認してみてください。</td>
          </tr>
        </table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool", "tools"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.gray-back {
  background-color: #eef1f2;
  border-radius: 10px;
  padding: 10px 20px;
}
.bold {
  font-weight: bold;
}
table {
  text-align: left;
  border-collapse: collapse;
  margin: 50px 0;
  tr {
    th,
    td {
      border: solid 1px var(--gray);
      padding: 10px 5px;
    }
    .last {
      width: 40%;
    }
  }
}
.top {
  margin-top: 50px;
}
</style>
