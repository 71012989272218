<template>
  <div class="ctrl-active-confirm">
    <el-row>
      <el-col>
        <p style="text-align:center;">※前日の全10分間の平均値と比べ、1％以下の数値になっている場合、赤文字になります。<br>また、赤文字が1つでもあると、行全体が黄色表示となります。</p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-tabs type="border-card">
          <el-tab-pane label="ポップアップ">
            <el-date-picker
              v-model="date01"
              type="date"
              placeholder="Pick a day"
              value-format="yyyy-MM-dd"
              style="margin: 15px auto;"
            />
            <table class="typeA stripe">
              <thead>
                <tr>
                  <th>時間（10分間）</th>
                  <th>アクセス数</th>
                  <th>広告表示回数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData01" :key="index" :class="{ errorYellow: item.errorA === '1' || item.errorB === '1' }">
                  <td>
                    {{item.start}}
                  </td>
                  <td :class="{ errorRed: item.errorA === '1' }">
                    {{item.access_amount}}
                  </td>
                  <td :class="{ errorRed: item.errorB === '1' }">
                    {{item.impression_amount}}
                  </td>
                </tr>
              </tbody>
            </table>

          </el-tab-pane>
          <el-tab-pane label="オーバーレイ（ボタン）">
            <el-date-picker
              v-model="date02"
              type="date"
              placeholder="Pick a day"
              value-format="yyyy-MM-dd"
              style="margin: 15px auto;"
            />
        
            <table class="typeA stripe">
              <thead>
                <tr>
                  <th>時間（10分間）</th>
                  <th>アクセス数</th>
                  <th>広告表示回数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData02" :key="index" :class="{ errorYellow: item.errorA === '1' || item.errorB === '1' }">
                  <td>
                    {{item.start}}
                  </td>
                  <td :class="{ errorRed: item.errorA === '1' }">
                    {{item.access_amount}}
                  </td>
                  <td :class="{ errorRed: item.errorB === '1' }">
                    {{item.impression_amount}}
                  </td>
                </tr>
              </tbody>
            </table>

          </el-tab-pane>
          <el-tab-pane label="オーバーレイ（テキスト）">
            <el-date-picker
              v-model="date03"
              type="date"
              placeholder="Pick a day"
              value-format="yyyy-MM-dd"
              style="margin: 15px auto;"
            />
        
            <table class="typeA stripe">
              <thead>
                <tr>
                  <th>時間（10分間）</th>
                  <th>アクセス数</th>
                  <th>広告表示回数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData03" :key="index" :class="{ errorYellow: item.errorA === '1' || item.errorB === '1' }">
                  <td>
                    {{item.start}}
                  </td>
                  <td :class="{ errorRed: item.errorA === '1' }">
                    {{item.access_amount}}
                  </td>
                  <td :class="{ errorRed: item.errorB === '1' }">
                    {{item.impression_amount}}
                  </td>
                </tr>
              </tbody>
            </table>

          </el-tab-pane>
          <el-tab-pane label="ガジェット">
            <el-date-picker
              v-model="date04"
              type="date"
              placeholder="Pick a day"
              value-format="yyyy-MM-dd"
              style="margin: 15px auto;"
            />
        
            <table class="typeA stripe">
              <thead>
                <tr>
                  <th>時間（10分間）</th>
                  <th>アクセス数</th>
                  <th>広告表示回数</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in tableData04" :key="index" :class="{ errorYellow: item.errorA === '1' || item.errorB === '1' }">
                  <td>
                    {{item.start}}
                  </td>
                  <td :class="{ errorRed: item.errorA === '1' }">
                    {{item.access_amount}}
                  </td>
                  <td :class="{ errorRed: item.errorB === '1' }">
                    {{item.impression_amount}}
                  </td>
                </tr>
              </tbody>
            </table>

          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  data() {
    return {
      tableData01: [],
      tableData02: [],
      tableData03: [],
      tableData04: [],
      date01: '',
      date02: '',
      date03: '',
      date04: '',
    }
  },
  components: {
  },
  created() {
  },
  mounted() {
    this.getActiveLog(1, 0);
    this.getActiveLog(2, 0);
    this.getActiveLog(3, 0);
    this.getActiveLog(4, 0);
  },
  methods: {
    getActiveLog(tool_id, date) {
      let params = new URLSearchParams();
      params.append("tool_id", tool_id);
      params.append("date", date);
      this.$axios
        .post(this.$store.getters.rt + "/api/ctrl/getActiveLog.php", params)
        .then(res => {
          console.log(res);
          switch (tool_id) {
            case 1:
              this.tableData01 = res.data.list;
              this.date01 = res.data.date;
              break;
            case 2:
              this.tableData02 = res.data.list;
              this.date02 = res.data.date;
              break;
            case 3:
              this.tableData03 = res.data.list;
              this.date03 = res.data.date;
              break;
            case 4:
              this.tableData04 = res.data.list;
              this.date04 = res.data.date;
              break;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  watch: {
    date01() {
      this.getActiveLog(1, this.date01);
    },
    date02() {
      this.getActiveLog(2, this.date02);
    },
    date03() {
      this.getActiveLog(3, this.date03);
    },
    date04() {
      this.getActiveLog(4, this.date04);
    },
  }
}
</script>

<style lang="scss" scoped>
.el-tab-pane {
  table.typeA {
    tr.errorYellow {
      background: yellow!important;
    }
    td.errorRed {
      color: red!important;
    }
  }
}
</style>
