<template>
  <div class="affiliate-ui-date-range-picker">
    <el-row>
      <div class="cover">
        <div class="min-cover">
        <el-button @click="changeDateRange('today')" type="primary"
          >本日</el-button
        >
        <el-button @click="changeDateRange('yesterday')" type="primary"
          >昨日</el-button
        >
        <el-button @click="changeDateRange('thisMonth')" type="primary"
          >当月</el-button
        >
        <el-button @click="changeDateRange('lastMonth')" type="primary"
          >先月</el-button
        >
        <el-button @click="changeDateRange('all')" type="primary"
          >全期間</el-button
        >
        </div>
        <el-date-picker
          v-model="localDateRange"
          type="daterange"
          align="right"
          start-placeholder="開始日"
          end-placeholder="終了日"
          format="yyyy-MM-dd"
          class="data"
        ></el-date-picker>
      </div>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AffiliateUiDateRangePicker",
  components: {},
  props: {
    dateRange: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    localDateRange: {
      get() {
        return this.dateRange;
      },
      set(newValue) {
        this.$emit("update:dateRange", newValue);
      },
    },
  },
  watch: {},
  methods: {
    changeDateRange(term) {
      this.localDateRange = this.g_getDateRange(term);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  @media screen and (max-width: 768px) {
    margin: 0 5px 5px 0 !important;
    // margin-right: 5px;
  }
}

.cover {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .min-cover{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: 5px;

  }
}
</style>
