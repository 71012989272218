var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools02-initial-setting-contents-tools02"},[_c('div',{staticClass:"initial-table-cover"},_vm._l(([
        [_vm.tutorialList[0], _vm.tutorialList[1], _vm.tutorialList[2]],
        [_vm.tutorialList[3], _vm.tutorialList[4], { title: '特典' }],
      ]),function(tutorialUnit,tutorialIndex){return _c('div',{key:tutorialIndex,staticClass:"initial-table",attrs:{"span":12}},_vm._l((tutorialUnit),function(categoryData,categoryIndex){return _c('div',{key:categoryIndex,staticClass:"tutorial-unit"},[(
            _vm.tool.codeKebab === 'yahoo-cv' &&
            tutorialIndex === 1 &&
            categoryIndex === 0
          )?_c('div'):(tutorialIndex === 1 && categoryIndex === 2)?_c('div',[(_vm.conditionGroupedPrivilegeList.length !== 0)?[_c('h3',[_vm._v(_vm._s(categoryData.title))]),_c('div',_vm._l((_vm.conditionGroupedPrivilegeList),function(conditionData,conditionIndex){return _c('table',{key:conditionIndex},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(conditionData.conditionName))])])]),_vm._l((conditionData.privilegeList),function(privilegeData,privilegeIndex){return _c('tbody',{key:privilegeIndex},[(privilegeData.acquired === '1')?_c('tr',{staticClass:"aquired"},[_c('th',{staticClass:"menu-no"},[_vm._v("達成")]),_c('td',{on:{"click":function($event){return _vm.g_toPrivilegePage(privilegeData.privilegeId)}}},[_c('span',[_vm._v(_vm._s(privilegeData.privilegeName))])])]):_c('tr',{staticClass:"unaquired"},[_vm._m(0,true),_c('td',[_vm._v(_vm._s(privilegeData.privilegeName))])])])})],2)}),0)]:_vm._e()],2):_c('div',[_c('h3',[_vm._v(_vm._s(categoryData.title))]),_vm._l((categoryData.sectionList),function(sectionData,sectionIndex){return _c('table',{key:sectionIndex},[_c('thead',[_c('tr',[_c('th',{attrs:{"colspan":"2"}},[_vm._v(_vm._s(sectionData.title))])])]),_c('tbody',_vm._l((sectionData.manualList),function(manualData,manualIndex){return _c('tr',{key:manualIndex},[_c('th',{staticClass:"menu-no"},[_c('el-checkbox',{model:{value:(manualData.checked),callback:function ($$v) {_vm.$set(manualData, "checked", $$v)},expression:"manualData.checked"}}),_vm._v(_vm._s(manualData.index)+" ")],1),_c('td',[(manualData.url === undefined)?_c('router-link',{attrs:{"to":`/manual/${_vm.tool.codeKebab}/${manualData.cate01}/${manualData.cate02}`}},[_vm._v(" "+_vm._s(manualData.title)+" ")]):_c('a',{attrs:{"href":manualData.url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(manualData.title)+" ")])],1)])}),0)])})],2)])}),0)}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"menu-no"},[_c('span',[_vm._v("未達")])])
}]

export { render, staticRenderFns }