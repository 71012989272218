<template>
  <div class="tools02-parameter-manual-set-lp-tag-in-wp">
    <h3 class="back-gray">中間ページタグの設置</h3>
    <el-row>
      <template v-if="category01 === 3">
        <el-col>
          <p>
            Squadbeyond上の中間ページを使用するとパラメータ引き継ぎができないため、Beyondページ上で
            アドタスカルの中間ページを作成します。
          </p>

          <h4>【1】「Beyondページ作成」をクリックします</h4>
          <img
            :src="`${rt}/img/tools02/parameter-manual3-11.png`"
            alt=""
            style="width: 95%"
          />
          <h4>【2】HTMLエディターでBeyondページを作成します。</h4>
          <table>
            <tr>
              <th>Beyondページ名</th>
              <td>：任意の名前を入力</td>
            </tr>
            <tr>
              <th>編集タイプ</th>
              <td>：HTMLエディター</td>
            </tr>
            <tr>
              <th>配信URL設定</th>
              <td>：任意のURLを入力</td>
            </tr>
            <tr>
              <th class="small">トラッキング項目の計測ツール・ASP</th>
              <td>：未選択</td>
            </tr>
          </table>
          <div style="margin-top: 50px">
            <img
              :src="`${rt}/img/tools02/parameter-manual3-12.png`"
              alt=""
              class="left150"
            />
          </div>
          <h4>【３】「作成する」をクリック</h4>
          <img
            :src="`${rt}/img/tools02/parameter-manual3-13.png`"
            alt=""
            class="left150"
          />
        </el-col>
        <el-col>
          <h4>
            【4】Versionの編集画面が表示されるので、「HTML」「Javascript
            Head」「Javascript Body」を設定します。
          </h4>
          <h4 class="left-space2">■HTML</h4>
          <p>自由に変更してもOKです。</p>
          <el-input
            class="copy-tag-area"
            type="textarea"
            :autosize="{ minRows: 1 }"
            v-model="textLinkTagString"
            onclick="this.select();"
          />
          <h4 class="left-space2">■Javascript Head</h4>
          <p>
            任意ですが{{
              tool.mediaName
            }}のタグを設置しましょう。他の媒体のタグを設置しても大丈夫です。
          </p>
          <el-input
            v-show="tool.codeKebab === 'google-cv'"
            class="copy-tag-area"
            type="textarea"
            :autosize="{ minRows: 1 }"
            v-model="mediaTagString"
            onclick="this.select();"
          />
          <h4 class="left-space2">■Javascript Body</h4>
          <p>
            「★アフィリリンク★」にアフィリエイトリンクを設置して、「Javascript
            Body」に設置してください。
          </p>
          <el-input
            class="copy-tag-area"
            type="textarea"
            :autosize="{ minRows: 1 }"
            v-model="middlePageTagString"
            onclick="this.select();"
          />
        </el-col>
        <el-col>
          <h4>【注意事項1】</h4>
          <p>
            以下のASPは、プログラムIDの付与が必要になります。詳しい設定方法は、ASPごとのマニュアルをご覧ください。
          </p>
          <ul>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/09#from-manual1`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >サルクルー</router-link
              >
            </li>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/16#from-manual2`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >メディパートナー</router-link
              >
            </li>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/17#from-manual3`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >CATS</router-link
              >
            </li>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/19#from-manual1`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >AFAD（Self
                Leap、LADDER、インペリアル、AD-LAVI、M-Ads、EQS）</router-link
              >
            </li>
          </ul>
          <h4>
            【5】最終的に、このような形になれば完了です。「更新」をクリックしてください。
          </h4>
          <img
            :src="`${rt}/img/tools02/parameter-manual3-14.png`"
            alt=""
            style="width: 95%"
          />

          <h4>
            【6】万が一、アドタスカルのサーバーに障害が発生したときでも中間ページからアフィリリンクに遷移するようにしています。
          </h4>
          <p>
            サーバーから3秒経過しても、応答がない場合は、強制的にアフィリリンクに遷移します。
          </p>
          <p>
            その場合、パラメータは引き継がれませんが、リファラでパラメータを取得できるので、リカバリー可能です。
          </p>
          <img :src="`${rt}/img/tools02/parameter-manual3-15.png`" alt="" />
          <p>
            デフォルトでは3000（3秒）になっていますが、エンドユーザーの回線次第ではページの読み込み完了に3秒以上掛かる場合もあります。<br />
            その場合は、障害が発生していなくても強制的にアフィリリンクに遷移し、パラメータ引き継ぎがされません。
          </p>
          <p>
            頻繁にパラメータ引き継ぎがされない場合は、3000を3500や4000にするなど秒数を調整してください。
          </p>
          <p>
            逆に3000（3秒）は長いと感じた場合は、短くしても大丈夫です。<br />
            ただ、短くしすぎると、通常時にパラメータ引き継ぎができていない状態で遷移することがあるので、その場合は、調整してください。
          </p>
          <p>
            お使いのサーバーやエンドユーザーの回線速度などによって変化するため一概に言えませんが、通常時は、0.1秒～1.2秒で遷移していました。
          </p>
          <p style="margin-top: 40px">
            <b>補足</b><br />
            一般的な中間ページとして「meta
            refreshを利用した0秒リダイレクト」を利用している方が多いと思います。
          </p>

          <div class="left-space2 note">
            &lt;meta http-equiv="refresh"
            content="0;URL=https://www.sample.com">
          </div>
          <p>
            0秒でリダイレクトさせるとなっていますが、実際は、ページの読み込みが完了してからリダイレクトが開始されます。<br />
            そして、ページの読み込み完了までに0.3秒～1.6秒ほど掛かります。
          </p>
          <p>
            そのため、アドタスカルの中間ページを使うことで、リダイレクトの遅延が発生することはないのでご安心ください。
            <br />むしろ、早くなるケースが多いと思います。
          </p>
        </el-col>
        <el-col>
          <h4>【7】「配信URL」ボタンをクリックします。</h4>
          <img
            :src="`${rt}/img/tools02/parameter-manual3-16.png`"
            alt=""
            style="width: 95%"
          />
          <h4>【8】「URLをコピー」をクリックします。</h4>
          <img :src="`${rt}/img/tools02/parameter-manual3-17.png`" alt="" />
          <h4>
            【9】記事のBeyondページを開いて、作成した中間ページのURLを設置します。
          </h4>
          <p>
            「外部」→URLに中間ページのURLを入力→「計測機能付きリンク」にチェック→「リンクを追加」
          </p>
          <img :src="`${rt}/img/tools02/parameter-manual3-18.png`" alt="" />
        </el-col>
      </template>

      <template v-else>
        <el-col>
          <h4 style="margin-top: 10px">
            【１】以下のファイルをダウンロードしてください。
          </h4>
          <p>
            PHP版orHTML版、テキストリンクありorなしの合計４パターン用意しているので、お好きなファイルをダウンロードしてください。
          </p>

          <ul>
            <li>
              <div @click="downloadSampleFile('1', 1, 'php')" class="sample">
                PHP版（テキストリンクあり）
              </div>
            </li>
            <li>
              <div @click="downloadSampleFile('1', 0, 'php')" class="sample">
                PHP版（テキストリンクなし）
              </div>
            </li>
            <li>
              <div @click="downloadSampleFile('1', 1, 'html')" class="sample">
                HTML版（テキストリンクあり）
              </div>
            </li>
            <li>
              <div @click="downloadSampleFile('1', 0, 'html')" class="sample">
                HTML版（テキストリンクなし）
              </div>
            </li>
          </ul>
          <p v-if="tool.codeKebab === 'facebook-cv'" class="red">
            ※中間ページファイルはユニットごとに異なるので、ご注意ください
          </p>
        </el-col>

        <el-col>
          <h4>【２】「sample-file」というファイルがダウンロードされます。</h4>
          <img :src="`${rt}/img/tools02/parameter-manual6-1.png`" alt />
        </el-col>

        <el-col>
          <h4>【３】ファイルを開いて編集していきます。</h4>
          <p>
            まず、任意ですが<span v-if="tool.codeKebab === 'google-cv'"
              >Google広告のタグ</span
            >
            <span v-else-if="tool.codeKebab === 'yahoo-cv'"
              >Yahoo!検索広告のタグ</span
            >
            <span v-else-if="tool.codeKebab === 'facebook-cv'"
              >FB広告のピクセル</span
            >
            <span v-else-if="tool.codeKebab === 'tiktok-cv'"
              >TikTok広告のピクセル</span
            >
            <span v-else-if="tool.codeKebab === 'line-cv'">LINE広告のタグ</span>
            <span v-else-if="tool.codeKebab === 'yda-cv'"
              >YDAのコンバージョン（？）</span
            >
            <span v-else>●●●●●</span
            >を設置しましょう。他の媒体のタグを設置しても大丈夫です。
          </p>
          <div
            v-if="tool.codeKebab === 'yahoo-cv'"
            class="yahoo-sup left-space"
          >
            <h5>補足</h5>
            <div>
              アドタスカルとは関係ありませんが、
              サイトジェネラルタグ・コンバージョン測定補完機能タグに「ローカルストレージを利用したコンバージョン計測の補完タグ」を設定することでCookie計測では最大1日のみだったコンバージョン測定期間を、最大7日間まで伸ばすことができます。
            </div>
            <div>
              また、MCVを計測したい場合は、このタグを記事LPやサイトにも設置してください。
            </div>
            <el-input
              v-model="yahooCvComplementTagString"
              type="textarea"
              :autosize="{ minRows: 1 }"
              style="width: 90%"
              onclick="this.select();"
            ></el-input>
            <h5 style="margin-top: 15px">参照</h5>
            <a
              href="https://ads-promo.yahoo.co.jp/support/release/849618.html"
              target="_blank"
              rel="noopener noreferrer"
              style="word-wrap: break-word"
              >https://ads-promo.yahoo.co.jp/support/release/849618.html</a
            >
          </div>
          <img
            :src="`${rt}/img/tools02/${tool.codeKebab}/parameter-manual6-2-1.png`"
            alt
            class="big"
          />
          <p>
            次に、「★アフィリリンク★」にアフィリエイトリンクを設置してください。
          </p>
          <img
            :src="`${rt}/img/tools02/${tool.codeKebab}/parameter-manual6-2-2.png`"
            alt
            class="big"
          />
          <p>&lt;body>内の表示は自由に変更してもOKです。</p>
          <h4>【注意事項１】</h4>
          <p>
            以下のASPは、プログラムIDの付与が必要になります。詳しい設定方法は、ASPごとのマニュアルをご覧ください。
          </p>
          <ul>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/09`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >サルクルー</router-link
              >
            </li>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/13`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >メディパートナー</router-link
              >
            </li>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/17`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >CATS</router-link
              >
            </li>
            <li>
              <router-link
                :to="`/manual/${tool.codeKebab}/05/19#from-manual1`"
                style="text-decoration: underline"
                target="_blank"
                rel="noopener noreferrer"
                >AFAD（Self
                Leap、LADDER、インペリアル、AD-LAVI、M-Ads、EQS）</router-link
              >
            </li>
            <!-- <li>
            <router-link :to="`/manual/${tool.codeKebab}/05/19`"
              >VENT</router-link
            >
          </li>-->
          </ul>
          <h4>【注意事項２】</h4>
          <p>
            中間ページを2枚以上使用されている場合、その全てにアドタスカル中間ページタグを設置するようにしてください。
          </p>
        </el-col>
        <el-col>
          <h4>
            【４】万が一、アドタスカルのサーバーに障害が発生したときでも中間ページからアフィリリンクに遷移するようにしています。
          </h4>
          <p>
            <!-- サーバーから4秒経過しても、応答がない場合は、強制的にアフィリリンクに遷移します。 -->
            サーバーから3秒経過しても、応答がない場合は、強制的にアフィリリンクに遷移します。
          </p>
          <p>
            その場合、パラメータは引き継がれませんが、リファラでパラメータを取得できるので、リカバリー可能です。
          </p>
          <img
            :src="`${rt}/img/tools02/${tool.codeKebab}/parameter-manual6-4.png`"
            alt
            class="big"
          />
          <p>
            <!-- デフォルトでは4000（4秒）になっていますが、エンドユーザーの回線次第ではページの読み込み完了に4秒以上掛かる場合もあります。その場合は、障害が発生していなくても強制的にアフィリリンクに遷移し、パラメータ引き継ぎがされません。 -->
            デフォルトでは3000（3秒）になっていますが、エンドユーザーの回線次第ではページの読み込み完了に3秒以上掛かる場合もあります。その場合は、障害が発生していなくても強制的にアフィリリンクに遷移し、パラメータ引き継ぎがされません。
          </p>
          <p>
            <!-- 頻繁にパラメータ引き継ぎがされない場合は、4000を4500や5000にするなど秒数を調整してください。 -->
            頻繁にパラメータ引き継ぎがされない場合は、3000を3500や4000にするなど秒数を調整してください。
          </p>
          <p>
            <!-- 逆に4000（4秒）は長いと感じた場合は、短くしても大丈夫です。ただ、短くしすぎると、通常時にパラメータ引き継ぎができていない状態で遷移することがあるので、その場合は、調整してください。 -->
            逆に3000（3秒）は長いと感じた場合は、短くしても大丈夫です。ただ、短くしすぎると、通常時にパラメータ引き継ぎができていない状態で遷移することがあるので、その場合は、調整してください。
          </p>
          <p>
            お使いのサーバーやエンドユーザーの回線速度などによって変化するため一概に言えませんが、通常時は、0.1秒～1.2秒で遷移していました。
          </p>
          <h5 class="left-space2">補足</h5>
          <p>
            一般的な中間ページとして「meta
            refreshを利用した0秒リダイレクト」を利用している方が多いと思います。
          </p>
          <el-input
            v-model="metaRefreshTagExample"
            style="width: 90%"
            class="left-space2"
            onclick="this.select();"
          ></el-input>
          <p>
            0秒でリダイレクトさせるとなっていますが、実際は、ページの読み込みが完了してからリダイレクトが開始されます。そして、ページの読み込み完了までに0.3秒～1.6秒ほど掛かります。
          </p>
          <p>
            そのため、アドタスカルの中間ページを使うことで、リダイレクトの遅延が発生することはないのでご安心ください。むしろ、早くなるケースが多いと思います。
          </p>
        </el-col>

        <el-col>
          <h4>
            【５】作成した中間ページを任意の名前に変更して、サーバーにアップロードしてください。
          </h4>
          <template v-if="tool.codeKebab === 'facebook-cv'"> </template>
          <template v-else>
            <p>参照：中間ページをアップロードする方法がわかりません。</p>
            <p>
              <a
                href="https://www.evernote.com/l/AB930fTHoJtKkIyB9TaO0_yK_1aOMm-Po9Q/"
                target="_blank"
                rel="noopener noreferrer"
                >https://www.evernote.com/l/AB930fTHoJtKkIyB9TaO0_yK_1aOMm-Po9Q/</a
              >
            </p>
          </template>
        </el-col>

        <el-col>
          <h4>【６】作成した中間ページのURLを記事LPに設定してください。</h4>
          <p>記事LPやサイトにアフィリリンクを設置してください。</p>
          <div class="memo">
            <div>
              &lt;a href="<span style="color: blue"
                >https://hogehoge.com/kiji/link.html</span
              >"&gt;公式サイト&lt;/a&gt;
            </div>
          </div>
        </el-col>
      </template>
      <el-col>
        <h4>【注意事項１】</h4>
        <p>
          「新しいタブで開く」の設定（「target=”_blank”」）は非推奨となっています。
        </p>

        <div class="memo">
          <div>
            【OK】&lt;a href="<span style="color: blue"
              >https://hogehoge.com/kiji/link.html</span
            >">公式サイト&lt;/a>
          </div>
          <div>
            【NG】&lt;a href="<span style="color: blue"
              >https://hogehoge.com/kiji/link.html</span
            >" <span class="red bold"> target=”_blank”</span>>公式サイト&lt;/a>
          </div>
        </div>
        <p v-if="category01 === 1">
          WordPressのテーマによっては、記事で「新しいタブで開く」の設定をしていなくても<br />全体のデフォルト設定で「新しいタブで開く」になっている場合があるのでご注意ください。
        </p>
        <h4>【注意事項２】</h4>
        <p>
          <span class="red2 bold"
            >「rel="noreferrer"」「rel="noopener noreferrer"」</span
          >を付けないようにお願いします。
        </p>
        <div class="left-space2 memo">
          <div>
            【OK】&lt;a href="<span style="color: blue"
              >https://hogehoge.com/kiji/link.html</span
            >">公式サイト&lt;/a>
          </div>
          <div>
            【NG】&lt;a href="<span style="color: blue"
              >https://hogehoge.com/kiji/link.html</span
            >"
            <span class="red bold">rel="noopener noreferrer"</span
            >>公式サイト&lt;/a>
          </div>
          <div>
            【NG】&lt;a href="<span style="color: blue"
              >https://hogehoge.com/kiji/link.html</span
            >" <span class="red bold">rel="noreferrer"</span>>公式サイト&lt;/a>
          </div>
          <div>
            【OK】&lt;a href="<span style="color: blue"
              >https://hogehoge.com/kiji/link.html</span
            >" <span class="red bold">rel="noopener"</span>>公式サイト&lt;/a>
          </div>
        </div>
        <template v-if="category01 === 1">
          <p>
            WordPressの場合、自動で付与される可能性があるので、必ずご確認ください。
          </p>
          <p>
            WordPressの場合、リンク先の設定で「新しいタブで開く」の設定（「target=”_blank”」）にしていると自動で「rel="noreferrer"」が付いてしまいます。<br />
            そのため、以下のどちらかで対処お願いします。
          </p>
          <p>
            ①「新しいタブで開く」の設定（「target=”_blank”」）を外す<br />
            ②<a href="https://tanweb.net/2019/09/11/28539/" target="_blank"
              >こちら</a
            >の記事を参考に「rel=”noreferrer”」を自動付与されなくする（<a
              href="https://tanweb.net/2019/09/11/28539/"
              target="_blank"
              >https://tanweb.net/2019/09/11/28539/</a
            >）
          </p>
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tools02ParameterManualSetLpTagInWP",
  components: {},
  props: {
    unitId: [String, Number],
    category01: Number,
    yahooCvComplementTag: String,
    textLinkTag: String,
    mediaTag: String,
    middlePageTag: String,
  },
  data() {
    return {
      metaRefreshTagExample:
        '<meta http-equiv="refresh" content="0;URL=https://www.sample.com">',

      // props
      yahooCvComplementTagString: this.yahooCvComplementTag,
      textLinkTagString: this.textLinkTag,
      mediaTagString: this.mediaTag,
      middlePageTagString: this.middlePageTag,
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage", "tools", "aspNames"]),
  },
  watch: {},
  methods: {
    downloadSampleFile(fileType, useTextLink, extension) {
      this.$axios
        .get(
          `${this.rt}/api/tools02/getSampleFile.php?tool_id=${
            this.tools[this.tool.codeCamel].id
          }&unit_id=${
            this.unitId
          }&file_type=${fileType}&use_text_link=${useTextLink}&extension=${extension}`
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            location.href = `${this.rt}/api/tools02/downloadFile.php?type=tools02SampleFile&file_name=${res.data.fileName}&download_file_name=${res.data.downloadFileName}`;
            return;
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
img,
.tag,
p,
ul {
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}

h4 {
  margin-top: 40px;
  margin-bottom: 15px;
}
img {
  border: 3px solid gray;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.big {
  width: 95%;
}

.yahoo-sup {
  width: 90%;
  margin-left: 40px;
  padding: 15px;
  background-color: #eee;
  border-radius: 5px;
  margin-bottom: 20px;
  h5 {
    margin: 5px 0;
  }
}

.tag {
  border: 2px solid gray;
  background-color: #f8f8ff;
  width: 70%;
  padding: 10px;
}

ul {
  line-height: 1.8em;
}
.sample {
  color: var(--link-blue);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
table {
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
  th,
  td {
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  th {
    font-weight: normal;
    text-align: left;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }
  .small {
    font-size: 15px;
  }
}
.copy-tag-area {
  width: 72%;
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 15px;
    width: 90%;
  }
}
.left-space {
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 10px;
  }
}
.left-space2 {
  margin-left: 0;
}
.left150 {
  margin-left: 150px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}
.memo {
  border: 1px solid #999;
  margin-left: 40px;
  padding: 15px;
  display: inline-block;
  @media screen and (max-width: 768px) {
    margin-left: 0;
  }
}
.note {
  border: 1px solid #999;
  padding: 15px;
  margin-left: 40px;
  @media screen and (max-width: 768px) {
    margin-left: 0;
    padding-right: 5px;
    padding-left: 10px;
    font-size: 14px;
  }
}
</style>
