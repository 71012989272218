<template>
  <div class="tag-test-system">
    <el-row>
      <el-col :span="4">
        <a
          :href="toolData.manualData.tagTestManualUrl"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" class="manual-button" msg="マニュアル" />
        </a>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="15">
        <table class="typeB">
          <tr>
            <th>{{ g_getTools02TagCategoryName(toolId) }}名</th>
            <td>
              <div style="padding: 10px">{{ tagData.name }}</div>
            </td>
          </tr>
          <tr>
            <th>
              <span>
                <span
                  v-if="
                    toolData.codeKebab === 'facebook-cv' ||
                    toolData.codeKebab === 'tiktok-cv'
                  "
                  >ピクセルID</span
                >
                <span v-else-if="toolData.codeKebab === 'line-cv'"
                  >LINE Tag ID</span
                >
                <span v-else-if="toolData.codeKebab === 'yda-cv'"
                  >yahoo_ydn_conv_io</span
                >
              </span>
            </th>
            <td>
              <div style="padding: 10px">{{ tagData.mediaTagId }}</div>
            </td>
          </tr>
          <tr>
            <th>
              <span
                v-if="
                  toolData.codeKebab === 'facebook-cv' ||
                  toolData.codeKebab === 'tiktok-cv' ||
                  toolData.codeKebab === 'line-cv'
                "
                >アクセストークン</span
              >
              <span v-else-if="toolData.codeKebab === 'yda-cv'"
                >yahoo_ydn_conv_label</span
              >
            </th>
            <td>
              <div style="padding: 10px; word-break: break-all">
                {{ tagData.accessToken }}
              </div>
            </td>
          </tr>
          <tr
            v-if="
              toolData.codeKebab === 'facebook-cv' ||
              toolData.codeKebab === 'tiktok-cv'
            "
          >
            <th>
              <span>テストコード</span>
            </th>
            <td>
              <el-input v-model="testEventCode" style="width: 30%"></el-input>
            </td>
          </tr>
        </table>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col>
        <div class="button-cover">
          <el-button @click="sendTestEvent" type="success"
            >テスト送信</el-button
          >
        </div>
      </el-col>
    </el-row>
    <el-row v-if="resultData !== null" type="flex" justify="center">
      <el-col :span="15">
        <h4>送信結果</h4>
        <div v-if="resultData.eventReceived === true">送信完了</div>
        <div v-else>{{ resultData.errorMessage }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  name: "TagTestSystem",
  components: {
    Button2,
  },
  props: {
    toolId: String,
    tagId: String,
  },
  data() {
    return {
      testEventCode: "",
      tagData: {
        name: "",
        mediaTagId: "",
        accessToken: "",
      },
      resultData: null,
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage", "userData"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {
    tagId: {
      handler(newTagId) {
        this.tagData = JSON.parse(
          JSON.stringify(
            this.$store.getters["tools02/tagData"](this.toolId, newTagId)
          )
        );
      },
      immediate: true,
    },
  },
  methods: {
    sendTestEvent() {
      //
      if (
        this.toolData.codeKebab === "facebook-cv" ||
        this.toolData.codeKebab === "tiktok-cv"
      ) {
        if (this.testEventCode !== "") {
          // ok.
        } else {
          alert("テストコードを入力してください。");
          return;
        }
      } else {
        // no action.
      }

      //
      if (this.toolData.codeKebab === "facebook-cv") {
        this.sendFacebookTestEvent();
      } else {
        this.sendElseTestEvent();
      }
    },
    sendFacebookTestEvent() {
      //
      let pixelData = {
        pixelName: this.tagData.name,
        pixelIdForApi: this.tagData.mediaTagId,
        accessToken: this.tagData.accessToken,
        testCode: this.testEventCode,
      };

      //
      let params = new URLSearchParams();
      params.append("pixel_data", JSON.stringify(pixelData));
      this.$axios
        .post(`${this.rt}/api/tools02/facebook-cv/send-pixel-test.php`, params)
        .then((response) => {
          console.log(response);
          if (response.data.status === 200 || response.data.status === 400) {
            alert(response.data.message);
            this.resultData = response.data.resultData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendElseTestEvent() {
      let params = new URLSearchParams();
      params.append("tool_id", this.toolId);
      params.append("tools02_tag_id", this.tagId);
      params.append("test_event_code", this.testEventCode);
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/conversion-api/manual-send-conversion-event`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            if (response.data.resultData.eventReceived === true) {
              let message =
                "テスト送信をしました。広告管理画面に反映されているか確認ください。";
              if (this.toolData.codeKebab === "line-cv") {
                message = "テスト送信に成功しました。";
              } else {
                // no action.
              }
              alert(message);
            } else {
              alert("テスト送信に失敗しました。");
            }
            this.resultData = response.data.resultData;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.button-cover {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-button {
    margin: 0 20px;
  }
}
</style>
