<template>
  <div class="manual-google-cv-contents">
    <h2>ピクセルコードの取得方法</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>ピクセルコードの取得方法</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>3分</p>
        <h4>【１】<router-link :to="`/manual/${tool.codeKebab}/01/02`">データソース</router-link>からアドタスカルと連携したいピクセルを選択し、「概要」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no10/01.png`" alt="" />
        <h4>【２】「イベントを追加」→「新しいウェブサイトサイトから」の順にクリックします</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no10/02.png`" alt="" />
        <h4>【３】</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no10/03.png`" alt="" />
        </div>
        <h4>【４】「コードをコピー」をクリックすると、ピクセルコードがコピーされます。次に「キャンセル」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no10/04.png`" alt="" />
        <h4>【５】「承認」をクリックします</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no10/05.png`" alt="" />
        </div>
        <h4>【６】コピーしたピクセルコードを記事LP（広告の遷移先）の&lt;/head&gt;タグの上に貼り付けてください。</h4>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
