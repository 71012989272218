<template>
  <div class="tools02-useage-guide">
    <el-row>
      <el-col>
        <h2 v-if="tool.codeKebab === 'cart-system'">解約・休止について</h2>
        <h2 v-else>利用設定・利用ステータス・ポイント消化について</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <Description />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Description from "@/components/Tools02/UsageGuide/Description.vue";
export default {
  components: {
    Description,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
