<template>
  <div class="report-column-description">
    <el-row>
      <el-col>
        <h4>【表示項目の説明】</h4>
      </el-col>
      <el-col>

        <table class="typeB">
          <!-- <tr v-if="type !== 1">
            <th>記事へのアクセス数</th>
            <td>ツールを設置したページの総アクセス数を表示。</td>
          </tr> -->
          <tr>
            <th>表示回数</th>
            <td>設定したツールが表示された総数を表示。</td>
          </tr>
          <tr>
            <th>クリック数</th>
            <td>設定したツールが表示され、クリックされた総数を表示。</td>
          </tr>
          <tr>
            <th>クリック率</th>
            <td>設定したツールが表示され、クリックされた率を表示。（ツールクリック数/ツール表示回数×100）</td>
          </tr>
          <!-- <tr>
            <th>MCV数</th>
            <td>マイクロコンバージョン数の略称。中間コンバージョン数とも言う。中間コンバージョン地点を設定した場合に、そのコンバージョン数を表示。</td>
          </tr>
          <tr>
            <th>MCVR</th>
            <td>マイクロコンバージョン率の略称。中間コンバージョン率とも言う。（MCV数/ツールクリック数×100）</td>
          </tr> -->
          <tr>
            <th>CV数</th>
            <td>コンバージョン数の略称。成約数とも言う。</td>
          </tr>
          <!-- <tr>
            <th>CVR</th>
            <td>コンバージョン率の略称。成約率とも言う。（CV数/MCV数×100）</td>
          </tr> -->
          <tr>
            <th>CVR</th>
            <td>トータルコンバージョン率の略称。（CV数/ツールクリック数×100）</td>
          </tr>
          <tr>
            <th>報酬額</th>
            <td>設定したコンバージョンから算出した売上金額を表示。（CV数×1コンバージョンの金額）</td>
          </tr>
          <tr>
            <th>EPP</th>
            <td>Earn Per Popupの略称。{{toolData.name}}1回表示当たりの価値・価格を表示。（金額/ツール表示回数×100）</td>
          </tr>
        </table>
        <p>※データとして計上される値はアドタスカルのシステムによって取得した参考値となります。他のアクセス解析ツールなどと値が異なる場合があります。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ReportColumnDescription',
  props: {
    toolId: String
  },
  computed: {
    toolData() {
      return this.$store.getters.toolData(this.toolId);
    },  
  }
}
</script>

<style lang="scss" scoped>
</style>
