<template>
  <div class="manual-google-cv-contents">
    <h2>顧客データに関する規約に<br class="sp-only">同意する</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <p>
          一部の{{tool.mediaName}}アカウントで、「顧客データに関する規約」に同意する求められるようになりました。
        </p>
        <h3>【所要時間】</h3>
        <p>3分</p>

        <h4>
          【１】「ツールと設定」→「測定」→「コンバージョン」の順にクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no05/01.png`" alt="" />
        <h4>【２】「設定」をクリックしてください</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/google-cv/no03/no05/02.png`" alt="" />
        </div>
          <h4>
            【３】「顧客データに関する規約」をクリックして、利用規約をご確認の上、「私は会社を代表し、利用規約を読んだ上で同意します」にチェックを入れて「保存」をクリックしてください。
          </h4>
                  <img :src="`${rt}/img/manual/google-cv/no03/no05/03.png`" alt="" />
                  <h4>
【４】「お客様は、これらの利用規約を読み、同意しています」と表示されたら同意完了です。</h4>
        <img :src="`${rt}/img/manual/google-cv/no03/no05/04.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
