import { render, staticRenderFns } from "./AbTestReportList.vue?vue&type=template&id=072fd875&scoped=true&"
import script from "./AbTestReportList.vue?vue&type=script&lang=js&"
export * from "./AbTestReportList.vue?vue&type=script&lang=js&"
import style0 from "./AbTestReportList.vue?vue&type=style&index=0&id=072fd875&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072fd875",
  null
  
)

export default component.exports