<template>
  <div class="tools02-tag-fire">
    <el-row>
      <el-col>
        <h2>{{g_getTools02TagCategoryName(toolId)}} 発火</h2>
      </el-col>
    </el-row>
    <TagFireEditor :toolId="toolId" :tagId="tagId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TagFireEditor from "@/components/Tools02/TagFire/TagFireEditor.vue";

export default {
  components: {
    TagFireEditor,
  },
  props: {
    toolId: String,
    accountId: String,
    tagId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {},
  created() {
    if (this.toolData.codeKebab === "line-cv") {
      this.setTitle("広告成果連携ツール LINE Tag詳細");
    } else {
      // no action.
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
