<template>
  <div class="pointback-setting-description">
    <div v-if="aspData.pointbackSetting === '0'">
      <h4>
        【重要】{{ aspData.aspName }}の自動連携設定単位について
      </h4>
      <p>
        {{ aspData.aspName }}の自動連携設定は「ASPアカウント単位」です。<br />
        そのため、自動連携の設定を一度したら、他の案件を扱う場合に再連携は必要ありません。
      </p>
    </div>
    <div v-else-if="aspData.pointbackSetting === '1'">
      <h4>
        【重要】{{ aspData.aspName }}の自動連携設定単位について
      </h4>
      <p>
        {{ aspData.aspName }}の自動連携設定は「ASP登録サイト単位」です。<br />
        そのため、登録サイトごとに自動連携の設定が必要となります。<br />
        別の登録サイトで発行されたアフィリリンクを使用する場合は、再連携を行ってください。
      </p>
    </div>
    <div v-else-if="aspData.pointbackSetting === '2'">
      <h4>
        【重要】{{ aspData.aspName }}の自動連携設定単位について
      </h4>
      <p>
        {{ aspData.aspName }}は案件単位でアドタスカルと連携されます。<br />
        そのため、新しい案件を実施する際は、再度、通知URLの設定をご依頼ください。
      </p>
    </div>
    <div v-else-if="aspData.pointbackSetting === '4'">
      <h4>
        【重要】{{ aspData.aspName }}の自動連携設定単位について
      </h4>
      <p>
        {{ aspData.aspName }}の自動連携設定は「メディア単位」です。<br />
        そのため、登録メディアごとに自動連携の設定が必要となります。<br />
        別の登録メディアで発行されたアフィリリンクを使用する場合は、再連携を行ってください。
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PointbackSettingDescription",
  props: {
    aspId: String,
  },
  computed: {
    ...mapGetters(["rt"]),
    aspData() {
      return this.$store.getters["aspData"](this.aspId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
