<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（famAD）</h2>
    <el-row>
      <el-col>
        <h4>
          【１】<a href="https://admin.ad-fam.com/report/" target="_blank"
            >famADの管理画面</a
          >
          にログインし、「click一覧」をクリックします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no13/01.png`" alt="" />

        <h4>
          【２】登録したいプログラムを見つけて、「アフィリエイト広告」欄をコピーします。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no13/02.png`" alt="" />

        <p class="red" style="font-weight: bold">
          ※「click一覧」に登録したいプログラムが表示されない場合は、famADから発行されたアフィリリンクをご自身で何度かクリックしてください。
        </p>

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【３】アドタスカルのプログラム情報に入力します。</h4>

        <p>
          ①ASPプログラム名：先程コピーした「アフィリエイト広告」名を貼り付けます。
        </p>
        <p>②プログラムID：アフィリエイト名の[]内の数値をコピペします。</p>
        <img :src="`${rt}/img/manual/google-cv/no05/no13/03.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
