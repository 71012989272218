<template>
  <div class="news">
    <el-row>
      <el-col>
        <div>{{list.release_datetime}}</div>
        <h2>{{list.title}}</h2>
        <div v-html="list.content2"></div>
      </el-col>
    </el-row>
    <hr>
    <el-row>
      <el-col>
        <el-button
          v-show="list.confirm == 0"
          size="mini"
          @click="confirm( list.id,1)"
        ><i class="el-icon-delete"> ゴミ箱へ</i></el-button>
        <el-button
          v-show="list.confirm == 1"
          size="mini"
          @click="confirm( list.id,0)"
        ><i class="el-icon-refresh-left"> 戻す</i></el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    id: Number,
  },
  data() {
    return {
      list: {
        release_datetime: "",
        title: "",
        content2: "",
      },
    };
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
  created() {
    let params = new URLSearchParams();
    params.append("id", this.id);
    this.$axios
      .post(this.rt + "/api/getUsersPostDetail.php", params)
      .then((res) => {
        console.log(res);
        this.list = res.data.list;
        // const regexp_url = /(?!")(https?:\/\/[\w/:%#$&?()~.=+-]+)/g;
        const regexp_url = /(?<!")\b(https?:\/\/[\w/:%#$&?()~.=+-]+)\b/g;
        this.list.content2 = this.list.content2.replace(regexp_url, '<a href="$1" target="_blank">$1</a>');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    confirm: function (id, val) {
      console.log(" id:" + id);

      //データベースのconfirm=1
      let params = new URLSearchParams();
      params.append("id", id);
      params.append("val", val);
      this.$axios
        .post(`${this.rt}/api/confirmPost.php`, params)
        .then((res) => {
          console.log(res);
          this.$router.go(-1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-delete,
.el-icon-refresh-left {
  font-size: 1.5em;
}
h2 {
  border-bottom: solid 1px #ccc;
  margin-bottom: 40px;
}
</style>
