<template>
  <div class="change-password-password-editor">
    <div class="pass-cover">
      <div class="pass-item">
        <el-input type="password" placeholder="" v-model="oldPass">
          <template slot="prepend">旧パスワード</template>
        </el-input>
      </div>
    </div>

    <div class="confirm">
      <div class="pass-cover">
        <div class="pass-item">
          <el-input type="password" placeholder="" v-model="newPass">
            <template slot="prepend">新パスワード</template>
          </el-input>
        </div>
      </div>
      <div class="pass-cover">
        <div class="pass-item">
          <el-input type="password" v-model="newPass2">
            <template slot="prepend">新パスワード（確認用）</template>
          </el-input>
        </div>
      </div>
    </div>

    <el-row type="flex" justify="center">
      <el-col :span="6" style="text-align: center">
        <div @click="change">
          <el-button type="primary">変更</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ChangePasswordPasswordEditor",
  components: {},
  props: {},
  data() {
    return {
      oldPass: "",
      newPass: "",
      newPass2: "",
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
  },
  methods: {
    change() {
      let params = new URLSearchParams();
      params.append("old_pass", this.oldPass);
      params.append("new_pass", this.newPass);
      params.append("new_pass2", this.newPass2);
      this.$axios
        .post(`${this.rt}/api/changePassword.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.$router.go(-1);
          } else if (res.data.status === 400) {
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            if (res.data.status2 === 1) {
              //no action.
            }else {
              this.$router.push(`/login`);
            }
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm {
  :deep(.el-input-group__prepend) {
    background-color: #e9f4ff;
  }
}
 :deep(.el-input-group__prepend) {
   @media screen and (max-width: 787px){
     font-size: 12px;
     padding: 0 5px;
    //  min-width: 120px;
   }
   
 }
.pass-cover{
  display: flex;
  align-items: center;
  justify-content: center;
  .pass-item{
    width: 90%;
    max-width: 900px;
    margin-bottom: 10px;
     @media screen and (max-width: 787px){
       width: 100%;
     }
  }
}
</style>
