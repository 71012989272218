<template>
  <div class="validation-error-messages">
    <div
      v-for="(errorMessage, index) in errorMessageList"
      :key="index"
      class="error-message"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "validation-error-messages",
  props: {
    errorMessageList: Array,
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  font-size: 12px;
  color: red;
}
</style>