<template>
  <div class="manual-google-cv-contents">
    <el-row>
      <el-col>
        <h2>キャンペーン固有の目標設定（説明）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>
            キャンペーン固有の目標設定を理解する
          </li>
        </ul>
        <h3>【所要時間】</h3>
        <p>15分</p>
        <h3>【設定方法】</h3>
        <p>キャンペーン固有の目標設定はされていますでしょうか？</p>
        <ul>
          <li>一つのアカウントで複数の案件を扱っている</li>
          <li>最終CVで最適化しながらMCVを計測したい</li>
          <li>MCVで最適化しながら最終CVを計測したい</li>
        </ul>
        <p>という方は絶対に設定したほうが良い機能になります。</p>
        
        <p>特にアドタスカルを使う場合は、</p>
        <ul>
          <li>MCVで最適化する場合</li>
          <li>オフラインCV（最終CV）で最適化する場合</li>
        </ul>

        <p>どちらの場合もキャンペーン固有の目標設定を推薦しています。</p>

<h4><span style="border-bottom:double;border-width:3px;">キャンペーン固有の目標設定とは？</span></h4>
<p>簡単に説明すると、<span class="red" style="font-size:20px;">「Googleにこのコンバージョンで最適化したいです」</span>と伝える設定です。</p>

<p>この設定をしないと、ASPアカウント単位での目標設定になるため、Googleが間違った最適化をしてしまいます。</p>

<p>たとえば、キャンペーンAには、</p>

    <ul>
      <li>アフィリリンクのクリック（MCV）</li>
      <li>商品購入（最終CV）</li>
    </ul>

    <p>の２つの成果地点でコンバージョン作成しました。</p>

    <p>キャンペーンAに、目標設定をしていません。</p>

    <p>すると、<span style="font-size:20px;font-weight:bold;">MCVも最終CVもコンバージョン数は同じ１としてカウント</span>されます。</p>
        
        <p>かと言って、MCVを作成しないと、スピーディーにPDCAが回せません。</p>
        <p>そこで、キャンペーン固有の目標設定が活躍します。</p>

<h4><span style="border-bottom:double;border-width:3px;">キャンペーン固有の目標設定の活用方法</span></h4>

<p>キャンペーン固有の目標設定を行うことで、</p>
<ul>
  <li>最適化対象にしたいコンバージョン</li>
  <li>計測用に数値だけ見たいコンバージョン</li>
</ul>

<p>を分けることができます。</p>

<p>「計測用に数値だけ見たいコンバージョン」は、作成と設置をするだけ。（オフラインCVの場合、設置不要）>「最適化対象にしたいコンバージョン」を「キャンペーン固有の目標設定」として設定する<br>「計測用に数値だけ見たいコンバージョン」は、作成と設置をするだけ。（オフラインCVの場合、設置不要）
</p>


<h4><span style="border-bottom:double;border-width:3px;">ケーススタディー</span></h4>
<p>アフィリエイトの場合、MCVと最終CVで２つの成果地点でコンバージョンを作成している方が多いと思います。</p>
<p>人によってどちらを最適化対象にするか変わってきますので、
２つのケースで設定方法をみていきます。</p>

<h4 style="padding-left:20px;">■ケース１：MCVで最適化して、最終CV（オフラインCV）は計測用
</h4>
<table>
  <tr>
    <th>MCV</th>
    <td>:キャンペーン固有の目標設定に設定</td>
  </tr>
  <tr>
    <th>最終CV（オフラインCV）</th>
    <td>:作成するだけ。</td>
  </tr>
</table>

<p class="red result">⇒ MCVで最適化しつつ、最終CVでのデータも計測できる。</p>


<h4 style="padding-left:20px;">■ケース２：最終CV（オフラインCV）で最適化して、MCVは計測用
</h4>
<table>
  <tr>
    <th>MCV</th>
    <td>:作成して、中間ページに設置するだけ。</td>
  </tr>
  <tr>
    <th>最終CV（オフラインCV）</th>
    <td>:キャンペーン固有の目標設定に設定</td>
  </tr>
</table>

<p class="red result">⇒ 最終CVで最適化しつつ、MCVでのデータも計測できる。</p>
<div style="margin:50px 0;">
<p>「キャンペーン固有の目標設定」について理解できたら、実際に設定してみましょう。</p><p><span style="padding-left:20px;">⇒</span><router-link to="/manual/google-cv/11/08"
            >キャンペーン固有の目標設定の設定方法</router-link
          ></p></div>
<div style="margin-top:60px;margin-left:15px;">
          <p>▼参照ページ<br><a href="https://support.google.com/google-ads/answer/9143218?hl=ja" target=_blank >[Google広告ヘルプ]キャンペーン固有の目標設定を有効にする</a></p>
          </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
table{
  padding-left: 50px;
  margin: 20px 0;
  @media screen and (max-width: 768px){
    padding-left: 20px;
  }
  tr{
    th{
      font-weight: initial;
      text-align: left;
    }
    th,td{
      @media screen and (max-width: 768px){
        display: block;
      }
    }
  }
}
.result{
  padding-left:30px;
  font-size: 18px;
  font-weight: bold;
  @media screen and (max-width: 768px){
    padding-left: 10px;
  }
}
</style>
