<template>
  <div class="promotion-id-description">
    <p class="red">
      ※{{aspData.aspName}}は他のASPと違い、プログラムIDを自分で命名して、それをアフィリリンクに付与する必要があるので、このマニュアルを注意して読んでください。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PromotionIdDescription",
  props: {
    aspId: String,
  },
  computed: {
    ...mapGetters(["rt"]),
    aspData() {
      return this.$store.getters["aspData"](this.aspId);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
