<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（SLVRbullet）</h2>

    <el-row>
      <el-col>
        <h4>
          【１】
          <a href="https://www.slvrbullet.com/pt/sign_in" target="_blank"
            >SLVRbulletの管理画面</a
          >にログインしたら、「提携情報」をクリックする
        </h4>

        <img :src="`${rt}/img/manual/google-cv/no05/no04/01.png`" alt="" />

        <h4>【２】「フィルターを適用する」をクリックする</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no04/02.png`" alt="" />

        <h4>【３】プログラム名をコピーする（※全てコピーすること）</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no04/03.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>

        <h4>【４】ASPプログラム名に貼り付ける</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no04/04.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
