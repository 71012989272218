<template>
  <div class="tools02-unit-detail">
    <el-row>
      <el-col>
        <h2 v-if="unitId === 0">ユニット 新規登録</h2>
        <h2 v-else>ユニット 編集</h2>
      </el-col>
    </el-row>

    <Tools02UnitDetailUnitEditor :unitId="unitId" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tools02UnitDetailUnitEditor from "@/components/Tools02/UnitDetail/UnitEditor.vue";

export default {
  components: {
    Tools02UnitDetailUnitEditor,
  },
  props: {
    unitId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
