<template>
  <div class="tools02-report-import-easy-api">
    <el-collapse accordion>
      <el-collapse-item title="手動でAPI送信する">
        <el-tooltip class="item" effect="dark" placement="right"
          ><div slot="content">
            <ul>
              <li>（※）は、必須項目です。</li>
            </ul>
          </div>
          <i class="el-icon-question"></i
        ></el-tooltip>
        <el-row type="flex" align="middle">
          <el-col :span="3">CVした案件（※）</el-col>
          <el-col :span="10">
            <el-select v-model="sendData.promotionId" placeholder="Select">
              <el-option
                v-for="(promotionData, index) in promotionList"
                :key="index"
                :label="promotionData.promotionName"
                :value="promotionData.promotionId"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">CV時間</el-col>
          <el-col :span="10">
            <el-date-picker
              v-model="sendData.conversionTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder=""
            >
            </el-date-picker>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">CVイベント（※）</el-col>
          <el-col :span="4">
            <el-select
              v-model="sendData.eventName"
              placeholder="選択してください。"
            >
              <el-option
                v-for="(facebookEventData, index) in facebookEventList"
                :key="index"
                :label="facebookEventData.displayName"
                :value="facebookEventData.eventName"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row
          v-show="sendData.eventName === 'CustomEvent'"
          type="flex"
          align="middle"
        >
          <el-col :span="3">カスタムイベント（※）</el-col>
          <el-col :span="4">
            <el-input v-model="sendData.customEventName" />
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">報酬額（※）</el-col>
          <el-col :span="4"
            ><el-input v-model="sendData.conversionValue" type="number"
          /></el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">ユニット（※）</el-col>
          <el-col :span="10">
            <el-select v-model="sendData.unitToken" placeholder="Select">
              <el-option
                v-for="unitData in units"
                :key="unitData.unitId"
                :label="unitData.unitName"
                :value="unitData.unitToken"
              >
              </el-option> </el-select
          ></el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">fbc</el-col>
          <el-col :span="10"><el-input v-model="sendData.fbc" /></el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">fbp</el-col>
          <el-col :span="10"><el-input v-model="sendData.fbp" /></el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">ipAddress</el-col>
          <el-col :span="10"><el-input v-model="sendData.ipAddress" /></el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">userAgent</el-col>
          <el-col :span="10"><el-input v-model="sendData.userAgent" /></el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">fbclid</el-col>
          <el-col :span="10"><el-input v-model="sendData.fbclid" /></el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="3">atid</el-col>
          <el-col :span="10"
            ><el-input v-model="sendData.adtasukaruId"
          /></el-col>
        </el-row>
        <div style="text-align: center">
          <el-button @click="sendApiRequests" type="primary">送信</el-button>
        </div>
        <el-row v-if="resultList.length > 0">
          <el-col>
            <h3>送信結果</h3>
            <div v-for="(resultData, index) in resultList" :key="index">
              <h4>ピクセル名: {{ resultData.pixelData.pixelName }}</h4>
              <div
                v-if="resultData.apiResponse.curlResponse.eventsReceived === 1"
              >
                API送信に成功しました。
              </div>
              <div v-else>
                <h5>
                  エラー種別:
                  {{ resultData.apiResponse.curlResponse.error.message }}
                </h5>
                <div>
                  {{ resultData.apiResponse.curlResponse.error.errorUserMsg }}
                </div>
              </div>
              <hr />
            </div>
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "tools02-report-import-easy-api",
  components: {},
  props: {},
  data() {
    return {
      sendData: {
        promotionId: "",
        eventName: "",
        customEventName: "",
        conversionTime: "",
        conversionValue: "",
        unitToken: "",
        fbc: "",
        fbp: "",
        ipAddress: "",
        userAgent: "",
        fbclid: "",
        adtasukaruId: "",
      },
      resultList: [],
      // sendData: {
      //   cvPageUrl: "",
      //   conversionTime: "2022-05-09 14:22:00",
      //   conversionValue: 1234,
      //   unitToken: "61b160a905145db8e5cab18637a1c674",
      //   fbc: "fb.1.1554763741205.AbCdEfGhIjKlMnOpQrStUvWxYz1234567890",
      //   fbp: "fb.1.1558571054389.1098115397",
      //   ipAddress: "60.94.160.98",
      //   userAgent: "Mozilla/5.0",
      //   fbclid: "hogehoge",
      //   adtasukaruId: "aiueo",
      // },
    };
  },
  computed: {
    ...mapGetters(["rt", "apiErrorMessage"]),
    ...mapGetters("tools02", ["units", "facebookEventList"]),
    promotionList() {
      return this.$store.getters["tools02/promotionsInTool"]("8");
    },
  },
  watch: {},
  methods: {
    sendApiRequests() {
      let params = new URLSearchParams();
      params.append("send_data", JSON.stringify(this.sendData));
      this.$axios
        .post(
          `${this.rt}/api/tools02/facebook-cv/easy-api-requests.php`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200) {
            this.resultList = response.data.resultList;
            return;
          } else if (response.data.status === 400) {
            alert(response.data.message);
            return;
          } else if (response.data.status === 401) {
            alert(response.data.message);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
