<template>
  <div class="manual-tools02-contents">
    <h2>プログラム情報登録<br class="sp-only">（Link-AG)</h2>
    <el-row>
      <el-col>
        <p>対象ASP：Link-AG、BULK-AD、SIXPACK-C</p>
        <p class="red">
          以下、「Link-AG」を例に解説をしていますが、「BULK-AD」「SIXPACK-C」とも同じ手順になります。適宜、「Link-AG」→（該当するASP名）に読み替えて設定を行ってください。
        </p>
        <h4>
          【１】
          <a href="https://link-ag.net/partner/sign_in" target="_blank"
            >Link-AGの管理画面</a
          >にログインし、「広告一覧」から提携している案件を探します。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no08/01.png`" alt="" />

        <h4>【2】広告IDと広告名をコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no08/02.png`" alt="" />

        <p class="kokokara">
          --------------------------------<br class="sp-only">ここからアドタスカル<br class="sp-only">--------------------------------
        </p>
        <h4>
          【3】アドタスカルのASPプログラム名、プログラムIDに貼り付けます。
        </h4>
        <img :src="`${rt}/img/manual/google-cv/no05/no08/03.png`" alt="" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "ManualGoogleCvContents",
  props: {},
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
    tools() {
      return this.$store.getters.tools;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>

</style>
