import { render, staticRenderFns } from "./ABtest.vue?vue&type=template&id=a32f399e&scoped=true&"
import script from "./ABtest.vue?vue&type=script&lang=js&"
export * from "./ABtest.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a32f399e",
  null
  
)

export default component.exports