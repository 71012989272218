import { render, staticRenderFns } from "./ProductsTable.vue?vue&type=template&id=3e5f3347&scoped=true&"
import script from "./ProductsTable.vue?vue&type=script&lang=js&"
export * from "./ProductsTable.vue?vue&type=script&lang=js&"
import style0 from "./ProductsTable.vue?vue&type=style&index=0&id=3e5f3347&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e5f3347",
  null
  
)

export default component.exports