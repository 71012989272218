<template>
  <div class="tools02-parameter-check-checking-system">
    <div class="top-text">
      <div class="left">
        <p>パラメータ引継ぎタグの設定をチェックします。</p>
        <p>
          ①「新しいシークレットウィンドウ」を開いてください。（CTRL＋SHIFT＋N）
        </p>
        <p>
          ②記事LPやサイト、中間ページを開いて、ソースコードを表示してください。「CTRL+U」
        </p>
      </div>
      <div style="width: 140px">
        <a
          href="https://adtasukaru.wraptas.site/23b518cc42a6430f93cc6bea1dc09b2f"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button2 type2="1" msg="マニュアル" />
        </a>
      </div>
    </div>
    <div class="cover">
      <div class="check">チェックする対象：</div>

      <div class="page" style="disply: flex">
        <el-radio v-model="objectToCheck" :label="0">記事LP・サイト</el-radio>
        <el-radio v-model="objectToCheck" :label="1">中間ページ</el-radio>
        <el-radio v-model="objectToCheck" :label="2">直LP</el-radio>
      </div>
    </div>
    <el-row>
      <el-col>
        <el-input
          type="textarea"
          placeholder="ここにソースコードを貼り付けてください。"
          :autosize="{ minRows: 15 }"
          v-model="sourceCode"
        />
        <ValidationErrorMessages
          :errorMessageList="validationErrorData.sourceCode"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col style="text-align: center">
        <el-button @click="checkSourceCode" type="primary">チェック</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h3>注意事項</h3>
        <p>
          ・パラメータ引継ぎ設定の動作を保証するものではありません。必ず、実際にアフィリリンクにパラメータが付与されるか動作確認をおこなってください。
        </p>
        <p>
          <a
            :href="toolData.manualData.parameterPassingCheckManualUrl"
            target="_blank"
            rel="noopener noreferrer"
            >動作確認の手順はこちら</a
          >
        </p>
      </el-col>
    </el-row>
    <el-row v-show="checkResult === 200 || checkResult === 440">
      <el-col>
        <h3>チェック結果</h3>
        <div class="check-result">
          <div v-show="checkResult === 200">
            <h3>
              <el-button
                type="success"
                icon="el-icon-check"
                circle
                class="success-icon"
              />エラーは検出されませんでしたが、念の為、動作確認をお願いします。
            </h3>
            <p>
              <a
                :href="toolData.manualData.parameterPassingCheckManualUrl"
                target="_blank"
                rel="noopener noreferrer"
                >動作確認の手順はこちら</a
              >
            </p>
          </div>
          <div v-show="checkResult === 440">
            <div v-for="(error, index) in errors" :key="index">
              <h3>
                <template v-if="error.icon === 'warning'">
                  <img
                    class="warning-icon"
                    :src="`${rt}/img/tools02/warning.png`"
                  />
                </template>
                <template v-else>
                  <el-button type="danger" round class="danger-icon"
                    >!</el-button
                  >
                </template>
                <a
                  :href="error.link"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ error.title }}</a
                >
              </h3>
              <ul>
                <li
                  v-for="(errorContent, index2) in error.contents"
                  :key="index2"
                >
                  {{ errorContent }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
      <el-col v-show="checkResult !== 200">
        <p>
          →エラー別対処法は
          <a
            href="https://adtasukaru.wraptas.site/b1ba58bf32a946ae85bcf6dcb7143fa0"
            target="_blank"
            rel="noopener noreferrer"
            >コチラ</a
          >
        </p>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="notes-box">
          <div class="icon"><i class="el-icon-warning"></i></div>
          <div class="description">
            <h4>
              設定しているはずなのにエラーが出る場合は、キャッシュの関係で設定前のソースが表示される場合があります。以下の方法を試してください。
            </h4>
            <p>
              ①スーパーリロード（参照：<a href="https://www.lct.jp/archives/column/9354/" target="_blank" rel="noopener noreferrer">https://www.lct.jp/archives/column/9354/</a>）する
            </p>
            <p>②シークレットモードで開く</p>
            <p>③別のブラウザで試す</p>
            <p>④別のパソコンで試す</p>
            <p>
              どうしても解決しない場合は、サポートまで記事URLと一緒にお問い合わせください。
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ValidationErrorMessages from "@/components/Ui/ValidationErrorMessages.vue";

import Button2 from "@/components/Ui/Button2.vue";
export default {
  name: "Tools02ParameterCheckCheckingSystem",
  components: {
    Button2,
    ValidationErrorMessages,
  },
  props: {
    toolId: String,
  },
  data() {
    return {
      sourceCode: "",
      errors: [],
      objectToCheck: 0,
      checkResult: 0,

      //
      validationErrorData: {
        sourceCode: [],
      },
    };
  },
  computed: {
    ...mapGetters(["rt", "tool", "apiErrorMessage"]),
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {},
  methods: {
    checkSourceCode() {
      //
      this.validationErrorData.sourceCode = [];

      //
      let params = new URLSearchParams();
      params.append("tool_id", this.toolId);
      params.append("page_type", this.objectToCheck);
      params.append("source_code", this.sourceCode);
      this.$axios
        .post(
          `${this.rt}/l/api/tools02/parameter-passing/check-source-code`,
          params
        )
        .then((response) => {
          console.log(response);
          if (response.data.status === 200 || response.data.status === 440) {
            alert(response.data.message);
            this.checkResult = response.data.status;
            this.errors = response.data.errors;
            return;
          } else if (response.data.status === 400) {
            this.validationErrorData = response.data.validationErrorData;
            return;
          } else if (response.data.status === 401) {
            alert(response.data.messsage);
            this.$router.push("/login");
          } else {
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.parameter {
  width: 300px;
  word-break: break-all;
  margin: 0 auto;
}
.check-result {
  border: solid 1px #999;
  min-height: 50px;
  padding: 15px;
  word-break: break-all;
}
:deep(.el-textarea) {
  textarea {
    max-height: 500px;
  }
}
.top-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1128px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
.cover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
  .check {
    width: 250px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
  .page {
    width: 40%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.warning-icon {
  width: 20px;
  height: 20px;
  margin: 0 3px;
  vertical-align: middle;
}

// 
.notes-box {
  background-color: #eee;
  padding: 20px 15px;
  display: flex;
  .icon {
    color: orange;
    margin-right: 5px;
    font-size: 17px;
  }
  .description {
    h4, p {
      margin: 0;
    }
  }
}
</style>
