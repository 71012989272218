<template>
  <div class="overlay-description02">
    <p class="title">オーバーレイとは</p>
    <p>
      オーバーレイとは、広告サイト上のどこを見ているときでも、常に固定の位置にボタンやテキストを表示し、見せたいページやコンテンツへユーザーを誘導したり、閲覧時の臨場感をかき立てたりすることができるツールです。
    </p>
    <div class="overlay01">
      <img :src="rt + '/img/description/overlay/overlay_01_sp.png'" />
      <img :src="rt + '/img/description/overlay/overlay_02_sp.png'" />
    </div>
    <p>
      オーバーレイは常にユーザーから見え、いつでもタップできるため、広告のパフォーマンス改善につながります。<br /><br />
      また、オーバーレイを任意のタイミングで表示させる設定もできます。たとえば、商品の紹介をした後、商品の公式サイトへのリンクを設置したオーバーレイを表示することができます。
    </p>
    <div class="overlay02">
      <img
        class="img01"
        :src="rt + '/img/description/overlay/overlay_03.png'"
      />
      <img
        class="yajirushi"
        :src="rt + '/img/description/overlay/yajirushi.png'"
      />
      <img
        class="img02"
        :src="rt + '/img/description/overlay/overlay_04.png'"
      />
    </div>

    <p>
      さらには、閲覧者がその設定した位置より上に戻った場合、「オーバーレイの表示を止める」または「オーバーレイを表示したままにする」という細かな設定もすることができます。<br /><br />
      アドタスカルのオーバーレイでは、「ボタンタイプ」と「テキストタイプ」のオーバーレイを選択して、設定することができます。
    </p>

    <div class="overlay01">
      <img :src="rt + '/img/description/overlay/btn_type.png'" />
      <img :src="rt + '/img/description/overlay/txt_type.png'" />
    </div>

    <p class="pin-style">
      「ボタンタイプ」は、リンク先を自由に指定することができます。「テキストタイプ」は、ご自由にテキストを設定することはもちろん、テキストと合わせて数値も設定し、その数値を自動で増減させることまでできます。
    </p>

    <div class="ResultAndEffect">
      <p class="title">アドタスカルは成果計測とA/Bテスト機能で改善効果◎</p>

      <p class="pin-style2">
        アドタスカルのポップアップは、コンバージョン測定機能、A/Bテスト機能、パラメータ保持機能があるため、成果計測と改善がしやすいのも大きな特徴です。
      </p>

      <Template02
        :TemplateTitle="'コンバージョン測定機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'コンバージョン測定機能で発行したコンバージョンタグをサンクスページに設置することで、コンバージョンの計測をすることができます。'"
      />
      <Template02
        :TemplateTitle="'A/Bテスト機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'アドタスカルはA/Bテスト機能を搭載しているため、テストによってパフォーマンス改善を追求することができます。A/Bテスト機能を利用することで、広告スキルやコンテンツ制作スキルもアップします。'"
      />
      <Template02
        :TemplateTitle="'パラーメター保持機能'"
        :DataName="'captcha.png'"
        :altText="'ダミー画像です'"
        :TemplateText="'パラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入るパラーメター保持機能のテキストが入る'"
      />
    </div>

    <p class="title">機能について</p>
    <OverlayFunction />
  </div>
</template>

<script>
import Template02 from "@/components/Description/Parts/Template02.vue";
import OverlayFunction from "@/components/Description/Parts/OverlayFunction.vue";

export default {
  name: "OverlayDescription",
  components: {
    Template02,
    OverlayFunction,
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    rt() {
      return this.$store.getters.rt;
    },
  },
}
</script>

<style lang="scss" scoped>
p {
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 20px;
}
.title {
  font-size: 36px;
  margin-bottom: 50px;
  color: #4d4d4d;
  font-weight: bold;
  text-align: center;
  // border-top: 1px solid #ccc;
  // border-bottom: 1px solid #ccc;
}
.overlay01 {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin: 60px 0;
  img {
    width: 350px;
  }
}
.overlay02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 80px 0 90px;
  .img01 {
    width: 250px;
    margin-left: 150px;
  }
  .img02 {
    width: 420px;
  }
  .yajirushi {
    width: 150px;
  }
}

.pin-style {
  margin-bottom: 150px;
}

.pin-style2 {
  margin-bottom: 70px;
}

.ResultAndEffect {
  margin: 120px 0;
}
</style>