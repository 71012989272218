<template>
  <div class="affiliate-home-link-buttons">
    <div class="cover">
      <div class="affiliate-atem">
        <router-link to="/affiliate/link">
          <MenuButton :text="`アフィリエイトリンク<br>特典設定`" />
        </router-link>
      </div>
      <div class="affiliate-atem">
        <router-link to="/affiliate/result">
          <MenuButton :text="`報酬・成果確認`" />
        </router-link>
      </div>
      <div class="affiliate-atem">
        <router-link to="/affiliate/status">
          <MenuButton :text="`獲得ユーザーの利用状況<br>（サマリー・個別）`" />
        </router-link>
      </div>
      <div class="affiliate-atem">
        <router-link to="/affiliate/reward">
          <MenuButton :text="`アフィリエイト報酬明細`" />
        </router-link>
      </div>
      <div class="affiliate-atem">
        <router-link to="/affiliate/notice">
          <MenuButton :text="`通知設定`" />
        </router-link>
      </div>
      <div class="affiliate-atem">
        <router-link to="/affiliate/bank-account">
          <MenuButton :text="`報酬の受取方法の設定<br>報酬の振込先口座情報`" />
        </router-link>
      </div>

      <div class="affiliate-atem">
        <a
          :href="`https://adtasukaru.wraptas.site/6fd36a37e80c4ce9ba283614d407fe77`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MenuButton :text="`よくある質問`" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import MenuButton from "@/components/Ui/MenuButton.vue";

export default {
  name: "AffiliateHomeLinkButtons",
  components: {
    MenuButton,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.cover {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
  .affiliate-atem {
    width: 32%;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
</style>
