<template>
  <div class="manual-google-cv-contents">
    <h2>ピクセルの新規作成</h2>
    <el-row>
      <el-col>
        <h3>【目的】</h3>
        <ul>
          <li>{{tool.mediaName}}を運用するためにピクセルを新規作成します。</li>
          <li>すでにお使いのピクセルと連携したい場合は、「<router-link :to="`/manual/${tool.codeKebab}/03/07`">ピクセルIDとアクセストークンの取得方法</router-link>」に進んでください。</li>
        </ul>
        <h3>【所要時間】</h3>
        <p>10分</p>
        <h4>【１】<a
            href="https://business.facebook.com/latest/home"
            target="_blank"
            rel="noopener noreferrer"
            >Meta Business Suite</a>
            にログインした状態で、「すべてのツール」をクリックします。</h4>
        <div class="mini-img">
          <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no06/01.png`" alt="" />
        </div>
        <h4>【２】「イベントマネージャ」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no06/02.png`" alt="" />
        <h4>【３】「データソースをリンク（緑色の＋）」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no06/03.png`" alt="" />
        <h4>【４】「ウェブ」をクリックし、「リンクする」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no06/04.png`" alt="" />
        <h4>【５】作成するピクセル名を任意で入力し、「ピクセルを作成」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no06/05.png`" alt="" />
        <h4>【６】「パートナー連携をチェック」が表示されたら「×」をクリックします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no06/06.png`" alt="" />
        <h4>【７】ピクセルの新規作成は完了です。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/no03/no06/07.png`" alt="" />
        <p>以上で、ピクセルの新規作成は完了です。</p>
        <p>「<router-link :to="`/manual/${tool.codeKebab}/03/07`">ピクセルIDとアクセストークンの取得方法</router-link>」に進んでください。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  computed: {
    ...mapGetters(["rt", "tool"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
