<template>
  <div class="account-menu-button-list-menu-button">
    <div class="outline">
      <div class="inline-cover">
        <i :class="iconName"></i>
        <p class="title">{{ title }}</p>
        <div class="color" :style="`border-color: ${colorCode}`"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MenuButton",
  components: {},
  props: {
    iconName: String,
    title: String,
    colorCode: String
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
$borderRadius: 8px;
.outline {
  width: 100%;
  border: solid 1px #999;
  border-radius: $borderRadius;
  padding: 10px;
  height: 118px;
  &:hover {
    opacity: 0.7;
  }
  .inline-cover {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadius;
    color: #555;
    i {
      font-size: 30px;
      margin-right: 10px;
    }
    p.title {
      font-weight: bold;
      font-size: 24px;
      margin-right: 20px;
    }
    .color {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-radius: 0 0 $borderRadius 0;
      border: 15px solid #666;
      border-top: 15px solid transparent!important;
      border-left: 15px solid transparent!important;
    }
  }
}
</style>
