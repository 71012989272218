var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools02-accounts-account-list"},[_c('el-row',[_c('el-col',{staticStyle:{"width":"140px"}},[_c('a',{attrs:{"href":_vm.toolData.manualData.accountRegistrationManualUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('Button2',{staticClass:"manual-button",attrs:{"type2":"1","msg":"マニュアル"}})],1)])],1),_c('el-row',[_c('el-col',[_c('router-link',{attrs:{"to":"account-detail/0"}},[_c('el-button',{staticClass:"new-add",attrs:{"type":"primary"}},[_vm._v("新規追加")])],1)],1)],1),(_vm.userData.bonusPointData[_vm.tool.codeCamel].account === false)?_c('el-row',[_c('el-col',[_c('UiBonusAlert',{attrs:{"toolCode":_vm.tool.codeCamel,"bonusCode":'account',"boxStyle":"fat"}})],1)],1):_vm._e(),_c('el-row',[_c('el-col',{staticClass:"table-cover"},[_c('table',{staticClass:"typeA stripe"},[_c('thead',[_c('tr',[_c('th',[_vm._v("アカウントID")]),_c('th',[_vm._v("アカウント名")]),(_vm.tool.codeKebab === 'google-cv')?_c('th',[_vm._v("種別")]):(_vm.tool.codeKebab === 'yahoo-cv')?_c('th',[_vm._v(" Yahoo!JAPAN ビジネスID ")]):_vm._e(),_c('th',{staticStyle:{"width":"50px"}}),_c('th',{staticStyle:{"width":"50px"}}),_c('th',{staticStyle:{"width":"50px"}}),(
                _vm.tool.codeKebab === 'google-cv' ||
                _vm.tool.codeKebab === 'yahoo-cv'
              )?_c('th',[_vm._v(" API自動アップロード ")]):_vm._e(),(_vm.tool.codeKebab === 'google-cv')?[_c('th'),_c('th')]:_vm._e()],2)]),(_vm.accountList.length > 0)?_c('tbody',_vm._l((_vm.accountList),function(accountData,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(accountData.accountCode)+" ")]),_c('td',{staticStyle:{"max-width":"160px","overflow-wrap":"break-word"}},[_vm._v(" "+_vm._s(accountData.accountName)+" ")]),(_vm.tool.codeKebab === 'google-cv')?_c('td',[_vm._v(" "+_vm._s(_vm.accountTypeNames2[accountData.accountType])+" ")]):(_vm.tool.codeKebab === 'yahoo-cv')?_c('td',[_vm._v(" "+_vm._s(_vm.$store.getters['tools02/bussinessAccountData'](accountData.bussinessAccountId).bussinessId)+" ")]):_vm._e(),_c('td',[(_vm.tool.codeKebab === 'yda-cv')?_c('router-link',{attrs:{"to":`account-detail/${accountData.accountId}/tags`}},[_c('el-button',{attrs:{"type":""}},[_vm._v("コンバージョン登録")])],1):_c('router-link',{attrs:{"to":`account-detail/${accountData.accountId}#promotions`}},[_c('el-button',{attrs:{"type":""}},[_vm._v("プログラム情報登録")])],1)],1),_c('td',{staticClass:"edit",staticStyle:{"width":"100px"}},[_c('router-link',{attrs:{"to":`account-detail/${accountData.accountId}`}},[_c('el-button',{attrs:{"type":""}},[_vm._v("編集")])],1)],1),_c('td',{staticClass:"edit"},[_c('el-button',{attrs:{"type":"danger"},on:{"click":function($event){return _vm.delAccount(accountData.accountId, accountData.accountCode)}}},[_vm._v("削除")])],1),(
                _vm.tool.codeKebab === 'google-cv' ||
                _vm.tool.codeKebab === 'yahoo-cv'
              )?_c('td',{staticStyle:{"text-align":"center"}},[(accountData.autoUpload === true)?_c('span',[_vm._v("ON")]):(accountData.autoUpload === false)?_c('span',[_vm._v("OFF")]):_vm._e()]):_vm._e(),(_vm.tool.codeKebab === 'google-cv')?[(accountData.autoUpload === true)?[_c('td',[(accountData.apiAuthorized === true)?_c('p',[_vm._v("認証済み")]):_c('p',{staticStyle:{"color":"red"}},[_vm._v("未認証")])]),_c('td',{staticStyle:{"text-align":"center"}},[_c('img',{staticStyle:{"cursor":"pointer","width":"150px"},attrs:{"src":`${_vm.rt}/img/tools02/google-cv/sign-in-button.png`,"alt":""},on:{"click":function($event){return _vm.authorize(accountData.accountId)}}})])]:[_c('td'),_c('td')]]:_vm._e()],2)}),0):_vm._e()])])],1),(
      _vm.userData.bonusPointData[_vm.tool.codeCamel].account === true &&
      _vm.userData.bonusPointData[_vm.tool.codeCamel].promotion === false
    )?_c('el-row',[_c('el-col',{staticClass:"center"},[_c('UiBonusAlert',{attrs:{"toolCode":_vm.tool.codeCamel,"bonusCode":'promotion',"boxStyle":"fat"}})],1)],1):_vm._e(),_c('a',{attrs:{"href":"http://","target":"_blank","rel":"noopener noreferrer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }