<template>
  <div v-if="loading === false" class="ctrl-send-mail-send-mail-system">
    <!-- ================ ↓↓ 入力画面 ↓↓ ================ -->
    <div v-if="pageType === 'edit'">
      <el-row>
        <el-col>
          <p style="color: red">
            ※「ユーザー属性」かつ「ユーザーID選択」かつ「ツール利用状況」というように”かつ”条件になっています。
            <br />なので、「ユーザーID選択」で送信する場合は「ユーザー属性」を全選択することをおすすめします。
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>ユーザー属性</h3>
        </el-col>
        <el-col class="box">
          <div class="button-box">
            <el-button @click="selectAll" size="mini" type="primary"
              >全選択</el-button
            >
            <el-button @click="unselectAll" size="mini" type="danger"
              >全解除</el-button
            >
          </div>
          <el-checkbox-group v-model="sendMailData.checkedClassificationList">
            <el-checkbox
              v-for="classificationData in classificationList"
              :key="classificationData.classId"
              :label="classificationData.classId"
              >{{ classificationData.className }}</el-checkbox
            >
          </el-checkbox-group>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>ユーザーID選択</h3>
        </el-col>
        <el-col class="box">
          <el-select
            v-model="sendMailData.selectedUserIdList"
            multiple
            placeholder="Select"
            style="width: 100%"
          >
            <el-option
              v-for="userData in realUserList"
              :key="userData.userId"
              :label="
                userData.userId +
                ': ' +
                userData.name01 +
                userData.name02 +
                (userData.userEmails > 0
                  ? ' (※' + userData.userEmails + ')'
                  : '')
              "
              :value="userData.userId"
            ></el-option>
          </el-select>
        </el-col>
        <el-col>
          <div style="font-size: 14px">
            ※ユーザー名の横に記載の「（※n）」は、登録メールアドレスとは別の、連絡先メールアドレスがn件であることを表しています。（送信メールには記載されません。）
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>ツール利用状況</h3>
        </el-col>
        <el-col class="box tool-condition-box">
          <el-row
            v-for="toolConditionData in sendMailData.toolConditionList"
            :key="toolConditionData.toolId"
          >
            <el-col>
              <h4>{{ toolConditionData.toolName }}</h4>
              <el-radio-group v-model="toolConditionData.conditionType">
                <el-radio
                  v-for="conditionTypeData in conditionTypeList"
                  :key="conditionTypeData.id"
                  :label="conditionTypeData.id"
                  >{{ conditionTypeData.name }}</el-radio
                >
              </el-radio-group>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <hr style="margin: 50px 0 20px" />
      <p style="font-size: 14px">
        ※注１「{name01}」「{name02}」部分にはそれぞれ、送信先ユーザーの「姓」「名」が入ります。任意の場所に挿入してもかまいません。
      </p>
      <el-row>
        <el-col>
          <h3>
            件名（※「【アドタスカル】」は自動で挿入されるので記載しないでください。）
          </h3>
        </el-col>
        <el-col>
          <el-input
            v-model="sendMailData.mailData.title"
            placeholder="件名を入力してください"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>ヘッダー</h3>
        </el-col>
        <el-col>
          <el-input
            v-model="sendMailData.mailData.header"
            type="textarea"
            :autosize="{ minRows: 3 }"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>ボディ</h3>
        </el-col>
        <el-col>
          <el-input
            v-model="sendMailData.mailData.body"
            type="textarea"
            :autosize="{ minRows: 20 }"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>フッター</h3>
        </el-col>
        <el-col>
          <el-input
            v-model="sendMailData.mailData.footer"
            type="textarea"
            :autosize="{ minRows: 10 }"
          ></el-input>
        </el-col>
      </el-row>
      <el-row v-if="mode === 'send'">
        <el-col style="text-align: center">
          <el-button @click="confirm" type="primary">確認</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- ================ ↑↑ 入力画面 ↑↑ ================ -->

    <!-- ================ ↓↓ 確認画面 ↓↓ ================ -->
    <div v-else-if="pageType === 'confirm'">
      <el-row>
        <el-col>
          <h3>ターゲット</h3>
        </el-col>
        <el-col class="box target-box">
          <el-row v-if="sendMailData.checkedClassificationList.length > 0">
            <el-col>
              <h3>ユーザー属性</h3>
            </el-col>
            <el-col>
              <div
                v-for="checkedClassId in sendMailData.checkedClassificationList"
                :key="checkedClassId"
              >
                ・{{ getClassName(checkedClassId) }}
              </div>
            </el-col>
          </el-row>
          <el-row v-if="sendMailData.selectedUserIdList.length > 0">
            <el-col>
              <h3>ユーザーID選択</h3>
            </el-col>
            <el-col>
              <span
                v-for="userId in sendMailData.selectedUserIdList"
                :key="userId"
                >{{ userId }},</span
              >
            </el-col>
          </el-row>
          <el-row v-if="conditionSelected === true">
            <el-col>
              <h3>ツール利用状況</h3>
            </el-col>
            <el-col>
              <div
                v-for="toolConditionData in sendMailData.toolConditionList"
                :key="toolConditionData.toolId"
              >
                <div v-if="toolConditionData.conditionType !== '0'">
                  <span>・{{ toolConditionData.toolName }}</span>
                  <span>:</span>
                  <span>
                    {{ getConditionTypeName(toolConditionData.conditionType) }}
                  </span>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row v-if="mode === 'history'">
        <el-col>
          <h3>
            送信先一覧（全{{
              sendMailData.sendUserIdList.length | localeNum
            }}件）
          </h3>
        </el-col>
        <el-col>
          <el-table :data="sendUserList" height="250" style="width: 100%">
            <el-table-column
              prop="userId"
              label="ユーザーID"
              width="180"
            ></el-table-column>
            <el-table-column prop="name" label="名前"></el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>件名</h3>
        </el-col>
        <el-col>
          <div>【アドタスカル】{{ sendMailData.mailData.title }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <h3>本文</h3>
        </el-col>
        <el-col>
          <div v-html="nl2br(sendMailData.mailData.header) + '<br><br>'"></div>
          <div v-html="nl2br(sendMailData.mailData.body) + '<br><br>'"></div>
          <div v-html="nl2br(sendMailData.mailData.footer)"></div>
        </el-col>
      </el-row>
      <el-row
        v-if="mode === 'send'"
        v-loading="sending"
        type="flex"
        justify="center"
        style="margin-top: 50px"
      >
        <el-col :span="3" style="text-align: center">
          <el-button @click="revise" type="info">修正</el-button>
        </el-col>
        <el-col :span="3" style="text-align: center">
          <el-button @click="sendMails" type="primary">送信</el-button>
        </el-col>
      </el-row>
      <p v-show="sending === true" style="text-align: center">
        送信中...
        <br />（AWSの仕様上、1秒に14通までしか送信できないのでしばらくお待ちください。）
      </p>
    </div>
    <!-- ================ ↑↑ 確認画面 ↑↑ ================ -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "send-mail-system",
  props: {
    mode: String,
    sendId: String,
  },
  data() {
    return {
      loading: true,
      pageType: "edit",

      //=== ↓↓ DBにもidを記録してるので値変えない（DBで管理予定） ↓↓ ===
      conditionTypeList: [
        {
          id: "0",
          name: "指定なし",
        },
        {
          id: "1",
          name: "現在ON",
        },
        {
          id: "2",
          name: "現在OFF（過去ONあり）",
        },
        {
          id: "3",
          name: "現在OFF（過去ONなし）",
        },
      ],
      //=== ↑↑ DBにもidを記録してるので値変えない（DBで管理予定） ↑↑ ===

      conditionSelected: false,
      sendMailData: {},
      sendUserList: [],

      sending: false,
    };
  },
  computed: {
    ...mapGetters(["rt", "tools", "apiErrorMessage"]),
    ...mapGetters("ctrl", ["classificationList", "userList", "realUserList"]),
  },
  watch: {
    sendMailData: {
      handler() {
        let conditionSelected = false;
        this.sendMailData.toolConditionList.forEach((toolConditionData) => {
          if (toolConditionData.conditionType === "0") {
            //no action.
          } else {
            conditionSelected = true;
          }
        });
        this.conditionSelected = conditionSelected;
      },
      deep: true,
    },
    sendId: {
      handler(newValue) {
        this.getSendMailData(newValue);
      },
      immediate: true,
    },
  },
  created() {
    //
    if (this.mode === "send") {
      this.pageType = "edit";
    } else if (this.mode === "history") {
      this.pageType = "confirm";
    } else {
      alert("不正なmode");
      return;
    }
  },
  methods: {
    getSendMailData(sendId) {
      this.$axios
        .get(`${this.rt}/api/ctrl/get-send-mail-data.php?send_id=${sendId}`)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            //
            this.sendMailData = res.data.sendMailData;

            //
            this.sendMailData.sendUserIdList.forEach((userId) => {
              this.userList.forEach((userData) => {
                if (userId === userData.userId) {
                  this.sendUserList.push(userData);
                } else {
                  //no action.
                }
              });
            });

            this.loading = false;
            return;
          } else if (res.data.status === 400 || res.data.status === 500) {
            this.sending = false;
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
            return;
          } else {
            this.sending = false;
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //============================= ↓↓ edit ↓↓ =============================
    selectAll() {
      this.unselectAll();
      this.classificationList.forEach((classificationData) => {
        this.sendMailData.checkedClassificationList.push(
          classificationData.classId
        );
      });
    },
    unselectAll() {
      this.sendMailData.checkedClassificationList = [];
    },
    confirm() {
      //ユーザー属性チェック
      if (this.sendMailData.checkedClassificationList.length !== 0) {
        //ok.
      } else {
        alert("ユーザー属性は少なくとも1つ選択してください。");
        return;
      }

      //未入力チェック
      if (
        this.sendMailData.mailData.title !== "" &&
        (this.sendMailData.mailData.header !== "" ||
          this.sendMailData.mailData.body !== "" ||
          this.sendMailData.mailData.footer !== "")
      ) {
        //ok.
      } else {
        alert("未入力の項目があります。");
        return;
      }

      //
      //https://kinocolog.com/javascript_sort/ //←配列のソート
      this.sendMailData.checkedClassificationList.sort();
      this.pageType = "confirm";
      window.scrollTo(0, 0);
    },
    //============================= ↑↑ edit ↑↑ =============================

    //============================= ↓↓ confirm ↓↓ =============================
    getClassName(classId) {
      let classificationData = this.classificationList.find(
        (classificationData) => classificationData.classId === classId
      );
      return classificationData.className;
    },
    getConditionTypeName(conditionType) {
      let conditionTypeData = this.conditionTypeList.find(
        (conditionTypeData) => conditionTypeData.id === conditionType
      );
      return conditionTypeData.name;
    },
    nl2br(text) {
      return text.replace(/\r?\n/g, "<br>");
    },
    revise() {
      this.pageType = "edit";
      window.scrollTo(0, 0);
    },
    sendMails() {
      this.sending = true;
      let params = new URLSearchParams();
      params.append("send_mail_data", JSON.stringify(this.sendMailData));
      this.$axios
        .post(`${this.rt}/api/ctrl/send-mails-to-users.php`, params)
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            this.sending = false;
            alert("送信完了。");
            this.$router.push("/ctrl/send-mails");
            return;
          } else if (res.data.status === 400 || res.data.status === 500) {
            this.sending = false;
            alert(res.data.message);
            return;
          } else if (res.data.status === 401) {
            alert(res.data.message);
            this.$router.push("/login");
            return;
          } else {
            this.sending = false;
            alert(this.apiErrorMessage);
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //============================= ↑↑ confirm ↑↑ =============================
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 5px;
}
.el-row {
  .el-col {
    &.box {
      border: solid 1px #ccc;
      padding: 10px;
      .button-box {
        margin: 10px 20px;
      }
      :deep(.el-checkbox-group) {
        .el-checkbox {
          margin: 10px 20px;
        }
      }
    }
    .tool-condition-box {
      padding-left: 30px;
      h4 {
        margin: 10px auto 5px;
      }
    }
    .target-box {
      padding-left: 30px;
      .el-row {
        margin: 20px 0;
      }
      h3 {
        margin: 0 auto 5px;
      }
    }
  }
}
</style>
