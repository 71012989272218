<template>
  <div class="manual-tools02-contents">
    <el-row>
      <el-col>
        <h2>ASPとアドタスカルを連携する（セレス）</h2>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <h4>【１】アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/point-back`">ASP自動連携設定</router-link>」にアクセスします。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/point-back.png`" alt="" />
        <h4>【２】セレスの通知URLをコピーします。</h4>
        <img :src="`${rt}/img/manual/google-cv/no08/no04/01.png`" alt="" />
        <h4>【３】セレスは、サイトごとに通知URLの設定が必要ですが、案件ごとにセレス側でサイト登録がされるので、実質案件ごとに連携が必要になります。アドタスカルと連携したい案件名と通知URLを担当者に連絡します。</h4>
        <p>-----------------------<br>
          アドタスカルとの連携希望です。<br>
          ・（案件A）<br>
          ・（案件B）<br>
          <br>
          （【２】でコピーしたセレスの通知URL）<br>
          -----------------------</p>
        <h4>【４】連携が完了したら、アフィリリンクからテスト購入をしてください。※テスト規定は各案件によって異なるので担当さんに確認ください。</h4>
        <h4>【５】アフィリリンクに「<span class="red">&amp;suid={{tool.clickIdName}}_xxx</span>」を追記してください。</h4>
        <h5>▼発行されたテストURL</h5>
        <p>https://ad-track.jp/ad/p/r?_site=29638&amp;_article=●●●&amp;_link=●●●&amp;_image=25810</p>
        <h5>▼クリックパラメータを自分で付ける</h5>
        <p>https://ad-track.jp/ad/p/r?_site=29638&amp;_article=●●●&amp;_link=●●●&amp;_image=25810<span class="red">&amp;suid={{tool.clickIdName}}_xxx</span></p>
        <p class="red">※自分で付けたパラメータを覚えておきましょう</p>
        <h4>【６】パラメータを付けたテストURLにアクセスして、テスト購入します。</h4>
        <h4>【７】Thanksページが表示されます。これでテスト完了です。</h4>
        <h4>【８】アドタスカルの「<router-link :to="`/tools02/${tool.codeKebab}/auto-import-log`">自動登録履歴</router-link>」を確認します。</h4>
        <img :src="`${rt}/img/manual/${tool.codeKebab}/general/log.png`" alt="" />
        <h4>【９】数分後に、テストCVが登録されていれば問題なく連携完了です。</h4>
        <AutoImportLog 
          :aspId="'3'"
          :conversionTime="'2021/04/19 21:41:03'"

          :aspPromotionName="'12860'"
          :conversionValue="'0'"
          :conversionValueReal="'0'"
          :errorDescription="'CLICK IDが取得不可'" />
        <ul>
          <li>ASP：セレス</li>
          <li>CV時間：テストした時間になっているか？（多少の誤差はあり）</li>
          <li>プログラム名：-</li>
          <li>パラメータ：【５】で付けたパラメータと一致しているか？</li>
          <li>プログラムID： テストした案件のプログラムID</li>
          <li>報酬額：テストした案件の報酬額</li>
          <li>インポート状況：「CLICK IDが取得不可」のエラーがあっても大丈夫です</li>
        </ul>
        <h4>【１０】連携完了</h4>
        <p>担当さんに一報入れましょう。これで連携は完了です。</p>
        <PointbackSettingDescription :aspId="'3'" />
        <p class="red">※セレスでは、新規案件を扱う場合、セレス側で新規にサイト登録がされるため、実質案件ごとに連携が必要になります。</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PointbackSettingDescription from "@/components/Manual/Tools02/No08/Parts/PointbackSettingDescription.vue";
import AutoImportLog from "@/components/Manual/Tools02/No08/Parts/AutoImportLog.vue";

export default {
  name: "ManualGoogleCvContents",
  components: {
    PointbackSettingDescription,
    AutoImportLog
  },
  props: {
  },
  computed: {
    ...mapGetters(["rt","tool"]),
    tools() {
      return this.$store.getters.tools;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>

</style>
