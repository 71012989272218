<template>
  <div class="tools01-home">
    <el-row>
      <el-col>
        <h2>{{ toolData.name2 }}</h2>
      </el-col>
    </el-row>
    <el-row :gutter="20" type="flex" align="middle">
      <el-col :span="24">
        <div class="link-buttons">
          <router-link :to="`/tools01/${toolData.code2}/new`">
            <el-button type="primary">新規設定</el-button>
          </router-link>
          <a
            :href="`/tools01/${toolData.code2}/report`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <el-button type="success">全体レポート</el-button>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <el-button>初期チュートリアル</el-button>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <el-button>消化ポイントについて</el-button>
          </a>
          <a href="" target="_blank" rel="noopener noreferrer">
            <el-button>マニュアル・よくある質問</el-button>
          </a>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="setting-description">
          ※「新規設定」から{{
            toolData.name
          }}の設定ができます。設定方法については「マニュアル」をご確認ください。
        </div>
      </el-col>
    </el-row>
    <PageList :toolId="toolId" :displayType="'default'"/>
  </div>
</template>

<script>
import PageList from "@/components/Tools01/Home/PageList.vue";

export default {
  components: {
    PageList,
  },
  props: {
    toolId: String,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    toolData() {
      return this.$store.getters["toolData"](this.toolId);
    },
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.link-buttons {
  a + a {
    margin-left: 30px;
  }
}
</style>
