<template>
  <div class="ctrl-post">
    <h2>お知らせ投稿</h2>
    <el-row :gutter="30" type="flex" justify="space-between">
      <el-col :span="16">
        <router-link to="/ctrl">
          <Button3 msg="戻る" type="success" />
        </router-link>
      </el-col>
      <el-col :span="4">
        <router-link to="/ctrl/post-edit/0">
          <Button3 msg="新規登録" type="primary" />
        </router-link>
      </el-col>
    </el-row>

    <el-row>
      <el-col>
        
        <table class="typeA stripe">
          <thead>
            <tr>
              <th>タイトル</th>
              <th>公開日時</th>
              <th>編集</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableData" :key="index">
              <td>
                {{item.title}}
              </td>
              <td>
                {{item.release_datetime}}
              </td>
              <td class="btn">
                <router-link :to="'/ctrl/post-edit/'+item.post_id">
                  <el-button>編集</el-button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Button3 from "@/components/Ui/Button3.vue";

export default {
  data() {
    return {
      tableData: [],
    }
  },
  components: {
    Button3
  },
  created() {
  },
  mounted() {
    this.$axios
      .get(this.$store.getters.rt + "/api/ctrl/getPostList.php")
      .then((res) => {
        console.log(res);
        this.tableData = res.data.list;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
</style>
