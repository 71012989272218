<template>
  <div class="Ctrl-parameter-log">
    <el-row>
      <el-col>
        <h2>カートシステム</h2>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :span="8">
        <router-link to="/ctrl/tools02/cart-system/subscriptions">
          <Button2 msg="申し込み一覧" />
        </router-link>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button2 from "@/components/Ui/Button2.vue";

export default {
  components: {
    Button2,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt"]),
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
